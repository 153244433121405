<template>
  <div class="student-modal" v-cloak>
    <h4 class="modal-title">
      {{
        isNew
          ? $t("studentModal.header.create")
          : $t("studentModal.header.update")
      }}
    </h4>

    <div class="class-group-modal-content grid-x content-wrapper">
      <div class="small-12 cell">
        <h6>{{ $t("studentModal.content.fullName") }}</h6>
        <input
          type="text"
          id="fullName"
          name="fullName"
          :placeholder="$t('studentModal.content.fullName.placeholder')"
          v-model="formData.fullName"
        />
      </div>
      <div class="small-12 cell">
        <h6>{{ $t("studentModal.content.displayName") }}</h6>
        <input
          type="text"
          id="displayName"
          name="displayName"
          :placeholder="$t('studentModal.content.displayName.placeholder')"
          v-model="formData.displayName"
        />
      </div>
      <div class="small-12 cell">
        <h6>{{ $t("studentModal.content.gender") }}</h6>
        <select v-model="formData.gender">
          <option :value="null">
            {{ $t("selectInput.placeholder.select") }}
          </option>
          <option v-for="option in genders" :value="option" :key="option">
            {{ $t(`gender.${option}`) }}
          </option>
        </select>
      </div>
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("studentModal.content.yearOfBirth") }}</h6>
        <select v-model="formData.yearOfBirth">
          <option :value="null">
            {{ $t("selectInput.placeholder.select") }}
          </option>
          <option v-for="option in years" :value="option" :key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("studentModal.content.learningSupportNeed") }}</h6>
        <select v-model="formData.learningSupportNeed">
          <option :value="null">
            {{ $t("selectInput.placeholder.select") }}
          </option>
          <option
            v-for="option in learningSupportNeedList"
            v-bind:value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("studentModal.content.behaviourSupportNeed") }}</h6>
        <select v-model="formData.behaviourSupportNeed">
          <option :value="null">
            {{ $t("selectInput.placeholder.select") }}
          </option>
          <option
            v-for="option in behaviourSupportNeedList"
            v-bind:value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <!--  v-for="category in taskItemCategoryListFormatted" :key="category.value"
			<div class="small-12 cell cell-padding-top">
				<h6>{{ $t('studentModal.content.learningSupportNeed') }}</h6>
				<input type="text" id="learningSupportNeed" name="learningSupportNeed" :placeholder="$t('studentModal.content.learningSupportNeed.placeholder')" v-model="formData.learningSupportNeed" />
			</div>

			<div class="small-12 cell cell-padding-top">
				<h6>{{ $t('studentModal.content.behaviourSupportNeed') }}</h6>
				<input type="text" id="behaviourSupportNeed" name="behaviourSupportNeed" :placeholder="$t('studentModal.content.behaviourSupportNeed.placeholder')" v-model="formData.behaviourSupportNeed" />
			</div>
-->
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("studentModal.content.additionalSupportNeed") }}</h6>
        <!-- <input type="text" id="additionalSupportNeed" name="additionalSupportNeed" :placeholder="$t('studentModal.content.additionalSupportNeed.placeholder')" v-model="formData.additionalSupportNeed" /> -->
        <select v-model="formData.additionalSupportNeed">
          <option :value="null">
            {{ $t("selectInput.placeholder.select") }}
          </option>
          <option
            v-for="option in additionalSupportNeedList"
            v-bind:value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>

      <div class="action-selector cell grid-x align-right">
        <div class="cell shrink">&nbsp;</div>
        <button
          type="submit"
          class="button primary margin-bottom-0"
          @click="submit"
        >
          {{
            isNew
              ? $t("studentModal.action.create")
              : $t("studentModal.action.save")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constants from "@/constants";

export default {
  name: "StudentModal",
  props: {
    fullName: {
      type: String,
      default: "",
    },
    displayName: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
      default: null,
    },
    yearOfBirth: {
      type: Number,
      default: null,
    },
    learningSupportNeed: {
      type: String,
      default: null,
    },
    behaviourSupportNeed: {
      type: String,
      default: null,
    },
    additionalSupportNeed: {
      type: String,
      default: null,
    },
    conditionTypes: {
      type: Array,
      default: null,
    },
    studentId: {
      type: String,
      default: undefined,
    },
    classId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentYear: "currentYear",
      behaviourSupportNeedList: "behaviourSupportNeedList",
      additionalSupportNeedList: "additionalSupportNeedList",
      learningSupportNeedList: "learningSupportNeedList",
    }),
    genders() {
      return Object.values(constants.GENDER);
    },
    learningSupportNeeds() {
      return Object.values(constants.LEARNING_SUPPORT_NEED);
    },
    behaviourSupportNeedListFormatted: function () {
      const reversedList = [...this.behaviourSupportNeedList].reverse();
      return reversedList;
    },
    years() {
      const startYear = this.currentYear;
      const yearSize = 100;

      const options = [];
      for (let i = 0; i < yearSize; i++) {
        options.push(startYear - i);
      }
      return options;
    },
    isNew() {
      return this.studentId === undefined;
    },
  },
  async created() {
    await this.getSupportNeedOptions();
  },
  data() {
    return {
      formData: {
        fullName: this.fullName,
        displayName: this.displayName,
        gender: this.gender,
        yearOfBirth: this.yearOfBirth,
        learningSupportNeed: this.learningSupportNeed,
        behaviourSupportNeed: this.behaviourSupportNeed,
        additionalSupportNeed: this.additionalSupportNeed,
        conditionTypes: this.conditionTypes,
      },
    };
  },
  methods: {
    submit() {
      if (this.isNew) {
        this.submitNew();
      } else {
        this.submitUpdate();
      }
    },
    async submitNew() {
      try {
        const { status: studentStatus, data: studentData } =
          await this.createStudentProfile(this.formData);
        if (studentStatus !== constants.RESPONSE_STATUS.OK && studentData) {
          return this.$toastr.error(studentData && studentData.message);
        }

        const { status: classGroupStatus, data: classGroupData } =
          await this.addStudentsToClassGroup({
            classId: this.classId,
            studentIds: [studentData.id],
          });
        if (
          classGroupStatus !== constants.RESPONSE_STATUS.OK &&
          classGroupData
        ) {
          return this.$toastr.error(classGroupData && classGroupData.message);
        }

        this.refreshSideMenu();
        this.$toastr.success(
          this.$t("studentModal.success.created", {
            name: this.formData.displayName,
            class: classGroupData.name,
          })
        );
        this.$modal.closeAll();
      } catch (error) {
        this.$toastr.error(error.data && error.data.message);
      }
    },
    async submitUpdate() {
      try {
        const { status, data } = await this.updateStudentProfile({
          ...this.formData,
          studentId: this.studentId,
        });
        if (status !== constants.RESPONSE_STATUS.OK && data) {
          return this.$toastr.error(data && data.message);
        }

        this.refreshSideMenu();
        this.$toastr.success(
          this.$t("studentModal.success.updated", {
            name: this.formData.displayName,
          })
        );
        this.$modal.closeAll();
        this.getClassGroup(this.classId);
      } catch (error) {
        this.$toastr.error(error.data && error.data.message);
      }
    },
    refreshSideMenu() {
      this.getSideMenu(this.$route.params.year);
    },
    ...mapActions([
      "createStudentProfile",
      "updateStudentProfile",
      "addStudentsToClassGroup",
      "getClassGroup",
      "getSideMenu",
      "getSupportNeedOptions",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
</style>
