<!-- CoachingLearningPointsBarChart.vue -->
<template>
	<div>
	  <coaching-bar-chart :chart-data="localChartData" :options="chartOptions" />
	</div>
</template>
  
<script>
  import CoachingBarChart from "@/components/CoachingBarChart";
  
  export default {
	name: "CoachingLearningPointsBarChart",
	components: {
	  CoachingBarChart,
	},
	props: {
	  learningPoints: {
		type: Array,
		required: true,
	  },
	},
	data() {
	  return {
		localChartData: {},
		chartOptions: {
		  responsive: true,
		  maintainAspectRatio: false,
		  scales: {
			xAxes: [
			  {
				type: "time",
				time: {
				  unit: "day",
				  displayFormats: {
					day: "MMM D",
				  },
				},
				scaleLabel: {
				  display: true,
				  labelString: "Date",
				},
			  },
			],
			yAxes: [
			  {
				ticks: {
				  beginAtZero: true,
				},
				scaleLabel: {
				  display: true,
				  labelString: "Accumulated Learning Points",
				},
			  },
			],
		  },
		},
	  };
	},
	mounted() {
	  this.generateChartData();
	},
	methods: {
	  generateChartData() {
		const sortedLearningPoints = this.learningPoints.slice().sort((a, b) => {
		  return new Date(a.createdDate) - new Date(b.createdDate);
		});
  
		let accumulatedLearningPoints = 0;
		const chartData = {
		  labels: [],
		  datasets: [
			{
			  label: "Accumulated Learning Points",
			  data: [],
			  backgroundColor: "#3e95cd",
			  borderColor: "#3e95cd",
			  borderWidth: 1,
			},
		  ],
		};
  
		sortedLearningPoints.forEach((learningPoint) => {
		  accumulatedLearningPoints += 1;
		  chartData.labels.push(learningPoint.createdDate);
		  chartData.datasets[0].data.push(accumulatedLearningPoints);
		});
  
		this.localChartData = chartData;
	  },
	},
  };
</script>
  
<style scoped>
</style>
  