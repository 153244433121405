<template>
  <div
    v-if="isEloSideTray()"
    @click="addToEloCodes(textId)"
    class="card-section edit"
    :class="[isAdded ? 'added' : '']"
    v-cloak
  >
    <FontAwesomeIcon
      v-if="isAdded"
      icon="check"
      class="check-icon color--success"
    ></FontAwesomeIcon>

    <elo-list-card-details
      :elo-code="textId"
      :elo-name="textName"
      :programme-codes="programmeCodes"
    >
      <p><elo-list-card-strands :strands="strands"></elo-list-card-strands></p>
    </elo-list-card-details>
  </div>

  <div
    v-else-if="(isProgrammePlan() || isAnnualPlan()) && hasEloDetails()"
    class="grid-x parent"
  >
    <elo-list-card-details
      @click.native="showEditModal($event)"
      class="card-section edit include-hover cell auto"
      :class="[
        (eloDetails && eloDetails.isNew) ||
        (studentEloDetails && studentEloDetails.isNew)
          ? 'is-new-elo'
          : '',
        eloStatus !== 'ACTIVE' ? 'terminated' : '',
      ]"
      :elo-code="eloDetails.eloCode"
      :elo-name="eloDetails.eloName"
      :programme-codes="eloDetails.programmeCodes"
    >
      <p v-if="isAnnualPlan()">
        <span class="year-subtext">{{ studentEloDetails.planYear }}</span>
        <span class="icon-bar">
          &nbsp;<font-awesome-icon
            v-if="studentEloDetails.hasTaskItems"
            class="icon-image color--black"
            icon="list-ul"
            size="1x"
          >
          </font-awesome-icon
          >&nbsp;
          <font-awesome-icon
            v-if="studentEloDetails.hasRemarks"
            class="icon-image comment color--black"
            :icon="['far', 'comment-dots']"
            size="1x"
          >
          </font-awesome-icon>
        </span>
      </p>

      <p v-else-if="isProgrammePlan()" class="grid-x align-middle">
        <elo-list-card-strands :strands="strands"></elo-list-card-strands>
        <span
          v-if="latestAttainmentDetails"
          class="margin-left-1 year-subtext"
          >{{ latestAttainmentDetails.years.join(", ") }}</span
        >
        <attainment-badge
          class="margin-left-1 margin-right-1 attainment-badge"
          v-if="
            latestAttainmentDetails && latestAttainmentDetails.attainmentLevel
          "
          :level="latestAttainmentDetails.attainmentLevel"
        >
        </attainment-badge>
        <span
          v-if="eloStatus !== 'ACTIVE'"
          :data-val="eloStatus.toLowerCase()"
          class="year-subtext"
          >{{ eloStatus.toLowerCase() }}</span
        >
      </p>
    </elo-list-card-details>

    <more-dropdown
      :options="dropdownOptions"
      :identifier="$vnode.key"
      :class="[isAnnualPlan() ? 'aiep-more' : '']"
    ></more-dropdown>

    <div class="aiep-plan cell small-12 medium-6" v-if="isAnnualPlan()">
      <div class="aiep-plan-column grid-x text-center">
        <p class="aiep-label grid-x align-bottom">
          {{ $t("eloListCard.first.assessment") }}
        </p>
        <div
          class="cell auto"
          :class="
            i > 1 &&
            (i < studentEloDetails.startTerm * 2 ||
              i > studentEloDetails.endTerm * 2 + 1)
              ? 'blank'
              : ''
          "
          v-for="(header, i) in 10"
          :key="i"
        >
          <span class="aiep-attainment grid-x align-center align-middle">
            <p v-if="isOdd(i)" class="prompt margin-0">
              <b>{{ promptHierarchyForTerm(i / 2, 1) }}</b>
            </p>
            <attainment-badge
              class="attainment-badge"
              v-else-if="attainmentLevelForTerm((i - 1) / 2, 1)"
              :level="attainmentLevelForTerm((i - 1) / 2, 1)"
            >
            </attainment-badge>
          </span>
        </div>
      </div>

      <div class="aiep-plan-column grid-x text-center">
        <p class="aiep-label grid-x align-bottom">
          {{ $t("eloListCard.second.assessment") }}
        </p>
        <div
          class="cell auto"
          :class="
            i > 1 &&
            (i < studentEloDetails.startTerm * 2 ||
              i > studentEloDetails.endTerm * 2 + 1)
              ? 'blank'
              : ''
          "
          v-for="(header, i) in 10"
          :key="i"
        >
          <span class="aiep-attainment grid-x align-center align-middle">
            <p v-if="isOdd(i)" class="prompt margin-0">
              <b>{{ promptHierarchyForTerm(i / 2, 2) }}</b>
            </p>
            <attainment-badge
              class="attainment-badge"
              v-else-if="attainmentLevelForTerm((i - 1) / 2, 2)"
              :level="attainmentLevelForTerm((i - 1) / 2, 2)"
            >
            </attainment-badge>
          </span>
        </div>
      </div>
    </div>
  </div>

  <elo-list-card-details
    v-else-if="hasEloDetails()"
    :elo-code="eloDetails.eloCode"
    :elo-name="eloDetails.eloName"
    :programme-codes="eloDetails.programmeCodes"
  >
  </elo-list-card-details>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TerminateEloModal from "./TerminateEloModal";
import EditEloModal from "@/views/EditEloModal/EditEloModal";
import MoreDropdown from "./MoreDropdown";
import EloListCardDetails from "./EloListCardDetails";
import EloListCardStrands from "./EloListCardStrands";
import AttainmentBadge from "./AttainmentBadge";

export default {
  name: "EloListCard",
  components: {
    FontAwesomeIcon,
    MoreDropdown,
    EloListCardDetails,
    EloListCardStrands,
    AttainmentBadge,
  },
  props: {
    textId: String,
    textName: String,
    programmeCodes: Array,
    programmeOutcome: Array,
    strands: Array,
    componentType: {
      type: String,
      required: true,
      validator: (value) => {
        let typesAllowed = [
          "EloSideTray",
          "ProgrammePlan",
          "AnnualPlan",
          "Others",
        ];
        return _.includes(typesAllowed, value);
      },
    },
  },
  data() {
    return {
      added: false,
    };
  },
  computed: {
    ...mapGetters({
      studentPlan: "studentPlan",
      studentAnnualPlan: "studentAnnualPlan",
      elosToAdd: "elosToAdd",
    }),
    isAdded: function () {
      return _.indexOf(this.elosToAdd, this.textId) >= 0;
    },
    eloObject: function () {
      let studentId = this.$route.params.studentId;
      let eloObject = _.find(
        this.studentPlan[studentId].studentProgrammeElos,
        (elo) => {
          return elo.eloCode === this.textId;
        }
      );
      return eloObject;
    },
    eloStatus: function () {
      return this.eloObject ? this.eloObject.eloStatus : null;
    },
    eloDetails: function () {
      return this.eloObject ? this.eloObject.elo : null;
    },
    studentEloDetails: function () {
      let studentId = this.$route.params.studentId;
      let year = this.$route.params.year;

      if (
        this.studentAnnualPlan[studentId] &&
        this.studentAnnualPlan[studentId][year] &&
        this.studentAnnualPlan[studentId][year].studentElos
      ) {
        return _.find(
          this.studentAnnualPlan[studentId][year].studentElos,
          (studentElo) => {
            return studentElo.eloCode === this.textId;
          }
        );
      } else {
        return {};
      }
    },
    studentPlanStatus: function () {
      if (this.isProgrammePlan()) {
        return this.$store.getters.studentProgrammePlanStatuses[
          this.$route.params.studentId
        ];
      } else if (this.isAnnualPlan()) {
        return this.$store.getters.studentAnnualPlanStatuses[
          this.$route.params.studentId
        ];
      }

      return {};
    },
    latestAttainmentDetails: function () {
      if (this.isProgrammePlan()) {
        return this.$store.getters.latestAttainmentDetails(
          this.$route.params.studentId,
          this.textId
        );
      }

      return null;
    },
    dropdownOptions: function () {
      let clickOptions = [
        {
          label: "eloListCard.edit",
          method: this.showEditModal,
          type: "CLICK",
        },
        {
          label: "eloListCard.delete",
          method: this.deleteEloClicked,
          type: "CLICK",
        },
      ];

      let terminateOptions = [
        {
          label: "eloListCard.attained",
          method: this.setToAttained,
          type: "CLICK",
        },
        {
          label: "eloListCard.exempted",
          method: this.setToExempted,
          type: "CLICK",
        },
      ];

      let activateOptions = [
        {
          label: "eloListCard.activate",
          method: this.setToActive,
          type: "CLICK",
        },
      ];

      if (this.isProgrammePlan()) {
        if (this.eloStatus === "ACTIVE") {
          return clickOptions.concat(terminateOptions);
        }

        return clickOptions.concat(activateOptions);
      }

      return clickOptions;
    },
  },
  methods: {
    addToEloCodes(code) {
      if (this.componentType === "EloSideTray") {
        if (!this.isAdded) {
          this.$store.dispatch("addToEloCode", code);
        } else {
          this.$store.dispatch("removeFromEloCode", code);
        }
      }
    },
    hasEloDetails() {
      return this.eloDetails && this.eloDetails.eloCode;
    },
    isEloSideTray() {
      return this.componentType === "EloSideTray";
    },
    isProgrammePlan() {
      return this.$route.name === "Piep";
    },
    isAnnualPlan() {
      return (
        this.$route.name === "Aiep" ||
        this.$route.name === "Aiep0" ||
        this.$route.name === "Aiep1" ||
        this.$route.name === "Aiep2"
      );
    },
    isOdd: function (val) {
      return val % 2 === 0;
    },
    promptHierarchyForTerm: function (term, assessmentCycle) {
      let requiredAttainment = _.find(
        this.studentEloDetails.attainments,
        (attainment) => {
          return (
            attainment &&
            attainment.planTerm === term &&
            attainment.assessmentCycle === assessmentCycle
          );
        }
      );

      return requiredAttainment ? requiredAttainment.promptHierarchy : "";
    },
    attainmentLevelForTerm: function (term, assessmentCycle) {
      let requiredAttainment = _.find(
        this.studentEloDetails.attainments,
        (attainment) => {
          return (
            attainment &&
            attainment.planTerm === term &&
            attainment.assessmentCycle === assessmentCycle
          );
        }
      );

      return requiredAttainment ? requiredAttainment.attainmentLevel : null;
    },
    showEditModal() {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
        programmeCodes: this.programmeCodes,
        programmeOutcome: this.programmeOutcome,
        studentId: parseInt(this.$route.params.studentId),
        selectedYear: parseInt(this.$route.params.year),
      };

      if (event.target.classList[1] !== "linkTab") {
        this.$modal.show({
          component: EditEloModal,
          componentProps: componentProps,
          class: "large",
          closeCallback: this.modalClose,
        });
      }
    },
    modalClose() {
      let params = {
        studentId: this.$route.params.studentId,
        planYear: this.$route.params.year,
      };
      this.$store.dispatch("getStudentPlan", params).then((success) => {
        if (success) {
          this.$store.dispatch("getLatestAttainmentDetails", params);
        }
      });
      this.$store.dispatch("getStudentAnnualPlan", params);
    },
    deleteEloClicked() {
      if (this.studentPlanStatus.planStatus === "APPROVED") {
        this.$modal.show({
          header: this.$t("addRemoveElo.whenApproved.modal.header"),
          text: this.$t("addRemoveElo.whenApproved.modal.text"),
        });
      } else {
        this.showDeleteConfirmation();
      }
    },
    showDeleteConfirmation() {
      let deletionOptions = {
        Aiep: {
          header: this.$t("eloListCard.delete.elo.annual.modalTitle"),
          text: this.$t("eloListCard.delete.elo.annual.modalText"),
          includeConfirm: true,
          successCallback: this.deleteEloFromAppropriatePlan,
        },
        Piep: {
          header: this.$t("eloListCard.delete.elo.programme.modalTitle"),
          text: this.$t("eloListCard.delete.elo.programme.modalText"),
          includeConfirm: true,
          successCallback: this.deleteEloFromAppropriatePlan,
        },
      };

      this.$modal.show(deletionOptions[this.$route.name]);
    },
    deleteEloFromAppropriatePlan() {
      if (this.isProgrammePlan()) {
        this.deleteEloFromProgrammePlan();
      } else if (this.isAnnualPlan()) {
        this.deleteEloFromAnnualPlan();
      }
    },
    deleteEloFromProgrammePlan() {
      let studentId = this.$route.params.studentId;
      let selectedYear = this.$route.params.year;
      let eloCode = this.textId;

      this.$store
        .dispatch("deleteEloFromProgrammePlan", {
          studentId,
          selectedYear,
          eloCode,
        })
        .then(() => {});
    },
    deleteEloFromAnnualPlan() {
      let studentId = this.$route.params.studentId;
      let planYear = this.$route.params.year;
      let eloCode = this.textId;

      this.$store
        .dispatch("deleteEloFromAnnualPlan", { studentId, planYear, eloCode })
        .then(() => {});
    },
    setToAttained() {
      this.modalOptions("ATTAINED");
    },
    setToExempted() {
      this.modalOptions("EXEMPTED");
    },
    setToActive() {
      this.modalOptions("ACTIVE");
    },
    modalOptions(toBeEloStatus) {
      let modalOptions = {
        ATTAINED: {
          APPROVED: {
            header: this.$t("setAttainedEloOnPiep.whenApproved.modal.header"),
            text: this.$t("setAttainedEloOnPiep.whenApproved.modal.text"),
          },
          DRAFT: {
            header: this.$t("setAttainedEloOnPiep.modal.header") + this.textId,
            text: this.$t("setAttainedEloOnPiep.modal.text"),
            includeConfirm: true,
            successCallback: this.showAttainmentRemarksModal,
          },
        },
        EXEMPTED: {
          APPROVED: {
            header: this.$t("setExemptedEloOnPiep.whenApproved.modal.header"),
            text: this.$t("setExemptedEloOnPiep.whenApproved.modal.text"),
          },
          DRAFT: {
            header: this.$t("setExemptedEloOnPiep.modal.header") + this.textId,
            text: this.$t("setExemptedEloOnPiep.modal.text"),
            includeConfirm: true,
            successCallback: this.showExemptionRemarksModal,
          },
        },
        ACTIVE: {
          APPROVED: {
            header: this.$t("setExemptedEloOnPiep.whenApproved.modal.header"),
            text: this.$t("setExemptedEloOnPiep.whenApproved.modal.text"),
          },
          DRAFT: {
            header: this.$t("setActiveEloOnPiep.modal.header") + this.textId,
            text: this.$t("setActiveEloOnPiep.modal.text"),
            includeConfirm: true,
            successCallback: this.commitEloStatus(toBeEloStatus),
          },
        },
      };

      this.$modal.show(
        modalOptions[toBeEloStatus][this.studentPlanStatus.planStatus]
      );
    },
    showAttainmentRemarksModal() {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
        programmeCodes: this.programmeCodes,
        studentId: parseInt(this.$route.params.studentId),
        selectedYear: parseInt(this.$route.params.year),
        componentType: "Attainment",
      };

      this.$modal.show({
        component: TerminateEloModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    showExemptionRemarksModal() {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
        programmeCodes: this.programmeCodes,
        studentId: parseInt(this.$route.params.studentId),
        selectedYear: parseInt(this.$route.params.year),
        componentType: "Exemption",
      };

      this.$modal.show({
        component: TerminateEloModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    commitEloStatus(toBeEloStatus) {
      // push to store
    },
  },
};
</script>

<style lang="scss" scoped>
.card-section {
  position: relative;
  border-bottom: 1px solid $gray;

  &.is-new-elo {
    border-left: 2px solid $success;
    //-webkit-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //-moz-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
  }

  &.terminated {
    background-color: $light-gray;
  }

  &.edit {
    cursor: pointer;
  }

  &.include-hover {
    &:hover {
      background-color: $bg-selected;
    }
  }

  &.added {
    background-color: $bg-selected;
    border-left: 2px solid $success;
  }

  .check-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}
.icon-image {
  font-size: $small-font-size / 1.1;
  color: $darker-gray;
}
.year-subtext {
  @extend %sans-serif-bold;

  padding: $global-padding / 5 $global-padding / 3;
  border-radius: 3px;
  font-size: $small-font-size / 1.2;
  color: $white;
  text-transform: capitalize;
  line-height: 1em;

  &[data-val="attained"] {
    background-color: $success;
  }
  &[data-val="exempted"] {
    background-color: $alert;
  }
}
.parent {
  position: relative;

  .aiep-more {
    right: 51%;

    @include breakpoint(small down) {
      right: 0;
    }
  }
}
.aiep-plan {
  border-left: 1px solid $gray;
  border-bottom: 1px solid $light-gray;

  .aiep-plan-column:last-child {
    border-top: 1px solid $gray;
  }

  .aiep-plan-column {
    position: relative;
    height: 50%;
    min-height: 2.5em;

    .aiep-label {
      position: absolute;
      transform: translateY(160%) rotateZ(-90deg);
      transform-origin: left bottom;
      font-size: 0.5em;
      color: $darker-gray;
      font-weight: 700;
      padding-bottom: 0.2em;
      text-transform: uppercase;
      pointer-events: none;
    }

    .cell:first-child {
      background-color: #111;
    }

    .cell {
      &:nth-child(odd) {
        &.blank {
          @extend %bg-pattern;
          background-color: $gray;
        }
        background-color: $lighter-gray;
      }
      &:nth-child(even) {
        &.blank {
          @extend %bg-pattern;
          background-color: $gray;
        }
        background-color: $light-gray;
      }

      .aiep-attainment {
        max-width: 100%;
        height: 100%;

        .prompt {
          padding: $global-padding * 0.3 $global-padding * 0.2;
          word-wrap: break-word;
          word-break: break-all;
        }

        .attainment-badge {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
