<template>
  <div class="maintenance-container">
    <img :src="logo" />

    <h1>Website currently under maintainence.</h1>
    <h1>Please hold on...</h1>
  </div>
</template>

<script>
import logo from "../../assets/logo.png";

export default {
  name: "Maintenance",
  data() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

h1 {
  margin: 0px;
}

img {
  padding-bottom: 2rem;
}

.maintenance-gif {
  width: 170px;
}
</style>
