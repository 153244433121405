<template>
  <div class="progress" :class="progressColor">
    <span class="progress-meter" :style="{ width: percentageString }">
      <p class="progress-meter-text">
        {{ percentage > 0 ? percentageString : "" }}
      </p>
    </span>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    numerator: {
      type: Number,
      default: 0,
    },
    denominator: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {
    percentage() {
      return this.denominator > 0
        ? (this.numerator / this.denominator) * 100
        : 0;
    },
    percentageString() {
      return this.percentage.toFixed() + "%";
    },
    progressColor() {
      if (this.percentage < 50) {
        return "alert";
      } else if (this.percentage < 70) {
        return "warning";
      } else if (this.percentage >= 70) {
        return "success";
      } else {
        return "";
      }
    },
  },
};
</script>

