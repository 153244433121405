<template>
  <div
    v-if="
      showTemplate === constants.FOUNDATION.MEDIA_QUERY.SMALL && smallAttributes
    "
    v-foundation:Sticky.destroy="stickyOptions"
    v-bind="smallAttributes"
    key="s"
    v-cloak
  >
    <slot></slot>
  </div>

  <div
    v-else-if="
      showTemplate === constants.FOUNDATION.MEDIA_QUERY.MEDIUM &&
      mediumAttributes
    "
    v-foundation:Sticky.destroy="stickyOptions"
    v-bind="mediumAttributes"
    key="m"
    v-cloak
  >
    <slot></slot>
  </div>

  <div
    v-else-if="
      showTemplate === constants.FOUNDATION.MEDIA_QUERY.LARGE && largeAttributes
    "
    v-foundation:Sticky.destroy="stickyOptions"
    v-bind="largeAttributes"
    key="l"
    v-cloak
  >
    <slot></slot>
  </div>

  <div
    v-else-if="showTemplate"
    v-foundation:Sticky.destroy="stickyOptions"
    v-bind="baseAttributes"
    key="default"
    v-cloak
  >
    <slot></slot>
  </div>
</template>

<script>
import stickyInitializer from "@/mixins/stickyInitializer";
import constants from "@/constants";

export default {
  name: "StickyWrapper",
  mixins: [stickyInitializer],
  props: {
    stickyOptions: Object,
    baseAttributes: Object,
    smallOverwrite: Object,
    mediumOverwrite: Object,
    largeOverwrite: Object,
  },
  data() {
    return {
      mediaQuery: null,
      showTemplate: null,
      smallAttributes: null,
      mediumAttributes: null,
      largeAttributes: null,
      constants: constants,
    };
  },
  watch: {
    "mediaQuery.current": function (val) {
      this.showTemplate = val;
    },
  },
  created() {
    this.mediaQuery = Foundation.MediaQuery;
    this.assignAttributes();
  },
  methods: {
    assignAttributes() {
      if (this.smallOverwrite) {
        this.smallAttributes = {
          ...this.baseAttributes,
          ...this.smallOverwrite,
        };
      }
      if (this.mediumOverwrite) {
        this.mediumAttributes = {
          ...this.baseAttributes,
          ...this.mediumOverwrite,
        };
      }
      if (this.largeOverwrite) {
        this.largeAttributes = {
          ...this.baseAttributes,
          ...this.largeOverwrite,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
