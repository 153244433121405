<template>
  <div class="programme-plan" v-cloak>
    <div v-if="!isEmpty(studentProgrammePlan)">
      <student-programme-plan-domain-list
        :programmePlanStatus="programmePlanStatus"
        :retain-elos="eloFilter()"
      ></student-programme-plan-domain-list>
    </div>
    <div v-if="isEmpty(studentProgrammePlan)">
      <create-student-programme-plan
        :studentId="studentId"
        :year="year"
      ></create-student-programme-plan>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import StudentProgrammePlanDomainList from "./StudentProgrammePlanDomainList";
import CreateStudentProgrammePlan from "./CreateStudentProgrammePlan";

export default {
  name: "StudentDetailsProgrammePlan",
  components: {
    StudentProgrammePlanDomainList,
    CreateStudentProgrammePlan,
  },
  props: ["studentId", "year"],
  computed: {
    studentProgrammePlan: function () {
      return this.$store.getters.studentPlan[this.studentId];
    },
    programmePlanStatus: function () {
      return this.$store.getters.studentProgrammePlanStatuses[
        this.$route.params.studentId
      ];
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.studentId, to.params.year);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadData(to.params.studentId, to.params.year);
    });
  },
  methods: {
    loadData(studentId, planYear) {
      this.$store
        .dispatch("getStudentPlan", {
          studentId: studentId,
          planYear: planYear,
        })
        .then((success) => {
          if (success) {
            this.$store.dispatch("getLatestAttainmentDetails", {
              studentId: studentId,
              planYear: planYear,
            });
          }
        });
      this.$store.dispatch("getStudentProgrammePlanStatus", {
        studentId: studentId,
        year: planYear,
      });
      this.$store.dispatch("getCurriculum");
    },
    isEmpty(details) {
      return _.isEmpty(details);
    },
    totalEloFilter: function (elo) {
      if (this.$route.query.filter) {
        return elo.eloStatus === this.$route.query.filter;
      }
      return true;
    },
    eloFilter: function () {
      let retainList =
        this.studentProgrammePlan &&
        this.studentProgrammePlan.studentProgrammeElos
          ? this.studentProgrammePlan.studentProgrammeElos
          : [];
      let filteredRetainList = _.filter(retainList, this.totalEloFilter);
      return (elos) => {
        let idlist = _.map(filteredRetainList, "elo.id");
        return _.filter(elos, function (elo) {
          return _.includes(idlist, elo.id);
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
