<template>
  <div
    id="class-group-edit-side-tray-students"
    class="class-group-edit-side-tray radius grid-y full-height"
    v-cloak
  >
    <div class="elo-side-tray-header cell shrink">
      <h6 class="margin-1">
        {{ isCoachingEngagement ? $t("classGroupEdit.assign.students.coaching", { title: "Engagement" }) : $t("classGroupEdit.assign.students", { title: "Class" }) }}
      </h6>
      <!-- Close button -->
      <button
        class="close-button color--medium-gray small"
        aria-label="Close menu"
        type="button"
        data-close
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="padding-left-1 padding-right-1 cell shrink">
      <side-tray-search
        v-if="readyToLoadSearchFields"
        :search-text-input-placeholder="searchTextInputPlaceholderValue"
        :search-term.sync="searchTerm"
        :initYear="classGroupFullDetails.year"
        :initSchoolId="classGroupFullDetails.schoolId"
        :initProgrammeName="classGroupFullDetails.programme.name"
        :initClassGroup="classGroupFullDetails.classGroup"
        @filter-results="setSelectedClassGroupIds"
        @show-unassigned="setShowUnassignedStudents"
      />
    </div>

    <class-group-edit-side-tray-list
      v-model="cardsToBeAdded"
      :list="filteredStudentResults"
      :existingClassGroupCardIds="existingClassGroupStudentIds"
      :no-results-text="isCoachingEngagement ? $t('classGroupEdit.sideTray.noStudentsFound.coaching') : $t('classGroupEdit.sideTray.noStudentsFound')"
      @list-changed="clearInputs"
      @toggle-side-dropdown="toggleSideDropdown"
      class="margin-top-1 class-group-edit-side-tray-list cell auto"
    />

    <class-group-edit-side-tray-students-side-dropdown
      class="side-dropdown-absolute"
      v-show="showSideDropdown"
      v-click-outside="hideSideDropdown"
      :style="sideDropdownPosition"
      :student-id="targetStudentId"
      @student-deleted="getUnassignedStudents()"
    />

    <div class="action-selector padding-left-1 padding-right-1 cell shrink">
      <div class="grid-x align-right">
        <button
          type="submit"
          class="align-right button primary"
          @click="addStudentsToClassGroupSubmit()"
        >
          {{ $t("sideTray.done") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SideTraySearch from "./SideTraySearch/SideTraySearch";
import ClassGroupEditSideTrayList from "./ClassGroupEditSideTrayList";
import ClassGroupEditSideTrayStudentsSideDropdown from "./ClassGroupEditSideTrayStudentsSideDropdown";
import _ from "lodash";

export default {
  name: "ClassGroupEditSideTrayStudents",
  components: {
    SideTraySearch,
    ClassGroupEditSideTrayList,
    ClassGroupEditSideTrayStudentsSideDropdown,
  },
  data() {
    return {
      searchTextInputPlaceholder: this.$t(
        "classGroupEdit.sideTray.search.students"
      ),
      showUnassignedStudents: false,
      selectedClassGroupIds: [],
      cardsToBeAdded: [], // TODO: store ids only
      searchTerm: "",
      showSideDropdown: false,
      sideDropdownPosition: {
        left: 0,
        top: 0,
      },
      targetStudentId: undefined,
    };
  },
  computed: {
    ...mapState({
      selectedClassGroupFullDetails: (state) =>
        state.classGroupSearch.selectedClassGroupFullDetails,
      unassignedStudents: (state) => state.classGroupSearch.unassignedStudents,
    }),
    classGroupFullDetails() {
      return this.selectedClassGroupFullDetails;
    },
    readyToLoadSearchFields() {
      return (
        this.classGroupFullDetails &&
        Object.keys(this.classGroupFullDetails).length > 0
      );
    },
    studentResults() {
      return this.$store.getters.studentsForClassGroupIds(
        this.selectedClassGroupIds
      );
    },
    flatStudentResults() {
      if (this.showUnassignedStudents) {
        return this.unassignedStudents;
      }

      let students = _.flatten(Object.values(this.studentResults));
      students = _.uniqBy(students, "id");
      return students;
    },
    filteredStudentResults() {
      return this.flatStudentResults.filter(
        (it) =>
          ~it.fullName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) ||
          ~it.displayName.toLowerCase().indexOf(this.searchTerm.toLowerCase())
      );
    },
    existingClassGroupStudentIds() {
      let existingStudents = this.classGroupFullDetails.classGroup
        ? this.classGroupFullDetails.classGroup.students
        : [];

      if (existingStudents.length > 0) {
        existingStudents = Object.keys(_.keyBy(existingStudents, "id"));
      }

      return existingStudents;
    },
    searchTextInputPlaceholderValue() {
      if (this.selectedClassGroupFullDetails.classGroup.coachingEngagement) {
        return this.$t("classGroupEdit.sideTray.search.students.coaching")
      } else {
        return this.$t("classGroupEdit.sideTray.search.students")
      }
    },
    isCoachingEngagement() {
      return this.classGroupFullDetails && 
             this.classGroupFullDetails.classGroup &&
             this.classGroupFullDetails.classGroup.coachingEngagement;
    },
  },
  methods: {
    ...mapActions([
      "addStudentsToClassGroup",
      "getClassGroup",
      "getClassGroupFullDetailsFor",
      "getUnassignedStudents",
      "getSideMenu",
    ]),
    setSelectedClassGroupIds(selectedClassGroupIds) {
      this.selectedClassGroupIds = selectedClassGroupIds;
    },
    setShowUnassignedStudents(toShow) {
      if (toShow) {
        this.getUnassignedStudents().then(() => {
          this.showUnassignedStudents = toShow;
        });
        return;
      }

      this.showUnassignedStudents = toShow;
    },
    clearInputs() {
      this.cardsToBeAdded = [];
    },
    addStudentsToClassGroupSubmit() {
      const { id: classId } = this.classGroupFullDetails.classGroup;
      const studentIds = this.cardsToBeAdded;

      this.addStudentsToClassGroup({ classId, studentIds }).then(() => {
        this.clearHandle();
        // TODO:
        // this.$toastr.success(this.$t('classGroupEdit.student.success.added', { studentName: this.studentDisplayName, className: this.classGroup.name }))

        setTimeout(() => {
          this.clearInputs();
          this.refreshSideMenu();
          this.fetchLatestClassGroupData();
          this.setShowUnassignedStudents(false); // alternative is to set this value in ClassGroupEditSideTraySearch component as prop so that they are synced
        }, 100);
      });
    },
    clearHandle() {
      this.$emit("clear-handle");
    },
    refreshSideMenu() {
      this.getSideMenu(this.$route.params.year);
    },
    fetchLatestClassGroupData() {
      this.getClassGroup(this.$route.params.classId);
      this.getClassGroupFullDetailsFor(this.$route.params.classId);
      this.getUnassignedStudents();
    },
    toggleSideDropdown(event) {
      if (!this.showSideDropdown) {
        this.targetStudentId = event.cardId;
        this.positionSideDropdown(event);
      } else {
        this.targetStudentId = undefined;
      }
      this.showSideDropdown = !this.showSideDropdown;
    },
    positionSideDropdown(event) {
      let { x, y } = event;
      const offsetX = 5;
      const offsetY = -25;

      this.sideDropdownPosition = {
        left: x + offsetX + "px",
        top: y + offsetY + "px",
      };
    },
    hideSideDropdown() {
      this.targetStudentId = undefined;
      this.showSideDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.class-group-edit-side-tray {
  // to enable descendants to position to it
  position: relative;

  .side-dropdown-absolute {
    position: absolute;
    z-index: 10;
  }
}
.action-selector {
  padding-top: $global-padding / 1.5;
  width: 100%;

  .button {
    margin-bottom: $global-margin / 1.5;
    font-size: $small-font-size * 1.05;
  }
}
</style>
