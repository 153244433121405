<template>
  <div :class="open ? 'fixed bottom-0 right-0 w-full' : 'fixed bottom-[10px] right-[10px]'">
    <!-- Header -->
    <div v-if="open" class="grid-x grid-padding-x align-middle bg-gray-300 radius overflow-hidden">
      <div class="cell small-9">
        <h4 class="text-small"> {{ this.profile.tenantName }} AI Bot</h4>
      </div>
      <div class="cell small-3 text-right">
        <button class="button small hollow" @click="open = false">Hide/Close</button>
      </div>
    </div>

    <!-- Messages -->
    <div v-if="open" class="messages" ref="messageBox">
      <ChatBubble
        v-for="message in messages"
        :key="message.id"
        :message="message"
        :user="getUser(message.userId)"
        :my-message="message.userId === me.id"
      />
      <ChatBubble v-for="user in usersTyping" :key="user.id" :user="user">
        <AppLoading />
      </ChatBubble>
    </div>

    <!-- Footer -->
    <div v-if="open" class="grid-x grid-padding-x">
      <div class="cell">
        <input
          ref="input"
          class="input"
          type="text"
          placeholder="Type your message"
          @keyup.enter="newMessageHandler($event)"
        />
      </div>
    </div>

    <!-- Chat Button when chat is closed -->
    <button v-if="!open" @click="open = true" class="button small hollow">
      <!-- <i class="fas fa-comments"></i> -->
      <font-awesome-icon icon="user-cog" size="lg"></font-awesome-icon>
      <!-- <p>Chat-Button</p> -->
    </button>
  </div>
</template>

<script>
// Import components and methods
import ChatBubble from "@/components/ChatBubble.vue";
import AppLoading from "@/components/AppLoading.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapGetters } from "vuex";

export default {
  name: "ChatBox",
  components: {
    ChatBubble,
    AppLoading,
    FontAwesomeIcon
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    me: {
      type: Object,
      required: true,
    },
    usersTyping: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    getUser(id) {
      return this.users.find((user) => user.id === id);
    },
    newMessageHandler(event) {
      const messageText = event.target.value;
      if (messageText.trim() !== '') {
        const newMessage = {
          id: Math.random().toString(36).substr(2, 9),
          userId: this.me.id,
          createdAt: new Date(),
          text: messageText,
        };
        this.$emit('newMessage', newMessage);
        event.target.value = '';
      }
    },
  },
};
</script>

<style scoped>
.text-small {
  font-size: 1rem;
}
</style>