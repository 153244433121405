import * as types from '../mutation-types';
import assessmentCriteriaSuggestionApi from '@/api/assessmentCriteriaSuggestion';

// initial state
const state = {
	suggestion: {}
};

// getters
const getters = {
	suggestion(state) {
		return state.suggestion;
	}
};

// actions
const actions = {
	getAssessmentCriteriaSuggestionForEloCode({ commit }, { eloCode }) {
		// TODO: make call to api
		assessmentCriteriaSuggestionApi.getAssessmentCriteriaSuggestionForEloCode(eloCode)
			.then((resp) => {
				if (resp.data) {
					commit(types.SET_ELO_ASSESSMENT_CRITERIA_SUGGESTION, resp.data.suggestion);
				}
			});
	}
};

// mutations
const mutations = {
	[types.SET_ELO_ASSESSMENT_CRITERIA_SUGGESTION](state, suggestion) {
		state.suggestion = suggestion;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
