<template>
  <span v-if="strands.length > 0" class="strands">
    {{ $tc("eloListCard.strands", strands.length) }}: {{ strands.join(", ") }}
  </span>
</template>

<script>
export default {
  name: "EloListCardStrands",
  props: {
    strands: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.strands {
  align-items: left;
  font-size: $small-font-size / 1.1;
}
</style>
