<!-- CoachingOutcomeGraph2.vue -->
<script>
import { Line, mixins } from "vue-chartjs";
import { mapGetters } from 'vuex';

export default {
  name: "ClientEngagementOutcomeChart2",
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    clientEngagement: Object,
    coachingOutcomes: {
        type: Array,
        required: true,
    },
    chartData: {
        type: Object,
        default: null,
    },
  },
  data() {
    return {
	  localChartData: this.chartData,
	  chartOptions: {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [
			{
				type: "time",
				time: {
				unit: "day",
				displayFormats: {
					day: "MMM D",
				},
				},
				scaleLabel: {
				display: true,
				labelString: "Date",
				},
				labels: this.sessionDates,
			},
			],
			yAxes: [
			{
				ticks: {
				beginAtZero: true,
				},
				scaleLabel: {
				display: true,
				labelString: "Outcome Assessment",
				},
			},
			],
		},
	  },
    };
  },
  watch: {
    clientEngagementAndActiveGoal: {
      deep: true,
      handler() {
		console.log("** watch: clientEngagementAndActiveGoal")
        this.generateChartData();
        this.$nextTick(() => {
          this.renderChart(this.localChartData, this.chartOptions);
        });
      },
    },
  },
  computed: {
	...mapGetters(['activeGoal', 'coachingEngagement', 'currentCoachingEngagement']),
	sessionDates() {
		return this.clientEngagement.sessions.map((session) =>
		session.startTime.toISOString()
		);
	},
	clientEngagementAndActiveGoal() {
		return {
		clientEngagement: this.clientEngagement,
		activeGoal: this.activeGoal
		};
	}
  },
  methods: {
    generateChartData() {
		console.log("** generateChartData")
		const datasets = [];

		this.coachingOutcomes.forEach((coachingOutcome) => {
			const outcomeName = coachingOutcome.outcomeName;

            // outcomeAssessment data
			const outcomeAssessmentDataset = {
                label: outcomeName + " (assessed in session)",
                data: [],
                borderColor: this.getRandomColor(),
                fill: false,
			};
            const combinedAssessmentData = coachingOutcome.coachingOutcomeAssessments
				.map((coachingOutcomeAssessment) => ({
					date: this.coachingSessionStartTime(coachingOutcomeAssessment.coachingSessionId),
					type: "coachingOutcome",
					data: coachingOutcomeAssessment,
			}));
            combinedAssessmentData.sort((a, b) => a.date - b.date);

            combinedAssessmentData.forEach((entry) => {
                console.log("Loop trough entries")
                if (entry.type === "coachingOutcome") {
                    outcomeAssessmentDataset.data.push({
                    x: entry.date,
                    y: entry.data.result,
                    });
                }
            });

            // outcomeSelfAssessment data
            const outcomeSelfAssessmentDataset = {
                label: outcomeName + " (self assessed)",
                data: [],
                borderColor: this.getRandomColor(),
                fill: false,
			};
            const combinedSelfAssessmentData = coachingOutcome.coachingOutcomeSelfAssessments
				.map((coachingOutcomeSelfAssessment) => ({
					date: this.coachingSessionStartTime(coachingOutcomeSelfAssessment.coachingSessionId),
					type: "coachingOutcome",
					data: coachingOutcomeSelfAssessment,
			}));
            combinedSelfAssessmentData.sort((a, b) => a.date - b.date);

            combinedSelfAssessmentData.forEach((entry) => {
                console.log("Loop trough entries")
                if (entry.type === "coachingOutcome") {
                    outcomeSelfAssessmentDataset.data.push({
                    x: entry.date,
                    y: entry.data.result,
                    });
                }
            });

            datasets.push(outcomeAssessmentDataset)
            datasets.push(outcomeSelfAssessmentDataset)
		});

		this.localChartData = {
      		datasets: datasets,
  		};

		console.log("Graphs: localChartData: ", this.localChartData)
	},
    getRandomColor() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    coachingSessionStartTime(sessionId){
        console.log("this.currentCoachingEngagement.coachingSessions", this.currentCoachingEngagement.coachingSessions)
        for (const session of this.currentCoachingEngagement.coachingSessions) {
        // this.currentCoachingEngagement.coachingSessions.forEach((session) => {
            console.log("Loop trough sessions, id:", session.id, (session.id === sessionId))
			if (session.id === sessionId) {
                console.log("Matchin session date: ", session.startTime, session.startTime)//.toISOString())
                return session.startTime// .toISOString()
			}
		// });
        }
        console.log("Error finding matching session: ", sessionId)
        return new Date('1970-01-01T00:00').toISOString()
    }
  },
  mounted() {
	console.log("** graphs: mounted")
    this.generateChartData();
    this.$nextTick(() => {
      this.renderChart(this.localChartData, this.chartOptions);
    });
  },
}
</script>