import dashboardApi from '@/api/dashboard';
// import * as types from '../mutation-types'
// import _ from 'lodash'

const state = {
	dashboardList: []
};

const getters = {
	dashboardList: state => state.dashboardList
};

const mutations = {
	SET_DASHBOARD_LIST(state, dataList) {
		state.dashboardList = dataList;
	}
};

const actions = {
	// { commit }
	async getDashboardList({ commit }) {
		const { data } = await dashboardApi.getDashboardList();

		commit('SET_DASHBOARD_LIST', data);

		// let schools = _.keyBy(data, 'id')
		// commit('SET_SCHOOL_IDS', Object.keys(schools))

		// const programmes = _.mapValues(schools, 'programmes')
		// commit('SET_SCHOOL_PROGRAMMES', programmes)

		// schools = _.transform(schools, (result, school, schoolId) => {
		// 	const flatSchoolObj = _.omit(school, 'programmes')
		// 	result[schoolId] = flatSchoolObj
		// }, {})

		// commit('SET_SCHOOLS', schools)
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
