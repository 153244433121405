<template>
  <div
    class="attainment-badge grid-x grid-y align-center align-middle"
    :class="colorClass"
    v-cloak
  >
    {{ level }}
  </div>
</template>

<script>
export default {
  name: "AttainmentBadge",
  props: {
    level: {
      type: String,
      required: true,
    },
  },
  computed: {
    colorClass: function () {
      let prefix = "bgcolor--attainment-";
      let className = "quaternary";
      switch (this.level) {
        case "C":
          className = "tertiary";
          break;
        case "R":
          className = "secondary";
          break;
        case "A":
          className = "primary";
          break;
      }
      return prefix + className;
    },
  },
};
</script>

<style lang="scss" scoped>
.attainment-badge {
  @extend .sans-serif-bold;
  $badge-size: rem-calc(30);

  width: $badge-size;
  height: $badge-size;
  border-radius: 50%;
  color: $white;

  .programme-plan & {
    width: $badge-size / 1.3;
    height: $badge-size / 1.3;
    font-size: $small-font-size / 1.1;
  }

  .edit-elo-modal-content-body & {
    width: $badge-size / 1.3;
    height: $badge-size / 1.3;
  }

  .lesson-record-wrapper & {
    width: rem-calc(22);
    height: rem-calc(22);
    font-size: $small-font-size;
    line-height: 1em;
  }

  @media print {
    width: 0;
    height: 0;
    background-color: transparent;
    font-size: $small-font-size / 1.2;
  }
}
</style>

