<template>
  <div class="grid-y elo-list-card" v-cloak>
    <div class="grid-x">
      <elo-list-card-details
        class="cell auto small"
        :elo-code="lessonElo.elo.eloCode"
        :elo-name="lessonElo.elo.eloName"
        :programme-codes="lessonElo.elo.programmeCodes"
        :show-details="lessonElo.elo.isDeveloped"
        :link="lessonElo.elo.link"
      >
      </elo-list-card-details>
      <div class="delete-icon no-print">
        <button class="button clear color--gray" @click="deleteItemConfirm">
          <font-awesome-icon
            :icon="['far', 'trash-alt']"
            size="1x"
          ></font-awesome-icon>
        </button>
      </div>
    </div>

    <div class="grid-x margin-top-1">
      <div
        class="image-wrapper"
        v-for="student in lessonElo.students"
        :key="student.studentId"
      >
        <profile-image
          :image-title="student.fullName"
          :image="student.image"
          :image-alt="student.displayName"
          :image-size="35"
        >
          <condition-type-badge
            slot="condition-type"
            :condition-types="student.conditionTypes"
            :with-text-label="false"
            :size="15"
          >
          </condition-type-badge>
        </profile-image>
        <div class="student-name text-center truncate">
          {{ student.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EloListCardDetails from "./EloListCardDetails";
import ProfileImage from "./ProfileImage.vue";
import ConditionTypeBadge from "./ConditionTypeBadge";

export default {
  name: "LessonPlanEloCard",
  components: {
    FontAwesomeIcon,
    EloListCardDetails,
    ProfileImage,
    ConditionTypeBadge,
  },
  props: {
    lessonElo: Object,
  },
  methods: {
    deleteItemConfirm: function () {
      this.$modal.show({
        header: this.$t("lessonPlanEloListCard.delete.modalTitle"),
        text: this.$t("lessonPlanEloListCard.delete.modalText"),
        includeConfirm: true,
        successCallback: this.deleteItem,
      });
    },
    deleteItem: function () {
      this.$store.dispatch("removeEloFromLessonPlan", {
        lessonId: this.$route.params.lessonId,
        eloCode: this.lessonElo.elo.eloCode,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-icon {
  text-align: right;
}
.elo-list-card {
  height: 100%;
  border: 1px solid $medium-gray;
  border-radius: $global-radius;
  padding: $global-padding / 1.7;
  background-color: $white;
  box-shadow: 0px 1px 0px 0px $medium-gray;
}
.image-wrapper {
  margin-left: 0.2em;
  margin-right: 0.2em;
  width: rem-calc(40px);
}
.student-name {
  font-size: $small-font-size / 1.3;
}
</style>
