import Vue from 'vue'

export default {
	getAssessmentCriteriaSuggestionForEloCode (eloCode) {
		return Vue.http.get('assessmentCriteriaSuggestion', {
			params: {
				eloCode: eloCode
			}
		})
	}
}
