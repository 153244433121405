import moment from "moment";
import * as types from "../mutation-types";
import authenticationApi from "@/api/authentication";
import tenantApi from "@/api/tenant";
import { resetState } from "@/store";

// initial state
const state = {
	profile: {}
};

// getters
const getters = {
	profile(state) {
		return state.profile;
	},
	isAuthenticated(state) {
		return Object.keys(state.profile).length > 0;
	}
};

// actions
const actions = {
	login({ dispatch }, { emailId, authToken }) {
		return authenticationApi.login(emailId, authToken).then(resp => {
            dispatch("setProfile", resp.data);
            if (!resp.data.roles.includes('ROLE_HYPER')) {
                dispatch("getTenantName", resp.data);
            }
		});
	},
	getTenantName({ commit }, profile) {
		profile.tenantName = "";
		tenantApi.getTenantDetails(profile.tenantId).then(resp => {
			profile.tenantName = resp.data.name;
		});
		return commit(types.SET_PROFILE, profile);
	},
	refreshToken({ state, dispatch }) {
		return authenticationApi.refreshToken(state.profile.refresh_token).then(resp => {
			dispatch('setProfile', resp.data);
		});
	},
	logout ({ dispatch }) {
		resetState()
		window.location.reload() // reload so that login page will refresh and display the login button
		return dispatch('setProfile', {})
	},
	setProfile({ commit }, profile) {
		if (profile.expires_in) {
			profile.expire = moment()
				.add(profile.expires_in, "seconds")
				.format();
			console.log("Profile set expiry: ");
			console.log(profile.expire);
		}
		return commit(types.SET_PROFILE, profile);
	}
};

// mutations
const mutations = {
	[types.SET_PROFILE](state, profile) {
		state.profile = profile;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
