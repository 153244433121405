import * as types from '../mutation-types';
import constants from '@/constants';
import classGroupSearchApi from '@/api/classGroupSearch';
import _ from 'lodash';

const state = {
	selectedClassGroupFullDetails: {},
	classGroupIds: [],
	classGroups: {},
	teachers: {},
	students: {},
	unassignedStudents: [],
	unassignedTeachers: []
};

const getters = {
	teachersForClassGroupIds: (state) => (classGroupIds) => {
		return _.pick(state.teachers, classGroupIds);
	},
	studentsForClassGroupIds: (state) => (classGroupIds) => {
		return _.pick(state.students, classGroupIds);
	},
	unassignedStudentForStudentId: (state) => (studentId) => {
		return state.unassignedStudents.find((student) => {
			return studentId === student.id;
		});
	}
};

const mutations = {
	[types.SET_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS](state, classGroupFullDetails) {
		state.selectedClassGroupFullDetails = classGroupFullDetails;
	},
	[types.CLEAR_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS](state) {
		state.selectedClassGroupFullDetails = {};
	},
	[types.SET_CLASSGROUPSEARCH_CLASS_GROUP_IDS](state, classGroupIds) {
		state.classGroupIds = classGroupIds;
	},
	[types.SET_CLASSGROUPSEARCH_CLASS_GROUPS](state, classGroups) {
		state.classGroups = classGroups;
	},
	[types.SET_CLASSGROUPSEARCH_TEACHERS](state, teachers) {
		state.teachers = teachers;
	},
	[types.SET_CLASSGROUPSEARCH_STUDENTS](state, students) {
		state.students = students;
	},
	[types.SET_CLASSGROUPSEARCH_UNASSIGNED_STUDENTS](state, unassignedStudents) {
		state.unassignedStudents = unassignedStudents;
	},
	[types.SET_CLASSGROUPSEARCH_UNASSIGNED_TEACHERS](state, unassignedTeachers) {
		state.unassignedTeachers = unassignedTeachers;
	},
	[types.CLEAR_CLASSGROUPSEARCH_RESULTS](state) {
		state.classGroupIds = [];
		state.classGroups = {};
		state.teachers = {};
		state.students = {};
		state.unassignedStudents = [];
	}
};

const actions = {
	clearClassGroupFullDetails({ commit }) {
		commit('CLEAR_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS');
	},
	clearClassGroupSearchResults({ commit }) {
		commit('CLEAR_CLASSGROUPSEARCH_RESULTS');
	},
	async getClassGroupFullDetailsFor({ commit, dispatch }, classId) {
		dispatch('clearClassGroupFullDetails');
		const { status, data } = await classGroupSearchApi.getClassGroupFullDetailsFor(classId);

		if (status === constants.RESPONSE_STATUS.OK && data) {
			commit('SET_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS', data.classGroup);
		}
	},
	async getClassGroupsForParams({ commit }, params) {
		const { status, data } = await classGroupSearchApi.getClassGroupsForParams(params);

		if (status === constants.RESPONSE_STATUS.OK && data) {
			let { classGroups } = data;

			// TODO: write a reusable utility method
			classGroups = _.keyBy(classGroups, 'id');
			const classGroupIds = Object.keys(classGroups);
			commit('SET_CLASSGROUPSEARCH_CLASS_GROUP_IDS', classGroupIds);

			const teachers = _.mapValues(classGroups, 'teachers');
			commit('SET_CLASSGROUPSEARCH_TEACHERS', teachers);

			const students = _.mapValues(classGroups, 'students');
			commit('SET_CLASSGROUPSEARCH_STUDENTS', students);

			const dataToOmit = ['teachers', 'students'];
			classGroups = _.transform(classGroups, (result, classGroup, classGroupId) => {
				const flatClassGroupObj = _.omit(classGroup, dataToOmit);
				result[classGroupId] = flatClassGroupObj;  // eslint-disable-line
			}, {});

			commit('SET_CLASSGROUPSEARCH_CLASS_GROUPS', classGroups);
		}
	},
	async getUnassignedStudents({ commit }) {
		const { status, data } = await classGroupSearchApi.getUnassignedStudents();

		if (status === constants.RESPONSE_STATUS.OK && data) {
			let { unassignedStudents } = data;

			for (let student of unassignedStudents) {
				student.isDeletable = true;
			}

			commit('SET_CLASSGROUPSEARCH_UNASSIGNED_STUDENTS', unassignedStudents);
		}
	},
	async getUnassignedTeachers({ commit }) {
		const { status, data } = await classGroupSearchApi.getUnassignedTeachers();

		if (status === constants.RESPONSE_STATUS.OK && data) {
			let { unassignedTeachers } = data;
			commit('SET_CLASSGROUPSEARCH_UNASSIGNED_TEACHERS', unassignedTeachers);
		}
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
