<template>
	<div class="goals-wrapper">
		<!-- <p>CurrentCoachingEngagement: {{ currentCoachingEngagement }}</p> -->
		<h3 class="goals-header">
			<p>Coaching Goals</p>
			<button class="add-button" @click="addingGoal=true">
				<font-awesome-icon icon="plus" size="1x"></font-awesome-icon>
				Add Goal
			</button>
		</h3>
		<div v-if="addingGoal" class="add-goal-container">
			<input 
				v-model="newGoalName" 
				@keyup.enter="confirmAddGoal"
				@keyup.esc="cancelAddGoal" 
				placeholder="Type goal name and press Enter"
			>
			<button class="button primary margin-top-1" @click="confirmAddGoal">Save</button>
			<button class="button primary margin-top-1" @click="cancelAddGoal">Cancel</button>

		</div>
    	<div
			v-for="(goal, index) in goals" 
			:key="index"
			class="goal-card"
			:class="{ 'active': index + 1 === activeGoal.index }" 
      		@click="setActiveGoal({ index: index + 1, goal })"
		>
      		<div class="goal-number">
        		{{ index + 1 }}
      		</div>
	  		<!-- <p class="goal-text">{{ goal.name }}</p> -->

			<p v-if="editMode !== goal.id" class="goal-text" @click.stop="editGoal(goal.id, goal.name)">
				{{ goal.name }}
			</p>
			<div v-else class="edit-goal-container">
				<input
					v-model="editedName"
					@keyup.enter="saveEditGoal(goal.id)"
					@keyup.esc="cancelEditGoal"
					@blur="saveEditGoal(goal.id)"
				/>
				<button class="button primary margin-top-1" @click="saveEditGoal(goal.id)">Save</button>
				<button class="button primary margin-top-1" @click="cancelEditGoal">Cancel</button>
				<button v-if="editMode === goal.id" @click.stop="saveEditGoal(goal.id)">
					<i class="edit-icon" />
				</button>
			</div>
		</div>
	</div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import _ from "lodash";

export default {
	name: "CoachingEngagementGoal",
	components: {
		FontAwesomeIcon,
	},
	props: ["goals"],
	data() {
		return {
			editMode: false,
			addingGoal: false,
			newGoalName: '',
		};
	},
	computed: {
		...mapGetters([
			'activeGoal',
			'currentCoachingEngagement'
		])
	},
  	methods: { 
		...mapActions([
			"setActiveGoal",
			"createCoachingGoal",
			"updateCoachingGoal"
		]),
		async confirmAddGoal() {
			if (this.newGoalName.trim() !== '') {
				// check the vuex store call to add goal
				await this.createCoachingGoal({ studentCoachingEngagementId: this.currentCoachingEngagement.id, name: this.newGoalName });
				this.newGoalName = '';
			}
			this.addingGoal = false;
		},
		cancelAddGoal() {
			this.addingGoal = false;
			this.newGoalName = '';
		},
		editGoal(id, name) {
			this.editMode = id;
			this.editedName = name;
		},
		async saveEditGoal(id) {
			if (this.editMode && this.editedName.trim() !== '') {
				await this.updateCoachingGoal({id, studentCoachingEngagementId: this.currentCoachingEngagement.id, name: this.editedName});
			}
			this.editMode = null;
		},
		cancelEditGoal() {
			this.editMode = null;
		}
	},
	created() {
		console.log("***CoachingEngagementGoal: ")
		console.log("***this.goals: ")
		console.log(this.goals)
		if (!this.activeGoal && this.goals.length > 0) {
			let lastIndex = this.goals.length - 1;
			this.setActiveGoal({ index: lastIndex + 1, goal: this.goals[lastIndex] });
		}
	}
  };
</script>
  
  <style lang="scss" scoped>
  // @import 'src/styles/_settings.scss';
  
  .goal-wrapper {
	position: relative;
	padding: $global-padding;
	border: 1px solid $light-gray;
	border-radius: $global-radius;
	margin-top: $global-margin;
  }
  
  .goal-card {
	cursor: pointer;
	position: relative;
	padding: $global-padding;
	border: 1px solid $light-gray;
	border-radius: $global-radius;
	margin-bottom: $global-margin;
	&.active {
		background: $bg-selected;
    	// border-color: $brand-quaternary;  // change this to whatever highlight style you want
  	}

	background-color: $lighter-gray;

	.button {
	  	width: 100%;
  	}
  }

  .goal-number {
	position: absolute;
	top: -10px; 
	left: -10px;
	width: 30px;
	height: 30px;
	background: $brand-quaternary;
	color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
  }

  .goals-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $title-color;
  color: $white;
  padding: 0.5em 1em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 1.25em;
  font-weight: bold;

  p {
    margin: 0; // Add this line
    padding: 0.5em; // Add this line
  }
  .add-button {
	color: $white;
	font-size: 1rem;

	&:hover {
			background-color: $bg-selected;
	}
  }
}

  .goal-title {
	margin-bottom: 0.5em;
	color: $dark-gray;
	font-size: 1.25em;
	font-weight: bold;

	background-color: $lighter-gray;

	.button {
      width: 100%;
  	}
  }
  
  .goal-text {
	margin-bottom: 0;
	color: $brand-quaternary;
	font-size: 1em;
  }

  .add-goal-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $white; // or any other color you want

	input {
		flex-grow: 1;
		//margin-right: 1em; // add some space between input and buttons
	}
	
	.button {
		width: auto; // reset the button width to auto for better spacing
		margin-left: 1em;
	}
  }

  .edit-goal-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // background-color: $white; // or any other color you want

	input {
		flex-grow: 1;
		//margin-right: 1em; // add some space between input and buttons
	}
	
	.button {
		width: auto; // reset the button width to auto for better spacing
		margin-left: 1em;
	}
  }
  </style>
  