<!-- CoachingEngagementLearningPointCard.vue -->
<template>
	<div class="learning-point-card">
		<div v-if="!editMode" @click="editLearningPoint" class="description">
			<p>
				<span class="learning-point-date">{{ formatDate(learningPoint.updatedDate) }}:</span>
				<span>{{ learningPoint.description }}</span>
			</p>
		</div>
		<div v-else class="edit-learning-point-container">
			<input
				v-model="editedDescription"
				@keyup.enter="saveEditLearningPoint"
				@keyup.esc="cancelEditLearningPoint"
				@blur="saveEditLearningPoint"
			/>
			<button class="button primary margin-top-1" @click="saveEditLearningPoint">Save</button>
			<button class="button primary margin-top-1" @click="cancelEditLearningPoint">Cancel</button>
		</div>
	</div>
</template>
  
 <script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
	name: "CoachingEngagementLearningPointCard",
	props: ["learningPoint"],
	data() {
	  return {
		editMode: false,
		editedDescription: '',
		isSaving: false,
	  };
	},
	computed: {
		...mapGetters({
			currentCoachingEngagement: 'currentCoachingEngagement',
		})
	},
	methods: {
	  ...mapActions([
		"createCoachingLearningPoint",
		"updateCoachingLearningPoint"
	  ]),
	  formatDate(dateString) {
		if (!dateString) {
			return 'N/A';
		}
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", {
		  year: "numeric",
		  month: "short",
		  day: "numeric",
		});
	  },
	  editLearningPoint() {
		this.editMode = true;
		this.editedDescription = this.learningPoint.description;
	  },
	  async saveEditLearningPoint() {
		this.isSaving = true;
		if (this.editedDescription.trim() !== '') {
			await this.updateCoachingLearningPoint({ id: this.learningPoint.id, studentCoachingEngagementId: this.currentCoachingEngagement.id, description: this.editedDescription.trim()})
		}
		this.editMode = false;
		this.isSaving = false;
	  },
	  cancelEditLearningPoint() {
		this.editMode = false;
	  },
	},
  };
 </script>
  
  <style lang="scss" scoped>
	$learning-point-card-padding: 1em;
	$learning-point-card-border-radius: 10px;
  
	.learning-point-card {
	  position: relative;
	  padding: $learning-point-card-padding;
	  background-color: $white;
	  border-radius: $learning-point-card-border-radius;
	  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	  margin-bottom: 1em;
	}
  
	.learning-point-date {
	  margin-right: 0.5em;
	  font-size: 0.875em;
	  color: $secondary;
	}

	.description{
		cursor: pointer;
	}

	.edit-learning-point-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		// background-color: $white; // or any other color you want

		input {
			flex-grow: 1;
			//margin-right: 1em; // add some space between input and buttons
		}
		
		.button {
			width: auto; // reset the button width to auto for better spacing
			margin-left: 1em;
		}
	}
  </style>
  