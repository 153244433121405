<template>
  <div class="dashboard" v-cloak>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let targetClassId =
        to.params.classId ||
        (vm.$store.getters.filteredClasses.length > 0 &&
          vm.$store.getters.filteredClasses[0].id) ||
        undefined;
      let targetYear =
        to.params.year || vm.$store.getters.selectedYearFilter || undefined;
      let target =
        vm.$route.name === "Dashboard"
          ? vm.$store.getters.allowedFunctions[0].target
          : undefined;

      vm.$router.replace({
        name: target,
        params: {
          classId: targetClassId,
          year: targetYear,
        },
      });
      vm.getClassGroup(targetClassId);
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    let target =
      this.$route.name === "Dashboard"
        ? this.$store.getters.allowedFunctions[0].target
        : undefined;
    this.$router.replace({
      name: target,
      params: {
        classId: to.params.classId || undefined,
        year: to.params.year || undefined,
      },
    });

    if (!to.params.classId) {
      this.$store.dispatch("clearClassGroup");
    }

    this.getClassGroup(to.params.classId);
  },
  methods: {
    async getClassGroup(classId) {
      if (!classId) return;
      this.$store.dispatch("getClassGroup", classId);
      if (!this.$store.getters.filteredClasses) return;
      let clazz = this.$store.getters.filteredClasses.find(
        (it) => it.id === classId
      );
      this.$store.dispatch("setSelectedItemClass", clazz && clazz.identifier);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
