import Vue from 'vue'
import jQuery from '../../node_modules/jquery/dist/jquery.js'

const files = require.context('.', false, /\.js$/)
files.keys().forEach((key) => {
	if (key === './index.js') return
	Vue.directive([key.replace(/(\.\/|\.js)/g, '')], files(key).default)
})

jQuery.event.special.touchstart = {
	setup: function (_, ns, handle) {
		if (ns.includes('noPreventDefault')) {
			this.addEventListener('touchstart', handle, { passive: false })
		} else {
			this.addEventListener('touchstart', handle, { passive: true })
		}
	}
}
jQuery.event.special.touchmove = {
	setup: function (_, ns, handle) {
		if (ns.includes('noPreventDefault')) {
			this.addEventListener('touchmove', handle, { passive: false })
		} else {
			this.addEventListener('touchmove', handle, { passive: true })
		}
	}
}
