<template>
  <router-link :to="routeObject" v-cloak>
    <base-class-group-list-card
      :image="image"
      :condition-types="conditionTypes"
      :text-content="textContent"
      :profile-name="profileName"
      :card-id="studentId"
      :class-id="classId"
    >
      <attainment-score v-if="!isCoachingEngagement"
        slot="right"
        :score="getStudentAttainmentScore(classId, studentId)"
        :annualPlanStatus="
          getStudentPlanStatus(classId, studentId).annualPlanStatus
        "
      >
      </attainment-score>
    </base-class-group-list-card>
  </router-link>
</template>

<script>
import BaseClassGroupListCard from "@/components/BaseClassGroupListCard";
import studentPlanStatus from "@/mixins/studentPlanStatus";
import AttainmentScore from "@/components/AttainmentScore";
import studentAttainmentScore from "@/mixins/studentAttainmentScore";

export default {
  name: "ClassGroupListCard",
  components: {
    BaseClassGroupListCard,
    AttainmentScore,
  },
  mixins: [studentPlanStatus, studentAttainmentScore],
  props: {
    image: String,
    conditionTypes: Array,
    textContent: String,
    profileName: String,
    studentId: String,
    classId: String,
    studentCoachingEngagementId: Number,
    //resources: String,
    //engagementDate: String,
    //completed: Boolean,
  },
	computed: {
	  isCoachingEngagement: function () {
      console.log("ClassGroupListCard: studentCoahingEngagement check: classId: ", this.classId)
      console.log("ClassGroupListCard: studentCoahingEngagement check: coachingEngagementId: ", this.coachingEngagementId)
      if (this.studentCoachingEngagementId) {
        return true
      }
      return false;
	  },
    routeObject() {
      console.log("ClassGroupListCard: routeObject: studentCoachingEngagementId:", this.studentCoachingEngagementId)
      if (this.studentCoachingEngagementId) {
        console.log("ClassGroupListCard: CoachingEngagement")
        return {
          name: 'CoachingEngagement',
          params: {
            year: this.$store.getters.selectedYearFilter,
            studentId: this.studentId,
            classId: this.classId,
            studentCoachingEngagementId: this.studentCoachingEngagementId,
            // resources: this.resources,
            // engagementDate: this.engagementDate,
            // completed: this.completed,
          },
        };
      } else {
        console.log("ClassGroupListCard: AIEP teaching engagement")
        return {
          name: 'Aiep',
          params: {
            year: this.$store.getters.selectedYearFilter,
            studentId: this.studentId,
          },
        };
      }
    },
  }
};
</script>

