<template>
  <div ref="domainCard" class="domain-list-card card" v-cloak>
    <a ref="domainHeader" class="domain-list-header card-divider padding-0">
      <slot name="stickyWrapper">
        <div class="domain-list-wrapper grid-x align-middle padding-1">
          <div class="cell auto h-pos-relative">
            <div class="domain-list-title grid-x align-middle">
              <h6 class="domain-title margin-bottom-0 truncate">
                {{ textName | labelize }}
              </h6>
            </div>
          </div>
          <div class="cell shrink"></div>
        </div>
        <MoreDropdown
          :options="dropdownOptions"
          :identifier="`domain-${domainId}`"
        >
        </MoreDropdown>
      </slot>
    </a>
    <div ref="content" class="domain-list-body">
      <CurriculumInterfaceSubDomainListCard
        v-for="subdomain in details"
        :key="subdomain.id"
        :id="subdomain.id"
        :sub-domain="subdomain"
        :domainName="textName"
        :domainCode="textId"
      >
        <CurriculumInterfaceTloListCard
          v-for="tlo in subdomain.tlos"
          :key="tlo.id"
          :domainName="textName"
          :subDomainName="subdomain.subDomainName"
          :subDomainCode="subdomain.subDomainId"
          :tlo="tlo"
          :tloName="tlo.tloName"
          :tloCode="tlo.tloCode"
          :id="tlo.id"
          :tloId="tlo.tloId"
          :domainCode="textId"
        >
          <slot name="injectEloListCard" :tlo="tlo">
            <CurriculumInterfaceEloListCard
              class="card-section edit include-hover cell auto"
              v-for="elo in tlo.elos"
              :key="elo.id"
              :id="elo.id"
              :text-id="elo.eloCode"
              :eloId="elo.eloId"
              :eloCode="elo.eloCode"
              :text-name="elo.eloName"
              :programmeCodes="elo.programmeCodes"
              :eloStatus="elo.active"
              :eloLink="elo.link"
              :domainName="textName"
              :domainCode="textId"
              :subDomainName="subdomain.subDomainName"
              :subDomainCode="subdomain.subDomainId"
              :tloName="tlo.tloName"
              :tloCode="tlo.tloCode"
            >
            </CurriculumInterfaceEloListCard>
          </slot>
        </CurriculumInterfaceTloListCard>
      </CurriculumInterfaceSubDomainListCard>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants";
import CurriculumInterfaceSubDomainListCard from "./CurriculumInterfaceSubDomainListCard";
import CurriculumInterfaceTloListCard from "./CurriculumInterfaceTloListCard";
import CurriculumInterfaceEloListCard from "./CurriculumInterfaceEloListCard";
import CurriculumInterfaceDomainEditModal from "@/views/CurriculumInterface/CurriculumInterfaceDomainEditModal";
import CurriculumInterfaceSubDomainAddModal from "@/views/CurriculumInterface/CurriculumInterfaceSubDomainAddModal";

import MoreDropdown from "./MoreDropdown";
export default {
  name: "CurriculumInterfaceDomainListCard",
  /* eslint-disable vue/no-unused-components */
  components: {
    CurriculumInterfaceSubDomainListCard,
    CurriculumInterfaceTloListCard,
    CurriculumInterfaceEloListCard,
    CurriculumInterfaceDomainEditModal,
    CurriculumInterfaceSubDomainAddModal,
    MoreDropdown,
    constants,
  },
  /* eslint-enable vue/no-unused-components */
  props: {
    domainId: Number,
    textId: String,
    textName: String,
    details: Array,
    edit: Boolean,
  },
  data() {
    return {
      fromData: {
        id: "DOMAIN-" + this.domainId,
      },
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
    dropdownOptions: function () {
      let dropdownOptions = {
        Add: {
          label: "domainListCard.add",
          method: this.showAddModal,
        },
        Edit: {
          label: "domainListCard.edit",
          method: this.showEditModal,
        },
        Delete: {
          label: "domainListCard.delete",
          method: this.curriculumInterfaceDomainDelete,
        },
      };

      let finalDropdownOptions = [];

      finalDropdownOptions.push(
        dropdownOptions["Add"],
        dropdownOptions["Edit"],
        dropdownOptions["Delete"]
      );

      return finalDropdownOptions;
    },
  },
  methods: {
    ...mapActions(["getCurriculum", "forceGetCurriculum"]),
    showEditModal() {
      let componentPropsEdit = {
        id: this.domainId,
        domainName: this.textName,
        domainId: this.textId,
      };
      this.$modal.show({
        component: CurriculumInterfaceDomainEditModal,
        componentProps: componentPropsEdit,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    showAddModal() {
      let componentProps = {
        subDomainName: "",
        subDomainId: "",
        domainId: this.domainId,
        domainName: this.textName,
        domainCode: this.textId,
      };
      this.$modal.show({
        component: CurriculumInterfaceSubDomainAddModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    curriculumInterfaceDomainDelete() {
      let deletedDomain = this.textName;
      let modalParams = {
        header: this.$t("elo.delete.modalTitle", { deletedDomain }),
        text: deletedDomain,
        includeConfirm: true,
        successCallback: this.doDeleteDomain,
      };

      this.$modal.show(modalParams);
    },
    async doDeleteDomain() {
      var curriculumInterface = this.textName;
      try {
        const { status, data } = await this.$store.dispatch(
          "curriculumInterfaceDomainDelete",
          this.fromData
        );
        if (status !== constants.RESPONSE_STATUS.OK && data) {
          return this.$toastr.error(data && data.message);
        }
        this.forceGetCurriculum();
        this.$toastr.success(
          this.$t("curriculumInterfaceModal.success.deleted", {
            curriculumInterface,
          })
        );
        this.$modal.closeAll();
      } catch (resp) {
        this.$toastr.error(resp.data && resp.data.message);
      }
    },
  },
  mounted() {
    // Can be refactored as methods. On the template use v-bind:class="method()" to return the appropriate classes, attributes. However, other parent components do not use this other than for the accordion.
    if (this.componentType === "EloSideTray") {
      this.$refs.domainCard &&
        this.$refs.domainCard.classList &&
        this.$refs.domainCard.classList.add("domain-list-card");
    }
  },
};
</script>

<style lang="scss" scoped>
.domain-list-card.card {
  overflow: visible;
}
.domain-list-header {
  background-color: $brand-primary;
  border-top: none;
  border-bottom: 1px solid smart-scale($brand-primary, 35%, 10%);
  font-size: 1rem;
  color: $white;
  height: 3em;
  min-height: 3em;
  position: relative;

  .elo-side-tray.elo-piep & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .elo-side-tray.elo-prog & {
    background-color: $darker-gray;
    border-bottom-color: smart-scale($darker-gray, 30%, 20%);
  }

  .elo-side-tray & {
    min-height: 2.7em;
  }

  .programme-plan & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .annual-plan-top-wrapper + .domain-list .accordion-item:first-child & {
    border-radius: 0;
  }
}
.domain-list-wrapper {
  padding: $global-padding / 1.5 $global-padding;
  width: 100%;
}
.domain-list-title {
  @extend .sans-serif-bold;
  margin-bottom: 0;
  padding-left: $global-padding * 1.1;
  white-space: nowrap;
  margin-left: -15px;

  .elo-side-tray & {
    padding-left: 0;
  }

  .elo-side-tray &::after {
    @extend .sans-serif-regular;
    position: absolute;
    top: 0.6em;
    right: 0;
    content: "A-IEP";
    font-size: 0.6em;
    letter-spacing: 1px;
    line-height: 1em;
  }

  .elo-aiep &::after {
    content: "A-IEP";
  }

  .elo-piep &::after {
    content: "P-IEP";
  }

  .elo-prog &::after {
    content: "PROG";
  }
}
.domain-title {
  width: 68%;
  flex-grow: 1;

  .elo-side-tray & {
    width: 55%;
    font-size: $global-font-size;
    flex-grow: 0;
  }

  @include breakpoint(medium) {
    width: auto;
    flex-grow: 0;
  }
}
.domain-list-body.accordion-content {
  padding: 0;
}
</style>

