<template>
  <div class="programme-plan" v-cloak>
    <CurriculumInterfaceProgrammePlanDomainList>
    </CurriculumInterfaceProgrammePlanDomainList>
    <div style="text-align: right">
      <button @click.stop="addDomain()" type="button" class="button primary">
        <small
          ><span class="hide-for-small-only"
            >&nbsp;&nbsp;Add Domain</span
          ></small
        >
      </button>
    </div>
  </div>
</template>

<script>
import CurriculumInterfaceProgrammePlanDomainList from "./CurriculumInterfaceProgrammePlanDomainList";
import AddDomain from "@/views/CurriculumInterface/AddDomain";

export default {
  name: "CurriculumInterfaceDetailsProgrammePlan",
  /* eslint-disable vue/no-unused-components */
  components: {
    CurriculumInterfaceProgrammePlanDomainList,
    AddDomain,
  },
  /* eslint-enable vue/no-unused-components */
  computed: {
    curriculums: function () {
      return this.$store.getters.curriculum;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData();
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadData();
    });
  },
  methods: {
    loadData() {
      this.$store.dispatch("getCurriculum");
    },
    addDomain() {
      this.$modal.show({
        component: AddDomain,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
