import Vue from 'vue'

export default {
	getCoachingSession (id) {
		return Vue.http.get(`coachingSession/${id}`)
	},
	createCoachingSession (studentCoachingEngagementId, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment) {
		console.log("Posting Session: ", { 
            studentCoachingEngagementId, 
            startTime, 
            endTime, 
            online, 
            venue, 
            privateNotes, 
            sharedNotes, 
            outcomeSelfAssessmentTime, 
            sessionAssessment 
        })
		return Vue.http.post('coachingSession', {
			studentCoachingEngagementId, 
            startTime, 
            endTime, 
            online, 
            venue, 
            privateNotes, 
            sharedNotes, 
            outcomeSelfAssessmentTime, 
            sessionAssessment
		})
	},
	updateCoachingSession (id, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment) {
		return Vue.http.put(`coachingSession/${id}`, {
			startTime, 
            endTime, 
            online, 
            venue, 
            privateNotes, 
            sharedNotes, 
            outcomeSelfAssessmentTime, 
            sessionAssessment
		})
	},
	deleteCoachingSession (id) {
		return Vue.http.delete(`coachingSession/${id}`)
	}
}