import * as types from '../mutation-types';
import latestAttainmentDetailsApi from '@/api/latestAttainmentDetails';

// initial state
const state = {
	latestAttainmentDetails: {}
};

// getters
const getters = {
	latestAttainmentDetails: (state) => (studentId, eloCode) => {
		let student = state.latestAttainmentDetails[studentId];
		return student ? student.elos.find(it => it.eloCode === eloCode) : null;
	}
};

// actions
const actions = {
	getLatestAttainmentDetails({ commit }, { studentId, planYear }) {
		return latestAttainmentDetailsApi.getLatestAttainmentDetails(studentId, planYear).then(resp => {
			return commit(types.SET_LATEST_ATTAINMENT_DETAILS, { studentId, details: resp.data });
		});
	}
};

// mutations
const mutations = {
	[types.SET_LATEST_ATTAINMENT_DETAILS](state, { studentId, details }) {
		state.latestAttainmentDetails = { ...state.latestAttainmentDetails, [studentId]: details };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
