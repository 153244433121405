import { render, staticRenderFns } from "./LessonPlanRemarks.vue?vue&type=template&id=1b57af53&scoped=true&"
import script from "./LessonPlanRemarks.vue?vue&type=script&lang=js&"
export * from "./LessonPlanRemarks.vue?vue&type=script&lang=js&"
import style0 from "./LessonPlanRemarks.vue?vue&type=style&index=0&id=1b57af53&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b57af53",
  null
  
)

export default component.exports