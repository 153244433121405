<template>
  <div
    v-if="annualPlanStatus && annualPlanStatus.planStatus === 'NOT_CREATED'"
    v-cloak
  >
    <p class="plan-status">
      <small>{{ $t("attainmentScore.planStatus.label.notCreated") }}</small>
    </p>
  </div>
  <div
    v-else-if="annualPlanStatus && annualPlanStatus.planStatus === 'DRAFT'"
    v-cloak
  >
    <p class="plan-status">
      <small>{{ $t("attainmentScore.planStatus.label.draft") }}</small>
    </p>
  </div>
  <div
    v-else-if="annualPlanStatus && annualPlanStatus.planStatus === 'APPROVED'"
    class="attainment-graph grid-x align-center align-bottom"
    v-cloak
  >
    <div class="fill-bar no-print" :style="style"></div>
    <p class="margin-0 sans-serif-bold attainment-score">
      {{ score >= 0 ? score + "%" : "-" }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AttainmentScore",
  props: {
    score: Number,
    annualPlanStatus: Object,
  },
  computed: {
    style: function () {
      let rotation = 0;
      if (this.score >= 0) {
        rotation = this.score;
      }
      return "transform: rotateZ(" + ((rotation / 100) * 180 + 45) + "deg)"; // 45 offset
    },
  },
};
</script>

<style lang="scss" scoped>
.attainment-graph {
  $graph-size: 4em;
  $bar-thickness: 0.4em;

  position: relative;
  width: $graph-size;
  overflow: hidden;
  transform: translateY(-$graph-size / 4);

  .lesson-record-top & {
    margin-top: -$global-margin / 3;
  }

  &:before {
    content: "";
    padding: $graph-size / 2 0;

    @media print {
      padding: 0;
    }
  }

  .fill-bar {
    position: absolute;
    top: 50%;
    left: 0;
    height: 100%;
    width: 100%;
    border: $bar-thickness solid $success;
    border-top-color: $light-gray;
    border-left-color: $light-gray;
    border-radius: 50%;
    transform-origin: 50% 50%;

    .lesson-record-wrapper & {
      border-top-color: $darker-gray;
      border-left-color: $darker-gray;
    }

    .dashboard-prog-card & {
      border-top-color: $light-gray;
      border-left-color: $light-gray;
    }
  }

  .dashboard-prog-card & {
    margin: -3.5em auto 0;
    width: 10em;
    transform: translateY(-10em / 4);

    &:before {
      height: 10em;
      padding: 0;
    }

    .fill-bar {
      border-width: 0.9em;
    }
  }

  @media print {
    transform: none;
  }
}
.attainment-score {
  font-size: $small-font-size;

  .dashboard-prog-card & {
    @extend .sans-serif-extrabold;
    font-size: map-deep-get($header-styles, medium, h4, font-size) * 1px;
    color: $lighter-gray;
    line-height: 1em;
  }

  @media print {
    padding-top: $global-margin / 2;
  }
}
.plan-status {
  @extend .sans-serif-bold;
  margin-top: $global-margin;
  color: $dark-gray;

  @media print {
    margin: 0;
  }
}
</style>
