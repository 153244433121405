<template>
  <div class="grid-x align-middle">
    <div class="start-year cell shrink">
      <span
        >{{ $t("studentDetailsProgrammePlan.startYear") }}:&nbsp;&nbsp;<b>{{
          startYear
        }}</b></span
      >
    </div>
    <div class="end-year cell shrink">
      <span
        >{{ $t("studentDetailsProgrammePlan.endYear") }}:
        <span v-if="!isDraft"
          >&nbsp;<b>{{ endYear }}</b></span
        >
      </span>
      <select
        v-if="isDraft"
        v-model="selectedEndYear"
        class="select-end-year"
        @change="endYearSelected()"
      >
        <option v-for="year in endYearRange" :key="year" :value="year">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "PlanYearSelector",
  data() {
    return {
      selectedEndYear: this.endYear,
    };
  },
  props: {
    startYear: Number,
    endYear: Number,
    isDraft: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    endYearRange() {
      return _.range(this.startYear, this.startYear + 4);
    },
  },
  methods: {
    endYearSelected() {
      this.$emit("end-year-selected", this.selectedEndYear);
    },
  },
};
</script>


<style lang="scss" scoped>
.start-year {
  padding: $global-padding/2 0;
  margin-right: $global-margin;
}
.end-year {
  padding: 0.5px 0;
}
.select-end-year {
  display: inline-block;
  border: none;
  box-shadow: none;
  text-decoration: underline;
  width: 70px;
}
</style>
