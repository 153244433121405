import * as types from '../mutation-types';
import programmeDetailsApi from '@/api/programmeDetails';

// initial state
const state = {
	programmeYearIds: [],
	programmeSchoolDetails: {},
	programmeClassGroupDetails: {}
};

// getters
const getters = {
	programmeClassGroupDetailsForId: (state) => (programmeYearId) => state.programmeClassGroupDetails[programmeYearId],
	programmeSchoolDetailsForId: (state) => (programmeYearId) => state.programmeSchoolDetails[programmeYearId]
};

// mutations
const mutations = {
	[types.SET_PROGRAMME_YEAR_IDS](state, programmeYearIds) {
		state.programmeYearIds = [...state.programmeYearIds, ...programmeYearIds];
	},
	[types.SET_PROGRAMME_SCHOOL_DETAILS](state, programmeSchoolDetails) {
		state.programmeSchoolDetails = { ...state.programmeSchoolDetails, ...programmeSchoolDetails };
	},
	[types.SET_PROGRAMME_CLASS_GROUP_DETAILS](state, programmeClassGroupDetails) {
		state.programmeClassGroupDetails = { ...state.programmeClassGroupDetails, ...programmeClassGroupDetails };
	}
};

// actions
const actions = {
	getProgrammeDetails({ commit, getters }, { programmeId, year }) {
		programmeDetailsApi.getProgrammeDetails(programmeId, year).then((resp) => {
			if (resp.data) {
				const programmeYearId = programmeId.toString() + '_' + year.toString();
				commit(types.SET_PROGRAMME_YEAR_IDS, [programmeYearId]);

				const programmeClassGroupDetails = {};
				programmeClassGroupDetails[programmeYearId] = resp.data.classGroups;
				commit(types.SET_PROGRAMME_CLASS_GROUP_DETAILS, programmeClassGroupDetails);

				const { schoolId, schoolName } = resp.data;
				const programmeSchoolDetails = {};
				programmeSchoolDetails[programmeYearId] = { schoolId, schoolName };
				commit(types.SET_PROGRAMME_SCHOOL_DETAILS, programmeSchoolDetails);
			}
		});
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
