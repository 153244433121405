var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lesson-plan-list-card"},[_c('router-link',{attrs:{"to":{
      name: 'LessonPlan',
      params: {
        year: _vm.$route.params.year,
        classId: _vm.$route.params.classId,
        term: _vm.$route.params.term,
        lessonId: _vm.lesson.id,
      },
    }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-section card-padding"},[_c('div',{staticClass:"grid-x"},[_c('small',{staticClass:"date-range color--text-color cell auto"},[_vm._v(_vm._s(_vm._f("moment")(_vm.lesson.startDate,"D MMM YYYY"))+" - "+_vm._s(_vm._f("moment")(_vm.lesson.endDate,"D MMM YYYY")))]),_c('font-awesome-icon',{staticClass:"type-icon color--gray fa-lg",attrs:{"icon":_vm.typeIcon(_vm.lesson.type)}})],1),_c('h5',{staticClass:"lesson-title"},[_vm._v(_vm._s(_vm.lesson.title))]),_c('p',{staticClass:"color--brand-primary"},[_c('b',[_vm._v(_vm._s(_vm._f("labelize")(_vm.lesson.associatedDomains && _vm.lesson.associatedDomains.join(", "))))])]),_c('div',{staticClass:"grid-x"},_vm._l((_vm.lesson.assignedTeachers),function(teacher){return _c('div',{key:teacher.id,staticClass:"image-wrapper"},[_c('profile-image',{attrs:{"image-title":teacher.fullName,"image":teacher.image,"image-alt":teacher.displayName,"image-size":35}}),_c('div',{staticClass:"text-center truncate"},[_c('small',[_vm._v(_vm._s(teacher.displayName))])])],1)}),0),_c('div',{staticClass:"grid-x"},[_c('p',{staticClass:"last-updated cell auto"},[(_vm.lesson.updatedBy && _vm.lesson.updatedOn)?_c('small',{staticClass:"color--text-color"},[_vm._v(" "+_vm._s(_vm.$t("lessonPlanList.lastUpdated", { updatedBy: _vm.lesson.updatedBy.fullName, updatedOn: _vm.updatedOn(_vm.lesson.updatedOn), }))+" ")]):_vm._e()]),_c('span',{staticClass:"cell shrink hide"},[_c('a',{staticClass:"sans-serif-bold",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.navigateToStudentRecord()}}},[_c('font-awesome-icon',{staticClass:"color--primary",attrs:{"icon":"id-card"}}),_vm._v(" "+_vm._s(_vm.$t("lessonPlan.link.studentRecord"))+" ")],1)])])])])]),_c('more-dropdown',{attrs:{"options":_vm.dropdownOptions,"identifier":_vm.$vnode.key}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }