import * as types from '../mutation-types';

// initial state
const state = {
	canvasOpen: true,
	domainsOpen: []
};

// getters
const getters = {
	canvasConfig(state) {
		return state.canvasOpen;
	},
	domainsOpen(state) {
		return state.domainsOpen;
	}
};

// actions
const actions = {
	saveCanvasConfig({ commit }, canvasOpen) {
		commit(types.SET_CANVAS_STATE, canvasOpen);
	},
	addDomainToOpen({ commit }, domainName) {
		commit(types.ADD_DOMAIN_TO_OPEN, domainName);
	},
	removeDomainFromOpen({ commit }, domainName) {
		commit(types.REMOVE_DOMAIN_FROM_OPEN, domainName);
	}
};

// mutations
const mutations = {
	[types.SET_CANVAS_STATE](state, canvasOpen) {
		state.canvasOpen = canvasOpen;
	},
	[types.ADD_DOMAIN_TO_OPEN](state, domainName) {
		let matchedDomainIndex = state.domainsOpen.find(it => it === domainName);
		!matchedDomainIndex && state.domainsOpen.push(domainName);
	},
	[types.REMOVE_DOMAIN_FROM_OPEN](state, domainName) {
		let matchedDomainIndex = state.domainsOpen.findIndex(it => it === domainName);
		state.domainsOpen.splice(matchedDomainIndex, 1);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
