<template>
  <CurriculumInterfaceDomainListCard
    ref="domainListCard"
    :domain-id="domainId"
    :text-id="textId"
    :text-name="textName"
    :details="details"
  >
  </CurriculumInterfaceDomainListCard>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CurriculumInterfaceDomainListCard from "./CurriculumInterfaceDomainListCard";
import StickyWrapper from "./StickyWrapper";

export default {
  name: "CurriculumInterfaceProgrammePlanDomainListCard",
  /* eslint-disable vue/no-unused-components */
  components: {
    FontAwesomeIcon,
    CurriculumInterfaceDomainListCard,
    StickyWrapper,
  },
  /* eslint-enable vue/no-unused-components */
  props: {
    domainId: Number,
    textId: String,
    textName: String,
    details: Array,
    edit: Boolean,
  },
  data() {
    return {
      stickyIdPrefix: "sticky_",
    };
  },
  computed: {},
  methods: {
    setSelectedDomainId(domainId) {
      this.$store.dispatch("setSelectedDomainId", domainId);
    },
    setUpReferencesOnChildrenComponents() {
      this.setUpFoundationAccordionReferencesOnDomainListCard();
      this.setUpFoundationStickyReferencesOnDomainListCard();
    },
    setUpFoundationAccordionReferencesOnDomainListCard() {
      let domainListCardInnerRefs = this.$refs.domainListCard.$refs;

      domainListCardInnerRefs.domainCard &&
        domainListCardInnerRefs.domainCard.classList &&
        domainListCardInnerRefs.domainCard.classList.add("accordion-item");

      domainListCardInnerRefs.domainCard &&
        domainListCardInnerRefs.domainCard.setAttribute(
          "data-accordion-item",
          ""
        );

      domainListCardInnerRefs.domainHeader &&
        domainListCardInnerRefs.domainHeader.classList &&
        domainListCardInnerRefs.domainHeader.classList.add("accordion-title");

      domainListCardInnerRefs.content &&
        domainListCardInnerRefs.content.classList &&
        domainListCardInnerRefs.content.classList.add("accordion-content");
      domainListCardInnerRefs.content &&
        domainListCardInnerRefs.content.setAttribute("data-tab-content", "");
    },
    setUpFoundationStickyReferencesOnDomainListCard() {
      let domainListCardInnerRefs = this.$refs.domainListCard.$refs;

      if (domainListCardInnerRefs.domainCard) {
        domainListCardInnerRefs.domainCard.id =
          this.stickyIdPrefix + this.domainId;
      }

      domainListCardInnerRefs.domainHeader.setAttribute(
        "data-sticky-container",
        ""
      );
    },
  },
  mounted() {
    this.setUpReferencesOnChildrenComponents();
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
  margin-bottom: 0;
  box-shadow: none;

  .accordion-icon {
    position: absolute;
    transition: transform 0.1s linear;
    transform: rotateZ(0);
    top: 0;
    margin-top: 0.1em;

    @include breakpoint(medium) {
      margin-top: 0.25em;
    }
  }

  &.is-active {
    .accordion-icon {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }
}
.domain-list-header {
  border-top: none;
  border-bottom: 1px solid smart-scale($brand-primary, 35%, 10%);
  font-size: 1rem;
  color: $white;
  height: 3em;
  min-height: 3em;

  .programme-plan & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .sticky {
    .accordion-item & {
      border-radius: $global-radius;
    }

    .accordion-item &.is-stuck {
      width: 100%;
      border-right: 2px solid $light-gray;
      border-radius: 0;
    }

    .programme-plan & {
      background-color: $brand-tertiary;
    }
  }
}
.domain-list-wrapper {
  padding: $global-padding / 1.5 $global-padding;
  width: 100%;
}
.domain-list-title {
  @extend .sans-serif-bold;
  margin-bottom: 0;
  padding-left: $global-padding * 1.1;
  white-space: nowrap;
}
.add-elo-button {
  color: $white;
  font-size: 1rem;
}
</style>

