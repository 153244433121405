<template>
  <form v-cloak @submit.prevent="saveRemark">
    <h4 class="modal-title">
      {{ $t("editEloDetails.analysis.remarkModal.title") }}
    </h4>

    <div class="grid-x grid-margin-x">
      <div class="cell small-12 padding-bottom-1">
        <label for="remarkInput">{{
          $t("editEloDetails.analysis.remarkModal.description")
        }}</label>
        <textarea
          id="remarkInput"
          v-model="remark"
          maxlength="255"
          required
        ></textarea>
      </div>
    </div>

    <div class="action-selector">
      <div class="grid-x align-right">
        <button type="submit" class="button primary margin-bottom-0">
          {{ $t("editEloDetails.analysis.remarkModal.confirm") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EditEloModalAnalysisCardRemarksEdit",
  props: {
    studentTaskItemId: Number,
    updateTaskItemRemarksFlag: Function,
    closeSelf: {
      type: Function,
      required: true,
    },
    week: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      remark: "",
    };
  },
  methods: {
    ...mapActions(["postStudentTaskItemRemarks"]),
    saveRemark(remark) {
      this.postStudentTaskItemRemarks({
        studentTaskItemId: this.studentTaskItemId,
        remark: this.remark,
        week: this.week,
      }).then((resp) => {
        if (resp) {
          const result = this.updateTaskItemRemarksFlag(true);
          if (result) {
            this.closeSelf();
            return this.$toastr.success(
              this.$t("editEloDetails.analysis.remarkModal.createSuccess")
            );
          }
        }
        this.$toastr.error(this.$t("error.unable.tryAgain"));
      });
    },
  },
};
</script>