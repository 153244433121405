import * as types from '../mutation-types';
import studentEloTerminationRemarksApi from '@/api/studentEloTerminationRemarks';

// initial state
const state = {
	studentEloTerminationRemarks: {}
};

const getters = {
	studentEloTerminationRemarks: (state) => (studentId, eloCode, external) => {
		return state.studentEloTerminationRemarks[studentId + '_' + eloCode + '_' + external];
	},
	studentEloTerminationRemarkEvidences: (state) => (studentId, eloCode, external) => {
		return state.studentEloTerminationRemarks[studentId + '_' + eloCode + '_' + external];
	}
};

// actions
const actions = {
	getStudentEloTerminationRemarks({ commit }, { studentId, eloCode, external }) {
		return studentEloTerminationRemarksApi.getStudentEloTerminationRemarks(studentId, eloCode, external).then((resp) => {
			if (resp.data && resp.data.studentEloTerminationRemarks) {
				commit(types.STUDENT_ELO_TERMINATION_REMARKS, { remarkId: studentId + '_' + eloCode + '_' + external, studentEloTerminationRemarks: resp.data.studentEloTerminationRemarks });
			}
		});
	},
	postStudentEloTerminationRemark({ commit }, { studentId, eloCode, remark, external, selectedYear, eloStatus }) {
		return studentEloTerminationRemarksApi.postStudentEloTerminationRemark(studentId, eloCode, remark, external, selectedYear, eloStatus).then((resp) => {
			if (resp.data) {
				commit(types.STUDENT_ELO_TERMINATION_REMARKS, { remarkId: studentId + '_' + eloCode + '_' + external, studentEloTerminationRemarks: resp.data });
			}
		});
	},
	deleteStudentEloTerminationRemark(_, remarkId) {
		return studentEloTerminationRemarksApi.deleteStudentEloTerminationRemark(remarkId);
	},
	setStudentEloTerminationRemark({ commit }, { studentId, studentEloTerminationRemarks }) {
		commit(types.STUDENT_ELO_TERMINATION_REMARKS, { studentId, studentEloTerminationRemarks });
	},
	addEvidenceToEloTerminationRemark({ commit }, { details, studentId, eloCode, external }) {
		return studentEloTerminationRemarksApi.postStudentEloTerminationRemarkEvidence(details).then((resp) => {
			if (resp.data) {
				commit(types.STUDENT_ELO_TERMINATION_REMARKS, { remarkId: studentId + '_' + eloCode + '_' + external, studentEloTerminationRemarks: resp.data });
			}
		});
	},
	getEloTerminationRemarkEvidence(_, { remarkId, fileUUID }) {
		return studentEloTerminationRemarksApi.getStudentEloTerminationRemarkEvidence(remarkId, fileUUID);
	}
};

// mutations
const mutations = {
	[types.STUDENT_ELO_TERMINATION_REMARKS](state, { remarkId, studentEloTerminationRemarks }) {
		state.studentEloTerminationRemarks = { ...state.studentEloTerminationRemarks, [remarkId]: studentEloTerminationRemarks };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
