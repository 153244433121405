<template>
  <div class="lesson-properties-form" v-cloak>
    <lesson-plan-body-edit
      v-if="isEditMode"
      v-on:cancelEdit="cancel()"
      v-on:submitEdit="submit()"
      :lessonPlan="lessonPlan"
    >
    </lesson-plan-body-edit>

    <div v-else-if="lessonPlan" class="card">
      <div class="card-section card-inner">
        <h6 class="color--brand-primary">
          {{
            lessonPlan.associatedDomains &&
            lessonPlan.associatedDomains.join(", ") | labelize
          }}
        </h6>

        <div class="grid-x align-middle">
          <div class="cell small-12 medium-auto small-order-2 medium-order-1">
            <span
              ><small class="date-range color--text-color"
                >{{ lessonPlan.startDate | moment("D MMM YYYY") }} -
                {{ lessonPlan.endDate | moment("D MMM YYYY") }}</small
              ></span
            >

            <h5>{{ lessonPlan.title }}</h5>
          </div>
          <div class="cell small-12 medium-auto small-order-2 medium-order-1">
            <!-- <span><small class="date-range color--text-color">{{ lessonPlan.startDate | moment('D MMM YYYY') }} - {{ lessonPlan.endDate | moment('D MMM YYYY') }}</small></span> -->

            <h5>Week {{ lessonPlan.weekUnderstandContent }}</h5>
          </div>
          <div
            class="
              lessonType
              cell
              small-12
              medium-shrink
              grid-x
              small-order-1
              medium-order-2
            "
          >
            <div class="cell auto medium-12 margin-bottom-1 edit-button">
              <button
                type="button"
                class="button clear no-print"
                @click="editLessonPlan()"
              >
                <small
                  ><font-awesome-icon icon="pencil-alt"></font-awesome-icon
                ></small>
                &nbsp;<small
                  ><span class="sans-serif-bold">{{
                    $t("lessonPlan.body.editLesson")
                  }}</span></small
                >
              </button>
            </div>
            <div class="cell shrink medium-12 text-right">
              <font-awesome-icon
                class="type-icon color--gray"
                :icon="lessonTypes[lessonPlan.type].icon"
              ></font-awesome-icon>
              <span class="display-block"
                ><small class="color--text-color">{{
                  $t(lessonTypes[lessonPlan.type].label)
                }}</small></span
              >
            </div>
          </div>
        </div>
        <div class="grid-x margin-top-1 margin-bottom-1">
          <div
            class="image-wrapper"
            v-for="teacher in lessonPlan.assignedTeachers"
            :key="teacher.id"
          >
            <profile-image
              :image-title="teacher.fullName"
              :image="teacher.image"
              :image-alt="teacher.displayName"
              :image-size="40"
            ></profile-image>
            <div class="text-center truncate">
              <small>{{ teacher.displayName }}</small>
            </div>
          </div>
        </div>

        <div id="table">
          <div
            class="section richtext"
            v-html="
              `${lessonPlan.learningObjectives || '<h5>Lesson Objectives</h5>'}`
            "
          ></div>

          <div class="section" style="display: flex">
            <!-- <div style="width: 10%; border-right: black solid thin; text-align: center;">Week</div> -->
            <div
              style="
                width: 10%;
                border-right: black solid thin;
                text-align: center;
              "
            >
              Time
            </div>
            <div
              style="
                width: 50%;
                border-right: black solid thin;
                text-align: center;
              "
            >
              Sequence of Activities
            </div>
            <div
              style="
                width: 25%;
                border-right: black solid thin;
                text-align: center;
              "
            >
              Teachers & Materials
            </div>
            <!-- <div style="width: 15%; text-align: center;">UDL Principles & Evidence</div> -->
            <div style="width: 15%; text-align: center">
              <a href="https://udlguidelines.cast.org/" target="_blank"
                >UDL Principles & Evidence</a
              >
            </div>
          </div>
          <div class="section" style="display: flex">
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.timeUnderstandContent}}</div>
						<div style="width: 50%; border-right: black solid thin;" v-html="'<h5>Introduction</h5><br/>' + lessonPlan.understandContent"></div>
						<div style="width: 25%; border-right: black solid thin;" v-html="lessonPlan.materialsUnderstandContent"></div>
						<div style="width: 15%;" v-html="lessonPlan.evidenceUnderstandContent"></div> -->
            <div style="width: 10%; border-right: black solid thin">
              {{ lessonPlan.timeIndependentPractice }}
            </div>
            <div
              style="width: 50%; border-right: black solid thin"
              class="richtext"
              v-html="
                `<h5>Introduction</h5><br/>${lessonPlan.activateContent || ''}`
              "
            ></div>
            <div
              style="width: 25%; border-right: black solid thin"
              class="richtext"
              v-html="lessonPlan.materialsIndependentPractice"
            ></div>
            <div
              style="width: 15%"
              class="richtext"
              v-html="lessonPlan.evidenceIndependentPractice"
            ></div>
          </div>
          <div class="section" style="display: flex">
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.weekPracticeContent}}</div> -->
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.timePracticeContent}}</div>
						<div style="width: 50%; border-right: black solid thin;" class="richtext" v-html="'<h5>Lesson Development (I-DO)</h5><br/>' + lessonPlan.practiceContent"></div>
						<div style="width: 25%; border-right: black solid thin;" class="richtext" v-html="lessonPlan.materialsPracticeContent"></div>
						<div style="width: 15%;" class="richtext" v-html="lessonPlan.evidencePracticeContent"></div> -->
            <div style="width: 10%; border-right: black solid thin">
              {{ lessonPlan.timeUnderstandContent }}
            </div>
            <div
              style="width: 50%; border-right: black solid thin"
              class="richtext"
              v-html="
                `<h5>Lesson Development (I-DO)</h5><br/>${
                  lessonPlan.understandContent || ''
                }`
              "
            ></div>
            <div
              style="width: 25%; border-right: black solid thin"
              class="richtext"
              v-html="lessonPlan.materialsUnderstandContent"
            ></div>
            <div
              style="width: 15%"
              class="richtext"
              v-html="lessonPlan.evidenceUnderstandContent"
            ></div>
          </div>
          <div class="section" style="display: flex">
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.weekApplyContent}}</div> -->
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.timeApplyContent}}</div>
						<div style="width: 50%; border-right: black solid thin;" class="richtext" v-html="'<h5>Guided Practice (WE-DO)</h5>' + lessonPlan.applyContent"></div>
						<div style="width: 25%; border-right: black solid thin;" class="richtext" v-html="lessonPlan.materialsApplyContent"></div>
						<div style="width: 15%;" class="richtext" v-html="lessonPlan.evidenceApplyContent"></div> -->
            <div style="width: 10%; border-right: black solid thin">
              {{ lessonPlan.timePracticeContent }}
            </div>
            <div
              style="width: 50%; border-right: black solid thin"
              class="richtext"
              v-html="
                `<h5>Guided Practice (WE-DO)</h5><br/>${
                  lessonPlan.practiceContent || ''
                }`
              "
            ></div>
            <div
              style="width: 25%; border-right: black solid thin"
              class="richtext"
              v-html="lessonPlan.materialsPracticeContent"
            ></div>
            <div
              style="width: 15%"
              class="richtext"
              v-html="lessonPlan.evidencePracticeContent"
            ></div>
          </div>
          <div class="section" style="display: flex">
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.weekIndependentPractice}}</div> -->
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.timeIndependentPractice}}</div>
						<div style="width: 50%; border-right: black solid thin;" class="richtext" v-html="'<h5>Independent Practice (YOU-DO)</h5>' + lessonPlan.independentPractice"></div>
						<div style="width: 25%; border-right: black solid thin;" class="richtext" v-html="lessonPlan.materialsIndependentPractice"></div>
						<div style="width: 15%;" class="richtext" v-html="lessonPlan.evidenceIndependentPractice"></div> -->
            <div style="width: 10%; border-right: black solid thin">
              {{ lessonPlan.timeApplyContent }}
            </div>
            <div
              style="width: 50%; border-right: black solid thin"
              class="richtext"
              v-html="
                `<h5>Independent Practice (YOU-DO)</h5><br/>${
                  lessonPlan.applyContent || ''
                }`
              "
            ></div>
            <div
              style="width: 25%; border-right: black solid thin"
              class="richtext"
              v-html="lessonPlan.materialsApplyContent"
            ></div>
            <div
              style="width: 15%"
              class="richtext"
              v-html="lessonPlan.evidenceApplyContent"
            ></div>
          </div>
          <div class="section" style="display: flex">
            <!-- <div style="width: 10%; border-right: black solid thin;">{{lessonPlan.weekClosureReview}}</div> -->
            <div style="width: 10%; border-right: black solid thin">
              {{ lessonPlan.timeClosureReview }}
            </div>
            <div
              style="width: 50%; border-right: black solid thin"
              class="richtext"
              v-html="
                `<h5>Closure / Review</h5><br/>${
                  lessonPlan.closureReview || ''
                }`
              "
            ></div>
            <div
              style="width: 25%; border-right: black solid thin"
              class="richtext"
              v-html="lessonPlan.materialsClosureReview"
            ></div>
            <div
              style="width: 15%"
              class="richtext"
              v-html="lessonPlan.evidenceClosureReview"
            ></div>
          </div>

          <div class="break richtext" v-html="'<p></p>'"></div>
          <div
            class="customHeight richtext"
            v-html="
              `<h5>Assessment of Student Learning</h5><br/>${
                lessonPlan.analysesStudentLearning || ''
              }`
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LessonPlanBodyEdit from "./LessonPlanBodyEdit";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProfileImage from "./ProfileImage";

export default {
  name: "LessonPlanBody",
  components: {
    LessonPlanBodyEdit,
    FontAwesomeIcon,
    ProfileImage,
  },
  props: {
    lessonPlan: {
      type: Object,
    },
  },
  data() {
    return {
      lessonTypes: {
        GROUP: {
          label: "lessonPlan.body.form.lessonType.group",
          icon: "users",
          value: "GROUP",
        },
        INDIVIDUAL: {
          label: "lessonPlan.body.form.lessonType.individual",
          icon: "user",
          value: "INDIVIDUAL",
        },
      },
      lessonContentTypes: [],
    };
  },
  computed: {
    teacherOptions: function () {
      return this.$store.getters.classGroup.teachers;
    },
    isEditMode: function () {
      return this.$store.getters.isEditMode;
    },
  },
  beforeDestroy() {
    this.setIsEditMode(false);
  },
  methods: {
    updatedOn: function (updatedOn) {
      return moment(updatedOn).calendar();
    },
    cancel() {
      this.setIsEditMode(false);
    },
    submit() {
      this.setIsEditMode(false);
    },
    editLessonPlan() {
      this.setIsEditMode(true);
    },
    setIsEditMode(val) {
      this.$store.dispatch("setIsEditMode", val);
    },
  },
};
</script>

<style scoped>
#table {
  border: black solid thin;
}
.section {
  border-bottom: black solid thin;
}
.break {
  height: 1rem;
  border-bottom: black solid thin;
}
.subSection {
  width: 50%;
}
.customHeight {
  min-height: 15vh;
}

.richtext >>> ul {
  list-style-type: none;
}

.richtext >>> ul[data-checked="false"] > li::before {
  content: "\2610";
}

.richtext >>> ul[data-checked="true"] li::before {
  content: "\2611";
}
</style>

<style lang="scss" scoped>
div {
  overflow-wrap: break-word;
}
.card {
  border-color: $light-gray;
}
.card-inner {
  padding: $global-padding * 1.5;
}
.card .lesson-content-card {
  border: 1px dashed $medium-gray;
  box-shadow: none;

  .card-section {
    position: relative;
    padding: $global-padding;

    .lesson-content-header {
      @extend .sans-serif-extrabold;
      display: block;
      position: absolute;
      top: 0;
      right: 1em;
      color: $light-gray;
    }

    .lesson-content {
      padding: $global-padding / 3;
    }
  }
}
.divider {
  border-top: 1px solid $light-gray;
}
.lessonType {
  text-align: center;
}
.type-icon {
  font-size: 1.5rem;
}
.date-range {
  margin-bottom: $global-margin / 2;
}
.edit-button {
  text-align: left;

  @include breakpoint(medium) {
    text-align: right;
  }
}
</style>
