import * as types from '../mutation-types';
import studentProfileApi from '@/api/studentProfile';
import constants from '@/constants';

// initial state
const state = {
	// TODO:  should not be storing classgroup information in the student obj
	studentProfile: {}
};

// getters
const getters = {
	studentForStudentId: (state) => (studentId) => state.studentProfile[studentId],
	studentProfile(state) {
		return state.studentProfile;
	}
};

// actions
const actions = {
	getStudentProfile({ commit }, { studentId, planYear }) {
		return studentProfileApi.getStudentProfile(studentId, planYear).then(resp => {
			return commit(types.SET_STUDENT_PROFILE, { studentId: studentId, data: resp.data });
		});
	},
	createStudentProfile({ commit }, { fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes }) {
		return studentProfileApi.createStudentProfile(
			fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes
		).then(resp => {
			if (resp.data) {
				commit(types.SET_STUDENT_PROFILE, { studentId: resp.data.id, data: resp.data });
			}
			return resp;
		});
	},
	updateStudentProfile({ commit }, { studentId, fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes }) {
		return studentProfileApi.updateStudentProfile(
			studentId, fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes
		).then(resp => {
			if (resp.data) {
				commit(types.SET_STUDENT_PROFILE, { studentId, data: resp.data });
			}
			return resp;
		});
	},
	async deleteStudentProfile(context, studentId) {
		const { status, data } = await studentProfileApi.deleteStudentProfile(studentId);

		if (status === constants.RESPONSE_STATUS.OK && data) {
		}
	}
};

// mutations
const mutations = {
	[types.SET_STUDENT_PROFILE](state, { studentId, data }) {
		state.studentProfile = { ...state.studentProfile, [studentId]: data };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
