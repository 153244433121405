<template>
  <div class="more-button" v-cloak>
    <button
      class="dark-gray clear button"
      type="button"
      @click.prevent
      :data-toggle="'more-dropdown-' + identifier"
    >
      <FontAwesomeIcon
        icon="ellipsis-v"
        class="more-ellipsis-icon"
      ></FontAwesomeIcon>
    </button>
    <div
      class="dropdown-pane"
      :id="'more-dropdown-' + identifier"
      v-foundation:Dropdown.destroy
      data-position="bottom"
      data-alignment="right"
      data-auto-focus="true"
      data-close-on-click="true"
    >
      <div
        v-for="(option, i) in options"
        :key="i"
        @click.prevent="option.method"
      >
        {{ $t(option.label) }}
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "MoreDropdown",
  components: {
    FontAwesomeIcon,
  },
  props: {
    options: {
      type: Array,
      props: {
        label: String,
        method: Function,
      },
    },
    identifier: {
      type: [String, Number],
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.more-button {
  position: absolute;
  top: 0.2em;
  right: 0em;

  button {
    width: 1.8rem;
    height: 1.8rem;

    .more-ellipsis-icon {
      color: $medium-gray;
    }

    &:hover {
      background-color: $bg-selected;

      .more-ellipsis-icon {
        color: $primary;
      }
    }
  }
}
</style>
