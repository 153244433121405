<template>
  <base-class-group-list-card
    class="class-group-edit-side-tray-list-card"
    :class="{ added: isAdded, disabled: isDisabled }"
    :image="image"
    :condition-types="conditionTypes"
    :text-content="textContent"
    :profile-name="profileName"
    :card-id="cardId"
    @click.native="toggleAdded()"
  >
    <font-awesome-icon
      slot="right"
      v-if="isAdded"
      icon="check"
      class="check-icon color--success"
    />
    <div
      slot="right-aligned"
      v-if="isDeletable"
      class="right-arrow grid-y align-middle text-center"
      @click.stop="toggleSideDropdown($event)"
    >
      <font-awesome-icon class="cell" icon="caret-right" size="sm" />
    </div>
  </base-class-group-list-card>
</template>

<script>
import BaseClassGroupListCard from "@/components/BaseClassGroupListCard";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ClassGroupEditSideTrayListCard",
  components: {
    BaseClassGroupListCard,
    FontAwesomeIcon,
  },
  data() {
    return {
      isAdded: false,
    };
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    image: String,
    conditionTypes: Array,
    textContent: String,
    profileName: String,
    cardId: String,
  },
  computed: {},
  methods: {
    toggleAdded() {
      if (this.isDisabled) {
        return;
      }

      this.isAdded = !this.isAdded;

      this.$emit("change", this.cardId);
    },
    toggleSideDropdown(event) {
      // TODO: event.target.getBoundingClientRect() for element's position
      let { clientX, clientY } = event;

      this.$emit("toggle-side-dropdown", {
        cardId: this.cardId,
        x: clientX,
        y: clientY,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// override
.elo-side-tray {
  .card.class-group-edit-side-tray-list-card {
    position: relative;
    margin-bottom: 0.3em;
    cursor: pointer;

    // &:hover {
    // 	background-color: $bg-selected;
    // }

    &.added {
      background-color: $bg-selected;
    }

    &.disabled {
      background-color: $light-gray;
      cursor: default;
    }

    // TODO: make card smaller!!!
    // .card-section {
    // 	.profile-image {
    // 		height: 30px;
    // 		width: 30px;
    // 	}

    // 	.student-name {
    // 		@extend .small-text;
    // 	}
    // }
  }
}
.right-arrow {
  padding: $global-padding + rem-calc(14) 0;
  color: $darker-gray;
  border-left: 1px solid $gray;

  &:hover {
    background-color: $bg-selected;
  }
}
.small-card {
  @extend .small-text;
  margin-bottom: $global-margin / 1.5;
  padding-top: $global-padding / 2.5;
  padding-bottom: $global-padding / 2.5;
  height: auto;
}
.small-text {
  @extend .sans-serif-regular;
  font-size: $small-font-size;
}
</style>
