import * as types from '../mutation-types';

/**
 * supported params
 * - component : Component
 * - componentProps : Object
 * - header : String
 * - text : String
 * - class : String
 * - includeConfirm : Boolean
 * - okText : String
 * - successCallback : Function
 * - closeCallback : Function
 */

// initial state
const state = {
	modals: []
};

// getters
const getters = {
	modals(state) {
		return state.modals;
	}
};

// actions
const actions = {
	addModal({ commit }, params) {
		commit(types.ADD_MODAL, params);
	},
	removeModal({ commit }, id) {
		commit(types.REMOVE_MODAL, id);
	},
	clearAllModals({ commit }) {
		commit(types.CLEAR_MODALS);
	}
};

// mutations
const mutations = {
	[types.ADD_MODAL](state, params) {
		state.modals.push(params);
	},
	[types.REMOVE_MODAL](state, id) {
		state.modals = state.modals.filter(it => it.id !== id);
	},
	[types.CLEAR_MODALS](state) {
		state.modals = [];
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
