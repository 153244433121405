import Vue from 'vue'

export default {
	getStudentAllTaskItemRemarks ({studentId, planYear, planTerm, eloCode}) {
		return Vue.http.get(`studentTaskItem?studentId=${studentId}&eloCode=${eloCode}&planYear=${planYear}&planTerm=${planTerm}`)
	},
	getStudentTaskItemRemarks (studentTaskItemId) {
		return Vue.http.get(`studentTaskItemRemarks/${studentTaskItemId}`)
	},
	postStudentTaskItemRemarks (studentTaskItemId, remark, week) {
		return Vue.http.post('studentTaskItemRemarks', {
			studentTaskItemId, remark, week
		})
	},
	deleteStudentTaskItemRemarks (studentTaskItemId, remarkId) {
		return Vue.http.delete(`studentTaskItemRemarks/${studentTaskItemId}`, { body: { remarkId } })
	}
}
