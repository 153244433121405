<template>
  <div v-cloak>
    <ul
      class="tabs margin-bottom-1"
      id="tab-contents"
      v-foundation:Tabs.destroy
    >
      <li
        class="tabs-title"
        :class="tab.isSelected ? 'is-active' : ''"
        v-for="(tab, index) in tabsContentList"
        :key="tab.label"
      >
        <a
          :data-tabs-target="tab.target"
          :aria-selected="tab.isSelected"
          @click="addToVisited(index)"
        >
          <FontAwesomeIcon :icon="tab.icon"></FontAwesomeIcon>
          &nbsp;{{ $t(tab.label) }}
        </a>
      </li>
    </ul>

    <div class="tabs-content" data-tabs-content="tab-contents">
      <div
        class="tabs-panel"
        :class="tabsContent.isSelected ? 'is-active' : ''"
        :id="tabsContent.target"
        v-for="(tabsContent, index) in tabsContentList"
        :key="tabsContent.target"
      >
        <slot
          :name="tabsContent.target"
          v-if="visitedTab.indexOf(index) > -1"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "TabsContent",
  components: {
    FontAwesomeIcon,
  },
  props: {
    tabsContentList: {
      type: Array,
      props: {
        label: String,
        target: String,
        icon: [String, Array],
        isSelected: Boolean,
      },
    },
    initTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visitedTab: [this.initTab],
    };
  },
  methods: {
    addToVisited(index) {
      if (this.visitedTab.indexOf(index) === -1) {
        this.visitedTab.push(index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  border: 0;

  .tabs-title.is-active > a {
    color: $black;
    border-bottom: 2px solid $black;
    &:focus {
      outline: none;
    }
  }
  .tabs-title > a {
    color: $primary;
    padding: 1rem 0.3rem 0.5rem 0.3rem;
    margin: 0rem 1rem 0rem 1rem;
  }

  .edit-elo-modal-content-body & {
    border-bottom: 1px solid $light-gray;

    .tabs-title {
      position: relative;
      top: 1px;

      > a {
        margin-left: 0.7em;
        margin-right: 0.7em;
      }

      @include breakpoint(small down) {
        > a {
          margin-left: 0;
          margin-right: 0;
          font-size: 0.85em;
        }

        .svg-inline--fa {
          display: none;
        }
      }
    }
  }
}
.tabs-content {
  border: 0;
}
.tabs-panel {
  @include breakpoint(small down) {
    padding: 0;
  }
}
</style>
