<template>
  <div class="side-dropdown">
    <div @click="showDeleteConfirmation">
      {{ $t("classGroupEdit.sideTray.student.delete") }}
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ClassGroupEditSideTrayStudentsSideDropdown",
  props: {
    studentId: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    student() {
      return this.$store.getters.unassignedStudentForStudentId(this.studentId);
    },
  },
  methods: {
    ...mapActions(["deleteStudentProfile"]),
    showDeleteConfirmation() {
      let executeDelete = () => {
        this.deleteUnassignedStudent();
      };

      this.$modal.show({
        header: this.$t("classGroupEdit.sideTray.student.delete.modalTitle", {
          studentName: this.student.displayName,
        }),
        text: this.$t("classGroupEdit.sideTray.student.delete.modalText", {
          studentName: this.student.displayName,
        }),
        includeConfirm: true,
        successCallback: executeDelete,
      });
    },
    deleteUnassignedStudent() {
      if (this.studentId) {
        let displayName = this.student.displayName;
        this.deleteStudentProfile(this.studentId).then(() => {
          this.$toastr.success(
            this.$t("classGroupEdit.student.success.deleted", {
              studentName: displayName,
            })
          );
          this.$modal.closeAll();
          this.$emit("student-deleted");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-dropdown {
  //copied from dropdown-pane css
  width: auto;
  color: $black;
  padding: 0;
  border: 1px solid $secondary-color;
  border-radius: $global-radius;
  font-size: 1em;
  background-color: $white;

  div {
    padding: $global-padding / 4 $global-padding / 1.5;
    border-bottom: 1px solid $medium-gray;
    cursor: pointer;

    &:hover {
      background-color: $bg-selected;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
