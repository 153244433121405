<template>
  <div class="user-modal" v-cloak>
    <h2 style="text-align: left">Edit TLO</h2>

    <form @submit.prevent="submit">
      <div class="class-group-modal-content grid-x content-wrapper">
        <div class="tlo-list-card" v-cloak>
          <div class="tlo-header card-divider grid-y">
            <div>
              <p
                class="tlo-text grid-x cell auto margin-bottom-0"
                style="margin-bottom: 5px"
              >
                <span class="tlo-code grid-x cell"
                  >{{ domainCode }}{{ ' '
                  }}&nbsp;<span style="color: #666666"
                    >{{ domainName }}</span
                  >
                </span>
              </p>
              <p class="tlo-text grid-x cell auto margin-bottom-0">
                <span class="tlo-code grid-x cell"
                  >{{ subDomainCode }}{{' '
                  }}&nbsp;<span style="color: #666666"
                    >{{ subdomainName }}</span
                  ></span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <label>Name</label>
          <input
            type="text"
            v-bind:name="tloName"
            required
            v-model="formData.tloName"
          />
          <label>Code</label>
          <input
            type="text"
            v-bind:name="tloCode"
            required
            v-model="formData.tloCode"
          />
          <label>Order Number</label>
          <input
            type="text"
            v-bind:name="tloId"
            required
            v-model="formData.tloId"
          />
          <input
            type="submit"
            class="cell button shrink primary hollow margin-bottom-0"
            value="Update"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CurriculumInterfaceTloEditModal",
  props: {
    domainName: String,
    domainCode: String,
    subdomainName: String,
    subDomainCode: String,
    id: String,
    tloName: String,
    tloCode: String,
    tloId: String,
  },
  data() {
    return {
      formData: {
        id: this.id,
        tloName: this.tloName,
        tloCode: this.tloCode,
        tloId: this.tloId,
      },
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
  },
  methods: {
    ...mapActions(["getCurriculum", "forceGetCurriculum"]),
    submit() {
      const currciulumInterface = this.formData.tloName;
      this.$store
        .dispatch("curriculumInterfaceTloUpdate", this.formData)
        .then((resp) => {
          if (resp) {
            this.$store.dispatch("curriculumTimestampReset");
            this.$modal.closeAll();
            this.forceGetCurriculum();
            this.$toastr.success(
              this.$t("currciulumInterfaceModal.success.updated", {
                currciulumInterface,
              })
            );
          }
        })
        .catch((err) => {
          this.$toastr.error(err.data && err.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tlo-list-card {
  border-radius: 0;
  border: 0;
  width: 100%;
}
.tlo-header {
  background-color: $lighter-gray;
  border-bottom: 1px solid $light-gray;
  position: relative;
}
.tlo-text {
  color: $darker-gray;
}
.tlo-code {
  color: $dark-gray;
  font-size: 10px;
}
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
.card {
  margin: auto;
  text-align: left;
  font-family: arial;
  border: none;
}
.card dmonainName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #73894e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.card subdmonainName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #dce1d3;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.card tloName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #efe;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
</style>
