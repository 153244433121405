<template>
  <div class="container-wrapper">
    <class-group-edit-profile
      :class-group="classGroup"
      :selected-class-group-full-details="selectedClassGroupFullDetails"
    />
    <class-group-edit-control-section :class-group="classGroup" />
    <router-view
      :class-group="classGroup"
      @refresh-list="refreshSideMenuAndClassGroupData()"
    ></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ClassGroupEditProfile from "./ClassGroupEditProfile";
import ClassGroupEditControlSection from "./ClassGroupEditControlSection";

export default {
  name: "ClassGroupEdit",
  components: {
    ClassGroupEditProfile,
    ClassGroupEditControlSection,
  },
  computed: {
    ...mapState({
      selectedClassGroupFullDetails: (state) =>
        state.classGroupSearch.selectedClassGroupFullDetails,
    }),
    ...mapGetters({
      classGroup: "classGroup",
    }),
  },
  created() {
    this.fetchClassGroupData();
  },
  methods: {
    ...mapActions([
      "getClassGroup",
      "getClassGroupFullDetailsFor",
      "clearClassGroupFullDetails",
      "clearClassGroupSearchResults",
      "getSideMenu",
    ]),
    fetchClassGroupData() {
      this.getClassGroup(this.$route.params.classId);
      this.getClassGroupFullDetailsFor(this.$route.params.classId);
    },
    refreshSideMenuAndClassGroupData() {
      this.getSideMenu(this.$route.params.year);
      this.fetchClassGroupData();
    },
  },
  beforeDestroy() {
    this.clearClassGroupFullDetails();
    this.clearClassGroupSearchResults();
  },
};
</script>
