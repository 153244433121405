import Vue from 'vue'

export default {
	getCoachingOutcomeSelfAssessment (id) {
		return Vue.http.get(`coachingOutcomeSelfAssessment/${id}`)
	},
    getCoachingOutcomeSelfAssessmentsForSession (coachingSessionId) {
		return Vue.http.get(`coachingOutcomeSelfAssessment/session/${coachingSessionId}`)
	},
	createCoachingOutcomeSelfAssessment (coachingOutcomeId, coachingSessionId, assessmentTime, result) {
		console.log("Posting OutcomeAssessment: { coachingOutcomeId, coachingSessionId, assessmentTime, result }", { coachingOutcomeId, coachingSessionId, assessmentTime, result })
		return Vue.http.post('coachingOutcomeSelfAssessment', {
			coachingOutcomeId, 
            coachingSessionId, 
            assessmentTime, 
            result
		})
	},
	updateCoachingOutcomeSelfAssessment (id, assessmentTime, result) {
		return Vue.http.put(`coachingOutcomeSelfAssessment/${id}`, {
			assessmentTime, 
            result
		})
	},
	deleteCoachingOutcomeSelfAssessment (id) {
		return Vue.http.delete(`coachingOutcomeSelfAssessment/${id}`)
	}
}