<template>
  <div class="container-wrapper" v-cloak>
    <class-group-profile :class-group="classGroup"></class-group-profile>
    <div
      v-if="hasEditPermission"
      class="cell small-12 grid-x text-right margin-bottom-1"
    >
      <div class="cell auto no-print">
        <button class="button clear" @click="editClassGroup()">
          <font-awesome-icon icon="users-cog" size="lg"></font-awesome-icon>
          <b
            v-if="
              classGroup &&
              classGroup.students &&
              classGroup.students.length > 0
            "
            >{{ classGroup.coachingEngagement ? $t("classGroup.action.edit.coaching") : $t("classGroup.action.edit") }}</b
          >
          <b v-else>{{ classGroup.coachingEngagement ? $t("classGroup.action.setup.coaching") : $t("classGroup.action.setup") }}</b>
        </button>
      </div>
    </div>
    <class-group-list
      v-if="classGroup && classGroup.students"
      :list="classGroup.students"
      :class-id="classGroup.id"
      :coachingEngagement="classGroup.coachingEngagement"
    ></class-group-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClassGroupList from "./ClassGroupList";
import ClassGroupProfile from "./ClassGroupProfile";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ClassGroup",
  components: {
    ClassGroupList,
    ClassGroupProfile,
    FontAwesomeIcon,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters({
      classGroup: "classGroup",
      profile: "profile",
    }),
    hasEditPermission() {
      const permittedRoles = ["ROLE_SUPER_TEACHER", "ROLE_LEAD_TEACHER"];
      return this.profile && this.profile.roles
        ? this.profile.roles.some((role) => permittedRoles.indexOf(role) > -1)
        : false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getStudentPlanStatusesForClassGroup(to.params.classId);
      vm.getStudentAttainmentScore(to.params.classId);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getStudentPlanStatusesForClassGroup(to.params.classId);
    this.getStudentAttainmentScore(to.params.classId);
    next();
  },
  methods: {
    getStudentAttainmentScore(classId) {
      if (!classId) return;
      this.$store.dispatch("getStudentAttainmentScore", classId);
    },
    getStudentPlanStatusesForClassGroup(classId) {
      if (!classId) return;
      this.$store.dispatch("getStudentPlanStatusesForClassGroup", classId);
    },
    editClassGroup() {
      // push to ClassGroupEdit
      this.$router.push({
        name: "ClassGroupEdit",
        params: {
          year: this.$route.params.year,
          classId: this.$route.params.classId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
