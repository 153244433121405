<template>
  <ul class="grid-x selector-radio" v-cloak>
    <li
      :class="[optionSelected === option.value ? 'selected' : 'unselected']"
      v-for="option in optionList"
      :key="option.value"
      @click="clickEvent(option.value)"
    >
      <label
        class="grid-x align-center align-middle cell auto no-print"
        :for="option.value"
      >
        <span v-if="option.icon" class="icon"
          ><font-awesome-icon :icon="option.icon"></font-awesome-icon
        ></span>
        <span class="truncate">{{ option.label }}</span>
      </label>
      <input
        class="no-print"
        type="radio"
        :id="option.value"
        :value="option.value"
        v-model="optionSelected"
      />
      <span class="only-print"> {{ option.label }} </span>
    </li>
  </ul>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RadioFilter",
  components: {
    FontAwesomeIcon,
  },
  props: {
    optionList: {
      type: Array,
      required: true,
      props: {
        label: String,
        value: [String, Number],
        icon: [String, Array],
      },
    },
    selectedValue: String,
    callback: Function,
  },
  data() {
    return {
      optionSelected: null,
    };
  },
  watch: {
    selectedValue: function (value) {
      this.optionSelected = value;
    },
  },
  mounted() {
    this.optionSelected = this.selectedValue || this.optionList[0].value;
  },
  methods: {
    clickEvent(value) {
      this.optionSelected = value;
      this.callback(this.optionSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .only-print,
  .only-print * {
    display: none !important;
  }
}
@media print {
  .selector-radio {
    display: inline-block;
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;

    li {
      background-color: transparent !important;
    }
  }
  .selected {
    .only-print,
    .only-print * {
      display: block !important;
      font-weight: $global-weight-bold;
    }
  }

  .unselected {
    .only-print,
    .only-print * {
      display: none !important;
    }
  }
}
.icon {
  margin-right: $global-margin * 0.3;
}
</style>
