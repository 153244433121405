import Vue from 'vue'

export default {
	getCoachingGoal (id) {
		return Vue.http.get(`coachingGoal/${id}`)
	},
	createCoachingGoal (studentCoachingEngagementId, name) {
		console.log("Posting Goal: { studentCoachingEngagementId, name }", { studentCoachingEngagementId, name })
		return Vue.http.post('coachingGoal', {
			studentCoachingEngagementId, name
		})
	},
	updateCoachingGoal (id, name) {
		return Vue.http.put(`coachingGoal/${id}`, {
			name
		})
	},
	deleteCoachingGoal (id) {
		return Vue.http.delete(`coachingGoal/${id}`)
	}
}