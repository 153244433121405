import Vue from 'vue'

export default {
	getStudentAnnualPlan (studentId, planYear) {
		return Vue.http.get('studentAnnualPlan/' + studentId, { params: { planYear } })
	},
	addEloToAnnualPlan (studentId, eloCodes, planYear) {
		return Vue.http.put('studentAnnualPlan/' + studentId, {
			eloCodes: eloCodes,
			planYear: planYear
		})
	},
	deleteEloFromAnnualPlan (studentId, planYear, eloCode) {
		return Vue.http.delete('studentAnnualPlan/' + studentId, {
			// body param is required for delete http calls
			body: {
				planYear: planYear,
				eloCode: eloCode
			}
		})
	}
}
