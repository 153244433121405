<template>
  <div ref="domainCard" class="domain-list-card card" v-cloak>
    <a ref="domainHeader" class="domain-list-header card-divider padding-0">
      <slot name="stickyWrapper" :effectiveEloCount="effectiveEloCount">
        <div class="domain-list-wrapper grid-x align-middle padding-1">
          <div class="cell auto h-pos-relative">
            <div class="domain-list-title grid-x align-middle">
              <h6 class="domain-title margin-bottom-0 truncate">
                {{ textName | labelize }}
              </h6>
              <span
                class="elo-count"
                :class="effectiveEloCount(details) === 0 ? 'count-alert' : ''"
              >
                {{ effectiveEloCount(details) }}
                {{ $tc("domainListCard.elos", effectiveEloCount(details)) }}
              </span>
            </div>
          </div>
          <div class="cell shrink"></div>
        </div>
      </slot>
    </a>
    <div ref="content" class="domain-list-body">
      <div v-if="effectiveEloCount(details) > 0">
        <sub-domain-list-card
          v-for="subdomain in details"
          :key="subdomain.id"
          :sub-domain="subdomain"
          :retain-elos="retainElos"
        >
          <tlo-list-card
            v-for="tlo in subdomain.tlos"
            :key="tlo.id"
            :tlo="tlo"
            :retain-elos="retainElos"
          >
            <slot name="injectEloListCard" :tlo="tlo">
              <elo-list-card
                v-for="elo in retainElos(tlo.elos)"
                :key="elo.id"
                :text-id="elo.eloCode"
                :text-name="elo.eloName"
                :programmeCodes="elo.programmeCodes"
                :programmeOutcome="elo.programmeOutcome"
                :strands="elo.strands"
              >
              </elo-list-card>
            </slot>
          </tlo-list-card>
        </sub-domain-list-card>
      </div>
      <div v-else>
        <p class="empty-message bgcolor--gray">{{ $t("empty.elos.list") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import SubDomainListCard from "./SubDomainListCard";
import TloListCard from "./TloListCard";
import EloListCard from "./EloListCard";

export default {
  name: "DomainListCard",
  components: {
    SubDomainListCard,
    TloListCard,
    EloListCard,
  },
  props: {
    domainId: Number,
    textId: String,
    textName: String,
    details: Array,
    edit: Boolean,
    retainElos: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    effectiveEloCount: function (details) {
      let allElos = _(details).flatMap("tlos").flatMap("elos").value();
      return this.retainElos(allElos) ? this.retainElos(allElos).length : 0;
    },
  },
  mounted() {
    // Can be refactored as methods. On the template use v-bind:class="method()" to return the appropriate classes, attributes. However, other parent components do not use this other than for the accordion.
    if (this.componentType === "EloSideTray") {
      this.$refs.domainCard &&
        this.$refs.domainCard.classList &&
        this.$refs.domainCard.classList.add("domain-list-card");
    }
  },
};
</script>

<style lang="scss" scoped>
.domain-list-card.card {
  overflow: visible;
}
.domain-list-header {
  background-color: $brand-primary;
  border-top: none;
  border-bottom: 1px solid smart-scale($brand-primary, 35%, 10%);
  font-size: 1rem;
  color: $white;
  height: 3em;
  min-height: 3em;

  .elo-side-tray.elo-piep & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .elo-side-tray.elo-prog & {
    background-color: $darker-gray;
    border-bottom-color: smart-scale($darker-gray, 30%, 20%);
  }

  .elo-side-tray & {
    min-height: 2.7em;
  }

  .programme-plan & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .annual-plan-top-wrapper + .domain-list .accordion-item:first-child & {
    border-radius: 0;
  }
}
.domain-list-wrapper {
  padding: $global-padding / 1.5 $global-padding;
  width: 100%;
}
.domain-list-title {
  @extend .sans-serif-bold;
  margin-bottom: 0;
  padding-left: $global-padding * 1.1;
  white-space: nowrap;

  .elo-side-tray & {
    padding-left: 0;
  }

  .elo-side-tray &::after {
    @extend .sans-serif-regular;
    position: absolute;
    top: 0.6em;
    right: 0;
    content: "A-IEP";
    font-size: 0.6em;
    letter-spacing: 1px;
    line-height: 1em;
  }

  .elo-aiep &::after {
    content: "A-IEP";
  }

  .elo-piep &::after {
    content: "P-IEP";
  }

  .elo-prog &::after {
    content: "PROG";
  }
}
.domain-title {
  width: 68%;
  flex-grow: 1;

  .elo-side-tray & {
    width: 55%;
    font-size: $global-font-size;
    flex-grow: 0;
  }

  @include breakpoint(medium) {
    width: auto;
    flex-grow: 0;
  }
}
.domain-list-body.accordion-content {
  padding: 0;
}
</style>

