import * as types from '../mutation-types';
import studentEloDetailsApi from '@/api/studentEloDetails';

// initial state
const state = {
	eloDetails: {}
};

const getters = {
	studentEloDetails: (state) => (studentId) => {
		return state.eloDetails[studentId];
	}
};

// actions
const actions = {
	getStudentEloDetails({ commit }, { studentId, eloCode, planYear }) {
		studentEloDetailsApi.getStudentEloDetails(studentId, eloCode, planYear).then((resp) => {
			if (resp.data && resp.data.eloDetails) {
				commit(types.SET_ELO_DETAILS, { studentId, eloDetails: resp.data.eloDetails });
			}
		});
	},
	postStudentEloDetails({ commit }, { studentId, eloCode, planYear, formData }) {
		return studentEloDetailsApi.postStudentEloDetails(studentId, eloCode, planYear, formData).then((resp) => {
			if (resp.data) {
				commit(types.SET_ELO_DETAILS, { studentId, eloDetails: resp.data });
			}
		});
	},
	setStudentEloDetails({ commit }, { studentId, eloDetails }) {
		commit(types.SET_ELO_DETAILS, { studentId, eloDetails });
	}
};

// mutations
const mutations = {
	[types.SET_ELO_DETAILS](state, { studentId, eloDetails }) {
		state.eloDetails = { ...state.eloDetails, [studentId]: eloDetails };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
