export default {
	name: 'termHandler',
	data() {
		return {
			optionTermList: [{
				label: '1',
				value: '1'
			}, {
				label: '2',
				value: '2'
			}, {
				label: '3',
				value: '3'
			}, {
				label: '4',
				value: '4'
			}]
		};
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (!to.params.term) {
				setTimeout(() => {
					vm.$router.replace({ params: { term: '1' } });
				});
			}
		});
	},
	beforeRouteUpdate(to, from, next) {
		if (!to.params.term) {
			setTimeout(() => {
				this.$router.replace({ params: { term: '1' } });
			});
		}
		next();
	},
	methods: {
		navigateTerm(val) {
			this.$router.push({ params: { term: val } });
		}
	}
};
