<template>
  <div class="loginpage">
    <header class="header">
      <img src="@/assets/algebra-logo-full-color-crop.png" class="app-logo">
      <nav class="nav-items">
        <a href="#">Home</a>
        <a href="#about-us">About</a>
        <a href="#sign-in">Sign-In</a>
      </nav>
    </header>
    <div class="intro">
      <img src="@/assets/bulb.jpg" class="bg-image" alt="Background Image">
      <h1>{{ $t('login.intro.title') }}</h1>
      <a href="https://www.algebratek.com" target="_blank" rel="noopener noreferrer">
        <button class="SendEmail">{{ $t('login.intro.action') }}</button>
      </a>
    </div>

    <div class="login grid-x align-center" id="sign-in" v-cloak>
	  	<div class="grid-y login-panel align-center text-center">
        <div class="grid-x grid-padding-x align-center align-middle">
          <div class="cell medium-5 large-6 hide">
            <img src="../assets/pict-login.jpg" class="cover-wrapper" :alt="$t('coverImg')">
          </div>
          <div class="cell medium-10 large-12">
            <img src="@/assets/logo.png" class="project-logo" :alt="$t('appName')">
            <h1 class="cover-text">{{ $t('login.welcome1') }}</h1>
            <h1 class="cover-text">{{ $t('login.welcome2') }}</h1>
            <div class="signin-button">
              <signin-button>
              </signin-button>
            </div>
          </div>
        </div>
		  </div>

      <div class="our-services" id="our-services">
        <h2 class="services-title">{{ $t('ourServices.title') }}</h2>
        <div class="achievements" id="achievements">
        
          <!-- Education and Curriculum System Section -->
          <div class="work">
            <p class="work-heading">{{ $t('ourServices.educationSystem.title') }}</p>
            <p class="work-text">{{ $t('ourServices.educationSystem.description') }}</p>
            <button class="product-button" @click="goToExternalSite('https://algebratek.com/products/curriculum-system')">
              {{ $t('ourServices.educationSystem.buttonText') }}
            </button>
          </div>
          
          <!-- Coaching System Section -->
          <div class="work">
            <p class="work-heading">{{ $t('ourServices.coachingSystem.title') }}</p>
            <p class="work-text">{{ $t('ourServices.coachingSystem.description') }}</p>
            <button class="product-button" @click="goToExternalSite('https://algebratek.com/products/coaching-system')">
              {{ $t('ourServices.coachingSystem.buttonText') }}
            </button>
          </div>
        </div>
      </div>


      <div class="about-section" id="about-us">
        <div class="about-content">
          <h2 class="about-title">{{ $t('aboutUs.title') }}</h2>
          <p class="about-description">{{ $t('aboutUs.description') }}</p>
          <button class="about-button" @click="goToExternalSite('https://algebratek.com')">
            {{ $t('aboutUs.buttonText') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SigninButton from "./SigninButton";

export default {
	name: 'Login',
  components: {
    SigninButton
  },
  methods: {
    goToExternalSite(url) {
      window.location.href = url; // Navigate to the external URL
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.intro, .login, .our-services {
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0;
  clear: both; /* Ensures no floating elements cause overlap */
}

html {
  scroll-padding-top: 100px; /* To ensure that sections are scrolled down enough */
}

#sign-in {
  scroll-margin-top: 100px; /* Ensure that the section does not overlap with header */
  margin-top: 120px; /* Add enough margin from the header */
}

.login {
  height: 100vh;
  background-color: $white;

  .project-logo {
    display: block;
    margin: 0 auto $global-margin * 2;
    width: 8em;
  }

// add style for sign-in button:
  	.signin-button {
		height: 1.5em;
		padding-right: $global-padding / 2;
    display: flex;
    justify-content: center;
	}
}

.login-panel {
  margin-top: -7em;
}

.app-logo {
    margin-top: 0;
    margin-left: 0.3em;
    width: 12em;
    transition: 0.2s ease-out;

    @media (max-width: map-get($breakpoints, medium)) {
        margin-left: 0.2em;
        width: 6em;
    }
}

.cover-text {
  margin-bottom: $global-margin * 3;
  font-size: map-deep-get($header-styles, small, h2, font-size) * 1px;
  line-height: 1.3em;

  @include breakpoint(medium) {
    font-size: map-deep-get($header-styles, medium, h4, font-size) * 1px;
  }

  @include breakpoint(large) {
    font-size: map-deep-get($header-styles, medium, h3, font-size) * 1px;
    line-height: 1.2em;
  }
}

.cover-wrapper {
  display: block;
  width: rem-calc(280);
}

@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');

body {
  margin: 0;
  box-sizing: border-box;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
}

.header .logo {
  font-size: 25px;
  font-family: $body-font-family;
  color: #000;
  text-decoration: none;
  margin-left: 30px;
}

.nav-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f5f5;
  margin-right: 20px;
}

.nav-items a {
  text-decoration: none;
  color: #000;
  padding: 35px 20px;
}

/* CSS for main element */
.intro {
  position: relative;
  min-height: 520px; /* Sets the height for the background image section */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 520px;
}

.intro img.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the whole container */
  z-index: -1; /* Sends the image to the background */
  opacity: 1.0; /* Optional: Add transparency if needed */
}

.intro h1 {
  font-family: $body-font-family;
  font-size: 60px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: map-get($breakpoints, medium)) {
    font-size: 24px;
  }
}

.intro p {
  font-size: 20px;
  color: #d1d1d1;
  text-transform: uppercase;
  margin: 20px 0;
}

.intro button {
  padding: 10px 20px;
  background-color: #f7941d; /* AlgebraTek logo orange color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.intro button:hover {
    background-color: #d97914; /* Darker shade of orange for hover effect */
}

.login {
  margin-top: 37px; /* Adds space between the intro and login section */
  padding: 20px; /* Additional padding for better spacing */
  z-index: 1; /* Ensures the login section appears above any other overlapping elements */
  background-color: #fff; /* Optionally, you can add a background color to avoid transparency */
}

.login img.project-logo {
  max-width: 100px; /* Ensure the logo doesn’t take too much space */
  margin-bottom: 20px; /* Space between the logo and other elements */
}

.login h1 {
  margin: 10px 0;
}

.login.signin-button {
  margin-bottom: 40px;

}

.cell {
  border: 1px solid #ccc; /* Thin border */
  padding: 20px; /* Add padding inside the container */
  max-width: 600px; 
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: #fff; /* Optional: Add a background color */
  margin-top: 20px; /* Space between this section and others */
}

.our-services {
  margin-top: 37px;
  display: flex;
  flex-direction: column; /* Ensure title is at the top and content below */
  justify-content: center;
  align-items: center;
  padding: 40px 80px;
  border-top: 2px solid #eeeeee;
}

.services-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.achievements {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work {
  width: 100%; /* Full width for each section */
  margin: 10px 0;
}

.work-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.work-text {
  font-size: 16px;
  margin-bottom: 10px;
}

.product-button {
  padding: 10px 20px;
  background-color: #f7941d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.product-button:hover {
  background-color: #d97914;
}

    .about-me {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 80px;
      border-top: 2px solid #eeeeee;
    }

    .about-me img {
      width: 500px;
      max-width: 100%;
      height: auto;
      border-radius: 10px;
    }

    .about-me-text h2 {
      font-size: 30px;
      color: #333333;
      text-transform: uppercase;
      margin: 0;
    }

    .about-me-text p {
      font-size: 15px;
      color: #585858;
      margin: 10px 0;
    }

    .our-product {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 80px;
      border-top: 2px solid #eeeeee;
    }

    .our-product img {
      width: 500px;
      max-width: 100%;
      height: auto;
      border-radius: 10px;
    }

    .our-product-text h2 {
      font-size: 30px;
      color: #333333;
      text-transform: uppercase;
      margin: 0;
    }

    .our-product-text p {
      font-size: 15px;
      color: #585858;
      margin: 10px 0;
    }

    /* CSS for footer */
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #302f49;
      padding: 40px 80px;
    }

    .footer .copy {
      color: #fff;
    }

    .bottom-links {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 40px 0;
    }

    .bottom-links .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 40px;
    }

    .bottom-links .links span {
      font-size: 20px;
      color: #fff;
      text-transform: uppercase;
      margin: 10px 0;
    }

    .bottom-links .links a {
      text-decoration: none;
      color: #a1a1a1;
      padding: 10px 20px;
    }

.about-section {
  margin-top: 40px;
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-title {
  font-size: 28px;
  color: #333333;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.about-description {
  font-size: 18px;
  color: #666666;
  margin-bottom: 20px;
}

.about-button {
  padding: 10px 20px;
  background-color: #f7941d; /* Same orange as used before */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.about-button:hover {
  background-color: #d97914; /* Darker shade for hover */
}


</style>
