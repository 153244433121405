<template>
  <div v-cloak>
    <h4 class="modal-title">{{ title }}</h4>

    <section>
      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("tenant.nameTitle") }}
        </h5>
        <input
          class="cell small-8 medium-6 margin-bottom-0"
          type="text"
          placeholder="Name"
          v-model="tenantField.name"
        />
      </div>
      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("tenant.email") }}
        </h5>
        <input
          class="cell small-8 medium-6 margin-bottom-0"
          type="text"
          placeholder="Email"
          v-model="tenantField.email"
        />
      </div>
      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("tenant.full_name") }}
        </h5>
        <input
          class="cell small-8 medium-6 margin-bottom-0"
          type="text"
          placeholder="Full Name"
          v-model="tenantField.fullname"
        />
      </div>
      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("tenant.status") }}
        </h5>
        <div class="small-6 cell">
            <h6></h6>
            <input type="checkbox" id="enabled" name="enabled"  @click="toggleAccountLocked" :checked="this.tenantField.enabled">
            <label for="enabled">{{ $t('userModal.content.accountLocked.label') }}</label>
        </div>
      </div>
    </section>

    <section class="action-selector grid-x align-right">
      <button
        v-if="isEditing"
        type="submit"
        class="button primary margin-bottom-0"
        :disabled="mainButtonIsDisabled"
        @click="update"
      >
        {{ $t("tenant.editButton") }}
      </button>
      <button
        v-else
        type="submit"
        class="button primary margin-bottom-0"
        :disabled="mainButtonIsDisabled"
        @click="create"
      >
        {{ $t("tenant.createButton") }}
      </button>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constants from "@/constants";

export default {
  name: "TenantEditor",
  props: {
    isEditing: { type: Boolean, require: true },
    tenant: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      tenantField: {
        name: "",
        email: "",
        fullname: "",
        enabled: false
      },
    };
  },
  computed: {
    ...mapGetters(["adminTenant"]),
    userTenant() {
      return this.adminTenant(this.tenant.id);
    },
    title() {
      return this.isEditing
        ? this.$t("tenant.editTitle", { tenant: this.tenant.name })
        : this.$t("tenant.createTitle");
    },
    mainButtonIsDisabled() {
      const list = [this.tenantField];
      let isEmpty = false;
      list.forEach((item) => {
        if (Object.values(item).includes("")) {
          isEmpty = true;
        }
      });

      return isEmpty;
    },
    organisationList() {
      return Object.values(constants.ORGANIZATION_TYPE);
    },
  },
  created() {
    if (this.isEditing) {
      const userTenant = this.userTenant;
      this.tenant = userTenant;
      this.tenantField = {...userTenant};
    }
  },
  mounted() {
    if (this.isEditing) {
      const userTenant = this.userTenant;
      this.tenant = userTenant;
      this.tenantField = {...userTenant};
    }
  },
  methods: {
    ...mapActions([
      "createTenant",
      "editTenant",
      // "deleteTenant"
    ]),
    toggleAccountLocked () {
        this.tenantField.enabled = !this.tenantField.enabled
    },
    async create() {
      let isError = false;
      try {
        await this.createTenant(this.tenantField);
      } catch (e) {
        isError = true;
        if (e.status === 422) {
          this.$toastr.error(this.$t("error.tenantNameExits"));
        }
      }
      if (!isError) {
        this.$modal.closeAll();
      }
    },
    async update(closeModal) {
      // const id = { id: this.userTenant.id }
      // const userId = { userId: this.userTenant.userId }
      const userId = this.userTenant.userId
      const params = {
        ...this.tenantField, userId
      };

      await this.editTenant(params);
      this.$modal.closeAll();
    },
    async deleteTenant() {
      const param = { tenantId: this.tenant.id };
      // const param = { id: programme.id };
      await this.deleteTenant(param);
    },
  },
};
</script>

<style lang="scss" scoped>
.minor-padding-bottom {
  padding-bottom: $global-padding / 2;
}

.create-background {
  background-color: $bg-selected;
}
</style>