// studentCoachingEngagement.js vuex store module
import Vue from 'vue'
import constants from '@/constants';
import * as types from '../mutation-types';
import StudentCoachingEngagementApi from '@/api/studentCoachingEngagement'

// initial state
const state = {
  studentCoachingEngagement: {},
  studentCoachingEngagements: [],
  currentCoachingEngagement:{},
};

// getters
const getters = {
  studentCoachingEngagement: state => state.studentCoachingEngagement,
  studentCoachingEngagements: state => state.studentCoachingEngagements,
  currentCoachingEngagement: state => state.currentCoachingEngagement,
  aiBotContext: state => {
    if (!state.currentCoachingEngagement.coachingGoals) return '';

    const contextString = state.currentCoachingEngagement.coachingGoals.map(goal => {
      const goalName = goal.name;
      const outcomes = goal.coachingOutcomes.map(outcome => {
        const outcomeName = outcome.outcomeName;

        const latestAssessment = outcome.coachingOutcomeAssessments.sort((a, b) => new Date(b.assessmentTime) - new Date(a.assessmentTime))[0];
        const latestSelfAssessment = outcome.coachingOutcomeSelfAssessments.sort((a, b) => new Date(b.assessmentTime) - new Date(a.assessmentTime))[0];

        const latestAssessmentResult = latestAssessment ? latestAssessment.result : 'N/A';
        const latestSelfAssessmentResult = latestSelfAssessment ? latestSelfAssessment.result : 'N/A';

        return `${outcomeName} [${latestAssessmentResult}, ${latestSelfAssessmentResult}]`;
      }).join(', ');

      return `${goalName} (${outcomes})`;
    }).join('; ');

    return `Client context follows in this format: goal (outcome[assessmentResult, selfAssessmentResult], ...);  ${contextString}`;
  },
  aiBotFormattedContext: state => {
    if (!state.currentCoachingEngagement.coachingGoals) return '';

    const contextString = state.currentCoachingEngagement.coachingGoals.map((goal, goalIndex) => {
      const goalName = goal.name;
      const goalString = `Goal ${goalIndex + 1}: ${goalName}`;

      const outcomes = goal.coachingOutcomes.map((outcome, outcomeIndex) => {
        const outcomeName = outcome.outcomeName;

        const latestAssessment = outcome.coachingOutcomeAssessments.sort((a, b) => new Date(b.assessmentTime) - new Date(a.assessmentTime))[0];
        const latestSelfAssessment = outcome.coachingOutcomeSelfAssessments.sort((a, b) => new Date(b.assessmentTime) - new Date(a.assessmentTime))[0];

        const latestAssessmentResult = latestAssessment ? latestAssessment.result : 'N/A';
        const latestSelfAssessmentResult = latestSelfAssessment ? latestSelfAssessment.result : 'N/A';

        return `Outcome ${outcomeIndex + 1}:${outcomeName} - Assessment Result = ${latestAssessmentResult}, Self-Assessment Result = ${latestSelfAssessmentResult}`;
      }).join('\n');

      return `${goalString}\n\n${outcomes}`;
    }).join('\n\n');

    return `Client context Coaching Goals:\n\n${contextString}`;
  },
};

// actions
const actions = {
  setStudentCoachingEngagement({ commit }, studentCoachingEngagement) {
    commit(types.SET_STUDENT_COACHING_ENGAGEMENT, studentCoachingEngagement);
  },
  setStudentCoachingEngagements({ commit }, studentCoachingEngagements) {
    commit(types.SET_STUDENT_COACHING_ENGAGEMENTS, studentCoachingEngagements);
  },
  setCurrentCoachingEngagement({ commit }, currentCoachingEngagement) {
    commit(types.SET_CURRENT_COACHING_ENGAGEMENT, currentCoachingEngagement);
  },
  setCurrentCoachingEngagementCoachingSessionOutcomeAssessments({ commit }, { coachingSessionId, coachingOutcomeAssessments }) {
    commit(types.SET_CURRENT_COACHING_ENGAGEMENT_COACHING_SESSION_OUTCOME_ASSESSMENTS, { coachingSessionId, coachingOutcomeAssessments });
  },
  async getStudentCoachingEngagement({ commit, state }, id) {
    const response = await StudentCoachingEngagementApi.getStudentCoachingEngagement(id)
    commit(types.SET_STUDENT_COACHING_ENGAGEMENT, response.data)
    const { title, resources, engagementDate, completed } = state.currentCoachingEngagement;
    commit(types.SET_CURRENT_COACHING_ENGAGEMENT, { 
      ...response.data, 
      title,
      resources,
      engagementDate,
      completed
    })
  },
  updateStudentCoachingEngagement({ commit }, { id, studentCoachingEngagement }) {
		return StudentCoachingEngagementApi.updateStudentCoachingEngagement(id, studentCoachingEngagement).then((resp) => {
			if (resp.data) {
        const studentCoachingEngagementResponse = resp.data
        console.log("Update response: ", studentCoachingEngagementResponse)
				commit(types.SET_STUDENT_COACHING_ENGAGEMENT, studentCoachingEngagementResponse);
        commit(
          types.SET_CURRENT_COACHING_ENGAGEMENT_LOCK_ACCESS, {
            lockedBy: studentCoachingEngagementResponse.studentCoachingEngagement.lockedBy, 
            accessList: studentCoachingEngagementResponse.studentCoachingEngagement.accessList
          }
        );
			}
		});
	},
};

// mutations
const mutations = {
  [types.SET_STUDENT_COACHING_ENGAGEMENT](state, studentCoachingEngagement) {
    state.studentCoachingEngagement = studentCoachingEngagement;
  },
  [types.SET_STUDENT_COACHING_ENGAGEMENTS] (state, studentCoachingEngagements) {
    state.studentCoachingEngagements = studentCoachingEngagements
  },
  [types.SET_CURRENT_COACHING_ENGAGEMENT](state, currentCoachingEngagement) {
    state.currentCoachingEngagement = currentCoachingEngagement;
  },
  [types.ADD_STUDENT_COACHING_ENGAGEMENT] (state, studentCoachingEngagement) {
    state.studentCoachingEngagements.unshift(studentCoachingEngagement)
  },
  [types.UPDATE_STUDENT_COACHING_ENGAGEMENT] (state, studentCoachingEngagement) {
    Vue.set(state.studentCoachingEngagement, studentCoachingEngagement)
    // const index = state.coachingEngagements.findIndex(e => e.id === engagement.id)
    // if (index !== -1) {
    //   Vue.set(state.coachingEngagements, index, engagement)
    // }
  },
  [types.SET_CURRENT_COACHING_ENGAGEMENT_LOCK_ACCESS] (state, { lockedBy, accessList }) {
    console.log("Store setting lock properties: lockedBy and accessList: ", lockedBy, accessList)
    if (state.currentCoachingEngagement) {
        state.currentCoachingEngagement.lockedBy = lockedBy;
        state.currentCoachingEngagement.accessList = accessList;
    }
  },
  [types.DELETE_COACHING_ENGAGEMENT] (state, studentCoachingEngagementId) {
    state.studentCoachingEngagements = state.studentCoachingEngagements.filter(e => e.id !== studentCoachingEngagementId)
  },
  [types.SET_CURRENT_COACHING_ENGAGEMENT_COACHING_SESSION_OUTCOME_ASSESSMENTS] (state, { coachingSessionId, coachingOutcomeAssessments }) {
    // (3) update the the session inside state.currentCoachingEngagement.sessions with matching session to have this property, or update the property if exists
    console.log("STORE: MUTATION: SET_CURRENT_..._SESSION_OUTCOME_ASSESSMENT")

    if (state.currentCoachingEngagement && state.currentCoachingEngagement.coachingSessions) {
      const coachingSessionInEngagement = state.currentCoachingEngagement.coachingSessions.find(coachingSession => coachingSession.id == coachingSessionId);
      console.log("Matching coaching session found in engagement: ", coachingSessionInEngagement)
      if (coachingSessionInEngagement) {
        console.log("Now setting outcomeAssessments:", coachingOutcomeAssessments)
        Vue.set(coachingSessionInEngagement, 'outcomeAssessments', coachingOutcomeAssessments);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
