<template>
  <div class="profile-image-wrapper grid-x align-center align-middle" v-cloak>
    <div
      class="profile-image"
      :style="{ height: imageSize + 'px', width: imageSize + 'px' }"
    >
      <img
        class="profile-pic"
        v-if="image"
        :src="image"
        :title="imageTitle"
        :alt="imageAlt"
      />
      <div
        v-else
        class="empty-profile-image grid-x align-center align-middle"
        :title="imageTitle"
        :style="{ backgroundColor: '#' + hexBackgroundColor }"
      >
        <span
          class="profile-initial"
          :style="{ color: '#' + textColor, fontSize: imageSize / 2.5 + 'px' }"
          >{{ initial }}</span
        >
      </div>
    </div>
    <slot name="condition-type"></slot>
  </div>
</template>

<script>
export default {
  name: "ProfileImage",
  props: {
    image: {
      type: String,
    },
    imageAlt: {
      type: String,
    },
    imageSize: {
      type: Number,
    },
    imageTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    initial: function () {
      let str = this.imageTitle;
      let matches = str.match(/([A-Z])[^A-Z]*([A-Z]?)/);
      let acronym = null;
      if (matches) {
        acronym = matches[1] + matches[2];
      } else {
        acronym = str[0] + (str.length > 1 ? str[1] : "");
      }
      return acronym;
    },
    hexBackgroundColor: function () {
      return this.toHexColour(this.imageTitle);
    },
    textColor: function () {
      return this.colorContrast(this.hexBackgroundColor);
    },
  },
  methods: {
    // Hash any string into an integer value
    // Then we'll use the int and convert to hex.
    hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    // Convert an int to hexadecimal with a max length
    // of six characters.
    intToARGB(i) {
      let hex =
        ((i >> 24) & 0xff).toString(16) +
        ((i >> 16) & 0xff).toString(16) +
        ((i >> 8) & 0xff).toString(16) +
        (i & 0xff).toString(16);
      // Sometimes the string returned will be too short so we
      // add zeros to pad it out, which later get removed if
      // the length is greater than six.
      hex += "000000";
      return hex.substring(0, 6);
    },
    // Extend the string type to allow converting to hex for quick access.
    toHexColour(str) {
      return this.intToARGB(this.hashCode(str));
    },
    colorContrast(hex) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);

      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "000000" : "FFFFFF";
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-image-wrapper {
  position: relative;

  .profile-image {
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;

    .profile-pic {
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .empty-profile-image {
      height: 100%;
      width: 100%;

      .profile-initial {
        font-weight: $global-weight-bold;
      }
    }
  }

  .condition-type-wrapper {
    position: absolute;
    right: -10px;
    bottom: 7px;
  }
}
</style>

