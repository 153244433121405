<template>
  <div class="class-group-modal" v-cloak>    
    <h4 class="modal-title" v-if="!isCoachingEngagement">
      {{
        isNew
          ? $t("classGroupModal.header.create")
          : $t("classGroupModal.header.update")
      }}
    </h4>
    <h4 class="modal-title" v-if="isCoachingEngagement">
      {{
        isNew
          ? $t("classGroupModal.header.createCoachingEngagement")
          : $t("classGroupModal.header.updateCoachingEngagement")
      }}
    </h4>

    <div class="class-group-modal-content grid-x content-wrapper">
      <div class="small-12 cell">
        <h6>{{ $t("classGroupModal.content.name") }}</h6>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="e.g. Teamwork 1P"
          v-model="formData.name"
        />
      </div>

      <div class="small-12 cell cell-padding-top">
      <div style="display: flex; align-items: center;">
        <input type="checkbox" id="isCoachingEngagement" v-model="isCoachingEngagement" style="margin-right: 0.5rem;">
        <h6>{{ $t("classGroupModal.content.coachingEngagement") }}</h6>
      </div>
      <!-- <p>{{ classGroup.coachingEngagement }}</p> -->
    </div>
      <div class="small-12 cell cell-padding-top" v-if="isCoachingEngagement">
        <div class="card">
          <h6>{{ $t("classGroupModal.content.engagementDate") }}</h6>
          <input type="datetime-local" id="engagementDate" v-model="localEngagementDate">
          <h6>{{ $t("classGroupModal.content.completed") }}</h6>
          <!-- <input type="checkbox" id="completed" v-model="engagementData.completed">
          <label for="completed">{{ engagementData.completed ? $t("Completed") : $t("Ongoing") }}</label>  -->
          <button @click="toggleStatus" class="status-button">
            <!-- {{ $t(engagementData.completed ? "Completed" : "Ongoing") }} -->
            {{ engagementData.completed ? "Completed" : "Ongoing" }}
          </button>
        </div>
      </div>

      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("classGroupModal.content.year") }}</h6>
        <select v-model="formData.year">
          <option v-for="option in yearOptions" :value="option" :key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("classGroupModal.content.school") }}</h6>
        <select v-model="formData.schoolId">
          <option
            v-for="option in schoolOptions"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("classGroupModal.content.programme") }}</h6>
        <select v-model="formData.programmeId">
          <option
            v-for="option in programmeOptions"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>

      <div class="action-selector cell grid-x align-right">
        <div class="cell shrink">&nbsp;</div>
        <button
          type="submit"
          class="button primary margin-bottom-0"
          @click="submit"
        >
          {{
            isNew
              ? $t("classGroupModal.action.create")
              : $t("classGroupModal.action.save")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import constants from "@/constants";

export default {
  name: "ClassGroupModal",
  data() {
    return {
      formData: {
        name: this.initName,
        year: this.initYear,
        schoolId: this.initSchoolId,
        programmeId: this.initProgrammeId,
        //isCoachingEngagement: Boolean(this.classGroup && this.classGroup.coachingEngagement),
      },
      isCoachingEngagement: false,
      localEngagementDate: null,
      engagementData: { // we initialize this from store onMounted()
        engagementDate: null, // -- new Date().toISOString().substring(0, 16), // set date to current, so that if activated as new, date is preset
        completed: null,
      }
    };
  },
  props: {
    classId: {
      type: String,
      default: undefined,
    },
    initName: {
      type: String,
      default: "New Name",
    },
    initYear: String,
    initSchoolId: {
      type: Number,
      default: undefined,
    },
    initProgrammeId: {
      type: Number,
      default: undefined,
    },
  },
  mounted() {
    this.isCoachingEngagement = Boolean(!this.isNew && this.classGroup && this.classGroup.coachingEngagement);
    if (this.isCoachingEngagement) {
      // Convert date to ISO 8601 format
      
      // Convert UTC date to local
      let date = new Date(this.classGroup.coachingEngagement.engagementDate);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.localEngagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format - the date from this.classGroup.coachingEngagement.engagementDate in local timezone

      // Reset to original UTC date
      date = new Date(this.classGroup.coachingEngagement.engagementDate);
      this.engagementData.engagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format - the date from this.classGroup.coachingEngagement.engagementDate in UTC

      console.log("existing as coachingEngagement")
      console.log("pre-existing server date:")
      console.log(this.classGroup.coachingEngagement.engagementDate)
      console.log("pre-existing formatted date:")
      console.log(this.engagementData.engagementDate)
      console.log("formatted local date:")
      console.log(this.localEngagementDate)
      //let date = new Date(this.classGroup.coachingEngagement.engagementDate);
      //this.engagementData.engagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format
      //date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      //this.localEngagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format
      this.engagementData.completed = this.classGroup.coachingEngagement.completed;
      // add here all pre-existing values
      this.engagementData.id = this.classGroup.coachingEngagement.id;
      this.engagementData.classGroupId = this.classGroup.coachingEngagement.classGroupId;
      this.engagementData.resources = this.classGroup.coachingEngagement.resources;
    } else {

      let date = new Date();
      this.localEngagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format - default current dateTime in local timezone
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      this.engagementData.engagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format - default current datetime in UTC

      console.log("new as coachingEngagement")
      console.log("current server formatted date:")
      console.log(this.engagementData.engagementDate)
      console.log("current formatted local date:")
      console.log(this.localEngagementDate)

      //let date = new Date();
      //this.engagementData.engagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format - default current datetime
      //date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      //this.localEngagementDate = date.toISOString().substring(0, 16); // 'YYYY-MM-DDTHH:MM' format - default current dateTime in local timezone
    }
  },
  created() {
    this.getSchools();
  },
  computed: {
    ...mapState({
      schools: (state) => state.school.schools,
      classGroup: (state) => state.classGroup.classGroup
    }),
    yearOptions() {
      return [this.initYear];
    },
    schoolOptions() {
      let placeholderOption = {
        placeholder: {
          id: undefined,
          name: this.$t("selectInput.placeholder.select"),
        },
      };
      let schools = this.schools;
      return { ...placeholderOption, ...schools };
    },
    programmeOptions() {
      let placeholderOption = {
        id: undefined,
        name: this.$t("selectInput.placeholder.select"),
      };
      let programmesArr = this.$store.getters.programmesForSchoolId(
        this.formData.schoolId
      );
      programmesArr.sort((a, b) => a.name - b.name);
      return [placeholderOption, ...programmesArr];
    },
    isNew() {
      return this.classId === undefined;
    },
  },
  methods: {
    ...mapActions([
      "getSchools",
      "createClassGroup",
      "updateClassGroup",
      "getClassGroup",
      "getSideMenu",
      "getClassGroupFullDetailsFor",
      // CoachingEngagement actions
      "createCoachingEngagement",
      "updateCoachingEngagement"
    ]),
    submit() {
      if (this.isNew) {
        this.submitNew();
      } else {
        this.submitUpdate();
      }
    },
    submitNew() {
      const newData = {
        ...this.formData,
        name: this.formData.name.trim(),
      };
      this.createClassGroup(newData).then(
        (response) => {
          if (this.isCoachingEngagement) {   
            console.log("*** creating coaching engagement into new classgroup")
            console.log("response on createClassGroup: ", response)  
            console.log("engagementData(1): ", this.engagementData)         
            // new coachingEngagement need to set classId
            // here we need to get the classId from the response

            // Convert local datetime to UTC and set the engagementDate in engagementObject
            let date = new Date(this.localEngagementDate);

            console.log("Saving NEW consulting Engagement ")
            console.log("Date from localDat: ")
            console.log(date)
            console.log("Date's timezone offset:")
            console.log(date.getTimezoneOffset())

            // no need to separately change the zone, as zone is included in the date
            // date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

            console.log("Date after timezone adjustment:")
            console.log(date)

            const newEngagementData = {
              ...this.engagementData,
              classGroupId: response.classGroup.id,
              engagementDate: date.toISOString(),
            } 
            this.engagementData.classGroupId = response.classGroup.id // this.classId    
            this.engagementData.engagementDate = date.toISOString();
            console.log("engagementData(2): ", this.engagementData)
            console.log("newEngagementData: ", newEngagementData)
            // this.createCoachingEngagement(this.engagementData);
            this.createCoachingEngagement(newEngagementData);
          }
          this.$router.push({
            name: "Programme",
            params: {
              year: this.formData.year,
              programmeId: this.formData.programmeId,
            },
          });
          this.$toastr.success(
            this.$t("classGroupModal.success.created", {
              className: this.formData.name,
              year: this.formData.year,
            })
          );
          this.$modal.closeAll();
        },
        (error) => {
          this.$toastr.error(error.data && error.data.message);
        }
      );
    },
    submitUpdate() {
      const newData = {
        classId: this.classId,
        ...this.formData,
        name: this.formData.name.trim(),
      };
      this.updateClassGroup(newData).then(
        ({ status, data }) => {
          if (this.isCoachingEngagement) {
            // Convert local datetime to UTC and set the engagementDate in engagementObject
            let date = new Date(this.localEngagementDate);

            console.log("Saving CHANGE consulting Engagement ")
            console.log("Date from localDat: ")
            console.log(date)
            console.log("Date's timezone offset:")
            console.log(date.getTimezoneOffset())

            // no need to separately change timezone as the date includes timezone
            // date.setMinutes(date.getMinutes() + date.getTimezoneOffset());            
            this.engagementData.engagementDate = date.toISOString();

            console.log("Date after timezone adjustment:")
            console.log(date)
            console.log("Date in server object:")
            console.log(this.engagementData.engagementDate)



            if (this.engagementData.id) {
              console.log("updating coaching engagement into existing classgroup")
              console.log("engagementData: ", this.engagementData)
              this.updateCoachingEngagement(this.engagementData);
            } else {
              // new coachingEngagement need to set classId
              this.engagementData.classGroupId = this.classId
              console.log("creating coaching engagement into existing classgroup")
              console.log("engagementData: ", this.engagementData)
              this.createCoachingEngagement(this.engagementData);
            }
          }
          if (status === constants.RESPONSE_STATUS.OK && data) {
            // update class page, and update sidemenu
            this.getClassGroup(this.classId);
            this.getSideMenu(this.formData.year);
            this.getClassGroupFullDetailsFor(this.classId);
            this.$toastr.success(
              this.$t("classGroupModal.success.updated", {
                className: this.formData.name,
                year: this.formData.year,
              })
            );
            this.$modal.closeAll();
          } else {
            this.$toastr.error(data && data.message);
          }
        },
        (error) => {
          this.$toastr.error(error.data && error.data.message);
        }
      );
    },

    toggleStatus() {
      this.engagementData.completed = !this.engagementData.completed;
    },

  },
};
</script>

<style lang="scss" scoped>
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
.status-button {
  background: none;
  border: none;
  color: inherit;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}
</style>
