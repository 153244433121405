// import * as types from '../mutation-types'

// initial state
const state = {
	termFilters: [{
		label: 'termFilter.baseline',
		value: 0
	}, {
		label: 'termFilter.term1',
		value: 1
	}, {
		label: 'termFilter.term2',
		value: 2
	}, {
		label: 'termFilter.term3',
		value: 3
	}, {
		label: 'termFilter.term4',
		value: 4
	}]
};

// getters
const getters = {
	termFilters(state) {
		return state.termFilters;
	},
	termFiltersWithoutBaseline(state) {
		return state.termFilters.filter(option => option.value !== 0);
	}
};

// actions
const actions = {
};

// mutations
const mutations = {
};

export default {
	state,
	getters,
	actions,
	mutations
};
