<template>
  <domain-list ref="programmePlanDomainList" :retain-elos="retainElos">
    <student-programme-plan-domain-list-card
      @addEloClicked="openDomainListAccordionFor"
      v-for="card in curriculum.domains"
      :key="card.id"
      :domain-id="card.id"
      :text-id="card.domainId"
      :text-name="card.domainName"
      :details="curriculum.domainDetails[card.id]"
      :retain-elos="retainElos"
      :programmePlanStatus="programmePlanStatus"
    >
    </student-programme-plan-domain-list-card>
  </domain-list>
</template>

<script>
import { mapGetters } from "vuex";
import DomainList from "@/components/DomainList";
import StudentProgrammePlanDomainListCard from "@/components/StudentProgrammePlanDomainListCard";

export default {
  name: "StudentProgrammePlanDomainList",
  components: {
    DomainList,
    StudentProgrammePlanDomainListCard,
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
    }),
  },
  data() {
    return {};
  },
  props: {
    programmePlanStatus: {
      type: Object,
    },
    retainElos: {
      type: Function,
    },
  },
  methods: {
    openDomainListAccordionFor(domainId, contentTarget) {
      this.$refs.programmePlanDomainList.openAccordionFor(
        domainId,
        contentTarget
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
