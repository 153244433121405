import masterReport from '@/api/masterReport';

// initial state
const state = {
	leadershipReport: [],
	selectionList: [],
	comparisons: [],
	reportType: 0,
	dataSelected: {},
	bodyReport: {},
	selectedList: {},
	attainment: [],
	title: ''
};

// getters
const getters = {
	leadershipReport: state => state.leadershipReport,
	selectionList: state => state.selectionList,
	comparisons: state => state.comparisons,
	reportType: state => state.reportType,
	dataSelected: state => state.dataSelected,
	bodyReport: state => state.bodyReport,
	selectedList: state => state.selectedList,
	attainmentData: state => state.attainment,
	title: state => state.title
};

// actions
const actions = {
	async createReport({ commit }, body) {
		try {
			const response = await masterReport.createReport(body);
			commit('SET_COMPARISONS', response.body.searchRequest.reportType[0].comparisons);
			commit('SET_REPORT', response.body.reportList);
			commit('SET_BODY_REPORT', body);

			return true;
		} catch (e) {
			console.log(e);
			commit('RESET_REPORT');
			return false;
		}
	},
	async downloadReport(vuex, body) {
		try {
			return await masterReport.createReport(body);
		} catch (e) {
			console.log(e);
			return false;
		}
	},
	setSelection({ commit }, value) {
		commit('SET_SELECTION', value);
	},
	setComparisons({ commit }, value) {
		commit('SET_COMPARISONS', value);
	},
	setReportType({ commit }, value) {
		commit('SET_REPORT_TYPE', value);
	},
	setDataSelection({ commit }, value) {
		commit('SET_DATA_SELECTION', value);
	},
	setSelectedList({ commit }, value) {
		commit('SET_SELECTED_LIST', value);
	},
	setAttainment({ commit }, value) {
		commit('SET_ATTAINMENT', value);
	},
	setTitle({ commit }, value) {
		commit('SET_TITLE', value);
	}
};

// mutations
const mutations = {
	SET_REPORT(state, value) {
		state.leadershipReport = value;
	},
	RESET_REPORT(state) {
		state.leadershipReport = [];
	},
	SET_SELECTION(state, value) {
		state.selectionList = value;
	},
	RESET_SELECTION(state) {
		state.selectionList = [];
	},
	SET_COMPARISONS(state, value) {
		state.comparisons = value;
	},
	RESET_COMPARISONS(state) {
		state.comparisons = [];
	},
	SET_REPORT_TYPE(state, value) {
		state.reportType = value;
	},
	SET_DATA_SELECTION(state, value) {
		state.dataSelected = value;
	},
	SET_BODY_REPORT(state, value) {
		state.bodyReport = value;
	},
	SET_SELECTED_LIST(state, value) {
		state.selectedList = value;
	},
	SET_ATTAINMENT(state, value) {
		state.attainment = value;
	},
	SET_TITLE(state, value) {
		state.title = value;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
