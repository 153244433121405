import store from '@/store'

const modal = {
	install (Vue, options) {
		if (this.installed) {
			return
		}
		this.installed = true

		Vue.$modal = Vue.prototype.$modal = {
			show (params) {
				params.id = 'reveal_' + randId()
				store.dispatch('addModal', params)
			},
			close (id) {
				store.dispatch('removeModal', id)
			},
			closeAll () {
				store.dispatch('clearAllModals')
			}
		}
	}
}

function randId () {
	return Math.random().toString(36).substr(2, 10)
}

export default modal
