<template>
  <div class="edit-elo-modal" v-cloak>
    <h4 class="modal-title">{{ student.displayName }}{{ modalTitle }}</h4>

    <div class="edit-elo-modal-content">
      <elo-list-card-details
        class="elo-card margin-top-1 card-section"
        :elo-code="eloCode"
        :elo-name="eloName"
        :programme-codes="programmeCodes"
      >
      </elo-list-card-details>

      <div class="edit-elo-modal-content-body margin-top-1">
        <tabs-content :tabs-content-list="tabList">
          <terminate-elo-modal-remarks
            :slot="tabList[0].target"
            :elo-code="eloCode"
            :editable="true"
            :student-id="studentId"
            :selected-year="selectedYear"
            :toBeEloStatus="toBeEloStatus"
          >
          </terminate-elo-modal-remarks>
        </tabs-content>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import TabsContent from "./TabsContent";
import EloListCardDetails from "./EloListCardDetails";
import TerminateEloModalRemarks from "./TerminateEloModalRemarks";

export default {
  name: "TerminateEloModal",
  components: {
    TabsContent,
    EloListCardDetails,
    TerminateEloModalRemarks,
  },
  props: {
    eloCode: String,
    eloName: String,
    studentId: String,
    selectedYear: Number,
    programmeCodes: Array,
    componentType: {
      type: String,
      required: true,
      validator: (value) => {
        let typesAllowed = ["Attainment", "Exemption"];
        return _.includes(typesAllowed, value);
      },
    },
  },
  data() {},
  computed: {
    tabList: function () {
      let labelOptions = {
        Attainment: "terminateElo.remarks.tabs.attainment",
        Exemption: "terminateElo.remarks.tabs.exemption",
      };

      return [
        {
          label: labelOptions[this.componentType],
          target: "remarks",
          icon: ["far", "comment"],
          isSelected: true,
        },
      ];
    },
    toBeEloStatus: function () {
      let eloStatusOptions = {
        Attainment: "ATTAINED",
        Exemption: "EXEMPTED",
      };

      return eloStatusOptions[this.componentType];
    },
    ...mapGetters({
      studentPlan: "studentPlan",
    }),
    student: function () {
      return this.$store.getters.studentProfile[this.studentId]
        ? this.$store.getters.studentProfile[this.studentId].student
        : {};
    },
    modalTitle: function () {
      let titleOptions = {
        Attainment: this.$t("terminateEloModal.attainment.modalTitle"),
        Exemption: this.$t("terminateEloModal.exemption.modalTitle"),
      };
      return titleOptions[this.componentType];
    },
  },
  mounted() {
    let params = {
      studentId: this.studentId,
      planYear: this.selectedYear,
    };
    this.$store.dispatch("getStudentProfile", params);
    this.$store.dispatch("getStudentPlan", params);
  },
  destroyed() {
    // Clear modal
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.elo-card {
  background-color: $lighter-gray;
  border-radius: $global-radius;
}
</style>
