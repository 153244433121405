// coachingOutcomeAssessment.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingOutcomeAssessmentApi from '@/api/coachingOutcomeAssessment'
import constants from '@/constants';

// initial state
const state = {
  // old coachingEngagement: null,
  coachingOutcomeAssessment: {},
  coachingOutcomeAssessments: [],
};

// getters
const getters = {
  coachingOutcomeAssessment: state => state.coachingOutcomeAssessment,
  coachingOutcomeAssessments: state => state.coachingOutcomeAssessments,
};

// actions
const actions = {
  async getCoachingOutcomeAssessment({ commit }, id) {
    const response = await CoachingOutcomeAssessmentApi.getCoachingOutcomeAssessment(id)
    commit(types.SET_COACHING_OUTCOME_ASSESSMENT, response.data)
  },

  async getCoachingOutcomeAssessmentsForSession({ commit }, coachingSessionId) {
    const response = await CoachingOutcomeAssessmentApi.getCoachingOutcomeAssessmentsForSession(coachingSessionId)
    console.log("STORE: getCoachingOutcomeAssessmentsForSession: ", response.data)
    if (response.data) {
      console.log("response.data", response.data)
      commit(types.SET_COACHING_OUTCOME_ASSESSMENTS, response.data)
      return response.data
    }
    // Here we should somehow associate these assessments with the session
  },

  async createCoachingOutcomeAssessment({ commit, dispatch }, { studentCoachingEngagementId, coachingOutcomeId, coachingSessionId, assessmentTime, result }) { // Note that studentCoachingEngagementId is included in the call
    console.log("STORE: createCoachingOutcomeAssessment - { coachingOutcomeId, coachingSessionId, assessmentTime, result }:", { coachingOutcomeId, coachingSessionId, assessmentTime, result })
    const response = await CoachingOutcomeAssessmentApi.createCoachingOutcomeAssessment(coachingOutcomeId, coachingSessionId, assessmentTime, result);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_OUTCOME_ASSESSMENT, response.data.coachingOutcomeAssessment);
      // here we need to get the studentCoachingEngagement again to have the latest info with coachingOutcomeAssessment
      await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
    }
  },

  async updateCoachingOutcomeAssessment({ state, commit, dispatch, rootState }, { id, studentCoachingEngagementId, assessmentTime, result }) { // Note that studentCoachingEngagementId is included in the call
    const response = await CoachingOutcomeAssessmentApi.updateCoachingOutcomeAssessment(id, assessmentTime, result)
    commit(types.UPDATE_COACHING_OUTCOME_ASSESSMENT, response.data.coachingOutcomeAssessment)
    // here we need to get the StudentCoachingEngagement again to have the latest info
    // NOTE: - this requires the studentCoachingEngagementId to be provided - we include in the call for now
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },

  async deleteCoachingEngagement({ commit, dispatch }, id, studentCoachingEngagementId) { // Note that studentCoachingEngagementId is included in the call
    await CoachingOutcomeAssessmentApi.deleteCoachingOutcomeAssessment(id)
    commit(types.DELETE_COACHING_OUTCOME_ASSESSMENT, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_OUTCOME_ASSESSMENT](state, coachingOutcomeAssessment) {
    Vue.set(state.coachingOutcomeAssessment, coachingOutcomeAssessment);
  },
  [types.SET_COACHING_OUTCOME_ASSESSMENTS] (state, coachingOutcomeAssessments) {
    console.log("coachingOutcomeAssessments:", coachingOutcomeAssessments)
    Vue.set(state.coachingOutcomeAssessments, coachingOutcomeAssessments)
  },
  [types.ADD_COACHING_OUTCOME_ASSESSMENT] (state, coachingOutcomeAssessment) {
    // state.coachingOutcomeAssessments.coachingOutcomeAssessments.unshift(coachingOutcomeAssessment) // was unshift then push
    if (state.coachingOutcomeAssessments.coachingOutcomeAssessments && state.coachingOutcomeAssessments.coachingOutcomeAssessments.length > 0) {
      Vue.set(state.coachingOutcomeAssessments, 'coachingOutcomeAssessments', [coachingOutcomeAssessment, ...state.coachingOutcomeAssessments.coachingOutcomeAssessments])
    } else {
      Vue.set(state.coachingOutcomeAssessments, 'coachingOutcomeAssessments', [coachingOutcomeAssessment])
    }
  },
  [types.UPDATE_COACHING_OUTCOME_ASSESSMENT] (state, coachingOutcomeAssessment) {
    Vue.set(state.coachingOutcomeAssessment, coachingOutcomeAssessment)
    // const index = state.coachingEngagements.findIndex(e => e.id === engagement.id)
    // if (index !== -1) {
    //   Vue.set(state.coachingEngagements, index, engagement)
    // }
  },
  [types.DELETE_COACHING_OUTCOME_ASSESSMENT] (state, coachingOutcomeAssessmentId) {
    state.coachingOutcomeAssessments = state.coachingOutcomeAssessments.filter(e => e.id !== coachingOutcomeAssessmentId)
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
