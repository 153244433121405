<template>
  <div class="filter-dropdown-control">
    <div @click="toggleDropdown()" class="control">Select Classes/Engagements</div>
    <div v-show="showDropdown" class="dropdown">
      <div class="dropdown-content grid-x">
        <div
          v-for="classGroup in classGroups"
          :key="classGroup.id"
          class="cell small-6"
        >
          <div
            @click="selectClassId(classGroup.id)"
            :class="{ active: isSelected(classGroup.id) }"
            class="cell-value small-margin"
          >
            {{ classGroup.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassGroupFilterDropdown",
  model: {
    prop: "selectedClassIdArr",
    event: "arr-updated",
  },
  props: {
    selectedClassIdArr: {
      type: Array,
    },
    classGroups: {
      type: Object,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectClassId(classId) {
      const foundIndex = this.selectedClassIdArr.findIndex(
        (existingClassId) => {
          return classId === existingClassId;
        }
      );

      let updatedArr = [...this.selectedClassIdArr];
      if (foundIndex === -1) {
        updatedArr = [...updatedArr, classId];
      } else {
        updatedArr.splice(foundIndex, 1);
      }
      this.$emit("arr-updated", updatedArr);
    },
    isSelected(classId) {
      const foundIndex = this.selectedClassIdArr.findIndex(
        (existingClassId) => {
          return classId === existingClassId;
        }
      );

      return foundIndex > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dropdown-control {
  position: relative;
  margin-bottom: $global-margin / 1.5;

  .control {
    @extend .small-padding;
    @extend .small-header;

    cursor: pointer;
    border: 1px solid $darker-gray;
    border-radius: 6px;
    background-color: $bg-selected;
    border-color: $gray;
    box-shadow: 0px 1px 0px 0px $gray;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.15;
  }

  .dropdown {
    margin-top: $global-margin / 2.5;
    position: absolute;
    width: 100%;
    z-index: 10;

    @extend .small-text;
    color: $black;
    padding: 0;
    border: 1px solid $secondary-color;
    border-radius: $global-radius;
    background-color: $light-gray;

    .cell-value {
      @extend .small-padding;
      color: $darker-gray;
      border: 1px solid $secondary-color;
      border-radius: $global-radius;
      background-color: $white;
      line-height: 1;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $black;
      }

      &.active {
        @extend .sans-serif-bold;
        color: $black;
        background-color: $bg-selected;
      }
    }
  }
}
.small-margin {
  margin-top: $global-margin / 2.5;
  margin-bottom: $global-margin / 2.5;
  margin-left: $global-margin / 2.5;
  margin-right: $global-margin / 2.5;
}
.small-padding {
  padding-top: $global-padding / 2.5;
  padding-bottom: $global-padding / 2.5;
  padding-left: $global-padding / 2.5;
  padding-right: $global-padding / 2.5;
  height: auto;
}
.small-text {
  @extend .sans-serif-regular;
  font-size: $small-font-size;
}
.small-header {
  @extend .sans-serif-bold;
  font-size: $small-font-size;
}
</style>
