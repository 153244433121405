import * as types from '../mutation-types';
import lessonPlanApi from '@/api/lessonPlan';

// initial state
const state = {
	lessonPlans: [],
	lessonPlan: null,
	isEditMode: false,
	lessonElos: [],
	studentElosForTerm: []
};

// getters
const getters = {
	lessonPlans: (state) => (id) => {
		return state.lessonPlans[id];
	},
	lessonPlan(state) {
		return state.lessonPlan;
	},
	isEditMode(state) {
		return state.isEditMode;
	},
	studentElosForTerm: (state) => (classGroupId, planTerm) => {
		let key = classGroupId + '_' + planTerm;
		return state.studentElosForTerm[key];
	},
	lessonElos: (state) => (lessonId) => {
		return state.lessonElos[lessonId];
	}
};

// actions
const actions = {
	createLessonPlan({ commit, getters }, { classGroupId, planTerm }) {
		return lessonPlanApi.createLessonPlan(classGroupId, planTerm).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_PLAN, resp.data);
				return getters.lessonPlan;
			}
		});
	},
	updateLessonPlan({ commit, getters }, { lessonId, lessonPlan }) {
		return lessonPlanApi.updateLessonPlan(lessonId, lessonPlan).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_PLAN, resp.data);
			}
		});
	},
	setLessonPlan({ commit, getters }, { lessonPlan }) {
		commit(types.SET_LESSON_PLAN, lessonPlan);
	},
	setIsEditMode({ commit, getters }, isEditMode) {
		commit(types.SET_IS_EDIT_MODE, isEditMode);
	},
	getLessonPlans({ commit }, { classGroupId, planYear, planTerm }) {
		return lessonPlanApi.getLessonPlans(classGroupId, planYear, planTerm).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_PLANS, { classGroupId, lessonPlans: resp.data });
			}
		});
	},
	deleteLessonPlan({ commit }, { classGroupId, lessonId }) {
		return lessonPlanApi.deleteLessonPlan(lessonId);
	},
	getLessonPlan({ commit }, { lessonId }) {
		return lessonPlanApi.getLessonPlan(lessonId).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_PLAN, resp.data);
			}
		});
	},
	getLessonPlanElos({ commit }, { lessonId }) {
		return lessonPlanApi.getLessonPlanElos(lessonId).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_ELOS, { lessonId, lessonElos: resp.data });
			}
		});
	},
	getStudentElosForTerm({ commit }, { classGroupId, planTerm }) {
		return lessonPlanApi.getStudentElosForTerm(classGroupId, planTerm).then((resp) => {
			if (resp.data) {
				commit(types.SET_STUDENT_ELOS_FOR_TERM, { classGroupId, planTerm, studentElos: resp.data });
			}
		});
	},
	addElosToLessonPlan({ commit, getters }, { lessonId }) {
		return lessonPlanApi.addElosToLessonPlan(lessonId, getters.elosToAdd).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_ELOS, { lessonId, lessonElos: resp.data });
			}
		});
	},
	removeEloFromLessonPlan({ commit }, { lessonId, eloCode }) {
		return lessonPlanApi.removeEloFromLessonPlan(lessonId, eloCode).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_ELOS, { lessonId, lessonElos: resp.data });
			}
		});
	}
};

// mutations
const mutations = {
	[types.SET_LESSON_PLANS](state, { classGroupId, lessonPlans }) {
		state.lessonPlans = { ...state.lessonPlans, [classGroupId]: lessonPlans };
	},
	[types.SET_LESSON_PLAN](state, lessonPlan) {
		state.lessonPlan = lessonPlan;
	},
	[types.SET_IS_EDIT_MODE](state, isEditMode) {
		state.isEditMode = isEditMode;
	},
	[types.SET_STUDENT_ELOS_FOR_TERM](state, { classGroupId, planTerm, studentElos }) {
		let key = classGroupId + '_' + planTerm;
		state.studentElosForTerm = { ...state.studentElosForTerm, [key]: studentElos };
	},
	[types.SET_LESSON_ELOS](state, { lessonId, lessonElos }) {
		state.lessonElos = { ...state.lessonElos, [lessonId]: lessonElos };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
