<template>
  <div class="profile-wrapper" v-cloak>
    <student-profile
      v-if="studentProfile && studentProfile.student"
      :profile="studentProfile.student"
      :class-group="studentProfile.classGroup"
    ></student-profile>
    <student-details-control-section
      v-if="studentDetails && studentDetails.programme"
      :key="studentId"
    >
      <total-elo-filter
        v-if="$route.name === 'Piep'"
        :student-details="studentDetails"
      >
      </total-elo-filter>
      <div class="cell auto"></div>
      <approval-dropdown
        v-if="
          studentPlanStatus && studentPlanStatus.planStatus !== 'NOT_CREATED'
        "
        :teacherAuthProfile="teacherAuthProfile"
        :planStatus="studentPlanStatus.planStatus"
        @planStatusChanged="handlePlanStatusChange"
      ></approval-dropdown>
      <div class="cell">
        <div class="year-selection thin-gray-wrapper">
          <plan-year-selector
            v-if="studentPlanStatus && $route.name === 'Piep'"
            :is-draft="studentPlanStatus.planStatus === 'DRAFT'"
            :start-year="studentDetails.startYear"
            :end-year="studentDetails.endYear"
            @end-year-selected="handleStudentPlanEndYearSelected"
          />
        </div>
      </div>
    </student-details-control-section>

    <router-view></router-view>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import StudentProfile from "@/components/StudentProfile";
import StudentDetailsControlSection from "@/components/StudentDetailsControlSection";
import TotalEloFilter from "./TotalEloFilter";
import ApprovalDropdown from "./ApprovalDropdown";
import PlanYearSelector from "./PlanYearSelector";

export default {
  name: "Learning",
  components: {
    StudentProfile,
    StudentDetailsControlSection,
    TotalEloFilter,
    ApprovalDropdown,
    PlanYearSelector,
  },
  props: ["studentId"],
  data() {
    return {
      modalOptions: {
        Piep: {
          DRAFT: {
            header: this.$t("approvalDropdown.modal.draft.programPlan.header"),
            text: this.$t("approvalDropdown.modal.draft.text"),
            includeConfirm: true,
          },
          APPROVED: {
            header: this.$t(
              "approvalDropdown.modal.approve.programPlan.header"
            ),
            text: this.$t("approvalDropdown.modal.approve.text"),
            includeConfirm: true,
          },
        },
        Aiep: {
          DRAFT: {
            header: this.$t("approvalDropdown.modal.draft.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.draft.text"),
            includeConfirm: true,
          },
          APPROVED: {
            header: this.$t("approvalDropdown.modal.approve.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.approve.text"),
            includeConfirm: true,
          },
        },
        Aiep0: {
          DRAFT: {
            header: this.$t("approvalDropdown.modal.draft.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.draft.text"),
            includeConfirm: true,
          },
          APPROVED: {
            header: this.$t("approvalDropdown.modal.approve.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.approve.text"),
            includeConfirm: true,
          },
        },
        Aiep1: {
          DRAFT: {
            header: this.$t("approvalDropdown.modal.draft.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.draft.text"),
            includeConfirm: true,
          },
          APPROVED: {
            header: this.$t("approvalDropdown.modal.approve.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.approve.text"),
            includeConfirm: true,
          },
        },
        Aiep3: {
          DRAFT: {
            header: this.$t("approvalDropdown.modal.draft.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.draft.text"),
            includeConfirm: true,
          },
          APPROVED: {
            header: this.$t("approvalDropdown.modal.approve.annualPlan.header"),
            text: this.$t("approvalDropdown.modal.approve.text"),
            includeConfirm: true,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
      teacherAuthProfile: "profile",
    }),
    studentDetails: function () {
      return this.$store.getters.studentPlan[this.$route.params.studentId];
    },
    studentProfile: function () {
      return this.$store.getters.studentProfile[this.$route.params.studentId];
    },
    // Make sure to check for undefined studentPlanStatus when initializing child components in this component.
    // Because the programmePlanStatus and annualPlanStatus fetching is executed within their respective views that are child to this component
    // e.g. StudentDetailsProgrammePlan, StudentDetailsAnnualPlan
    studentPlanStatus: function () {
      let routeName = this.$route.name;
      if (
        routeName === "Aiep" ||
        routeName === "Aiep0" ||
        routeName === "Aiep1" ||
        routeName === "Aiep3"
      ) {
        return this.annualPlanStatus;
      }

      return this.programmePlanStatus;
    },
    programmePlanStatus: function () {
      return this.$store.getters.studentProgrammePlanStatuses[
        this.$route.params.studentId
      ];
    },
    annualPlanStatus: function () {
      return this.$store.getters.studentAnnualPlanStatuses[
        this.$route.params.studentId
      ];
    },
  },
  beforeRouteUpdate(to, from, next) {
    let student = this.getStudentInClass(to.params.studentId, to.params.classId);
    this.setSelectedStudentClassName(student);
    this.$store.dispatch("getStudentProfile", {
      studentId: to.params.studentId,
      planYear: to.params.year,
    });
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let student = vm.getStudentInClass(to.params.studentId, to.params.classId);
      vm.setSelectedStudentClassName(student);
      vm.$store.dispatch("getStudentProfile", {
        studentId: to.params.studentId,
        planYear: to.params.year,
      });
    });
  },
  methods: {
    getStudent(studentId) {
      let students = _.flatMap(this.$store.getters.filteredClasses, "students");
      return students.find((it) => it.id === studentId);
    },
    getStudentInClass(studentId, classId) {
		let classes = this.$store.getters.filteredClasses;
		let targetClass = classes.find((cls) => cls.id === classId);
		if (targetClass && targetClass.students) {
			return targetClass.students.find((student) => student.id === studentId);
		}
		return null;
	  },
    setSelectedStudentClassName(student) {
      this.$store.dispatch(
        "setSelectedItemClass",
        student && student.identifier
      );
    },
    handlePlanStatusChange(toBePlanStatus) {
      let routeName = this.$route.name;
      let modalToShow = this.modalOptions[routeName][toBePlanStatus];
      let dispatchFunction = null;

      if (
        routeName === "Aiep" ||
        routeName === "Aiep0" ||
        routeName === "Aiep1" ||
        routeName === "Aiep3"
      ) {
        let vm = this;
        dispatchFunction = function () {
          vm.$store.dispatch("setStudentAnnualPlanStatus", {
            studentId: vm.$route.params.studentId,
            planYear: vm.$route.params.year,
            planStatus: toBePlanStatus,
          });
        };
      } else {
        let vm = this;
        dispatchFunction = function () {
          vm.$store.dispatch("setStudentProgrammePlanStatus", {
            studentId: vm.$route.params.studentId,
            year: vm.$route.params.year,
            planStatus: toBePlanStatus,
          });
        };
      }

      modalToShow.successCallback = dispatchFunction;
      this.$modal.show(modalToShow);
    },
    ...mapActions(["setStudentPlanEndYear"]),
    handleStudentPlanEndYearSelected(endYear) {
      const isSuccessful = this.setStudentPlanEndYear({
        studentId: this.studentId,
        startYear: this.studentDetails.startYear,
        endYear,
      });

      if (isSuccessful) {
        this.$toastr.success(
          this.$t("studentDetailsProgrammePlan.endYear.changed", {
            endYear: endYear,
          })
        );
      } else {
        this.$toastr.error(this.$t("error.unable.tryAgain"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.year-selection {
  margin-top: $global-margin / 2;
  display: inline-block;
}
</style>
