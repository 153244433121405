<template>
  <div class="container-wrapper" v-cloak>
    <class-group-profile
      v-if="isLessonList()"
      :class-group="classGroup"
    ></class-group-profile>
    <div class="grid-x">
      <div class="cell auto">
        <div
          v-if="isStudentLessonRecord()"
          class="record-link no-print grid-x align-middle"
        >
          <a class="cell shrink" @click="navigateToLessonPlan()">
            <font-awesome-icon
              icon="chevron-left"
              class="color--primary"
            ></font-awesome-icon>
            <strong
              >{{ $t("lessonPlan.header.term") }}
              {{ $route.params.term }}:</strong
            >
            {{ $t("lessonPlan.header.lesson") }} {{ $route.params.lessonId }}
          </a>
          <span class="cell auto"></span>
          <div class="grid-x shrink">
            <print-button></print-button>
          </div>
        </div>
        <div
          v-else-if="isStudentLessonPlan()"
          class="record-link no-print grid-x align-middle"
        >
          <a class="cell shrink" @click="navigateToLessonList()">
            <font-awesome-icon
              icon="chevron-left"
              class="color--primary"
            ></font-awesome-icon>
            {{ $t("lessonPlan.header.back") }}
          </a>
          <span class="cell auto"></span>
          <div class="cell shrink no-print">
            <div
              class="print-button no-print selector-radio student-record-link"
              @click="navigateToStudentRecord()"
            >
              <font-awesome-icon icon="id-card"></font-awesome-icon>
              <small class="sans-serif-bold">{{
                $t("lessonPlan.link.studentRecord")
              }}</small>
            </div>
          </div>
          <div class="cell shrink">
            <print-button></print-button>
          </div>
        </div>
        <div class="grid-x align-middle" v-else>
          <div class="grid-x cell shrink margin-bottom-1 align-middle">
            <span class="print-term cell shrink"
              >{{ $t("master.term") }}:&nbsp;&nbsp;</span
            >
            <div class="cell shrink">
              <radio-filter
                :option-list="optionTermList"
                :callback="navigateTerm"
                :selected-value="this.$route.params.term"
              ></radio-filter>
            </div>
          </div>
          <div
            v-if="classGroup && classGroup.id && isLessonList()"
            class="cell small-12 medium-auto grid-x text-right create-lesson"
          >
            <div class="cell auto no-print">
              <button class="button clear" @click="createLessonPlan()">
                <font-awesome-icon icon="plus"></font-awesome-icon>
                &nbsp;<b>{{ $t("lessonPlan.header.createLesson") }}</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ClassGroupProfile from "@/views/ClassGroup/ClassGroupProfile";
import RadioFilter from "./RadioFilter";
import PrintButton from "./PrintButton";
import termHandler from "@/mixins/termHandler";

export default {
  name: "LessonPlanHeader",
  components: {
    FontAwesomeIcon,
    ClassGroupProfile,
    RadioFilter,
    PrintButton,
  },
  mixins: [termHandler],
  props: ["year", "term", "classId", "lessonId"],
  computed: {
    ...mapGetters({
      classGroup: "classGroup",
    }),
  },
  methods: {
    ...mapActions(["updateLessonPlan"]),
    isLessonList() {
      return this.$route.name === "LessonPlanList";
    },
    isStudentLessonRecord() {
      return this.$route.name === "LessonPlanRecord";
    },
    isStudentLessonPlan() {
      return this.$route.name === "LessonPlan";
    },
    navigateToLessonPlan(classId, year, term, lessonId) {
      this.$router.push({
        name: "LessonPlan",
        params: {
          classId: classId || this.classId,
          year: year || this.year,
          term: term || this.term,
          lessonId: lessonId || this.lessonId,
        },
      });
    },
    navigateToLessonList(classId, year, term, lessonId) {
      this.$router.push({
        name: "LessonPlanList",
        params: {
          classId: classId || this.classId,
          year: year || this.year,
          term: term || this.term,
          lessonId: lessonId || this.lessonId,
        },
      });
    },
    createLessonPlan() {
      this.$store
        .dispatch("createLessonPlan", {
          classGroupId: this.classId,
          planTerm: this.term,
        })
        .then((data) => {
          let lessonPlan = {
            ...data,
            evidenceIndependentPractice: `<p><strong>Multiple Means of Representation (the “what” of learning):</strong></p><ul data-checked="false"><li>Offer ways of customising the display of information</li><li>Activate or supply background knowledge</li><li>Others: </li></ul><p><br></p><p> </p><p><strong>Multiple Means of Action and Expression (the “how” of learning):</strong></p><ul data-checked="false"><li>Vary the methods for response and navigation</li><li>Optimise access to tools and assistive technologies</li><li>Others: </li></ul><p><br></p><p> </p><p><strong>Multiple Means of Engagement (the “why” of learning):</strong></p><ul data-checked="false"><li>Optimise relevance, value and authenticity</li><li>Minimise threats and distractions</li><li>Heighten salience of goals and objectives</li><li>Others: </li></ul><p><br></p><p> </p><p>Description: </p><p></p><p> </p><p>  </p>`,
            evidenceClosureReview: `<p><strong>Multiple Means of Representation (the “what” of learning):</strong></p><ul data-checked="true"><li>Maximise transfer and generalisation</li></ul><ul data-checked="false"><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Action and Expression (the “how” of learning):</strong></p><ul data-checked="false"><li>Vary the methods for response and navigation</li><li>Enhance capacity for monitoring progress</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Engagement (the “why” of learning):</strong></p><ul data-checked="false"><li>Optimise relevance, value &amp; authenticity</li><li>Others: </li></ul><p><br></p><p><br></p><p>Description: </p><p></p>`,
            evidenceUnderstandContent: `<p><strong>Multiple Means of Representation (the “what” of learning):</strong></p><ul data-checked="true"><li>Offer ways of customising the display of information</li></ul><ul data-checked="false"><li>Offer alternatives for auditory information</li><li>Offer alternatives for visual information</li><li>Clarify vocabulary and symbols</li><li>Highlight patterns, critical features, big ideas and relationships</li><li>Guide information processing, visualisation and manipulation</li><li>Maximise transfer and generalisation</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Action and Expression (the “how” of learning):</strong></p><ul data-checked="false"><li>Vary the methods for response and navigation</li><li>Optimise access to tools and assistive technologies</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Engagement (the “why” of learning):</strong></p><ul data-checked="false"><li>Optimise relevance, value and authenticity</li><li>Minimise threats and distractions</li><li>Promote expectations and beliefs that optimise</li><li>motivation</li><li>Others: </li></ul><p><br></p><p><br></p><p>Description: </p><p></p>`,
            evidencePracticeContent: `<p><strong>Multiple Means of Representation (the “what” of learning):</strong></p><ul data-checked="false"><li>Maximise transfer and generalisation</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Action and Expression (the “how” of learning):</strong></p><ul data-checked="false"><li>Vary the methods for response and navigation</li><li>Optimise access to tools and assistive technologies</li><li>Use multiple media for communication</li><li>Use multiple tools for construction and composition</li><li>Guide appropriate goal setting</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Engagement (the “why” of learning):</strong></p><ul data-checked="false"><li>Optimise individual choice &amp; autonomy</li><li>Increase mastery-oriented feedback</li><li>Promote expectations and beliefs that optimise motivation</li><li>Develop self-assessment &amp; reflection</li><li>Foster collaboration and community</li><li>Others: </li></ul><p><br></p><p><br></p><p>Description: </p><p></p>`,
            evidenceApplyContent: `<p><strong>Multiple Means of Representation (the “what” of learning):</strong></p><ul data-checked="false"><li>Maximise transfer and generalisation</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Action and Expression (the “how” of learning):</strong></p><ul data-checked="false"><li>Use multiple media for communication</li><li>Use multiple tools for construction and composition</li><li>Vary the methods for response and navigation</li><li>Build fluencies with graduated levels of support for practice</li><li>and performance</li><li>Enhance capacity for monitoring progress</li><li>Others: </li></ul><p><br></p><p><br></p><p><strong>Multiple Means of Engagement (the “why” of learning):</strong></p><ul data-checked="false"><li>Promote expectations and beliefs that optimize motivation</li><li>Facilitate personal coping skills and strategies</li><li>Optimise relevance, value &amp; authenticity</li><li>Others: </li></ul><p><br></p><p><br></p><p>Description: </p><p></p>`,
          };

          this.updateLessonPlan({ lessonId: data.id, lessonPlan }).then(() => {
            this.setIsEditMode(true);
            this.navigateToLessonPlan(null, null, null, data.id);
          });
        });
    },
    setIsEditMode(val) {
      this.$store.dispatch("setIsEditMode", val);
    },
    navigateToStudentRecord() {
      this.$router.push({
        name: "LessonPlanRecord",
        params: {
          classId: this.$route.params.classId,
          year: this.$route.params.year,
          term: this.$route.params.term,
          lessonId: this.$route.params.lessonId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-selection {
  margin-top: $global-margin / 2;
  margin-bottom: $global-margin / 2;
  padding-left: $global-padding / 2;

  @include breakpoint(small) {
    margin: 0;
  }
}
.create-lesson {
  margin-top: $global-margin;

  @include breakpoint(medium) {
    margin: 0;
  }
}
.student-record-link {
  margin-right: $global-margin / 3;
}
@media print {
  .print-term {
    position: relative;
    top: -2px;
    float: left;
    padding-right: $global-padding / 2.5;
  }
}
</style>
