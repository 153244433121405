<template>
  <div>
    <p>{{ title }}</p>
    <div class="row underline" style="margin: 3vh 0" />
    <div>
      <p>Selected Options:</p>
      <p v-if="selectedList.school.length">
        School: {{ selectedList.school.join(", ") }}.
      </p>
      <p v-if="selectedList.programme.length">
        Programme: {{ selectedList.programme.join(", ") }}.
      </p>
      <p v-if="selectedList.classId.length">
        Class: {{ selectedList.classId.join(", ") }}.
      </p>
      <p v-if="selectedList.student.length">
        Student: {{ selectedList.student.join(", ") }}.
      </p>
      <p v-if="selectedList.domain.length">
        Domain: {{ selectedList.domain.join(", ") }}.
      </p>
      <p v-if="selectedList.tlo.length">
        TLO: {{ selectedList.tlo.join(", ") }}.
      </p>
      <p v-if="selectedList.elo.length">
        ELO: {{ selectedList.elo.join(", ") }}.
      </p>
      <p v-if="selectedList.programmeOutcome.length">
        Programme Outcome: {{ selectedList.programmeOutcome.join(", ") }}.
      </p>

      <p v-if="selectedList.learning.length">
        Learning Support Need: {{ selectedList.learning.join(", ") }}.
      </p>
      <p v-if="selectedList.behaviour.length">
        Behaviour Support Need: {{ selectedList.behaviour.join(", ") }}.
      </p>
      <p v-if="selectedList.supportNeed.length">
        Additional Support Need: {{ selectedList.supportNeed.join(", ") }}.
      </p>

      <p v-if="selectedList.coreSteps1 !== ''">
        {{ $t("masterReport.core.less", { step: selectedList.coreSteps1 }) }}
      </p>
      <p v-if="selectedList.attainment.length">
        Attainment: {{ selectedList.attainment.join(", ") }}.
      </p>
      <p v-if="selectedList.coreSteps2 !== ''">
        {{ $t("masterReport.core.more", { step: selectedList.coreSteps2 }) }}
      </p>
      <p v-if="selectedList.criteria !== ''">
        Criteria: {{ selectedList.criteria }}.
      </p>
    </div>
    <div class="row underline" style="margin: 3vh 0" />
    <div id="mainWrapper">
      <div id="outerWrapper">
        <div id="categories" style="width: 30%">
          <p
            :style="`margin: 0 0 0 ${i * 2}vw`"
            v-for="(select, i) of selectionList"
            :key="select"
          >
            {{ select }}
          </p>
        </div>
        <div
          v-for="(data, i) of year"
          :key="i"
          id="categories"
          style="
            width: 35%;
            text-align: center;
            display: flex;
            align-items: center;
          "
        >
          <p>{{ data.term === 0 ? "Baseline" : `Term ${data.term}` }}</p>
          <p>{{ year[i].year }}</p>
          <font-awesome-icon
            @click="changeCoreSteps()"
            :icon="coreSteps ? 'plus' : 'minus'"
            style="cursor: pointer; user-select: none"
          ></font-awesome-icon>
          <div id="subHeader">
            <!-- '${attainmentData*11 + 16.65}' -->
            <p :style="`width: ${coreSteps ? '100%' : `83%`};`">Attainment</p>
            <p
              :style="`width: ${
                50 / attainmentData.length + 1 + 20
              }%; display: ${
                coreSteps ? 'none' : ''
              }; border-left: 1px solid #9e9999`"
            >
              Core Steps
            </p>
          </div>
        </div>
      </div>
      <div id="sectionList">
        <div id="firstSection"></div>
        <div class="sections" v-for="(data, index) of comparisons" :key="index">
          <p
            class="shortSection"
            :style="`width: ${
              coreSteps
                ? `${55 / attainmentData.length}%`
                : `${50 / attainmentData.length}%`
            }`"
            v-for="attain of attainmentData"
            :key="attain"
          >
            {{ attain[0] }}
          </p>
          <p class="longSection" :style="`${coreSteps ? 'width: 15%;' : ''}`">
            Sum
          </p>
          <p class="longSection" :style="`${coreSteps ? 'width: 15%;' : ''}`">
            Tot
          </p>
          <p class="longSection" :style="`${coreSteps ? 'width: 15%;' : ''}`">
            %
          </p>
          <p class="longSection" :style="`display: ${coreSteps ? 'none' : ''}`">
            Sum
          </p>
          <p
            class="shortSection"
            :style="`display: ${coreSteps ? 'none' : ''}; width: ${
              50 / attainmentData.length
            }%`"
          >
            Tot
          </p>
          <p class="longSection" :style="`display: ${coreSteps ? 'none' : ''}`">
            %
          </p>
        </div>
      </div>
      <div v-for="(leader, i) of reportData" :key="i">
        <div
          id="outerWrapper"
          v-for="(select, j) of selectionList"
          :key="j"
          :style="`display: ${leader[`Name${j + 1}`] !== null ? '' : 'none'}`"
        >
          <div
            v-for="(section, l) of [1, ...comparisons]"
            :key="l"
            :style="`width: ${
              section === 1 ? '30%' : '35%'
            }; border: 1px solid #9e9999`"
          >
            <p
              v-if="section === 1"
              :style="`margin: 0 0 0 ${j * 2}vw;`"
              id="trimString"
            >
              {{ leader[`Name${j + 1}`] }}
            </p>
            <div v-else id="outerWrapper">
              <p
                v-for="(attain, k) of attainment"
                :key="k"
                :style="`margin: 0; text-align: center; user-select: none;
								color: ${
                  leader.value.length === 1
                    ? j + 1 === selectionList.length &&
                      leader.value[0].Year === section.year &&
                      leader.value[0].Term === section.term
                      ? 'default'
                      : 'transparent'
                    : leader.value.length >= l && j + 1 === selectionList.length
                    ? 'default'
                    : 'transparent'
                };
								width: ${
                  attain === 'TotalAttainment' ||
                  attain === 'Sum_Percentage' ||
                  attain === 'Sum_Completed' ||
                  attain === 'Students' ||
                  attain === 'TotalAttainment_Percentage'
                    ? coreSteps
                      ? '15%'
                      : '10%'
                    : coreSteps
                    ? `${55 / attainmentData.length}%`
                    : `${50 / attainmentData.length}%`
                };
								background: ${
                  attain === 'TotalAttainment' ||
                  attain === 'Sum_Percentage' ||
                  attain === 'students' ||
                  attain === 'TotalAttainment_Percentage'
                    ? '#ddd'
                    : 'transparent'
                };
								display: ${
                  attain === 'Sum_Completed' ||
                  attain === 'Sum_Total' ||
                  attain === 'Sum_Percentage'
                    ? coreSteps
                      ? 'none'
                      : ''
                    : ''
                };`"
              >
                {{
                  leader.value.length >= l && j + 1 === selectionList.length
                    ? leader.value[l - 1][attain] || "0"
                    : "0"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="sectionList">
        <div id="firstSection">
          <p style="margin: 0">Total</p>
        </div>
        <div class="sections" v-for="(data, index) of results" :key="index">
          <p
            :class="list < 5 ? 'shortSection' : 'longSection'"
            :style="`margin: 0; text-align: center; user-select: none;
						width: ${
              attain === 'TotalAttainment' ||
              attain === 'Sum_Percentage' ||
              attain === 'Sum_Completed' ||
              attain === 'Students' ||
              attain === 'TotalAttainment_Percentage'
                ? coreSteps
                  ? '15%'
                  : '10%'
                : coreSteps
                ? `${55 / attainmentData.length}%`
                : `${50 / attainmentData.length + 1}%`
            };
						display: ${
              attain === 'Sum_Completed' ||
              attain === 'Sum_Total' ||
              attain === 'Sum_Percentage'
                ? coreSteps
                  ? 'none'
                  : ''
                : ''
            };`"
            v-for="(attain, list) of attainment"
            :key="list"
          >
            {{ data[attain] }}
          </p>
        </div>
      </div>
    </div>
    <div class="row underline" />
    <div class="fluid">
      <button id="downloadButton" @click="exportExcel()">
        <img src="../assets/excel.png" />
        Export as Excel
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "MasterReportDetails",
  components: {
    FontAwesomeIcon,
  },
  data: () => {
    return {
      reportData: {},
      attainment: [
        "TotalAttainment",
        "Students",
        "TotalAttainment_Percentage",
        "Sum_Completed",
        "Sum_Total",
        "Sum_Percentage",
      ],
      year: [],
      coreSteps: true,
      results: [{}, {}],
    };
  },
  computed: {
    ...mapGetters([
      "leadershipReport",
      "selectionList",
      "comparisons",
      "reportType",
      "bodyReport",
      "selectedList",
      "attainmentData",
      "title",
    ]),
  },
  created() {
    const { leadershipReport, attainment, selectionList } = this;

    const defaultValue = {
      Adaptive: 0,
      Cooperative: 0,
      Developing: 0,
      Experienced: 0,
      Routinised: 0,
      Students: 0,
      Sum_Completed: 0,
      Sum_Percentage: 0,
      Sum_Total: 0,
      TotalAttainment: 0,
      TotalAttainment_Percentage: 0,
    };

    // Parsing the comparisons into year
    this.year = this.comparisons;

    // Checking wether year is the same length or not
    if (this.year.length === 1) this.year.push(this.year[0]);

    // Sorting year and term based on which is smaller (lower sort is prioritized)
    this.year.sort((a, b) => a.term - b.term);
    this.year.sort((a, b) => a.year - b.year);

    // make local copy of received data
    this.reportData = _.cloneDeep(leadershipReport[0].resultList[0].leaders);

    let column = 0;
    let maxColumns = 2;
    let row = 0;
    let rowCriteria = "";
    let currentCriteria = "";
    const newAttainment = [...this.attainmentData, ...attainment]; // 'AssessmentCycle', 'Term', 'Year'
    this.attainment = _.cloneDeep(newAttainment);

    this.reportData.forEach((leader) => {
      if (selectionList.includes("Student Name")) {
        leader[`Code${selectionList.length}`] = leader.studentid;
        leader[`Name${selectionList.length}`] = leader.studentname;
        delete leader.studentname;
        delete leader.studentid;
      }
      if (rowCriteria === "") {
        for (let select = 1; select <= selectionList.length; select++) {
          rowCriteria = rowCriteria + leader[`Code${select}`];
        }
        for (let select = 1; select <= selectionList.length; select++) {
          this.reportData[row][`Name${select}`] = leader[`Name${select}`];
        }
      }
      currentCriteria = "";
      for (let select = 1; select <= selectionList.length; select++) {
        currentCriteria = currentCriteria + leader[`Code${select}`];
      }
      if (currentCriteria !== rowCriteria) {
        // fill in remaining columns if any
        while (column < maxColumns) {
          this.reportData[row].value.push(defaultValue);
          column++;
        }
        column = 0;
        row++;
        rowCriteria = currentCriteria;
        for (let select = 1; select <= selectionList.length; select++) {
          this.reportData[row][`Name${select}`] = leader[`Name${select}`];
        }
      }

      leader.value = [];
      let tempValue = {};

      while (
        this.year[column].year !== leader.Year ||
        this.year[column].term !== leader.Term ||
        this.year[column].assessmentCycle !== leader.AssessmentCycle
      ) {
        this.reportData[row].value.push(defaultValue);
        column++;
        if (column >= maxColumns) {
          column = 0;
        }
      }

      for (let att of newAttainment) {
        tempValue[att] = Math.round(leader[att]);
      }

      this.reportData[row].value.push(tempValue);

      column++;
      if (column >= maxColumns) {
        column = 0;
      }
    });

    if (column !== 0) {
      while (column < maxColumns) {
        this.reportData[row].value.push(defaultValue);
        column++;
      }
    }

    for (let i = this.reportData.length - 1; i >= 0; i--) {
      if (this.reportData[i].value.length === 0) {
        this.reportData.splice(i, 1);
      }
    }

    const cloneTempLeaders = _.cloneDeep(this.reportData);

    this.reportData.forEach((leader, i) => {
      for (let select = 1; select <= selectionList.length; select++) {
        if (i > 0) {
          if (
            leader[`Name${select}`] === cloneTempLeaders[i - 1][`Name${select}`]
          ) {
            leader[`Name${select}`] = null;
          } else {
            break;
          }
        }
      }
    });

    for (let column = 0; column < maxColumns; column++) {
      newAttainment.forEach((att) => {
        if (
          this.results[column][att] === undefined ||
          isNaN(this.results[column][att])
        ) {
          this.results[column][att] = 0;
        }
        for (let row = 0; row < this.reportData.length; row++) {
          if (
            this.reportData[row].value[column][att] !== undefined &&
            !isNaN(this.reportData[row].value[column][att])
          ) {
            this.results[column][att] =
              this.results[column][att] +
              this.reportData[row].value[column][att];
          }
        }
      });
      // Calculate percentages for totals
      const { TotalAttainment, Students } = this.results[column];
      const sumCompleted = this.results[column].Sum_Completed;
      const sumTotal = this.results[column].Sum_Total;
      if (Students !== 0) {
        this.results[column].TotalAttainment_Percentage = Math.round(
          (TotalAttainment / Students) * 100
        );
      } else {
        this.results[column].TotalAttainment_Percentage = 0;
      }
      if (sumTotal !== 0) {
        this.results[column].Sum_Percentage = Math.round(
          (sumCompleted / sumTotal) * 100
        );
      } else {
        this.results[column].Sum_Percentage = 0;
      }
    }
  },
  methods: {
    ...mapActions(["downloadReport"]),
    async exportExcel() {
      const {
        body: { reportList },
      } = await this.downloadReport(this.bodyReport);
      const { resultList } = reportList[0];
      const { leaders } = resultList[0];

      let binaryWS = XLSX.utils.json_to_sheet(leaders);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      // export your excel
      XLSX.writeFile(wb, "Report.xlsx");
    },
    changeCoreSteps() {
      this.coreSteps = !this.coreSteps;
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}

#mainWrapper {
  margin: 0 0 3vh 0;
}

#subHeader {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

#outerWrapper {
  display: flex;
  width: 100%;
}

#categories {
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 1px solid #9e9999;
}

#sectionList {
  display: flex;
  width: 100%;
  background: #ddd;
}

#firstSection {
  border: 1px solid #9e9999;
  width: 30%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid #9e9999;
  width: 35%;
  text-align: center;
}

.sections > .shortSection {
  width: 8.333%;
  margin: 0;
}

.sections > .longSection {
  width: 10%;
  margin: 0;
}

#trimString {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fluid {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#downloadButton {
  width: 10%;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9e9999;
  border-radius: 10px;
  cursor: pointer;
}

#downloadButton > img {
  margin: 0 5% 0 0;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 3vh 0;
}

.underline {
  width: 100%;
  margin: 0 0 3vh 0;
  border-bottom: 0.3vh solid grey;
}
</style>
