<template>
  <div id="wrapper">
    <div v-if="showCheck">
      <input
        v-if="!listModel.status"
        @click="addCheckList()"
        type="checkbox"
        v-model="listModel.status"
      />
      <h5 v-else @click="addCheckList()">{{ listModel.number + 1 }}</h5>
    </div>
    <div class="multiselect">
      <div class="selectBox" @click="disable ? () => {} : showCheckboxes()">
        <select :disabled="disable">
          <option>{{ labelOption }}</option>
        </select>
        <div class="overSelect"></div>
      </div>
      <div
        @mouseleave="disable ? () => {} : showCheckboxes()"
        :id="name + `checkboxes`"
        style="
          display: none;
          width: 20vw;
          background-color: #ccc;
          position: absolute;
          border: 1px #dadada solid;
          z-index: 9999;
          max-height: 15vh;
          overflow-y: auto;
        "
      >
        <label v-for="(label, index) in labelList" :key="index">
          <input
            :checked="inputList.includes(label.id)"
            style="margin-left: 1vw"
            @click="addValue(label)"
            type="checkbox"
            :id="index"
          />
          <p>{{ label.name }}</p>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import getAndSet from "@/libs/getAndSet";
export default {
  name: "MultiSelect",
  data: () => {
    return {
      expanded: false,
    };
  },
  props: {
    name: {
      required: true,
      type: String,
    },
    addCheckList: {
      required: false,
      type: Function,
    },
    checkListModel: {
      required: false,
    },
    labelList: {
      required: true,
      type: Array,
    },
    addValue: {
      required: true,
      type: Function,
    },
    labelOption: {
      required: true,
    },
    showCheck: {
      default: true,
    },
    inputList: {
      default: () => [],
      required: false,
    },
    disable: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  computed: {
    listModel: getAndSet.default("checkListModel"),
  },
  methods: {
    showCheckboxes() {
      let checkboxes = document.getElementById(this.name + "checkboxes");
      if (!this.expanded) {
        checkboxes.style.display = "block";
        this.expanded = true;
      } else {
        checkboxes.style.display = "none";
        this.expanded = false;
      }
    },
  },
};
</script>
<style scoped>
#wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  margin: 0 1.09vw 0 0;
  width: 1.09vw;
  height: auto;
}
h5 {
  margin: 0 1.09vw 0 0;
  width: 1.09vw;
  height: auto;
  cursor: pointer;
  user-select: none;
}
.multiselect {
  width: 20vw;
  margin: 0 1vw 0 0;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  /* font-weight: bold; */
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  width: 20vw;
  background-color: #ccc;
  position: absolute;
  border: 1px #dadada solid;
}
label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 3vh;
  margin: 0.5vh 0;
}
label p {
  margin: 0;
  width: 100%;
}

label:hover {
  background-color: #1e90ff;
}
</style>
