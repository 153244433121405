<template>
  <div class="details" v-cloak>
    <div class="elo-code">
      <font-awesome-icon
        v-if="showDetails"
        class="icon-image comment"
        :icon="['far', 'star']"
        size="1x"
        :style="{ color: 'gold', fill: 'gold' }"
      >
      </font-awesome-icon>
      {{ eloCode }}<span v-if="eloStatus">{{ " " }}</span
      ><span v-else style="color: #d67c7c"> {{ " " }} Inactive</span>
    </div>
    <p class="elo-desc">{{ eloName }}</p>
    <slot></slot>
    <MoreDropdown :options="dropdownOptions" :identifier="`elo-${id}`">
    </MoreDropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import MoreDropdown from "./MoreDropdown";
import CurriculumInterfaceEditEloModal from "@/views/CurriculumInterface/CurriculumInterfaceEditEloModal";
import CurriculumInterfaceEloTaskItemModal from "@/views/CurriculumInterface/CurriculumInterfaceEloTaskItemModal";

export default {
  name: "CurriculumInterfaceEloListCardDetails",
  /* eslint-disable vue/no-unused-components */
  components: {
    FontAwesomeIcon,
    MoreDropdown,
    CurriculumInterfaceEditEloModal,
    CurriculumInterfaceEloTaskItemModal,
    constants,
  },
  /* eslint-enable vue/no-unused-components */
  props: {
    eloCode: String,
    eloName: String,
    eloStatus: Boolean,
    eloLink: String,
    showDetails: Boolean,
    domainName: String,
    domainCode: String,
    subDomainName: String,
    subDomainCode: String,
    programmeCodes: Array,
    tloName: String,
    tloCode: String,
    id: Number,
    eloId: String,
  },
  data() {
    return {
      fromData: {
        id: "ELO-" + this.id,
      },
      lists: this.programmeCodes,
      juniorProgramme: false,
      seniorProgramme: false,
      middleProgramme: false,
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
    dropdownOptions: function () {
      if (this.programmeCodes.length > 0) {
        this.lists.forEach((element) => {
          if (element === "J") {
            this.juniorProgramme = true;
          } else if (element === "M") {
            this.middleProgramme = true;
          } else if (element === "S") {
            this.seniorProgramme = true;
          }
        });
      }

      let dropdownOptions = {
        TaskItem: {
          label: "eloTaskItem.add",
          method: this.taskItemModal,
        },
        Edit: {
          label: "eloListCard.edit",
          method: this.showEditModal,
        },
        Delete: {
          label: "eloListCard.delete",
          method: this.curriculumInterfaceEloDelete,
        },
      };

      let finalDropdownOptions = [];

      finalDropdownOptions.push(
        dropdownOptions["TaskItem"],
        dropdownOptions["Edit"],
        dropdownOptions["Delete"]
      );

      return finalDropdownOptions;
    },
  },
  methods: {
    ...mapActions(["getCurriculum", "forceGetCurriculum", "curriculumInterfaceTaskItemAdd"]),
    showEditModal() {
      let componentProps = {
        domainName: this.domainName,
        domainCode: this.domainCode,
        subdomainName: this.subDomainName,
        subDomainCode: this.subDomainCode,
        tloName: this.tloName,
        tloCode: this.tloCode,
        eloName: this.eloName,
        eloCode: this.eloCode,
        id: this.id,
        eloLink: this.eloLink,
        eloStatus: this.eloStatus,
        eloId: this.eloId,
        programmeCodes: this.programmeCodes,
        juniorProgramme: this.juniorProgramme,
        seniorProgramme: this.seniorProgramme,
        middleProgramme: this.middleProgramme,
      };
      this.$modal.show({
        component: CurriculumInterfaceEditEloModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    curriculumInterfaceEloDelete() {
      let eloName = this.eloName;
      let modalParams = {
        header: this.$t("elo.delete.modalTitle", { eloName }),
        text: eloName,
        includeConfirm: true,
        successCallback: this.doDeleteElo,
      };

      this.$modal.show(modalParams);
    },
     taskItemModal() {
      console.log('test' + this.eloCode)
      let componentProps = {
        eloCode: this.eloCode
      };
      this.$modal.show({
        component: CurriculumInterfaceEloTaskItemModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    async doDeleteElo() {
      const curriculumInterface = this.eloName;
      try {
        const { status, data } = await this.$store.dispatch(
          "curriculumInterfaceEloDelete",
          this.fromData
        );
        if (status !== constants.RESPONSE_STATUS.OK && data) {
          return this.$toastr.error(data && data.message);
        }
        this.forceGetCurriculum();
        this.$toastr.success(
          this.$t("curriculumInterfaceModal.success.deleted", {
            curriculumInterface,
          })
        );
        this.$modal.closeAll();
      } catch (resp) {
        this.$toastr.error(resp.data && resp.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  position: relative;

  // Remove margin bottom for elos in student record.
  .lesson-record-wrapper & p {
    margin-bottom: 0;
  }
}
.elo-code {
  margin-bottom: $global-margin / 3;
  color: $dark-gray;
  font-size: 11px;

  .elo-core {
    @extend .sans-serif-extrabold;
    padding-left: $global-padding / 4.2;
    color: $brand-quaternary;
  }

  .elo-outcome {
    @extend .sans-serif-extrabold;
    padding-left: $global-padding / 4.2;
    color: $brand-outcome;
  }

  @media print {
    margin-bottom: 0;
  }
}
.elo-desc {
  // margin-bottom: $global-margin / 3;
  margin-bottom: 0;

  .terminated & {
    color: $darker-gray;
  }
}
</style>

