<template>
  <div
    id="class-group-edit-side-tray"
    class="elo-side-tray off-canvas position-left grid-y"
    v-foundation:OffCanvas
    data-close-on-click="false"
    v-cloak
  >
    <component
      v-if="childToRender"
      :is="childToRender.name"
      @clear-handle="clearHandle()"
    ></component>
  </div>
</template>

<script>
import ClassGroupEditSideTrayStudents from "./ClassGroupEditSideTrayStudents";
import ClassGroupEditSideTrayTeachers from "./ClassGroupEditSideTrayTeachers";

export default {
  name: "ClassGroupEditSideTray",
  components: {
    ClassGroupEditSideTrayStudents,
    ClassGroupEditSideTrayTeachers,
  },
  data() {
    return {
      childComponents: [
        {
          key: "students",
          name: "ClassGroupEditSideTrayStudents",
        },
        {
          key: "teachers",
          name: "ClassGroupEditSideTrayTeachers",
        },
      ],
    };
  },
  computed: {
    childToRender() {
      let componentKey = this.$route.path.split("/").pop();

      let componentToRender = this.childComponents.find((child) => {
        return componentKey === child.key;
      });

      return componentToRender;
    },
  },
  methods: {
    clearHandle() {
      $(this.$el).foundation("close");
    },
  },
  mounted() {
    $(this.$el).on("closed.zf.offcanvas", this.clearHandle);
  },
  destroyed() {
    console.log("destroyed");
    $(this.$el).off("closed.zf.offcanvas", this.clearHandle);
  },
};
</script>

<style lang="scss" scoped>
.elo-side-tray {
  background-color: $white;
  margin-top: $global-margin / 2;
  border: 2px solid $black;
  height: 98%;
  width: $elo-side-tray-width;
  z-index: $sidetray-zindex;
  overflow-y: initial;

  &.position-left:not(.is-open) {
    transform: translateX(-#{$elo-side-tray-width});
  }

  .full-height {
    height: 100%;
  }
}
</style>

