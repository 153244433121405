import Vue from 'vue'

export default {
	getStudentEloDetailsList (studentId, planYear) {
		return Vue.http.get('studentEloDetails/' + studentId, {
			params: {
				planYear
			}
		})
	},
	getStudentEloDetails (studentId, eloCode, planYear) {
		return Vue.http.get('studentEloDetails/' + studentId, {
			params: {
				eloCode, planYear
			}
		})
	},
	postStudentEloDetails (studentId, eloCode, planYear, formData) {
		return Vue.http.post('studentEloDetails', {
			studentId: studentId,
			eloCode: eloCode,
			planYear: planYear,
			startTerm: formData.startTerm,
			endTerm: formData.endTerm,
			baselineAssessmentCriteria: formData.baselineAssessmentCriteria,
			assessmentCriteria: formData.assessmentCriteria
		})
	}
}
