<template>
  <div v-if="hasElos" class="sub-domain-list-card card" v-cloak>
    <div class="sub-domain-header card-divider">
      <span class="grid-y cell auto"
        >{{ subDomain.subDomainName | labelize }}
      </span>
    </div>
    <div class="sub-domain-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "SubDomainListCard",
  props: {
    subDomain: {
      type: Object,
    },
    retainElos: {
      type: Function,
    },
  },
  computed: {
    hasElos: function () {
      return (
        this.retainElos(_.chain(this.subDomain.tlos).flatMap("elos").value())
          .length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-domain-list-card.card {
  margin-bottom: 0;
  border-radius: 0;
  border: 0;
  overflow: visible;
}
.sub-domain-header {
  border-bottom: 1px solid $light-gray;
  background-color: $brand-secondary;
  color: $text-color;

  span {
    @extend .sans-serif-bold;
    line-height: 1.3em;
  }

  .annual-plan &,
  .elo-aiep & {
    background-color: transparentize($brand-primary, 0.75);
  }

  .programme-plan &,
  .elo-piep & {
    background-color: transparentize($brand-tertiary, 0.75);
  }

  .elo-prog & {
    background-color: transparentize($darker-gray, 0.75);
  }

  .elo-side-tray & {
    font-size: $small-font-size;
  }

  @media print {
    padding: $global-padding / 2.5 $global-padding;
    background-color: $gray !important;
    font-size: $small-font-size;
  }
}
</style>

