import * as types from '../mutation-types';
import studentEloTaskItemRemarksApi from '@/api/studentEloTaskItemRemarks';
import constants from '@/constants';

// initial state
const state = {
	studentTaskItemRemarks: {}
};

const getters = {
	studentTaskItemRemarks: state => studentTaskItemId => {
		return state.studentTaskItemRemarks[studentTaskItemId];
	}
};

// actions
const actions = {
	getStudentAllTaskItemRemarks({ commit }, params) {
		return studentEloTaskItemRemarksApi.getStudentAllTaskItemRemarks(params).then((resp) => {
			if (resp.data) {
				commit(types.SET_ALL_TASK_ITEM_REMARKS, resp.data);
				return resp.data;
			}
			return null;
		});
	},
	getStudentTaskItemRemarks({ commit }, { studentTaskItemId }) {
		return studentEloTaskItemRemarksApi.getStudentTaskItemRemarks(studentTaskItemId).then((resp) => {
			if (resp.data) {
				commit(types.SET_TASK_ITEM_REMARKS, { studentTaskItemId, remarks: resp.data.taskItemRemarks });
				return resp.data.taskItemRemarks;
			}
			return null;
		});
	},
	postStudentTaskItemRemarks({ commit }, { studentTaskItemId, remark, week }) {
		return studentEloTaskItemRemarksApi.postStudentTaskItemRemarks(studentTaskItemId, remark, week).then((resp) => {
			if (resp.data) {
				commit(types.SET_TASK_ITEM_REMARKS, { studentTaskItemId, remarks: resp.data.taskItemRemarks });
				return resp.data.taskItemRemarks;
			}
			return null;
		});
	},
	deleteStudentTaskItemRemarks({ dispatch }, { studentTaskItemId, remarkId }) {
		return studentEloTaskItemRemarksApi.deleteStudentTaskItemRemarks(studentTaskItemId, remarkId).then((resp) => {
			if (resp.status === constants.RESPONSE_STATUS.OK) {
				dispatch('getStudentTaskItemRemarks', { studentTaskItemId });
				// commit(types.SET_TASK_ITEM_REMARKS, { studentTaskItemId, remarks: [] })
				return true;
			}
			return null;
		});
	}
};

// mutations
const mutations = {
	[types.SET_ALL_TASK_ITEM_REMARKS](state, remarks) {
		state.studentTaskItemRemarks = remarks;
	},
	[types.SET_TASK_ITEM_REMARKS](state, { studentTaskItemId, remarks }) {
		state.studentTaskItemRemarks = { ...state.studentTaskItemRemarks, [studentTaskItemId]: remarks };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
