<template>
  <div class="aiep-plan cell small-12 medium-6">
    <div class="aiep-plan-row grid-x text-center">
      <div
        class="cell auto"
        :class="
          i > 1 &&
          (i < studentEloDetails.startTerm * 2 ||
            i > studentEloDetails.endTerm * 2 + 1)
            ? 'blank'
            : ''
        "
        v-for="(header, i) in 10"
        :key="i"
      >
        <span class="aiep-attainment grid-x align-center align-middle">
          <div
            v-if="isOdd(i) && completedOverTotalForTerm(i / 2)"
            class="prompt margin-0"
          >
            <div>
              <b>{{ completedOverTotalForTerm(i / 2).completed }}</b>
            </div>
            <div class="line-divider" />
            <div>
              <b>{{ completedOverTotalForTerm(i / 2).total }}</b>
            </div>
          </div>

          <div
            v-else
            class="attainment-column grid-y align-center align-middle"
          >
            <div
              v-for="(value, cycleNum) in getNumCyclesForTerm((i - 1) / 2)"
              :key="'term-' + (i - 1) / 2 + '-learningCycle-' + (cycleNum + 1)"
              class="cell grid-y align-center align-middle"
              :class="{ 'add-border': cycleNum + 1 > 1 }"
            >
              <p
                v-if="
                  getNumCyclesForTerm((i - 1) / 2) > 1 &&
                  attainmentLevelForTerm((i - 1) / 2, cycleNum + 1) &&
                  cycleNum + 1 === 1
                "
                class="assessment-cycle-label"
              >
                {{ $t("eloListCard.first.assessment") }}
              </p>
              <p
                v-if="
                  getNumCyclesForTerm((i - 1) / 2) > 1 &&
                  attainmentLevelForTerm((i - 1) / 2, cycleNum + 1) &&
                  cycleNum + 1 === 2
                "
                class="assessment-cycle-label"
              >
                {{ $t("eloListCard.second.assessment") }}
              </p>

              <attainment-badge
                class="attainment-badge cell"
                v-if="attainmentLevelForTerm((i - 1) / 2, cycleNum + 1)"
                :level="attainmentLevelForTerm((i - 1) / 2, cycleNum + 1)"
              >
              </attainment-badge>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AttainmentBadge from "./AttainmentBadge";

export default {
  name: "StudentAnnualPlanTermGrid",
  components: {
    AttainmentBadge,
  },
  props: {
    studentEloDetails: Object,
  },
  data() {
    return {
      numCyclesForTerm: {
        0: 1,
        1: 1,
        2: 2,
        3: 2,
        4: 1,
      },
    };
  },
  methods: {
    isOdd: function (val) {
      return val % 2 === 0;
    },
    getNumCyclesForTerm(term) {
      return this.numCyclesForTerm[term.toString()];
    },
    completedOverTotalForTerm: function (term) {
      const termMap = ["baseline", "term1", "term2", "term3", "term4"];
      const completedOverTotal =
        this.studentEloDetails.completedOverTotalTaskItems[termMap[term]];

      return completedOverTotal.total ? completedOverTotal : null;
    },
    attainmentLevelForTerm: function (term, assessmentCycle) {
      let requiredAttainment = _.find(
        this.studentEloDetails.attainments,
        (attainment) => {
          return (
            attainment &&
            attainment.planTerm === term &&
            attainment.assessmentCycle === assessmentCycle
          );
        }
      );

      return requiredAttainment ? requiredAttainment.attainmentLevel : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.aiep-plan {
  border-left: 1px solid $gray;
  border-bottom: 1px solid $gray;

  .aiep-plan-row {
    height: 100%;
    min-height: 5em;

    > .cell {
      &:nth-child(odd) {
        &.blank {
          @extend %bg-pattern;
          background-color: $gray;
        }
        background-color: $lighter-gray;
      }
      &:nth-child(even) {
        &.blank {
          @extend %bg-pattern;
          background-color: $gray;
        }
        background-color: $light-gray;
      }

      .aiep-attainment {
        max-width: 100%;
        height: 100%;

        .prompt {
          padding: $global-padding * 0.3 $global-padding * 0.2;
          min-width: 55%;
          max-width: 90%;
          // word-wrap: break-word;
          // word-break: break-all;

          .line-divider {
            border-bottom: 1px solid $black;
          }
        }

        .attainment-column {
          width: 100%;
          height: 100%;

          > .cell {
            height: 50%;
            width: 100%;
            position: relative;

            &.add-border {
              // border-top: 1px solid $gray;
            }

            .assessment-cycle-label {
              position: absolute;
              top: 0.5em;
              right: 0.5em;
            }

            .attainment-badge {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
