import Vue from 'vue'

export default {
	getStudentEloTerminationRemarks (studentId, eloCode, external) {
		return Vue.http.get('studentEloTerminationRemarks/' + studentId, {
			params: {
				eloCode: eloCode,
				external: external
			}
		})
	},
	postStudentEloTerminationRemark (studentId, eloCode, remark, external, selectedYear, eloStatus) {
		return Vue.http.post('studentEloTerminationRemarks', {
			studentId: studentId,
			eloCode: eloCode,
			remark: remark,
			external: external,
			selectedYear: selectedYear,
			eloStatus: eloStatus
		})
	},
	deleteStudentEloTerminationRemark (remarkId) {
		return Vue.http.delete('studentEloTerminationRemarks/' + remarkId)
	},
	getStudentEloTerminationRemarkEvidence (remarkId, fileUUID) {
		return Vue.http.get('studentEloTerminationRemarksEvidence/' + fileUUID, {
			params: {
				remarkId: remarkId
			}
		})
	},
	postStudentEloTerminationRemarkEvidence (details) {
		return Vue.http.post('studentEloTerminationRemarksEvidence', details, {
			setContentType: false,
			processData: false,
			headers: { 'Content-Type': undefined }
		})
	}
}
