<template>
	<div v-cloak v-if="shouldDisplay" class="refresh-token-container">
		<p class="margin-bottom-0 mini-padding-bottom">
			{{ $t("refreshToken.alert", { timeLeft }) }}
		</p>
		<button class="button rounded warning margin-bottom-0" @click="refresh">
			{{ $t("refreshToken.button") }}
		</button>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
	name: "RefreshTokenAlert",
	data() {
		return {
			interval: null,
			timeLeft: 300,
		};
	},
	computed: {
		...mapGetters(["isAuthenticated", "profile"]),
		shouldDisplay() {
		// return this.isAuthenticated && this.timeLeft < 300000;
		// console.log(this.timeLeft);
		// return this.isAuthenticated && this.timeLeft < 20;
			return this.isAuthenticated && this.timeLeft < 900; // 15 minutes
		},
	},
	mounted() {
		this.interval = setInterval(() => {
			if (this.isAuthenticated) {
				this.timeLeft = moment(this.profile.expire).diff(moment(), "seconds");
				if (this.timeLeft <= 0) {
					this.logout();
					this.$router.push({ name: "Login" });
				}
			}
		}, 1000);
	},
	destroyed() {
		clearTimeout(this.interval);
	},
	methods: {
		...mapActions(["logout", "refreshToken"]),
		refresh() {
			this.refreshToken();
		},
	},
};
</script>

<style lang="scss" scoped>
.refresh-token-container {
	border: 1px solid $warning;
	padding: $global-padding / 2;
	position: fixed;
	bottom: $global-margin;
	right: $global-margin;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	z-index: 9999;
}

.mini-padding-bottom {
	padding-bottom: $global-padding / 3;
}
</style>