import * as types from '../mutation-types';
import _ from 'lodash';

// initial state
const state = {
	selectedDomainId: null,
	domain: {}
};

// getters
const getters = {
	selectedDomainId(state) {
		return state.selectedDomainId;
	},
	domain(state) {
		return state.domain;
	}
};

// actions
const actions = {
	setSelectedDomainId({ commit, getters }, selectedDomainId) {
		// TODO: retrieve domainName and domainDetails of unadded ELOs
		let domainObj = {
			id: selectedDomainId
		};

		// Use curriculum for now
		let curriculum = getters.curriculum;

		_.forEach(curriculum.domains, (domain) => {
			if (domainObj.id === domain.id) {
				domainObj.domainId = domain.domainId;
				domainObj.domainName = domain.domainName;
				return false;
			}
		});

		domainObj.details = curriculum.domainDetails[domainObj.id];

		commit(types.SET_SELECTED_DOMAIN_ID, selectedDomainId);
		commit(types.SET_DOMAIN, domainObj);
	}
};

// mutations
const mutations = {
	[types.SET_SELECTED_DOMAIN_ID](state, selectedDomainId) {
		state.selectedDomainId = selectedDomainId;
	},
	[types.SET_DOMAIN](state, domain) {
		state.domain = domain;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
