<template>
	<div class="sessions-wrapper">
	  <!--
	  <div class="sessions-header">
		<p>Sessions</p>
	  </div>
	  -->
	  <h3 class="sessions-header">
			<p>Sessions</p>
		<!--
			<button class="add-button" @click="addingSession=true">
				<font-awesome-icon icon="plus" size="1x"></font-awesome-icon>
				Add Session
			</button>
		-->
			<div class="cell shrink">
				<button
				@click.stop="addingSession=true"
				type="button"
				class="add-button clear button">
					<small>
						<font-awesome-icon icon="plus" size="1x"></font-awesome-icon>
						<span class="hide-for-small-only">&nbsp;&nbsp;Add Session</span>
					</small>
				</button>
			</div>
	  </h3>
	  <div v-if="addingSession" class="add-session-container">
		<div>
			<label for="newDate">Date:</label>
			<input 
			type="date"
			v-model="newDate"
			id="newDate"
			>
		</div>
		<div>
			<label for="newStartTime">Start Time:</label>
			<input 
			type="time"
			v-model="newStartTime"
			id="newStartTime"
			>
		</div>
		<div>
			<label for="newEndTime">End Time:</label>
			<input 
			type="time"
			v-model="newEndTime"
			id="newEndTime"
			>
		</div>
		<div>
			<label for="newSessionOnline">Online:</label>
			<input 
			type="checkbox"
			v-model="newSessionOnline"
			id="newSessionOnline"
			>
		</div>
		<div>
			<label for="newSessionVenue">Venue:</label>
			<input 
			type="text"
			v-model="newSessionVenue"
			id="newSessionVenue"
			placeholder="Enter venue location"
			>
		</div>
		<button class="button primary margin-top-1" @click="confirmAddSession">Save</button>
		<button class="button primary margin-top-1" @click="cancelAddSession">Cancel</button>
	  </div>

	  <coaching-engagement-session-card
      v-if="futureSession"
      :session="futureSession"
      :filteredRemarks="filteredRemarksForSession(futureSession.startTime, findPrevSessionStartTime(futureSession.startTime))"
    ></coaching-engagement-session-card>
	  <div
		v-for="(session, index) in sessions"
		:key="index"
		class="session-item"
	  >
	  	<!-- <p>{{ session }}</p> -->
		<coaching-engagement-session-card
		  :session="session"
		  :filteredRemarks="filteredRemarksForSession(session.startTime, findPrevSessionStartTime(session.startTime))"
		></coaching-engagement-session-card>
	  </div>
	</div>
  </template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import _ from "lodash";
import CoachingEngagementSessionCard from "@/components/CoachingEngagementSessionCard";

export default {
	name: "CoachingEngagementSessionsList",
	components: {
		FontAwesomeIcon,
	   CoachingEngagementSessionCard
	},
	props: ["sessions", "clientRemarks"],
	data() {
		return {
			editMode: false,
			addingSession: false,
			newSessionStartTime: new Date().toISOString().substr(0, 10), 
      newSessionEndTime: new Date().toISOString().substr(0, 16), 
      newSessionOnline: false, 
      newSessionVenue: '', 
      newSessionPrivateNotes: '', 
      newSessionSharedNotes: '', 
      newSessionOutcomeSelfAssessmentTime: new Date(`1970-01-01T00:00`), 
      newSessionAssessment: 0,
			newDate: '', // new Date(),
			newStartTime: '',
			newEndTime: ''
		};
	},
	watch: {
		newDate(newDate) {
			console.log("**newDate:")
			if (this.newStartTime) {
				const startTime = new Date(`${newDate}T${this.newStartTime}:00`);
				this.newSessionStartTime = startTime; // Full timestamp
				if (!this.newEndTime || this.newEndTime == '') {
					const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add one hour
					this.newSessionEndTime = endTime; // Full timestamp
					// this.newEndTime = endTime.toISOString().substring(11, 16); // Only hours and minutes !! this pulls UTC hours while all the dates in client operate in local timezone
					const hours = String(endTime.getHours()).padStart(2, '0'); 
					const minutes = String(endTime.getMinutes()).padStart(2, '0');
					this.newEndTime = `${hours}:${minutes}`; // Only hours and minutes, in local tim
				}
			}
			if (this.newEndTime) {
				this.newSessionEndTime = new Date(`${newDate}T${this.newEndTime}:00`);
			}
			console.log(newDate)
			console.log("this.newSessionStartTime: ", this.newSessionStartTime)
			console.log("this.newSessionEndTime:", this.newSessionEndTime)
			console.log("this.newDate:", this.newDate)
			console.log("this.newStartTime:", this.newStartTime)
			console.log("this.newEndTime:", this.newEndTime)
		},
		newStartTime(newStartTime) {
			console.log("**newStartTime:")
			// Convert the start time to a Date object and add one hour
			if (this.newDate && this.newDate != '') {
				const startTime = new Date(`${this.newDate}T${newStartTime}:00`);
				this.newSessionStartTime = startTime; // Full timestamp
				if (!this.newEndTime || this.newEndTime == '') {
					const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Add one hour
					this.newSessionEndTime = endTime; // Full timestamp
					// this.newEndTime = endTime.toISOString().substring(11, 16); // Only hours and minutes !! this pulls UTC hours while all the dates in client operate in local timezone
					const hours = String(endTime.getHours()).padStart(2, '0'); 
					const minutes = String(endTime.getMinutes()).padStart(2, '0');
					this.newEndTime = `${hours}:${minutes}`; // Only hours and minutes, in local time
				}
			}
			console.log(newStartTime)
			console.log("this.newSessionStartTime: ", this.newSessionStartTime)
			console.log("this.newSessionEndTime:", this.newSessionEndTime)
			console.log("this.newDate:", this.newDate)
			console.log("this.newStartTime:", this.newStartTime)
			console.log("this.newEndTime:", this.newEndTime)
		},
		newEndTime(newEndTime) {
			console.log("**newEndTime:")
			// Convert the start time to a Date object and add one hour
			if (this.newDate) {
				this.newSessionEndTime = new Date(`${this.newDate}T${this.newEndTime}:00`);
			}
			console.log(newEndTime)
			console.log("this.newSessionStartTime: ", this.newSessionStartTime)
			console.log("this.newSessionEndTime:", this.newSessionEndTime)
			console.log("this.newDate:", this.newDate)
			console.log("this.newStartTime:", this.newStartTime)
			console.log("this.newEndTime:", this.newEndTime)
		},
		
	},
	computed: {
		...mapGetters([
			'currentCoachingEngagement'
		]),
		futureSession() {
			const now = new Date();
			const hasFutureSession = this.sessions.some(session => new Date(session.startTime) > now);
			if (!hasFutureSession) {
				const nowISOString = now.toISOString();
				return {
					startTime: new Date(nowISOString),
					endTime: new Date(nowISOString),
					venue: "Future Session",
					outcomeAssessments: [],
					sessionAssessment: 0,
					privateNotes: "",
					sharedNotes: "",
					placeholderFutureSession: true,
				};
			}
			return null;
		},
	},
	methods: {
		...mapActions([
			"createCoachingSession",
			"updateCoachingSession"
		]),
		validateSession(startTime, endTime, venue) {
			if (endTime <= startTime) {
				return false
			}
			else if (!venue || venue.length === 0 ) {
				return false
			}
			else {
				return true
			}
		},
		async confirmAddSession() {
			console.log("studentCoachingEngagementId:", this.currentCoachingEngagement.id)
			console.log("sessionStartTime:", this.newSessionStartTime) 
			console.log("sessionEndTime:", this.newSessionEndTime)
			console.log("sessionOnline:", this.newSessionOnline) 
			console.log("sessionVenue:", this.newSessionVenue) 
			console.log("sessionPrivateNotes:", this.newSessionPrivateNotes) 
			console.log("sessionSharedNotes:", this.newSessionSharedNotes) 
			console.log("sessionOutcomeSelfAssessmentTime:", this.newSessionOutcomeSelfAssessmentTime) 
			console.log("sessionAssessment:", this.newSessionAssessment)
			if (this.validateSession(this.newSessionStartTime, this.newSessionEndTime, this.newSessionVenue)) {
				await this.createCoachingSession({ 
					studentCoachingEngagementId: this.currentCoachingEngagement.id, 
					startTime: this.newSessionStartTime, 
					endTime: this.newSessionEndTime, 
					online: this.newSessionOnline, 
					venue: this.newSessionVenue, 
					privateNotes: this.newSessionPrivateNotes, 
					sharedNotes: this.newSessionSharedNotes, 
					outcomeSelfAssessmentTime: this.newSessionOutcomeSelfAssessmentTime, 
					sessionAssessment: this.newSessionAssessment
				});
				this.newSessionstartTime = new Date().toISOString().substr(0, 10), 
      			this.newSessionendTime = new Date().toISOString().substr(0, 16), 
				this.venue = "Future Session";
				this.outcomeAssessments = [];
				this.sessionAssessment = 0;
				this.privateNotes = "";
				this.sharedNotes = "";
				this.newSessionOutcomeSelfAssessmentTime = new Date(`1970-01-01T00:00`), 
      			this.newSessionAssessment = 0,
				this.newDate = '', // new Date();
				this.newStartTime = '';
				this.newEndTime = ''
			} else {
				console.log("New session validation fail in front end app")
				this.$toastr.error("Validation error in session data");// this.$t("error.unable.tryAgain"));
			}
			this.addingSession = false;
		},
		cancelAddSession() {
			this.addingSession = false;
			this.newSessionstartTime = new Date().toISOString().substr(0, 10), 
      this.newSessionendTime = new Date().toISOString().substr(0, 16), 
			this.venue = "Future Session";
			this.outcomeAssessments = [];
			this.sessionAssessment = 0;
			this.privateNotes = "";
			this.sharedNotes = "";
			this.newSessionOutcomeSelfAssessmentTime = new Date(`1970-01-01T00:00`), 
      this.newSessionAssessment = 0,
			this.newDate = '', // new Date();
			this.newStartTime = '';
			this.newEndTime = ''
		},
		editSession(id, name) {
			this.editMode = id;
			this.editedName = name;
		},
		async saveEditSession(id) {
			if (this.editMode && this.editedName.trim() !== '') {
				await this.updateCoachingSession({id, studentCoachingEngagementId: this.currentCoachingEngagement.id, name: this.editedName});
			}
			this.editMode = null;
		},
		cancelEditSession() {
			this.editMode = null;
		},
		findPrevSessionStartTime(currentSessionStartTime) {
		const sortedSessions = this.sessions
			.filter((session) => session.startTime < currentSessionStartTime)
			.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

		return sortedSessions.length ? sortedSessions[0].startTime : null;
		},
		filteredRemarksForSession(sessionStartTime, prevSessionStartTime) {
			console.log("Filtering remarks: ", sessionStartTime, prevSessionStartTime)
			if (!prevSessionStartTime) {
    			return [];
  			}
			const remarksToFilter = prevSessionStartTime
				? (remark) =>
					remark.remarkDate > new Date(prevSessionStartTime) &&
					remark.remarkDate < new Date(sessionStartTime)
				: () => true;

			return this.clientRemarks
				.filter(remarksToFilter)
				.sort((a, b) => new Date(b.remarkDate) - new Date(a.remarkDate));
		},
	}
}
</script>

<style lang="scss" scoped>
.sessions-wrapper {
	position: relative;
	padding: $global-padding;
	border: 1px solid $light-gray;
	border-radius: $global-radius;
	margin-top: $global-margin;
}

.sessions-header {
  padding: $global-padding / 1.5 $global-padding;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $title-color;
  color: $white;
  //padding: 0.5em 1em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 1.25em;
  font-weight: bold;

  p {
	// flex-grow: 1;
    margin: 0; // keeps button vertically aligned with the text
    padding: 0.5em; // increase width of the sessions title bas
  }
  .add-button {
	align-self: flex-end;
  	color: $white;
  	margin-left: 1em;
  	font-size: 1rem;
  	width: 100%;
  }
}

.sessions-item {
  padding: 0.5em 1em;
  border-bottom: 1px solid $darker-gray;

  &:last-child {
    border-bottom: none;
  }
}

.add-session-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $white; // or any other color you want

	input {
		flex-grow: 1;
		//margin-right: 1em; // add some space between input and buttons
	}

}

.edit-session-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	// background-color: $white; // or any other color you want

	input {
		flex-grow: 1;
		//margin-right: 1em; // add some space between input and buttons
	}
}

</style>