import Vue from 'vue'

export default {
	getCoachingOutcome (id) {
		return Vue.http.get(`coachingOutcome/${id}`)
	},
	createCoachingOutcome (coachingGoalId, outcomeName) {
		console.log("Posting Outcome: { studentCoachingEngagementId, name }", { coachingGoalId, outcomeName })
		return Vue.http.post('coachingOutcome', {
			coachingGoalId, outcomeName
		})
	},
	updateCoachingOutcome (id, outcomeName) {
		return Vue.http.put(`coachingOutcome/${id}`, {
			outcomeName
		})
	},
	deleteCoachingOutcome (id) {
		return Vue.http.delete(`coachingOutcome/${id}`)
	}
}