<template>
  <domain-list-card
    ref="domainListCard"
    :domain-id="domainId"
    :text-id="textId"
    :text-name="textName"
    :details="details"
    :retain-elos="retainElos"
  >
    <div slot="injectEloListCard" slot-scope="{ tlo }">
      <elo-side-tray-elo-list-card
        v-for="elo in retainElos(tlo.elos)"
        :key="elo.id"
        :text-id="elo.eloCode"
        :text-name="elo.eloName"
        :programmeCodes="elo.programmeCodes"
        :strands="elo.strands"
        :isDeveloped="elo.isDeveloped"
        :link="elo.link"
        :active="elo.active"
      >
        <!-- isDeveloped need to be changed into wether there is a link there or not -->
      </elo-side-tray-elo-list-card>
    </div>
  </domain-list-card>
</template>

<script>
import DomainListCard from "./DomainListCard";
import EloSideTrayEloListCard from "./EloSideTrayEloListCard";

export default {
  name: "EloSideTrayDomainListCard",
  components: {
    DomainListCard,
    EloSideTrayEloListCard,
  },
  props: {
    domainId: Number,
    textId: String,
    textName: String,
    details: Array,
    edit: Boolean,
    retainElos: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.domain-list-header {
  background-color: $brand-primary;
  border-top: none;
  border-bottom: 1px solid smart-scale($brand-primary, 35%, 10%);
  font-size: 1rem;
  color: $white;
  height: 3em;
  min-height: 3em;

  .elo-side-tray.elo-piep & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .elo-side-tray.elo-prog & {
    background-color: $darker-gray;
    border-bottom-color: smart-scale($darker-gray, 30%, 20%);
  }

  .elo-side-tray & {
    min-height: 2.7em;
  }
}

.domain-list-title {
  @extend .sans-serif-bold;
  margin-bottom: 0;
  padding-left: $global-padding * 1.1;
  white-space: nowrap;

  .elo-side-tray & {
    padding-left: 0;
  }

  .elo-side-tray &::after {
    @extend .sans-serif-regular;
    position: absolute;
    top: 0.6em;
    right: 0;
    content: "A-IEP";
    font-size: 0.6em;
    letter-spacing: 1px;
    line-height: 1em;
  }

  .elo-piep &::after {
    content: "P-IEP";
  }
}
</style>

