<template>
  <div class="plan-elo-wrapper" v-cloak>
    <div class="grid-x plan-elo-top align-middle">
      <h5 class="cell small-12 large-auto color--text-color margin-bottom=-0">
        {{ $t("lessonPlanEloList.title") }}
      </h5>
      <div class="cell add-elo-link shrink no-print">
        <select v-model="selectedDomain" class="add-elos">
          <option :key="0" :value="0">
            {{ $t("lessonPlanEloList.dropdown.placeholder") }}
          </option>
          <option
            v-for="domain in curriculum.domains"
            :key="domain.id"
            :value="domain.id"
          >
            {{ domain.domainName | labelize }}
          </option>
        </select>
      </div>
    </div>

    <!--{{ lessonElos }} -->
    <div
      v-if="elosInLessonPlan && elosInLessonPlan.length > 0"
      class="elo-list grid-x"
    >
      <div
        class="small-12 medium-4 large-3 elo-list-card-wrapper"
        v-for="lessonElo in elosInLessonPlan"
        :key="lessonElo.elo.id"
      >
        <lesson-plan-elo-card :lesson-elo="lessonElo"> </lesson-plan-elo-card>
      </div>
    </div>
    <div class="empty-elo-list" v-else>
      <p class="empty-message">{{ $t("empty.lesson.elo.message") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LessonPlanEloCard from "./LessonPlanEloCard.vue";

export default {
  name: "LessonPlanEloList",
  components: {
    LessonPlanEloCard,
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
      lessonElos: "lessonElos",
    }),
    elosInLessonPlan: function () {
      return this.$store.getters.lessonElos(this.$route.params.lessonId);
    },
    selectedDomain: {
      get() {
        return 0;
      },
      set(value) {
        if (value !== 0) {
          this.$store.dispatch("setSelectedDomainId", value);
          $("#elo-side-tray").foundation("open");
        }
      },
    },
  },
  data() {
    return {
      Others: "Others",
    };
  },
};
</script>

<style lang="scss" scoped>
.plan-elo-wrapper {
  margin-top: $global-margin / 2;
  margin-bottom: $global-margin;
  border-radius: $global-radius;
  padding: $global-padding / 2;
  background-color: $bg-selected;

  @include breakpoint(medium) {
    padding: $global-padding * 1.3 $global-padding;
  }
}
.plan-elo-top {
  padding-top: $global-padding / 2;
  text-align: center;

  @include breakpoint(large) {
    padding-top: 0;
    text-align: left;
  }
}
.add-elo-link {
  margin: $global-margin / 2 auto;

  @include breakpoint(large) {
    margin: 0;
  }
}
.elo-list-card-wrapper {
  padding: $global-padding / 3;
}
.add-elos {
  @extend .sans-serif-bold;
  background-color: $bg-gray;
  color: $primary-color;
}
</style>
