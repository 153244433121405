<template>
  <CurriculumInterfaceEloListCardDetails
    :elo-code="textId"
    :elo-name="textName"
    :domainName="domainName"
    :domainCode="domainCode"
    :eloLink="eloLink"
    :eloStatus="eloStatus"
    :subDomainName="subDomainName"
    :subDomainCode="subDomainCode"
    :programmeCodes="programmeCodes"
    :tloName="tloName"
    :tloCode="tloCode"
    :eloId="eloId"
    :id="id"
  >
  </CurriculumInterfaceEloListCardDetails>
</template>

<script>
import CurriculumInterfaceEloListCardDetails from "./CurriculumInterfaceEloListCardDetails";

export default {
  name: "CurriculumInterfaceEloListCard",
  components: {
    CurriculumInterfaceEloListCardDetails,
  },
  props: {
    textId: String,
    textName: String,
    eloStatus: Boolean,
    eloLink: String,
    programmeCodes: Array,
    domainName: String,
    domainCode: String,
    subDomainName: String,
    subDomainCode: String,
    tloName: String,
    tloCode: String,
    eloId: String,
    id: Number,
  },
  data() {
    return {
      added: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-section {
  position: relative;
  border-bottom: 1px solid $gray;

  &.is-new-elo {
    border-left: 2px solid $success;
    //-webkit-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //-moz-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
  }

  &.terminated {
    background-color: $light-gray;
  }

  &.edit {
    cursor: pointer;
  }

  &.include-hover {
    &:hover {
      background-color: $bg-selected;
    }
  }

  &.added {
    background-color: $bg-selected;
    border-left: 2px solid $success;
  }

  .check-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}
.icon-image {
  font-size: $small-font-size / 1.1;
  color: $darker-gray;
}
.year-subtext {
  @extend %sans-serif-bold;

  padding: $global-padding / 5 $global-padding / 3;
  border-radius: 3px;
  font-size: $small-font-size / 1.2;
  color: $white;
  text-transform: capitalize;
  line-height: 1em;

  &[data-val="attained"] {
    background-color: $success;
  }
  &[data-val="exempted"] {
    background-color: $alert;
  }
}
.parent {
  position: relative;

  .aiep-more {
    right: 51%;

    @include breakpoint(small down) {
      right: 0;
    }
  }
}
.aiep-plan {
  border-left: 1px solid $gray;
  border-bottom: 1px solid $light-gray;

  .aiep-plan-column:last-child {
    border-top: 1px solid $gray;
  }

  .aiep-plan-column {
    position: relative;
    height: 50%;
    min-height: 2.5em;

    .aiep-label {
      position: absolute;
      transform: translateY(160%) rotateZ(-90deg);
      transform-origin: left bottom;
      font-size: 0.5em;
      color: $darker-gray;
      font-weight: 700;
      padding-bottom: 0.2em;
      text-transform: uppercase;
      pointer-events: none;
    }

    .cell:first-child {
      background-color: #111;
    }

    .cell {
      &:nth-child(odd) {
        &.blank {
          @extend %bg-pattern;
          background-color: $gray;
        }
        background-color: $lighter-gray;
      }
      &:nth-child(even) {
        &.blank {
          @extend %bg-pattern;
          background-color: $gray;
        }
        background-color: $light-gray;
      }

      .aiep-attainment {
        max-width: 100%;
        height: 100%;

        .prompt {
          padding: $global-padding * 0.3 $global-padding * 0.2;
          word-wrap: break-word;
          word-break: break-all;
        }

        .attainment-badge {
          max-width: 100%;
        }
      }
    }
  }
}
</style>

