<template>
  <div class="class-group-edit-profile-control-section">
    <button class="button clear" @click="showUpdateClassGroupModal">
      <font-awesome-icon :icon="['far', 'edit']" size="sm" />
    </button>
    <button class="button clear" @click="showDeleteClassGroupModal">
      <font-awesome-icon :icon="['far', 'trash-alt']" size="sm" />
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ClassGroupModal from "@/views/ClassGroupModal/ClassGroupModal";
import constants from "@/constants";

export default {
  name: "ClassGroupEditProfileControlSection",
  components: {
    FontAwesomeIcon,
  },
  props: {
    classGroup: {
      type: Object,
      required: true,
    },
    selectedClassGroupFullDetails: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["getProgrammeDetails", "getSideMenu", "deleteClassGroup"]),
    showUpdateClassGroupModal() {
      const { id, name, year } = this.classGroup;
      const { schoolId } = this.selectedClassGroupFullDetails;
      const { id: programmeId } = this.selectedClassGroupFullDetails.programme;

      const componentProps = {
        classId: id,
        initName: name,
        initYear: year.toString(),
        initSchoolId: schoolId,
        initProgrammeId: programmeId,
      };

      this.$modal.show({
        component: ClassGroupModal,
        componentProps: componentProps,
        class: "small",
      });
    },
    showDeleteClassGroupModal() {
      const { name: className, year } = this.classGroup;

      let modalParams = {
        header: this.$t("classGroupModal.delete.modalTitle", { className }),
        text: this.$t("classGroupModal.delete.modalText", { className, year }),
        includeConfirm: true,
        successCallback: this.doDeleteClassGroup,
      };

      this.$modal.show(modalParams);
    },
    doDeleteClassGroup() {
      const { id: classId, name: className, year } = this.classGroup;
      const { id: programmeId } = this.selectedClassGroupFullDetails.programme;

      this.deleteClassGroup({ classId }).then(
        ({ status, data }) => {
          if (status === constants.RESPONSE_STATUS.OK) {
            // update sidemenu
            this.getProgrammeDetails({ programmeId, year });
            this.getSideMenu(year);

            this.$router.push({
              name: "Programme",
              params: {
                year: year,
                programmeId: programmeId,
              },
            });

            this.$toastr.success(
              this.$t("classGroupModal.success.deleted", { className, year })
            );
            this.$modal.closeAll();
          } else {
            this.$toastr.error(data && data.message);
          }
        },
        (error) => {
          this.$toastr.error(error.data && error.data.message);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.class-group-edit-profile-control-section {
  margin-bottom: $global-margin * 0.5;
}
</style>
