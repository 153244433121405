import Vue from 'vue'

import GSignInButton from 'vue-google-signin-button'
import AsyncComputed from 'vue-async-computed'

import toastr from './toastr'
import modal from './modal'
import moment from 'vue-moment'

Vue.use(GSignInButton)
Vue.use(AsyncComputed)

Vue.use(toastr)
Vue.use(modal)
Vue.use(moment)
