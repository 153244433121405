<template>
  <div
    class="slider"
    v-if="sliderList"
    v-foundation:Slider="slider"
    :data-initial-start="initialStart"
    :data-initial-end="initialEnd"
    :data-end="sliderList.length"
    :data-double-sided="twoHandles"
    data-changed-delay="500"
    aria-controls="1"
    v-cloak
  >
    <div
      ref="handle1"
      class="slider-handle"
      data-slider-handle
      role="slider"
      tabindex="1"
    >
      <div class="grid-x align-center align-middle">
        <b>{{ $t("slider.start") }}</b>
      </div>
    </div>
    <span class="slider-fill" data-slider-fill></span>
    <div
      v-if="twoHandles"
      ref="handle2"
      class="slider-handle"
      data-slider-handle
      role="slider"
      tabindex="1"
    >
      <div class="grid-x align-center align-middle">
        <b>{{ $t("slider.end") }}</b>
      </div>
    </div>
    <div class="slider-marking" role="presentation">
      <span
        v-for="(marking, i) in sliderList.length"
        :key="i"
        class="slider-tick color--darker-gray text-center"
      >
        <small>{{ $t(sliderList[i]) }}</small>
      </span>
    </div>
    <input type="hidden" ref="handleData1" />
    <input v-if="twoHandles" type="hidden" ref="handleData2" />
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    sliderList: {
      type: Array,
      required: true,
    },
    twoHandles: {
      type: Boolean,
      default: false,
    },
    initialStart: {
      type: Number,
      default: 0,
      validator: (val) => {
        return val >= 0;
      },
    },
    initialEnd: {
      type: Number,
      default: 1,
      validator: (val) => {
        return val >= 0;
      },
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  watch: {
    initialStart: function (val) {
      this.slider._setHandlePos($(this.$refs.handle1), val);
      this.reflow();
    },
    initialEnd: function (val) {
      this.slider._setHandlePos($(this.$refs.handle2), val);
      this.reflow();
    },
  },
  mounted() {
    this.slider = this.$el.foundation;

    this.$nextTick(() => {
      this.slider._reflow();
    });

    $(this.$el).on("moved.zf.slider", this.emitValues);
  },
  destroyed() {
    $(this.$el).off("moved.zf.slider", this.emitValues);
  },
  updated() {
    this.reflow();
  },
  methods: {
    reflow() {
      setTimeout(() => {
        this.slider._reflow();
      }, 50);
    },
    emitValues() {
      this.$emit(
        "update:initialStart",
        parseInt($(this.$refs.handleData1).val())
      );
      this.$emit(
        "update:initialEnd",
        parseInt($(this.$refs.handleData2).val())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-marking {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: space-around;
}
.slider-tick {
  display: block;
  margin-top: 5px;
  width: 100%;
  height: 7px;
  border-left: 1px solid $darker-gray;
  &:last-child {
    border-right: 1px solid $darker-gray;
  }
}
</style>
