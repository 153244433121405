<template>
  <div v-if="hasEloDetails()" class="grid-x parent">
    <elo-list-card-details
      @click.native="eloListCardClicked($event)"
      :style="listCardStyle"
      class="card-section edit include-hover cell auto"
      :class="[
        eloDetails && eloDetails.isNew ? 'is-new-elo' : '',
        eloStatus !== 'ACTIVE' ? 'terminated' : '',
      ]"
      :show-details="showDetails"
      :link="link"
      :elo-code="eloDetails.eloCode"
      :elo-name="eloDetails.eloName"
      :programme-codes="eloDetails.programmeCodes"
    >
      <p class="grid-x align-middle">
        <template v-if="eloStatus !== 'ACTIVE'">
          <span :data-val="eloStatus.toLowerCase()" class="status-subtext">
            {{ eloStatus.toLowerCase() }}
          </span>

          <span class="icon-bar">
            &nbsp;
            <font-awesome-icon
              v-if="eloObject.hasTerminationRemarks"
              class="icon-image comment"
              :icon="['far', 'comment-dots']"
              size="1x"
            >
            </font-awesome-icon>
            &nbsp;
          </span>
        </template>
        <elo-list-card-strands :strands="strands"></elo-list-card-strands>
        <span v-if="latestAttainmentDetails" class="year-subtext">{{
          latestAttainmentDetails.years.join(", ")
        }}</span>
        <attainment-badge
          class="margin-left-1 margin-right-1 attainment-badge"
          v-if="
            latestAttainmentDetails && latestAttainmentDetails.attainmentLevel
          "
          :level="latestAttainmentDetails.attainmentLevel"
        >
        </attainment-badge>
      </p>
    </elo-list-card-details>

    <more-dropdown :options="dropdownOptions" :identifier="$vnode.key">
    </more-dropdown>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TerminateEloModal from "./TerminateEloModal";
import EditEloModal from "@/views/EditEloModal/EditEloModal";
import MoreDropdown from "./MoreDropdown";
import EloListCardDetails from "./EloListCardDetails";
import EloListCardStrands from "./EloListCardStrands";
import AttainmentBadge from "./AttainmentBadge";

export default {
  name: "StudentProgrammePlanEloListCard",
  components: {
    FontAwesomeIcon,
    MoreDropdown,
    EloListCardDetails,
    EloListCardStrands,
    AttainmentBadge,
  },
  props: {
    textId: String,
    textName: String,
    programmeCodes: Array,
    strands: Array,
    showDetails: Boolean,
    link: String,
  },
  data() {
    return {
      added: false,
    };
  },
  computed: {
    ...mapGetters({
      studentPlan: "studentPlan",
    }),
    listCardStyle() {
      if (
        this.latestAttainmentDetails &&
        this.latestAttainmentDetails.attainmentLevel
      ) {
        switch (this.latestAttainmentDetails.attainmentLevel) {
          case "E":
            return "";
          case "D":
            return "";
          case "C":
            return "background-color: #efefef";
          case "R":
            return "background-color: #efefef";
          case "A":
            return "background-color: #efefef";
          default:
            return "";
        }
      } else {
        return "";
      }
    },
    eloObject: function () {
      let studentId = this.$route.params.studentId;
      let eloObject = _.find(
        this.studentPlan[studentId].studentProgrammeElos,
        (elo) => {
          return elo.eloCode === this.textId;
        }
      );
      return eloObject;
    },
    eloStatus: function () {
      return this.eloObject ? this.eloObject.eloStatus : null;
    },
    eloDetails: function () {
      return this.eloObject ? this.eloObject.elo : null;
    },
    studentPlanStatus: function () {
      return this.$store.getters.studentProgrammePlanStatuses[
        this.$route.params.studentId
      ];
    },
    latestAttainmentDetails: function () {
      return this.$store.getters.latestAttainmentDetails(
        this.$route.params.studentId,
        this.textId
      );
    },
    isCommonCore: function () {
      return this.programmeCodes.length > 0;
    },
    dropdownOptions: function () {
      let dropdownOptions = {
        Edit: {
          label: "eloListCard.edit",
          method: this.showAppropriateModal,
        },
        Delete: {
          label: "eloListCard.delete",
          method: this.deleteEloClicked,
        },
        Attained: {
          label: "eloListCard.attained",
          method: this.setToAttained,
        },
        Exempted: {
          label: "eloListCard.exempted",
          method: this.setToExempted,
        },
        Activate: {
          label: "eloListCard.activate",
          method: this.setToActive,
        },
      };

      let finalDropdownOptions = [];

      if (this.isCommonCore) {
        if (this.eloStatus === "ACTIVE") {
          finalDropdownOptions.push(
            dropdownOptions["Edit"],
            dropdownOptions["Attained"],
            dropdownOptions["Exempted"]
          );
        } else {
          finalDropdownOptions.push(
            dropdownOptions["Edit"],
            dropdownOptions["Activate"]
          );
        }
      } else {
        finalDropdownOptions.push(
          dropdownOptions["Edit"],
          dropdownOptions["Delete"]
        );
      }

      return finalDropdownOptions;
    },
  },
  methods: {
    hasEloDetails() {
      return this.eloDetails && this.eloDetails.eloCode;
    },
    eloListCardClicked(event) {
      if (event.target.classList[1] !== "linkTab") {
        this.showAppropriateModal();
      }
    },
    showAppropriateModal() {
      let modalOptions = {
        ATTAINED: this.showAttainmentRemarksModal,
        EXEMPTED: this.showExemptionRemarksModal,
        ACTIVE: this.showEditModal,
      };

      modalOptions[this.eloStatus]();
    },
    showEditModal() {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
		link: this.link,
        programmeCodes: this.programmeCodes,
        studentId: this.$route.params.studentId,
        selectedYear: parseInt(this.$route.params.year),
      };

      this.$modal.show({
        component: EditEloModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    modalClose() {
      let params = {
        studentId: this.$route.params.studentId,
        planYear: this.$route.params.year,
      };
      this.$store.dispatch("getStudentPlan", params).then((success) => {
        if (success) {
          this.$store.dispatch("getLatestAttainmentDetails", params);
        }
      });
    },
    deleteEloClicked() {
      if (this.studentPlanStatus.planStatus === "APPROVED") {
        this.$modal.show({
          header: this.$t("addRemoveElo.whenApproved.modal.header"),
          text: this.$t("addRemoveElo.whenApproved.modal.text"),
        });
      } else {
        this.showDeleteConfirmation();
      }
    },
    showDeleteConfirmation() {
      let modalParams = {
        header: this.$t("eloListCard.delete.elo.programme.modalTitle"),
        text: this.$t("eloListCard.delete.elo.programme.modalText"),
        includeConfirm: true,
        successCallback: this.deleteEloFromProgrammePlan,
      };

      this.$modal.show(modalParams);
    },
    deleteEloFromProgrammePlan() {
      let studentId = this.$route.params.studentId;
      let selectedYear = this.$route.params.year;
      let eloCode = this.textId;

      this.$store
        .dispatch("deleteEloFromProgrammePlan", {
          studentId,
          selectedYear,
          eloCode,
        })
        .then(() => {});
    },
    setToAttained() {
      this.modalOptions("ATTAINED");
    },
    setToExempted() {
      this.modalOptions("EXEMPTED");
    },
    setToActive() {
      this.modalOptions("ACTIVE");
    },
    modalOptions(toBeEloStatus) {
      let modalOptions = {
        ATTAINED: {
          APPROVED: {
            header: this.$t("setAttainedEloOnPiep.whenApproved.modal.header"),
            text: this.$t("setAttainedEloOnPiep.whenApproved.modal.text"),
          },
          DRAFT: {
            header: this.$t("setAttainedEloOnPiep.modal.header") + this.textId,
            text: this.$t("setAttainedEloOnPiep.modal.text"),
            includeConfirm: true,
            successCallback: this.showAttainmentRemarksModal,
          },
        },
        EXEMPTED: {
          APPROVED: {
            header: this.$t("setExemptedEloOnPiep.whenApproved.modal.header"),
            text: this.$t("setExemptedEloOnPiep.whenApproved.modal.text"),
          },
          DRAFT: {
            header: this.$t("setExemptedEloOnPiep.modal.header") + this.textId,
            text: this.$t("setExemptedEloOnPiep.modal.text"),
            includeConfirm: true,
            successCallback: this.showExemptionRemarksModal,
          },
        },
        ACTIVE: {
          APPROVED: {
            header: this.$t("setExemptedEloOnPiep.whenApproved.modal.header"),
            text: this.$t("setExemptedEloOnPiep.whenApproved.modal.text"),
          },
          DRAFT: {
            header: this.$t("setActiveEloOnPiep.modal.header") + this.textId,
            text: this.$t("setActiveEloOnPiep.modal.text"),
            includeConfirm: true,
            successCallback: this.setEloStatusToActive,
          },
        },
      };

      this.$modal.show(
        modalOptions[toBeEloStatus][this.studentPlanStatus.planStatus]
      );
    },
    showAttainmentRemarksModal() {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
        programmeCodes: this.programmeCodes,
        studentId: this.$route.params.studentId,
        selectedYear: parseInt(this.$route.params.year),
        componentType: "Attainment",
      };

      this.$modal.show({
        component: TerminateEloModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    showExemptionRemarksModal() {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
        programmeCodes: this.programmeCodes,
        studentId: this.$route.params.studentId,
        selectedYear: parseInt(this.$route.params.year),
        componentType: "Exemption",
      };

      this.$modal.show({
        component: TerminateEloModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    setEloStatusToActive() {
      // push to store
      let studentId = this.$route.params.studentId;
      let selectedYear = this.$route.params.year;
      let eloCode = this.textId;

      this.$store
        .dispatch("setStudentProgrammeEloStatusActive", {
          studentId,
          selectedYear,
          eloCode,
        })
        .then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.card-section {
  position: relative;
  border-bottom: 1px solid $gray;

  &.is-new-elo {
    border-left: 2px solid $success;
    //-webkit-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //-moz-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
  }

  &.terminated {
    background-color: $light-gray;
  }

  &.edit {
    cursor: pointer;
  }

  &.include-hover {
    &:hover {
      background-color: $bg-selected;
    }
  }

  &.added {
    background-color: $bg-selected;
    border-left: 2px solid $success;
  }

  .check-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}
.icon-image {
  font-size: $small-font-size / 1.1;
  color: $darker-gray;
  vertical-align: baseline;
}
.year-subtext {
  font-size: $small-font-size / 1.1;
  line-height: 1em;
  color: $darker-gray;
}
.status-subtext {
  padding: $global-padding / 5 $global-padding / 3;
  border-radius: 3px;
  font-size: $small-font-size / 1.2;
  text-transform: capitalize;
  line-height: 1em;

  @extend %sans-serif-bold;
  color: $white;

  &[data-val="attained"] {
    background-color: $success;
  }
  &[data-val="exempted"] {
    background-color: $alert;
  }
}
.parent {
  position: relative;
}
</style>
