export const ADD_LANGUAGE = 'ADD_LANGUAGE'

export const ADD_TOASTR = 'ADD_TOASTR'
export const REMOVE_TOASTR = 'REMOVE_TOASTR'

export const ADD_LOADER = 'ADD_LOADER'
export const REMOVE_LOADER = 'REMOVE_LOADER'

export const ADD_MODAL = 'ADD_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const CLEAR_MODALS = 'CLEAR_MODALS'

export const SET_CANVAS_STATE = 'SET_CANVAS_STATE'
export const ADD_DOMAIN_TO_OPEN = 'ADD_DOMAIN_TO_OPEN'
export const REMOVE_DOMAIN_FROM_OPEN = 'REMOVE_DOMAIN_FROM_OPEN'

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_USERNAME = 'SET_USERNAME'
export const SET_ROLES = 'SET_ROLES'
export const SET_DETAILS = 'SET_DETAILS'
export const SET_PROFILE = 'SET_PROFILE'

export const SET_SIDE_MENU = 'SET_SIDE_MENU'
export const SET_HIERARCHY = 'SET_HIERARCHY'
export const SET_CURRENT_YEAR = 'SET_CURRENT_YEAR'
export const SET_ALLOWED_FUNCTIONS = 'SET_FUNCTIONAL_PERMISSIONS'
export const SET_SELECTED_YEAR_FILTER = 'SET_SELECTED_YEAR_FILTER'
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'

export const SET_SCHOOL_IDS = 'SET_SCHOOL_IDS'
export const SET_SCHOOLS = 'SET_SCHOOLS'
export const SET_SCHOOL = 'SET_SCHOOL'
export const REMOVE_SCHOOL = 'REMOVE_SCHOOL'
export const SET_SCHOOL_PROGRAMMES = 'SET_SCHOOL_PROGRAMMES'
export const REMOVE_SCHOOL_PROGRAMMES = 'REMOVE_SCHOOL_PROGRAMMES'

export const SET_TENANT_IDS = 'SET_TENANT_IDS'
export const SET_TENANTS = 'SET_TENANTS'
export const SET_TENANT = 'SET_TENANT'
export const UPDATE_TENANT = 'UPDATE_TENANT'
export const REMOVE_TENANT = 'REMOVE_TENANT'

export const SET_CLASS_GROUP = 'SET_CLASS_GROUP'
export const SET_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS = 'SET_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS'
export const SET_CLASSGROUPSEARCH_CLASS_GROUP_IDS = 'SET_CLASSGROUPSEARCH_CLASS_GROUP_IDS'
export const SET_CLASSGROUPSEARCH_CLASS_GROUPS = 'SET_CLASSGROUPSEARCH_CLASS_GROUPS'
export const SET_CLASSGROUPSEARCH_TEACHERS = 'SET_CLASSGROUPSEARCH_TEACHERS'
export const SET_CLASSGROUPSEARCH_STUDENTS = 'SET_CLASSGROUPSEARCH_STUDENTS'
export const SET_CLASSGROUPSEARCH_UNASSIGNED_STUDENTS = 'SET_CLASSGROUPSEARCH_UNASSIGNED_STUDENTS'
export const SET_CLASSGROUPSEARCH_UNASSIGNED_TEACHERS = 'SET_CLASSGROUPSEARCH_UNASSIGNED_TEACHERS'
export const CLEAR_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS = 'CLEAR_CLASSGROUPSEARCH_CLASS_GROUP_FULL_DETAILS'
export const CLEAR_CLASSGROUPSEARCH_RESULTS = 'CLEAR_CLASSGROUPSEARCH_RESULTS'
export const SET_PROGRAMME_YEAR_IDS = 'SET_PROGRAMME_YEAR_IDS'
export const SET_PROGRAMME_SCHOOL_DETAILS = 'SET_PROGRAMME_SCHOOL_DETAILS'
export const SET_PROGRAMME_CLASS_GROUP_DETAILS = 'SET_PROGRAMME_CLASS_GROUP_DETAILS'

export const SET_CURRICULUM = 'SET_CURRICULUM'
export const SET_CURRICULUM_TIMESTAMP = 'SET_CURRICULUM_TIMESTAMP'
export const SET_CURRICULUM_INTERFACE_TASK_ITEM = 'SET_CURRICULUM_INTERFACE_TASK_ITEM'
export const SET_CURRICULUM_TASK_ITEM = 'SET_CURRICULUM_TASK_ITEM'
export const SET_STUDENT_PLAN = 'SET_STUDENT_PLAN'
export const SET_STUDENT_ANNUAL_PLAN = 'SET_STUDENT_ANNUAL_PLAN'
export const ADD_TO_ELO_CODE = 'ADD_TO_ELO_CODE'
export const REMOVE_FROM_ELO_CODE = 'REMOVE_FROM_ELO_CODE'
export const CLEAR_ELO_CODE = 'CLEAR_ELO_CODE'
export const ADD_NEW_ANNUAL_ELO = 'ADD_NEW_ANNUAL_ELO'
export const REMOVE_ANNUAL_ELO = 'REMOVE_ANNUAL_ELO'
export const ADD_NEW_ANNUAL_ELO_CODES = 'ADD_NEW_ANNUAL_ELO_CODES'
export const ADD_NEW_PROGRAMME_ELO = 'ADD_NEW_PROGRAMME_ELO'
export const REMOVE_PROGRAMME_ELO = 'REMOVE_PROGRAMME_ELO'
export const SET_PROGRAMME_ELO_STATUS = 'SET_PROGRAMME_ELO_STATUS'

export const STUDENT_ELO_TERMINATION_REMARKS = 'STUDENT_ELO_TERMINATION_REMARKS'

export const SET_PLAN_STATUS_FOR_CLASS_GROUP = 'SET_PLAN_STATUS_FOR_CLASS_GROUP'
export const SET_PROGRAMME_PLAN_STATUS = 'SET_PROGRAMME_PLAN_STATUS'
export const SET_ANNUAL_PLAN_STATUS = 'SET_ANNUAL_PLAN_STATUS'

export const SET_LATEST_ATTAINMENT_DETAILS = 'SET_LATEST_ATTAINMENT_DETAILS'

export const SET_ELO_DETAILS = 'SET_ELO_DETAILS'
export const SET_TASK_ITEM_LIST = 'SET_TASK_ITEM_LIST'
export const SET_ALL_TASK_ITEM_REMARKS = 'SET_ALL_TASK_ITEM_REMARKS'
export const SET_TASK_ITEM_REMARKS = 'SET_TASK_ITEM_REMARKS'
export const SET_ELO_ATTAINMENT = 'SET_ELO_ATTAINMENT'
export const SET_TASK_ITEM_COUNT_SUMMARY = 'SET_TASK_ITEM_COUNT_SUMMARY'
export const SET_ELO_REMARKS = 'SET_ELO_REMARKS'
export const UPDATE_ELO_REMARKS = 'UPDATE_ELO_REMARKS'

export const SET_ELO_ASSESSMENT_CRITERIA_SUGGESTION = 'SET_ELO_ASSESSMENT_CRITERIA_SUGGESTION'

export const SET_CLASS_ATTAINMENT_SCORES = 'SET_CLASS_ATTAINMENT_SCORES'

export const SET_DASHBOARD_LIST = 'SET_DASHBOARD_LIST'

export const SET_LESSON_PLANS = 'SET_LESSON_PLANS'
export const SET_LESSON_PLAN = 'SET_LESSON_PLAN'
export const SET_IS_EDIT_MODE = 'SET_IS_EDIT_MODE'
export const SET_STUDENT_ELOS_FOR_TERM = 'SET_STUDENT_ELOS_FOR_TERM'
export const SET_LESSON_ELOS = 'SET_LESSON_ELOS'
export const SET_LESSON_PLAN_REMARKS = 'SET_LESSON_PLAN_REMARKS'

export const RESET_USER_STATE = 'RESET_USER_STATE'
export const SET_PERMITTED_ROLES = 'SET_PERMITTED_ROLES'
export const SET_USER = 'SET_USER'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_USER_TEACHER_DETAILS = 'SET_USER_TEACHER_DETAILS'
export const REMOVE_USER = 'REMOVE_USER'
export const REMOVE_USER_ROLE = 'REMOVE_USER_ROLE'
export const REMOVE_USER_TEACHER_DETAILS = 'REMOVE_USER_TEACHER_DETAILS'

// EloSideTray Variables
export const SET_SELECTED_DOMAIN_ID = 'SET_SELECTED_DOMAIN_ID';
export const SET_DOMAIN = 'SET_DOMAIN';

export const SET_STUDENT_PROFILE = 'SET_STUDENT_PROFILE';

export const SET_ATTAINMENT_LEVEL_LIST = 'SET_ATTAINMENT_LEVEL_LIST';
export const SET_TASK_ITEM_CATEGORY_LIST = 'SET_TASK_ITEM_CATEGORY_LIST';
export const SET_PROMPT_HIERARCHY_LIST = 'SET_PROMPT_HIERARCHY_LIST';
export const SET_OUTCOME_LEVEL_LIST = 'SET_OUTCOME_LEVEL_LIST';

// Student Support Needs
export const SET_LEARNING_SUPPORT_NEED_LIST = 'SET_LEARNING_SUPPORT_NEED_LIST';
export const SET_BEHAVIOUR_SUPPORT_NEED_LIST = 'SET_BEHAVIOUR_SUPPORT_NEED_LIST';
export const SET_ADDITIONAL_SUPPORT_NEED_LIST = 'SET_ADDITIONAL_SUPPORT_NEED_LIST';

// Coaching Interface
export const SET_ACTIVE_GOAL = 'SET_ACTIVE_GOAL';
export const SET_COACHING_ENGAGEMENT = 'SET_COACHING_ENGAGEMENT';
export const SET_COACHING_ENGAGEMENTS = 'SET_COACHING_ENGAGEMENTS';
export const ADD_COACHING_ENGAGEMENT = 'ADD_COACHING_ENGAGEMENT';
export const UPDATE_COACHING_ENGAGEMENT = 'UPDATE_COACHING_ENGAGEMENT';
export const DELETE_COACHING_ENGAGEMENT = 'DELETE_COACHING_ENGAGEMENT';

export const SET_STUDENT_COACHING_ENGAGEMENT = 'SET_STUDENT_COACHING_ENGAGEMENT';
export const SET_STUDENT_COACHING_ENGAGEMENTS = 'SET_STUDENT_COACHING_ENGAGEMENTS';
export const SET_CURRENT_COACHING_ENGAGEMENT = 'SET_CURRENT_COACHING_ENGAGEMENT';
export const SET_CURRENT_COACHING_ENGAGEMENT_COACHING_SESSION_OUTCOME_ASSESSMENTS = 'SET_CURRENT_COACHING_ENGAGEMENT_COACHING_SESSION_OUTCOME_ASSESSMENTS';
export const SET_CURRENT_COACHING_ENGAGEMENT_LOCK_ACCESS = 'SET_CURRENT_COACHING_ENGAGEMENT_LOCK_ACCESS';
export const ADD_STUDENT_COACHING_ENGAGEMENT = 'ADD_STUDENT_COACHING_ENGAGEMENT';
export const UPDATE_STUDENT_COACHING_ENGAGEMENT = 'UPDATE_STUDENT_COACHING_ENGAGEMENT';
export const DELETE_STUDENT_COACHING_ENGAGEMENT = 'DELETE_STUDENT_COACHING_ENGAGEMENT';

export const SET_COACHING_GOAL = 'SET_COACHING_GOAL';
export const SET_COACHING_GOALS = 'SET_COACHING_GOALS';
export const ADD_COACHING_GOAL = 'ADD_COACHING_GOAL';
export const UPDATE_COACHING_GOAL = 'UPDATE_COACHING_GOAL';
export const DELETE_COACHING_GOAL = 'DELETE_COACHING_GOAL';

export const SET_COACHING_OUTCOME = 'SET_COACHING_OUTCOME';
export const SET_COACHING_OUTCOMES = 'SET_COACHING_OUTCOMESS';
export const ADD_COACHING_OUTCOME = 'ADD_COACHING_OUTCOME';
export const UPDATE_COACHING_OUTCOME = 'UPDATE_COACHING_OUTCOME';
export const DELETE_COACHING_OUTCOME = 'DELETE_COACHING_OUTCOME';

export const SET_COACHING_SESSION = 'SET_COACHING_SESSION';
export const SET_COACHING_SESSIONS = 'SET_COACHING_SESSIONSS';
export const ADD_COACHING_SESSION = 'ADD_COACHING_SESSION';
export const UPDATE_COACHING_SESSION = 'UPDATE_COACHING_SESSION';
export const DELETE_COACHING_SESSION = 'DELETE_COACHING_SESSION';
export const SET_COACHING_SESSION_OUTCOME_ASSESSMENTS = 'SET_COACHING_SESSION_OUTCOME_ASSESSMENTS';

export const SET_COACHING_OUTCOME_ASSESSMENT = 'SET_COACHING_OUTCOME_ASSESSMENT';
export const SET_COACHING_OUTCOME_ASSESSMENTS = 'SET_COACHING_OUTCOME_ASSESSMENTS';
export const ADD_COACHING_OUTCOME_ASSESSMENT = 'ADD_COACHING_OUTCOME_ASSESSMENT';
export const UPDATE_COACHING_OUTCOME_ASSESSMENT = 'UPDATE_COACHING_OUTCOME_ASSESSMENT';
export const DELETE_COACHING_OUTCOME_ASSESSMENT = 'DELETE_COACHING_OUTCOME_ASSESSMENT';

export const SET_COACHING_OUTCOME_SELF_ASSESSMENT = 'SET_COACHING_OUTCOME_SELF_ASSESSMENT';
export const SET_COACHING_OUTCOME_SELF_ASSESSMENTS = 'SET_COACHING_OUTCOME_SELF_ASSESSMENTS';
export const ADD_COACHING_OUTCOME_SELF_ASSESSMENT = 'ADD_COACHING_OUTCOME_SELF_ASSESSMENT';
export const UPDATE_COACHING_OUTCOME_SELF_ASSESSMENT = 'UPDATE_COACHING_OUTCOME_SELF_ASSESSMENT';
export const DELETE_COACHING_OUTCOME_SELF_ASSESSMENT = 'DELETE_COACHING_OUTCOME_SELF_ASSESSMENT';

export const SET_COACHING_LEARNING_POINT = 'SET_COACHING_LEARNING_POINT';
export const SET_COACHING_LEARNING_POINTS = 'SET_COACHING_LEARNING_POINTS';
export const ADD_COACHING_LEARNING_POINT = 'ADD_COACHING_LEARNING_POINT';
export const UPDATE_COACHING_LEARNING_POINT = 'UPDATE_COACHING_LEARNING_POINT';
export const DELETE_COACHING_LEARNING_POINT = 'DELETE_COACHING_LEARNING_POINT';