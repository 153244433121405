<template>
  <CurriculumInterfaceDomainList ref="programmePlanDomainList">
    <CurriculumInterfaceProgrammePlanDomainListCard
      @click.stop="openDomainListAccordionFor"
      v-for="card in curriculum.domains"
      :key="card.id"
      :domain-id="card.id"
      :text-id="card.domainId"
      :text-name="card.domainName"
      :details="curriculum.domainDetails[card.id]"
    >
    </CurriculumInterfaceProgrammePlanDomainListCard>
  </CurriculumInterfaceDomainList>
</template>

<script>
import { mapGetters } from "vuex";
import CurriculumInterfaceDomainList from "@/components/CurriculumInterfaceDomainList";
import CurriculumInterfaceProgrammePlanDomainListCard from "@/components/CurriculumInterfaceProgrammePlanDomainListCard";

export default {
  name: "CurriculumInterfaceProgrammePlanDomainList",
  components: {
    CurriculumInterfaceDomainList,
    CurriculumInterfaceProgrammePlanDomainListCard,
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
    }),
  },
  data() {
    return {};
  },
  methods: {
    openDomainListAccordionFor(domainId, contentTarget) {
      this.$refs.programmePlanDomainList.openAccordionFor(
        domainId,
        contentTarget
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
