<template>
  <form v-cloak @submit.prevent="launchCloneTaskItemListConfirmModal">
    <h4 class="modal-title">
      {{ $t("editEloDetails.analysis.cloneModal.title") }}
    </h4>

    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-6 padding-bottom-1">
        <label for="editEloDetails_analysis_cloneModal_term">{{
          $t("editEloDetails.analysis.cloneModal.sourceTerm")
        }}</label>
        <select
          id="editEloDetails_analysis_cloneModal_term"
          v-model="formData.sourceTerm"
          required
        >
          <option
            v-for="term in termFilters"
            :key="term.value"
            :value="term.value"
          >
            {{ $t(term.label) }}
          </option>
        </select>
      </div>

      <div class="cell small-12 medium-6">
        <label for="editEloDetails_analysis_cloneModal_student_id">
          {{ $t("editEloDetails.analysis.cloneModal.sourceStudentId") }}
          <!-- <button type="button" class="button clear" @click="pasteClipboard">
						<font-awesome-icon :icon="['far', 'clipboard']"></font-awesome-icon>
					</button> -->
        </label>
        <input
          id="editEloDetails_analysis_cloneModal_student_id"
          type="text"
          ref="sourceStudentId"
          v-model.trim="formData.sourceStudentId"
        />
      </div>
    </div>

    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-6 padding-bottom-1">
        <label for="editEloDetails.analysis.cloneModal.multipleStudent">
          {{ $t("editEloDetails.analysis.cloneModal.multipleStudent") }}
        </label>
        <!-- <input id="toggleMultiple" type="checkbox" v-model="multiple"> -->
        <div class="onoffswitch">
          <input
            type="checkbox"
            v-model="multiple"
            name="onoffswitch"
            class="onoffswitch-checkbox"
            id="myonoffswitch"
            tabindex="0"
            checked
          />
          <label class="onoffswitch-label" for="myonoffswitch">
            <span class="onoffswitch-inner"></span>
            <span class="onoffswitch-switch"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="grid-x grid-margin-x" v-if="multiple">
      <div class="cell small-12 medium-6" v-if="multiple">
        <label for="editEloDetails.analysis.cloneModal.schoolName">
          {{ $t("editEloDetails.analysis.cloneModal.schoolName") }}
        </label>
        <select
          id="editEloDetails_analysis_cloneModal_term"
          v-model="school"
          required
        >
          <option
            v-for="(data, index) in programmeList"
            :key="data.id"
            :value="index"
          >
            {{ $t(data.name) }}
          </option>
        </select>
      </div>

      <div class="cell small-12 medium-6" v-if="multiple">
        <label for="editEloDetails.analysis.cloneModal.levelName">
          {{ $t("editEloDetails.analysis.cloneModal.levelName") }}
        </label>
        <select
          id="editEloDetails_analysis_cloneModal_term"
          v-model="level"
          required
        >
          <option
            v-for="(data, index) in programmeList[school].programmes"
            :key="data.id"
            :value="index"
          >
            {{ $t(data.name) }}
          </option>
        </select>
      </div>
    </div>

    <div class="grid-x grid-margin-x" v-if="multiple">
      <div class="cell small-12 medium-6" v-if="multiple">
        <label for="editEloDetails.analysis.cloneModal.className">
          {{ $t("editEloDetails.analysis.cloneModal.className") }}
        </label>
        <select
          id="editEloDetails_analysis_cloneModal_term"
          v-model="classSelected"
          required
        >
          <option
            v-for="(data, index) in programmeList[school].programmes[level]
              .classes"
            :key="data.id"
            :value="index"
          >
            {{ $t(data.name) }}
          </option>
        </select>
      </div>

      <div class="cell small-12 medium-6" v-if="multiple">
        <label for="editEloDetails.analysis.cloneModal.studentName">
          {{ $t("editEloDetails.analysis.cloneModal.studentName") }}
        </label>
        <select
          id="editEloDetails_analysis_cloneModal_term"
          v-model="students"
          required
          multiple
        >
          <option
            v-for="(data, index) in programmeList[school].programmes[level]
              .classes[classSelected].students"
            :key="data.id"
            :value="index"
          >
            {{ $t(data.fullName) }}
          </option>
        </select>
      </div>
    </div>

    <div class="grid-x">
      <span
        >{{
          $t("editEloDetails.analysis.cloneModal.studentId", { studentName })
        }}:</span
      >&nbsp;
      <span class="copy-student-id" @click="copyStudentId"
        ><b>{{ studentId }}</b></span
      >&nbsp;
      <button type="button" class="button clear" @click="copyStudentId">
        <font-awesome-icon :icon="['far', 'copy']"></font-awesome-icon>
      </button>
    </div>

    <div class="action-selector">
      <div class="grid-x align-right">
        <button type="submit" class="button primary margin-bottom-0">
          {{ $t("editEloDetails.analysis.cloneModal.clone") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "EditEloModalAnalysisClone",
  components: {
    FontAwesomeIcon,
  },
  props: {
    closeSelf: {
      type: Function,
      required: true,
    },
    studentId: {
      type: String,
      required: true,
    },
    eloCode: {
      type: String,
      required: true,
    },
    planYear: {
      type: Number,
      required: true,
    },
    targetTerm: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        sourceTerm: 0,
        sourceStudentId: "",
      },
      multiple: false,
      school: 0,
      level: 0,
      classSelected: 0,
      students: [],
    };
  },
  computed: {
    ...mapGetters(["termFilters", "studentForStudentId", "programmeList"]),
    studentObj() {
      return this.studentForStudentId(this.studentId);
    },
    studentName() {
      return (
        this.studentObj &&
        this.studentObj.student &&
        this.studentObj.student.displayName
      );
    },
  },
  methods: {
    ...mapActions(["cloneTaskItemListFromTerm"]),
    copyStudentId() {
      const el = document.createElement("textarea");
      el.value = this.studentId;
      document.body.appendChild(el);
      el.select();
      if (document.execCommand("copy")) {
        this.$toastr.success(
          this.$t("editEloDetails.analysis.cloneModal.copySuccess", {
            value: el.value,
          })
        );
      } else {
        this.$toastr.error(
          this.$t("editEloDetails.analysis.cloneModal.copyError")
        );
      }
      document.body.removeChild(el);
    },
    async pasteClipboard() {
      try {
        const text = await navigator.clipboard.readText();
        this.formData.sourceStudentId = text;
      } catch (error) {
        this.$toastr.error(error);
      }
    },
    launchCloneTaskItemListConfirmModal() {
      const { sourceTerm, sourceStudentId } = this.formData;

      const selectedTerm = this.termFilters.find(
        (it) => it.value === sourceTerm
      );

      this.$modal.show({
        header: this.$t("editEloDetails.analysis.confirm.modalTitle"),
        text: this.$t(
          `editEloDetails.analysis.confirm.modalText${
            sourceStudentId !== "" ? "WithStudentId" : ""
          }`,
          {
            sourceTerm: selectedTerm
              ? this.$t(selectedTerm.label)
              : "undefined",
            sourceStudentId,
          }
        ),
        includeConfirm: true,
        successCallback: this.doCloneTaskItemList,
      });
    },
    async doCloneTaskItemList() {
      const {
        programmeList,
        formData: { sourceTerm, sourceStudentId },
        school,
        level,
        classSelected,
        students,
        multiple,
      } = this;
      var sourceStudentSelect = sourceStudentId;
      if (!sourceStudentId) {
        sourceStudentSelect = this.studentId;
      }
      if (multiple) {
        const idArray = [];
        for (const ids of students) {
          const { students } =
            programmeList[school].programmes[level].classes[classSelected];
          const { id } = students[ids];

          idArray.unshift(id);
        }

        try {
          await this.cloneTaskItemListFromTerm({
            originalStudentId: this.studentId,
            studentId: idArray,
            eloCode: this.eloCode,
            planYear: this.planYear,
            planTerm: sourceTerm,
            targetTerm: this.targetTerm,
            sourceStudentId: sourceStudentSelect,
            multi: true,
          });
          this.closeSelf();
          this.$toastr.success(
            this.$t("editEloDetails.analysis.cloneModal.cloneSuccess")
          );
        } catch (err) {
          this.$toastr.error(err.data && err.data.message);
        }
      } else {
        try {
          await this.cloneTaskItemListFromTerm({
            studentId: this.studentId,
            eloCode: this.eloCode,
            planYear: this.planYear,
            planTerm: sourceTerm,
            targetTerm: this.targetTerm,
            sourceStudentId: sourceStudentSelect,
          });

          this.closeSelf();
          this.$toastr.success(
            this.$t("editEloDetails.analysis.cloneModal.cloneSuccess")
          );
        } catch (err) {
          this.$toastr.error(err.data && err.data.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-student-id {
  cursor: pointer;
}
.onoffswitch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #999999;
  border-radius: 20px;
  margin-right: 0;
  margin-left: 0.3rem;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "MULTI";
  padding-left: 10px;
  background-color: #34a7c1;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "ONE";
  padding-right: 10px;
  background-color: #eeeeee;
  color: #999999;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 29px;
  margin: 0.5px;
  background: #f0f0f0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
</style>