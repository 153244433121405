<template>
  <div
    class="the-sidebar-profile no-print"
    :class="expanded ? 'margin-1' : 'padding-top-1 padding-bottom-1'"
    v-cloak
  >
    <div
      class="profile grid-x align-center overflow-hidden"
      :class="expanded ? '' : ''"
    >
      <profile-image
        v-if="profile && profile.details"
        :image="profile.details.image"
        :image-alt="profile.details.displayName"
        :image-size="40"
        :image-title="profile.details.fullName"
      ></profile-image>
      <div
        v-if="expanded && profile.details"
        class="profile-details cell auto margin-left-1"
      >
        <p class="h6 margin-bottom-0">{{ profile.details.displayName }}</p>
        <p class="color--medium-gray margin-bottom-0">
          <small>{{ profile.details.designation }}</small>
        </p>
      </div>
    </div>

    <div v-if="expanded" class="grid-x align-right">
      <a class="logout text-uppercase" @click="logout"
        ><small>{{ $t("sidebar.logout") }}</small></a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileImage from "./ProfileImage";

export default {
  name: "TheSidebarProfile",
  components: {
    ProfileImage,
  },
  props: {
    expanded: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.the-sidebar-profile {
  padding-bottom: $global-padding / 2;
  border-bottom: 1px solid transparentize($light-gray, 0.8);

  .profile-details {
    margin-left: 0.5em;
  }
}
.logout {
  font-size: 0.8em;
}
</style>
