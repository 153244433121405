<template>
  <div v-cloak>
    <div v-if="!list || list.length === 0">
      {{ classGroup.coachingEngagement ? $t("classGroup.noStudentFound.coaching") : $t("classGroup.noStudentFound") }}
    </div>
    <class-group-edit-list-card
      v-else
      v-for="card in list"
      :key="card.id"
      :card-id="card.id"
      :image="card.image"
      :conditionTypes="card.conditionTypes"
      :text-content="card.displayName"
      :profile-name="card.fullName"
      :update-function="updateFunction"
      :remove-function="removeFunction"
    >
    </class-group-edit-list-card>
  </div>
</template>

<script>
import ClassGroupEditListCard from "./ClassGroupEditListCard";

export default {
  name: "ClassGroupEditList",
  components: {
    ClassGroupEditListCard,
  },
  props: {
    list: Array,
    updateFunction: Function,
    removeFunction: Function,
    classGroup: Object,
  },
};
</script>

<style lang="scss" scoped>
</style>
