<template>
  <div class="card evaluation-wrapper" v-cloak>
    <div class="card-section">
      <h5 class="margin-bottom-2 margin-top-1">
        {{ $t("lessonPlanRemarks.title") }}
      </h5>
      <p class="empty-message" v-if="remarks && remarks.length == 0">
        {{ $t("lessonPlanRemarks.empty") }}
      </p>
      <remark-card
        v-for="remark in remarks"
        :key="remark.id"
        :remark="remark"
        :evidence-src="evidenceSrc"
        @deleteRemark="deleteRemark"
        @fileLoaded="createImage"
      >
      </remark-card>
      <form @submit.prevent="submit" class="no-print margin-top-2">
        <rich-text-editor
          v-model="formData.remark"
          :placeholder="$t('remarks.message.placeholder')"
          :height="100"
        >
        </rich-text-editor>

        <div class="action-selector margin-top-1">
          <div class="grid-x align-right">
            <button type="submit" class="cell button shrink default hollow">
              {{ $t("remarks.add") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import RemarkCard from "./RemarkCard";
import RichTextEditor from "./RichTextEditor";

export default {
  name: "LessonPlanRemarks",
  components: {
    RemarkCard,
    RichTextEditor,
  },
  data() {
    return {
      formData: {
        remark: "",
      },
      evidenceSrc: "getLessonPlanRemarkEvidence",
    };
  },
  computed: {
    remarks: function () {
      return this.$store.getters.lessonPlanRemarks(this.$route.params.lessonId);
    },
  },
  methods: {
    submit() {
      if (!_.isEmpty(this.formData.remark)) {
        let remarksForm = { ...this.formData };
        let lessonPlanId = this.$route.params.lessonId;
        this.$store
          .dispatch("postLessonPlanRemark", {
            lessonPlanId: lessonPlanId,
            remark: remarksForm.remark,
          })
          .then((this.formData.remark = ""));
      }
    },
    deleteRemark(remarkId) {
      this.$store.dispatch("deleteLessonPlanRemark", remarkId).then(
        () => {
          this.$store.dispatch("getLessonPlanRemarks", {
            lessonPlanId: this.$route.params.lessonId,
          });
        },
        () => {
          this.$toastr.error("unable to delete");
        }
      );
    },
    createImage(file, fileName, remarkId) {
      let formData = new FormData();
      formData.append("remarkId", remarkId);
      formData.append("file", file);
      formData.append("fileName", fileName);
      this.$store
        .dispatch("addEvidenceToLessonPlanRemark", {
          details: formData,
          lessonPlanId: this.$route.params.lessonId,
        })
        .catch((error) => {
          this.$toastr.error(error.data && error.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluation-wrapper {
  padding: 0 $global-padding / 2;
  background-color: $bg-special;
  border: 1px solid transparentize($brand-quinary, 0.4);
  box-shadow: 0px 2px 0px 0px transparentize($brand-quinary, 0.4);
}
</style>