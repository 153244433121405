<template>
  <form v-if="editable" @submit.prevent="submit" v-cloak>
    <h6>
      {{ $t("editEloDetails.general.baselineAssessmentCriteria.header") }}
    </h6>
    <textarea
      class="cell auto padding-right-2"
      v-model="formData.baselineAssessmentCriteria"
      :placeholder="
        $t('editEloDetails.general.baselineAssessmentCriteria.placeholder')
      "
      rows="8"
    >
    </textarea>

    <div class="grid-x margin-top-1 align-middle">
      <h6 class="cell auto">
        {{ $t("editEloDetails.general.assessmentCriteria.header") }}
      </h6>
      <div class="cell shrink suggestion-button">
        <button
          type="button"
          class="btn-info color--gray clear button"
          data-toggle="suggestion-dropdown"
        >
          <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
        </button>
      </div>
    </div>
    <div class="grid-x align-bottom position-relative">
      <textarea
        class="cell auto padding-right-2"
        v-model="formData.assessmentCriteria"
        :placeholder="
          $t('editEloDetails.general.assessmentCriteria.placeholder')
        "
        rows="8"
      >
      </textarea>
      <assessment-criteria-suggestion :text-id="eloCode" :text-name="eloName">
      </assessment-criteria-suggestion>
    </div>

    <h6 class="margin-top-1">
      {{ $t("editEloDetails.general.slider.header") }}
    </h6>
    <div class="slider-container">
      <slider
        :slider-list="sliderList"
        :initial-start.sync="formData.startTerm"
        :initial-end.sync="formData.endTerm"
        :two-handles="true"
      >
      </slider>
    </div>

    <p
      class="last-updated"
      v-if="eloDetails && eloDetails.assessedOn && eloDetails.assessedBy"
    >
      <small>
        {{ $t("editEloDetails.lastUpdated.assessedOn") }}
        {{ eloDetails.assessedOn | moment("calendar") }}
        {{ $t("editEloDetails.lastUpdated.by") }}
        {{ eloDetails.assessedBy.fullName }}
      </small>
    </p>

    <div class="action-selector">
      <div class="grid-x align-right">
        <div class="cell shrink">&nbsp;</div>
        <button type="submit" class="button primary margin-bottom-0">
          {{ $t("editEloDetails.update") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Slider from "@/components/Slider";
import AssessmentCriteriaSuggestion from "@/components/AssessmentCriteriaSuggestion";

export default {
  name: "EditEloModalGeneral",
  components: {
    Slider,
    FontAwesomeIcon,
    AssessmentCriteriaSuggestion,
  },
  props: {
    eloCode: String,
    eloName: String,
    editable: Boolean,
    studentId: String,
    selectedYear: Number,
  },
  data() {
    return {
      sliderList: [
        "editEloDetails.general.term1",
        "editEloDetails.general.term2",
        "editEloDetails.general.term3",
        "editEloDetails.general.term4",
      ],
      formData: {
        startTerm: 0,
        endTerm: 4,
        baselineAssessmentCriteria: "",
        assessmentCriteria: "",
      },
    };
  },
  computed: {
    eloDetails: function () {
      return this.$store.getters.studentEloDetails(this.studentId);
    },
  },
  watch: {
    eloDetails: function (val) {
      if (!_.isEmpty(val)) {
        // array of slider starts from 0
        val.startTerm -= 1;
        this.formData = { ...this.formData, ...val };
        this.formData.baselineAssessmentCriteria =
          this.formData.baselineAssessmentCriteria ||
          this.$t(
            "editEloDetails.general.baselineAssessmentCriteria.placeholder"
          );
      }
    },
  },
  created() {
    let params = {
      studentId: this.studentId,
      eloCode: this.eloCode,
      planYear: this.selectedYear,
    };
    this.$store.dispatch("getStudentEloDetails", params);
  },
  methods: {
    submit() {
      // return to original term to send back to server
      let massageFormData = { ...this.formData };
      massageFormData.startTerm += 1;
      let studentId = this.studentId;
      let planYear = this.selectedYear;
      this.$store
        .dispatch("postStudentEloDetails", {
          studentId: studentId,
          eloCode: this.eloCode,
          planYear: planYear,
          formData: massageFormData,
        })
        .then(
          () => {
            this.$toastr.success(this.$t("editEloDetails.saved"));
            this.$modal.closeAll();
          },
          (error) => {
            this.$toastr.error(error.data && error.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  height: 100%;
}
.action-selector {
  padding-top: $global-padding * 1.5;
}
textarea {
  resize: vertical;
  min-height: 2.5em;
}
.suggestion-button {
  right: 0.5em;
  bottom: 0.5em;
}
.btn-info {
  font-size: map-deep-get($header-styles, small, h2, font-size) * 1px;
}
.last-updated {
  margin-top: $global-margin * 2;
  line-height: 1.2em;
}
</style>
