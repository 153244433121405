<template>
  <div class="programme-dashboard container-wrapper" v-cloak>
    
<!--
    <p>
    {{  programme }}
    {{  year }}
    {{  showCreateClassGroupModal }}
    </p>

    <div>
      <p> hasCoachingEngagement: {{ hasCoachingEngagement }}</p>
    </div>
  -->

    <programme-profile
      :programme="programme"
      :year="year"
      @create-class-group="showCreateClassGroupModal"
    />
    <div v-if="hasCoachingEngagement" class="grid-x margin-top-1 margin-bottom-1 align-bottom" v-cloak>
      <RadioFilter
        :optionList="filterOptions"
        :selectedValue="engagementFilter"
        :callback="updateEngagementFilter"
      ></RadioFilter>
    </div>
    <div class="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
      <programme-dashboard-list-card
        class="cell"
        v-for="classGroupId in filteredProgrammeClassGroupIds"
        :key="classGroupId"
        :programme-details="programmeClassGroups[classGroupId]"
      >
      </programme-dashboard-list-card>
    </div>
  </div>
</template>

<script>
import ProgrammeProfile from "./ProgrammeProfile";
import ProgrammeDashboardListCard from "./ProgrammeDashboardListCard";
import ClassGroupModal from "@/views/ClassGroupModal/ClassGroupModal";
import RadioFilter from "@/components/RadioFilter";
import { mapGetters, mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "ProgrammeDashboard",
  components: {
    ProgrammeDashboardListCard,
    ProgrammeProfile,
    RadioFilter,
  },
  props: {
    year: Number,
    programmeId: Number,
  },
  watch: {
    year() {
      this.loadProgrammeClassGroups();
    },
    programmeId() {
      this.loadProgrammeClassGroups();
    },
  },
  computed: {
    ...mapState({
      selectedYearFilter: (state) => state.sideMenu.selectedYearFilter, // may not necessary
      selectedItemClass: (state) => state.sideMenu.selectedItemClass, // may not necessary
    }),
    ...mapGetters(["programmes"]),
    programmeYearId() {
      return this.programmeId.toString() + "_" + this.year.toString();
    },
    programmeSchool() {
      return this.$store.getters.programmeSchoolDetailsForId(
        this.programmeYearId
      );
    },
    programme: function () {
      return _.find(this.programmes, (obj) => {
        return obj.id === parseInt(this.programmeId);
      });
    },
    programmeClassGroups() {
      return this.$store.getters.programmeClassGroupDetailsForId(
        this.programmeYearId
      );
    },
    programmeClassGroupIds: function () {
      return _.keys(this.programmeClassGroups);
    },
    hasCoachingEngagement: function () {
      if (this.programmeClassGroupIds && Array.isArray(this.programmeClassGroupIds) && this.programmeClassGroups && typeof this.programmeClassGroups === 'object') {
        return this.programmeClassGroupIds.some(classGroupId => {
          const classGroupObj = this.programmeClassGroups[classGroupId];
          return  classGroupObj && 
                  classGroupObj.classGroup && 
                  classGroupObj.classGroup.coachingEngagement !== undefined && 
                  classGroupObj.classGroup.coachingEngagement !== null;
        });
      }
      return false;
    },
    filteredProgrammeClassGroupIds() {
      if (this.engagementFilter === 'all') {
        return this.programmeClassGroupIds;
      }
      return this.programmeClassGroupIds.filter(classGroupId => {
        const classGroupObj = this.programmeClassGroups[classGroupId];
        return   classGroupObj && 
                  classGroupObj.classGroup && 
                  classGroupObj.classGroup.coachingEngagement !== undefined && 
                  classGroupObj.classGroup.coachingEngagement !== null &&
                  classGroupObj.classGroup.coachingEngagement.completed === false;
      });
    },
    filterOptions() {
      return [
        { label: 'All', value: 'all' },
        { label: 'Ongoing Engagements Only', value: 'ongoing' },
      ];
    },
  },
  data() {
    return {
      engagementFilter: 'all',
    };
  },
  created() {
    this.loadProgrammeClassGroups();
  },
  methods: {
    loadProgrammeClassGroups() {
      if (this.programmeClassGroups) {
        let programme = this.$store.getters.programmes.find(
          (it) => it.id === this.programmeId
        );
        this.setSelectedItemClass(programme && programme.identifier);
        return this.programmeClassGroups;
      }

      this.fetchProgrammeClassGroupsFromApi();
    },
    fetchProgrammeClassGroupsFromApi() {
      if (this.programmeId) {
        this.getProgrammeDetails({
          programmeId: this.programmeId,
          year: this.year,
        });
        let programme = this.$store.getters.programmes.find(
          (it) => it.id === this.programmeId
        );
        this.setSelectedItemClass(programme && programme.identifier);
      } else {
        this.setSelectedItemClass(null);
      }
    },
    ...mapActions(["getProgrammeDetails", "setSelectedItemClass"]),
    showCreateClassGroupModal() {
      const componentProps = {
        initName: "New Name",
        initYear: this.year.toString(),
        initSchoolId: this.programmeSchool
          ? this.programmeSchool.schoolId
          : undefined,
        initProgrammeId: this.programmeId ? this.programmeId : undefined,
      };

      this.$modal.show({
        component: ClassGroupModal,
        componentProps: componentProps,
        class: "small",
      });
    },
    shouldDisplayCard(programmeDetails) {
      if (this.engagementFilter === 'all') {
        return true;
      }
      if (this.engagementFilter === 'ongoing' && programmeDetails.classGroup && programmeDetails.classGroup.coachingEngagement !== null && programmeDetails.classGroup.coachingEngagement !== undefined) {
        return true;
      }
      return false;
    },
    updateEngagementFilter(value) {
      this.engagementFilter = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  flex-grow: 0;
  // margin: $global-margin / 3 !important;
}
</style>
