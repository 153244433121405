var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu vertical",class:_vm.level > 0 ? 'nested' : ''},[(!_vm.filteredList || _vm.filteredList.length === 0)?_c('li',{staticClass:"menu-item empty-item"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.menu.nested.empty"))+" ")]):_vm._e(),_vm._l((_vm.filteredList),function(nested){return _c('li',{key:nested.identifier},[_c('div',{staticClass:"grid-x",class:[
        _vm.isSelectedItemClass(nested.identifier) ? 'selected' : '',
        'level-' + _vm.level,
      ]},[_c('a',{staticClass:"menu-item cell auto truncate",attrs:{"title":nested.name || nested.displayName},on:{"click":function($event){return _vm.updateDashboard(nested)}}},[_vm._v(_vm._s(nested.name || nested.displayName))]),(
          nested[_vm.hierarchy[_vm.level + 1]] &&
          nested[_vm.hierarchy[_vm.level + 1]].length > 0
        )?_c('div',{staticClass:"grid-x align-middle"},[(!_vm.dropdownMode)?_c('button',{staticClass:"button clear caret padding-left-1 padding-right-1",on:{"click":function($event){return _vm.toggleOpen(nested)}}},[_c('font-awesome-icon',{staticClass:"type-icon",attrs:{"icon":nested.isOpen ? 'chevron-up' : 'chevron-down'}})],1):_c('div',{staticClass:"caret padding-left-1 padding-right-1"},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)]):_vm._e()]),(
        nested[_vm.hierarchy[_vm.level + 1]] &&
        nested[_vm.hierarchy[_vm.level + 1]].length > 0
      )?_c('the-sidebar-menu-nested',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdownMode || nested.isOpen),expression:"dropdownMode || nested.isOpen"}],attrs:{"list":nested[_vm.hierarchy[_vm.level + 1]],"hierarchy":_vm.hierarchy,"level":_vm.level + 1,"dropdown-mode":_vm.dropdownMode}}):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }