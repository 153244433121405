<template>
  <form
    class="card"
    :style="`border: ${
      packetLimitExceeded ? 'red solid thick' : 'black solid thin'
    }`"
    @submit.prevent="submit"
    v-cloak
  >
    <small class="version-mark">{{ "Version: OCMS-1.3.23" }}</small>
    <div class="card-section card-inner">
      <div class="grid-x">
        <div class="cell small-12 large-6 small-order-2 large-order-1">
          <label>
            <date-range-picker
              :startDate.sync="formData.startDate"
              :endDate.sync="formData.endDate"
            ></date-range-picker>
          </label>
        </div>
        <div
          class="cell small-12 large-3 small-order-2 large-order-2"
          style="margin-right: 1rem"
        >
          <label for="title">{{ $t("lessonPlan.body.form.lessonWeek") }}</label>
          <input
            type="text"
            placeholder="1, 2, 3, ..."
            v-model="formData.weekUnderstandContent"
          />
        </div>
        <div class="cell shrink small-order-2 large-order-2">
          <label>{{ $t("lessonPlan.body.form.lessonType") }}</label>
          <ul class="grid-x grid-padding-x align-middle group-radio">
            <li
              v-for="lessonType in lessonTypes"
              :key="lessonType.value"
              class="option cell shrink"
              :class="formData.type === lessonType.value ? 'selected' : ''"
            >
              <label :for="lessonType.value">
                <FontAwesomeIcon
                  class="lesson-type-icon"
                  :icon="lessonType.icon"
                ></FontAwesomeIcon>
                <span class="truncate">{{ $t(lessonType.label) }}</span>
              </label>
              <input
                type="radio"
                :id="lessonType.value"
                :value="lessonType.value"
                v-model="formData.type"
              />
            </li>
          </ul>
        </div>
      </div>

      <div class="grid-x">
        <div class="small-12 cell">
          <label for="title">{{
            $t("lessonPlan.body.form.lessonTitle")
          }}</label>
          <input
            type="text"
            id="title"
            name="title"
            v-model="formData.title"
            @focus="$event.target.select()"
          />
        </div>
      </div>

      <div class="small-12 cell">
        <label>{{ $t("lessonPlan.body.form.assignTeachers") }}</label>
        <ul class="grid-x group-checkbox">
          <li
            v-for="teacher in teacherOptions"
            :key="teacher.id"
            class="option cell small-12 medium-shrink"
            :class="isTeacherSelected(teacher) ? 'selected' : ''"
          >
            <label
              class="grid-x align-middle cell auto"
              :for="teacher.id + teacher.fullName"
            >
              <profile-image
                class="cell shrink padding-left-1 padding-right-1"
                :image-title="teacher.fullName"
                :image="teacher.image"
                :image-alt="teacher.displayName"
                :image-size="40"
              ></profile-image>
              <span class="cell auto padding-right-1 truncate">{{
                teacher.displayName
              }}</span>
            </label>
            <input
              type="checkbox"
              :id="teacher.id + teacher.fullName"
              :value="teacher"
              v-model="formData.assignedTeachers"
            />
          </li>
          <li class="last cell auto"></li>
        </ul>
      </div>
      <div class="text-content-areas grid-x margin-top-2">
        <div id="table" class="small-12 cell">
          <h5
            :style="`background-color: ${
              formData.learningObjectives !== null &&
              formData.learningObjectives.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Lesson Objectives
          </h5>
          <rich-text-editor
            :style="`background-color: ${
              formData.learningObjectives !== null &&
              formData.learningObjectives.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="activateContent"
            class="section"
            v-model="formData.learningObjectives"
          ></rich-text-editor>
          <!-- <rich-text-editor id="activateContent" class="section" v-model="formData.activateContent"
					placeholder="Assessment of Student Learning
Explain how you will introduce the target skill for this lesson. Make connections to prior learning or knowledge."></rich-text-editor> -->
          <div class="break" v-html="'<p></p>'"></div>
          <h5
            :style="`background-color: ${
              formData.activateContent !== null &&
              formData.activateContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Introduction
          </h5>
          <div class="section" style="display: flex">
            <!-- <div class="subSection">
							<input type="text" placeholder="Week" v-model="formData.weekUnderstandContent"/>
						</div> -->
            <div class="subSection" style="border-left: black solid thin">
              <input
                type="text"
                placeholder="Time"
                v-model="formData.timeIndependentPractice"
              />
            </div>
          </div>
          <rich-text-editor
            :style="`background-color: ${
              formData.activateContent !== null &&
              formData.activateContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="understand"
            class="section customHeight"
            v-model="formData.activateContent"
          ></rich-text-editor>
          <div class="section" style="display: flex">
            <div class="subSectionTitle">
              <p>Teachers & Materials</p>
            </div>
            <div class="subSectionTitle">
              <a href="https://udlguidelines.cast.org/" target="_blank"
                >UDL Principles & Evidence</a
              >
            </div>
          </div>
          <div class="section customHeight" style="display: flex">
            <rich-text-editor
              :style="`background-color: ${
                formData.materialsIndependentPractice !== null &&
                formData.materialsIndependentPractice.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.materialsIndependentPractice"
            ></rich-text-editor>
            <rich-text-editor
              :style="`background-color: ${
                formData.evidenceIndependentPractice !== null &&
                formData.evidenceIndependentPractice.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.evidenceIndependentPractice"
            ></rich-text-editor>
          </div>

          <div class="break" v-html="'<p></p>'"></div>
          <h5
            :style="`background-color: ${
              formData.understandContent !== null &&
              formData.understandContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Lesson Development (I-DO)
          </h5>
          <p>
            What methods, activities and examples will be used to present the
            concept, content or skill (say, do, model, show, etc?) How will the
            lesson be structured and sequenced?
          </p>
          <div class="section" style="display: flex">
            <!-- <div class="subSection">
							<input type="text" placeholder="Week" v-model="formData.weekPracticeContent"/>
						</div> -->
            <div class="subSection" style="border-left: black solid thin">
              <input
                type="text"
                placeholder="Time"
                v-model="formData.timeUnderstandContent"
              />
            </div>
          </div>
          <rich-text-editor
            :style="`background-color: ${
              formData.understandContent !== null &&
              formData.understandContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="practice"
            class="section customHeight"
            v-model="formData.understandContent"
          ></rich-text-editor>
          <div class="section" style="display: flex">
            <div class="subSectionTitle">
              <p>Teachers & Materials</p>
            </div>
            <div class="subSectionTitle">
              <a href="https://udlguidelines.cast.org/" target="_blank"
                >UDL Principles & Evidence</a
              >
            </div>
          </div>
          <div class="section customHeight" style="display: flex">
            <rich-text-editor
              :style="`background-color: ${
                formData.materialsUnderstandContent !== null &&
                formData.materialsUnderstandContent.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.materialsUnderstandContent"
            ></rich-text-editor>
            <rich-text-editor
              :style="`background-color: ${
                formData.evidenceUnderstandContent !== null &&
                formData.evidenceUnderstandContent.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.evidenceUnderstandContent"
            ></rich-text-editor>
          </div>

          <div class="break" v-html="'<p></p>'"></div>
          <h5
            :style="`background-color: ${
              formData.practiceContent !== null &&
              formData.practiceContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Guided Practice (WE-DO)
          </h5>
          <p>
            How will students demonstrate or practice the skills? What is your
            approach to support them to perform the skill?
          </p>
          <div class="section" style="display: flex">
            <!-- <div class="subSection">
							<input type="text" placeholder="Week" v-model="formData.weekApplyContent"/>
						</div> -->
            <div class="subSection" style="border-left: black solid thin">
              <input
                type="text"
                placeholder="Time"
                v-model="formData.timePracticeContent"
              />
              <!-- <input type="text" placeholder="Time" v-model="formData.timeApplyContent"/> -->
            </div>
          </div>
          <!-- <rich-text-editor id="practice" class="section customHeight" v-model="formData.applyContent" -->
          <rich-text-editor
            :style="`background-color: ${
              formData.practiceContent !== null &&
              formData.practiceContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="practice"
            class="section customHeight"
            v-model="formData.practiceContent"
          ></rich-text-editor>
          <div class="section" style="display: flex">
            <div class="subSectionTitle">
              <p>Teachers & Materials</p>
            </div>
            <div class="subSectionTitle">
              <a href="https://udlguidelines.cast.org/" target="_blank"
                >UDL Principles & Evidence</a
              >
            </div>
          </div>
          <div class="section customHeight" style="display: flex">
            <rich-text-editor
              :style="`background-color: ${
                formData.materialsPracticeContent !== null &&
                formData.materialsPracticeContent.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.materialsPracticeContent"
            ></rich-text-editor>
            <rich-text-editor
              :style="`background-color: ${
                formData.evidencePracticeContent !== null &&
                formData.evidencePracticeContent.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.evidencePracticeContent"
            ></rich-text-editor>
          </div>

          <div class="break" v-html="'<p></p>'"></div>
          <h5
            :style="`background-color: ${
              formData.applyContent !== null &&
              formData.applyContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Independent Practice (YOU-DO)
          </h5>
          <p>
            What will students need to do to demonstrate the skill
            independently? How will you plan for opportunities for
            generalisation and maintenance of the skills?
          </p>
          <div class="section" style="display: flex">
            <!-- <div class="subSection">
							<input type="text" placeholder="Week" v-model="formData.weekIndependentPractice"/>
						</div> -->
            <div class="subSection" style="border-left: black solid thin">
              <!-- <input type="text" placeholder="Time" v-model="formData.timeIndependentPractice"/> -->
              <input
                type="text"
                placeholder="Time"
                v-model="formData.timeApplyContent"
              />
            </div>
          </div>
          <!-- <rich-text-editor id="practice" class="section customHeight" v-model="formData.independentPractice" -->
          <rich-text-editor
            :style="`background-color: ${
              formData.applyContent !== null &&
              formData.applyContent.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="practice"
            class="section customHeight"
            v-model="formData.applyContent"
          ></rich-text-editor>
          <div class="section" style="display: flex">
            <div class="subSectionTitle">
              <p>Teachers & Materials</p>
            </div>
            <div class="subSectionTitle">
              <a href="https://udlguidelines.cast.org/" target="_blank"
                >UDL Principles & Evidence</a
              >
            </div>
          </div>
          <div class="section customHeight" style="display: flex">
            <!-- <rich-text-editor id="understand" class="subSection" placeholder="Teachers & Materials" v-model="formData.materialsIndependentPractice"></rich-text-editor>
						<rich-text-editor id="understand" class="subSection" placeholder="UDL Principles & Evidence" v-model="formData.evidenceIndependentPractice"></rich-text-editor> -->
            <rich-text-editor
              :style="`background-color: ${
                formData.materialsApplyContent !== null &&
                formData.materialsApplyContent.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.materialsApplyContent"
            ></rich-text-editor>
            <rich-text-editor
              :style="`background-color: ${
                formData.evidenceApplyContent !== null &&
                formData.evidenceApplyContent.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.evidenceApplyContent"
            ></rich-text-editor>
          </div>

          <div class="break" v-html="'<p></p>'"></div>
          <h5
            :style="`background-color: ${
              formData.closureReview !== null &&
              formData.closureReview.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Closure / Review
          </h5>
          <p>
            How are you going to review concepts taught/ learned and wrap up the
            lesson?
          </p>
          <div class="section" style="display: flex">
            <!-- <div class="subSection">
							<input type="text" placeholder="Week" v-model="formData.weekClosureReview"/>
						</div> -->
            <div class="subSection" style="border-left: black solid thin">
              <input
                type="text"
                placeholder="Time"
                v-model="formData.timeClosureReview"
              />
            </div>
          </div>
          <rich-text-editor
            :style="`background-color: ${
              formData.closureReview !== null &&
              formData.closureReview.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="practice"
            class="section customHeight"
            v-model="formData.closureReview"
          ></rich-text-editor>
          <div class="section" style="display: flex">
            <div class="subSectionTitle">
              <p>Teachers & Materials</p>
            </div>
            <div class="subSectionTitle">
              <a href="https://udlguidelines.cast.org/" target="_blank"
                >UDL Principles & Evidence</a
              >
            </div>
          </div>
          <div class="section customHeight" style="display: flex">
            <rich-text-editor
              :style="`background-color: ${
                formData.materialsClosureReview !== null &&
                formData.materialsClosureReview.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.materialsClosureReview"
            ></rich-text-editor>
            <rich-text-editor
              :style="`background-color: ${
                formData.evidenceClosureReview !== null &&
                formData.evidenceClosureReview.length > maxRichText
                  ? '#D9534F'
                  : 'white'
              }`"
              id="understand"
              class="subSection"
              v-model="formData.evidenceClosureReview"
            ></rich-text-editor>
          </div>

          <div class="break" v-html="'<p></p>'"></div>
          <h5
            :style="`background-color: ${
              formData.analysesStudentLearning !== null &&
              formData.analysesStudentLearning.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
          >
            Assessment of Student Learning
          </h5>
          <p>
            What methods of assessment will be used to determine each student’s
            learning? How will you know if each student has learned / understood
            the content that was taught? (Include Generalisation and
            Maintenance).
          </p>
          <rich-text-editor
            :style="`background-color: ${
              formData.analysesStudentLearning !== null &&
              formData.analysesStudentLearning.length > maxRichText
                ? '#D9534F'
                : 'white'
            }`"
            id="practice"
            class="customHeight"
            v-model="formData.analysesStudentLearning"
          ></rich-text-editor>
        </div>
      </div>
      <div class="margin-top-1 action-selector">
        <div class="grid-x align-right">
          <p v-if="packetLimitExceeded" class="error-paragraph">
            Reduce content to activate save!
          </p>
          <button
            type="button"
            @click="emitCancel()"
            class="cancel-button button secondary hollow margin-bottom-0"
          >
            {{ $t("lessonPlan.body.form.cancel") }}
          </button>
          <button
            type="submit"
            :disabled="packetLimitExceeded"
            class="save-button button primary margin-bottom-0"
          >
            {{ $t("lessonPlan.body.form.save") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProfileImage from "./ProfileImage";
import DateRangePicker from "./DateRangePicker";
import RichTextEditor from "./RichTextEditor";

export default {
  name: "LessonPlanBodyEdit",
  components: {
    FontAwesomeIcon,
    ProfileImage,
    DateRangePicker,
    RichTextEditor,
  },
  props: {
    lessonPlan: {
      type: Object,
    },
  },
  data() {
    return {
      lessonTypes: [
        {
          label: "lessonPlan.body.form.lessonType.group",
          icon: "users",
          value: "GROUP",
        },
        {
          label: "lessonPlan.body.form.lessonType.individual",
          icon: "user",
          value: "INDIVIDUAL",
        },
      ],
      formData: {},
      maxRichText: 1000000, // 4193000,
      disableSave: false,
    };
  },
  computed: {
    teacherOptions: function () {
      return this.$store.getters.classGroup.teachers;
    },
    limitAlert: function () {
      var bg = "background-color: white";
      if (this.formData.learningObjectives) {
        if (this.formData.learningObjectives.length > this.maxRichText) {
          console.log(this.formData.learningObjectives.length);
          bg = "background-color: #d9534f";
        }
      }
      return bg;
    },
    packetLimitExceeded: function () {
      if (
        (
          this.formData.analysesStudentLearning +
          this.formData.evidenceClosureReview +
          this.formData.materialsClosureReview +
          this.formData.closureReview +
          this.formData.evidenceApplyContent +
          this.formData.materialsApplyContent +
          this.formData.applyContent +
          this.formData.materialsPracticeContent +
          this.formData.evidencePracticeContent +
          this.formData.practiceContent +
          this.formData.understandContent +
          this.formData.evidenceUnderstandContent +
          this.formData.materialsUnderstandContent +
          this.formData.materialsIndependentPractice +
          this.formData.evidenceIndependentPractice +
          this.formData.activateContent +
          this.formData.learningObjectives +
          ""
        ).length > this.maxRichText
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    console.log("LessonPlanBodyEdit");
    console.log(this.lessonPlan);
    this.formData = this.cloneLessonPlan();
    console.log("blabla", this.formData);
  },
  methods: {
    cloneLessonPlan() {
      return _.cloneDeep(this.lessonPlan);
    },
    isTeacherSelected(teacher) {
      return _.find(
        this.formData.assignedTeachers,
        (it) => it.id === teacher.id
      );
    },
    emitCancel() {
      this.$emit("cancelEdit");
    },
    submit() {
      this.$store
        .dispatch("updateLessonPlan", {
          lessonId: this.formData.id,
          lessonPlan: this.formData,
        })
        .then(
          () => {
            this.$emit("submitEdit");
          },
          (error) => {
            this.$toastr.error(error.data && error.data.message);
          }
        );
    },
  },
};
</script>

<style scoped>
#table {
  border: black solid thin;
}
.section {
  border-bottom: black solid thin;
}
.break {
  height: 1rem;
  border-bottom: black solid thin;
}
.subSectionTitle {
  width: 100%;
  /* border-left: black solid thin */
}
.subSectionTitle:nth-child(2) {
  border-left: black solid thin;
}
.subSection {
  width: 100%;
  height: 10%;
}
.customHeight {
  min-height: 15vh;
}
input {
  margin: 0;
}
</style>

<style lang="scss" scoped>
.card {
  border: 2px solid $brand-quinary;
}
.card-inner {
  padding: $global-padding * 1.5;
}

.version-mark {
  text-align: right;
  font-size: 10px;
  margin: 0.5em;
  color: gray;
}

.divider {
  border-top: 1px solid $light-gray;
}
.group-checkbox {
  label {
    width: 100%;
  }
}
.group-radio {
  .option {
    label {
      height: auto;

      padding-top: $global-padding / 3;
      padding-bottom: $global-padding / 3;

      @include breakpoint(small down) {
        padding-left: $global-padding / 3;
      }

      color: $dark-gray;

      .lesson-type-icon {
        margin-right: 0.3em;
      }
    }

    &.selected label {
      color: $black;
    }
  }
}
.action-selector {
  .error-paragraph {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: red;
    margin-bottom: 0;
    margin-right: $global-margin / 2;
  }
  .cancel-button {
    margin-right: $global-margin / 2;
  }
}
</style>
