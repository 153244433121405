import Vue from 'vue'

export default {
	getStudentAttainment (studentId, eloCode, planYear, planTerm) {
		return Vue.http.get('studentEloAttainment/' + studentId, {
			params: {
				eloCode: eloCode,
				planYear: planYear,
				planTerm: planTerm
			}
		})
	},
	postStudentAttainment (studentId, eloCode, planYear, planTerm, formData) {
		return Vue.http.post('studentEloAttainment', {
			studentId: studentId,
			eloCode: eloCode,
			planYear: planYear,
			planTerm: planTerm,
			assessmentCycleResults: formData
		})
	}
}
