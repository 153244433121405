// coachingEngagement.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingEngagementApi from '@/api/coachingEngagement'
import constants from '@/constants';

// initial state
const state = {
  // old coachingEngagement: null,
  coachingEngagement: {},
  coachingEngagements: [],
};

// getters
const getters = {
  coachingEngagement: state => state.coachingEngagement,
  coachingEngagements: state => state.coachingEngagements,
};

// actions
const actions = {
  setCoachingEngagement({ commit }, coachingEngagement) {
    commit(types.SET_COACHING_ENGAGEMENT, coachingEngagement);
  },

  // new actions
  setCoachingEngagements({ commit }, coachingEngagements) {
    commit(types.SET_COACHING_ENGAGEMENTS, coachingEngagements);
  },
  async getCoachingEngagement({ commit }, id) {
    const response = await CoachingEngagementApi.getCoachingEngagement(id)
    commit(types.SET_COACHING_ENGAGEMENT, response.data)
  },

  //async createCoachingEngagement({ commit }, engagementData) {
  //  console.log("STORE: createCoachingENgagement - engagementData: ", engagementData)
  //  const response = await CoachingEngagementApi.createCoachingEngagement(engagementData)
  //  commit('newCoachingEngagement', response.data)
  //},

  async createCoachingEngagement({ commit, dispatch }, { classGroupId, engagementDate, completed, resources = "" }) {
    console.log("STORE: createCoachingEngagement - { classGroupId, engagementDate, completed, resources }:", { classGroupId, engagementDate, completed, resources })
    const response = await CoachingEngagementApi.createCoachingEngagement(classGroupId, engagementDate, completed, resources);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_ENGAGEMENT, response.data.coachingEngagement);
      // here we need to get the classGroup again to have the latest infor with coachingEngagement
      await dispatch('getClassGroup', classGroupId);
    }
  },

  // async updateCoachingEngagement({ commit }, engagementData) {
  async updateCoachingEngagement({ commit, dispatch }, { id, classGroupId, engagementDate, completed, resources = "" }) {
    const response = await CoachingEngagementApi.updateCoachingEngagement(id, classGroupId, engagementDate, completed, resources)
    commit(types.UPDATE_COACHING_ENGAGEMENT, response.data.coachingEngagement)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getClassGroup', classGroupId);
  },

  async deleteCoachingEngagement({ commit, dispatch }, id, classGroupId) {
    await CoachingEngagementApi.deleteCoachingEngagement(id)
    commit(types.DELETE_COACHING_ENGAGEMENT, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getClassGroup', classGroupId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_ENGAGEMENT](state, coachingEngagement) {
    state.coachingEngagement = coachingEngagement;
  },
  [types.SET_COACHING_ENGAGEMENTS] (state, engagements) {
    state.coachingEngagements = engagements
  },
  [types.ADD_COACHING_ENGAGEMENT] (state, engagement) {
    state.coachingEngagements.unshift(engagement)
  },
  [types.UPDATE_COACHING_ENGAGEMENT] (state, engagement) {
    Vue.set(state.coachingEngagement, engagement)
    // const index = state.coachingEngagements.findIndex(e => e.id === engagement.id)
    // if (index !== -1) {
    //   Vue.set(state.coachingEngagements, index, engagement)
    // }
  },
  [types.DELETE_COACHING_ENGAGEMENT] (state, engagementId) {
    state.coachingEngagements = state.coachingEngagements.filter(e => e.id !== engagementId)
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
