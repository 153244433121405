<template>
  <div class="remark-card grid-x parent" v-cloak>
    <div class="profile-image-section shrink cell margin-right-1">
      <profile-image
        v-if="remark && remark.remarkBy"
        :image="remark.remarkBy.image"
        :image-alt="remark.remarkBy.displayName"
        :image-size="30"
        :image-title="remark.remarkBy.fullName"
      ></profile-image>
    </div>
    <more-dropdown
      class="no-print"
      v-if="filteredDropdownOptions.length"
      :options="filteredDropdownOptions"
      :identifier="'remark-' + $vnode.key"
    ></more-dropdown>
    <input
      :id="inputId"
      type="file"
      style="display: none"
      accept="image/*"
      :enctype="encType"
      @change="fileUpload"
      ref="fileInput"
    />

    <div class="remark-content auto cell grid-x">
      <div class="remark-meta small-12 medium-12 large-12 cell">
        <span class="remark-by">{{ remark.remarkBy.displayName }},</span>
        <span class="remark-designation color--dark-gray"
          >{{ remark.remarkBy.designation }},</span
        >
        <span class="remark-on color--dark-gray">{{
          remark.remarkOn | moment("calendar")
        }}</span>
      </div>
      <div class="grid-x small-12 medium-12 large-12 cell">
        <div
          class="remark"
          v-html="remark.remark"
          v-if="!this.edit"
          :key="remark.id"
        ></div>
        <div class="remark" v-if="external && this.edit">
          <form @submit.prevent="update">
            <rich-text-editor
              v-model="remark.remark"
              :placeholder="$t('remarks.message.placeholder')"
              :height="100"
            >
            </rich-text-editor>
            <div class="action-selector margin-top-1">
              <div class="grid-x align-right">
                <button
                  type="submit"
                  class="cell button shrink primary hollow margin-bottom-0"
                >
                  {{ $t("editEloDetails.update") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="grid-x small-12 medium-12 large-12 cell">
        <image-container
          class="image-container"
          v-for="evidence in remark.evidences"
          :key="evidence.id"
          :image-context-id="remark.id"
          :image-id="evidence.fileUUID"
          :image-alt="evidence.name"
          :image-size="50"
          :image-type="'RemarkEvidence'"
          :image-title="evidence.name"
          :image-src="evidenceSrc"
        >
        </image-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileImage from "./ProfileImage";
import MoreDropdown from "./MoreDropdown";
import ImageContainer from "./ImageContainer";
import RichTextEditor from "@/components/RichTextEditor";
export default {
  name: "RemarkCard",
  components: {
    ProfileImage,
    MoreDropdown,
    ImageContainer,
    RichTextEditor,
  },
  props: {
    remark: {
      type: Object,
      required: true,
    },
    external: {
      type: Boolean,
      required: true,
    },
    evidenceSrc: String,
  },
  data() {
    return {
      fileUploadOption: {
        label: "remarks.attachFileOption",
        method: this.triggerFileUpload,
      },
      deleteOption: {
        label: "remarks.deleteRemarkOption",
        method: this.deleteRemarkConfirm,
      },
      editOption: {
        label: "remarks.editRemarkOption",
        method: this.editRemark,
      },
      maxFileSize: 2 * 1000 * 1000,
      edit: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
    inputId: function () {
      return "fileInput_" + this.remark.id;
    },
    encType: function () {
      return "multipart/form-data";
    },
    filteredDropdownOptions: function () {
      let dropdownOptions = [];
      const profileRole = this.$store.getters.profile.roles;
      const currentDate = new Date();
      const isRoleChecked = profileRole.map((element) => {
        return element.indexOf("TEACHER") > 0;
      });
      if (
        this.external &&
        isRoleChecked.includes(true) &&
        currentDate.getFullYear() === this.remark.planYear
      ) {
        dropdownOptions.push(this.editOption);
      }

      dropdownOptions.push(this.fileUploadOption);

      if (
        this.profile.details &&
        this.remark.remarkBy &&
        this.profile.details.id === this.remark.remarkBy.id
      ) {
        dropdownOptions.push(this.deleteOption);
      }

      return dropdownOptions;
    },
  },
  methods: {
    fileUpload(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.loadFile(files[0]);
    },
    loadFile(file) {
      if (this.fileValidation(file)) {
        let fReader = new FileReader();

        fReader.readAsDataURL(file);

        fReader.onloadend = (event) => {
          this.$emit(
            "fileLoaded",
            event.target.result,
            file.name,
            this.remark.id
          );
          this.$refs.fileInput.value = null;
        };
      }
    },
    fileValidation(file) {
      if (file.size > this.maxFileSize) {
        this.$toastr.error(this.$t("error.maxFileSize"));
        this.$refs.fileInput.value = null;
        return false;
      } else if (file.type.indexOf("image") === -1) {
        this.$toastr.error(this.$t("error.invalidType"));
        this.$refs.fileInput.value = null;
        return false;
      }
      return true;
    },
    triggerFileUpload() {
      document.getElementById(this.inputId).click();
    },
    editRemark() {
      this.edit = true;
      const element = document.getElementById(
        "more-dropdown-remark-" + this.remark.id
      );
      element.classList.remove("is-open");
    },
    update() {
      let remarks = this.remark.remark;
      let studentId = this.remark.studentId;
      let planYear = this.remark.planYear;
      this.$store
        .dispatch("updateStudentEloRemark", {
          remarkId: this.remark.id,
          studentId: studentId,
          eloCode: this.remark.eloCode,
          planYear: planYear,
          remark: remarks,
          external: this.external,
        })
        .then(() => {
          this.edit = false;
        })
        .catch((error) => {
          this.$toastr.error(error.data && error.data.message);
          this.edit = true;
        });
    },
    deleteRemarkConfirm() {
      this.$modal.show({
        header: this.$t("remarks.delete.modalTitle"),
        text: this.$t("remarks.delete.modalText"),
        includeConfirm: true,
        successCallback: this.deleteRemark,
      });
    },
    deleteRemark() {
      this.$emit("deleteRemark", this.remark.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.remark-card {
  border: none;
  margin-bottom: $global-margin;

  &:last-child {
    margin-bottom: $global-margin * 3;
  }
}
.remark-meta {
  margin-bottom: $global-margin / 3;
  font-size: $small-font-size;
}
.remark-by {
  color: $black;
  font-weight: $global-weight-bold;
}
.remark {
  padding-right: $global-padding * 1.5;
}
.image-container {
  margin: $menu-icon-spacing $menu-icon-spacing $menu-icon-spacing 0;
}

.parent {
  position: relative;
}
</style>

