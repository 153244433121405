<template>
  <div class="container-wrapper" v-cloak>
    <div class="grid-x">
      <div class="cell medium-auto small-12 margin-bottom-1">
        <h1 class="page-title">{{ $t("reports") }}</h1>
        <h6 class="sans-serif-regular color--darker-gray" v-if="classGroup">
          {{ classGroup.name }}, {{ classGroup.year }}
        </h6>
      </div>
    </div>

    <aiep-report class="margin-top-1" :student-list="studentList"></aiep-report>
    <aiep-report-no-attainment
      class="margin-top-1"
      :student-list="studentList"
    ></aiep-report-no-attainment>
    <piep-report class="margin-top-1" :student-list="studentList"></piep-report>
    <iar-report class="margin-top-1" :student-list="studentList"></iar-report>
    <class-group-report
      class="margin-top-1"
      :class-group-list="classGroupList"
    ></class-group-report>
  </div>
</template>

<script>
import _ from "lodash";
import AiepReport from "./AiepReport";
import AiepReportNoAttainment from "./AiepReportNoAttainment";
import PiepReport from "./PiepReport";
import IarReport from "./IarReport";
import ClassGroupReport from "./ClassGroupReport";

export default {
  name: "Reports",
  components: {
    AiepReport,
    AiepReportNoAttainment,
    PiepReport,
    IarReport,
    ClassGroupReport,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    studentList: function () {
      let classGroup = this.$store.getters.classGroup;
      if (!classGroup) return;
      return _.map(classGroup.students, (student) => {
        return { id: student.id, fullName: student.fullName };
      });
    },
    classGroup: function () {
      let classGroup = { ...this.$store.getters.classGroup };
      let profile = this.$store.getters.profile;
      if (!_.isEmpty(classGroup) && profile.details) {
        classGroup.teachers =
          classGroup.teachers &&
          classGroup.teachers.filter((it) => it.id !== profile.details.id);
      }
      return !_.isEmpty(classGroup) ? classGroup : null;
    },
    classGroupList: function () {
      return this.classGroup ? [this.classGroup] : [];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
