<template>
<div>
    <div class="control-section grid-x align-middle">
        <div class="cell small-12 medium-auto">
            <button class="button clear" @click="toAddItem()">
                <font-awesome-icon icon="plus"></font-awesome-icon>
                {{ $t("editEloDetails.analysis.addAnalysis") }}
            </button>
        </div>
    </div>
    <Task-Item-Add-Modal-Card v-if="addItem" :eloCode="this.eloCode" :readonly="false" :taskDescription="newItemValue" :task-item-id="0" @delete-row="cancelInput">
    </Task-Item-Add-Modal-Card>
    <Task-Item-Add-Modal-Card :readonly="true" 
    v-for="(taskItem) in taskItemList" 
    :key="taskItem.id" 
    :taskDescription="taskItem.description"
    :category="taskItem.category" 
    :id="taskItem.id"
    :order="taskItem.orderNumber" 
    :eloCode="eloCode"
    :focus="taskItem.focus">
    </Task-Item-Add-Modal-Card>
</div>
</template>

<script>
import TaskItemAddModalCard from "./TaskItemAddModalCard";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import { mapGetters,mapActions } from 'vuex'
export default {
    name: "CurriculumInterfaceDomainEditModal",
    /* eslint-disable vue/no-unused-components */
    components: {
        TaskItemAddModalCard,
        FontAwesomeIcon,
    },
    /* eslint-enable vue/no-unused-components */
    props: {
        eloCode: String
    },
    data() {
        return {
            addItem: false,
            newItemValue: "",
        };
    },
    created() {
        this.getTaskItemList({
            eloCode: this.eloCode
        })
    },
    computed: {
        ...mapGetters(["taskItemList"])
    },
    methods: {
        ...mapActions(["getTaskItemList"]),
        toggleSort: function () {
            this.reverseSort = !this.reverseSort;
        },
        toAddItem: function () {
            this.addItem = true;
        },
        cancelInput: function () {
            this.addItem = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.cell-item {
    padding-top: $global-padding / 4;
    padding-bottom: $global-padding / 4;
}

.control-section {
    margin: $global-padding 0 $global-padding / 2;
}

.task-analysis {
    li {
        list-style: none;
        border-bottom: 1px solid $dark-gray;
    }
}

.update-status {
    margin-bottom: 0;
    font-size: $small-font-size;
    line-height: 1.4em;
}

.border-left {
    border-left: 1px solid $dark-gray;
}
</style>
