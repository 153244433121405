import Vue from 'vue'

export default {
	getStudentEloRemarks (studentId, eloCode, planYear, external) {
		return Vue.http.get('studentEloRemarks/' + studentId, {
			params: {
				eloCode: eloCode,
				planYear: planYear,
				external: external
			}
		})
	},
	postStudentEloRemark (studentId, eloCode, planYear, remark, external) {
		return Vue.http.post('studentEloRemarks', {
			studentId: studentId,
			eloCode: eloCode,
			planYear: planYear,
			remark: remark,
			external: external
		})
	},
	updateStudentEloRemark (remarkId, studentId, eloCode, planYear, remark, external) {
		return Vue.http.put('studentEloRemarks/' + remarkId, {
			studentId: studentId,
			eloCode: eloCode,
			planYear: planYear,
			remark: remark,
			external: external
		})
	},
	deleteStudentEloRemark (remarkId) {
		return Vue.http.delete('studentEloRemarks/' + remarkId)
	},
	getStudentEloRemarkEvidence (remarkId, fileUUID) {
		return Vue.http.get('studentEloRemarksEvidence/' + fileUUID, {
			params: {
				remarkId: remarkId
			}
		})
	},
	postStudentEloRemarkEvidence (details) {
		return Vue.http.post('studentEloRemarksEvidence', details, {
			setContentType: false,
			processData: false,
			headers: { 'Content-Type': undefined }
		})
	}
}
