import Vue from 'vue'

export default {
	getStudentTaskItemList (studentId, eloCode, planYear, planTerm) {
		return Vue.http.get('studentTaskItemList/' + studentId, {
			params: {
				eloCode: eloCode,
				planYear: planYear,
				planTerm: planTerm
			}
		})
	},
	addItemToTaskItemList (studentId, eloCode, planYear, planTerm, taskDescription, subItemDescription) {
		return Vue.http.post('studentTaskItemList', {
			studentId: studentId,
			eloCode: eloCode,
			planYear: planYear,
			planTerm: planTerm,
			taskDescription: taskDescription,
			subItemDescription
		})
	},
	cloneTaskItemListFromTerm (studentId, eloCode, planYear, planTerm, targetTerm, sourceStudentId, multi) {
		return Vue.http.post('studentTaskItemList', {
			studentId: studentId,
			eloCode: eloCode,
			planYear: planYear,
			planTerm: planTerm,
			targetTerm: targetTerm,
			sourceStudentId,
			clone: true,
			multi
		})
	},
	removeItemFromTaskItemList (studentId, eloCode, planYear, planTerm, studentTaskItemId) {
		return Vue.http.delete('studentTaskItemList/' + studentId, {
			body: {
				eloCode: eloCode,
				planYear: planYear,
				planTerm: planTerm,
				studentTaskItemId: studentTaskItemId
			}
		})
	},
	updateTaskItemList (studentId, eloCode, planYear, planTerm, updateParams) {
		return Vue.http.put('studentTaskItemList/' + studentId, {
			eloCode: eloCode,
			planYear: planYear,
			planTerm: planTerm,
			...updateParams
		})
	}
}
