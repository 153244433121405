<template>
  <ul class="menu vertical" :class="level > 0 ? 'nested' : ''" v-cloak>
    <li
      v-if="!filteredList || filteredList.length === 0"
      class="menu-item empty-item"
    >
      {{ $t("sidebar.menu.nested.empty") }}
    </li>
    <li v-for="nested in filteredList" :key="nested.identifier">
      <div
        class="grid-x"
        :class="[
          isSelectedItemClass(nested.identifier) ? 'selected' : '',
          'level-' + level,
        ]"
      >
        <a
          class="menu-item cell auto truncate"
          @click="updateDashboard(nested)"
          :title="nested.name || nested.displayName"
          >{{ nested.name || nested.displayName }}</a
        >

        <div
          v-if="
            nested[hierarchy[level + 1]] &&
            nested[hierarchy[level + 1]].length > 0
          "
          class="grid-x align-middle"
        >
          <button
            v-if="!dropdownMode"
            class="button clear caret padding-left-1 padding-right-1"
            @click="toggleOpen(nested)"
          >
            <font-awesome-icon
              class="type-icon"
              :icon="nested.isOpen ? 'chevron-up' : 'chevron-down'"
            ></font-awesome-icon>
          </button>
          <div v-else class="caret padding-left-1 padding-right-1">
            <font-awesome-icon icon="chevron-right"></font-awesome-icon>
          </div>
        </div>
      </div>

      <!-- Create accordion recursively -->
      <the-sidebar-menu-nested
        v-if="
          nested[hierarchy[level + 1]] &&
          nested[hierarchy[level + 1]].length > 0
        "
        :list="nested[hierarchy[level + 1]]"
        :hierarchy="hierarchy"
        :level="level + 1"
        :dropdown-mode="dropdownMode"
        v-show="dropdownMode || nested.isOpen"
      ></the-sidebar-menu-nested>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "TheSidebarMenuNested",
  components: {
    FontAwesomeIcon,
  },
  props: {
    list: {
      type: Array,
    },
    hierarchy: {
      type: Array,
    },
    level: {
      type: Number,
    },
    isOpen: {
      type: Boolean,
    },
    dropdownMode: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      isChildClassSelected: "isChildClassSelected",
      isSelectedItemClass: "isSelectedItemClass",
      selectedYearFilter: "selectedYearFilter",
      classGroup: "classGroup",
    }),
    filteredList() {
      this.list &&
        this.list.forEach((it) => {
          it.isOpen = this.isChildClassSelected(it.identifier);
        });
      if (
        this.list &&
        this.hierarchy &&
        this.hierarchy[this.level] === "classes"
      ) {
        return this.list.filter((it) => it.year === this.selectedYearFilter);
      }
      return this.list;
    },
  },
  methods: {
    ...mapActions([
			"getClassGroup",
		]),
    toggleOpen(nested) {
      nested.isOpen = !nested.isOpen;
    },
    async updateDashboard(item) {
      let name = null;
      let routeId = null;
      let params = {};
      let selectedClassGroupId = null;
      let selectedClassGroup = {};
      switch (this.hierarchy[this.level]) {
        // case 'schools':
        // 	name = 'School'
        // 	break
        case "programmes":
          name = "Programme";
          routeId = this.$route.params.programmeId;
          params = {
            programmeId: item.id,
          };
          break;
        case "classes":
          name = this.getRedirectName("Dashboard", "ClassGroup");
          routeId = this.$route.params.classId;
          params = {
            classId: item.id,
          };
          break;
        case "students":
          selectedClassGroupId = this.retrieveClassIdFromIdentifier(item.identifier)
          if (this.$route.params.classId && selectedClassGroupId !== this.$route.params.classId) {
            await this.getClassGroup(selectedClassGroupId, () => {
              selectedClassGroup = this.classGroup;
            });
          } else {
            selectedClassGroup = this.classGroup;
          }
          if (this.classGroup.coachingEngagement) {
            let studentCoachingEngagement = this.classGroup.coachingEngagement.studentCoachingEngagements.find(a => a.studentId == item.id) 
            name = 'CoachingEngagement';
            // routeId = this.$route.params.studentCoachingEngagementId;
            params = {
              year: this.$store.getters.selectedYearFilter,
              studentId: item.id,
              classId: this.classGroup.id, //this.retrieveClassIdFromIdentifier(item.identifier),
              studentCoachingEngagementId: studentCoachingEngagement.id,
            };
            break;
          } else {
            name = this.getRedirectName("Aiep", "Aiep");
            routeId = this.$route.params.studentId;
            params = {
              classId: this.retrieveClassIdFromIdentifier(item.identifier),
              studentId: item.id,
            };
            break;
          }
      }

      this.setSelected(item.identifier);
      if (name && (routeId !== item.id || this.$route.name !== name)) {
        this.$router.push({
          name: name,
          params: {
            ...params,
            year: this.$store.getters.selectedYearFilter,
            term: this.$route.params.term,
          },
        });
      }
    },
    getRedirectName(matchedRoute, fallbackRoute) {
      if (this.$route.matched.some((it) => it.name === matchedRoute)) {
        return this.$route.meta.mainRoute || this.$route.name;
      } else {
        return fallbackRoute;
      }
    },
    setSelected(identifier) {
      this.$store.dispatch("setSelectedItemClass", identifier);
    },
    retrieveClassIdFromIdentifier(identifier) {
      // e.g. identifier = 'schools_1-programmes_3-classes_Y19-P3-C01-students_MDS-STU-0265'
      let arrSplitted = identifier.split("_");

      const regex = /^\d*-*classes/;
      const indexOfClasses = arrSplitted.findIndex(
        (str) => str.search(regex) > -1
      );

      let classId = arrSplitted[indexOfClasses + 1]; // 'Y19-P3-C01-students'
      arrSplitted = classId.split("-");
      arrSplitted = arrSplitted.slice(0, 3);

      classId = arrSplitted.join("-");
      return classId;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  color: inherit;
  line-height: 1.2em;
}
.level-0 a,
.level-1 a,
.level-2 a,
.level-3 a {
  color: inherit;
  line-height: 1.2em;

  &:hover {
    color: inherit;
  }
}
.caret {
  color: inherit;
  opacity: 0.25;
  font-size: $small-font-size;
}
.selected {
  border-radius: $global-radius;
  background-color: $bg-selected;
  transition: all 0.4s ease;
  color: inherit;

  .menu-item {
    @extend .sans-serif-bold;
    display: block;
    border: none;
    line-height: 1.2em;
    height: auto;
    opacity: 1;
  }
}
.menu-item {
  padding-top: $global-padding / 1.7;
  padding-bottom: $global-padding / 1.7;
  text-align: left;
  color: inherit;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
.empty-item {
  padding: $global-padding * 0.7 $global-padding;
}
</style>

