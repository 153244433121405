<template>
  <div v-cloak>
    <div
      class="grid-x grid-margin-x top-info-wrapper"
      v-if="filteredClassGroup && filteredClassGroup.id"
    >
      <div class="cell shrink margin-bottom-1">
        <div class="grid-x">
          <h1 class="page-title margin-right-1">{{ pageTitle }}</h1>
        </div>

        <div class="grid-x cell shrink margin-bottom-0">
          <h6 class="sans-serif-regular color--darker-gray cell shrink">
            {{ filteredClassGroup.name }}
            <span class="cell shrink">
              <small class="banner-date"
                >{{ filteredClassGroup.session }},&nbsp;&nbsp;{{
                  filteredClassGroup.year
                }}</small
              >
            </span>
          </h6>
          <class-group-edit-profile-control-section
            class="cell shrink margin-left-1"
            :class-group="classGroup"
            :selected-class-group-full-details="selectedClassGroupFullDetails"
          />
        </div>
      </div>

      <div class="cell small-12 medium-auto grid-x align-right">
        <div
          class="image-wrapper"
          v-for="teacher in filteredClassGroup.teachers"
          :key="teacher.id"
        >
          <profile-image
            :image-title="teacher.fullName"
            :image="teacher.image"
            :image-alt="teacher.displayName"
            :image-size="35"
          ></profile-image>
          <div class="text-center truncate">
            <small>{{ teacher.displayName }}</small>
          </div>
        </div>
      </div>
    </div>

    <p v-else>{{ $t("classGroup.noClassFound") }}</p>
  </div>
</template>

<script>
import ProfileImage from "@/components/ProfileImage";
import ClassGroupEditProfileControlSection from "./ClassGroupEditProfileControlSection";

export default {
  name: "ClassGroupEditProfile",
  components: {
    ProfileImage,
    ClassGroupEditProfileControlSection,
  },
  props: {
    classGroup: {
      type: Object,
      required: true,
    },
    selectedClassGroupFullDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pageTitle() {
      return this.classGroup.coachingEngagement ? this.$t("classGroupEdit.classConfiguration.coaching") : this.$t("classGroupEdit.classConfiguration");
    },
    filteredClassGroup: function () {
      let classGroup = { ...this.classGroup };
      let profile = this.$store.getters.profile;
      if (profile.details) {
        classGroup.teachers =
          classGroup.teachers &&
          classGroup.teachers.filter((it) => it.id !== profile.details.id);
      }
      return classGroup;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-info-wrapper {
  margin-bottom: $global-margin;

  @media print {
    margin-bottom: 0;
  }
}
.image-wrapper {
  margin-left: 0;
  margin-right: 0;
}
</style>
