<template>
  <div
    id="suggestion-dropdown"
    class="suggestion-dropdown dropdown-pane"
    data-position="bottom"
    data-alignment="right"
    data-allow-overlap="true"
    data-auto-focus="true"
    data-close-on-click="false"
    v-foundation:Dropdown.destroy
    v-cloak
  >
    <div>
      <h6 class="margin-bottom-0">
        {{ $t("editEloDetails.general.assessmentCriteria.suggestion.header") }}
      </h6>
    </div>
    <div
      v-for="(textValue, suggestionType) in suggestion"
      :key="suggestionType"
      class="suggestion-box grid-x"
    >
      <div class="suggestion-box-label cell shrink">
        {{ suggestionType | capitalizeFirstLetter }}
      </div>
      <p class="suggestion-box-text cell auto">{{ textValue }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AssessmentCriteriaSuggestion",
  filters: {
    capitalizeFirstLetter: function (text) {
      if (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
  },
  props: {
    textId: String,
    textName: String,
  },
  computed: {
    ...mapGetters({
      suggestion: "suggestion",
    }),
  },
  created() {
    this.$store.dispatch("getAssessmentCriteriaSuggestionForEloCode", {
      eloCode: this.textId,
    });
  },
};
</script>

<style lang="scss" scoped>
.suggestion-dropdown {
  width: 90%;
  max-width: 25em;
  background-color: $white;
}
.suggestion-box {
  .suggestion-box-label {
    padding: 0 0 0 $global-padding / 2;
    border-bottom: 0;
    font-size: 0.7rem;
    color: $medium-gray;
    transform: rotate(180deg);
    writing-mode: tb-rl;
  }
  .suggestion-box-text {
    border: 1px dashed $medium-gray;
    border-radius: $global-radius;
    padding: $global-padding * 0.5 $global-padding * 0.8;
    margin: $global-margin * 0.1;
    font-size: 0.8rem;
  }
}
</style>
