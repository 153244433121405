import _ from 'lodash';
import * as types from '../mutation-types';
import constants from '@/constants';
import studentAnnualPlanApi from '@/api/studentAnnualPlan';
import studentProgrammePlanApi from '@/api/studentProgrammePlan';
import studentProgrammeEloStatusApi from '@/api/studentProgrammeEloStatus';

// initial state
const state = {
	studentPlan: {},
	studentAnnualPlan: {},
	elosToAdd: []
};

// getters
const getters = {
	studentPlan(state) {
		return state.studentPlan;
	},
	elosToAdd(state) {
		return state.elosToAdd;
	},
	studentAnnualPlan(state) {
		return state.studentAnnualPlan;
	}
};

// actions
const actions = {
	createStudentPlan({ commit }, { studentId, selectedYear, planStartYear, planEndYear }) {
		return studentProgrammePlanApi.createStudentPlan(studentId, selectedYear, planStartYear, planEndYear).then(() => {
			return studentProgrammePlanApi.getStudentPlan(studentId, selectedYear).then(resp => {
				if (resp.data) {
					commit(types.SET_STUDENT_PLAN, { data: resp.data, studentId: studentId });
					return true;
				}
			});
		});
	},
	getStudentPlan({ commit }, { studentId, planYear }) {
		return studentProgrammePlanApi.getStudentPlan(studentId, planYear).then(resp => {
			commit(types.SET_STUDENT_PLAN, { data: resp.data, studentId: studentId });
			if (!_.isEmpty(resp.data)) {
				return true;
			}
		});
	},
	async setStudentPlanEndYear({ commit }, { studentId, startYear, endYear }) {
		let { status, data } = await studentProgrammePlanApi.setProgrammePlanEndYear(studentId, startYear, endYear);

		if (status === constants.RESPONSE_STATUS.OK && data) {
			commit(types.SET_STUDENT_PLAN, { data, studentId });
			return true;
		}

		return false;
	},
	addEloToProgrammePlan({ commit, getters }, { studentId, selectedYear }) {
		let newlyAddedElos = getters.elosToAdd;
		return studentProgrammePlanApi.addEloToProgrammePlan(studentId, selectedYear, getters.elosToAdd).then((resp) => {
			if (resp.data) {
				let studentProgrammeElos = _.map(resp.data.studentProgrammeElos, (studentProgrammeElo) => {
					if (_.indexOf(newlyAddedElos, studentProgrammeElo.eloCode) >= 0) {
						studentProgrammeElo.elo.isNew = true;
					}
					return studentProgrammeElo;
				});
				commit(types.ADD_NEW_PROGRAMME_ELO, { studentId: studentId, selectedYear: selectedYear, studentProgrammeElos: studentProgrammeElos });
			}
		});
	},
	deleteEloFromProgrammePlan({ commit }, { studentId, selectedYear, eloCode }) {
		return studentProgrammePlanApi.deleteEloFromProgrammePlan(studentId, selectedYear, eloCode).then((resp) => {
			if (resp.data) {
				commit(types.REMOVE_PROGRAMME_ELO, { studentId: studentId, selectedYear: selectedYear, studentProgrammeElos: resp.data.studentProgrammeElos });
			}
		});
	},
	setStudentProgrammeEloStatusActive({ commit }, { studentId, selectedYear, eloCode }) {
		return studentProgrammeEloStatusApi.setStudentProgrammeEloStatusActive(studentId, selectedYear, eloCode).then((resp) => {
			if (resp.data) {
				commit(types.SET_PROGRAMME_ELO_STATUS, { studentId: studentId, selectedYear: selectedYear, studentProgrammeElos: resp.data.studentProgrammeElos });
			}
		});
	},
	addToEloCode({ commit, getters }, code) {
		if (getters.elosToAdd.indexOf(code) === -1) {
			commit(types.ADD_TO_ELO_CODE, code);
		}
	},
	removeFromEloCode({ commit, getters }, code) {
		let index = getters.elosToAdd.findIndex(it => it === code);
		if (index === parseInt(index, 10)) {
			commit(types.REMOVE_FROM_ELO_CODE, index);
		}
	},
	clearEloCodes({ commit }) {
		commit(types.CLEAR_ELO_CODE);
	},
	getStudentAnnualPlan({ commit }, { studentId, planYear }) {
		return studentAnnualPlanApi.getStudentAnnualPlan(studentId, planYear).then(resp => {
			if (resp.body) {
				let studentAnnualPlans = getters.studentAnnualPlan[studentId] || {};
				let yearPlan = studentAnnualPlans[planYear] || {};
				yearPlan.studentElos = resp.body.studentElos || [];
				return commit(types.SET_STUDENT_ANNUAL_PLAN, { studentId: studentId, planYear: planYear, yearPlan: yearPlan });
			}
		});
	},
	addEloToAnnualPlan({ commit, getters }, { studentId, planYear }) {
		let newlyAddedElos = getters.elosToAdd;
		return studentAnnualPlanApi.addEloToAnnualPlan(studentId, getters.elosToAdd, planYear).then((resp) => {
			if (resp.data) {
				let annualElos = _.map(resp.data.studentElos, (elo) => {
					if (_.indexOf(newlyAddedElos, elo.eloCode) >= 0) {
						elo.isNew = true;
					}
					return elo;
				});
				let studentAnnualPlans = getters.studentAnnualPlan[studentId] || {};
				let yearPlan = studentAnnualPlans[planYear] || {};
				yearPlan.studentElos = annualElos;
				commit(types.ADD_NEW_ANNUAL_ELO, { studentId: studentId, planYear: planYear, yearPlan: yearPlan });
				commit(types.ADD_NEW_ANNUAL_ELO_CODES, { studentId, eloCodes: newlyAddedElos });
			}
		});
	},
	deleteEloFromAnnualPlan({ commit }, { studentId, planYear, eloCode }) {
		return studentAnnualPlanApi.deleteEloFromAnnualPlan(studentId, planYear, eloCode).then((resp) => {
			if (resp.data) {
				let studentAnnualPlans = getters.studentAnnualPlan[studentId] || {};
				let yearPlan = studentAnnualPlans[planYear] || {};
				yearPlan.studentElos = resp.data.studentElos;
				commit(types.REMOVE_ANNUAL_ELO, { studentId: studentId, planYear: planYear, yearPlan: yearPlan });
			}
		});
	},
	existsInAnnualPlan({ getters }, { studentId, planYear, eloCode }) {
		let elos = getters.studentAnnualPlan[studentId][planYear].studentElos;
		let eloFound = _.find(elos, (eloObj) => {
			return eloObj.eloCode === eloCode;
		});
		return eloFound;
	}
};

// mutations
const mutations = {
	[types.SET_STUDENT_PLAN](state, { studentId, data }) {
		state.studentPlan = { ...state.studentPlan, [studentId]: data };
	},
	[types.ADD_TO_ELO_CODE](state, code) {
		state.elosToAdd.push(code);
	},
	[types.REMOVE_FROM_ELO_CODE](state, index) {
		state.elosToAdd.splice(index, 1);
	},
	[types.CLEAR_ELO_CODE](state) {
		state.elosToAdd = [];
	},
	[types.ADD_NEW_PROGRAMME_ELO](state, { studentId, studentProgrammeElos }) {
		state.studentPlan[studentId] = { ...state.studentPlan[studentId], studentProgrammeElos: studentProgrammeElos };
	},
	[types.REMOVE_PROGRAMME_ELO](state, { studentId, studentProgrammeElos }) {
		state.studentPlan[studentId] = {
			...state.studentPlan[studentId],
			studentProgrammeElos: studentProgrammeElos
		};
	},
	[types.SET_PROGRAMME_ELO_STATUS](state, { studentId, studentProgrammeElos }) {
		state.studentPlan[studentId] = { ...state.studentPlan[studentId], studentProgrammeElos: studentProgrammeElos };
	},
	[types.SET_STUDENT_ANNUAL_PLAN](state, { studentId, planYear, yearPlan }) {
		state.studentAnnualPlan = { ...state.studentAnnualPlan, [studentId]: { [planYear]: yearPlan } };
	},
	[types.ADD_NEW_ANNUAL_ELO](state, { studentId, planYear, yearPlan }) {
		state.studentAnnualPlan = { ...state.studentAnnualPlan, [studentId]: { [planYear]: yearPlan } };
	},
	[types.REMOVE_ANNUAL_ELO](state, { studentId, planYear, yearPlan }) {
		state.studentAnnualPlan = { ...state.studentAnnualPlan, [studentId]: { [planYear]: yearPlan } };
	},
	[types.ADD_NEW_ANNUAL_ELO_CODES](state, { studentId, eloCodes }) {
		state.studentPlan[studentId].annualPlansEloCodes = _.union(state.studentPlan[studentId].annualPlansEloCodes, eloCodes);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
