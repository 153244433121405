const generateGetAndSet = {
	default: stateName => ({
		get() {
			return this[stateName];
		},
		set(val) {
			return this.$emit(`update:${stateName}`, val);
		}
	})
};

export default generateGetAndSet;
