import Vue from 'vue'

export default {
	getStudentPlan (studentId, planYear) {
		return Vue.http.get('studentProgrammePlan/' + studentId, { params: { planYear } })
	},
	createStudentPlan (studentId, selectedYear, planStartYear, planEndYear) {
		return Vue.http.post('studentProgrammePlan', {
			studentId: studentId,
			selectedYear: selectedYear,
			planStartYear: planStartYear,
			planEndYear: planEndYear
		})
	},
	setProgrammePlanEndYear (studentId, startYear, endYear) {
		return Vue.http.put('studentProgrammePlan/' + studentId, {
			startYear,
			endYear
		})
	},
	addEloToProgrammePlan (studentId, selectedYear, eloCodes) {
		return Vue.http.put('studentProgrammePlanElo/' + studentId, {
			selectedYear: selectedYear,
			eloCodes: eloCodes
		})
	},
	deleteEloFromProgrammePlan (studentId, selectedYear, eloCode) {
		return Vue.http.delete('studentProgrammePlanElo/' + studentId, {
			body: {
				selectedYear: selectedYear,
				eloCode: eloCode
			}
		})
	}
}
