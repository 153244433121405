<!-- CoachingEngagementOutcomesList.vue -->
<template>
	<div class="outcomes-wrapper">
	  <div class="outcomes-header">
		<p>Outcomes for goal <span class="goal-number">{{ activeGoal.index }}</span></p>
		<button class="add-button" @click="addingOutcome=true">
			<font-awesome-icon icon="plus" size="1x"></font-awesome-icon>
			Add Outcome
		</button>	
	  </div>
	  <div v-if="addingOutcome" class="add-outcome-container">
		<input 
			v-model="newOutcomeName" 
			@keyup.enter="confirmAddOutcome"
			@keyup.esc="cancelAddOutcome" 
			placeholder="Type outcome name and press Enter"
		>
		<button class="button primary margin-top-1" @click="confirmAddOutcome">Save</button>
		<button class="button primary margin-top-1" @click="cancelAddOutcome">Cancel</button>
	  </div>
	  <div
		v-for="(coachingOutcome, index) in activeGoal.goal.coachingOutcomes"
		:key="index"
		class="outcome-item"
	  >
		<coaching-engagement-outcome-card
	  	  :outcomeId="coachingOutcome.id"
		  :outcomeName="coachingOutcome.outcomeName"
      	  :latestOutcomeAssessment="latestAssessment(coachingOutcome.coachingOutcomeAssessments)"
		></coaching-engagement-outcome-card>
	  </div>
	</div>
  </template>

<script>
import CoachingEngagementOutcomeCard from "@/components/CoachingEngagementOutcomeCard";
import { mapGetters, mapActions } from 'vuex';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
	name: "CoachingEngagementOutcomesList",
	components: {
	  FontAwesomeIcon,
	  CoachingEngagementOutcomeCard
	},
  computed: {
    ...mapGetters(['activeGoal'])
  },
  data() {
	return {
		addingOutcome: false,
		newOutcomeName: '',
	}
  },
  methods: {
	...mapActions([
		"createCoachingOutcome"
	]),
	latestAssessment(assessmentsList) {
		console.log("latestAssessment: ")
		if(assessmentsList.length === 0){
			return 0;
		}
		// sort assessmentsList by assessmentTime making the most recent first
		const sortedAssessments = [...assessmentsList].sort((a, b) => { 
			return new Date(b.assessmentTime) - new Date(a.assessmentTime);
		});  
		// otherwise return the first item of sorted assessmentsList
		return sortedAssessments[0].result;
	},
	async confirmAddOutcome() {
		if (this.newOutcomeName.trim() !== '') {
			// check the vuex store call to add outcome
			await this.createCoachingOutcome({ 
				coachingGoalId: this.activeGoal.goal.id, 
				outcomeName: this.newOutcomeName 
			});
			this.newOutcomeName = '';
		}
		this.addingOutcome = false;
	},
	cancelAddOutcome() {
		this.addingOutcome = false;
		this.newOutcomeName = '';
	},
  }
}
</script>

<style lang="scss" scoped>
.outcomes-wrapper {
  position: relative;
  border: 2px solid $light-gray;
  border-radius: 10px;
}

.outcomes-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $title-color;
  color: $white;
  padding: 0.5em 1em;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 1.25em;
  font-weight: bold;

  p {
    margin: 0; // Add this line
    padding: 0.5em; // Add this line
  }
  .add-button {
      color: $white;
      font-size: 1rem;

      &:hover {
          background-color: $bg-selected;
      }
    }
}

.add-outcome-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white; // or any other color you want

    input {
      flex-grow: 1;
      //margin-right: 1em; // add some space between input and buttons
    }
    
    .button {
      width: auto; // reset the button width to auto for better spacing
      margin-left: 1em;
    }
  }

.outcome-item {
  padding: 0.5em 1em;
  border-bottom: 1px solid $darker-gray;

  &:last-child {
    border-bottom: none;
  }
}

.goal-number {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: $brand-quaternary;
	color: white;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	font-weight: bold;
  text-align: center;
  line-height: 30px; // this will vertically center the text
	vertical-align: middle; // this will align the circle with the text
	margin-left: 10px; // this will create space between the text and the circle
  // font-size: 14px;
}

</style>