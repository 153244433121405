var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isEloSideTray())?_c('div',{staticClass:"card-section edit",class:[_vm.isAdded ? 'added' : ''],on:{"click":function($event){return _vm.addToEloCodes(_vm.textId)}}},[(_vm.isAdded)?_c('FontAwesomeIcon',{staticClass:"check-icon color--success",attrs:{"icon":"check"}}):_vm._e(),_c('elo-list-card-details',{attrs:{"elo-code":_vm.textId,"elo-name":_vm.textName,"programme-codes":_vm.programmeCodes}},[_c('p',[_c('elo-list-card-strands',{attrs:{"strands":_vm.strands}})],1)])],1):((_vm.isProgrammePlan() || _vm.isAnnualPlan()) && _vm.hasEloDetails())?_c('div',{staticClass:"grid-x parent"},[_c('elo-list-card-details',{staticClass:"card-section edit include-hover cell auto",class:[
      (_vm.eloDetails && _vm.eloDetails.isNew) ||
      (_vm.studentEloDetails && _vm.studentEloDetails.isNew)
        ? 'is-new-elo'
        : '',
      _vm.eloStatus !== 'ACTIVE' ? 'terminated' : '',
    ],attrs:{"elo-code":_vm.eloDetails.eloCode,"elo-name":_vm.eloDetails.eloName,"programme-codes":_vm.eloDetails.programmeCodes},nativeOn:{"click":function($event){return _vm.showEditModal($event)}}},[(_vm.isAnnualPlan())?_c('p',[_c('span',{staticClass:"year-subtext"},[_vm._v(_vm._s(_vm.studentEloDetails.planYear))]),_c('span',{staticClass:"icon-bar"},[_vm._v("  "),(_vm.studentEloDetails.hasTaskItems)?_c('font-awesome-icon',{staticClass:"icon-image color--black",attrs:{"icon":"list-ul","size":"1x"}}):_vm._e(),_vm._v("  "),(_vm.studentEloDetails.hasRemarks)?_c('font-awesome-icon',{staticClass:"icon-image comment color--black",attrs:{"icon":['far', 'comment-dots'],"size":"1x"}}):_vm._e()],1)]):(_vm.isProgrammePlan())?_c('p',{staticClass:"grid-x align-middle"},[_c('elo-list-card-strands',{attrs:{"strands":_vm.strands}}),(_vm.latestAttainmentDetails)?_c('span',{staticClass:"margin-left-1 year-subtext"},[_vm._v(_vm._s(_vm.latestAttainmentDetails.years.join(", ")))]):_vm._e(),(
          _vm.latestAttainmentDetails && _vm.latestAttainmentDetails.attainmentLevel
        )?_c('attainment-badge',{staticClass:"margin-left-1 margin-right-1 attainment-badge",attrs:{"level":_vm.latestAttainmentDetails.attainmentLevel}}):_vm._e(),(_vm.eloStatus !== 'ACTIVE')?_c('span',{staticClass:"year-subtext",attrs:{"data-val":_vm.eloStatus.toLowerCase()}},[_vm._v(_vm._s(_vm.eloStatus.toLowerCase()))]):_vm._e()],1):_vm._e()]),_c('more-dropdown',{class:[_vm.isAnnualPlan() ? 'aiep-more' : ''],attrs:{"options":_vm.dropdownOptions,"identifier":_vm.$vnode.key}}),(_vm.isAnnualPlan())?_c('div',{staticClass:"aiep-plan cell small-12 medium-6"},[_c('div',{staticClass:"aiep-plan-column grid-x text-center"},[_c('p',{staticClass:"aiep-label grid-x align-bottom"},[_vm._v(" "+_vm._s(_vm.$t("eloListCard.first.assessment"))+" ")]),_vm._l((10),function(header,i){return _c('div',{key:i,staticClass:"cell auto",class:i > 1 &&
          (i < _vm.studentEloDetails.startTerm * 2 ||
            i > _vm.studentEloDetails.endTerm * 2 + 1)
            ? 'blank'
            : ''},[_c('span',{staticClass:"aiep-attainment grid-x align-center align-middle"},[(_vm.isOdd(i))?_c('p',{staticClass:"prompt margin-0"},[_c('b',[_vm._v(_vm._s(_vm.promptHierarchyForTerm(i / 2, 1)))])]):(_vm.attainmentLevelForTerm((i - 1) / 2, 1))?_c('attainment-badge',{staticClass:"attainment-badge",attrs:{"level":_vm.attainmentLevelForTerm((i - 1) / 2, 1)}}):_vm._e()],1)])})],2),_c('div',{staticClass:"aiep-plan-column grid-x text-center"},[_c('p',{staticClass:"aiep-label grid-x align-bottom"},[_vm._v(" "+_vm._s(_vm.$t("eloListCard.second.assessment"))+" ")]),_vm._l((10),function(header,i){return _c('div',{key:i,staticClass:"cell auto",class:i > 1 &&
          (i < _vm.studentEloDetails.startTerm * 2 ||
            i > _vm.studentEloDetails.endTerm * 2 + 1)
            ? 'blank'
            : ''},[_c('span',{staticClass:"aiep-attainment grid-x align-center align-middle"},[(_vm.isOdd(i))?_c('p',{staticClass:"prompt margin-0"},[_c('b',[_vm._v(_vm._s(_vm.promptHierarchyForTerm(i / 2, 2)))])]):(_vm.attainmentLevelForTerm((i - 1) / 2, 2))?_c('attainment-badge',{staticClass:"attainment-badge",attrs:{"level":_vm.attainmentLevelForTerm((i - 1) / 2, 2)}}):_vm._e()],1)])})],2)]):_vm._e()],1):(_vm.hasEloDetails())?_c('elo-list-card-details',{attrs:{"elo-code":_vm.eloDetails.eloCode,"elo-name":_vm.eloDetails.eloName,"programme-codes":_vm.eloDetails.programmeCodes}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }