<template>
  <div class="user-modal" v-cloak>
    <h4 class="modal-title">
      {{
        isNew ? $t("userModal.header.create") : $t("userModal.header.update")
      }}
    </h4>

    <div class="class-group-modal-content grid-x content-wrapper">
      <div class="small-12 cell">
        <h6>{{ $t("userModal.content.username") }}</h6>
        <input
          type="text"
          id="username"
          name="username"
          :placeholder="$t('userModal.content.username.placeholder')"
          v-model="formData.username"
        />
      </div>
      <div class="small-12 cell">
        <h6>{{ $t("userModal.content.role") }}</h6>
        <select v-model="formData.role" :disabled="this.isRoleDisable">
          <option :value="null">
            {{ $t("selectInput.placeholder.select") }}
          </option>
          <option
            v-for="option in permittedRoles"
            :value="option"
            :key="option"
          >
            {{ $t(option) }}
          </option>
        </select>
      </div>

      <div v-if="isPrinciple" class="small-12 cell cell-padding-top">
        <h6>{{ $t("usermodal.content.school") }}</h6>
        <MultiSelectComponent
          :value.sync="formData.schoolId"
          :options="schoolList"
          :minimumSelected="1"
        ></MultiSelectComponent>
      </div>

      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("userModal.content.salutation") }}</h6>
        <select v-model="formData.salutation">
          <option v-for="option in salutations" :value="option" :key="option">
            {{ option }}
          </option>
        </select>
      </div>
      <div class="small-12 cell cell-padding-top">
        <h6>{{ $t("userModal.content.fullName") }}</h6>
        <input
          type="text"
          id="fullName"
          name="fullName"
          :placeholder="$t('userModal.content.fullName.placeholder')"
          v-model="formData.fullName"
        />
      </div>
      <div class="small-12 cell">
        <h6>{{ $t("userModal.content.displayName") }}</h6>
        <input
          type="text"
          id="displayName"
          name="displayName"
          :placeholder="$t('userModal.content.displayName.placeholder')"
          v-model="formData.displayName"
        />
      </div>
      <div class="small-12 cell">
        <h6>{{ $t("userModal.content.designation") }}</h6>
        <input
          type="text"
          id="designation"
          name="designation"
          :placeholder="$t('userModal.content.designation.placeholder')"
          v-model="formData.designation"
        />
      </div>
      <div class="small-12 cell">
        <h6>{{ $t("userModal.content.accountLocked") }}</h6>
        <input
          type="checkbox"
          id="accountLocked"
          name="accountLocked"
          @click="toggleAccountLocked"
          :checked="!formData.accountLocked"
        />
        <label for="accountLocked">{{
          $t("userModal.content.accountLocked.label")
        }}</label>
      </div>

      <div class="action-selector cell grid-x align-right">
        <div class="cell shrink">&nbsp;</div>
        <button
          type="submit"
          class="button primary margin-bottom-0"
          :disabled="isDisabled"
          @click="submit"
        >
          {{
            isNew ? $t("userModal.action.create") : $t("userModal.action.save")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import MultiSelectComponent from "@/components/MultiSelect";
import constants from "@/constants";

export default {
  name: "UserModal",
  components: { MultiSelectComponent },
  props: {
    username: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: null,
    },
    salutation: {
      type: String,
      default: constants.SALUTATION.MR,
    },
    schoolId: {
      type: Array,
      default: () => [],
    },
    fullName: {
      type: String,
      default: "",
    },
    displayName: {
      type: String,
      default: "",
    },
    designation: {
      type: String,
      default: "",
    },
    accountLocked: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: undefined,
    },
    teacherId: {
      type: String,
      default: undefined,
    },
    isRoleDisable: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      permittedRoles: (state) => state.user.permittedRoles,
    }),
    ...mapGetters(["schools"]),
    schoolList() {
      return Object.values(this.schools).map(({ name, id }) => ({
        name,
        id,
      }));
    },
    salutations() {
      return Object.values(constants.SALUTATION);
    },
    isNew() {
      return this.userId === undefined;
    },
    isPrinciple() {
      return this.formData.role === "ROLE_PRINCIPAL";
    },
    isDisabled() {
      return this.isPrinciple && this.formData.schoolId.length === 0;
    },
  },
  data() {
    return {
      formData: {
        username: this.username,
        role: this.role,
        salutation: this.salutation,
        schoolId: this.schoolId,
        fullName: this.fullName,
        displayName: this.displayName,
        designation: this.designation,
        accountLocked: this.accountLocked,
      },
    };
  },
  methods: {
    ...mapActions([
      "createUser",
      "createTeacher",
      "updateUser",
      "updateTeacherDetails",
    ]),
    toggleAccountLocked() {
      this.formData.accountLocked = !this.formData.accountLocked;
    },
    getSanitizedFormData() {
      let {
        username,
        role,
        accountLocked,
        schoolId,
        salutation,
        fullName,
        displayName,
        designation,
      } = this.formData;

      username = username.trim();
      fullName = fullName.trim();
      displayName = displayName.trim();
      designation = designation.trim();
      schoolId = schoolId.map(({ id }) => id);

      return {
        username,
        role,
        accountLocked,
        schoolId,
        salutation,
        fullName,
        displayName,
        designation,
      };
    },
    stringCheck(...args) {
      return !args.some((it) => it === "");
    },
    submit() {
      if (this.isNew) {
        this.submitNew();
      } else {
        this.submitUpdate();
      }
    },
    async submitNew() {
      const {
        username,
        role,
        accountLocked,
        schoolId,
        salutation,
        fullName,
        displayName,
        designation,
      } = this.getSanitizedFormData();

      if (!this.stringCheck(username, fullName, displayName, designation)) {
        return this.$toastr.error(this.$t("userModal.create.fields.error"));
      }
      if (!role) {
        return this.$toastr.error(this.$t("userModal.create.role.error"));
      }

      const userForm = {
        username: username,
        role,
        schoolId,
        accountLocked,
      };

      const teacherForm = {
        salutation,
        fullName,
        displayName,
        designation,
      };

      try {
        const { status: userStatus, data: userData } = await this.createUser(
          userForm
        );
        if (userStatus !== constants.RESPONSE_STATUS.OK && userData) {
          return this.$toastr.error(userData && userData.message);
        }
        const { status: teacherStatus, data: teacherData } =
          await this.createTeacher({
            ...teacherForm,
            ...{ userId: userData.user.id },
          });
        if (teacherStatus !== constants.RESPONSE_STATUS.OK && teacherData) {
          this.$toastr.error(teacherData && teacherData.message);
        }
        this.$toastr.success(
          this.$t("userModal.success.created", { username })
        );
        this.$modal.closeAll();
      } catch (error) {
        this.$toastr.error(error.data && error.data.message);
      }
    },
    async submitUpdate() {
      const {
        username,
        role,
        accountLocked,
        salutation,
        fullName,
        schoolId,
        displayName,
        designation,
      } = this.getSanitizedFormData();

      if (!this.stringCheck(username, fullName, displayName, designation)) {
        return this.$toastr.error(this.$t("userModal.create.fields.error"));
      }
      if (!role) {
        return this.$toastr.error(this.$t("userModal.create.role.error"));
      }

      const userForm = {
        userId: this.userId,
        username,
        role,
        schoolId,
        accountLocked,
      };

      const teacherForm = {
        userId: this.userId,
        teacherId: this.teacherId,
        salutation,
        fullName,
        displayName,
        designation,
      };
      try {
        const { status: userStatus, data: userData } = await this.updateUser(
          userForm
        );
        if (userStatus !== constants.RESPONSE_STATUS.OK && userData) {
          return this.$toastr.error(userData && userData.message);
        }
        const { status: teacherStatus, data: teacherData } =
          await this.updateTeacherDetails(teacherForm);
        if (teacherStatus !== constants.RESPONSE_STATUS.OK && teacherData) {
          this.$toastr.error(teacherData && teacherData.message);
        }
        this.$toastr.success(
          this.$t("userModal.success.updated", { username })
        );
        this.$modal.closeAll();
      } catch (error) {
        this.$toastr.error(error.data && error.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
</style>
