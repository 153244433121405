export default {
	bind (el, binding, vnode) {
		let expressionToCall = vnode.context[binding.expression]

		el.addEventListener('click', binding.def.stopProp)
		document.body.addEventListener('click', expressionToCall)
	},
	unbind (el, binding, vnode) {
		let expressionToCall = vnode.context[binding.expression]

		el.removeEventListener('click', binding.def.stopProp)
		document.body.removeEventListener('click', expressionToCall)
	},
	stopProp (event) {
		event.stopPropagation()
	}
}
