import Vue from 'vue'

export default {
	getTenants() {
		return Vue.http.get('tenant/tenant')
	},
    getTenantDetails(id) {
		return Vue.http.get(`tenant/tenant/${id}`)
	},
    viewEditTenant(id) {
		return Vue.http.get(`tenant/tenant/viewEdit/${id}`)
	},
	createTenant({ name, email, fullname, enabled }) {
		return Vue.http.post('tenant/tenant', { name, email, fullname, enabled })
	},
	editTenant(body) {
		return Vue.http.put(`tenant/tenant/${body.id}`, {...body})
	},
	deleteTenant({ id }) {
		return Vue.http.delete(`tenant/tenant/${id}`)
	},
};
