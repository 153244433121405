// coachingOutcome.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingOutcomeApi from '@/api/coachingOutcome'
import constants from '@/constants';

function getGoalById(goals, goalId) {
	return goals.find(goal => goal.id === goalId);
}
  
// initial state
const state = {
  // old coachingEngagement: null,
  coachingOutcome: {},
  coachingOutcomes: [],
};

// getters
const getters = {
  coachingOutcome: state => state.coachingOutcome,
  coachingOutcomes: state => state.coachingOutcomes,
};

// actions
const actions = {
  setCoachingOutcome({ commit }, coachingOutcome) {
    commit(types.SET_COACHING_OUTCOME, coachingOutcome);
  },

  // new actions
  setCoachingOutcomes({ commit }, coachingOutcomes) {
    commit(types.SET_COACHING_OUTCOMES, coachingOutcomes);
  },
  async getCoachingOutcome({ commit }, id) {
    const response = await CoachingOutcomeApi.getCoachingOutcome(id)
    commit(types.SET_COACHING_OUTCOME, response.data)
  },

  async createCoachingOutcome({ commit, dispatch, rootState }, { coachingGoalId, outcomeName }) {
    console.log("STORE: createCoachingOutcome - { coachingGoalId, outcomeName }:", { coachingGoalId, outcomeName })
    const response = await CoachingOutcomeApi.createCoachingOutcome(coachingGoalId, outcomeName);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_OUTCOME, response.data.coachingOutcome);
      // here we need to get the studentCoachingEngagement again to have the latest info with coachingOutcome
	  // await dispatch('studentCoachingEngagement/getStudentCoachingEngagement', rootState.studentCoachingEngagement.currentCoachingEngagement.id, { root: true }); 
	  await dispatch('getStudentCoachingEngagement', rootState.studentCoachingEngagement.currentCoachingEngagement.id);

	  // Now let's update the activeGoal
	  const activeGoal = rootState.coachingActiveGoal.activeGoal;
	  const updatedGoal = getGoalById(rootState.studentCoachingEngagement.currentCoachingEngagement.coachingGoals, activeGoal.goal.id);

	  // Check if the updatedGoal exists and then dispatch setActiveGoal action
	  if(updatedGoal) {
	    dispatch('setActiveGoal', { index: activeGoal.index, goal: updatedGoal });
	  }

    }
  },

  async updateCoachingOutcome({ commit, dispatch }, { id, studentCoachingEngagementId, outcomeName }) {
    const response = await CoachingOutcomeApi.updateCoachingOutcome(id, outcomeName)
    commit(types.UPDATE_COACHING_OUTCOME, response.data.coachingOutcome)
    // here we need to get the classGroup again to have the latest info with coachingOutcome
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },

  async deleteCoachingEngagement({ commit, dispatch }, id, studentCoachingEngagementId) {
    await CoachingOutcomeApi.deleteCoachingOutcome(id)
    commit(types.DELETE_COACHING_OUTCOME, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_OUTCOME](state, coachingOutcome) {
    state.coachingOutcome = coachingOutcome;
  },
  [types.SET_COACHING_OUTCOMES] (state, coachingOutcomes) {
    state.coachingOutcomes = coachingOutcomes
  },
  [types.ADD_COACHING_OUTCOME] (state, coachingOutcome) {
    state.coachingOutcomes.unshift(coachingOutcome)
  },
  [types.UPDATE_COACHING_OUTCOME] (state, coachingOutcome) {
    Vue.set(state.coachingOutcome, coachingOutcome)
    // const index = state.coachingEngagements.findIndex(e => e.id === engagement.id)
    // if (index !== -1) {
    //   Vue.set(state.coachingEngagements, index, engagement)
    // }
  },
  [types.DELETE_COACHING_OUTCOME] (state, coachingOutcomeId) {
    state.coachingOutcomes = state.coachingOutcomes.filter(e => e.id !== coachingOutcomeId)
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
