<template>
  <div
    v-if="haveEffectiveElos(details)"
    ref="domainCard"
    :id="'sticky_lesson_grid_' + $vnode.key"
    v-cloak
  >
    <div
      class="domain-list-header card-divider padding-0"
      data-sticky-container
    >
      <div
        class="wrapper grid-x align-middle padding-1"
        v-foundation:Sticky.destroy
        :data-anchor="'sticky_lesson_grid_' + $vnode.key"
        :data-margin-top="marginTop"
        data-sticky-on="small"
        data-check-every="0"
      >
        <div class="cell auto h-pos-relative">
          <h6 class="domain-list-title truncate">
            {{ domainName | labelize }}&nbsp;&nbsp;
          </h6>
          <div class="accordion-icon"><!-- place accordion icon --></div>
        </div>
      </div>
    </div>
    <div class="domain-list-body">
      <sub-domain-list-card
        v-for="subdomain in details"
        :key="subdomain.id"
        :sub-domain="subdomain"
        :retain-elos="retainElos"
      >
        <tlo-list-card
          v-for="tlo in subdomain.tlos"
          :key="tlo.id"
          :tlo="tlo"
          :retain-elos="retainElos"
        >
          <div v-for="elo in retainElos(tlo.elos)" :key="elo.id">
            <div class="grid-x">
              <elo-list-card-details
                class="cell auto padding-1"
                :elo-code="elo.eloCode"
                :elo-name="elo.eloName"
                :programme-codes="elo.programmeCodes"
              >
              </elo-list-card-details>
              <p class="lesson-count padding-1">
                {{
                  $tc(
                    "lessonPlanRecordDomainListCard.lessons",
                    lessonCount(elo.eloCode),
                    { lessonCount: lessonCount(elo.eloCode) }
                  )
                }}
              </p>
            </div>
            <div class="grid-x cell align-middle">
              <div
                v-for="student in students"
                :key="student.id"
                class="
                  student-elo-check
                  cell
                  auto
                  grid-x
                  align-center align-middle
                "
                @click="showEditModal(student.id, elo)"
                :class="
                  getStudentElo(student.id, elo) ? 'selected' : 'unselected'
                "
              >
                <div
                  class="
                    attainment
                    no-print
                    cell
                    grid-x
                    align-center align-middle
                  "
                >
                  <div
                    class="attainment-cell"
                    v-if="
                      attainmentForTermAndAssessmentCycle(student.id, elo, 1) &&
                      attainmentForTermAndAssessmentCycle(student.id, elo, 1)
                        .attainmentLevel
                    "
                  >
                    <p class="assessment-cycle-label">
                      {{ $t("eloListCard.first.assessment") }}
                    </p>
                    <attainment-badge
                      :level="
                        attainmentForTermAndAssessmentCycle(student.id, elo, 1)
                          .attainmentLevel
                      "
                    >
                    </attainment-badge>
                  </div>
                  <div
                    class="attainment-cell"
                    v-if="
                      attainmentForTermAndAssessmentCycle(student.id, elo, 2) &&
                      attainmentForTermAndAssessmentCycle(student.id, elo, 2)
                        .attainmentLevel
                    "
                  >
                    <p class="assessment-cycle-label">
                      {{ $t("eloListCard.second.assessment") }}
                    </p>
                    <attainment-badge
                      :level="
                        attainmentForTermAndAssessmentCycle(student.id, elo, 2)
                          .attainmentLevel
                      "
                    >
                    </attainment-badge>
                  </div>
                </div>
                <div
                  class="cell last-analysis no-print text-center"
                  v-if="lastAnalysisWeekForTerm(student.id, elo)"
                >
                  <font-awesome-icon icon="tasks"></font-awesome-icon>
                  <span class="badge">{{
                    lastAnalysisWeekForTerm(student.id, elo)
                  }}</span>
                </div>
                <!-- <span class="cell prompt no-print text-center" v-if="attainmentForTerm(student.id, elo).promptHierarchy">
									{{ attainmentForTerm(student.id, elo).promptHierarchy }}
								</span> -->
                <span class="print-checkmark"
                  ><font-awesome-icon
                    slot="fontAwesomeIcon"
                    icon="check"
                    class="fa-xs"
                  ></font-awesome-icon
                ></span>
                <font-awesome-icon
                  class="clickable color--dark-gray fa-xs no-print"
                  :class="getStudentElo(student.id, elo) ? '' : 'hide'"
                  icon="chevron-right"
                ></font-awesome-icon>
              </div>
            </div>
          </div>
        </tlo-list-card>
      </sub-domain-list-card>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SubDomainListCard from "./SubDomainListCard";
import TloListCard from "./TloListCard";
import EloListCardDetails from "./EloListCardDetails";
import EditEloModal from "@/views/EditEloModal/EditEloModal";
import AttainmentBadge from "./AttainmentBadge";
import stickyInitializer from "@/mixins/stickyInitializer";

export default {
  name: "LessonPlanRecordDomainListCard",
  components: {
    FontAwesomeIcon,
    SubDomainListCard,
    TloListCard,
    EloListCardDetails,
    AttainmentBadge,
  },
  mixins: [stickyInitializer],
  props: {
    domainName: String,
    details: Array,
    edit: Boolean,
    retainElos: {
      type: Function,
      default: () => [],
    },
    students: Array,
    selectedYear: Number,
  },
  data() {
    return {
      marginTop: window.innerWidth > 1024 ? 4.5 : 5.2,
    };
  },
  computed: {
    plannedTermElos: function () {
      let selectedTerm = this.$route.params.term;
      return (
        this.$store.getters.studentElosForTerm(
          this.$route.params.classId,
          selectedTerm
        ) || []
      );
    },
  },
  mounted() {
    this.$refs.domainCard &&
      this.$refs.domainCard.classList &&
      this.$refs.domainCard.classList.add("domain-list-card");
  },
  methods: {
    haveEffectiveElos: function (details) {
      let allElos = _(details).flatMap("tlos").flatMap("elos").value();
      return this.retainElos(allElos) && this.retainElos(allElos).length > 0;
    },
    getStudentElo: function (studentId, elo) {
      return _.find(
        this.plannedTermElos,
        (it) => it.studentId === studentId && it.eloCode === elo.eloCode
      );
    },
    lessonCount: function (eloCode) {
      let elo = _.find(this.plannedTermElos, (it) => it.eloCode === eloCode);
      return elo ? elo.lessonCount : null;
    },
    attainmentForTerm: function (studentId, elo) {
      let studentElo = this.getStudentElo(studentId, elo) || {};
      let term = this.$route.params.term;

      let requiredAttainment = _.filter(
        studentElo.attainments,
        (attainment) => {
          return attainment &&
            attainment !== null &&
            attainment.planTerm !== null &&
            attainment.attainmentLevel !== null
            ? parseInt(attainment.planTerm) === parseInt(term)
            : false;
        }
      );

      return requiredAttainment;
    },
    attainmentForTermAndAssessmentCycle(studentId, elo, assessmentCycle) {
      const requiredAttainment = this.attainmentForTerm(studentId, elo).find(
        (attainment) => {
          return attainment.assessmentCycle === assessmentCycle;
        }
      );

      return requiredAttainment;
    },
    lastAnalysisWeekForTerm: function (studentId, elo) {
      let studentElo = this.getStudentElo(studentId, elo) || {};
      let term = parseInt(this.$route.params.term);

      return (
        studentElo.latestAnalysisWeek && studentElo.latestAnalysisWeek[term]
      );
    },
    showEditModal(studentId, elo) {
      if (this.getStudentElo(studentId, elo)) {
        let componentProps = {
          eloCode: elo.eloCode,
          eloName: elo.eloName,
          programmeCodes: elo.programmeCodes,
          studentId: studentId,
          selectedYear: parseInt(this.$route.params.year),
          selectedTerm: parseInt(this.$route.params.term),
        };

        this.$modal.show({
          component: EditEloModal,
          componentProps: componentProps,
          class: "large",
          closeCallback: this.closeModal,
        });
      }
    },
    closeModal() {
      this.$store.dispatch("getStudentElosForTerm", {
        classGroupId: this.$route.params.classId,
        planTerm: this.$route.params.term,
      });
      this.$store.dispatch(
        "getStudentAttainmentScore",
        this.$route.params.classId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .only-print,
  .only-print * {
    display: none !important;
  }
}

@media print {
  .selected {
    .only-print,
    .only-print * {
      display: block !important;
    }
  }

  .unselected {
    .only-print,
    .only-print * {
      display: none !important;
    }
  }
}

.domain-list-body {
  .lesson-count {
    padding: 1em;
  }
}
.student-elo-check {
  position: relative;
  min-height: rem-calc(130);
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  border-left: 1px solid $gray;

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  &.selected:hover {
    cursor: pointer;
    background-color: transparentize($primary, 0.9);
  }

  @include breakpoint(large) {
    min-height: rem-calc(110);
  }

  @media print {
    min-height: 40px;
  }
}
.accordion-item {
  background-color: transparent;
  margin-bottom: 0;
  box-shadow: none;

  .accordion-icon {
    position: absolute;
    transition: transform 0.1s linear;
    transform: rotateZ(0);
    top: 0;
    margin-top: 0.1em;
  }

  &.is-active {
    .accordion-icon {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }
}
.selected {
  background: $bg-selected;

  .clickable {
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
  }
}
.prompt {
  @extend .sans-serif-bold;
  word-wrap: break-word;
  word-break: break-all;
  font-size: $small-font-size;

  & + .print-checkmark {
    display: none;
  }

  @include breakpoint(large) {
    padding: $global-padding * 0.3 $global-padding / 1.5 0;
  }

  @media print {
    position: relative;
    top: 5px;
    padding: 0;
    font-size: $small-font-size / 1.2;
  }
}
.print-checkmark {
  display: none;

  @media print {
    .selected & {
      display: block;
    }
  }
}
.unselected {
  background: $white;
}
.domain-list-header {
  border-bottom: 1px solid smart-scale($brand-primary, 35%, 10%);
  height: 2.8em;
  min-height: 2.8em;

  .wrapper {
    width: 100%;
  }

  .sticky {
    background-color: $brand-primary;
    z-index: 1;

    .accordion-item & {
      border-radius: $global-radius;
    }

    .accordion-item &.is-stuck {
      width: 100%;
      border-right: 1px solid $light-gray;
      border-radius: 0;
    }

    @media print {
      padding: $global-padding / 2.5 $global-padding;
      background-color: $medium-gray;
    }
  }

  .annual-plan-top-wrapper + .domain-list .accordion-item:first-child & {
    border-radius: 0;
  }

  @media print {
    height: auto !important;
    min-height: auto !important;
    border-bottom: 1px solid $darker-gray;
  }
}
.domain-list-title {
  @extend .sans-serif-extrabold;
  margin-bottom: 0;

  @media print {
    font-size: $small-font-size;
  }
}
.lesson-count {
  @extend %sans-serif-bold;
  font-size: $small-font-size;
  color: $darker-gray;
}
.attainment {
  .attainment-cell {
    padding: 0 ($global-padding * 0.4);
    position: relative;

    .assessment-cycle-label {
      position: absolute;
      top: -0.65em;
      right: -0.25em;
    }
  }
}
.last-analysis {
  display: block;
  position: relative;
  padding-bottom: 0.6 * $global-margin;
  color: $title-color;

  .badge {
    display: block;
    margin: auto;
    position: absolute;
    top: -6px;
    left: 50%;
    min-width: 1.5em;
    padding: 0.1em 0;
    font-size: 0.7em;
  }
}
</style>
