<template>
  <div class="side-tray-search">
    <div class="grid-x">
      <div class="cell auto">
        <input
          type="text"
          :placeholder="searchTextInputPlaceholder"
          :value="searchTerm"
          @input="$emit('update:searchTerm', $event.target.value)"
        />
      </div>
      <span
        class="unassigned-toggle cell shrink"
        :class="{ active: showUnassigned }"
        @click="toggleUnassigned()"
      >
        <font-awesome-icon icon="user-times"
      /></span>
    </div>

    <filter-dropdown
      :year="initYear"
      :school-id="initSchoolId"
      :programme-name="initProgrammeName"
      :initClassGroup="initClassGroup"
      @filter-results="filterResults"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FilterDropdown from "./FilterDropdown";

export default {
  name: "SideTraySearch",
  components: {
    FontAwesomeIcon,
    FilterDropdown,
  },
  data() {
    return {
      showUnassigned: false,
    };
  },
  props: {
    searchTerm: {
      type: String,
      default: "",
    },
    searchTextInputPlaceholder: {
      type: String,
    },
    initYear: {
      type: Number,
      default: undefined,
    },
    initSchoolId: {
      type: Number,
      default: undefined,
    },
    initProgrammeName: {
      type: String,
      default: undefined,
    },
    initClassGroup: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    toggleUnassigned() {
      this.showUnassigned = !this.showUnassigned;
      this.$emit("show-unassigned", this.showUnassigned);
    },
    filterResults(selectedClassIds) {
      this.$emit("filter-results", selectedClassIds);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-tray-search {
  .unassigned-toggle {
    margin-left: $global-margin / 2;
    color: $medium-gray;
    cursor: pointer;

    position: relative;
    top: 5px;

    &:hover {
      color: $primary;
    }

    &.active {
      color: $button-background-hover;
    }
  }

  input {
    @extend .small-form-field;
  }
  select {
    @extend .small-form-field;
  }
}
.selected-classes {
  margin-top: $global-margin / 2;
  @extend .small-padding;
  border: 1px solid $secondary-color;
  border-radius: $global-radius;
  background-color: $light-gray;

  .selected-classes-header {
    @extend .small-header;
    margin-top: $global-margin / 5;
  }
  .selected-classes-value {
    @extend .small-padding;
    @extend .small-header;
    margin-right: $global-margin / 3.5;
    margin-bottom: $global-margin / 2.5;
    color: $black;
    border: 1px solid $secondary-color;
    border-radius: $global-radius;
    background-color: $bg-selected;
    line-height: 1;
    text-align: center;
  }
}

.small-form-field {
  @extend .small-text;
  margin-bottom: $global-margin / 1.5;
  padding-top: $global-padding / 2.5;
  padding-bottom: $global-padding / 2.5;
  height: auto;
}
.small-text {
  @extend .sans-serif-regular;
  font-size: $small-font-size;
}
.small-header {
  @extend .sans-serif-bold;
  font-size: $small-font-size;
}
.small-padding {
  padding-top: $global-padding / 3.5;
  padding-bottom: $global-padding / 3.5;
  padding-left: $global-padding / 3.5;
  padding-right: $global-padding / 3.5;
  height: auto;
}
</style>
