<template>
  <div class="dashboard-list-card card">
    <div class="card-section grid-y align-center">
      <div class="grid-x">
        <profile-image
          class="margin-right-1"
          :image="image"
          :image-alt="textContent"
          :image-size="50"
          :image-title="profileName"
        >
          <condition-type-badge
            slot="condition-type"
            :condition-types="conditionTypes"
            :with-text-label="false"
          >
          </condition-type-badge>
        </profile-image>

        <span class="grid-y align-center cell auto card-label">{{
          textContent
        }}</span>

        <div class="right">
          <slot name="right" />
        </div>
      </div>

      <div class="right-aligned">
        <slot name="right-aligned" />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImage from "@/components/ProfileImage";
import ConditionTypeBadge from "@/components/ConditionTypeBadge";

export default {
  name: "BaseClassGroupListCard",
  components: {
    ProfileImage,
    ConditionTypeBadge,
  },
  props: {
    image: String,
    conditionTypes: Array,
    textContent: String,
    profileName: String,
    cardId: String,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-list-card {
  margin-bottom: 0.3em;

  a {
    color: $dark-gray;

    &:hover {
      color: smart-scale($dark-gray, 20%);
    }
  }
}
.card-section {
  padding-top: $global-padding / 1.5;
  padding-bottom: $global-padding / 1.5;

  .right {
    margin: 0 $global-margin / 1.8;
  }

  position: relative;
  .right-aligned {
    position: absolute;

    width: $global-padding;
    height: rem-calc(50) + ($global-padding / 1.5) * 2;
    left: $elo-side-tray-width - ($global-padding * 2.5);
  }
}
.card-label {
  font-size: map-deep-get($header-styles, small, h5, font-size) * 1px;
}
</style>

