import * as types from '../mutation-types';
import assessmentOptionsApi from '@/api/assessmentOptions';

// initial state
const state = {
	attainmentLevelList: [],
	taskItemCategoryList: [],
	promptHierarchyList: [],
	outcomeLevelList: []
};

// getters
const getters = {
	attainmentLevelList(state) {
		return state.attainmentLevelList;
	},
	taskItemCategoryList(state) {
		return state.taskItemCategoryList;
	},
	promptHierarchyList(state) {
		return state.promptHierarchyList;
	},
	outcomeLevelList(state) {
		return state.outcomeLevelList;
	}
};

// actions
const actions = {
	getAssessmentOptions({ commit }) {
		assessmentOptionsApi.getAssessmentOptions().then((resp) => {
			commit(types.SET_ATTAINMENT_LEVEL_LIST, resp.data.attainmentLevelList);
			commit(types.SET_TASK_ITEM_CATEGORY_LIST, resp.data.taskItemCategoryList);
			commit(types.SET_PROMPT_HIERARCHY_LIST, resp.data.promptHierarchyList);
			commit(types.SET_OUTCOME_LEVEL_LIST, resp.data.outcomeLevelList);
		});
	}
};

// mutations
const mutations = {
	[types.SET_ATTAINMENT_LEVEL_LIST](state, attainmentLevelList) {
		state.attainmentLevelList = attainmentLevelList;
	},
	[types.SET_TASK_ITEM_CATEGORY_LIST](state, taskItemCategoryList) {
		state.taskItemCategoryList = taskItemCategoryList;
	},
	[types.SET_PROMPT_HIERARCHY_LIST](state, promptHierarchyList) {
		state.promptHierarchyList = promptHierarchyList;
	},
	[types.SET_OUTCOME_LEVEL_LIST](state, outcomeLevelList) {
		state.outcomeLevelList = outcomeLevelList;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
