<template>
  <div
    class="chat"
    :class="{
      'chat-end': myMessage,
      'chat-start': !myMessage,
    }"
  >
    <div class="chat-image avatar">
      <!--
        <div class="avatar-container">
          <img :src="user.avatar" class="avatar-image" />
        </div>
      -->
      <font-awesome-icon :icon="user.avatar" size="lg"></font-awesome-icon>
    </div>
    <div class="chat-header mb-2">
      {{ user.name }}
      <!-- <time v-if="message" class="text-xs opacity-50">{{
        useTimeAgo(message.createdAt).value
      }}</time> -->
      <time v-if="message" class="text-xs opacity-50">{{ timeAgo(message.createdAt) }}</time>
    </div>
    <div
      class="chat-bubble py-0 prose prose-sm bg-white dark:bg-gray-900 max-w-max w-full"
    >
      <slot>
        <VueMarkdown :source="message.text" class="w-full" />
      </slot>
    </div>
    <!-- <div class="chat-footer opacity-50">Delivered</div> -->
  </div>
</template>


<script>
import VueMarkdown from "vue-markdown"  // import Markdown from "vue3-markdown-it";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ChatBubble",
  components: {
    VueMarkdown,
    FontAwesomeIcon
  },
  props: {
    message: Object, //Message;
    user: Object, //User;
    myMessage: Boolean,
  },
  methods: {
    timeAgo(timestamp) {
      // Get the current time
      const now = new Date().getTime();

      // Calculate the difference in milliseconds
      const difference = now - new Date(timestamp).getTime();

      // Calculate the difference in minutes
      const minutes = Math.floor(difference / 60000);

      // If less than 60 minutes, return in minutes
      if (minutes < 60) {
        return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
      }

      // Calculate the difference in hours
      const hours = Math.floor(minutes / 60);

      // Return in hours
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    },
  },
};
</script>


<style scoped>
code {
  background: none;
  overflow-x: auto;
  width: 100%;
}
pre {
  background-color: rgba(0, 0, 0, 0.3); /* For dark mode */
  overflow-x: auto;
  width: 100%;
}
.chat-bubble {
  border-radius: 12px;
  padding: 10px;
  background-color: #f1f1f1;
  margin: 5px;
  max-width: 80%; /* To ensure the bubble doesn't take the full width */
}
.avatar-container {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
