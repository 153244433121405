import Vue from 'vue'

export default {
	getSchools() {
		return Vue.http.get('school')
	},
	createSchool({ name, code, organisationType }) {
		return Vue.http.post('school', { name, code, organisationType })
	},
	editSchool({ id, name, code, organisationType, programmes }) {
		return Vue.http.put(`school/${id}`, { name, code, organisationType, programmes })
	},
	deleteSchool({ id }) {
		return Vue.http.delete(`school/${id}`)
	},
};
