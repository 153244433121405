<template>
  <div v-cloak>
    <h4 class="modal-title">{{ title }}</h4>

    <section>
      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("school.nameTitle") }}
        </h5>
        <input
          class="cell small-8 medium-6 margin-bottom-0"
          type="text"
          placeholder="Name"
          v-model="schoolField.name"
        />
      </div>

      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("school.codeTitle") }}
        </h5>
        <input
          class="cell small-5 medium-2 margin-bottom-0"
          type="text"
          placeholder="Code"
          v-model="schoolField.code"
        />
      </div>

      <div class="grid-x minor-padding-bottom">
        <h5 class="cell small-4 medium-3 margin-0">
          {{ $t("school.organisationTypeTitle") }}
        </h5>

        <select
          class="cell small-6 medium-3 margin-bottom-0"
          type="text" :disabled=true
          v-model="schoolField.organisationType"
        >
          <option
            v-for="organisation in organisationList"
            :key="`organisation-${organisation}`"
            :value="organisation"
          >
            {{ organisation }}
          </option>
        </select>
      </div>
    </section>

    <section v-if="isEditing">
      <hr />
      <h5>{{ $t("school.programmesTitle") }}</h5>
      <div class="card create-background">
        <div class="card-section card-padding grid-x">
          <input
            class="cell small-4 medium-2 margin-bottom-0"
            type="text"
            placeholder="Code"
            v-model="programmeField.code"
          />
          <div class="cell small-8 medium-6 padding-left-1">
            <input
              class="margin-bottom-0"
              type="text"
              placeholder="Name"
              v-model="programmeField.name"
            />
          </div>

          <div class="cell small-12 medium-4 grid-x align-right">
            <button
              class="button clear margin-0"
              @click="createProgramme"
              :disabled="programmeCreationIsDisabled"
            >
              {{ $t("school.createButton") }}
            </button>
          </div>
        </div>
      </div>

      <div
        v-for="(programme, key) in programmes"
        class="card"
        :key="`programme-${programme.id}`"
      >
        <div class="card-section card-padding grid-x">
          <input
            class="cell small-4 medium-2 margin-bottom-0"
            type="text"
            placeholder="Code"
            v-model="programmes[key].code"
          />
          <div class="cell small-8 medium-6 padding-left-1">
            <input
              class="margin-bottom-0"
              type="text"
              placeholder="Name"
              v-model="programmes[key].name"
            />
          </div>

          <div class="cell small-12 medium-4 grid-x align-right">
            <button
              class="button clear"
              @click="deleteSchoolProgramme(programme)"
            >
              {{ $t("school.delete") }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="action-selector grid-x align-right">
      <button
        v-if="isEditing"
        type="submit"
        class="button primary margin-bottom-0"
        :disabled="mainButtonIsDisabled"
        @click="update"
      >
        {{ $t("school.editButton") }}
      </button>
      <button
        v-else
        type="submit"
        class="button primary margin-bottom-0"
        :disabled="mainButtonIsDisabled"
        @click="create"
      >
        {{ $t("school.createButton") }}
      </button>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants";

export default {
  name: "SchoolEditor",
  props: {
    isEditing: { type: Boolean, require: true },
    school: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      schoolField: {
        code: "",
        name: "",
        // organisationType: "SCHOOL",   <<< Add condition here to make default if value is missing
        organisationType: constants.ORGANIZATION_TYPE.SCHOOL,
      },
      programmeField: {
        code: "",
        name: "",
      },
      programmes: [],
    };
  },
  computed: {
    ...mapGetters(["programmesForSchoolId"]),
    title() {
      return this.isEditing
        ? this.$t("school.editTitle", { school: this.school.name })
        : this.$t("school.createTitle");
    },
    programmeCreationIsDisabled() {
      return Object.values(this.programmeField).includes("");
    },
    mainButtonIsDisabled() {
      const list = [...this.programmes, this.schoolField];
      let isEmpty = false;
      list.forEach((item) => {
        if (Object.values(item).includes("")) {
          isEmpty = true;
        }
      });

      return isEmpty;
    },
    organisationList() {
      return Object.values(constants.ORGANIZATION_TYPE);
    },
  },
  created() {
    if (this.isEditing) {
      this.schoolField = this.school;
      this.programmes = this.programmesForSchoolId(this.school.id);
    }
  },
  methods: {
    ...mapActions([
      "createSchool",
      "editSchool",
      "deleteSchool",
      "deleteProgramme",
    ]),
    async create() {
      await this.createSchool(this.schoolField);
      this.$modal.closeAll();
    },
    async update(closeModal) {
      const params = {
        ...this.schoolField,
        programmes: this.programmes,
      };
      await this.editSchool(params);
      this.programmes = this.programmesForSchoolId(this.school.id);
      if (closeModal) this.$modal.closeAll();
    },
    createProgramme() {
      this.programmes.push(this.programmeField);
      this.resetProgrammeField();
      this.update(false);
    },
    async deleteSchoolProgramme(programme) {
      const param = { id: programme.id, schoolId: this.school.id };
      // const param = { id: programme.id };
      await this.deleteProgramme(param);
      this.programmes = this.programmesForSchoolId(this.school.id);
    },
    resetProgrammeField() {
      this.programmeField = {
        code: "",
        name: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.minor-padding-bottom {
  padding-bottom: $global-padding / 2;
}

.create-background {
  background-color: $bg-selected;
}
</style>