<template>
  <div class="grid-x align-center align-middle" v-cloak>
    <div
      class="image-holder"
      data-options="is_hover:true; hover_timeout:5000"
      :data-toggle="'suggestion-dropdown' + imageId"
      :style="{ height: imageSize + 'px', width: imageSize + 'px' }"
    >
      <img
        class="picture"
        v-if="image"
        :src="image"
        :title="imageTitle"
        :alt="imageAlt"
      />
      <font-awesome-icon
        v-else
        class="empty-image"
        :icon="['far', 'image']"
      ></font-awesome-icon>
    </div>

    <div
      :id="'suggestion-dropdown' + imageId"
      class="suggestion-dropdown dropdown-pane"
      v-foundation:Dropdown.destroy
      data-position="right"
      data-alignment="center"
      data-hover="true"
      data-hover-pane="true"
      data-allow-overlap="true"
      data-auto-focus="true"
      data-close-on-click="true"
    >
      <img
        class="picture-fill"
        v-if="image"
        :src="image"
        :title="imageTitle"
        :alt="imageAlt"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import _ from "lodash";

export default {
  name: "ImageContainer",
  components: {
    FontAwesomeIcon,
  },
  props: {
    imageContextId: Number,
    imageId: String,
    imageAlt: String,
    imageSize: Number,
    imageTitle: String,
    imageSrc: {
      type: String,
      required: true,
    },
    imageType: {
      type: String,
      required: true,
      validator: (value) => {
        let typesAllowed = ["RemarkEvidence"];
        return _.includes(typesAllowed, value);
      },
    },
  },
  asyncComputed: {
    image: {
      lazy: true,
      get() {
        return this.$store
          .dispatch(this.imageSrc, {
            remarkId: this.imageContextId,
            fileUUID: this.imageId,
          })
          .then((result) => {
            return result ? result.data : null;
          });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.picture-fill {
  object-fit: contain;
}
.image-holder {
  background-repeat: no-repeat;
  border-radius: 50%;

  .picture {
    height: 100%;
    object-fit: cover;
  }

  .empty-image {
    height: 100%;
    width: 100%;
    color: $light-gray;
  }
}
.suggestion-dropdown {
  background-color: $white;
}
.suggestion-box {
  .suggestion-box-label {
    padding: $global-padding 0 $global-padding $global-padding * 0.4;
    font-size: 0.7rem;
    color: $medium-gray;
    transform: rotate(180deg);
    writing-mode: tb-rl;
  }
  .suggestion-box-text {
    border: 1px dashed $medium-gray;
    border-radius: $global-radius;
    padding: $global-padding * 0.5 $global-padding * 0.8;
    margin: $global-margin * 0.1;
    font-size: 0.8rem;
  }
}
.btn-info {
  font-size: map-deep-get($header-styles, small, h2, font-size) * 1px;
}
</style>

