<template>
  <div class="lesson-record-wrapper" v-cloak>
    <div class="heading-section color--white padding-left-1">
      <b>{{ $t(recordContextHeadingKey) }}</b>
    </div>

    <div id="sticky_lesson_grid">
      <div data-sticky-container class="lesson-record-top">
        <div
          ref="sticky"
          class="lesson-record-title"
          v-foundation:Sticky.destroy
          data-anchor="sticky_lesson_grid"
          data-margin-top="0"
          data-sticky-on="small"
          data-check-every="0"
        >
          <div class="grid-x" v-if="classGroup">
            <div
              v-for="(student, i) in classGroup.students"
              :key="i"
              class="lesson-record-term-header cell auto"
            >
              <div class="lesson-record-term grid-y align-middle">
                <div
                  class="student-name-wrapper"
                  :class="
                    student.profileStatus === constants.PROFILE_STATUS.DRAFT
                      ? 'draft'
                      : ''
                  "
                >
                  <profile-image
                    class="hide no-print"
                    :image-title="student.fullName"
                    :image="student.image"
                    :image-alt="student.displayName"
                    :image-size="40"
                  ></profile-image>
                  <p class="student-name truncate">{{ student.displayName }}</p>
                </div>
                <attainment-score
                  class="show-for-large"
                  :score="getStudentAttainmentScore(classId, student.id)"
                  :annualPlanStatus="
                    getStudentPlanStatus(classId, student.id) &&
                    getStudentPlanStatus(classId, student.id).annualPlanStatus
                  "
                >
                </attainment-score>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="classGroup && classGroup.students">
        <lesson-plan-record-domain-list-card
          v-for="card in curriculum.domains"
          :key="card.id"
          :domain-name="card.domainName"
          :details="curriculum.domainDetails[card.id]"
          :retain-elos="eloFilter()"
          :students="classGroup.students"
        >
        </lesson-plan-record-domain-list-card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import LessonPlanRecordDomainListCard from "./LessonPlanRecordDomainListCard";
import ProfileImage from "./ProfileImage";
import AttainmentScore from "./AttainmentScore";
import stickyInitializer from "@/mixins/stickyInitializer";
import studentPlanStatus from "@/mixins/studentPlanStatus";
import studentAttainmentScore from "@/mixins/studentAttainmentScore";
import constants from "@/constants";

export default {
  name: "LessonPlanRecord",
  components: {
    LessonPlanRecordDomainListCard,
    ProfileImage,
    AttainmentScore,
  },
  mixins: [stickyInitializer, studentPlanStatus, studentAttainmentScore],
  props: ["classId", "term", "lessonId"],
  data() {
    return {
      constants: constants,
    };
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
      classGroup: "classGroup",
    }),
    isLessonRecord: function () {
      return this.$route.name === "LessonPlanRecord";
    },
    recordContextHeadingKey: function () {
      return this.isLessonRecord
        ? "lessonPlan.record.heading.lesson"
        : "lessonPlan.record.heading.term";
    },
  },
  watch: {
    term: function () {
      this.getStudentElosForTerm();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.getStudentPlanStatusesForClassGroup(to.params.classId);
    this.getStudentElosForTerm(to.params.classId, to.params.term);
    if (to.params.lessonId) {
      this.getLessonPlanElos(to.params.lessonId);
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getStudentPlanStatusesForClassGroup(to.params.classId);
      vm.getStudentElosForTerm(to.params.classId, to.params.term);
      if (to.params.lessonId) {
        vm.getLessonPlanElos(to.params.lessonId);
      }
    });
  },
  created() {
    this.getCurriculum();
  },
  methods: {
    ...mapActions(["getCurriculum"]),
    eloFilter: function () {
      let plannedTermElos =
        this.$store.getters.studentElosForTerm(this.classId, this.term) || [];
      let termEloIdList = _(plannedTermElos)
        .flatMap("elo")
        .map("id")
        .uniq()
        .value();
      let lessonElos = this.$store.getters.lessonElos(this.lessonId) || [];
      let lessonEloIdList = _(lessonElos)
        .flatMap("elo")
        .map("id")
        .uniq()
        .value();
      return (elos) => {
        let isLessonRecord = this.isLessonRecord;
        return _.filter(elos, function (elo) {
          return isLessonRecord
            ? _.includes(termEloIdList, elo.id) &&
                _.includes(lessonEloIdList, elo.id)
            : _.includes(termEloIdList, elo.id);
        });
      };
    },
    getStudentElosForTerm(classId, term) {
      if (classId && term) {
        this.$store.dispatch("getStudentElosForTerm", {
          classGroupId: classId,
          planTerm: term,
        });
        this.$store.dispatch("getStudentAttainmentScore", classId);
      }
    },
    getLessonPlanElos(lessonId) {
      this.$store.dispatch("getLessonPlanElos", {
        lessonId: lessonId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-record-wrapper {
  border-top: 2px solid $light-gray;
  border-right: 2px solid $light-gray;
  border-left: 2px solid $light-gray;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: $title-color;
  color: $white;

  @media print {
    background-color: $light-gray;
    border: 2px solid $darker-gray;

    .lesson-record-top {
      height: 100% !important;
    }
  }
}
.lesson-record-term-header {
  color: $lighter-gray;
  background-color: inherit;
  text-align: center;

  &:not(:first-child) {
    border-left: 1px solid $darker-gray;
  }

  .cell {
    padding-top: $global-padding / 2;
    padding-bottom: $global-padding / 2;
    font-size: 0.8em;
    text-align: center;
    line-height: 1.4em;
  }

  @include breakpoint(small down) {
    border-top: 1px solid $darker-gray;
  }
}
.lesson-record-term {
  @extend %sans-serif-bold;
  position: relative;
  padding: $global-padding / 2 $global-padding 0;
  border-bottom: 1px solid $darker-gray;
  height: 100%;
  font-size: 0.85em;
  line-height: 1.2em;
  white-space: nowrap;

  .draft {
    opacity: 1;
  }

  @include breakpoint(medium down) {
    height: 6em;
    white-space: normal;
  }

  @media print {
    padding: 0;
  }
}
.sticky {
  background-color: transparent;

  &.is-stuck {
    background-color: $black;

    .heading-section {
      display: none;
    }
  }
}
.heading-section {
  padding-top: $global-padding / 2;
  padding-bottom: $global-padding / 2;
  border-bottom: 1px solid $darker-gray;
}
.student-name-wrapper {
  width: 100%;

  .student-name {
    margin-bottom: 0;
    width: auto;
    transform: none;
    text-align: center;
    line-height: 1.2em;

    @include breakpoint(medium down) {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: 0;
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      white-space: nowrap;
      width: 5em;
      text-align: left;
    }
  }
}
</style>
