import * as types from '../mutation-types';
import curriculumApi from '@/api/curriculum';

// initial state
const state = {
	curriculum: {},
	curriculumTimestamp: 0,
	taskItems: [],
	taskItemList: []
};

// getters
const getters = {
	curriculum(state) {
		return state.curriculum;
	},
	curriculumTimestamp(state) {
		return state.curriculumTimestamp;
	},
	taskItems(state) {
		return state.taskItems;
	},
	taskItemList(state) {
		return state.taskItemList;
	}
};

// actions
const actions = {
	getTaskItemList({ commit }, { eloCode }) {
		return curriculumApi.getTaskItem(eloCode).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM_INTERFACE_TASK_ITEM, resp.data);
				return resp.data;
			}
		});
	},
	getCurriculum({ commit, state }) {
		if (state.curriculumTimestamp < (Date.now() - 600000)) { // Refreshed every 10 minutes after initial load
			return curriculumApi.getCurriculum().then(resp => {
				if (resp.data) {
					commit(types.SET_CURRICULUM, resp.data);
					commit(types.SET_CURRICULUM_TIMESTAMP, Date.now());
					return resp.data;
				}
			});
		}
	},
	forceGetCurriculum({ commit, state }) {
		return curriculumApi.getCurriculum().then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data);
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now());
				return resp.data;
			}
		});
	},
	uploadDomain(vue, body) {
		return curriculumApi.uploadDomain(body);
	},
	uploadSubDomain(vue, body) {
		return curriculumApi.uploadSubDomain(body);
	},
	uploadTLO(vue, body) {
		return curriculumApi.uploadTLO(body);
	},
	uploadELO(vue, body) {
		return curriculumApi.uploadELO(body);
	},
	getTaskItem({ commit }, { domainIndex, subDomainIndex, tloIndex, eloIndex, eloCode }) {
		console.log({ domainIndex, subDomainIndex, tloIndex, eloIndex, eloCode });
		return curriculumApi.getTaskItem(eloCode).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM_TASK_ITEM, { domainIndex, subDomainIndex, tloIndex, eloIndex, taskItem: resp.data });
				return resp.data;
			}
		});
	},
	uploadTaskItem(vue, body) {
		return curriculumApi.uploadTaskItem(body).then(resp => {
			if (resp.data) {
				return resp.data;
			}
		});
	},
	downloadCsv(vue, type) {
		return curriculumApi.downloadCsv(type);
	},
	curriculumInterfaceEloUpdate({ commit }, { id, eloName, eloCode, eloLink, eloStatus, eloId, selectedProgrameeArray, unCheckedProgrammeeArray }) {
		return curriculumApi.curriculumInterfaceEloUpdate(
			id, eloName, eloCode, eloLink, eloStatus, eloId, selectedProgrameeArray, unCheckedProgrammeeArray
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceEloAdd({ commit }, { id, eloName, eloCode, eloLink, eloStatus, tloId, selectedProgrameeArray, unCheckedProgrammeeArray }) {
		return curriculumApi.curriculumInterfaceEloAdd(
			id, eloName, eloCode, eloLink, eloStatus, tloId, selectedProgrameeArray, unCheckedProgrammeeArray
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceTaskItemAdd({ commit }, { tableName, taskDescription, eloCode, itemcategory }) {
		console.log('eloCode=' + eloCode)
		return curriculumApi.curriculumInterfaceTaskItemAdd(
			tableName, taskDescription, eloCode, itemcategory
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM_INTERFACE_TASK_ITEM, resp.data)
			}
			return resp
		})
	},
	curriculumInterfaceTaskItemUpdate({ commit }, { id, taskDescription, eloCode, itemcategory, orderNumber }) {
		return curriculumApi.curriculumInterfaceTaskItemUpdate(
			id, taskDescription, eloCode, itemcategory, orderNumber
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM_INTERFACE_TASK_ITEM, resp.data)
			}
			return resp
		})
	},
	async removeItemFromTaskItem({ _ }, { id }) {
		return curriculumApi.removeItemFromTaskItem(id)
	},
	async deleteCurriculumInterfaceElo({ commit }) {
		console.log('Test Delete Api Call');
		commit(types.SET_CURRICULUM_TIMESTAMP, 0)
		return true;
	},
	curriculumInterfaceTloAdd({ commit }, { id, subdomainId, tloName, tloCode }) {
		return curriculumApi.curriculumInterfaceTloAdd(
			id, subdomainId, tloName, tloCode
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceTloUpdate({ commit }, { id, tloName, tloCode, tloId }) {
		return curriculumApi.curriculumInterfaceTloUpdate(
			id, tloName, tloCode, tloId
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceSubDomainUpdate({ commit }, { id, subDomainName, orderNumber, subDomainId }) {
		return curriculumApi.curriculumInterfaceSubDomainUpdate(
			id, subDomainName, orderNumber, subDomainId
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceSubDomainAdd({ commit }, { id, subDomainName, subDomainId, domainId }) {
		return curriculumApi.curriculumInterfaceSubDomainAdd(
			id, subDomainName, subDomainId, domainId
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceDomainUpdate({ commit }, { id, domainName, domainId }) {
		return curriculumApi.curriculumInterfaceDomainUpdate(
			id, domainName, domainId
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	curriculumInterfaceDomainAdd({ commit }, { id, domainName, domainId }) {
		return curriculumApi.curriculumInterfaceDomainAdd(
			id, domainName, domainId
		).then(resp => {
			if (resp.data) {
				commit(types.SET_CURRICULUM, resp.data)
				commit(types.SET_CURRICULUM_TIMESTAMP, Date.now())
			}
			return resp
		})
	},
	async curriculumInterfaceEloDelete({ _ }, { id }) {
		return curriculumApi.curriculumInterfaceEloDelete(id)
	},
	async curriculumInterfaceTloDelete({ _ }, { id }) {
		return curriculumApi.curriculumInterfaceTloDelete(id)
	},
	async curriculumInterfaceSubDomainDelete({ _ }, { id }) {
		return curriculumApi.curriculumInterfaceSubDomainDelete(id)
	},
	async curriculumInterfaceDomainDelete({ _ }, { id }) {
		const { status } = await curriculumApi.curriculumInterfaceDomainDelete(id)
		return { status };
	},
	curriculumTimestampReset({ commit }) {
		commit(types.SET_CURRICULUM_TIMESTAMP, 0);
		return true;
	}
};

// mutations
const mutations = {
	[types.SET_CURRICULUM_INTERFACE_TASK_ITEM](state, taskItemList) {
		state.taskItemList = taskItemList;
	},
	[types.SET_CURRICULUM](state, curriculum) {
		state.curriculum = curriculum;
	},
	[types.SET_CURRICULUM_TIMESTAMP](state, curriculumTimestamp) {
		state.curriculumTimestamp = curriculumTimestamp;
	},
	[types.SET_CURRICULUM_TASK_ITEM](state, { domainIndex, subDomainIndex, tloIndex, eloIndex, taskItem }) {
		state.curriculum.domainDetails[domainIndex][subDomainIndex].tlos[tloIndex].elos[eloIndex].taskItems = taskItem;
		state.taskItems = taskItem;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
