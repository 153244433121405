<template>
  <div class="container-wrapper" v-cloak>
    <div class="grid-x align-middle align-justify">
      <h1 class="page-title">{{ $t("tenant.title") }}</h1>
      <button class="button clear" @click="createModal">
        <font-awesome-icon icon="plus"></font-awesome-icon>
        &nbsp;<b>{{ $t("tenant.create") }}</b>
      </button>
    </div>

    <section>
      <div
        v-for="tenant in tenants"
        :class= "tenant.enabled == true  ? 'card padding-bottom-m ' : 'card inactive adding-bottom-m '"
        :key="`tenant-${tenant.id}`" >
        
        <div
          class="card-section card-padding"
          style="padding: 30px 0 30px 10px"
        >
          <div class="grid-x">
            <profile-image
              class="margin-right-1"
              :image="logo"
              image-alt="logo"
              :image-size="50"
              image-title="logo"
            >
            </profile-image>
            <div>
              <span class="card-label" style="padding: 0px 20px 0px 0px">{{
                tenant.name
              }}</span>
            </div> 
            <more-dropdown v-if="tenant.enabled === true"
              :options="dropdownOptionsActivate"
              :identifier="`tenant-${tenant.id}`"
            ></more-dropdown>

          
            <more-dropdown v-else
              :options="dropdownOptionsDeactivate"
              :identifier="`tenant-${tenant.id}`"
            ></more-dropdown>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MoreDropdown from "@/components/MoreDropdown";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TenantEditor from "./TenantEditor.vue";
import ProfileImage from "@/components/ProfileImage";
export default {
  name: "Tenant",
  components: {
    MoreDropdown,
    FontAwesomeIcon,
    ProfileImage,
  },
  data() {
    return {
      dropdownOptionsActivate: [
        {
          label: "tenant.edit",
          method: this.editModal,
          type: "CLICK",
        },
        {
          label: "tenant.deactivate",
          method: this.deactivate,
          type: "CLICK",
        }
      ],
      dropdownOptionsDeactivate: [
        {
          label: "tenant.edit",
          method: this.editModal,
          type: "CLICK",
        },
        {
          label: "tenant.activate",
          method: this.activate,
          type: "CLICK",
        }
      ],
      logo: "https://pbs.twimg.com/profile_images/1410645236666167301/l2xlZP7J_400x400.png",
      imageSize: 50,
    };
  },
  computed: {
    ...mapGetters(["tenants", "adminTenant"]),
    // userTenant() {
    //   return this.adminTenant(this.tenant.id);
    // },
  },
  created() {
    this.getTenants();
  },
  methods: {
    ...mapActions(["getTenants", "viewEditTenant", "deactivateTenant" , "activateTenant"]),
    getTargetTenantFromElement(e) {
      // const tenantId = e.target.parentElement.id.split("-").at("-1");
      let tenantId = e.target.parentElement.id.replace("tenant-", "");
      tenantId = tenantId.replace("more-dropdown-", "");
      return this.tenants[tenantId];
    },
    createModal() {
      const componentProps = {
        isEditing: false,
      };

      this.$modal.show({
        component: TenantEditor,
        componentProps: componentProps,
        class: "large",
      });
    },
    editModal(e) {
        let tenant = this.getTargetTenantFromElement(e);
        this.viewEditTenant(tenant).then((data) => {
            const componentProps = {
                isEditing: true,
                tenant,
            };
            this.$modal.show({
                component: TenantEditor,
                componentProps: componentProps,
                class: "large", 
            });   
        }, (error) => {
            this.$toastr.error(error.data && error.data.message)
        })
    },
    deactivate(e) {
        const tenant = this.getTargetTenantFromElement(e);
        const tenantId = tenant.id
        const tenantEnabled = false
        const params = {
          id: tenantId,
          enabled: tenantEnabled
        }
        this.$modal.show({
            header: this.$t("tenant.deactivate.modalTitle", {
            tenant: tenant.name }),
             text: this.$t("tenant.deactivate.modalText", {
            tenant: tenant.name }),
            includeConfirm: true,
            successCallback: () => this.deactivateTenant(params).then((data) => {
                this.$modal.closeAll();
        }, (error) => {
            this.$toastr.error(error.data && error.data.message)
        })
       })
    },
    activate(e) {
        const tenant = this.getTargetTenantFromElement(e);
        const tenantId = tenant.id
        const tenantEnabled = true
        const params = {
          id: tenantId,
          enabled: tenantEnabled
        }
        this.$modal.show({
            header: this.$t("tenant.activate.modalTitle", {
            tenant: tenant.name }),
            text: this.$t("tenant.activate.modalText", {
            tenant: tenant.name }),
            includeConfirm: true,
            successCallback: () => this.activateTenant(params).then((data) => {
                this.$modal.closeAll();
        }, (error) => {
            this.$toastr.error(error.data && error.data.message)
        })
       })
    }
    // deleteModal(e) {
    //     const tenant = this.getTargetTenantFromElement(e);
    //     this.$modal.show({
    //         header: this.$t("tenant.delete.modalTitle", {
    //         tenant: tenant.name }),
    //         text: this.$t("tenant.delete.modalText", {
    //         tenant: tenant.name }),
    //         includeConfirm: true,
    //         successCallback: () => this.deleteTenant(tenant)
    //     })
    // }
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
	&.inactive {
		background-color: $light-gray;
	}
}
</style>