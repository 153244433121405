<!-- ApprovalDropdown.vue -->
<template>
	<div class="approval-button" v-cloak>
		<button class="dropdown button rounded" type="button" @click.prevent 
			data-toggle="approval-dropdown">
			{{ $t(planStatusLabel) }}
		</button>
		<div v-if="dropdownOptions.length > 0" class="dropdown-pane" id="approval-dropdown" v-foundation:Dropdown.destroy
			data-position="bottom" data-alignment="right"
			data-auto-focus="true" data-close-on-click="true">
			<div v-for="(option, i) in dropdownOptions" :key="i" @click="option.method">
				{{ $t(option.label) }}
			</div>
		</div>
		<div v-else class="dropdown-pane" id="approval-dropdown" v-foundation:Dropdown.destroy
			data-position="bottom" data-alignment="right"
			data-auto-focus="true" data-close-on-click="true">
			<div>{{ $t('approvalDropdown.noOptions.text') }}</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'

export default {
	name: 'ApprovalDropdown',
	components: {

	},
	props: {
		teacherAuthProfile: {
			type: Object,
			required: true
		},
		planStatus: {
			type: String,
			required: false,
			default: 'DRAFT',
			validator: (planStatus) => {
				return ['NOT_CREATED', 'DRAFT', 'APPROVED'].indexOf(planStatus) > -1
			}
		}
	},
	data () {

	},
	computed: {
		planStatusLabel: function () {
			let labelMap = {
				DRAFT: 'approvalDropdown.status.draft',
				APPROVED: 'approvalDropdown.status.approved'
			}
			return labelMap[this.planStatus]
		},
		dropdownOptions: function () {
			let dropdownOptions = [{
				label: 'approvalDropdown.option.draft',
				method: this.setToDraft,
				auth: ['ROLE_TEACHER', 'ROLE_LEAD_TEACHER', 'ROLE_SUPER_TEACHER'],
				value: 'DRAFT'
			}, {
				label: 'approvalDropdown.option.approve',
				method: this.setToApproved,
				auth: ['ROLE_LEAD_TEACHER', 'ROLE_SUPER_TEACHER'],
				value: 'APPROVED'
			}]

			let availableOptions = dropdownOptions.filter(option => {
				return option.value !== this.planStatus
			})
			let authorizedOptions = availableOptions.filter(option => {
				if (option.auth.length === 0) {
					return true
				}
				return _.intersection(option.auth, this.teacherAuthProfile.roles).length > 0
			})

			return authorizedOptions
		}
	},
	methods: {
		setToDraft () {
			this.$emit('planStatusChanged', 'DRAFT')
		},
		setToApproved () {
			this.$emit('planStatusChanged', 'APPROVED')
		}
	}
}
</script>

<style lang="scss" scoped>
.approval-button {

	button.rounded {
		@extend %sans-serif-extrabold;

		margin-bottom: 0;
		padding: $global-padding / 2 $global-padding;
		border-radius: 50%;
		font-size: $small-font-size;
		
		&:hover {
			background-color: $bg-selected;
		}
	}
}
</style>