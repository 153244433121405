<template>
  <domain-list-card
    ref="domainListCard"
    :domain-id="domainId"
    :text-id="textId"
    :text-name="textName"
    :details="details"
    :retain-elos="retainElos"
  >
    <sticky-wrapper
      slot="stickyWrapper"
      slot-scope="{ effectiveEloCount }"
      class="domain-list-wrapper grid-x align-middle padding-1"
      :base-attributes="stickyAttribute"
      :small-overwrite="smallStickyAttribute"
    >
      <div class="cell auto h-pos-relative">
        <div class="domain-list-title grid-x align-middle">
          <h6 class="domain-title margin-bottom-0 truncate">
            {{ textName | labelize }}
          </h6>
          <span
            class="elo-count"
            :class="effectiveEloCount(details) === 0 ? 'count-alert' : ''"
          >
            {{ effectiveEloCount(details) }}
            {{ $tc("domainListCard.elos", effectiveEloCount(details)) }}
          </span>
        </div>
        <div class="accordion-icon">
          <font-awesome-icon icon="angle-down" size="1x"></font-awesome-icon>
        </div>
      </div>
      <div class="cell shrink">
        <button
          @click.stop="addEloClicked(domainId)"
          type="button"
          class="add-elo-button clear button"
        >
          <small
            ><font-awesome-icon icon="plus" size="1x"></font-awesome-icon
            ><span class="hide-for-small-only"
              >&nbsp;&nbsp;{{ $t("domainList.addElo") }}</span
            ></small
          >
        </button>
      </div>
    </sticky-wrapper>

    <div slot="injectEloListCard" slot-scope="{ tlo }">
      <student-programme-plan-elo-list-card
        v-for="elo in retainElos(tlo.elos)"
        :key="elo.id"
        :text-id="elo.eloCode"
        :text-name="elo.eloName"
        :programmeCodes="elo.programmeCodes"
        :strands="elo.strands"
        :showDetails="elo.isDeveloped"
        :link="elo.link"
      >
      </student-programme-plan-elo-list-card>
    </div>
  </domain-list-card>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DomainListCard from "./DomainListCard";
import StickyWrapper from "./StickyWrapper";
import StudentProgrammePlanEloListCard from "./StudentProgrammePlanEloListCard";

export default {
  name: "StudentProgrammePlanDomainListCard",
  components: {
    FontAwesomeIcon,
    DomainListCard,
    StickyWrapper,
    StudentProgrammePlanEloListCard,
  },
  props: {
    domainId: Number,
    textId: String,
    textName: String,
    details: Array,
    edit: Boolean,
    retainElos: {
      type: Function,
      default: () => [],
    },
    programmePlanStatus: Object,
  },
  data() {
    return {
      stickyIdPrefix: "sticky_",
    };
  },
  computed: {
    stickyAttribute: function () {
      return {
        "data-top-anchor": this.stickyIdPrefix + this.domainId + ":top",
        "data-margin-top":
          this.$route.name === "Aiep" ||
          this.$route.name === "Aiep0" ||
          this.$route.name === "Aiep1" ||
          this.$route.name === "Aiep2"
            ? 4
            : 0,
        "data-sticky-on": "small",
        "data-check-every": 0,
      };
    },
    smallStickyAttribute: function () {
      return {
        "data-margin-top": 0,
      };
    },
  },
  methods: {
    addEloClicked(domainId) {
      if (this.programmePlanStatus.planStatus === "APPROVED") {
        this.$modal.show({
          header: this.$t("addRemoveElo.whenApproved.modal.header"),
          text: this.$t("addRemoveElo.whenApproved.modal.text"),
        });
      } else {
        this.$emit(
          "addEloClicked",
          domainId,
          this.$refs.domainListCard.$refs.content
        );
        this.setSelectedDomainId(domainId);
        $("#elo-side-tray").foundation("open");
      }
    },
    setSelectedDomainId(domainId) {
      this.$store.dispatch("setSelectedDomainId", domainId);
    },
    setUpReferencesOnChildrenComponents() {
      this.setUpFoundationAccordionReferencesOnDomainListCard();
      this.setUpFoundationStickyReferencesOnDomainListCard();
    },
    setUpFoundationAccordionReferencesOnDomainListCard() {
      let domainListCardInnerRefs = this.$refs.domainListCard.$refs;

      domainListCardInnerRefs.domainCard &&
        domainListCardInnerRefs.domainCard.classList &&
        domainListCardInnerRefs.domainCard.classList.add("accordion-item");

      domainListCardInnerRefs.domainCard &&
        domainListCardInnerRefs.domainCard.setAttribute(
          "data-accordion-item",
          ""
        );

      domainListCardInnerRefs.domainHeader &&
        domainListCardInnerRefs.domainHeader.classList &&
        domainListCardInnerRefs.domainHeader.classList.add("accordion-title");

      domainListCardInnerRefs.content &&
        domainListCardInnerRefs.content.classList &&
        domainListCardInnerRefs.content.classList.add("accordion-content");
      domainListCardInnerRefs.content &&
        domainListCardInnerRefs.content.setAttribute("data-tab-content", "");
    },
    setUpFoundationStickyReferencesOnDomainListCard() {
      let domainListCardInnerRefs = this.$refs.domainListCard.$refs;

      if (domainListCardInnerRefs.domainCard) {
        domainListCardInnerRefs.domainCard.id =
          this.stickyIdPrefix + this.domainId;
      }

      domainListCardInnerRefs.domainHeader.setAttribute(
        "data-sticky-container",
        ""
      );
    },
  },
  mounted() {
    this.setUpReferencesOnChildrenComponents();
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  background-color: transparent;
  margin-bottom: 0;
  box-shadow: none;

  .accordion-icon {
    position: absolute;
    transition: transform 0.1s linear;
    transform: rotateZ(0);
    top: 0;
    margin-top: 0.1em;

    @include breakpoint(medium) {
      margin-top: 0.25em;
    }
  }

  &.is-active {
    .accordion-icon {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }
}
.domain-list-header {
  border-top: none;
  border-bottom: 1px solid smart-scale($brand-primary, 35%, 10%);
  font-size: 1rem;
  color: $white;
  height: 3em;
  min-height: 3em;

  .programme-plan & {
    background-color: $brand-tertiary;
    border-bottom-color: smart-scale($brand-tertiary, 30%, 20%);
  }

  .sticky {
    .accordion-item & {
      border-radius: $global-radius;
    }

    .accordion-item &.is-stuck {
      width: 100%;
      border-right: 2px solid $light-gray;
      border-radius: 0;
    }

    .programme-plan & {
      background-color: $brand-tertiary;
    }
  }
}
.domain-list-wrapper {
  padding: $global-padding / 1.5 $global-padding;
  width: 100%;
}
.domain-list-title {
  @extend .sans-serif-bold;
  margin-bottom: 0;
  padding-left: $global-padding * 1.1;
  white-space: nowrap;
}
.add-elo-button {
  color: $white;
  font-size: 1rem;
}
</style>

