<template>
  <ul class="menu vertical dropdown" v-foundation:DropdownMenu.destroy v-cloak>
    <li class="is-dropdown-submenu-parent">
      <a
        class="
          menu-item
          color--light-gray
          sans-serif-bold
          dropdown-first-item
          text-center
        "
      >
        <small>
          {{ selectedYearFilter }}
        </small>
      </a>

      <the-sidebar-menu-nested
        :list="sideMenu"
        :hierarchy="hierarchy"
        :level="0"
        :dropdown-mode="true"
      >
      </the-sidebar-menu-nested>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import TheSidebarMenuNested from "./TheSidebarMenuNested";

export default {
  name: "TheSidebarDropdownMenu",
  components: {
    TheSidebarMenuNested,
  },
  computed: {
    ...mapGetters({
      sideMenu: "sideMenu",
      hierarchy: "hierarchy",
      selectedYearFilter: "selectedYearFilter",
    }),
  },
};
</script>

<style lang="scss">
.dropdown {
  .submenu {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &.menu .dropdown-first-item {
    padding: $global-padding * 0.7 $global-padding * 0.5;

    @include breakpoint(medium down) {
      padding: $global-padding * 0.7 0;
      transform: rotate(-90deg);
      transform-origin: center;
    }
  }

  .is-dropdown-submenu {
    background: $title-color;
  }
}
</style>
