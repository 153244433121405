<template>
	<div class="analysis-card grid-x">
		<div class="analysis-item small-5 grid-x align-middle">
			<div v-if="readOnly && mount" class="cell grid-x align-middle" v-cloak>
				<div class="cell small-12 medium-auto">
					<p
						:style="`${show ? '' : 'text-overflow: ellipsis; overflow:hidden; white-space:nowrap;'} ${focus ? 'background-color: #FFFF00;' : ''}`"
						class="read-only"
						:id="`text-collapsable-${index}`">
						<span class="count">{{order}}</span>{{taskDescription}}
					</p>
					<p v-if="subItemDescription.length > 0 && show" class="read-only" :style="`${show ? '' : 'text-overflow: ellipsis; overflow:hidden; white-space:nowrap;'} font-size: .75rem;`">
						<span class="count" style="color: transparent; user-select: none;">{{order}}</span>{{subItemDescription}}
					</p>

				</div>

				<div class="cell small-12 medium-shrink grid-x align-right action-column small-margin-collapse">
					<div class="cell category-dropdown">
						<div class="category-dropdown-control" :data-toggle="'category-dropdown-' + $vnode.key">
							<div class="category-dropdown-label text-center">
								{{category}}
							</div>
						</div>
						<div ref="dropdownContent" class="dropdown-pane category-dropdown-content" data-dropdown :id="'category-dropdown-' + $vnode.key"
							v-foundation:Dropdown.destroy
							data-auto-focus="true" data-close-on-click="true"
							data-position="top" data-alignment="right">
							<div v-for="category in taskItemCategoryListFormatted" :key="category.value"
								@click="updateCategory(category.label)">
								{{ category.label }}
							</div>
						</div>
						<div class="category-dropdown-control" style="margin: .5rem 0 0 0;" @click="updateFocus(studentId, selectedYear)">
							<div class="category-dropdown-label text-center">
								FOCUS
							</div>
						</div>
					</div>

					<button v-if="showButton || subItemDescription.length > 0" @click="() => {show = !show}" class="cell shrink button clear drag-handle">
						<font-awesome-icon :icon="show ? 'minus' : 'plus'"></font-awesome-icon>
					</button>

					<edit-elo-modal-analysis-card-remarks
						class="cell shrink"
						style="margin: 0 0 0 .5rem"
						:student-id="studentId"
						:student-task-item-id="studentTaskItemId"
						:has-remarks="hasRemarks"
					/>

					<button class="cell shrink button clear drag-handle">
						<font-awesome-icon icon="bars"></font-awesome-icon>
					</button>
				</div>
			</div>

			<textarea v-if="!readOnly" ref="textarea" rows="2" placeholder="Description" v-model="taskDescription" class="cell editable"></textarea>
			<textarea v-if="!readOnly" rows="2" placeholder="Sub-Item Description" v-model="subItemDescription" class="cell editable" style="margin: 1.5rem 0 0 0"></textarea>
			<div v-if="!readOnly" class="cell grid-x edit-buttons text-center align-middle">
				<button class="button primary small hollow" @click="addOrUpdateItem">
					{{ $t('editEloDetails.analysis.done') }}
				</button>
				<button class="button secondary small hollow" @click="cancel">
					{{ $t('editEloDetails.analysis.cancel') }}
				</button>
			</div>
		</div>

		<div class="small-6 grid-x">
			<div class="small-2 border-left grid-x align-top"
				:class="[readOnly ? 'assessment' : 'bgcolor--gray', week % 5 === 0 ? 'end-cycle' : '']"
				v-for="week in weekRange" :key="week"
				:data-toggle="'prompt-dropdown-' + $vnode.key + week">

				<div v-if="promptHierarchies" class="cell auto text-center prompt-assessment">
					<div :class="`cell auto text-center prompt-assessment ${promptHierarchies[week - 1] === 'I' ? 'color--success' : 'color--primary'}`">{{ promptHierarchies[week - 1] }}</div>
					<div
						@mouseover="setHover(true, week)"
						@mouseleave="setHover(false, week)"
					>
						<button
							class="button clear"
							:class="hasWeekRemarks.findIndex(el => el === week) > -1 ? 'color--medium-gray' : 'color--light-gray'"
							@click="() => openEditRemarkModal(week)"
						>
							<template v-if="hasWeekRemarks.findIndex(el => el === week) > -1">
								<font-awesome-icon :icon="['far','comment-dots']" />
							</template>
							<template v-else>
								<font-awesome-icon :icon="['far','comment']" />
							</template>
						</button>
						<div
							class="dropdown-pane has-alignment-center has-position-right is-open"
							v-if="modalValue[week]"
						>
							<div v-for="remarkObj in selectedWeek" :key="remarkObj.id" class="grid-x">
								<span>
									<p class="margin-bottom-0 remark" style="text-align: left;">{{ remarkObj.remark }}</p>
									<p class="margin-bottom-0 cell auto remark" style="font-size: .75rem; text-align: left;">{{ lastUpdated(remarkObj) }}</p>
								</span>
								<button class="button clear cell shrink margin-left-1" v-if="showDelete || remarkObj.remarkBy.fullName === profile.details.fullName" @click="deleteRemark(remarkObj)">
									<font-awesome-icon :icon="['far','trash-alt']" />
								</button>
							</div>
						</div>
					</div>
				</div>

				<div v-if="readOnly" class="dropdown-pane padding-0" :id="'prompt-dropdown-' + $vnode.key + week"
					v-foundation:Dropdown.destroy
					data-position="top" data-alignment="right"
					data-auto-focus="true" data-close-on-click="true">
					<div v-for="promptHierarchy in promptHierarchyOptions" :key="promptHierarchy.value"
						@click="updatePrompt(promptHierarchy.value, week)">
						<font-awesome-icon :class="'color--' + promptHierarchy.color" :icon="promptHierarchy.icon" size="1x"></font-awesome-icon>&nbsp;&nbsp;{{ $t(promptHierarchy.label) }}
					</div>
					<div @click="updatePrompt(null, week)" class="color--dark-gray">
						<font-awesome-icon :icon="['far','trash-alt']" size="1x"></font-awesome-icon>&nbsp;&nbsp;{{ $t('editEloDetails.analysis.removePrompt') }}
					</div>
				</div>
			</div>
		</div>

		<div class="small-1 prompt-assessment border-left text-center align-center grid-x align-top">
			<button class="cell auto small-12 medium-6 button clear color--medium-gray" @click="editItem">
				<font-awesome-icon v-if="readOnly" :icon="['far','edit']" size="1x"></font-awesome-icon>
			</button>
			<button class="cell auto small-12 medium-6 button clear color--medium-gray" @click="deleteItemConfirm">
				<font-awesome-icon v-if="readOnly" :icon="['far','trash-alt']" size="1x"></font-awesome-icon>
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import EditEloModalAnalysisCardRemarks from './EditEloModalAnalysisCardRemarks'
import EditEloModalAnalysisCardRemarksEdit from './EditEloModalAnalysisCardRemarksEdit'
import moment from 'moment'

export default {
	name: 'EditEloModalAnalysisCard',
	/* eslint-disable vue/no-unused-components */
	components: {
		FontAwesomeIcon,
		EditEloModalAnalysisCardRemarks,
		EditEloModalAnalysisCardRemarksEdit
	},
	/* eslint-enable vue/no-unused-components */
	props: {
		taskDescriptionText: String,
		category: String,
		readonly: Boolean,
		reverse: Boolean,
		eloCode: String,
		studentId: String,
		selectedYear: Number,
		studentTaskItemId: Number,
		promptHierarchies: {
			type: Array,
			default: () => []
		},
		weekRange: [Number, Array],
		order: Number,
		planTerm: Number,
		hasRemarks: Boolean,
		subItem: String,
		focus: Boolean,
		index: Number
	},
	data () {
		return {
			taskDescription: this.taskDescriptionText,
			subItemDescription: this.subItem || '',
			edit: false,
			show: false,
			showButton: false,
			weekRemarks: [],
			selectedWeek: [],
			hasWeekRemarks: [],
			modalValue: {1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false}
		}
	},
	computed: {
		...mapGetters({
			taskItemCategoryList: 'taskItemCategoryList',
			promptHierarchyList: 'promptHierarchyList',
			profile: 'profile',
			studentTaskItemRemarks: 'studentTaskItemRemarks'
		}),
		readOnly: function () {
			return this.readonly && !this.edit
		},
		taskItemCategoryListFormatted: function () {
			const reversedList = [ ...this.taskItemCategoryList ].reverse()
			return reversedList
		},
		promptHierarchyOptions: function () {
			const promptHierarchyOptions = [ ...this.promptHierarchyList ]
			for (let prompt of promptHierarchyOptions) {
				if (prompt.sign === '+') {
					prompt['icon'] = 'plus'
					prompt['color'] = 'success'
				} else {
					prompt['icon'] = 'minus'
					prompt['color'] = 'alert'
				}
			}

			return promptHierarchyOptions
		},
		showDelete () {
			const seniorTeacher = this.profile.roles.includes('ROLE_SENIOR_TEACHER')
			const leadTeacher = this.profile.roles.includes('ROLE_LEAD_TEACHER')
			const superTeacher = this.profile.roles.includes('ROLE_SUPER_TEACHER')
			return seniorTeacher || leadTeacher || superTeacher
		},
		/*
		mount () {
			if (this.studentTaskItemId) {
				this.weekRemarks = this.studentTaskItemRemarks(this.studentTaskItemId)
				if (!this.weekRemarks) {
					this.weekRemarks = []
				}
				if (this.weekRemarks) {
					const response = this.weekRemarks.map(el => el.week)
					this.hasWeekRemarks = [...new Set(response)]
					return true
				}
			}
			return false
		},
		*/
	},
	mounted () {
		this.mount();
		if (!this.readOnly) {
			this.textareaFocus()
		}
		this.checkWidthButton()
	},
	methods: {
		...mapActions([
			'updateTaskDescriptionInTaskItem',
			'updateCategoryInTaskItem',
			'addItemToTaskItemList',
			'removeItemFromTaskItemList',
			'updatePromptInTaskItem',
			'updateFocusInTaskItem',
			// 'getStudentTaskItemRemarks',
			'updateTaskItemListRemarks',
			'deleteStudentTaskItemRemarks'
		]),
		mount () {
			if (this.studentTaskItemId) {
				this.weekRemarks = this.studentTaskItemRemarks(this.studentTaskItemId)
				if (!this.weekRemarks) {
					this.weekRemarks = []
				}
				if (this.weekRemarks) {
					const response = this.weekRemarks.map(el => el.week)
					this.hasWeekRemarks = [...new Set(response)]
					return true
				}
			}
			return false
		},
		lastUpdated ({lastUpdated, remarkBy}) {
			const result = moment().format('DD MM YYYY') === moment(lastUpdated).format('DD MM YYYY') ? 'Today' : moment(lastUpdated).format('DD/MM/YYYY')
			const time = moment(lastUpdated).format('H:mm')
			return `${result} at ${time} by ${remarkBy.fullName}`
		},
		setHover (newValue, week) {
			this.selectedWeek = []
			this.modalValue[week] = newValue

			if (newValue) {
				this.weekRemarks.forEach(el => {
					if (el.week === week) this.selectedWeek.push(el)
				})
			}
			this.selectedWeek.sort((a, b) => {
				return a.id - b.id
			})
		},
		openEditRemarkModal (week) {
			this.$modal.show({
				component: EditEloModalAnalysisCardRemarksEdit,
				componentProps: {
					studentTaskItemId: this.studentTaskItemId,
					updateTaskItemRemarksFlag: this.updateTaskItemRemarksFlag,
					week
				}
			})
		},
		deleteRemark ({id}) {
			this.deleteStudentTaskItemRemarks({
				studentTaskItemId: `${this.studentTaskItemId}`,
				remarkId: id
			}).then((resp) => {
				if (resp) {
					const result = this.updateTaskItemRemarksFlag(false)
					if (result) {
						return this.$toastr.success(this.$t('editEloDetails.analysis.remarkModal.deleteSuccess'))
					}
				}
				this.$toastr.error(this.$t('error.unable.tryAgain'))
			})
		},
		async updateTaskItemRemarksFlag (flag) {
			return this.updateTaskItemListRemarks({
				studentId: this.studentId,
				studentTaskItemId: this.studentTaskItemId,
				hasRemarks: flag
			})
		},
		textareaFocus () {
			this.$nextTick(() => {
				this.$refs.textarea.focus()
			})
		},
		editItem () {
			this.edit = true
			this.textareaFocus()
		},
		addOrUpdateItem () {
			let studentId = this.studentId
			let planYear = this.selectedYear
			if (!this.edit) {
				this.addItem(studentId, planYear)
			} else {
				this.updateItem(studentId, planYear)
				this.edit = false
			}
		},
		addItem (studentId, planYear) {
			this.addItemToTaskItemList({
				studentId: studentId,
				eloCode: this.eloCode,
				planYear: planYear,
				planTerm: this.planTerm,
				taskDescription: this.taskDescription,
				subItemDescription: this.subItemDescription
			}).then(() => {
				this.$emit('delete-row')
				this.checkWidthButton()
			})
		},
		async updateItem (studentId, planYear) {
			await this.updateTaskDescriptionInTaskItem({
				studentId: studentId,
				eloCode: this.eloCode,
				planYear: planYear,
				planTerm: this.planTerm,
				studentTaskItemId: this.studentTaskItemId,
				taskDescription: this.taskDescription,
				subItemDescription: this.subItemDescription,
				focus: !this.focus
			})

			this.checkWidthButton()
		},
		async updateFocus (studentId, planYear) {
			await this.updateFocusInTaskItem({
				studentId,
				eloCode: this.eloCode,
				planYear: planYear,
				planTerm: this.planTerm,
				studentTaskItemId: this.studentTaskItemId,
				focus: !this.focus
			})

			this.checkWidthButton()
		},
		updateCategory (categoryLabel) {
			this.updateCategoryInTaskItem({
				studentId: this.studentId,
				eloCode: this.eloCode,
				planYear: this.selectedYear,
				planTerm: this.planTerm,
				studentTaskItemId: this.studentTaskItemId,
				category: categoryLabel
			}).then(() => {
				$(this.$refs.dropdownContent).foundation('close')
				this.checkWidthButton()
			})
		},
		cancel () {
			if (!this.edit) {
				this.$emit('delete-row')
			} else {
				this.edit = false
			}
			this.taskDescription = this.taskDescriptionText

			this.checkWidthButton()
		},
		deleteItemConfirm () {
			this.$modal.show({
				header: this.$t('editEloDetails.analysis.delete.modalTitle'),
				text: this.$t('editEloDetails.analysis.delete.modalText', { item: this.taskDescription }),
				includeConfirm: true,
				successCallback: this.deleteItem
			})
		},
		async deleteItem () {
			await this.removeItemFromTaskItemList({
				studentId: this.studentId,
				eloCode: this.eloCode,
				planYear: this.selectedYear,
				planTerm: this.planTerm,
				studentTaskItemId: this.studentTaskItemId
			})

			this.checkWidthButton()
		},
		async updatePrompt (promptHierarchyCode, week) {
			await this.updatePromptInTaskItem({
				studentId: this.studentId,
				eloCode: this.eloCode,
				planYear: this.selectedYear,
				planTerm: this.planTerm,
				planWeek: week,
				studentTaskItemId: this.studentTaskItemId,
				promptHierarchyCode: promptHierarchyCode
			})

			this.checkWidthButton()
		},
		checkWidthButton () {
			const element = document.getElementById(`text-collapsable-${this.index}`)
			if (element !== null) {
				this.showButton = element.offsetWidth < element.scrollWidth
			} else {
				this.showButton = false
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.remark {
	word-break: break-word;
}
.analysis-card {
	border-bottom: $card-border;

	.analysis-item {
		padding: $global-padding $global-padding $global-padding $global-padding / 2;
	}
}
.editable {
	margin: 0;
	resize: vertical;
}
.read-only {
	position: relative;
	padding-left: $global-padding * 1.5;
	margin-bottom: 0;
	white-space: pre-wrap;
	word-break: break-word;
	line-height: 1.4em;
}
.count {
	@extend .sans-serif-bold;
	position: absolute;
	top: 0;
	left: 0;
}
.border-left {
	border-left: $card-border;
}
.prompt-assessment {
	padding-top: $global-padding / 1.5;
	padding-bottom: $global-padding / 1.5;
}
.assessment {
	&.end-cycle {
		background: $bg-special;
	}
	&:hover {
		background: $bg-selected;
		cursor: pointer;
	}
}
.edit-buttons {
	button {
		margin-top: $global-margin / 2;
		margin-bottom: 0;
		padding: $global-padding / 3 $global-padding / 2;

		&:first-child {
			margin-right: $global-margin / 2;
		}
	}
}
.action-column {
	margin-left: $global-margin / 2;
}
.category-dropdown {
	margin-bottom: $global-margin / 3;

	.category-dropdown-control {
		.category-dropdown-label {
			@extend .small-selector-radio;
			@extend .tiny-form-field;
			cursor: pointer;

			&:hover {
				background: $bg-selected;
			}
		}
	}
	.category-dropdown-content {
		@extend .small-text
	}
}
.drag-handle {
	margin-left: $global-margin / 2;
	cursor: pointer;
}
</style>

