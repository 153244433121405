import Vue from 'vue'

export default {
	getStudentCoachingEngagement (engagementId) {
		return Vue.http.get(`studentCoachingEngagement/${engagementId}`)
	},
	updateStudentCoachingEngagement(id, studentCoachingEngagement) {
		return Vue.http.put('studentCoachingEngagement/' + id, studentCoachingEngagement)
	}
}
