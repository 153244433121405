<template>
  <div
    ref="domainListAccordion"
    class="domain-list accordion"
    data-accordion
    data-allow-all-closed="true"
    data-multi-expand="true"
    v-cloak
  >
    <slot></slot>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "DomainList",
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
      domainsOpen: "domainsOpen",
    }),
  },
  data() {
    return {
      domainsOpenCopy: null,
    };
  },
  props: {
    retainElos: {
      type: Function,
    },
  },
  mounted() {
    this.accordion = new Foundation.Accordion($(this.$el));

    $(this.$el).on("down.zf.accordion", this.addDomainToOpen);
    $(this.$el).on("up.zf.accordion", this.removeDomainFromOpen);
  },
  updated() {
    if (!_.isEqual(this.domainsOpenCopy, this.domainsOpen)) {
      this.domainsOpenCopy = this.domainsOpen;

      this.domainsOpen.forEach((elementId) => {
        // For performance
        this.$nextTick(() => {
          setTimeout(() => {
            $(this.$el).foundation(
              "down",
              $("#" + elementId).find(".domain-list-body")
            );
          });
        });
      });
    }
    Foundation.reInit($(this.$el));
  },
  destroyed() {
    $(this.$el).off("down.zf.accordion", this.addDomainToOpen);
    $(this.$el).off("up.zf.accordion", this.removeDomainFromOpen);
  },
  methods: {
    openAccordionFor(domainId, accordionContentTarget) {
      $(this.$refs.domainListAccordion).foundation(
        "down",
        $(accordionContentTarget)
      );
    },
    addDomainToOpen(event, element) {
      let parentId = $(element).parent().attr("id");
      parentId && this.$store.dispatch("addDomainToOpen", parentId);
    },
    removeDomainFromOpen(event, element) {
      let parentId = $(element).parent().attr("id");
      parentId && this.$store.dispatch("removeDomainFromOpen", parentId);
    },
  },
};
</script>

<style lang="scss" scoped>
.domain-list {
  background-color: $light-gray;
  border: 1px solid $light-gray;
  border-radius: 10px;

  .programme-plan & {
    border-color: transparentize($brand-tertiary, 0.7);
  }
}
.add-elo-button {
  color: $white;
  font-size: 1rem;
}
</style>
