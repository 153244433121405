<template>
  <div
    id="elo-side-tray"
    :class="[selectedPlan]"
    class="elo-side-tray off-canvas position-left radius grid-y"
    v-foundation:OffCanvas
    data-close-on-click="false"
    v-cloak
  >
    <div class="elo-side-tray-header">
      <h6 class="margin-1">{{ getTitle() }}</h6>
      <!-- Close button -->
      <button
        class="close-button color--medium-gray small"
        aria-label="Close menu"
        type="button"
        data-close
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="padding-left-1 padding-right-1">
      <input
        type="text"
        class="search-elo"
        :placeholder="$t('sideTray.search')"
        v-model="searchTerm"
      />
    </div>
    <!-- Your menu or Off-canvas content goes here -->
    <elo-side-tray-domain-list-card
      :domain-id="domain.id"
      :text-id="domain.domainId"
      :text-name="domain.domainName"
      :details="domain.details"
      :retain-elos="eloFilter()"
    >
    </elo-side-tray-domain-list-card>

    <div class="action-selector padding-left-1 padding-right-1">
      <div class="grid-x align-right">
        <button
          type="submit"
          class="align-right button primary"
          @click="addToPlan()"
          :disabled="elosToAdd.length === 0"
        >
          {{ $t("sideTray.done") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import EloSideTrayDomainListCard from "./EloSideTrayDomainListCard";
import constants from "@/constants";

import { mapGetters } from "vuex";

export default {
  name: "EloSideTray",
  components: {
    EloSideTrayDomainListCard,
  },
  data() {
    return {
      componentType: "EloSideTray",
      title: "Add ELOs from Programme",
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      years: "years",
      domain: "domain",
      elosToAdd: "elosToAdd",
    }),
    selectedPlan: function () {
      if (this.$route.name === "Piep") {
        return "elo-prog";
      } else if (
        this.$route.name === "Aiep" ||
        this.$route.name === "Aiep0" ||
        this.$route.name === "Aiep1" ||
        this.$route.name === "Aiep3"
      ) {
        return "elo-piep";
      } else if (this.$route.name === "LessonPlan") {
        return "elo-aiep";
      }

      return "";
    },
  },
  mounted() {
    $(this.$el).on("closed.zf.offcanvas", this.clearHandle);
  },
  destroyed() {
    $(this.$el).off("closed.zf.offcanvas", this.clearHandle);
  },
  methods: {
    getTitle() {
      if (this.$route.name === "Piep") {
        return "Add ELOs from Programme";
      } else if (
        this.$route.name === "Aiep" ||
        this.$route.name === "Aiep0" ||
        this.$route.name === "Aiep1" ||
        this.$route.name === "Aiep3"
      ) {
        return "Add ELOs from P-IEP";
      } else if (this.$route.name === "LessonPlan") {
        return "Add Student ELOs planned for this term";
      }
    },
    addToPlan() {
      if (this.$route.name === "Piep") {
        return this.addToProgrammePlan();
      } else if (
        this.$route.name === "Aiep" ||
        this.$route.name === "Aiep0" ||
        this.$route.name === "Aiep1" ||
        this.$route.name === "Aiep3"
      ) {
        return this.addToAnnualPlan();
      } else if (this.$route.name === "LessonPlan") {
        return this.addToLessonPlan();
      }
    },
    addToLessonPlan() {
      this.$modal.show({
        header: this.$t("lessonPlan.sideTray.modalTitle"),
        text: this.$t("lessonPlan.sideTray.modalText"),
        includeConfirm: true,
        successCallback: this.addToLessonPlanSuccessCallback,
      });
    },
    addToAnnualPlan() {
      this.$modal.show({
        header: this.$t("annualPlan.sideTray.modalTitle"),
        text: this.$t("annualPlan.sideTray.modalText"),
        includeConfirm: true,
        successCallback: this.addToAnnualSuccessCallback,
      });
    },
    addToProgrammePlan() {
      this.$modal.show({
        header: this.$t("programmePlan.sideTray.modalTitle"),
        text: this.$t("programmePlan.sideTray.modalText"),
        includeConfirm: true,
        successCallback: this.addToProgrammeSuccessCallback,
      });
    },
    addToLessonPlanSuccessCallback() {
      this.$store
        .dispatch("addElosToLessonPlan", {
          lessonId: this.$route.params.lessonId,
        })
        .then(() => {
          this.clearHandle();
        });
    },
    addToProgrammeSuccessCallback() {
      this.$store
        .dispatch("addEloToProgrammePlan", {
          studentId: this.$route.params.studentId,
          selectedYear: this.$route.params.year,
        })
        .then(() => {
          this.scrollToNew(".is-new-elo", 0, 50);
          this.clearHandle();
        });
    },
    addToAnnualSuccessCallback() {
      this.$store
        .dispatch("addEloToAnnualPlan", {
          studentId: this.$route.params.studentId,
          planYear: this.$route.params.year,
        })
        .then(() => {
          this.scrollToNew(".is-new-elo", 0, 115);
          this.clearHandle();
          // front-end has no knowledge of existence of annual plan. Therefore status to always fetch when addElo is called
          this.$store.dispatch("getStudentAnnualPlanStatus", {
            studentId: this.$route.params.studentId,
            planYear: this.$route.params.year,
          });
        });
    },
    scrollToNew(className, threshold, offset) {
      Foundation.SmoothScroll.scrollToLoc($(className), {
        threshold: threshold || 0,
        offset: offset || 0,
      });
    },
    clearHandle() {
      this.clearEloCodes();
      this.clearSearchTerm();
      $(this.$el).foundation("close");
    },
    clearEloCodes() {
      this.$store.dispatch("clearEloCodes");
    },
    clearSearchTerm() {
      this.searchTerm = "";
    },
    searchFilter(elo) {
      return (
        elo.eloCode.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        elo.eloName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    strandFilter(elo) {
      let matchString = "strand:";
      let searchString = this.searchTerm.toLowerCase();
      if (elo.strands && searchString.indexOf(matchString) > -1) {
        let strandNumber = searchString.replace(matchString, "").trim();
        return strandNumber
          ? elo.strands.indexOf(strandNumber) > -1
          : elo.strands.length > 0;
      }
    },
    filters(elo) {
      return this.searchFilter(elo) || this.strandFilter(elo);
    },
    eloFilter() {
      if (this.$route.name === "Piep") {
        let studentDetails =
          this.$store.getters.studentPlan[this.$route.params.studentId];
        let programmeElos =
          studentDetails && studentDetails.studentProgrammeElos
            ? studentDetails.studentProgrammeElos
            : [];
        return (elos) => {
          let idlist = _.map(programmeElos, "elo.id");
          return _.filter(elos, (elo) => {
            return !_.includes(idlist, elo.id) && this.filters(elo);
          });
        };
      } else if (
        this.$route.name === "Aiep" ||
        this.$route.name === "Aiep0" ||
        this.$route.name === "Aiep1" ||
        this.$route.name === "Aiep3"
      ) {
        let studentDetails =
          this.$store.getters.studentPlan[this.$route.params.studentId];
        let programmeElos =
          studentDetails && studentDetails.studentProgrammeElos
            ? studentDetails.studentProgrammeElos
            : [];
        let filteredProgrammeElos = _.filter(programmeElos, (elo) => {
          return elo.eloStatus === constants.ELO_STATUS.ACTIVE;
        });
        let studentAnnualDetails =
          this.$store.getters.studentAnnualPlan[this.$route.params.studentId];
        let annualPlanElos =
          studentAnnualDetails &&
          studentAnnualDetails[this.$route.params.year] &&
          studentAnnualDetails[this.$route.params.year].studentElos
            ? _.map(
                studentAnnualDetails[this.$route.params.year].studentElos,
                "elo"
              )
            : [];
        return (elos) => {
          let programmeIdlist = _.map(filteredProgrammeElos, "elo.id");
          let annualIdlist = _.map(annualPlanElos, "id");
          return _.filter(elos, (elo) => {
            return (
              _.includes(programmeIdlist, elo.id) &&
              !_.includes(annualIdlist, elo.id) &&
              this.filters(elo)
            );
          });
        };
      } else if (this.$route.name === "LessonPlan") {
        let selectedTerm = this.$route.params.term;
        let plannedTermElos = this.$store.getters.studentElosForTerm(
          this.$route.params.classId,
          selectedTerm
        )
          ? this.$store.getters.studentElosForTerm(
              this.$route.params.classId,
              selectedTerm
            )
          : [];
        let lessonElos = this.$store.getters.lessonElos(
          this.$route.params.lessonId
        )
          ? this.$store.getters.lessonElos(this.$route.params.lessonId)
          : [];
        let idlist = _(plannedTermElos).flatMap("elo").map("id").uniq().value();
        let lessonEloIdList = _(lessonElos)
          .flatMap("elo")
          .map("id")
          .uniq()
          .value();
        return (elos) => {
          return _.filter(elos, (elo) => {
            return (
              _.includes(idlist, elo.id) &&
              !_.includes(lessonEloIdList, elo.id) &&
              this.filters(elo)
            );
          });
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.elo-side-tray {
  background-color: $white;
  margin-top: $global-margin / 2;
  border: 2px solid $black;
  height: 98%;
  width: $elo-side-tray-width;
  z-index: $sidetray-zindex;

  &.position-left:not(.is-open) {
    transform: translateX(-#{$elo-side-tray-width});
  }

  .domain-list-card.card {
    overflow-y: auto;
    border-radius: 0;
  }

  .action-selector {
    padding-top: $global-padding / 1.5;
    width: 100%;

    .button {
      margin-bottom: $global-margin / 1.5;
      font-size: $small-font-size * 1.05;
    }
  }
}

.search-elo {
  @extend .sans-serif-regular;
  margin-bottom: $global-margin / 1.5;
  padding-top: $global-padding / 2.5;
  padding-bottom: $global-padding / 2.5;
  height: auto;
  font-size: $small-font-size;
}
</style>
