<template>
  <div class="the-toastr position-fixed" v-cloak>
    <div
      class="callout"
      :class="['bgcolor--' + toast.type, toast.classId]"
      data-closable="scale-out-down fast"
      data-close
      v-for="toast in toastr"
      :key="toast.key"
    >
      <p class="margin-bottom-0">
        {{ toast.translate ? $t(toast.message) : toast.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheToastr",
  computed: {
    ...mapGetters({
      toastr: "toastr",
    }),
  },
};
</script>

<style lang="scss" scoped>
.the-toastr {
  right: 1em;
  bottom: 1em;
  z-index: $toastr-zindex;

  .bgcolor--success,
  .bgcolor--alert {
    color: $white;
  }
  .bgcolor--warning {
    color: $black;
  }
}
</style>
