<template>
  <div>
    <div class="cell small-12 grid-x text-right margin-bottom-1">
      <div class="cell auto no-print">
        <button class="button clear" @click="createNewStudent()">
          <font-awesome-icon icon="user-plus" size="lg" />
          <b>{{ classGroup.coachingEngagement ? $t("classGroupEdit.create.student.coaching") : $t("classGroupEdit.create.student") }}</b>
        </button>
        <button class="button clear" @click="assignStudents()">
          <font-awesome-icon icon="child" size="lg" />
          <b>{{
            classGroup.coachingEngagement ? $t("classGroupEdit.assign.students.coaching", { title: classGroup.name }) : $t("classGroupEdit.assign.students", { title: classGroup.name })
          }}</b>
        </button>
      </div>
    </div>
    <class-group-edit-list
      v-if="classGroup && classGroup.students"
      :list="classGroup.students"
      :update-function="updateStudent"
      :remove-function="showDeleteConfirmation"
      :classGroup="classGroup"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ClassGroupEditList from "./ClassGroupEditList";
import StudentModal from "@/views/StudentModal/StudentModal";
import moment from "moment";

export default {
  name: "ClassGroupEditStudents",
  components: {
    FontAwesomeIcon,
    ClassGroupEditList,
  },
  props: {
    classGroup: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createNewStudent() {
      let modalParams = {
        component: StudentModal,
        componentProps: {
          classId: this.classGroup.id,
        },
        class: "small",
      };

      this.$modal.show(modalParams);
    },
    updateStudent(studentId) {
      const student =
        this.classGroup &&
        this.classGroup.students &&
        this.classGroup.students.find((it) => it.id === studentId);
      const {
        fullName,
        displayName,
        gender,
        dateOfBirth,
        conditionTypes,
        additionalSupportNeed,
        behaviourSupportNeed,
        learningSupportNeed,
      } = student;
      const yearOfBirth = moment(dateOfBirth).year();

      let modalParams = {
        component: StudentModal,
        componentProps: {
          classId: this.classGroup.id,
          studentId,
          fullName,
          displayName,
          gender,
          yearOfBirth,
          conditionTypes,
          additionalSupportNeed,
          behaviourSupportNeed,
          learningSupportNeed,
        },
        class: "small",
      };

      this.$modal.show(modalParams);
    },
    assignStudents() {
      $("#class-group-edit-side-tray").foundation("open");
    },
    refreshList() {
      this.$emit("refresh-list");
    },
    ...mapActions(["removeStudentFromClassGroup"]),
    showDeleteConfirmation(studentId, displayName) {
      let executeDelete = () => {
        this.removeStudentFromClassGroupCallback(studentId, displayName);
      };

      this.$modal.show({
        header: this.$t("classGroupEdit.listCard.student.remove.modalTitle", {
          studentName: displayName,
          className: this.classGroup.name,
        }),
        text: this.$t("classGroupEdit.listCard.student.remove.modalText", {
          studentName: displayName,
          className: this.classGroup.name,
          year: this.classGroup.year,
        }),
        includeConfirm: true,
        successCallback: executeDelete,
      });
    },
    removeStudentFromClassGroupCallback(studentId, displayName) {
      // execute removal
      this.removeStudentFromClassGroup({
        classId: this.classGroup.id,
        studentId: studentId,
      }).then(() => {
        this.refreshList();
        this.$toastr.success(
          this.$t("classGroupEdit.student.success.removed", {
            studentName: displayName,
            className: this.classGroup.name,
          })
        );
        this.$modal.closeAll();
      });
    },
  },
};
</script>
