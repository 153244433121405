<template>
  <div class="container-wrapper" v-cloak>
    <div class="grid-x align-middle align-justify">
      <h1 class="page-title">{{ $t("school.title") }}</h1>
      <button class="button clear" @click="createModal">
        <font-awesome-icon icon="plus"></font-awesome-icon>
        &nbsp;<b>{{ $t("school.create") }}</b>
      </button>
    </div>

    <section>
      <div v-for="school in schools" class="card" :key="`school-${school.id}`">
        <div class="card-section card-padding">
          <h5>{{ school.name }}</h5>
          <p class="color--brand-primary">
            {{ $t("school.code", { code: school.code }) }}
          </p>
          <more-dropdown
            :options="dropdownOptions"
            :identifier="`school-${school.id}`"
          ></more-dropdown>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MoreDropdown from "@/components/MoreDropdown";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SchoolEditor from "./SchoolEditor.vue";

export default {
  name: "Schools",
  components: {
    MoreDropdown,
    FontAwesomeIcon,
  },
  data() {
    return {
      dropdownOptions: [
        {
          label: "school.edit",
          method: this.editModal,
          type: "CLICK",
        },
        {
          label: "school.delete",
          method: this.deleteModal,
          type: "CLICK",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["schools"]),
  },
  created() {
    this.getSchools();
  },
  methods: {
    ...mapActions(["getSchools", "deleteSchool"]),
    getTargetSchoolFromElement(e) {
      const schoolId = e.target.parentElement.id.split("-").at("-1");
      return this.schools[schoolId];
    },
    createModal() {
      const componentProps = {
        isEditing: false,
      };

      this.$modal.show({
        component: SchoolEditor,
        componentProps: componentProps,
        class: "large",
      });
    },
    editModal(e) {
      const school = this.getTargetSchoolFromElement(e);
      const componentProps = {
        isEditing: true,
        school,
      };

      this.$modal.show({
        component: SchoolEditor,
        componentProps: componentProps,
        class: "large",
      });
    },
    deleteModal(e) {
      const school = this.getTargetSchoolFromElement(e);

      this.$modal.show({
        header: this.$t("school.delete.modalTitle", {
          school: school.name,
        }),
        text: this.$t("school.delete.modalText", {
          school: school.name,
        }),
        includeConfirm: true,
        successCallback: () => this.deleteSchool(school),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
}
</style>