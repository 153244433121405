<template>
  <div class="filter-dropdown-control">
    <div
      @click="toggleDropdown()"
      class="control grid-y text-center"
      :class="{ active: showDropdown }"
    >
      <div class="cell">
        {{ filterLabel }}
        <font-awesome-icon v-if="showDropdown" icon="times" size="sm" />
        <font-awesome-icon v-else icon="angle-down" size="sm" />
      </div>
    </div>

    <div v-show="showDropdown" class="dropdown">
      <div class="grid-x year-school-programme-dropdown">
        <div class="small-12 medium-4 margin-right-1 cell">
          <select v-model="searchFilter.year" @change="executeSearch()">
            <option v-for="year in yearOptions" :value="year" :key="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="small-12 medium-auto cell">
          <select v-model="searchFilter.schoolId" @change="executeSearch()">
            <option
              v-for="school in schoolOptions"
              :value="school.id"
              :key="school.id"
            >
              {{ school.name }}
            </option>
          </select>
        </div>
        <div class="small-12 medium-12 cell">
          <select
            v-model="searchFilter.programmeName"
            @change="executeSearch()"
          >
            <option
              v-for="programme in programmeOptions"
              :value="programme.name"
              :key="programme.name"
            >
              {{ programme.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="grid-x classes-dropdown">
        <div class="small-12 cell">
          <!-- TODO: better looking dropdown component for multi-select -->
          <class-group-filter-dropdown
            v-model="selectedClassIds"
            @arr-updated="filterResults()"
            :classGroups="classGroupResults"
          />
        </div>
      </div>
      <div class="grid-x selected-classes">
        <div class="small-12 cell">
          <h6 class="selected-classes-header">
            {{ $t("sideTray.search.selectedClasses") }}:
          </h6>
        </div>
        <div class="grid-x selected-classes-values">
          <div
            class="cell shrink selected-classes-value"
            v-for="classGroup in selectedClassGroups"
            :key="classGroup.id"
          >
            {{ classGroup.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassGroupFilterDropdown from "./ClassGroupFilterDropdown";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import _ from "lodash";
import { mapActions, mapState } from "vuex";

export default {
  name: "FilterDropdown",
  components: {
    ClassGroupFilterDropdown,
    FontAwesomeIcon,
  },
  data() {
    return {
      showDropdown: false,
      searchFilter: {
        year: this.year,
        schoolId: this.schoolId,
        programmeName: this.programmeName,
      },
      selectedClassIds: this.initClassGroup ? [this.initClassGroup.id] : [],
      yearVariance: 4,
    };
  },
  props: {
    year: {
      type: Number,
      default: undefined,
    },
    schoolId: {
      type: Number,
      default: undefined,
    },
    programmeName: {
      type: String,
      default: undefined,
    },
    initClassGroup: {
      type: Object,
      default: undefined,
    },
  },
  created() {
    this.getSchools();
    this.getClassGroupsForParams({
      year: this.year,
      schoolId: this.schoolId,
      programmeName: this.programmeName,
    }).then(() => {
      this.filterResults([this.initClassGroup.id]);
    });
  },
  computed: {
    filterLabel() {
      return this.showDropdown
        ? this.$t("sideTray.filters.hide")
        : this.$t("sideTray.filters.show");
    },
    yearOptions() {
      // const initYear = this.year === 'All Years' ? this.initYear : this.year
      const startYear = this.year - 4;
      const endYear = this.year + 4;

      let options = ["All Years"];
      for (let i = startYear; i <= endYear; i++) {
        options.push(i);
      }
      return options;
    },
    ...mapState({
      schools: (state) => state.school.schools,
      programmes: (state) => state.school.programmes,
      classGroupResults: (state) => state.classGroupSearch.classGroups,
    }),
    schoolOptions() {
      return {
        0: { name: "All Branches", id: 0 },
        ...this.schools,
      };
    },
    programmeOptions() {
      // retrieve all programmes from all schools, and return unique values only
      let programmes = _.uniqBy(
        _.flatten(Object.values(this.programmes)),
        "name"
      );
      // omit 'id' field
      programmes = _.transform(
        programmes,
        (result, prog) => {
          result.push(_.omit(prog, ["id"]));
        },
        []
      );

      programmes.sort((a, b) => {
        return a.name - b.name;
      });

      const all = { name: "All Programmes" };

      return [all, ...programmes];
    },
    selectedClassGroups() {
      return _.pick(this.classGroupResults, this.selectedClassIds);
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    ...mapActions(["getSchools", "getClassGroupsForParams"]),
    executeSearch() {
      this.clearSelectedClassIds();
      this.fetchClassGroupResults();
    },
    fetchClassGroupResults() {
      const { year, schoolId, programmeName } = this.searchFilter;

      let queryParams = this.generateQueryParams({
        year,
        schoolId,
        programmeName,
      });

      this.getClassGroupsForParams(queryParams).then(() => {
        this.selectAllClassIds();
      });
    },
    generateQueryParams(params) {
      let queryParams = { ...params };
      if (params.year === "All Years") {
        delete queryParams["year"];
      }

      if (params.schoolId === 0) {
        delete queryParams["schoolId"];
      }

      if (params.programmeName === "All Programmes") {
        delete queryParams["programmeName"];
      }

      return queryParams;
    },
    clearSelectedClassIds() {
      this.selectedClassIds = [];
      this.filterResults();
    },
    selectAllClassIds() {
      this.selectedClassIds = this.$store.state.classGroupSearch.classGroupIds;
      this.filterResults();
    },
    filterResults() {
      this.$emit("filter-results", this.selectedClassIds);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dropdown-control {
  position: relative;

  .control {
    position: absolute;
    left: $elo-side-tray-width - $global-padding;
    bottom: $global-margin / 1.5;
    padding: $global-padding/2;

    @extend .small-header;
    color: $darker-gray;

    cursor: pointer;
    border: 1px solid $darker-gray;
    border-radius: 6px;
    background-color: $white;
    border-color: $gray;
    box-shadow: 0px 1px 0px 0px $gray;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.15;

    &:hover {
      color: $black;
    }

    &.active {
      color: $primary;
    }
  }

  .dropdown {
    width: $elo-side-tray-width;

    @include breakpoint(medium) {
      left: $elo-side-tray-width - $global-padding;
      width: 55vw;
    }

    @include breakpoint(large) {
      width: 45vw;
    }

    margin-top: $global-margin / 2.5;
    position: absolute;
    top: -$global-margin;
    z-index: 10;

    padding: $global-padding / 2;
    color: $black;
    border: 1px solid $secondary-color;
    border-radius: $global-radius;
    background-color: $white;
  }
}

select {
  @extend .small-form-field;
  margin-bottom: $global-margin / 1.5;
}
.selected-classes {
  margin-top: $global-margin / 2;
  @extend .small-padding;
  border: 1px solid $secondary-color;
  border-radius: $global-radius;
  background-color: $light-gray;

  .selected-classes-header {
    @extend .small-header;
    margin-top: $global-margin / 5;
  }
  .selected-classes-value {
    @extend .small-padding;
    @extend .small-header;
    margin-right: $global-margin / 3.5;
    margin-bottom: $global-margin / 2.5;
    color: $black;
    border: 1px solid $secondary-color;
    border-radius: $global-radius;
    background-color: $bg-selected;
    line-height: 1;
    text-align: center;
  }
}

// .small-form-field {
// 	@extend .small-text;
// 	margin-bottom: $global-margin / 1.5;
// 	padding-top: $global-padding / 2.5;
// 	padding-bottom: $global-padding / 2.5;
// 	height: auto;
// }
// .small-text {
// 	@extend .sans-serif-regular;
// 	font-size: $small-font-size;
// }
// .small-header {
// 	@extend .sans-serif-bold;
// 	font-size: $small-font-size;
// }
// .small-padding {
// 	padding-top: $global-padding / 3.5;
// 	padding-bottom: $global-padding / 3.5;
// 	padding-left: $global-padding / 3.5;
// 	padding-right: $global-padding / 3.5;
// 	height: auto;
// }
</style>
