import _ from 'lodash'
import * as types from '../mutation-types'
import sideMenuApi from '@/api/sideMenu'
// import store from '@/store'
// initial state
const state = {
	sideMenu: [],
	allowedFunctions: [],
	hierarchy: [],
	currentYear: null,
	selectedYearFilter: null,
	selectedItemClass: null,
	programmeList: []
};

// getters
const getters = {
	sideMenu(state) {
		return state.sideMenu
	},
	allowedFunctions(state, getters) {
		let navigationList = [{
			functionalGroup: 'classes',
			target: 'ClassGroup',
			label: 'navigation.students'
		}, {
			functionalGroup: 'classes',
			target: 'Master',
			label: 'navigation.master'
		}, {
			functionalGroup: 'lessons',
			target: 'LessonPlanList',
			label: 'navigation.lessons'
		}, {
			functionalGroup: 'reports',
			target: 'Reports',
			label: 'navigation.reports'
		}, {
			functionalGroup: 'dashboards',
			target: 'MasterReport',
			label: 'navigation.realMaster'
		}, {
			functionalGroup: 'users',
			target: 'Users',
			label: 'navigation.users'
		}, {
			functionalGroup: 'schools',
			target: 'Schools',
			label: 'navigation.schools'
		},{
			functionalGroup: 'tenants', 
			target: 'Tenants',
			label: 'navigation.tenants'
		}, {
			functionalGroup: 'curriculum',
			target: 'Curriculum',
			label: 'navigation.curriculum'
		}, {
			functionalGroup: 'curriculum',
			target: 'CurriculumInterface',
			label: 'navigation.curriculumInterface'
		}, {
			functionalGroup: 'client',
			target: 'ClassGroup',
			label: 'navigation.client'
		}]

		// const nameList = [
		// 	'LoiWeiMing@rainbowcentre.org.sg',
		// 	// 'annekingsley@rainbowcentre.org.sg',
		// 	// 'jarinapeer@rainbowcentre.org.sg',
		// 	// 'Fauziah@rainbowcentre.org.sg',
		// 	// 'jessicawee@rainbowcentre.org.sg',
		// 	// 'HelenLUI@rainbowcentre.org.sg',
		// 	// 'josh@rainbowcentre.org.sg',
		// 	'michelleong@rainbowcentre.org.sg',
		// 	'Michelle ONG Sim Hwee',
		// 	'joe18lg@gmail.com',
		// 	'lynettegomez@rainbowcentre.org.sg',
		// 	'pearlyne@rainbowcentre.org.sg',
		// 	'quincytan@rainbowcentre.org.sg',
		// 	'snowflake.ocms@gmail.com',
		// 	'ocms.admn@gmail.com'
		// ]
		// if (!nameList.includes(getters.profile.username)) {
		// 	navigationList.splice(4, 1)
		// }

		return _.remove(navigationList, function (it) {
			return _.indexOf(state.allowedFunctions, it.functionalGroup) >= 0;
		});
	},
	hierarchy(state) {
		return state.hierarchy
	},
	currentYear(state) {
		return state.currentYear
	},
	selectedYearFilter(state) {
		return state.selectedYearFilter
	},
	programmes(state) {
		let programmes = _.cloneDeep(state.sideMenu)
		for (let i = 0; i < state.hierarchy.length - 1; i++) {
			let flatMap = _.flatMap(programmes, state.hierarchy[i]);
			if (flatMap[0]) {
				programmes = flatMap;
			}
			if (state.hierarchy[i] === 'programmes') break;
		}
		return programmes;
	},
	classes(state) {
		let classes = _.cloneDeep(state.sideMenu)
		for (let i = 0; i < state.hierarchy.length - 1; i++) {
			let flatMap = _.flatMap(classes, state.hierarchy[i]);
			if (flatMap[0]) {
				classes = flatMap;
			}
		}
		return classes;
	},
	filteredClasses(state, getters) {
		let classes = _.cloneDeep(getters.classes)
		return classes && Object.keys(classes).length > 0 ? classes.filter(it => it.year === getters.selectedYearFilter) : []
	},
	isChildClassSelected: (state) => (identifier) => {
		if (!state.selectedItemClass) return false;
		let idArr = identifier.split('-');
		let selectedArr = state.selectedItemClass.split('-');
		let isSelected = true;
		idArr.some(it => {
			if (selectedArr.indexOf(it) === -1) {
				isSelected = false;
				return true;
			}
		});
		return isSelected;
	},
	isSelectedItemClass: (state) => (identifier) => {
		return state.selectedItemClass === identifier;
	},
	programmeList(state) {
		return state.programmeList
	}
};

// actions
const actions = {
	getCurrentYear({ commit }) {
		return sideMenuApi.getCurrentYear().then(resp => {
			commit(types.SET_CURRENT_YEAR, resp.data.currentYear);
		});
	},
	getSideMenu({ commit }, selectedYear) {
		return sideMenuApi.getSideMenu(selectedYear).then(resp => {
			let data = resp.data || {};
			data.list && data.hierarchy && setIdentifier(data.list, data.hierarchy, 0);

			const schoolArray = [];
			data.programmeList.forEach(el => {
				if (!schoolArray.some(school => school.name === el.schoolName)) {
					schoolArray.push({ name: el.schoolName, programmes: [{ name: el.name, classes: el.classes }] })
				} else {
					const schoolIndex = schoolArray.findIndex(schoolName => schoolName.name === el.schoolName);
					schoolArray[schoolIndex].programmes.push({ name: el.name, classes: el.classes });
				}
			});

			schoolArray.sort((a, b) => {
				let fa = a.name.toLowerCase();
				let fb = b.name.toLowerCase();

				if (fa < fb) {
					return -1;
				}
				if (fa > fb) {
					return 1;
				}
				return 0;
			});

			schoolArray.forEach(el => {
				el.programmes.sort((a, b) => {
					let fa = a.name.toLowerCase();
					let fb = b.name.toLowerCase();

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0
				})
			})
            
			commit(types.SET_SIDE_MENU, data.list)
			commit(types.SET_HIERARCHY, data.hierarchy)
			commit(types.SET_ALLOWED_FUNCTIONS, data.functions)
			commit('SET_PROGRAMME_LIST', schoolArray)
		})
	},
    getSideMenuHyber({commit}) {
        console.log("Setting Hyper role")
		let data = { "list": null, "programmeList": [], "hierarchy": null, "authority": ["ROLE_HYPER"], "functions": null }
        data.list && data.hierarchy && setIdentifier(data.list, data.hierarchy, 0)
        commit(types.SET_SIDE_MENU, [])
        commit(types.SET_HIERARCHY, [])
        commit(types.SET_ALLOWED_FUNCTIONS, ["tenants"])
        commit('SET_PROGRAMME_LIST', [])
	},
	setSelectedItemClass({ commit }, identifier) {
		commit(types.SET_SELECTED_ITEM, identifier)
	},
	setSelectedYearFilter({ commit }, year) {
		commit(types.SET_SELECTED_YEAR_FILTER, year)
	}
};

// mutations
const mutations = {
	[types.SET_SIDE_MENU](state, sideMenu) {
		state.sideMenu = sideMenu
	},
	[types.SET_HIERARCHY](state, hierarchy) {
		state.hierarchy = hierarchy
	},
	[types.SET_CURRENT_YEAR](state, currentYear) {
		state.currentYear = currentYear
	},
	[types.SET_ALLOWED_FUNCTIONS](state, allowedFunctions) {
        state.allowedFunctions = allowedFunctions
	},
	[types.SET_SELECTED_YEAR_FILTER](state, year) {
		state.selectedYearFilter = year
	},
	[types.SET_SELECTED_ITEM](state, className) {
		state.selectedItemClass = className
	},
	SET_PROGRAMME_LIST(state, programmeList) {
		state.programmeList = programmeList
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};

let setIdentifier = (item, hierarchy, hierarchyIndex, parentIdentifier) => {
	item.map(it => {
		it.isOpen = false;
		it.identifier = (parentIdentifier ? parentIdentifier + '-' : '') + hierarchy[hierarchyIndex] + '_' + (it.id || it.code);
		delete it.image; // remove student image
		Object.keys(it).map(key => {
			if (hierarchy.indexOf(key) > -1) {
				setIdentifier(it[key], hierarchy, hierarchyIndex + 1, it.identifier);
			}
		});
	});
};
