<template>
	<div v-if="!isLoading && isAuthorized" class="profile-wrapper" v-cloak>
		<!--
		<p>CoachingEngagement.vue</p>

		<p> StudentCoachingEngagementId: {{ studentCoachingEngagementId }}</p>
		<p> StudentCoachingEngagement: {{ studentCoachingEngagement }}</p>
		<p> Year: {{ year }}</p>
		<p> StudentId: {{ studentId }}</p>
		<p> ClassId: {{ classId }}</p>
		<p> currentCoachingEngagement.title: {{ currentCoachingEngagement.title }}</p>
		<p> currentCoachingEngagement.engagementDate: {{ currentCoachingEngagement.engagementDate }}</p>
		<p> currentCoachingEngagement.completed: {{ currentCoachingEngagement.completed }}</p>
		<p> ActiveGoal: {{ activeGoal }}</p>
		<p v-if="activeGoal"> ihvi activeGoal on true</p>
	-->

	  <coaching-engagement-title
		:title="currentCoachingEngagement.title"
		:engagementDate="currentCoachingEngagement.engagementDate"
		:completed="currentCoachingEngagement.completed"
		:stakeholders="currentCoachingEngagement.stakeholders"
	  ></coaching-engagement-title>
	  <coaching-engagement-goal
		:goals="currentCoachingEngagement.coachingGoals"
	  ></coaching-engagement-goal>
	  <coaching-engagement-outcomes
	  	v-if="activeGoal && activeGoal.goal"
	  ></coaching-engagement-outcomes>
	  <div 
	  	v-if="activeGoal && activeGoal.goal"
	  	class="view-graphs-button"
	  >
      	<button @click="toggleGraphs">View Graphs</button>
	  </div>

	  <!--
	  <coaching-outcome-graph
		v-if="showGraphs"
		:client-engagement="clientEngagement"
		:outcome-numbers="[1, 2, 3]"
		:chart-data.sync="chartData"
	  ></coaching-outcome-graph>
	  -->
	  <!-- Graph invoked separately for each outcome -->
	  <template v-if="showGraphs && activeGoal">
		<!-- <coaching-outcome-graph
			v-for="(outcomeName, index) in activeGoal.goal.outcomeNames"
			:key="index"
			:client-engagement="clientEngagement"
			:outcome-numbers="[index + 1]"
			:chart-data.sync="chartData"
		></coaching-outcome-graph> -->
		<coaching-outcome-graph2
			v-for="(coachingOutcome, index) in activeGoal.goal.coachingOutcomes"
			:key="index"
			:client-engagement="clientEngagement"
			:coachingOutcomes="[coachingOutcome]"
			:chart-data.sync="chartData"
		></coaching-outcome-graph2>
		<coaching-learning-points-bar-chart
			:learning-points="currentCoachingEngagement.coachingLearningPoints"
		></coaching-learning-points-bar-chart>
	  </template>

	  <coaching-engagement-learning-points
		:learningPoints="currentCoachingEngagement.coachingLearningPoints"
	  ></coaching-engagement-learning-points>

	  <coaching-engagement-sessions-list
		:sessions="currentCoachingEngagement.coachingSessions"
		:clientRemarks="clientEngagement.clientRemarks"
	  ></coaching-engagement-sessions-list>

	  <!-- 
	  <coaching-engagement-sessions-and-remarks
		:sessions="currentCoachingEngagement.coachingSessions"
		:clientRemarks="clientEngagement.clientRemarks"
	  ></coaching-engagement-sessions-and-remarks>
	  -->
	  <!-- <router-view></router-view> -->
	</div>
  </template>
  
  <script>
  import _ from "lodash";
  import { mapGetters, mapActions } from "vuex";

  import CoachingEngagementTitle from "@/components/CoachingEngagementTitle";
  import CoachingEngagementGoal from "@/components/CoachingEngagementGoal";
  import CoachingEngagementOutcomes from "@/components/CoachingEngagementOutcomes";
  import coachingEngagementSessionsList from "@/components/CoachingEngagementSessionsList";
  // import CoachingEngagementSessionsAndRemarks from "@/components/CoachingEngagementSessionsAndRemarks";
  // import CoachingOutcomeGraph from "@/components/CoachingOutcomeGraph";
  import CoachingOutcomeGraph2 from "@/components/CoachingOutcomeGraph2";
  import CoachingEngagementLearningPoints from "@/components/CoachingEngagementLearningPoints.vue";
  import CoachingLearningPointsBarChart from "@/components/CoachingLearningPointsBarChart.vue";
  
  export default {
	name: "CoachingEngagement",
	components: {
	  CoachingEngagementTitle,
	  CoachingEngagementGoal,
	  CoachingEngagementOutcomes,
	  coachingEngagementSessionsList,
	  // CoachingEngagementSessionsAndRemarks,
	  // CoachingOutcomeGraph,
	  CoachingOutcomeGraph2,
	  CoachingEngagementLearningPoints,
	  CoachingLearningPointsBarChart,
	},
	props: [
		"year",
		"studentId",
		"classId",
		"studentCoachingEngagementId",
	],
	data() {
	  return {
		isLoading: true,
		isAuthorized: false,
		localCoachingEngagement: {}, 
/*		clientEngagement: {
			title: "Jane Doe (Test Student in Demo) with Joe Doe (Coach)",
			// goal: "Example goal description here as agreed with client",
			goals: [ //"First coaching goal", "Second coaching Goal"
				{
					id: "001",
					name: "First coaching goal",
					outcomeNames: ["Goal 1 Outcome Description One", "Goal 1 Outcome Description Two", "Goal 1 Outcome Description Three"]
				},
				{
					id: "002",
					name: "Second coaching goal",
					outcomeNames: ["Goal 2 Outcome Description One", "Goal 2 Outcome Description Two", "Goal 2 Outcome Description Three"]
				}
			],
			// outcomeNames: ["Outcome Description One", "Outcome Description Two", "Outcome Description Three"],
			resources: "Resources descriptions here",
			engagementDate: new Date("2023-03-28T12:30:00"),
			completed: false,
			sessions: [
				{ 
					startTime: new Date("2023-04-20T12:30:00"),
					endTime: new Date("2023-04-20T13:30:00"),
					online: false,
					venue: "Meeting place",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [6, 8, 10],
					outcomeSelfAssessment: [6, 7, 9],
					outcomeSelfAssessmentTime: new Date("2023-04-21T10:30:00"),
					sessionAssessment: 9
				},
				{ 
					startTime: new Date("2023-04-07T12:30:00"),
					endTime: new Date("2023-04-07T13:30:00"),
					online: false,
					venue: "Meeting place",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [4, 5, 7],
					outcomeSelfAssessment: [3, 3, 8],
					outcomeSelfAssessmentTime: new Date("2023-04-09T11:30:00"),
					sessionAssessment: 7
				},
				{ 
					startTime: new Date("2023-04-01T09:30:00"),
					endTime: new Date("2023-04-01T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [2, 3, 5],
					outcomeSelfAssessment: [3, 4, 6],
					outcomeSelfAssessmentTime: new Date("2023-04-02T10:30:00"),
					sessionAssessment: 6
				},
				{ 
					startTime: new Date("2023-03-04T09:30:00"),
					endTime: new Date("2023-03-04T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [2, 3, 5],
					outcomeSelfAssessment: [3, 4, 6],
					outcomeSelfAssessmentTime: new Date("2023-03-05T10:30:00"),
					sessionAssessment: 6
				},
				{ 
					startTime: new Date("2023-02-15T09:30:00"),
					endTime: new Date("2023-02-15T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [2, 3, 5],
					outcomeSelfAssessment: [3, 4, 6],
					outcomeSelfAssessmentTime: new Date("2023-02-15T14:30:00"),
					sessionAssessment: 6
				},
				{ 
					startTime: new Date("2023-02-01T09:30:00"),
					endTime: new Date("2023-02-01T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [1, 2, 4],
					outcomeSelfAssessment: [2, 4, 5],
					outcomeSelfAssessmentTime: new Date("2023-02-02T10:30:00"),
					sessionAssessment: 6
				},
				{ 
					startTime: new Date("2023-01-16T09:30:00"),
					endTime: new Date("2023-01-16T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [2, 3, 5],
					outcomeSelfAssessment: [3, 4, 6],
					outcomeSelfAssessmentTime: new Date("2023-01-17T10:30:00"),
					sessionAssessment: 6
				},
				{ 
					startTime: new Date("2023-01-02T09:30:00"),
					endTime: new Date("2023-01-02T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [2, 3, 5],
					outcomeSelfAssessment: [3, 4, 6],
					outcomeSelfAssessmentTime: new Date("2023-01-03T10:30:00"),
					sessionAssessment: 6
				},
				{ 
					startTime: new Date("2022-12-01T09:30:00"),
					endTime: new Date("2022-12-01T10:30:00"),
					online: true,
					venue: "Zoom 1234",
					goalId: "001",
					privateNotes: "Session Notes here",
					sharedNotes: "Shared Notes here",
					outcomeAssessments: [2, 3, 5],
					outcomeSelfAssessment: [3, 4, 6],
					outcomeSelfAssessmentTime: new Date("2022-12-02T10:30:00"),
					sessionAssessment: 6
				},
			],
			clientRemarks: [
				{
					remarkDate: new Date("2023-04-03T13:30:00"),
					remark: "Client made remark (1). More client notes. And some more.",
					outcomeAssessments: [2, 3, 5],
				},
				{
					remarkDate: new Date("2023-04-08T13:30:00"),
					remark: "Client made remark (2). More client notes. And some more.",
					outcomeAssessments: [2, 3, 5],
				},
				{
					remarkDate: new Date("2023-04-12T13:30:00"),
					remark: "Client made remark (3). More client notes. And some more.",
					outcomeAssessments: [4, 5, 6],
				},
				{
					remarkDate: new Date("2023-04-16T13:30:00"),
					remark: "Client made remark (4). More client notes. And some more.",
					outcomeAssessments: [6, 7, 6],
				},
				{
					remarkDate: new Date("2023-04-20T13:30:00"),
					remark: "Client made remark (5)",
					outcomeAssessments: [8, 9, 8],
				},
				{
					remarkDate: new Date("2023-04-22T13:30:00"),
					remark: "Client made remark (6)",
					outcomeAssessments: [7, 10, 8],
				},
			],
			learningPoints: [
				{
					learningPointDate: new Date("2023-04-03T13:30:00"),
					learningPoint: "Lesson learned that can continue to be applied (1)",
				},
				{
					learningPointDate: new Date("2023-04-08T13:30:00"),
					learningPoint: "Lesson learned that can continue to be applied (2)",
				},
				{
					learningPointDate: new Date("2023-04-12T13:30:00"),
					learningPoint: "Lesson learned that can continue to be applied (3)",
				},
				{
					learningPointDate: new Date("2023-04-16T13:30:00"),
					learningPoint: "Lesson learned that can continue to be applied (4)",
				},
				{
					learningPointDate: new Date("2023-04-20T13:30:00"),
					learningPoint: "Lesson learned that can continue to be applied (5)",
				},
				{
					learningPointDate: new Date("2023-04-22T13:30:00"),
					learningPoint: "Lesson learned that can continue to be applied (6)",
				},
			]
}, */
		showGraphs: false,
		chartData: null
	  };
	},
	async mounted() {
		this.isLoading = true;
		let student = this.getStudentInClass(this.$route.params.studentId, this.$route.params.classId);
		this.setSelectedStudentClassName(student);
		// set store clientEngagement with the testing content defined in this component
		// this.$store.dispatch('setCoachingEngagement', this.clientEngagement)
		// get studentCoachingEngagement based on the call id from server and set to the store
		await this.$store.dispatch('getStudentCoachingEngagement', this.studentCoachingEngagementId)
		// here if no classGroup -> get classGroup ??
		this.localCoachingEngagement = { 
			...this.studentCoachingEngagement,
			resources: this.classGroup.coachingEngagement.resources,
			engagementDate: this.classGroup.coachingEngagement.engagementDate,
			completed: this.classGroup.coachingEngagement.completed ,
			title: this.classGroup.name,
			stakeholders: this.classGroup.teachers,
			learningPoints: [],
			clientRemarks: []
		}
		this.$store.dispatch('setCurrentCoachingEngagement', this.localCoachingEngagement) // TODO review - we just used current to set this - not right
		// this.$store.dispatch('setCoachingEngagement', this.localCoachingEngagement) // TODO review this line - either set to actual coachingEngagement, or remove
		this.clientEngagement = this.localCoachingEngagement
		if (!this.currentCoachingEngagement.coachingGoals || !this.currentCoachingEngagement.coachingGoals.length === 0 )  {
			console.log("** set active goal to empty")
			this.$store.dispatch('setActiveGoal', {})
		} else {
			// We default goal viewing to the first goal
			console.log("** set active goal to default value - first goal")
			this.$store.dispatch('setActiveGoal', { 
				index: 1,
				goal: this.currentCoachingEngagement.coachingGoals[0]
			})
		}
		this.isLoading = false;
		if (['ROLE_CLIENT', 'ROLE_COACH', 'ROLE_TEACHER', 'ROLE_THERAPIST'].some(role => this.teacherAuthProfile.roles.includes(role))) {
			this.isAuthorized = this.classGroup.teachers.some(teacher => teacher.id === this.teacherAuthProfile.details.id);
		} else {
			this.isAuthorized = true
		}
	},
	computed: {
	  ...mapGetters({
		teacherAuthProfile: "profile",
		activeGoal: "activeGoal",
		coachingEngagement: "coachingEngagement",
		studentCoachingEngagement: "studentCoachingEngagement",
		currentCoachingEngagement: "currentCoachingEngagement",
		classGroup: "classGroup",
	  }),
	},
	async beforeRouteUpdate(to, from, next) {
		let student = this.getStudentInClass(to.params.studentId, to.params.classId);
		await this.getClassGroup(to.params.classId);
		this.setSelectedStudentClassName(student);
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			let student = vm.getStudentInClass(to.params.studentId, to.params.classId); 
			vm.setSelectedStudentClassName(student);
			await vm.$store.dispatch('getClassGroup', to.params.classId);
		});
	},
	// let student = vm.getStudentInClass(to.params.studentId, to.params.classId); 
	methods: {
	  ...mapActions([
		"getClassGroup",
	  ]),
	  toggleGraphs() {
    	this.showGraphs = !this.showGraphs;
      },
	  getStudent(studentId) {
		let students = _.flatMap(this.$store.getters.filteredClasses, "students");
		return students.find((it) => it.id === studentId);
	  },
	  getStudentInClass(studentId, classId) {
		let classes = this.$store.getters.filteredClasses;
		let targetClass = classes.find((cls) => cls.id === classId);
		if (targetClass && targetClass.students) {
			return targetClass.students.find((student) => student.id === studentId);
		}
		return null;
	  },

	  setSelectedStudentClassName(student) {
		this.$store.dispatch(
		  "setSelectedItemClass",
		  student && student.identifier
		);
	  },
	  /*
	  getOutcomeAssessmentAverages() {
		// Get the total number of sessions
		const sessionCount = this.clientEngagement.sessions.length;

		// Initialize an array to store the sum of outcome assessments
		const outcomeSums = [];

		// Iterate through each session
		this.clientEngagement.sessions.forEach((session) => {
			// Iterate through the outcome assessments in the current session
			session.outcomeAssessments.forEach((outcome, index) => {
				// Check if the outcomeSums array has an element at the current index
				if (outcomeSums[index] === undefined) {
				// If not, initialize the sum with the current outcome value
				outcomeSums[index] = outcome;
				} else {
				// If yes, add the current outcome value to the sum
				outcomeSums[index] += outcome;
				}
			});
		});

		// Calculate the average of each outcome by dividing the sum by the number of sessions
		const averageOutcomes = outcomeSums.map((sum) => sum / sessionCount);

		console.log("averageOutcomes: ", averageOutcomes)
		return averageOutcomes;
  	  },

	  getOutcomeAssessmentAveragesForActiveGoal() {
		// Filter sessions that match the activeGoal

		if (this.activeGoal) {
			console.log("getOutcomeAssessmentAveragesForActiveGoal", this.activeGoal);
		} else {
			console.log("getOutcomeAssessmentAveragesForActiveGoal - activeGoal null or falsey");
		}
		let filteredSessions = this.clientEngagement.sessions // if there is no activeGoal set, we calculate based on all sessions
		if (this.activeGoal && this.clientEngagement.sessions && this.clientEngagement.sessions.length > 0) {
			filteredSessions = this.clientEngagement.sessions.filter(
				(session) => session.goalId === this.activeGoal.goal.id
			);
		}

		// Get the total number of filtered sessions
		let sessionCount = 0
		if (filteredSessions) {
			sessionCount = filteredSessions.length;
		}
		
		// Initialize an array to store the sum of outcome assessments
		const outcomeSums = [];

		// Iterate through each filtered session
		if (sessionCount > 0) {
			filteredSessions.forEach((session) => {
				// Iterate through the outcome assessments in the current session
				session.outcomeAssessments.forEach((outcome, index) => {
					// Check if the outcomeSums array has an element at the current index
					if (outcomeSums[index] === undefined) {
						// If not, initialize the sum with the current outcome value
						outcomeSums[index] = outcome;
					} else {
						// If yes, add the current outcome value to the sum
						outcomeSums[index] += outcome;
					}
				});
			});
		}

		// Calculate the average of each outcome by dividing the sum by the number of filtered sessions
		const averageOutcomes = outcomeSums.map((sum) => sessionCount > 0 ? sum / sessionCount : 0);

		console.log("activeGoal averageOutcomes: ", averageOutcomes)
		return averageOutcomes;
	  },
	  */
	},
  };
  </script>
  
  <style lang="scss" scoped>
  .year-selection {
	margin-top: $global-margin / 2;
	display: inline-block;
  }

  .view-graphs-button {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.view-graphs-button button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
}

.view-graphs-button button:hover {
  background-color: #0069d9;
}
  </style>
  