import * as types from '../mutation-types';

/**
 * supported params
 * - header : String
 * - delay : Number
 * - tranlate : Boolean
 */

// export for test to remove delays
export const options = {
	id: 0,
	delay: 5000,
	removeDelay: 500
};

// initial state
const state = {
	toastr: []
};

// getters
const getters = {
	toastr(state) {
		return state.toastr;
	}
};

// actions
const actions = {
	addToastr({ commit }, toastr) {
		toastr.key = options.id++;
		toastr.classId = 'toastr_' + toastr.key;
		commit(types.ADD_TOASTR, toastr);
		setTimeout(() => {
			$('.' + toastr.classId).trigger('close');
			setTimeout(() => {
				commit(types.REMOVE_TOASTR, toastr);
			}, options.removeDelay);
		}, toastr.delay || options.delay);
	},
	removeToastr({ commit }, toastr) {
		commit(types.REMOVE_TOASTR, toastr);
	}
};

// mutations
const mutations = {
	[types.ADD_TOASTR](state, toastr) {
		state.toastr.push(toastr);
	},
	[types.REMOVE_TOASTR](state, toastr) {
		state.toastr = state.toastr.filter(it => it.key !== toastr.key);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
