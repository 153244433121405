<!-- CoachingEngagementOutcomeCard.vue -->
<template>
	<div class="outcome-card">
    <div v-if="!editMode" @click="editOutcome" class="outcome-name">
      <p>
        {{ outcomeName }}
      </p>
    </div>
    <div v-else class="edit-outcome-container">
      <input
        v-model="editedOutcomeName"
        @keyup.enter="saveEditOutcome"
        @keyup.esc="cancelEditOutcome"
        @blur="saveEditOutcome"
      />
      <button class="button primary margin-top-1" @click="saveEditOutcome">Save</button>
      <button class="button primary margin-top-1" @click="cancelEditOutcome">Cancel</button>
    </div>
	  <div class="assessment-bar">
		<span class="bar-number start">1</span>
		<div class="bar-inner" :style="{ width: latestOutcomeAssessment * 10 + '%' }"></div>
		<span class="bar-number end">10</span>
	  </div>
    <!-- optional presentations for outcomes
	  <div class="assessment-circle" :style="{background: circleGradient}">
		<span>{{ latestOutcomeAssessment }}</span>
	  </div>
	  <div class="assessment-circle" :style="circleGradientColor">
		<span>{{ latestOutcomeAssessment }}</span>
	  </div>
    -->
	</div>
  </template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	name: "CoachingEngagementOutcomeCard",
	props: ["outcomeId", "outcomeName", "latestOutcomeAssessment"],
  data() {
    return {
      editMode: false,
      editedOutcomeName: '',
    };
  },
	computed: {
		...mapGetters({
			activeGoal: 'activeGoal',
			currentCoachingEngagement: 'currentCoachingEngagement',
		}),
		circleGradient() {
			const percentage = (this.latestOutcomeAssessment-1) / 9;
			// const gradient = `linear-gradient(to top, dullgray ${percentage * 100}%, green ${percentage * 100}%)`;
			const gradient = `linear-gradient(180deg, #a9a9a9 ${(1 - percentage) * 100}%, green ${percentage * 100}%)`;
			return gradient;
		},
		circleGradientColor() {
			const percentage = this.latestOutcomeAssessment / 10;
			const red = Math.round(169 * (1 - percentage));
			const green = Math.round(169 * percentage + 86);
			const blue = Math.round(169 * (1 - percentage) + 86);
			const color = `rgb(${red}, ${green}, ${blue})`;
			return {
				backgroundColor: color,
			};
    },
  },
  methods: {
    ...mapActions([
			"updateCoachingOutcome",
      "setActiveGoal",
		]),
    editOutcome() {
      this.editMode = true;
      this.editedOutcomeName = this.outcomeName;
    },
    async saveEditOutcome() {
      if (this.editedOutcomeName.trim() !== '') {
        await this.updateCoachingOutcome({ id: this.outcomeId, studentCoachingEngagementId: this.currentCoachingEngagement.id, outcomeName: this.editedOutcomeName.trim()})
        const currentGoal = this.currentCoachingEngagement.coachingGoals.find(a => a.id == this.activeGoal.goal.id)
        this.setActiveGoal({ index: this.activeGoal.index, goal: currentGoal })
      }
      this.editMode = false;
    },
    cancelEditOutcome() {
      this.editMode = false;
    },
  },
}
</script>

<style lang="scss" scoped>
$outcome-card-padding: 1em;
$outcome-card-border-radius: 10px;

.outcome-card {
  position: relative;
  padding: $outcome-card-padding;
  background-color: $white;
  border-radius: $outcome-card-border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1em;
}

.outcome-name{
  cursor: pointer;
}

.edit-outcome-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // background-color: $white; // or any other color you want

	input {
		flex-grow: 1;
		//margin-right: 1em; // add some space between input and buttons
	}
	
	.button {
		width: auto; // reset the button width to auto for better spacing
		margin-left: 1em;
	}
}

.assessment-bar {
  position: relative;
  background-color: $light-gray;
  height: 10px;
  width: 100%;
  border-radius: 5px;

  .bar-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: green;
    border-radius: 5px;
  }

  .bar-number {
    font-size: 0.7em;
    position: absolute;
    top: -1.5em;
  }

  .start {
    left: 0;
  }

  .end {
    right: 0;
  }
}

.assessment-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-weight: bold;
  color: white;
  margin-top: 1em;
}
</style>