<template>
  <div>
    <p>
      {{ $t("studentDetailsProgrammePlan.empty.1") }}
      <br />
      {{ $t("studentDetailsProgrammePlan.empty.2") }}
    </p>
    <div class="grid-x margin-top-2">
      <div class="cell medium-shrink create-plan">
        <button
          class="button primary margin-top-1"
          @click="createStudentProgrammePlan(studentId, year, year, endYear)"
        >
          {{ $t("studentDetailsProgrammePlan.create") }}
        </button>
        <div class="grid-x year-selection align-middle">
          <div class="start-year cell shrink">
            <span>
              {{ $t("studentDetailsProgrammePlan.startYear") }}:&nbsp;&nbsp;
              <b>{{ startYear }}</b>
            </span>
          </div>
          <div class="end-year cell shrink">
            <span>{{ $t("studentDetailsProgrammePlan.endYear") }}:</span>
            <select id="endYear" v-model="endYear" class="select-end-year">
              <option
                v-for="year in endYearRange"
                :key="Number.parseInt(startYear) + Number.parseInt(year)"
                :value="Number.parseInt(startYear) + Number.parseInt(year)"
              >
                {{ Number.parseInt(startYear) + Number.parseInt(year) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "CreateStudentProgrammePlan",
  props: ["studentId", "year"],
  computed: {
    startYear: function () {
      return Number.parseInt(this.year);
    },
    endYear: function () {
      return Number.parseInt(this.year) + 3;
    },
    endYearRange: function () {
      return _.range(0, 4);
    },
  },
  methods: {
    createStudentProgrammePlan(
      studentId,
      selectedYear,
      planStartYear,
      planEndYear
    ) {
      this.$store
        .dispatch("createStudentPlan", {
          studentId: studentId,
          selectedYear: selectedYear,
          planStartYear: planStartYear,
          planEndYear: planEndYear,
        })
        .then((resp) => {
          if (resp) {
            this.$store.dispatch("getStudentProgrammePlanStatus", {
              studentId: studentId,
              year: selectedYear,
            });
            this.routeToProgrammePlan(studentId, selectedYear);
          }
        })
        .catch((err) => {
          this.$toastr.error(err.data && err.data.message);
        });
    },
    routeToProgrammePlan(studentId, planYear) {
      this.$router.replace({
        name: "Piep",
        params: { studentId: studentId, year: planYear },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-plan {
  padding: $global-padding / 2 $global-padding;
  border-radius: $global-radius;
  background-color: $lighter-gray;

  .button {
    width: 100%;
  }
}
.select-end-year {
  display: inline-block;
  border: none;
  box-shadow: none;
  text-decoration: underline;
  width: 70px;
}
.start-year {
  margin-right: $global-margin;
}
</style>
