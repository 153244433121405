<template>
  <select @change="changeTerm" v-cloak>
    <option
      v-for="(term, index) in termFilters"
      :key="term.value"
      :value="term.value"
      :selected="term.value === value || index === 0"
    >
      {{ $t(term.label) }}
    </option>
  </select>
</template>

<script>
export default {
  name: "TermFilter",
  props: {
    baseline: {
      type: Boolean,
      default: true,
    },
    value: [String, Number],
  },
  computed: {
    termFilters: function () {
      return this.baseline
        ? this.$store.getters.termFilters
        : this.$store.getters.termFiltersWithoutBaseline;
    },
  },
  mounted() {
    if (!this.value) {
      this.$emit("input", this.termFilters[0].value);
    }
  },
  methods: {
    changeTerm(event) {
      this.$emit("input", parseInt(event.target.value));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
