import * as types from '../mutation-types';
import studentEloAttainmentApi from '@/api/studentEloAttainment';

// initial state
const state = {
	studentAttainment: {},
	taskItemCountSummary: {}
};

const getters = {
	studentAttainment: (state) => (studentId) => {
		return state.studentAttainment[studentId];
	},
	getTaskItemCountSummaryForStudentId: (state) => (studentId) => {
		return state.taskItemCountSummary[studentId];
	}
};

// actions
const actions = {
	getStudentAttainment({ commit }, { studentId, eloCode, planYear, planTerm }) {
		studentEloAttainmentApi.getStudentAttainment(studentId, eloCode, planYear, planTerm).then((resp) => {
			if (resp.data) {
				commit(types.SET_ELO_ATTAINMENT, { studentId, attainment: resp.data.eloAttainment });
				commit(types.SET_TASK_ITEM_COUNT_SUMMARY, { studentId, taskItemCountSummary: resp.data.taskItemCountSummary });
			}
		});
	},
	postStudentAttainment({ commit }, { studentId, eloCode, planYear, planTerm, formData }) {
		return studentEloAttainmentApi.postStudentAttainment(studentId, eloCode, planYear, planTerm, formData).then((resp) => {
			if (resp.data) {
				commit(types.SET_ELO_ATTAINMENT, { studentId, attainment: resp.data });
			}
		});
	},
	setStudentAttainment({ commit }, { studentId, attainment }) {
		commit(types.SET_ELO_ATTAINMENT, { studentId, attainment });
	},
	setTaskItemCountSummary({ commit }, { studentId, taskItemCountSummary }) {
		commit(types.SET_TASK_ITEM_COUNT_SUMMARY, { studentId, taskItemCountSummary });
	}
};

// mutations
const mutations = {
	[types.SET_ELO_ATTAINMENT](state, { studentId, attainment }) {
		state.studentAttainment = { ...state.studentAttainment, [studentId]: attainment };
	},
	[types.SET_TASK_ITEM_COUNT_SUMMARY](state, { studentId, taskItemCountSummary }) {
		state.taskItemCountSummary = { ...state.taskItemCountSummary, [studentId]: taskItemCountSummary };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
