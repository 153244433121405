import Vue from 'vue'

export default {
	getClassGroupFullDetailsFor (classId) {
		return Vue.http.get('classGroupSearch/' + classId)
	},
	getClassGroupsForParams (params) {
		return Vue.http.get('classGroupSearch', {
			params
		})
	},
	getUnassignedStudents () {
		return Vue.http.get('classGroupSearch', {
			params: {
				showUnassignedStudents: true
			}
		})
	},
	getUnassignedTeachers () {
		return Vue.http.get('classGroupSearch', {
			params: {
				showUnassignedTeachers: true
			}
		})
	}
}
