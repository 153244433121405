import Vue from 'vue'

export default {
	getLatestAttainmentDetails (studentId, planYear) {
		return Vue.http.get('latestAttainmentDetails/' + studentId, {
			params: {
				planYear: planYear
			}
		})
	}
}
