import { mapGetters } from 'vuex';

const initialDelay = 500;

export default {
	name: 'stickyInitializer',
	computed: {
		...mapGetters({
			canvasConfig: 'canvasConfig'
		})
	},
	watch: {
		canvasConfig: function (val) {
			if ($('.sticky').length > 0) {
				this.recalculate();
			}
		}
	},
	mounted() {
		setTimeout(() => {
			$(window).trigger('load.zf.sticky');

			if ($('.sticky').length > 0) {
				$(window).resize(this.recalculate);
				this.recalculate();
			}
		}, initialDelay);
	},
	updated() {
		$(window).trigger('load.zf.sticky');
	},
	destroyed() {
		$(window).off('resize', this.recalculate);
	},
	methods: {
		recalculate() {
			$('.sticky').foundation('_calc', true);
		}
	}
};
