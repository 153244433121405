import Vue from 'vue'

export default {
	getCoachingEngagement (engagementId) {
		return Vue.http.get(`coachingEngagement/${engagementId}`)
	},
	createCoachingEngagement (classGroupId, engagementDate, completed, resources) {
		console.log("Posting: { classGroupId, engagementDate, completed, resources }", { classGroupId, engagementDate, completed, resources })
		return Vue.http.post('coachingEngagement', {
			classGroupId, engagementDate, completed, resources
		})
	},
	updateCoachingEngagement (engagementId, classGroupId, engagementDate, completed, resources) {
		return Vue.http.put(`coachingEngagement/${engagementId}`, {
			classGroupId, engagementDate, completed, resources
		})
	},
	deleteCoachingEngagement (engagementId) {
		return Vue.http.delete(`coachingEngagement/${engagementId}`)
	},
	getEngagementsByClassGroupId (classGroupId) {
		return Vue.http.get(`coachingEngagement/classGroup/${classGroupId}`)
	}
}
