import Vue from 'vue'

export default {
	createTeacher (userId, salutation, fullName, displayName, designation) {
		return Vue.http.post('teacher', {
			userId, salutation, fullName, displayName, designation
		})
	},
	updateTeacherDetails (teacherId, salutation, fullName, displayName, designation) {
		return Vue.http.put(`teacher/${teacherId}`, {
			salutation, fullName, displayName, designation
		})
	},
	deleteTeacherDetails (teacherId) {
		return Vue.http.delete(`teacher/${teacherId}`)
	}
}
