<template>
  <div class="grid-x dashboard-prog-wrapper card-container card" v-cloak>
    <router-link
      :to="{
        name: 'Dashboard',
        params: {
          year: $store.getters.selectedYearFilter,
          classId: programmeDetails.classGroup.id,
        },
      }"
    >
      <div class="cell dashboard-list-card dashboard-prog-card">     
        <div class="card-section">
          <div class="grid-x stats-graph">
            <div
              class="
                cell
                grid-x
                align-middle
                padding-top-1 padding-left-1 padding-right-1
              "
            >
              <h6 class="cell auto margin-bottom-0 color--white">
                {{ programmeDetails.classGroup.name }}
              </h6>
              <div class="cell shrink color--gray">
                <span v-if="!isCoachingEngagement"
                  ><small
                    >{{ programmeDetails.classGroup.session }},&nbsp;</small
                  ></span
                >
                <span
                  ><small>{{ programmeDetails.classGroup.year }}</small></span
                >
              </div>
            </div>
            <div class="cell" v-if="!isCoachingEngagement">
              <attainment-score
                :score="averageAttainment()"
                :annualPlanStatus="status"
              >
              </attainment-score>
            </div>
            <div class="cell" v-if="isCoachingEngagement">
              <coaching-assessment-score
                :assessmentProgress="programmeDetails.hasOwnProperty('assessmentProgressAverage') ? programmeDetails.assessmentProgressAverage : null"
                :selfAssessmentProgress="programmeDetails.hasOwnProperty('selfAssessmentProgressAverage') ? programmeDetails.selfAssessmentProgressAverage : null"
              >
              </coaching-assessment-score>
            </div>
          </div>
          <div class="grid-x text-center" v-if="!isCoachingEngagement">
            <div class="cell small-6 prog-elos">
              <span class="h5"
                >{{ programmeDetails.totalAttainedElos }} /
                {{ programmeDetails.totalPlannedElos }}</span
              >
              <p class="margin-bottom-0">
                {{ $t("programmeDashboardListCard.elo.attained") }}
              </p>
            </div>
            <div class="cell small-6 prog-elos">
              <span class="h5">{{ programmeDetails.studentCount }}</span>
              <p class="margin-bottom-0">
                {{ $t("programmeDashboardListCard.student.total") }}
              </p>
            </div>
          </div>

          <div class="grid-x text-center" v-if="isCoachingEngagement">
            <div class="cell small-6 prog-elos">
              <span class="h5">{{ totalCoachees }}</span>
              <p class="margin-bottom-0">
                {{ $t("programmeDashboardListCard.coachee.total") }}
              </p>
            </div>
            <div class="cell small-6 prog-elos">
              <span class="h5">{{ totalSessions }}</span>
              <p class="margin-bottom-0">
                {{ $t("programmeDashboardListCard.session.total") }}
              </p>
            </div>
          </div>

          <div class="grid-x padding-top-1 padding-left-1 padding-right-1" v-if="!isCoachingEngagement">
            <div class="cell grid-x align-middle">
              <span class="cell auto margin-bottom-0"
                >{{
                  $t("programmeDashboardListCard.attainment.average")
                }}
                (%)</span
              >
              <span class="cell shrink prog-score"
                ><b>{{ averageAttainment() }}</b></span
              >
            </div>
          </div>

          <div class="grid-x padding-top-1 padding-left-1 padding-right-1" v-if="isCoachingEngagement">
            <div class="cell grid-x align-middle">
              <span class="cell auto margin-bottom-0"
                >{{
                  $t("programmeDashboardListCard.sessionAssessment.average")
                }}
              </span>
              <span class="cell shrink prog-score"
                ><b>{{ programmeDetails.sessionAssessmentAverage }}</b></span
              >
            </div>
          </div>

          <div class="grid-x padding-left-1 padding-right-1">
            <span class="cell sans-serif-bold"
              ><small>{{
                isCoachingEngagement ? $t("programmeDashboardListCard.teachers.coaching") : $t("programmeDashboardListCard.teachers")
              }}</small></span
            >
            <div class="cell prog-teachers">
              <div
                class="image-wrapper"
                v-for="teacher in programmeDetails.classGroup.teachers"
                :key="teacher.id"
              >
                <profile-image
                  :image-title="teacher.fullName"
                  :image="teacher.image"
                  :image-alt="teacher.displayName"
                  :image-size="30"
                ></profile-image>
                <div class="text-center truncate hide">
                  <small>{{ teacher.displayName }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-x padding-top-1 padding-left-1 padding-right-1" v-if="!isCoachingEngagement">
            <p class="cell margin-bottom-0">
              <small>{{
                $t("programmeDashboardListCard.student.belowAverage")
              }}</small>
            </p>
            <span class="cell prog-student-list color--alert">{{
              getBelowAverageList(programmeDetails.studentAttainments)
            }}</span>
          </div>
          <div class="grid-x padding-1" v-if="!isCoachingEngagement">
            <p class="cell margin-bottom-0">
              <small>{{
                $t("programmeDashboardListCard.student.noAiep")
              }}</small>
            </p>
            <span class="cell prog-student-list color--primary">{{
              makeList(programmeDetails.studentWithoutPlan)
            }}</span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ProfileImage from "@/components/ProfileImage";
import AttainmentScore from "@/components/AttainmentScore";
import CoachingAssessmentScore from "@/components/CoachingAssessmentScore";
import _ from "lodash";

export default {
  name: "ProgrammeDashboardListCard",
  components: {
    ProfileImage,
    AttainmentScore,
    CoachingAssessmentScore,
  },
  props: { programmeDetails: Object },
  data() {
    return {
      status: { planStatus: "APPROVED" },
    };
  },
  mixins: [],
  computed: {
	  isCoachingEngagement: function () {     
      if (this.programmeDetails.classGroup.coachingEngagement) {
        console.log("programmeDetails.classGroup.coachingEngagement: ", this.programmeDetails.classGroup.coachingEngagement)
        return true
      }
      return false;
	  },
    totalSessions: function ()  {
      if (this.programmeDetails && this.programmeDetails.classGroup && this.programmeDetails.classGroup.coachingEngagement) {
        const coachingEngagement = this.programmeDetails.classGroup.coachingEngagement;
        if (coachingEngagement.studentCoachingEngagements && Array.isArray(coachingEngagement.studentCoachingEngagements)) {
          return coachingEngagement.studentCoachingEngagements.reduce((total, sce) => {
            return total + (sce.numberOfSessions || 0);
          }, 0);
        }
      }
      return 0;
    },
    totalCoachees() {
      if (this.programmeDetails && this.programmeDetails.classGroup && this.programmeDetails.classGroup.coachingEngagement) {
        const coachingEngagement = this.programmeDetails.classGroup.coachingEngagement;
        if (coachingEngagement.studentCoachingEngagements && Array.isArray(coachingEngagement.studentCoachingEngagements)) {
          return coachingEngagement.studentCoachingEngagements.length;
        }
      }
      return 0;
    }
  },
  methods: {
    averageAttainment: function () {
      return (
        Math.round(
          (this.programmeDetails.totalAttainedElos /
            this.programmeDetails.totalPlannedElos) *
            100 *
            100
        ) / 100
      );
    },
    makeList: function (valuesArray) {
      return _.join(valuesArray, ", ");
    },
    getBelowAverageList: function (attainments) {
      let avgAttainment = this.averageAttainment();
      return _(attainments)
        .filter((it) => it.score < avgAttainment)
        .map("name")
        .value()
        .join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-prog-wrapper a {
  width: 100%;
  height: 100%;

  &:hover {
    color: smart-scale($dark-gray, 20%);
    background-color: $bg-selected;
  }
}
.stats-graph {
  background-color: transparentize($title-color, 0.25);
  padding-bottom: $global-padding / 2;
}
.prog-elos {
  padding: $global-padding / 2 $global-padding;
  border-bottom: 1px solid $medium-gray;

  &:first-child {
    border-right: 1px solid $medium-gray;
  }
}
.prog-student-list {
  @extend .sans-serif-bold;
  font-size: $small-font-size;
}
.prog-score {
  padding: $global-padding / 4 $global-padding / 1.5;
  border-radius: $global-radius;
  background-color: $light-gray;
  text-align: center;
}
.dashboard-list-card {
  color: $text-color;

  .card-section {
    padding: 0;
  }
}
.prog-teachers {
  display: flex;
  position: relative;
  margin-top: $global-margin / 3;

  .image-wrapper {
    margin-left: -0.6em;

    &:first-child {
      margin-left: -0.3em;
    }
  }
}
</style>

