// coachingActiveGoal.js vuex store module
import * as types from '../mutation-types';

// initial state
const state = {
  activeGoal: null,
};

// getters
const getters = {
  activeGoal: state => state.activeGoal,
};

// actions
const actions = {
  setActiveGoal({ commit }, { index, goal }) {
	const activeGoal = { index, goal }
    commit(types.SET_ACTIVE_GOAL, activeGoal);
  },
};

// mutations
const mutations = {
  [types.SET_ACTIVE_GOAL](state, activeGoal) {
    state.activeGoal = activeGoal;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
