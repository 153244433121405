export default {
	inserted (el, binding) {
		if (binding.value && binding.value.disable) return

		if (binding.arg) {
			el.foundation = new Foundation[binding.arg]($(el))
		}
	},
	unbind (el, binding) {
		if (binding.value && binding.value.disable) return

		if (binding.modifiers.destroy) {
			$(el).foundation('_destroy')
		}
	}
}
