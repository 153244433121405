import Vue from 'vue'

export default {
	getProgrammeDetails (programmeId, year) {
		return Vue.http.get('programmeDetails/' + programmeId, {
			params: {
				planYear: year
			}
		})
	}
}
