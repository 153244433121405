<template>
  <transition name="fade">
    <div
      v-if="haveLoaders"
      class="
        loader
        color--white
        grid-x
        position-fixed
        align-middle align-center
      "
      v-cloak
    >
      <font-awesome-icon icon="spinner" size="3x" spin> </font-awesome-icon>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "TheLoader",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      loaderDelay: 500,
    };
  },
  computed: {
    haveLoaders: function () {
      return this.$store.getters.loaders.length > 0;
    },
  },
  created() {
    Vue.http.interceptors.push((request, next) => {
      const loaderId = Math.random().toString(36).substring(2);

      let timeout = setTimeout(() => {
        this.$store.dispatch("addLoader", loaderId);
      }, this.loaderDelay);

      next((response) => {
        clearTimeout(timeout);
        if (this.haveLoaders) {
          this.$store.dispatch("removeLoader", loaderId);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($black, 0.4);
  z-index: $loader-zindex;
}
</style>
