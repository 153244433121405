<template>
  <div v-cloak>
    <div v-if="!coachingEngagement">
      <p>
        {{ $t("classGroupList.description") }}</p>
      <div v-if="!list || list.length === 0">
        {{ $t("classGroup.noStudentFound") }}
      </div>
      <class-group-list-card 
        v-else
        v-for="card in list"
        :key="card.id"
        :student-id="card.id"
        :image="card.image"
        :conditionTypes="card.conditionTypes"
        :text-content="card.displayName"
        :profile-name="card.fullName"
        :class-id="classId"
        :student-coaching-engagement-id="getStudentCoachingEngagementId(card.id)"
        resources=""
        engagementDate=""
        completed=""
      >
      </class-group-list-card>
    </div>
    <div v-if="coachingEngagement">
      <p>
        {{ $t("classGroupList.coachingDescription") }}</p>
      <div v-if="!list || list.length === 0">
        {{ $t("classGroup.noClientOrStudentFound") }}
      </div>
      <class-group-list-card 
        v-else
        v-for="card in list"
        :key="card.id"
        :student-id="card.id"
        :image="card.image"
        :conditionTypes="card.conditionTypes"
        :text-content="card.displayName"
        :profile-name="card.fullName"
        :class-id="classId"
        :student-coaching-engagement-id="getStudentCoachingEngagementId(card.id)"
        :resources="coachingEngagement.resources"
        :engagementDate="coachingEngagement.engagementDate"
        :completed="coachingEngagement.completed"
      >
      </class-group-list-card>
    </div>
  </div>
</template>

<script>
import ClassGroupListCard from "./ClassGroupListCard";

export default {
  name: "ClassGroupList",
  components: {
    ClassGroupListCard,
  },
  props: {
    list: Array,
    classId: String,
    coachingEngagement: Object,
  },
  methods: {
    getStudentCoachingEngagementId(studentId) {
      if (this.coachingEngagement) {
        const engagement = this.coachingEngagement.studentCoachingEngagements.find(e => e.studentId === studentId);
        return engagement ? engagement.id : null;
      } else {
        return null
      }
    }
}

};
</script>

<style lang="scss" scoped>
</style>
