import tenantApi from '@/api/tenant'
import * as types from '../mutation-types'
import constants from '@/constants'
import _ from 'lodash'

const state = {
	tenantIds: [],
	tenants: {}
}

const getters = {
	tenants: (state) => state.tenants,
	adminTenant: (state) => (tenantId) => state.tenants[tenantId],
}

const mutations = {
	[types.SET_TENANT_IDS](state, tenantIds) {
		state.tenantIds = [...state.tenantIds, ...tenantIds]
	},
	[types.SET_TENANTS](state, tenants) {
		state.tenants = { ...state.tenants, ...tenants }
	},
	[types.SET_TENANT](state, tenant) {
        state.tenants = { ...state.tenants, [tenant.id]: {...tenant} }
    },
    [types.UPDATE_TENANT](state, tenant) {
        state.tenants = { ...state.tenants, [tenant.id]: {...tenant} }
	},
	[types.REMOVE_TENANT](state, tenant) {
		const copyOfTenants = { ...state.tenants }
		delete copyOfTenants[tenant.id]
		state.tenants = copyOfTenants
	}
}

const actions = {
	async getTenants({ commit }) {
		const { data } = await tenantApi.getTenants()

		let tenants = _.keyBy(data, 'id')
		commit('SET_TENANT_IDS', Object.keys(tenants))

		commit('SET_TENANTS', tenants)
	},
	async viewEditTenant({ commit }, params) {
		const { data } = await tenantApi.viewEditTenant(params.id)
    
		commit(types.UPDATE_TENANT, data)
		return data
	},
	async createTenant({ commit }, params) {
		const { data } = await tenantApi.createTenant(params)

		commit(types.SET_TENANT, data)
		return data
	},
	async editTenant({ commit }, params) {
		const { data } = await tenantApi.editTenant(params)
        
		commit(types.UPDATE_TENANT, data)
		return data
	},
	async deleteTenant({ commit }, params) {
		const { status } = await tenantApi.deleteTenant(params)

		if (status === constants.RESPONSE_STATUS.OK) {
			commit(types.REMOVE_TENANT, { id: params.id })
			return true
        }
        return false
	},
    async deactivateTenant({ commit }, {id, enabled}) {
        const { data } = await tenantApi.editTenant({id, enabled})
        commit(types.UPDATE_TENANT, data)
		return data
	},
    async activateTenant({ commit }, {id, enabled}) {
        const { data } = await tenantApi.editTenant({id, enabled})
        commit(types.UPDATE_TENANT, data)
		return data
	},
}

export default {
	state,
	getters,
	mutations,
	actions
}
