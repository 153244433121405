<template>
  <div class="container-wrapper" v-cloak>
    <div class="cell medium-auto small-12 margin-bottom-1">
      <h1 class="page-title">{{ $t("userList.title") }}</h1>
    </div>

    <div class="cell small-12 grid-x text-right margin-bottom-1">
      <div class="cell auto no-print">
        <input
          type="text"
          :placeholder="$t('userList.search.placeholder')"
          v-model="searchTerm"
        />
      </div>

      <div class="cell auto no-print">
        <button class="button clear" @click="showCreateUserModal()">
          <font-awesome-icon icon="user-plus" size="lg" />
          &nbsp;<b>{{ $t("userList.user.create") }}</b>
        </button>
      </div>
    </div>

    <div v-if="!users || users.length === 0">
      {{ $t("userList.noUserFound") }}
    </div>
    <user-card
      v-else
      v-for="user in filteredUsers"
      :key="user.id"
      :user="user"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import UserCard from "./UserCard";
import UserModal from "@/views/UserModal/UserModal";

export default {
  name: "UserList",
  components: {
    FontAwesomeIcon,
    UserCard,
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
    }),
    ...mapGetters(["userTeacherDetails"]),
    sortedUsers() {
      return Object.values(this.users).sort((a, b) =>
        a.username.localeCompare(b.username, "en", { sensitivity: "base" })
      );
    },
    filteredUsers() {
      return this.sortedUsers.filter((it) => {
        const { fullName, displayName } = this.userTeacherDetails(it.id) || {};
        const searchList = [it.username, fullName, displayName];
        return searchList.some((it) => {
          return ~it.toLowerCase().indexOf(this.searchTerm.toLowerCase());
        });
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getUserList();
      vm.getSchools();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getUserList();
    this.getSchools();
    next();
  },
  methods: {
    showCreateUserModal() {
      let modalParams = {
        component: UserModal,
        class: "small",
      };

      this.$modal.show(modalParams);
    },
    ...mapActions(["getUserList", "getSchools"]),
  },
};
</script>
