<template>
  <quill-editor
    class="rich-text-editor"
    :options="editorOption"
    v-model="textData"
    v-cloak
  ></quill-editor>
</template>

<script>
// import oriQuill from 'quill'
import { quillEditor, Quill } from "vue-quill-editor";
import { icon } from "@fortawesome/fontawesome-svg-core";
import ImageResize from "@taoqf/quill-image-resize-module";
import { ImageDrop } from "quill-image-drop-module";

// customisation for quilljs
import "@/utils/quilljs";
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

export default {
  name: "RichTextEditor",
  components: {
    quillEditor,
  },
  props: {
    value: String,
    placeholder: String,
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      editorOption: {
        placeholder:
          this.placeholder || this.$i18n.t("richTextEditor.placeholder"),
        bounds: ".rich-text-editor",
        modules: {
          imageDrop: true,
          imageResize: true,
          toolbar: {
            container: [
              ["undo", "redo"],
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ font: [] }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ["clean"],
              ["link"],
              ["image"],
              // ['video']
            ],
            handlers: {
              undo: function () {
                this.quill.history.undo();
              },
              redo: function () {
                this.quill.history.redo();
              },
            },
          },
        },
      },
      textData: null,
    };
  },
  watch: {
    value: function (value) {
      this.textData = this.value;
    },
    textData: function (value) {
      this.$emit("input", value);
    },
  },
  beforeCreate() {
    let icons = Quill.import("ui/icons");
    icons["undo"] = icon({ prefix: "fas", iconName: "undo" }).html[0];
    icons["redo"] = icon({ prefix: "fas", iconName: "redo" }).html[0];
  },
  mounted() {
    this.textData = this.value;
    $(this.$el)
      .find(".ql-editor")
      .css("min-height", this.height + "px");
  },
};
</script>

<style lang="scss">
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 5px;
}
</style>
