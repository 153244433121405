<template>
    <div class="modal" v-if="isVisible">
      <div class="modal-content">
        <h3>{{ isLocked ? 'Manage Access' : 'Set Lock' }}</h3>
        <div v-if="!isLocked">
          <div class="section">
            <div class="button-group">
                <button @click="setLock"
                data-tooltip tabindex="1" 
                title="Activate lock on this record that allow you to manage detailed access"
                data-position="bottom"
                data-alignment="left">
                  Add <font-awesome-icon icon="user-lock" size="sm"></font-awesome-icon></button>
                <button @click="cancel"
                  title="Close this dialog without making any changes"
                  data-position="bottom"
                  data-alignment="left">
                  Cancel</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="section">
            <ul>
                <li v-for="user in filteredStakeholders" :key="user.id">
                {{ user.displayName }}
                <span class="access-indicator" :class="{ 'access-granted': getAccessString(user.id), 'access-denied': !getAccessString(user.id) }">
                    {{ getAccessString(user.id) ? 'Access' : 'No Access' }}
                </span>
                <button v-if="!getAccessString(user.id)" @click="toggleAccess(user.id, 'grant')"
                  title="Allow this person access according to their role"
                  data-position="bottom"
                  data-alignment="left">
                  Grant</button>
                <button v-if="getAccessString(user.id)" @click="toggleAccess(user.id, 'deny')"
                  title="Remove access from this person"
                  data-position="bottom"
                  data-alignment="left">
                  Deny</button>
                </li>
            </ul>
          </div>
          <div class="section">
            <div class="button-group">
                <button @click="updateAccess"
                  title="Save changes to individual access"
                  data-position="bottom"
                  data-alignment="left">
                  Save Changes</button>
                <button @click="cancel"
                  title="Close this dialog without making any changes"
                  data-position="bottom"
                  data-alignment="left">
                  Cancel</button>
            </div>
          </div>
          <div class="single-button">
            <button @click="removeLock"
                  title="Your lock will be removed together with your access controls. General access controls will apply."
                  data-position="bottom"
                  data-alignment="left">
              Remove <font-awesome-icon icon="user-lock" size="sm"></font-awesome-icon></button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

  export default {
    components: {
		FontAwesomeIcon,
	},
    props: {
        isVisible: {
            type: Boolean,
            required: true
        }, 
        stakeholders: {
            type: Array,
            required: true
        },
        currentUserId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            localAccessList: [], // Local copy of accessList
            accessListChanged: false,
        };
    },
    created() {
        this.localAccessList = [...this.currentCoachingEngagement.accessList]; // we create local copy rather than pointer
    },
    computed: {
        ...mapGetters({
            currentCoachingEngagement: 'currentCoachingEngagement'
        }),
        isLocked() {
            return this.currentCoachingEngagement.lockedBy !== null;
        },
        filteredStakeholders() {
            return this.stakeholders.filter(user => user.id !== this.currentUserId);
        }
    },
    watch: {
        'currentCoachingEngagement.accessList': function(newVal) {
            this.localAccessList = [...newVal];
        }
    },
    mounted() {
        this.localAccessList = [...this.currentCoachingEngagement.accessList];
    },
    methods: {
      ...mapActions([
        "updateStudentCoachingEngagement",
      ]),
      async setLock() {
        await this.updateStudentCoachingEngagement({ 
            id: this.currentCoachingEngagement.id,  
            studentCoachingEngagement: {
                id: this.currentCoachingEngagement.id,
                coachingEngagementId: this.currentCoachingEngagement.coachingEngagementId,
                studentId: this.currentCoachingEngagement.studentId,
                lockedBy: this.currentUserId,
                accessList: this.currentCoachingEngagement.accessList
            }
        })
      },
      toggleAccess(userId, action) {
        this.accessListChanged = true
        const index = this.localAccessList.findIndex(entry => entry.id === userId);
        if (action === 'grant' && index === -1) {
            this.localAccessList.push({ id: userId, accessString: 'Access Granted' }); // Customize accessString as needed
        } else if (action === 'deny' && index !== -1) {
            this.localAccessList.splice(index, 1);
        }
      },
      async updateAccess() {
        if (this.accessListChanged) {
          await this.updateStudentCoachingEngagement({ 
              id: this.currentCoachingEngagement.id,  
              studentCoachingEngagement: {
                  id: this.currentCoachingEngagement.id,
                  coachingEngagementId: this.currentCoachingEngagement.coachingEngagementId,
                  studentId: this.currentCoachingEngagement.studentId,
                  lockedBy: this.currentCoachingEngagement.lockedBy,
                  accessList: this.localAccessList
              }
          })
          this.accessListChanged = false
        }
        this.localAccessList = [...this.currentCoachingEngagement.accessList];
        this.$emit('update:isVisible', false);
      },
      async removeLock() {
        await this.updateStudentCoachingEngagement({ 
            id: this.currentCoachingEngagement.id,  
            studentCoachingEngagement: {
                id: this.currentCoachingEngagement.id,
                coachingEngagementId: this.currentCoachingEngagement.coachingEngagementId,
                studentId: this.currentCoachingEngagement.studentId,
                lockedBy: null,
                accessList: this.currentCoachingEngagement.accessList
            }
        })
        this.$emit('update:isVisible', false);
      },
      cancel() {
        this.localAccessList = [...this.currentCoachingEngagement.accessList];
        this.$emit('update:isVisible', false);
      },
      getAccessString(userId) {
        const accessEntry = this.localAccessList.find(entry => entry.id === userId);
        return accessEntry ? accessEntry.accessString : null;
        },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .modal {
    position: absolute;
    top: 50px;
    right: 50px;
    min-width: 400px;
    width: auto;
    height: auto;
    z-index: 1000; /* Ensure it's above other elements */

    /* Foundation border and border-radius utilities */
    background-color: #fff;
    border: 1px solid $light-gray;
    border-radius: $global-radius;
    margin-top: $global-margin;

    /* Box shadow for popup effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .modal-content {
    /* Styles for modal content */
    background-color: $white; /* Assuming $white is defined in your variables */
    color: $dark-gray;
    padding: $global-padding;
    border-radius: $global-radius;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    h3, h4 {
        color: $dark-gray; /* Explicitly set color for headings */
    }
  }

/* Additional styles for buttons, headings, etc., inside the modal */
.modal-content button {
  padding: $global-padding / 3 $global-padding / 2;
  margin-top: $global-margin / 2;
  border: none;
  border-radius: $global-radius / 2;
  background-color: $primary;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: darken($primary, 10%);
  }
}

.access-indicator {
    padding: 0.25em 0.5em;
    border-radius: $global-radius;
    color: $white;
    font-weight: bold;
    margin-right: 0.5em;

    &.access-granted {
      background-color: $success-color; 
    }

    &.access-denied {
      /* background-color: $alert-color; */
      background-color: $dark-gray;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between; // This will place the buttons at opposite ends
    margin-bottom: 1rem; // Adds some space between this group and the next element
  }

  .single-button {
    display: flex;
    justify-content: center; // Center the button on its line
  }

.lock-indicator {
	font-weight: bold;
	color: $white;
	padding: 0.25em 0.5em;
	border-radius: $global-radius;
	position: absolute;
	top: $global-padding * 2.8;
	right: $global-padding; 
	background-color: background-color;
  }
  
  .section {
    border: 1px solid $light-gray;
    padding: $global-padding / 2;
    margin-bottom: $global-margin / 2;
  }

  </style>
  