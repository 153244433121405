<template>
  <div class="lesson-plan-list-card" v-cloak>
    <router-link
      :to="{
        name: 'LessonPlan',
        params: {
          year: $route.params.year,
          classId: $route.params.classId,
          term: $route.params.term,
          lessonId: lesson.id,
        },
      }"
    >
      <div class="card">
        <div class="card-section card-padding">
          <div class="grid-x">
            <small class="date-range color--text-color cell auto"
              >{{ lesson.startDate | moment("D MMM YYYY") }} -
              {{ lesson.endDate | moment("D MMM YYYY") }}</small
            >
            <font-awesome-icon
              class="type-icon color--gray fa-lg"
              :icon="typeIcon(lesson.type)"
            ></font-awesome-icon>
          </div>
          <h5 class="lesson-title">{{ lesson.title }}</h5>
          <p class="color--brand-primary">
            <b>{{
              lesson.associatedDomains &&
              lesson.associatedDomains.join(", ") | labelize
            }}</b>
          </p>
          <div class="grid-x">
            <div
              class="image-wrapper"
              v-for="teacher in lesson.assignedTeachers"
              :key="teacher.id"
            >
              <profile-image
                :image-title="teacher.fullName"
                :image="teacher.image"
                :image-alt="teacher.displayName"
                :image-size="35"
              ></profile-image>
              <div class="text-center truncate">
                <small>{{ teacher.displayName }}</small>
              </div>
            </div>
          </div>
          <div class="grid-x">
            <p class="last-updated cell auto">
              <small
                class="color--text-color"
                v-if="lesson.updatedBy && lesson.updatedOn"
              >
                {{
                  $t("lessonPlanList.lastUpdated", {
                    updatedBy: lesson.updatedBy.fullName,
                    updatedOn: updatedOn(lesson.updatedOn),
                  })
                }}
              </small>
            </p>
            <span class="cell shrink hide">
              <a
                @click.prevent.stop="navigateToStudentRecord()"
                class="sans-serif-bold"
              >
                <font-awesome-icon
                  icon="id-card"
                  class="color--primary"
                ></font-awesome-icon>
                {{ $t("lessonPlan.link.studentRecord") }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </router-link>

    <more-dropdown
      :options="dropdownOptions"
      :identifier="$vnode.key"
    ></more-dropdown>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import ProfileImage from "./ProfileImage";
import MoreDropdown from "./MoreDropdown";

export default {
  name: "LessonPlanListCard",
  components: {
    FontAwesomeIcon,
    ProfileImage,
    MoreDropdown,
  },
  props: {
    lesson: {
      type: Object,
      props: {
        id: Number,
        startDate: String,
        endDate: String,
        updatedOn: String,
        updatedBy: Object,
        title: String,
        type: String,
        domains: Array,
        assignedTeachers: Array,
      },
    },
  },
  data() {
    return {
      dropdownOptions: [
        {
          label: "lessonPlanList.edit",
          method: this.goToEdit,
        },
        {
          label: "lessonPlanList.delete",
          method: this.showDeleteConfirmation,
        },
      ],
    };
  },
  methods: {
    updatedOn: function (updatedOn) {
      return moment(updatedOn).calendar();
    },
    typeIcon: function (type) {
      switch (type) {
        case "INDIVIDUAL":
          return "user";
        case "GROUP":
          return "users";
        default:
          return "question";
      }
    },
    goToEdit() {
      this.$router.push({
        name: "LessonPlan",
        params: {
          year: this.$store.getters.selectedYearFilter,
          classId: this.$route.params.classId,
          lessonId: this.lesson.id,
        },
      });
    },
    showDeleteConfirmation() {
      this.$modal.show({
        header: this.$t("lessonPlanCardList.delete.modalTitle"),
        text: this.$t("lessonPlanCardList.delete.modalText", {
          lessonPlan: this.lesson.title,
        }),
        includeConfirm: true,
        successCallback: this.deleteLessonPlan,
      });
    },
    deleteLessonPlan() {
      this.$store
        .dispatch("deleteLessonPlan", {
          classGroupId: this.$route.params.classId,
          lessonId: this.lesson.id,
        })
        .then(() => {
          this.$store.dispatch("getLessonPlans", {
            classGroupId: this.$route.params.classId,
            planYear: this.$store.getters.selectedYearFilter,
            planTerm: this.$route.params.term,
          });
        });
    },
    navigateToStudentRecord() {
      this.$router.push({
        name: "LessonPlanRecord",
        params: {
          classId: this.$route.params.classId,
          year: this.$route.params.year,
          term: this.$route.params.term,
          lessonId: this.lesson.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-plan-list-card {
  position: relative;
}
.lesson-title {
  margin-bottom: $global-margin / 3;
}
.type-icon {
  margin-right: $global-margin;
}
.date-range {
  margin-bottom: $global-margin / 4;
}
.last-updated {
  margin-top: $global-margin / 2;
  margin-bottom: 0;
}
</style>
