<template>
  <domain-list ref="annualPlanDomainList" :retain-elos="retainElos">
    <student-annual-plan-domain-list-card
      @addEloClicked="openDomainListAccordionFor"
      v-for="card in curriculum.domains"
      :key="card.id"
      :domain-id="card.id"
      :text-id="card.domainId"
      :text-name="card.domainName"
      :details="curriculum.domainDetails[card.id]"
      :retain-elos="retainElos"
      :annualPlanStatus="annualPlanStatus"
    >
    </student-annual-plan-domain-list-card>
  </domain-list>
</template>

<script>
import { mapGetters } from "vuex";
import DomainList from "@/components/DomainList";
import StudentAnnualPlanDomainListCard from "@/components/StudentAnnualPlanDomainListCard";

export default {
  name: "StudentAnnualPlanDomainList",
  components: {
    DomainList,
    StudentAnnualPlanDomainListCard,
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum",
    }),
  },
  data() {
    return {};
  },
  props: {
    annualPlanStatus: {
      type: Object,
    },
    retainElos: {
      type: Function,
    },
  },
  methods: {
    openDomainListAccordionFor(domainId, contentTarget) {
      this.$refs.annualPlanDomainList.openAccordionFor(domainId, contentTarget);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
