<template>
  <div>
    <p>{{ title }}</p>
    <div class="row underline" style="margin: 3vh 0" />
    <div>
      <p>Selected Options:</p>
      <p v-if="selectedList.school.length">
        School: {{ selectedList.school.join(", ") }}.
      </p>
      <p v-if="selectedList.programme.length">
        Programme: {{ selectedList.programme.join(", ") }}.
      </p>
      <p v-if="selectedList.classId.length">
        Class: {{ selectedList.classId.join(", ") }}.
      </p>
      <p v-if="selectedList.student.length">
        Student: {{ selectedList.student.join(", ") }}.
      </p>
      <p v-if="selectedList.domain.length">
        Domain: {{ selectedList.domain.join(", ") }}.
      </p>
      <p v-if="selectedList.tlo.length">
        TLO: {{ selectedList.tlo.join(", ") }}.
      </p>
      <p v-if="selectedList.elo.length">
        ELO: {{ selectedList.elo.join(", ") }}.
      </p>
      <p v-if="selectedList.programmeOutcome.length">
        Programme Outcome: {{ selectedList.programmeOutcome.join(", ") }}.
      </p>

      <p v-if="selectedList.learning.length">
        Learning Support Need: {{ selectedList.learning.join(", ") }}.
      </p>
      <p v-if="selectedList.behaviour.length">
        Behaviour Support Need: {{ selectedList.behaviour.join(", ") }}.
      </p>
      <p v-if="selectedList.supportNeed.length">
        Additional Support Need: {{ selectedList.supportNeed.join(", ") }}.
      </p>

      <p v-if="selectedList.coreSteps1 !== ''">
        {{ $t("masterReport.core.less", { step: selectedList.coreSteps1 }) }}
      </p>
      <p v-if="selectedList.attainment.length">
        Attainment: {{ selectedList.attainment.join(", ") }}.
      </p>
      <p v-if="selectedList.coreSteps2 !== ''">
        {{ $t("masterReport.core.more", { step: selectedList.coreSteps2 }) }}
      </p>
      <p v-if="selectedList.criteria !== ''">
        Criteria: {{ selectedList.criteria }}.
      </p>
    </div>
    <div class="row underline" style="margin: 3vh 0" />
    <div id="mainWrapper">
      <div id="outerWrapper">
        <div id="categories" style="width: 20%">
          <p
            :style="`margin: 0 0 0 ${i * 2}vw`"
            v-for="(select, i) of selectionList"
            :key="select"
          >
            {{ select }}
          </p>
        </div>
        <div
          v-for="(data, i) of year"
          :key="i"
          id="categories"
          style="width: 11.42%; text-align: center"
        >
          <p>{{ data.term === 0 ? "Baseline" : `Term ${data.term}` }}</p>
          <p :style="`color: ${data.term === 0 ? 'transparent' : 'black'}`">
            Assessment
            {{
              data.term === 1
                ? data.assessmentCycle
                : data.term === 2
                ? data.assessmentCycle + data.term - 1
                : data.term === 4
                ? data.assessmentCycle + data.term + 1
                : data.assessmentCycle + data.term
            }}
          </p>
          <p>{{ year[i].year }}</p>
          <div id="subHeader">
            <p style="width: 100%">Attainment</p>
          </div>
        </div>
      </div>
      <div id="sectionList">
        <div id="firstSection"></div>
        <div class="sections" v-for="(data, index) of comparisons" :key="index">
          <p
            class="shortSection"
            :style="`width: ${55 / attainmentData.length}%`"
            v-for="attain of attainmentData"
            :key="attain"
          >
            {{ attain[0] }}
          </p>
          <p class="longSection">S</p>
          <p class="longSection">T</p>
          <p class="longSection">%</p>
        </div>
      </div>
      <div v-for="(leader, i) of reportData" :key="i">
        <div
          id="outerWrapper"
          v-for="(select, j) of selectionList"
          :key="j"
          :style="`display: ${leader[`Name${j + 1}`] !== null ? '' : 'none'}`"
        >
          <div
            v-for="(section, l) of [1, ...comparisons]"
            :key="l"
            :style="`width: ${
              section === 1 ? '20%' : '11.42%'
            }; border: 1px solid #9e9999`"
          >
            <p
              v-if="section === 1"
              :style="`margin: 0 0 0 ${j * 2}vw;`"
              id="trimString"
            >
              {{ leader[`Name${j + 1}`] }}
            </p>
            <div v-else id="outerWrapper">
              <div
                v-for="(attain, k) of attainment"
                :key="k"
                :style="`margin: 0; text-align: center; user-select: none; color: ${
                  j === selectionList.length - 1 ? '' : 'transparent'
                }; width: ${
                  attain === 'TotalAttainment_Percentage' ||
                  attain === 'TotalAttainment' ||
                  attain === 'Students'
                    ? '15%'
                    : `${55 / attainmentData.length}%`
                }; background: ${
                  attain === 'TotalAttainment' ||
                  attain === 'Sum_Percentage' ||
                  attain === 'students' ||
                  attain === 'TotalAttainment_Percentage'
                    ? '#ddd'
                    : 'transparent'
                };
								font-size: .7rem;
								display: flex;
								justify-content: center;
								align-items: center;`"
              >
                {{
                  leader.value.length >= l && j + 1 === selectionList.length
                    ? leader.value[l - 1][attain] || "0"
                    : "0"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sectionList">
        <div id="firstSection">
          <p style="margin: 0; font-size: 0.7rem">Total</p>
        </div>
        <div
          class="sections"
          v-for="(data, index) of results"
          :key="index"
          style="font-size: 0.7rem"
        >
          <p
            :class="list < 5 ? 'shortSection' : 'longSection'"
            :style="
              list < 5
                ? `width: ${
                    list > attainmentData.length - 1
                      ? '15'
                      : 55 / attainmentData.length
                  }%;`
                : 'width: 15%;'
            "
            v-for="(attain, list) of attainment"
            :key="list"
          >
            {{ data[attain] }}
          </p>
        </div>
      </div>
    </div>
    <div class="row underline" />
    <div class="fluid">
      <button id="downloadButton" @click="exportExcel()">
        <img src="../assets/excel.png" />
        Export Data
      </button>
      <button
        id="downloadButton"
        @click="anotherExcel()"
        style="margin: 0 0 0 1%"
      >
        <img src="../assets/excel.png" />
        Export Report
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import XLSX from "xlsx";
import * as Excel from "exceljs";
import FileSaver from "file-saver";

export default {
  name: "MasterReportDetails",
  data: () => {
    return {
      reportData: [],
      attainment: ["TotalAttainment", "Students", "TotalAttainment_Percentage"],
      year: [],
      results: [{}, {}, {}, {}, {}, {}, {}],
    };
  },
  computed: {
    ...mapGetters([
      "leadershipReport",
      "selectionList",
      "comparisons",
      "reportType",
      "bodyReport",
      "selectedList",
      "attainmentData",
      "title",
    ]),
  },
  created() {
    const { selectionList, attainment } = this;

    const defaultValue = {
      Adaptive: 0,
      Cooperative: 0,
      Developing: 0,
      Experienced: 0,
      Routinised: 0,
      Students: 0,
      Sum_Completed: 0,
      Sum_Percentage: 0,
      Sum_Total: 0,
      TotalAttainment: 0,
      TotalAttainment_Percentage: 0,
    };

    // Parsing the comparisons into year
    this.year = this.comparisons;

    // Checking wether year is the same length or not
    if (this.year.length === 1) this.year.push(this.year[0]);

    // Sorting year based on which is smaller
    this.year.sort((a, b) => a.year - b.year);

    this.reportData = _.cloneDeep(
      this.leadershipReport[0].resultList[0].leaders
    );
    const maxColumns = 7;
    let column = 0;
    let rowCriteria = "";
    let currentCriteria = "";
    let row = 0;
    const newAttainment = [...this.attainmentData, ...attainment]; // 'AssessmentCycle', 'Term', 'Year'
    this.attainment = _.cloneDeep(newAttainment);

    this.reportData.forEach((leader) => {
      if (selectionList.includes("Student Name")) {
        // If it's a student list then they will try to replace studentname into Name5
        // And delete the studentname and studentid
        leader[`Code${selectionList.length}`] = leader.studentid;
        leader[`Name${selectionList.length}`] = leader.studentname;

        delete leader.studentname;
        delete leader.studentid;
      }
      if (rowCriteria === "") {
        for (let select = 1; select <= selectionList.length; select++) {
          rowCriteria = rowCriteria + leader[`Code${select}`];
        }
        for (let select = 1; select <= selectionList.length; select++) {
          this.reportData[row][`Name${select}`] = leader[`Name${select}`];
        }
      }
      currentCriteria = "";
      for (let select = 1; select <= selectionList.length; select++) {
        currentCriteria = currentCriteria + leader[`Code${select}`];
      }
      if (currentCriteria !== rowCriteria) {
        // fill in remaining columns if any
        while (column < maxColumns) {
          this.reportData[row].value.push(defaultValue);
          column++;
        }
        column = 0;
        row++;
        rowCriteria = currentCriteria;
        for (let select = 1; select <= selectionList.length; select++) {
          this.reportData[row][`Name${select}`] = leader[`Name${select}`];
        }
      }
      leader.value = [];
      let tempValue = {};

      while (
        this.year[column].year !== leader.Year ||
        this.year[column].term !== leader.Term ||
        this.year[column].assessmentCycle !== leader.AssessmentCycle
      ) {
        this.reportData[row].value.push(defaultValue);
        column++;
        if (column >= maxColumns) {
          column = 0;
        }
      }

      for (let att of newAttainment) {
        tempValue[att] = Math.round(leader[att]);
      }

      this.reportData[row].value.push(tempValue);
      column++;
      if (column >= maxColumns) {
        // after each max column here is the row change.
        column = 0;
      }
    });

    if (column !== 0) {
      while (column < maxColumns) {
        this.reportData[row].value.push(defaultValue);
        column++;
      }
    }

    for (let i = this.reportData.length - 1; i >= 0; i--) {
      if (this.reportData[i].value.length === 0) {
        this.reportData.splice(i, 1);
      }
    }

    const cloneTempLeaders = _.cloneDeep(this.reportData);

    this.reportData.forEach((leader, i) => {
      for (let select = 1; select <= selectionList.length; select++) {
        if (i > 0) {
          if (
            leader[`Name${select}`] === cloneTempLeaders[i - 1][`Name${select}`]
          ) {
            leader[`Name${select}`] = null;
          } else {
            break;
          }
        }
      }
    });

    for (let column = 0; column < maxColumns; column++) {
      newAttainment.forEach((att) => {
        if (
          this.results[column][att] === undefined ||
          isNaN(this.results[column][att])
        ) {
          this.results[column][att] = 0;
        }
        for (let row = 0; row < this.reportData.length; row++) {
          if (
            this.reportData[row].value[column][att] !== undefined &&
            !isNaN(this.reportData[row].value[column][att])
          ) {
            this.results[column][att] =
              this.results[column][att] +
              this.reportData[row].value[column][att];
          }
        }
      });
      // Calculate percentages for totals
      const { TotalAttainment, Students } = this.results[column];
      const sumCompleted = this.results[column].Sum_Completed;
      const sumTotal = this.results[column].Sum_Total;
      if (Students !== 0) {
        this.results[column].TotalAttainment_Percentage = Math.round(
          (TotalAttainment / Students) * 100
        );
      } else {
        this.results[column].TotalAttainment_Percentage = 0;
      }
      if (sumTotal !== 0) {
        this.results[column].Sum_Percentage = Math.round(
          (sumCompleted / sumTotal) * 100
        );
      } else {
        this.results[column].Sum_Percentage = 0;
      }
    }
  },
  methods: {
    ...mapActions(["downloadReport"]),
    async exportExcel() {
      // const { selectionList, attainment } = this
      const {
        body: { reportList },
      } = await this.downloadReport(this.bodyReport);
      const { resultList } = reportList[0];
      const { leaders } = resultList[0];

      let binaryWS = XLSX.utils.json_to_sheet(leaders);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      // export your excel
      XLSX.writeFile(wb, "Report.xlsx");
    },
    async anotherExcel() {
      const {
        attainment,
        year,
        selectionList,
        comparisons,
        attainmentData,
        results,
      } = this;
      const blobType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const workbook = new Excel.Workbook();

      workbook.creator = "Automated";
      workbook.lastModifiedBy = "Automated";
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();

      const sheet = workbook.addWorksheet("My Sheet");

      let tempSelection = [];

      selectionList.forEach((select, index) => {
        let el = _.cloneDeep(select);
        for (let i = 0; i <= index; i++) {
          el = "\xa0\xa0\xa0\xa0" + el;
        }
        tempSelection.push(el);
      });

      sheet.getColumn(1).header = [...tempSelection];
      sheet.getColumn(1).width = 20;

      sheet.mergeCells(1, 2, 1, attainment.length + 1);
      sheet.mergeCells(2, 2, 2, attainment.length + 1);
      sheet.mergeCells(3, 2, 3, attainment.length + 1);
      sheet.mergeCells(4, 2, 4, attainment.length + 1);

      sheet.getColumn(2).header = ["Baseline", "", year[0].year, "Attainment"];
      for (let i = 2; i <= attainment.length * 7; i++) {
        sheet.getColumn(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }

      sheet.mergeCells(1, attainment.length + 2, 1, attainment.length * 2 + 1);
      sheet.mergeCells(2, attainment.length + 2, 2, attainment.length * 2 + 1);
      sheet.mergeCells(3, attainment.length + 2, 3, attainment.length * 2 + 1);
      sheet.mergeCells(4, attainment.length + 2, 4, attainment.length * 2 + 1);

      sheet.getColumn(attainment.length + 2).header = [
        `Term ${year[1].term}`,
        "Assessment 1",
        year[1].year,
        "Attainment",
      ];
      sheet.getColumn(attainment.length + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      sheet.mergeCells(
        1,
        attainment.length * 2 + 2,
        1,
        attainment.length * 3 + 1
      );
      sheet.mergeCells(
        2,
        attainment.length * 2 + 2,
        2,
        attainment.length * 3 + 1
      );
      sheet.mergeCells(
        3,
        attainment.length * 2 + 2,
        3,
        attainment.length * 3 + 1
      );
      sheet.mergeCells(
        4,
        attainment.length * 2 + 2,
        4,
        attainment.length * 3 + 1
      );

      sheet.getColumn(attainment.length * 2 + 2).header = [
        `Term ${year[2].term}`,
        "Assessment 2",
        year[2].year,
        "Attainment",
      ];
      sheet.getColumn(attainment.length * 2 + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      sheet.mergeCells(
        1,
        attainment.length * 3 + 2,
        1,
        attainment.length * 4 + 1
      );
      sheet.mergeCells(
        2,
        attainment.length * 3 + 2,
        2,
        attainment.length * 4 + 1
      );
      sheet.mergeCells(
        3,
        attainment.length * 3 + 2,
        3,
        attainment.length * 4 + 1
      );
      sheet.mergeCells(
        4,
        attainment.length * 3 + 2,
        4,
        attainment.length * 4 + 1
      );

      sheet.getColumn(attainment.length * 3 + 2).header = [
        `Term ${year[3].term}`,
        "Assessment 3",
        year[3].year,
        "Attainment",
      ];
      sheet.getColumn(attainment.length * 3 + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      sheet.mergeCells(
        1,
        attainment.length * 4 + 2,
        1,
        attainment.length * 5 + 1
      );
      sheet.mergeCells(
        2,
        attainment.length * 4 + 2,
        2,
        attainment.length * 5 + 1
      );
      sheet.mergeCells(
        3,
        attainment.length * 4 + 2,
        3,
        attainment.length * 5 + 1
      );
      sheet.mergeCells(
        4,
        attainment.length * 4 + 2,
        4,
        attainment.length * 5 + 1
      );

      sheet.getColumn(attainment.length * 4 + 2).header = [
        `Term ${year[4].term}`,
        "Assessment 4",
        year[4].year,
        "Attainment",
      ];
      sheet.getColumn(attainment.length * 4 + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      sheet.mergeCells(
        1,
        attainment.length * 5 + 2,
        1,
        attainment.length * 6 + 1
      );
      sheet.mergeCells(
        2,
        attainment.length * 5 + 2,
        2,
        attainment.length * 6 + 1
      );
      sheet.mergeCells(
        3,
        attainment.length * 5 + 2,
        3,
        attainment.length * 6 + 1
      );
      sheet.mergeCells(
        4,
        attainment.length * 5 + 2,
        4,
        attainment.length * 6 + 1
      );

      sheet.getColumn(attainment.length * 5 + 2).header = [
        `Term ${year[5].term}`,
        "Assessment 5",
        year[5].year,
        "Attainment",
      ];
      sheet.getColumn(attainment.length * 5 + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      sheet.mergeCells(
        1,
        attainment.length * 6 + 2,
        1,
        attainment.length * 7 + 1
      );
      sheet.mergeCells(
        2,
        attainment.length * 6 + 2,
        2,
        attainment.length * 7 + 1
      );
      sheet.mergeCells(
        3,
        attainment.length * 6 + 2,
        3,
        attainment.length * 7 + 1
      );
      sheet.mergeCells(
        4,
        attainment.length * 6 + 2,
        4,
        attainment.length * 7 + 1
      );

      sheet.getColumn(attainment.length * 6 + 2).header = [
        `Term ${year[6].term}`,
        "Assessment 6",
        year[6].year,
        "Attainment",
      ];
      sheet.getColumn(attainment.length * 6 + 2).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      const attainmentRow = sheet.getRow(
        selectionList.length > 5 ? selectionList.length : 5
      );
      const nameRow = selectionList.length > 5 ? selectionList.length + 1 : 6;
      let i = 2;

      comparisons.forEach((compare) => {
        [...attainmentData, "S", "T", "%"].forEach((attain) => {
          attainmentRow.getCell(i).value = attain[0];
          attainmentRow.getCell(i).alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          i++;
        });
      });

      let nameIndex = 0;

      this.reportData.forEach((report) => {
        selectionList.forEach((select, jIndex) => {
          if (report[`Name${jIndex + 1}`] !== null) {
            let value = report[`Name${jIndex + 1}`];
            for (let i = 0; i <= jIndex; i++) {
              value = "\xa0\xa0\xa0\xa0" + value;
            }
            sheet.getRow(nameRow + nameIndex).getCell(1).value = value;

            let index = 2;

            if (jIndex + 1 === selectionList.length) {
              report.value.forEach((val) => {
                attainment.forEach((attain) => {
                  sheet.getRow(nameRow + nameIndex).getCell(index).value =
                    val[attain] || 0;
                  index++;
                });
              });
            }

            nameIndex++;
          }
        });
      });

      const totalRow = sheet.getRow(nameRow + nameIndex);
      let totalIndex = 2;
      totalRow.getCell(1).value = "Total";

      results.forEach((result) => {
        attainment.forEach((att) => {
          totalRow.getCell(totalIndex).value = result[att];
          totalIndex++;
        });
      });

      const data = await workbook.xlsx.writeBuffer();
      const blob = new Blob([data], { type: blobType });
      FileSaver.saveAs(blob, "Master_Report");
    },
  },
};
</script>
<style scoped>
#mainWrapper {
  margin: 0 0 3vh 0;
}

#subHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

#outerWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  user-select: none;
}

#categories {
  display: flex;
  flex-direction: column;
  border: 1px solid #9e9999;
}

#sectionList {
  display: flex;
  width: 100%;
  background: #ddd;
}

#firstSection {
  border: 1px solid #9e9999;
  width: 20%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid #9e9999;
  width: 11.42%;
  text-align: center;
}

.sections > .shortSection {
  width: 11%;
  margin: 0;
}

.sections > .longSection {
  width: 15%;
  margin: 0;
}

#trimString {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fluid {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#downloadButton {
  width: 10%;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9e9999;
  border-radius: 10px;
  cursor: pointer;
}

#downloadButton > img {
  margin: 0 5% 0 0;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 3vh 0;
}

.underline {
  width: 100%;
  margin: 0 0 3vh 0;
  border-bottom: 0.3vh solid grey;
}
</style>
