<template>
	<div class="coaching-engagement-title">
	  <h2 class="title">{{ title }}</h2>
	  <p class="engagement-date">Case open date: {{ engagementDate }}</p>
	  <div class="status-indicator" :class="{ completed: completed }">
		{{ completed ? "Completed" : "Ongoing" }}
	  </div>
	  <!-- Chat Button when chat is closed -->
	  <div class="lock-indicator" v-if="profile.roles[0] != 'ROLE_CLIENT'">
	  	<button v-if="!lockSetting" @click="lockSetting = true" class="button small hollow"
		  data-tooltip tabindex="1" 
		  :title="tooltip"
		  data-position="bottom"
		  data-alignment="left">
          <font-awesome-icon icon="lock" size="lg"></font-awesome-icon>
		  <font-awesome-icon icon="user-lock" size="lg" v-if="currentCoachingEngagement.lockedBy"></font-awesome-icon>	  
      	</button>
		<div v-if="lockSetting">
			<CoachingUserLock
				:isVisible.sync="lockSetting"
				:stakeholders="stakeholders"
				:currentUserId="profile.details.id">
			</CoachingUserLock>
		</div>
	  </div> 
	  <ChatWidget 
	  	:context="aiBotFormattedContext">
	  </ChatWidget>
	</div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import ChatWidget from "@/components/ChatWidget.vue";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import CoachingUserLock from '../views/Coaching/CoachingUserLock.vue';

  export default {
	name: "CoachingEngagementTitle",
	data() {
		return {
			lockSetting: false,
			context: "current goals: Strenghten the communication between father and child with outcomes: To have daily conversations at meal and dinner times peacefully",
		}
	},
	components: {
		ChatWidget,
		FontAwesomeIcon,
		CoachingUserLock,
	},
	props: ["title", "engagementDate", "completed", "stakeholders"],
	computed: {
		  ...mapGetters({
			  aiBotFormattedContext: 'aiBotFormattedContext',
			  profile: 'profile',
			  currentCoachingEngagement: 'currentCoachingEngagement',
		  }),
		  tooltip() {
			if (!this.currentCoachingEngagement.lockedBy) {
				return 'Set double lock to restrict access' 
			} else {
				return 'Manage access restrictions'
			}
		  }
	  },
  };
  </script>
  
  <style lang="scss" scoped>
  // @import 'src/styles/_settings.scss';
  
  .coaching-engagement-title {
	background-color: $white;
	border: 1px solid $light-gray;
	border-radius: $global-radius;
	padding: $global-padding;
	position: relative;
  }
  
  .title {
	font-size: 1.5em;
	font-weight: bold;
	color: $title-color;
	margin-bottom: $global-margin / 2;
  }
  
  .engagement-date {
	font-size: 0.875em;
	color: $secondary;
	margin-bottom: $global-margin / 2;
  }
  
  .status-indicator {
	font-weight: bold;
	color: $white;
	padding: 0.25em 0.5em;
	border-radius: $global-radius;
	position: absolute;
	top: $global-padding;
	right: $global-padding;
  
	&.completed {
	  background-color: $gray;
	}
  
	&:not(.completed) {
	  background-color: $success;
	}
  }

  .lock-indicator {
	font-weight: bold;
	color: $white;
	padding: 0.25em 0.5em;
	border-radius: $global-radius;
	position: absolute;
	top: $global-padding * 2.8;
	right: $global-padding; 
	background-color: background-color;
  }
  </style>
  