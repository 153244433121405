import { Quill } from 'vue-quill-editor';
const Parchment = Quill.import('parchment');

const indentLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const indentMultiplier = 15;
const indentUnit = 'px';

class IndentAttributor extends Parchment.Attributor.Style {
	add(node, value) {
		if (value === 0) {
			this.remove(node);
			return true;
		} else {
			return super.add(node, `${value * indentMultiplier}${indentUnit}`);
		}
	}

	value(node) {
		return parseFloat(super.value(node)) / indentMultiplier || undefined; // Don't return NaN
	}
}

const IndentStyle = new IndentAttributor('indent', 'text-indent', {
	scope: Parchment.Scope.BLOCK,
	whitelist: indentLevels.map(it => `${it * indentMultiplier}${indentUnit}`) // ["15px", "30px", "45px", "60px", "75px", "90px", "105px", "120px", "135px"]
});

export default { 'formats/indent': IndentStyle };
