import * as types from '../mutation-types';
import studentEloRemarksApi from '@/api/studentEloRemarks';

// initial state
const state = {
	eloRemarks: {}
};

const getters = {
	studentEloRemarks: (state) => (studentId, planYear, eloCode, external) => {
		return state.eloRemarks[studentId + '_' + planYear + '_' + eloCode + '_' + external];
	},
	eloRemarkEvidences: (state) => (studentId, planYear, eloCode, external) => {
		return state.eloRemarks[studentId + '_' + planYear + '_' + eloCode + '_' + external];
	}
};

// actions
const actions = {
	getStudentEloRemarks({ commit }, { studentId, eloCode, planYear, external }) {
		studentEloRemarksApi.getStudentEloRemarks(studentId, eloCode, planYear, external).then((resp) => {
			if (resp.data && resp.data.eloRemarks) {
				commit(types.SET_ELO_REMARKS, { remarkId: studentId + '_' + planYear + '_' + eloCode + '_' + external, eloRemarks: resp.data.eloRemarks });
			}
		});
	},
	postStudentEloRemark({ commit }, { studentId, eloCode, planYear, remark, external }) {
		studentEloRemarksApi.postStudentEloRemark(studentId, eloCode, planYear, remark, external).then((resp) => {
			if (resp.data) {
				commit(types.SET_ELO_REMARKS, { remarkId: studentId + '_' + planYear + '_' + eloCode + '_' + external, eloRemarks: resp.data });
			}
		});
	},
	updateStudentEloRemark({ commit }, { remarkId, studentId, eloCode, planYear, remark, external }) {
		return studentEloRemarksApi.updateStudentEloRemark(remarkId, studentId, eloCode, planYear, remark, external).then((resp) => {
			if (resp.data) {
				commit(types.UPDATE_ELO_REMARKS, { remarkId: studentId + '_' + planYear + '_' + eloCode + '_' + external, eloRemarks: resp.data });
			}
		});
	},
	deleteStudentEloRemark(_, remarkId) {
		return studentEloRemarksApi.deleteStudentEloRemark(remarkId);
	},
	setStudentEloRemark({ commit }, { studentId, eloRemarks }) {
		commit(types.SET_ELO_REMARKS, { studentId, eloRemarks });
	},
	addEvidenceToRemark({ commit }, { details, studentId, eloCode, planYear, external }) {
		return studentEloRemarksApi.postStudentEloRemarkEvidence(details).then((resp) => {
			if (resp.data) {
				commit(types.SET_ELO_REMARKS, { remarkId: studentId + '_' + planYear + '_' + eloCode + '_' + external, eloRemarks: resp.data });
			}
		});
	},
	getEloRemarkEvidence(_, { remarkId, fileUUID }) {
		return studentEloRemarksApi.getStudentEloRemarkEvidence(remarkId, fileUUID);
	}
};

// mutations
const mutations = {
	[types.SET_ELO_REMARKS](state, { remarkId, eloRemarks }) {
		state.eloRemarks = { ...state.eloRemarks, [remarkId]: eloRemarks };
	},
	[types.UPDATE_ELO_REMARKS](state, { remarkId, eloRemarks }) {
		state.eloRemarks = { [remarkId]: eloRemarks, ...state.eloRemarks };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
