<template>
  <div class="print-button no-print selector-radio" @click="print" v-cloak>
    <font-awesome-icon icon="print"></font-awesome-icon>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PrintButton",
  components: {
    FontAwesomeIcon,
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
