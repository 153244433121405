import Vue from 'vue'

export default {
	getUserList() {
		return Vue.http.get('user')
	},
	createUser(username, role, schoolId, accountLocked) {
		return Vue.http.post('user', {
			username, role, schoolId, accountLocked
		})
	},
	updateUser(userId, username, role, schoolId, accountLocked) {
		return Vue.http.put(`user/${userId}`, {
			username, role, schoolId, accountLocked
		})
	},
	deleteUser(userId) {
		return Vue.http.delete(`user/${userId}`)
	}
}
