<template>
  <div
    id="class-group-edit-side-tray-teachers"
    class="radius grid-y full-height"
    v-cloak
  >
    <div class="elo-side-tray-header cell shrink">
      <h6 class="margin-1">
        {{ isCoachingEngagement ? $t("classGroupEdit.assign.teachers.coaching", { title: "Engagement" }) : $t("classGroupEdit.assign.teachers", { title: "Class" }) }}
      </h6>
      <!-- Close button -->
      <button
        class="close-button color--medium-gray small"
        aria-label="Close menu"
        type="button"
        data-close
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="padding-left-1 padding-right-1 cell shrink">
      <side-tray-search
        v-if="readyToLoadSearchFields"
        :search-text-input-placeholder="searchTextInputPlaceholderValue"
        :search-term.sync="searchTerm"
        :initYear="classGroupFullDetails.year"
        :initSchoolId="classGroupFullDetails.schoolId"
        :initProgrammeName="classGroupFullDetails.programme.name"
        :initClassGroup="classGroupFullDetails.classGroup"
        @filter-results="setSelectedClassGroupIds"
        @show-unassigned="setShowUnassignedTeachers"
      />
    </div>

    <class-group-edit-side-tray-list
      v-model="cardsToBeAdded"
      :list="filteredTeacherResults"
      :existingClassGroupCardIds="existingClassGroupTeacherIds"
      :no-results-text="isCoachingEngagement ? $t('classGroupEdit.sideTray.noTeachersFound.coaching') : $t('classGroupEdit.sideTray.noTeachersFound')"
      @list-changed="clearInputs"
      class="
        margin-top-1
        padding-left-1 padding-right-1
        class-group-edit-side-tray-list
        cell
        auto
      "
    />

    <div class="action-selector padding-left-1 padding-right-1 cell shrink">
      <div class="grid-x align-right">
        <button
          type="submit"
          class="align-right button primary"
          @click="addTeachersToClassGroupSubmit()"
        >
          {{ $t("sideTray.done") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SideTraySearch from "./SideTraySearch/SideTraySearch";
import ClassGroupEditSideTrayList from "./ClassGroupEditSideTrayList";
import _ from "lodash";

export default {
  name: "ClassGroupEditSideTrayTeachers",
  components: {
    SideTraySearch,
    ClassGroupEditSideTrayList,
  },
  data() {
    return {
      // searchTextInputPlaceholder: this.classGroupFullDetails.classGroup.coachingEngagement ? this.$t("classGroupEdit.sideTray.search.teachers.coaching") : this.$t("classGroupEdit.sideTray.search.teachers"),
      // searchTextInputPlaceholder: this.selectedClassGroupFullDetails.classGroup.coachingEngagement ? this.$t("classGroupEdit.sideTray.search.teachers.coaching") : this.$t("classGroupEdit.sideTray.search.teachers"),
      searchTextInputPlaceholder: this.searchTextInputPlaceholderValue,
      showUnassignedTeachers: false,
      selectedClassGroupIds: [],
      cardsToBeAdded: [], // TODO: store ids only
      searchTerm: "",
    };
  },
  computed: {
    ...mapState({
      selectedClassGroupFullDetails: (state) =>
        state.classGroupSearch.selectedClassGroupFullDetails,
      unassignedTeachers: (state) => state.classGroupSearch.unassignedTeachers,
    }),
    classGroupFullDetails() {
      return this.selectedClassGroupFullDetails;
    },
    readyToLoadSearchFields() {
      return (
        this.classGroupFullDetails &&
        Object.keys(this.classGroupFullDetails).length > 0
      );
    },
    teacherResults() {
      return this.$store.getters.teachersForClassGroupIds(
        this.selectedClassGroupIds
      );
    },
    flatTeacherResults() {
      if (this.showUnassignedTeachers) {
        return this.unassignedTeachers;
      }

      let teachers = _.flatten(Object.values(this.teacherResults));
      teachers = _.uniqBy(teachers, "id");
      return teachers;
    },
    filteredTeacherResults() {
      return this.flatTeacherResults.filter(
        (it) =>
          ~it.fullName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) ||
          ~it.displayName.toLowerCase().indexOf(this.searchTerm.toLowerCase())
      );
    },
    existingClassGroupTeacherIds() {
      let existingTeachers = this.classGroupFullDetails.classGroup
        ? this.classGroupFullDetails.classGroup.teachers
        : [];

      if (existingTeachers.length > 0) {
        existingTeachers = Object.keys(_.keyBy(existingTeachers, "id"));
      }

      return existingTeachers;
    },
    searchTextInputPlaceholderValue() {
      if (this.selectedClassGroupFullDetails.classGroup.coachingEngagement) {
        return this.$t("classGroupEdit.sideTray.search.teachers.coaching")
      } else {
        return this.$t("classGroupEdit.sideTray.search.teachers")
      }
    },
    isCoachingEngagement() {
      return this.classGroupFullDetails && 
             this.classGroupFullDetails.classGroup && 
             this.classGroupFullDetails.classGroup.coachingEngagement;
    },
  },
  methods: {
    ...mapActions([
      "addTeachersToClassGroup",
      "getClassGroup",
      "getClassGroupFullDetailsFor",
      "getUnassignedTeachers",
      "getSideMenu",
    ]),
    setSelectedClassGroupIds(selectedClassGroupIds) {
      this.selectedClassGroupIds = selectedClassGroupIds;
    },
    setShowUnassignedTeachers(toShow) {
      if (toShow) {
        this.getUnassignedTeachers().then(() => {
          this.showUnassignedTeachers = toShow;
        });
        return;
      }

      this.showUnassignedTeachers = toShow;
    },
    clearInputs() {
      this.cardsToBeAdded = [];
    },
    addTeachersToClassGroupSubmit() {
      const { id: classId } = this.classGroupFullDetails.classGroup;
      const teacherIds = this.cardsToBeAdded;

      this.addTeachersToClassGroup({ classId, teacherIds }).then(() => {
        this.clearHandle();
        // TODO:
        // this.$toastr.success(this.$t('classGroupEdit.student.success.added', { studentName: this.studentDisplayName, className: this.classGroup.name }))

        setTimeout(() => {
          this.clearInputs();
          this.refreshSideMenu();
          this.fetchLatestClassGroupData();
          this.setShowUnassignedTeachers(false); // alternative is to set this value in ClassGroupEditSideTraySearch component as prop so that they are synced
        }, 300);
      });
    },
    clearHandle() {
      this.$emit("clear-handle");
    },
    refreshSideMenu() {
      this.getSideMenu(this.$route.params.year);
    },
    fetchLatestClassGroupData() {
      this.getClassGroup(this.$route.params.classId);
      this.getClassGroupFullDetailsFor(this.$route.params.classId);
      this.getUnassignedTeachers();
    },
  },
};
</script>

<style lang="scss" scoped>
.action-selector {
  padding-top: $global-padding / 1.5;
  width: 100%;

  .button {
    margin-bottom: $global-margin / 1.5;
    font-size: $small-font-size * 1.05;
  }
}
</style>
