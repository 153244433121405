import Vue from 'vue'
import store from '@/store'

export default (router) => {
	router.beforeEach((to, from, next) => {
		Vue.$modal.closeAll()
		if (to.matched.some(m => m.meta.requireAuth) && !store.getters.isAuthenticated) {
			setTimeout(() => {
				router.replace({ name: 'Login', query: { redirect: to.path } })
			})
		}
        document.title = (store.getters.isAuthenticated && store.getters.profile.tenantName !== undefined) ? store.getters.profile.tenantName : 'Snowflake';
		next()
	})

	router.beforeResolve((to, from, next) => {
		next()
	})

	router.afterEach((to, from) => {})

	router.onError(() => {})
}
