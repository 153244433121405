<template>
  <div class="sub-domain-list-card card" v-cloak>
    <div class="sub-domain-header card-divider">
      <span class="grid-y cell auto">{{ subDomain.subDomainName }} </span>
      <MoreDropdown
        :options="dropdownOptions"
        :identifier="`subdomain-${subDomain.id}`"
      >
      </MoreDropdown>
    </div>
    <div class="sub-domain-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MoreDropdown from "./MoreDropdown";
import constants from "@/constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CurriculumInterfaceSubdomainEditModal from "@/views/CurriculumInterface/CurriculumInterfaceSubdomainEditModal";
import CurriculumInterfaceTloAddModal from "@/views/CurriculumInterface/CurriculumInterfaceTloAddModal";
export default {
  name: "CurriculumInterfaceSubDomainListCard",
  /* eslint-disable vue/no-unused-components */
  components: {
    MoreDropdown,
    CurriculumInterfaceSubdomainEditModal,
    CurriculumInterfaceTloAddModal,
    FontAwesomeIcon,
    constants,
  },
  /* eslint-enable vue/no-unused-components */
  props: {
    subDomain: {
      type: Object,
    },
    domainCode: String,
    domainName: String
  },
  data() {
    return {
      fromData: {
        id: "SUBDOMAIN-" + this.subDomain.id,
      },
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
    dropdownOptions: function () {
      let dropdownOptions = {
        Add: {
          label: "tloListCard.add",
          method: this.showAddModal,
        },
        Edit: {
          label: "tloListCard.edit",
          method: this.showEditModal,
        },
        Delete: {
          label: "tloListCard.delete",
          method: this.curriculumInterfaceSubDomainDelete,
        },
      };
      let finalDropdownOptions = [];

      finalDropdownOptions.push(
        dropdownOptions["Add"],
        dropdownOptions["Edit"],
        dropdownOptions["Delete"]
      );
      return finalDropdownOptions;
    },
  },
  methods: {
    ...mapActions(["getCurriculum", "forceGetCurriculum"]),
    showEditModal() {
      let componentProps = {
        id: this.subDomain.id,
        subDomainName: this.subDomain.subDomainName,
        orderNumber: this.subDomain.orderNumber,
        subDomainId: this.subDomain.subDomainId,
        domainCode: this.domainCode,
        domainName: this.domainName
      };
      this.$modal.show({
        component: CurriculumInterfaceSubdomainEditModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    showAddModal() {
      let componentProps = {
        id: "TLO",
        subdomainId: this.subDomain.id,
        tloName: "",
        tloCode: "",
        domainCode: this.domainCode,
        domainName: this.domainName,
        subdomainName: this.subDomain.subDomainName,
        subdomainCode: this.subDomain.subDomainId,
      };
      this.$modal.show({
        component: CurriculumInterfaceTloAddModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    curriculumInterfaceSubDomainDelete() {
      let deletedSubDomainName = this.subDomain.subDomainName;
      let modalParams = {
        header: this.$t("elo.delete.modalTitle", { deletedSubDomainName }),
        text: deletedSubDomainName,
        includeConfirm: true,
        successCallback: this.doDeleteSubDomain,
      };

      this.$modal.show(modalParams);
    },
    async doDeleteSubDomain() {
      const curriculumInterface = this.subDomain.subDomainName;
      try {
        const { status, data } = await this.$store.dispatch(
          "curriculumInterfaceSubDomainDelete",
          this.fromData
        );
        if (status !== constants.RESPONSE_STATUS.OK && data) {
          return this.$toastr.error(data && data.message);
        }
        this.forceGetCurriculum();
        this.$toastr.success(
          this.$t("curriculumInterfaceModal.success.deleted", {
            curriculumInterface,
          })
        );
        this.$modal.closeAll();
      } catch (resp) {
        this.$toastr.error(resp.data && resp.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-domain-list-card.card {
  margin-bottom: 0;
  border-radius: 0;
  border: 0;
  overflow: visible;
}
.sub-domain-header {
  border-bottom: 1px solid $light-gray;
  background-color: $brand-secondary;
  color: $text-color;
  position: relative;

  span {
    @extend .sans-serif-bold;
    line-height: 1.3em;
  }

  .annual-plan &,
  .elo-aiep & {
    background-color: transparentize($brand-primary, 0.75);
  }

  .programme-plan &,
  .elo-piep & {
    background-color: transparentize($brand-tertiary, 0.75);
  }

  .elo-prog & {
    background-color: transparentize($darker-gray, 0.75);
  }

  .elo-side-tray & {
    font-size: $small-font-size;
  }

  @media print {
    padding: $global-padding / 2.5 $global-padding;
    background-color: $gray !important;
    font-size: $small-font-size;
  }
}
</style>

