<template>
  <div class="class-group-edit-side-tray-list" v-cloak>
    <div class="small-text" v-if="!list || list.length === 0">
      {{ noResultsText }}
    </div>
    <class-group-edit-side-tray-list-card
      v-else
      v-for="card in list"
      :key="card.id"
      :card-id="card.id"
      :image="card.image"
      :conditionTypes="card.conditionTypes"
      :text-content="card.displayName"
      :profile-name="card.fullName"
      :is-disabled="isDisabled(card.id)"
      :is-deletable="card.isDeletable"
      @change="markCardToBeAddedOrRemoved"
      @toggle-side-dropdown="toggleSideDropdown"
    >
    </class-group-edit-side-tray-list-card>
  </div>
</template>

<script>
import ClassGroupEditSideTrayListCard from "./ClassGroupEditSideTrayListCard";

export default {
  name: "ClassGroupEditSideTrayList",
  components: {
    ClassGroupEditSideTrayListCard,
  },
  data() {
    return {};
  },
  model: {
    prop: "cardsToBeAdded",
    event: "change",
  },
  props: {
    list: Array,
    existingClassGroupCardIds: {
      type: Array,
      required: true,
    },
    cardsToBeAdded: {
      type: Array,
      default: () => [],
    },
    noResultsText: {
      type: String,
      default: "No results found.",
    },
  },
  watch: {
    list() {
      this.$emit("list-changed");
    },
  },
  methods: {
    isDisabled(cardId) {
      const foundIndex = this.existingClassGroupCardIds.findIndex(
        (existingCardId) => {
          return cardId === existingCardId;
        }
      );

      return foundIndex > -1;
    },
    markCardToBeAddedOrRemoved(cardIdToBeAddedOrRemoved) {
      const foundIndex = this.cardsToBeAdded.findIndex((cardId) => {
        return cardIdToBeAddedOrRemoved === cardId;
      });

      if (foundIndex > -1) {
        let arr = [...this.cardsToBeAdded];
        arr.splice(foundIndex, 1);
        this.$emit("change", arr);
      } else {
        const cardIdToAdd = cardIdToBeAddedOrRemoved;
        this.$emit("change", [...this.cardsToBeAdded, cardIdToAdd]);
      }
    },
    toggleSideDropdown(event) {
      this.$emit("toggle-side-dropdown", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.class-group-edit-side-tray-list {
  overflow-y: auto;
  border-radius: 0;
  border: 1px solid $gray;
  padding: 0 $global-padding / 2;
}
.small-text {
  @extend .sans-serif-regular;
  font-size: $small-font-size;
}
</style>
