import Vue from 'vue'

export default {
	getCoachingLearningPoint (id) {
		return Vue.http.get(`coachingLearningPoint/${id}`)
	},
	createCoachingLearningPoint (studentCoachingEngagementId, description) {
		console.log("Posting LearningPoint: { studentCoachingEngagementId, description }", { studentCoachingEngagementId, description })
		return Vue.http.post('coachingLearningPoint', {
			studentCoachingEngagementId, description
		})
	},
	updateCoachingLearningPoint (id, description) {
		return Vue.http.put(`coachingLearningPoint/${id}`, {
			description
		})
	},
	deleteCoachingLearningPoint (id) {
		return Vue.http.delete(`coachingLearningPoint/${id}`)
	}
}