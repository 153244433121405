<template>
  <div class="annual-plan" id="sticky_annual" v-cloak>
    <div v-if="!hasNoPIEP">
      <div class="annual-plan-top-wrapper">
        <div data-sticky-container>
          <div
            ref="sticky"
            class="annual-plan-duration"
            v-foundation:Sticky.destroy
            data-anchor="sticky_annual"
            data-margin-top="0"
            data-sticky-on="small"
            data-check-every="0"
          >
            <div class="grid-x align-middle">
              <div
                class="
                  cell
                  medium-auto
                  small-12
                  grid-x
                  align-middle
                  annual-plan-title
                "
              >
                <span class="color--white padding-left-1">
                  <b>{{ $t("annual.learningObjectives") }}</b>
                </span>

                <span class="elo-count annual-plan-total-elo"
                  >{{ $t("studentDetailsAnnualPlan.totalElos") }}:
                  {{ totalAnnualElos }}</span
                >
              </div>
              <div class="cell medium-6">
                <div class="grid-x">
                  <div class="cell shrink annual-plan-cycle">
                    <span class="annual-plan-cycle-header">{{
                      $t("studentDetailsAnnualPlan.assessment")
                    }}</span>
                  </div>
                  <div
                    v-for="(header, i) in headers"
                    :key="i"
                    class="cell annual-plan-term-header auto"
                  >
                    <div class="annual-plan-term">
                      <div class="show-for-large truncate">
                        {{ $t("studentDetailsAnnualPlan." + header) }}
                      </div>
                      <div class="hide-for-large truncate">
                        {{
                          $t(
                            "studentDetailsAnnualPlan." + header + ".shorthand"
                          )
                        }}
                      </div>
                    </div>
                    <div class="grid-x annual-plan-access">
                      <span class="cell small-6">{{
                        $t("studentDetailsAnnualPlan.items.shorthand")
                      }}</span>
                      <span class="cell small-6">{{
                        $t("studentDetailsAnnualPlan.assessment.shorthand")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <student-annual-plan-domain-list
        :annualPlanStatus="annualPlanStatus"
        :retain-elos="eloFilter()"
      ></student-annual-plan-domain-list>
    </div>
    <div v-if="hasNoPIEP">
      <create-student-programme-plan
        :studentId="studentId"
        :year="year"
      ></create-student-programme-plan>
    </div>
  </div>
</template>


<script>
import _ from "lodash";
import stickyInitializer from "@/mixins/stickyInitializer";
import StudentAnnualPlanDomainList from "@/components/StudentAnnualPlanDomainList";
import CreateStudentProgrammePlan from "@/components/CreateStudentProgrammePlan";

export default {
  name: "StudentDetailsAnnualPlan",
  components: {
    StudentAnnualPlanDomainList,
    CreateStudentProgrammePlan,
  },
  mixins: [stickyInitializer],
  props: ["studentId", "year"],
  data() {
    return {
      headers: ["baseline", "term1", "term2", "term3", "term4"],
      hasNoPIEP: undefined,
      selectedYear: 0,
    };
  },
  computed: {
    studentAnnualPlan: function () {
      return this.$store.getters.studentAnnualPlan[this.studentId];
    },
    annualPlanStatus: function () {
      return this.$store.getters.studentAnnualPlanStatuses[
        this.$route.params.studentId
      ];
    },
    totalAnnualElos: function () {
      let planYear = this.year;
      return (
        this.studentAnnualPlan &&
        this.studentAnnualPlan[planYear] &&
        this.studentAnnualPlan[planYear].studentElos &&
        this.studentAnnualPlan[planYear].studentElos.length
      );
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.hasNoPIEP = undefined;
    this.loadData(to.params.studentId, to.params.year);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadData(to.params.studentId, to.params.year);
    });
  },
  methods: {
    loadData(studentId, planYear) {
      this.selectedYear = planYear;
      this.$store.dispatch("getCurriculum");
      this.$store
        .dispatch("getStudentPlan", {
          studentId: studentId,
          planYear: planYear,
        })
        .then((resp) => {
          if (!resp) {
            this.hasNoPIEP = true;
          } else {
            this.$store.dispatch("getStudentAnnualPlan", {
              studentId: studentId,
              planYear: planYear,
            });
            this.$store.dispatch("getStudentAnnualPlanStatus", {
              studentId: studentId,
              planYear: planYear,
            });
            this.hasNoPIEP = false;
          }
        });
    },
    changePlanYear(studentId, planYear) {
      planYear -= 1;
      this.loadData(studentId, `${planYear}`);
    },
    isEmpty(details) {
      return _.isEmpty(details);
    },
    eloFilter: function () {
      let planYear = this.selectedYear;
      return (elos) => {
        let retainList =
          this.studentAnnualPlan &&
          this.studentAnnualPlan[planYear] &&
          this.studentAnnualPlan[planYear].studentElos
            ? _.map(this.studentAnnualPlan[planYear].studentElos, "elo")
            : [];
        let idlist = _.map(retainList, "id");
        return _.filter(elos, function (elo) {
          return _.includes(idlist, elo.id);
        });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.annual-plan-top-wrapper {
  border-top: 2px solid $light-gray;
  border-right: 2px solid $light-gray;
  border-left: 2px solid $light-gray;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: $title-color;
  color: $white;

  & + .domain-list {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
  }
}
.annual-plan-total-elo {
  background-color: transparentize($white, 0.85);

  @include breakpoint(small down) {
    position: relative;
    right: $global-margin;
    margin-left: auto;
    margin-right: 0;
  }
}
.annual-plan-term-header {
  border-left: 1px solid $darker-gray;
  color: $lighter-gray;
  text-align: center;

  .cell {
    padding-top: $global-padding / 2;
    padding-bottom: $global-padding / 2;
    font-size: 0.8em;
    text-align: center;
    line-height: 1.4em;
  }

  @include breakpoint(small down) {
    border-top: 1px solid $darker-gray;
  }
}
.annual-plan-title {
  @include breakpoint(small down) {
    padding-top: $global-padding / 2;
    padding-bottom: $global-padding / 2;
  }
}
.annual-plan-cycle {
  position: relative;

  .annual-plan-cycle-header {
    position: absolute;
    margin-bottom: 0;
    margin-left: -0.85em;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-90deg);
    transform-origin: center;
    font-size: 0.63em;
    color: $gray;
    z-index: 20;
    white-space: nowrap;

    @include breakpoint(small down) {
      color: $text-color;
    }
  }
}

.annual-plan-term {
  @extend %sans-serif-bold;
  display: block;
  padding: $global-padding / 2 $global-padding;
  border-bottom: 1px solid $darker-gray;
  font-size: 0.85em;
  line-height: 1.2em;
  white-space: nowrap;

  @include breakpoint(small down) {
    white-space: normal;
  }
}
.annual-plan-access {
  display: flex;
  position: relative;
  font-size: 0.85em;
  color: $lighter-gray;

  .cell {
    display: block;

    &:last-child {
      border-left: 1px solid $darker-gray;
    }
  }
}
.filter {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.sticky {
  background-color: transparent;

  &.is-stuck {
    background-color: $black;
  }
}
</style>
