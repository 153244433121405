// coachingOutcomeSelfAssessment.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingOutcomeSelfAssessmentApi from '@/api/coachingOutcomeSelfAssessment'
import constants from '@/constants';

// initial state
const state = {
  // old coachingEngagement: null,
  coachingOutcomeSelfAssessment: {},
  coachingOutcomeSelfAssessments: [],
};

// getters
const getters = {
  coachingOutcomeSelfAssessment: state => state.coachingOutcomeSelfAssessment,
  coachingOutcomeSelfAssessments: state => state.coachingOutcomeSelfAssessments,
};

// actions
const actions = {
  async getCoachingOutcomeSelfAssessment({ commit }, id) {
    const response = await CoachingOutcomeSelfAssessmentApi.getCoachingOutcomeSelfAssessment(id)
    commit(types.SET_COACHING_OUTCOME_SELF_ASSESSMENT, response.data)
  },

  async getCoachingOutcomeSelfAssessmentsForSession({ commit }, coachingSessionId) {
    const response = await CoachingOutcomeSelfAssessmentApi.getCoachingOutcomeSelfAssessmentsForSession(coachingSessionId)
    console.log("STORE: getCoachingOutcomeSelfAssessmentsForSession: ", response.data)
    if (response.data) {
      console.log("response.data", response.data)
      commit(types.SET_COACHING_OUTCOME_SELF_ASSESSMENTS, response.data)
      return response.data
    }
    // Here we should somehow associate these assessments with the session
  },

  async createCoachingOutcomeSelfAssessment({ commit, dispatch }, { studentCoachingEngagementId, coachingOutcomeId, coachingSessionId, assessmentTime, result }) { // Note that studentCoachingEngagementId is included in the call
    console.log("STORE: createCoachingOutcomeSelfAssessment - { coachingOutcomeId, coachingSessionId, assessmentTime, result }:", { coachingOutcomeId, coachingSessionId, assessmentTime, result })
    const response = await CoachingOutcomeSelfAssessmentApi.createCoachingOutcomeSelfAssessment(coachingOutcomeId, coachingSessionId, assessmentTime, result);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_OUTCOME_SELF_ASSESSMENT, response.data.coachingOutcomeSelfAssessment);
      // here we need to get the studentCoachingEngagement again to have the latest info with coachingOutcomeSelfAssessment
      await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
    }
  },

  async updateCoachingOutcomeSelfAssessment({ state, commit, dispatch, rootState }, { id, studentCoachingEngagementId, assessmentTime, result }) { // Note that studentCoachingEngagementId is included in the call
    const response = await CoachingOutcomeSelfAssessmentApi.updateCoachingOutcomeSelfAssessment(id, assessmentTime, result)
    commit(types.UPDATE_COACHING_OUTCOME_SELF_ASSESSMENT, response.data.coachingOutcomeSelfAssessment)
    // here we need to get the StudentCoachingEngagement again to have the latest info
    // NOTE: - this requires the studentCoachingEngagementId to be provided - we include in the call for now
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },

  async deleteCoachingEngagement({ commit, dispatch }, id, studentCoachingEngagementId) { // Note that studentCoachingEngagementId is included in the call
    await CoachingOutcomeSelfAssessmentApi.deleteCoachingOutcomeSelfAssessment(id)
    commit(types.DELETE_COACHING_OUTCOME_SELF_ASSESSMENT, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_OUTCOME_SELF_ASSESSMENT](state, coachingOutcomeSelfAssessment) {
    Vue.set(state.coachingOutcomeSelfAssessment, coachingOutcomeSelfAssessment);
  },
  [types.SET_COACHING_OUTCOME_SELF_ASSESSMENTS] (state, coachingOutcomeSelfAssessments) {
    console.log("coachingOutcomeSelfAssessments:", coachingOutcomeSelfAssessments)
    Vue.set(state.coachingOutcomeSelfAssessments, coachingOutcomeSelfAssessments)
  },
  [types.ADD_COACHING_OUTCOME_SELF_ASSESSMENT] (state, coachingOutcomeSelfAssessment) {
    // state.coachingOutcomeSelfAssessments.coachingOutcomeSelfAssessments.unshift(coachingOutcomeSelfAssessment) // was unshift then push
    if (state.coachingOutcomeSelfAssessments.coachingOutcomeSelfAssessments && state.coachingOutcomeSelfAssessments.coachingOutcomeSelfAssessments.length > 0) {
      Vue.set(state.coachingOutcomeSelfAssessments, 'coachingOutcomeSelfAssessments', [coachingOutcomeSelfAssessment, ...state.coachingOutcomeSelfAssessments.coachingOutcomeSelfAssessments])
    } else {
      Vue.set(state.coachingOutcomeSelfAssessments, 'coachingOutcomeSelfAssessments', [coachingOutcomeSelfAssessment])
    }
  },
  [types.UPDATE_COACHING_OUTCOME_SELF_ASSESSMENT] (state, coachingOutcomeSelfAssessment) {
    Vue.set(state.coachingOutcomeSelfAssessment, coachingOutcomeSelfAssessment)
    // const index = state.coachingEngagements.findIndex(e => e.id === engagement.id)
    // if (index !== -1) {
    //   Vue.set(state.coachingEngagements, index, engagement)
    // }
  },
  [types.DELETE_COACHING_OUTCOME_SELF_ASSESSMENT] (state, coachingOutcomeSelfAssessmentId) {
    state.coachingOutcomeSelfAssessments = state.coachingOutcomeSelfAssessments.filter(e => e.id !== coachingOutcomeSelfAssessmentId)
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
