<template>
  <div class="edit-elo-modal" v-cloak>
    <h4 class="modal-title">
      {{ student.displayName }}{{ $t("editEloDetails.title") }}
    </h4>

	<div class="edit-elo-modal-content">
	  <elo-list-card-details
		class="elo-card margin-top-1 card-section"
	      :elo-code="eloCode"
		  :elo-name="eloName"
		  :programme-outcome="programmeOutcome"
		  :programme-codes="programmeCodes"
		  :link="link"
	  >
	  </elo-list-card-details>

      <div
        v-if="eloNotInAnnualPlan"
        class="elo-inactive margin-top-1 card-section"
      >
        <span class="elo-inactive-text">{{
          $t("editEloDetails.inactive")
        }}</span>
      </div>

      <div class="edit-elo-modal-content-body margin-top-1">
        <tabs-content :tabs-content-list="tabList">
          <edit-elo-modal-general
            :slot="tabList[0].target"
            :elo-code="eloCode"
            :elo-name="eloName"
            :student-id="studentId"
            :selected-year="selectedYear"
            :editable="!eloNotInAnnualPlan"
          >
          </edit-elo-modal-general>

          <edit-elo-modal-analysis
            :slot="tabList[1].target"
            :elo-code="eloCode"
            :editable="!eloNotInAnnualPlan"
            :student-id="studentId"
            :selected-year="selectedYear"
            :selected-term="selectedTerm"
          >
          </edit-elo-modal-analysis>

          <edit-elo-modal-attainment
            :slot="tabList[2].target"
            :elo-code="eloCode"
            :editable="!eloNotInAnnualPlan"
            :student-id="studentId"
            :selected-year="selectedYear"
            :selected-term="selectedTerm"
          >
          </edit-elo-modal-attainment>

          <edit-elo-modal-remarks
            :slot="tabList[3].target"
            :elo-code="eloCode"
            :editable="!eloNotInAnnualPlan"
            :student-id="studentId"
            :selected-year="selectedYear"
          >
          </edit-elo-modal-remarks>
        </tabs-content>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import TabsContent from "@/components/TabsContent";
import EloListCardDetails from "@/components/EloListCardDetails";
import EditEloModalGeneral from "./EditEloModalGeneral";
import EditEloModalAnalysis from "./EditEloModalAnalysis";
import EditEloModalAttainment from "./EditEloModalAttainment";
import EditEloModalRemarks from "./EditEloModalRemarks";

export default {
  name: "EditEloModal",
  components: {
    TabsContent,
    EloListCardDetails,
    EditEloModalGeneral,
    EditEloModalAnalysis,
    EditEloModalAttainment,
    EditEloModalRemarks,
  },
  props: {
    eloCode: String,
    eloName: String,
	link: String,
    programmeCodes: Array,
    programmeOutcome: Array,
    studentId: String,
    selectedYear: Number,
    selectedTerm: Number,
  },
  data() {
    return {
      tabList: [
        {
          label: "editEloDetails.tabs.general",
          target: "general",
          icon: "cog",
          isSelected: true,
        },
        {
          label: "editEloDetails.tabs.analysis",
          target: "analysis",
          icon: "tasks",
          isSelected: false,
        },
        {
          label: "editEloDetails.tabs.attainment",
          target: "attainment",
          icon: "bullseye",
          isSelected: false,
        },
        {
          label: "editEloDetails.tabs.remarks",
          target: "remarks",
          icon: ["far", "comment"],
          isSelected: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      studentPlan: "studentPlan",
    }),
    student: function () {
      return this.$store.getters.studentProfile[this.studentId]
        ? this.$store.getters.studentProfile[this.studentId].student
        : {};
    },
    eloNotInAnnualPlan: function () {
      if (
        this.$route.name === "Aiep" ||
        this.$route.name === "Aiep0" ||
        this.$route.name === "Aiep1" ||
        this.$route.name === "Aiep2"
      ) {
        return false;
      }
      return (
        this.studentPlan[this.studentId] &&
        this.studentPlan[this.studentId].annualPlansEloCodes &&
        !_.includes(
          this.studentPlan[this.studentId].annualPlansEloCodes,
          this.eloCode
        )
      );
    },
  },
  destroyed() {
    // Clear modal
    this.$store.dispatch("setStudentEloDetails", {
      studentId: this.studentId,
      eloDetails: {},
    });
    this.$store.dispatch("setStudentTaskItemList", {
      studentId: this.studentId,
      taskItemList: {},
    });
    this.$store.dispatch("setStudentAttainment", {
      studentId: this.studentId,
      attainment: {},
    });
    this.$store.dispatch("setTaskItemCountSummary", {
      studentId: this.studentId,
      taskItemCountSummary: {},
    });
    this.$store.dispatch("setStudentEloRemark", {
      studentId: this.studentId,
      eloRemarks: {},
    });
  },
  async created() {
    await this.getStudentProfile({
      studentId: this.studentId,
      planYear: this.selectedYear,
    });
  },
  methods: {
    ...mapActions(["getStudentProfile"]),
  },
};
</script>

<style lang="scss" scoped>
.elo-card {
  background-color: $lighter-gray;
  border-radius: $global-radius;
}
.elo-inactive {
  border-radius: $global-radius;
  background-color: $pale-warning;
}
</style>
