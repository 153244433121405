<template>
	<div v-cloak>
		<!-- side menu -->
		<div class="sidebar position-fixed grid-y no-print" v-if="isOpen">
			<div class="collapse-button" @click="setCanvasConfig(!isOpen)">
				<span class="collapse-icon"><font-awesome-icon icon="angle-double-left" size="1x"></font-awesome-icon>&nbsp;&nbsp;{{ $t('sidebar.collapse') }}</span>
			</div>

			<the-sidebar-profile :expanded="true"></the-sidebar-profile>

			<the-sidebar-year v-if="!isHyper" class="margin-bottom-1"></the-sidebar-year>
			<div class="cell auto" v-if="!isHyper">
				<the-sidebar-menu></the-sidebar-menu>
			</div>
		</div>

		<!-- small side menu -->
		<div class="small-sidebar position-fixed grid-y no-print" v-else>
			<div class="collapse-button" @click="setCanvasConfig(!isOpen)">
				<span class="collapse-icon"><font-awesome-icon class="collapse-icon" icon="angle-double-right" size="1x"></font-awesome-icon></span>
				<p class="sidebar-link hide-for-large">{{ $t('sidebar.classList') }}</p>
			</div>
			<div class="show-for-large">
				<the-sidebar-profile :expanded="false"></the-sidebar-profile>
			</div>

			<div class="cell auto">
				<the-sidebar-dropdown-menu></the-sidebar-dropdown-menu>
			</div>
		</div>

		<!-- content area -->
		<div class="small-menu-padding content print-content" :class="isOpen ? 'sidebar-open' : ''">
			<div class="grid-y align-center">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import TheSidebarProfile from './TheSidebarProfile'
import TheSidebarYear from './TheSidebarYear'
import TheSidebarMenu from './TheSidebarMenu'
import TheSidebarDropdownMenu from './TheSidebarDropdownMenu'
import store from '@/store'
export default {
	name: 'TheSidebar',
	components: {
		FontAwesomeIcon,
		TheSidebarProfile,
		TheSidebarYear,
		TheSidebarMenu,
		TheSidebarDropdownMenu
	},
	data () {
		return {
            isHyper: false,
			menu: [{
				key: 1,
				label: 'menu1'
			}, {
				ket: 2,
				label: 'menu2'
			}]
		}
	},
     created() {
        this.isHyper = this.isHyperCheck();
    },
	computed: {
		...mapGetters({
			isOpen: 'canvasConfig'
		})
	},
	methods: {
		setCanvasConfig (canvasState) {
			this.$store.dispatch('saveCanvasConfig', canvasState)
		},
        isHyperCheck() {
            const role = store.getters.profile.roles;
            const isHyper = role.includes('ROLE_HYPER');
            return isHyper
        }
	}
}
</script>

<style lang="scss" scoped>
.small-sidebar,
.sidebar {
  height: 100%;
  top: 0;
  left: 0;
  background-color: $title-color;
  color: $white;
}

.sidebar-link {
  margin-top: 7em;
  margin-bottom: 0;
  transform: rotate(-90deg);
  transform-origin: left top 0;
  white-space: nowrap;
}

.sidebar {
  width: map-get($offcanvas-sizes, small);
  z-index: $sidemenu-zindex;
}

.small-sidebar {
  width: $small-sidebar-width;
  z-index: $sidemenu-zindex;

  @include breakpoint(medium down) {
    width: $tiny-sidebar-width;
  }
}

.collapse-button {
  padding: $global-padding / 2.3;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  color: inherit;
  opacity: 0.6;
}

.content {
  padding-left: $small-sidebar-width;

  &.sidebar-open {
    padding-left: map-get($offcanvas-sizes, small);
  }

  @include breakpoint(medium down) {
    padding-left: $tiny-sidebar-width;
  }
}
</style>
