import * as types from '../mutation-types';
import studentEloTaskItemsApi from '@/api/studentEloTaskItems';

// initial state
const state = {
	taskItemList: {}
};

const getters = {
	studentTaskItemListForStudentId: (state) => (studentId) => {
		return state.taskItemList[studentId];
	}
};

// actions
const actions = {
	getStudentTaskItemList({ commit }, { studentId, eloCode, planYear, planTerm }) {
		studentEloTaskItemsApi.getStudentTaskItemList(studentId, eloCode, planYear, planTerm).then((resp) => {
			if (resp.data && resp.data.eloTaskItemList) {
				commit(types.SET_TASK_ITEM_LIST, { studentId, taskItemList: resp.data.eloTaskItemList });
			}
		});
	},
	addItemToTaskItemList({ commit }, { studentId, eloCode, planYear, planTerm, taskDescription, subItemDescription }) {
		return studentEloTaskItemsApi.addItemToTaskItemList(studentId, eloCode, planYear, planTerm, taskDescription, subItemDescription).then((resp) => {
			if (resp.data && resp.data.eloTaskItemList) {
				commit(types.SET_TASK_ITEM_LIST, { studentId, taskItemList: resp.data.eloTaskItemList });
			}
		});
	},
	cloneTaskItemListFromTerm({ commit }, { originalStudentId, studentId, eloCode, planYear, planTerm, targetTerm, sourceStudentId, multi }) {
		return studentEloTaskItemsApi.cloneTaskItemListFromTerm(studentId, eloCode, planYear, planTerm, targetTerm, sourceStudentId, multi).then((resp) => {
			if (resp.data && resp.data.eloTaskItemList) {
				const realId = originalStudentId || studentId;
				commit(types.SET_TASK_ITEM_LIST, { studentId: realId, taskItemList: resp.data.eloTaskItemList });
			}
		});
	},
	removeItemFromTaskItemList({ commit }, { studentId, eloCode, planYear, planTerm, studentTaskItemId }) {
		return studentEloTaskItemsApi.removeItemFromTaskItemList(studentId, eloCode, planYear, planTerm, studentTaskItemId).then((resp) => {
			if (resp.data && resp.data.eloTaskItemList) {
				commit(types.SET_TASK_ITEM_LIST, { studentId, taskItemList: resp.data.eloTaskItemList });
			}
		});
	},
	updateTaskDescriptionInTaskItem({ dispatch }, { studentId, eloCode, planYear, planTerm, studentTaskItemId, taskDescription, subItemDescription }) {
		const updateParams = {
			studentTaskItemId,
			taskDescription,
			subItemDescription
		};

		return dispatch('updateTaskItemList', {
			studentId,
			eloCode,
			planYear,
			planTerm,
			updateParams
		});
	},
	updateFocusInTaskItem({ dispatch }, { studentId, eloCode, planYear, planTerm, studentTaskItemId, focus }) {
		const updateParams = {
			studentTaskItemId,
			focus
		};

		return dispatch('updateTaskItemList', {
			studentId,
			eloCode,
			planYear,
			planTerm,
			updateParams
		});
	},
	updateCategoryInTaskItem({ dispatch }, { studentId, eloCode, planYear, planTerm, studentTaskItemId, category }) {
		const updateParams = {
			studentTaskItemId,
			category
		};

		return dispatch('updateTaskItemList', {
			studentId,
			eloCode,
			planYear,
			planTerm,
			updateParams
		});
	},
	updateOrderInTaskItemList({ dispatch }, { studentId, eloCode, planYear, planTerm, fromIndex, toIndex }) {
		const updateParams = {
			fromIndex,
			toIndex
		};

		return dispatch('updateTaskItemList', {
			studentId,
			eloCode,
			planYear,
			planTerm,
			updateParams
		});
	},
	updatePromptInTaskItem({ dispatch }, { studentId, eloCode, planYear, planTerm, planWeek, studentTaskItemId, promptHierarchyCode }) {
		const updateParams = {
			studentTaskItemId,
			measurementNumber: planWeek,
			prompt: promptHierarchyCode
		};

		return dispatch('updateTaskItemList', { studentId, eloCode, planYear, planTerm, updateParams });
	},
	updateTaskItemList({ commit, dispatch }, { studentId, eloCode, planYear, planTerm, updateParams }) {
		return studentEloTaskItemsApi.updateTaskItemList(studentId, eloCode, planYear, planTerm, updateParams).then((resp) => {
			if (resp.data && resp.data.eloTaskItemList) {
				commit(types.SET_TASK_ITEM_LIST, { studentId, taskItemList: resp.data.eloTaskItemList });
			}
		}).catch((error) => {
			dispatch('addToastr', {
				type: 'alert',
				message: 'Failed to update. Please try again',
				error
			});
		});
	},
	updateTaskItemListRemarks({ commit, state }, { studentId, studentTaskItemId, hasRemarks }) {
		const newTaskItemList = state.taskItemList[studentId];
		if (newTaskItemList && newTaskItemList.studentTaskItems) {
			const taskItem = newTaskItemList.studentTaskItems.find(it => it.id === studentTaskItemId) || {};
			taskItem.hasRemarks = hasRemarks;

			commit(types.SET_TASK_ITEM_LIST, { studentId, taskItemList: newTaskItemList });
		}
		return true;
	},
	setStudentTaskItemList({ commit }, { studentId, taskItemList }) {
		commit(types.SET_TASK_ITEM_LIST, { studentId, taskItemList });
	}
};

// mutations
const mutations = {
	[types.SET_TASK_ITEM_LIST](state, { studentId, taskItemList }) {
		state.taskItemList = { ...state.taskItemList, [studentId]: taskItemList };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
