<template>
  <div v-cloak>
    <div
      class="grid-x grid-margin-x top-info-wrapper"
      v-if="filteredClassGroup && filteredClassGroup.id"
    >

      <div class="grid-x grid-margin-x mid-info-wrapper">

        <div class="cell shrink margin-bottom-1 header-container">
          <h1 class="page-title margin-right-1">{{ $t(pageTitle) }}</h1>
          <div v-if="this.classGroup.coachingEngagement" class="status-indicator" :class="{ completed: this.classGroup.coachingEngagement.completed }">
            {{ this.classGroup.coachingEngagement.completed ? "Completed" : "Ongoing" }}
          </div>
        </div>
        <div class="cell shrink margin-bottom-1 header-container">
          <h6
            class="
              sans-serif-regular
              color--darker-gray
              cell
              shrink
              margin-bottom-0
            "
          >
            {{ filteredClassGroup.name }}
            <span class="cell shrink">
              <small class="banner-date"
                >{{ filteredClassGroup.session }},&nbsp;&nbsp;{{
                  filteredClassGroup.year
                }}</small
              >
            </span>
          </h6>
        </div>

      </div>

      <div class="cell small-12 medium-auto grid-x align-right">
        <div
          class="image-wrapper"
          v-for="teacher in filteredClassGroup.teachers"
          :key="teacher.id"
        >
          <profile-image
            :image-title="teacher.fullName"
            :image="teacher.image"
            :image-alt="teacher.displayName"
            :image-size="35"
          ></profile-image>
          <div class="text-center truncate">
            <small>{{ teacher.displayName }}</small>
          </div>
        </div>
      </div>
    </div>

    <p v-else>{{ $t("classGroup.noClassFound") }}</p>
  </div>
</template>

<script>
import ProfileImage from "@/components/ProfileImage";

export default {
  name: "ClassGroupProfile",
  components: {
    ProfileImage,
  },
  props: {
    classGroup: Object,
  },
  computed: {
    filteredClassGroup: function () {
      let classGroup = { ...this.classGroup };
      let profile = this.$store.getters.profile;
      if (profile.details) {
        classGroup.teachers =
          classGroup.teachers &&
          classGroup.teachers.filter((it) => it.id !== profile.details.id);
      }
      return classGroup;
    },
    pageTitle: function () {
      if (this.$route.name === "LessonPlanList") {
        return "lessons";
      } else if (this.$route.name === "ClassGroup") {
        if (this.classGroup.coachingEngagement) {
          return "coachingEngagement";
        }
        return "students";
      } else if (this.$route.name === "MasterRecord") {
        return "master";
      } else if (this.$route.name === "Reports") {
        return "reports";
      }
      return "lessons";
    },
  },
};
</script>

<style lang="scss" scoped>
.top-info-wrapper {
  margin-bottom: $global-margin;

  @media print {
    margin-bottom: 0;
  }
}

.mid-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $global-margin;

  @media print {
    margin-bottom: 0;
  }
}

.image-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-indicator {
	font-weight: bold;
	color: $white;
	padding: 0.25em 0.5em;
	border-radius: $global-radius;
  
	&.completed {
	  background-color: $gray;
	}
  
	&:not(.completed) {
	  background-color: $success;
	}
  }

</style>
