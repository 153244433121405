<template>
    <div>
        <ChatBox
        :me="me"
        :users="users"
        :messages="messages"
        @newMessage="handleNewMessage"
        :usersTyping="usersTyping"
        />
    </div>
</template>
  
<script>
  import aiApi from "@/api/ai";
  import ChatBox from "@/components/ChatBox.vue";
  
  export default {
  name: "ChatWidget",
  props: {
    context: {
      type: String,
      required: false,
      default: null, // default value is null, making it optional
    },
  },
  components: {
    ChatBox,
  },
    data() {
      return {
        me: {
          id: "user",
          // avatar: "/avatar.jpg",
          avatar: "user-alt", // user-alt
          name: "You",
        },
        bot: {
          id: "assistant",
          // avatar: "/bot.jpg",
          avatar: "user-cog", // user-cog, user-headset
          name: "AI Assistant Coach"
        },
        messages: [],
        usersTyping: [],
      };
    },
    computed: {
      users() {
        return [this.me, this.bot];
      },
      messagesForApi() {
        return this.messages
          .map((m) => ({
            role: m.userId,
            content: m.text,
          }))
          .slice(-50); // finish_reason: "length"
      },
    },
    methods: {
      async handleNewMessage(message) {
        console.log("ChatWidget: handleNewMessage: message:", message)
        this.messages.push(message);
        this.usersTyping.push(this.bot);
        try {
            console.log("ChatWidget: await getAIResponse: this.messagesForApi: ", this.messagesForApi)
            console.log("ChatWidget: props: this.context: ", this.context)
            const res = await aiApi.fetchAIResponse(this.messagesForApi, this.context);
            console.log("ChatWidget: await getAIResponse: res: ", res)
            if (res.data && res.data.choices && res.data.choices[0].message?.content) {
            const msg = {
                id: res.data.id,
                userId: this.bot.id,
                createdAt: new Date(),
                text: res.data.choices[0].message?.content,
            };
            console.log("ChatWidget: constructed msg: ", msg)
            this.messages.push(msg);
            }
        } catch (error) {
            console.error("Error fetching AI response:", error);
        }
        this.usersTyping = [];
      },
    },
  };
  </script>
  