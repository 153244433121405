<template>
  <div v-cloak>
    <the-modal-component
      v-for="params in modals"
      :key="params.id"
      :params="params"
    ></the-modal-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheModalComponent from "./TheModalComponent";

export default {
  name: "TheModal",
  components: {
    TheModalComponent,
  },
  data() {
    return {
      storeTop: null,
    };
  },
  computed: {
    ...mapGetters({
      modals: "modals",
    }),
  },
  watch: {
    modals: function (val) {
      this.$nextTick(() => {
        if (val.length === 0) {
          $("html").removeClass("is-reveal-open").css("top", "");
          window.scrollTo(0, -this.storeTop);
          this.storeTop = null;
        } else {
          let topValue = parseInt($("html").css("top").replace("px", ""));
          if (!isNaN(topValue)) {
            this.storeTop = topValue || this.storeTop;
            setTimeout(() => {
              $("html").css("top", this.storeTop + "px");
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.modal-title {
  margin-bottom: $global-margin;
  padding-bottom: $global-padding/ 1.5;
  border-bottom: 1px solid $black;
}
</style>
