<template>
  <div class="grid-x margin-top-1 margin-bottom-1 align-bottom" v-cloak>
    <radio-filter
      :option-list="optionList"
      :callback="navigateToPlan"
      :selected-value="$route.name"
    ></radio-filter>
    <slot></slot>
    <!-- <button class="filter button gray margin-0">
			<font-awesome-icon icon="filter"></font-awesome-icon>
		</button> -->
  </div>
</template>

<script>
import RadioFilter from "@/components/RadioFilter";

export default {
  name: "ClassGroupEditControlSection",
  components: {
    RadioFilter,
  },
  props: {
    classGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionList: [
        {
          label: this.classGroup.coachingEngagement ? this.$t("classGroupEdit.radio.students.coaching") : this.$t("classGroupEdit.radio.students"),
          value: "ClassGroupEditStudents",
        },
        {
          label: this.classGroup.coachingEngagement ? this.$t("classGroupEdit.radio.teachers.coaching") : this.$t("classGroupEdit.radio.teachers"),
          value: "ClassGroupEditTeachers",
        },
      ],
    };
  },
  methods: {
    navigateToPlan(val) {
      this.$router.push({
        name: val,
        params: {
          classId: this.$route.params.classId,
          year: this.$route.params.year,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
