<template>
  <div class="off-canvas-wrapper" v-cloak>
    <the-sidebar>
      <the-navigation
        :params="{ classId, studentId, year, term, lessonId }"
      ></the-navigation>

      <div class="content-wrapper">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </the-sidebar>
    <elo-side-tray></elo-side-tray>
    <class-group-edit-side-tray />
  </div>
</template>

<script>
import store from "@/store";
import TheSidebar from "./TheSidebar";
import TheNavigation from "./TheNavigation";
import EloSideTray from "./EloSideTray";
import ClassGroupEditSideTray from "@/views/ClassGroupEdit/ClassGroupEditSideTray";

export default {
	name: 'TheLayout',
	components: {
		TheSidebar,
		TheNavigation,
		EloSideTray,
		ClassGroupEditSideTray
	},
	props: ['classId', 'studentId', 'year', 'term', 'lessonId'],
	beforeRouteEnter (to, from, next) {
        if (!store.getters.profile.roles.includes('ROLE_HYPER')) {
            store.dispatch('getAssessmentOptions')
            // store.dispatch('getSupportNeedOptions') store.dispatch('getSideMenu2',{})
            store.dispatch('getCurrentYear').then(() => {
                store.dispatch('setSelectedYearFilter', parseInt(to.params.year) || store.getters.currentYear)
                return store.dispatch('getSideMenu', store.getters.selectedYearFilter)
            }).then(next)
        } else if (store.getters.profile.roles.includes('ROLE_HYPER') === true) {
           store.dispatch('getSideMenuHyber').then(next)
        }
	}
}
</script>

<style lang="scss">
</style>
