import _ from 'lodash';
import * as types from '../mutation-types';
import userApi from '@/api/user';
import teacherApi from '@/api/teacher';
import constants from '@/constants';

// initial state
const state = {
	permittedRoles: [],
	users: {},
	usersRole: {},
	usersTeacherDetails: {}
};

// getters
const getters = {
	user: (state) => (userId) => state.users[userId],
	userRole: (state) => (userId) => state.usersRole[userId],
	userTeacherDetails: (state) => (userId) => state.usersTeacherDetails[userId],
    getUserRoles: (state) => state.usersRole
}

// actions
const actions = {
	async getUserList({ commit }) {
		commit(types.RESET_USER_STATE)
		const { status, data } = await userApi.getUserList()
		if (status === constants.RESPONSE_STATUS.OK && data) {
			commit(types.SET_PERMITTED_ROLES, data.permittedRoles);
			if (data.users) {
				data.users.forEach((userDetails) => {
					let userId = userDetails.user.id;

					let user = {
						[userId]: userDetails.user
					};
					commit(types.SET_USER, user);

					let userRole = {
						[userId]: userDetails.role
					};
					commit(types.SET_USER_ROLE, userRole);

					let userTeacherDetails = {
						[userId]: userDetails.teacher
					};
					commit(types.SET_USER_TEACHER_DETAILS, userTeacherDetails);
				});
			}
		}
	},
	async createUser({ commit }, { username, role, schoolId, accountLocked }) {
		const { status, data } = await userApi.createUser(username, role, schoolId, accountLocked)
		if (status === constants.RESPONSE_STATUS.OK && data) {
			let userId = data.user.id;

			let user = {
				[userId]: data.user
			};
			commit(types.SET_USER, user);

			let userRole = {
				[userId]: data.role
			};
			commit(types.SET_USER_ROLE, userRole);
		}
		return { status, data };
	},
	async updateUser({ commit }, { userId, username, role, schoolId, accountLocked }) {
		const { status, data } = await userApi.updateUser(userId, username, role, schoolId, accountLocked)
		if (status === constants.RESPONSE_STATUS.OK && data) {
			let userId = data.user.id;

			let user = {
				[userId]: data.user
			};
			commit(types.SET_USER, user);

			let userRole = {
				[userId]: data.role
			};
			commit(types.SET_USER_ROLE, userRole);
		}
		return { status, data };
	},
	async deleteUser({ commit }, userId) {
		const { status } = await userApi.deleteUser(userId)
		if (status === constants.RESPONSE_STATUS.OK) {
			commit(types.REMOVE_USER, userId);
			commit(types.REMOVE_USER_ROLE, userId);
		}
		return { status };
	},
	async createTeacher({ commit }, { userId, salutation, fullName, displayName, designation }) {
		const { status, data } = await teacherApi.createTeacher(userId, salutation, fullName, displayName, designation)
		if (status === constants.RESPONSE_STATUS.OK && data) {
			let teacherDetails = {
				[userId]: data
			};
			commit(types.SET_USER_TEACHER_DETAILS, teacherDetails);
		}
		return { status, data };
	},
	async updateTeacherDetails({ commit }, { userId, teacherId, salutation, fullName, displayName, designation }) {
		const { status, data } = await teacherApi.updateTeacherDetails(teacherId, salutation, fullName, displayName, designation)
		if (status === constants.RESPONSE_STATUS.OK && data) {
			let teacherDetails = {
				[userId]: data
			};
			commit(types.SET_USER_TEACHER_DETAILS, teacherDetails);
		}
		return { status, data };
	},
	async deleteTeacherDetails({ commit }, { userId, teacherId }) {
		const { status } = await teacherApi.deleteTeacherDetails(teacherId)
		if (status === constants.RESPONSE_STATUS.OK) {
			commit(types.REMOVE_USER_TEACHER_DETAILS, userId);
		}
		return { status };
	}
};

// mutations
const mutations = {
	[types.RESET_USER_STATE](state) {
		state.permittedRoles = []
		state.users = {}
		state.usersRole = {}
		state.usersTeacherDetails = {}
	},
	[types.SET_PERMITTED_ROLES](state, permittedRoles) {
		state.permittedRoles = permittedRoles
	},
	[types.SET_USER](state, user) {
		state.users = { ...state.users, ...user }
	},
	[types.SET_USER_ROLE](state, userRole) {
		state.usersRole = { ...state.usersRole, ...userRole }
	},
	[types.SET_USER_TEACHER_DETAILS](state, userTeacherDetails) {
		state.usersTeacherDetails = { ...state.usersTeacherDetails, ...userTeacherDetails }
	},
	[types.REMOVE_USER](state, userId) {
		state.users = _.omit(state.users, userId)
	},
	[types.REMOVE_USER_ROLE](state, userId) {
		state.usersRole = _.omit(state.usersRole, userId)
	},
	[types.REMOVE_USER_TEACHER_DETAILS](state, userId) {
		state.usersTeacherDetails = _.omit(state.usersTeacherDetails, userId)
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
