<template>
  <div v-if="editable" v-cloak>
    <div class="grid-x">
      <term-filter v-model="planTerm" class="cell medium-2"></term-filter>
    </div>

    <div class="margin-top-1">
      <p
        v-if="
          attainmentDetailsForSelectedPlanTerm.length > 0 &&
          attainmentDetailsForSelectedPlanTerm[0] &&
          attainmentDetailsForSelectedPlanTerm[0].updatedOn &&
          attainmentDetailsForSelectedPlanTerm[0].updatedBy
        "
        class="update-status"
      >
        {{ $t("editEloDetails.lastUpdated.updatedOn") }}:
        {{
          attainmentDetailsForSelectedPlanTerm[0].updatedOn | moment("calendar")
        }}
        {{ $t("editEloDetails.lastUpdated.by") }}
        {{ attainmentDetailsForSelectedPlanTerm[0].updatedBy.fullName }}
      </p>
    </div>

    <form @submit.prevent="submit">
      <div
        class="grid-x margin-top-1"
        v-for="(num, index) in numCyclesArr"
        :key="num"
      >
        <div
          v-if="numCyclesArr.length > 1"
          class="attainment-cycle cell shrink"
        >
          <p class="attainment-cycle-header">
            {{ $t("editEloDetails.attainment.learningCycle" + num) }}
          </p>
        </div>
        <div class="cycle cell auto">
          <!---				<div class="grid-x">
						<h5 class="cell auto">{{ $t('editEloDetails.attainment.header') }}</h5>
						<div v-if="index === 0" class="cell shrink info-help-button">
							<button type="button" class="btn-info color--gray clear button" data-toggle="attainment-level-criteria-dropdown">
								<font-awesome-icon icon="info-circle"></font-awesome-icon>
							</button>
						</div>
						<attainment-level-criteria :attainmentLevelList="attainmentLevelList" />
					</div> --->

          <ul class="grid-x group-radio">
            <li
              class="cell auto"
              :class="
                formDataArr.length > 0 &&
                formDataArr[index] &&
                formDataArr[index].attainmentLevel === level.value
                  ? 'selected'
                  : ''
              "
              v-for="level in attainmentLevelList"
              :key="level.value + num"
            >
              <label
                class="grid-x align-center align-middle cell auto"
                :for="level.value + num"
                @click="uncheck(formDataArr[index], level.value)"
              >
                <attainment-badge
                  class="cell shrink"
                  :level="level.value"
                ></attainment-badge>
                <span class="truncate"
                  ><big>{{ $t(level.label) }}</big></span
                >
              </label>
              <input
                v-if="formDataArr[index]"
                type="radio"
                :id="level.value + num"
                :value="level.value"
                v-model="formDataArr[index].attainmentLevel"
              />
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="
          taskItemCountSummary &&
          taskItemCountSummary.core &&
          taskItemCountSummary.supplementary &&
          taskItemCountSummary.higher
        "
        class="grid-x item-count"
      >
        <div v-if="taskItemCountSummary.core.total > 0" class="cell small-12">
          <h6 class="item-count-header">
            {{ $t("editEloDetails.attainment.coreItems.header") }}: &nbsp;
            <small
              >{{ taskItemCountSummary.core.completed }}/{{
                taskItemCountSummary.core.total
              }}{{ $t("editEloDetails.attainment.itemsCompleted") }}</small
            >
          </h6>
          <span></span>
          <progress-bar
            :key="'term-' + planTerm + '-core-progress'"
            :numerator="taskItemCountSummary.core.completed"
            :denominator="taskItemCountSummary.core.total"
          ></progress-bar>
        </div>
        <div
          v-if="taskItemCountSummary.supplementary.total > 0"
          class="cell small-12"
        >
          <h6 class="item-count-header">
            {{ $t("editEloDetails.attainment.supplementaryItems.header") }}:
            &nbsp;
            <small
              >{{ taskItemCountSummary.supplementary.completed }}/{{
                taskItemCountSummary.supplementary.total
              }}{{ $t("editEloDetails.attainment.itemsCompleted") }}</small
            >
          </h6>
          <progress-bar
            :key="'term-' + planTerm + '-supplementary-progress'"
            :numerator="taskItemCountSummary.supplementary.completed"
            :denominator="taskItemCountSummary.supplementary.total"
          ></progress-bar>
        </div>
        <div v-if="taskItemCountSummary.higher.total > 0" class="cell small-12">
          <h6 class="item-count-header">
            {{ $t("editEloDetails.attainment.higherItems.header") }}: &nbsp;
            <small
              >{{ taskItemCountSummary.higher.completed }}/{{
                taskItemCountSummary.higher.total
              }}{{ $t("editEloDetails.attainment.itemsCompleted") }}</small
            >
          </h6>
          <progress-bar
            :key="'term-' + planTerm + '-higher-progress'"
            :numerator="taskItemCountSummary.higher.completed"
            :denominator="taskItemCountSummary.higher.total"
          ></progress-bar>
        </div>
      </div>

      <div class="action-selector">
        <div class="grid-x align-right">
          <div class="cell shrink">&nbsp;</div>
          <button type="submit" class="button primary margin-bottom-0">
            {{ $t("editEloDetails.update") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import TermFilter from "@/components/TermFilter";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import AttainmentLevelCriteria from "@/components/AttainmentLevelCriteria";
import AttainmentBadge from "@/components/AttainmentBadge";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "EditEloModalAttainment",
  components: {
    TermFilter,
    // FontAwesomeIcon,
    // AttainmentLevelCriteria,
    AttainmentBadge,
    ProgressBar,
  },
  props: {
    eloCode: String,
    editable: Boolean,
    studentId: String,
    selectedYear: Number,
    selectedTerm: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      planTerm: this.selectedTerm,
      studentEloAttainmentObjPlaceholder: {
        attainmentLevel: null,
        updatedBy: null,
        updatedOn: null,
      },
      numCyclesForTerm: {
        0: [1],
        1: [1],
        2: [1, 2],
        3: [1, 2],
        4: [1],
      },
      formDataArr: [],
    };
  },
  computed: {
    ...mapGetters([
      "attainmentLevelList",
      "studentTaskItemListForStudentId",
      "getTaskItemCountSummaryForStudentId",
    ]),
    studentTaskItemList() {
      return this.studentTaskItemListForStudentId(this.studentId);
    },
    taskItemCountSummary() {
      return this.getTaskItemCountSummaryForStudentId(this.studentId);
    },
    numCyclesArr() {
      return this.numCyclesForTerm[this.planTerm.toString()];
    },
    attainmentDetailsForSelectedPlanTerm: function () {
      return this.$store.getters.studentAttainment(this.studentId)
        ? this.$store.getters.studentAttainment(this.studentId)
        : [];
    },
  },
  created() {
    this.insertPlaceholdersIntoFormDataArr(this.numCyclesArr.length);
    this.getStudentAttainmentWithParams();
  },
  watch: {
    planTerm: function (value) {
      this.getStudentAttainmentWithParams({ planTerm: value });
    },
    attainmentDetailsForSelectedPlanTerm: function (value) {
      this.formDataArr = value;
      if (value.length === this.numCyclesArr.length) {
        return;
      }

      const numOfPlaceholdersRequired = this.numCyclesArr.length - value.length;
      this.insertPlaceholdersIntoFormDataArr(numOfPlaceholdersRequired);
    },
  },
  methods: {
    ...mapActions(["getStudentAttainment", "postStudentAttainment"]),
    getStudentAttainmentWithParams({
      studentId = this.studentId,
      eloCode = this.eloCode,
      planYear = this.selectedYear,
      planTerm = this.planTerm,
    } = {}) {
      this.getStudentAttainment({ studentId, eloCode, planYear, planTerm });
    },
    insertPlaceholdersIntoFormDataArr(numOfPlaceholdersRequired) {
      for (let i = 0; i < numOfPlaceholdersRequired; i++) {
        this.formDataArr.push({ ...this.studentEloAttainmentObjPlaceholder });
      }
    },
    uncheck(learningCycleObj, val) {
      if (learningCycleObj.attainmentLevel === val) {
        this.$set(learningCycleObj, "attainmentLevel", null);
      }
    },
    submit() {
      let formData = _.cloneDeep(this.formDataArr);
      for (var i = 0; i < formData.length; i++) {
        let attainmentObj = formData[i];
        attainmentObj["assessmentCycle"] = i + 1;
      }

      this.postStudentAttainment({
        studentId: this.studentId,
        eloCode: this.eloCode,
        planYear: this.selectedYear,
        planTerm: this.planTerm,
        formData: formData,
      }).then(
        () => {
          this.$toastr.success(this.$t("editEloDetails.saved"));
          this.$modal.closeAll();
        },
        (error) => {
          this.$toastr.error(error.data && error.data.message);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.info-help-button {
  right: 0.5em;
  bottom: 0.5em;
}
.btn-info {
  font-size: map-deep-get($header-styles, small, h2, font-size) * 1px;
}
.item-count-header {
  small {
    color: $black;
  }
}
.attainment-select {
  font-size: $large-font-size;
}
.update-status {
  margin-bottom: 0;
  font-size: $small-font-size;
  line-height: 1.4em;
}
.group-radio label {
  height: auto;

  padding-top: $global-padding / 3;
  padding-bottom: $global-padding / 3;

  @include breakpoint(small down) {
    padding-left: $global-padding / 3;
  }
}
.attainment-badge {
  margin-right: 0.3em;
}
.action-selector {
  padding-top: $global-padding * 1.5;
}

.cycle {
  background-color: $bg-special;
  border-radius: $global-radius;
  padding: $global-padding $global-padding * 1.5;

  .group-radio,
  .attainment-select {
    background-color: $white;
  }
}

.attainment-cycle {
  position: relative;
  width: 1.2em;
  border-top-left-radius: $global-radius;
  border-bottom-left-radius: $global-radius;
  background-color: $title-color;

  .attainment-cycle-header {
    position: absolute;
    margin-bottom: 0;
    margin-left: 0.15em;
    top: 45%;
    left: 45%;
    transform: translateX(-50%) rotate(-90deg);
    transform-origin: center;
    font-size: 0.65em;
    color: $lighter-gray;
    z-index: 20;
    white-space: nowrap;
    text-transform: uppercase;
  }
}

.item-count {
  margin-top: $global-margin * 2;
  padding: $global-padding $global-padding * 1.5;
}
</style>
