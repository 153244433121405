import store from '@/store'

const toastr = {
	install (Vue, options) {
		if (this.installed) {
			return
		}
		this.installed = true

		Vue.$toastr = Vue.prototype.$toastr = {
			success (message, options) {
				let toastr = {
					type: 'success',
					message,
					...options
				}
				addToastr(toastr)
			},
			warning (message, options) {
				let toastr = {
					type: 'warning',
					message,
					...options
				}
				addToastr(toastr)
			},
			error (message, options) {
				let toastr = {
					type: 'alert',
					message,
					...options
				}
				addToastr(toastr)
			}
		}

		const addToastr = (toastr) => {
			store.dispatch('addToastr', toastr)
		}
	}
}

export default toastr
