<template>
  <div class="grid-x align-middle">
    <span class="cell total-elo-label auto">{{ label }}</span>
    <span
      class="total-elo-count auto button rounded"
      :class="{
        'bgcolor--primary': match === constants.ELO_STATUS.ACTIVE,
        'bgcolor--success': match === constants.ELO_STATUS.ATTAINED,
        'bgcolor--alert': match === constants.ELO_STATUS.EXEMPTED,
      }"
    >
      {{ eloCount }}
    </span>
  </div>
</template>

<script>
import constants from "@/constants";

export default {
  name: "TotalEloFilterDetails",
  props: {
    label: String,
    match: String,
    eloCount: Number,
  },
  data() {
    return {
      constants: constants,
    };
  },
};
</script>

<style lang="scss" scoped>
.total-elo-label {
  @extend .sans-serif-bold;

  font-size: 0.9em;
  color: $darker-gray;
  white-space: nowrap;
}
.total-elo-count {
  @extend .sans-serif-bold;

  display: block;
  margin-bottom: 0;
  margin-left: $global-margin * 1.7;
  padding: $global-padding / 4 $global-padding / 2;
  font-size: $small-font-size / 1.2;
  color: $white;
}
</style>
