import Vue from "vue";
import Router from "vue-router";

import navigationGuard from './navigation-guard';

import TheLayout from "@/components/TheLayout";

import Login from "@/components/Login"
import Dashboard from "@/components/Dashboard"
import ClassGroup from "@/views/ClassGroup/ClassGroup"
import LessonPlanHeader from "@/components/LessonPlanHeader"
import LessonPlanList from "@/components/LessonPlanList"
import LessonPlan from "@/components/LessonPlan"
import LessonPlanRecord from "@/components/LessonPlanRecord"
import Master from "@/components/Master"
import Learning from '@/components/Learning'
import CurriculumInterfaceDetailsProgrammePlan from '@/components/CurriculumInterfaceDetailsProgrammePlan'
import StudentDetailsProgrammePlan from '@/components/StudentDetailsProgrammePlan'
// import CurriculumInterfaceStudentDetailsProgramePlan from '@/components/CurriculumInterfaceStudentDetailsProgramePlan'
import StudentDetailsAnnualPlan from '@/components/StudentDetailsAnnualPlan'
import Reports from '@/components/Reports'
import MasterReports from '@/components/MasterReports'
import MasterReportsDetails from '@/components/MasterReportsDetails'
import MasterReportsDetailsMultiTerms from '@/components/MasterReportsDetailsMultiTerms'
import ProgrammeDashboard from '@/views/Programme/ProgrammeDashboard'

import ClassGroupEdit from "@/views/ClassGroupEdit/ClassGroupEdit";
import ClassGroupEditStudents from "@/views/ClassGroupEdit/ClassGroupEditStudents";
import ClassGroupEditTeachers from "@/views/ClassGroupEdit/ClassGroupEditTeachers";

import UserList from "@/views/UserList/UserList";
import Maintenance from "../views/Maintenance/Maintenance";

import Schools from "@/views/Schools/Schools";
import Tenants from "@/views/Tenants/Tenants";
import Curriculum from "@/views/Curriculum/Curriculum";

import CoachingEngagement from "@/components/CoachingEngagement";
// import ClientEngagement from "@/components/ClientEngagement";


Vue.use(Router);

let router = null;

// console.log(process.env.IS_MAINTENANCE);
if (process.env.IS_MAINTENANCE) {
	router = new Router({
		routes: [
			{
				path: '/',
				name: 'Maintenance',
				component: Maintenance
			},]
	});
} else {
	router = new Router({
		routes: [
			{
				path: '/',
				name: 'Login',
				component: Login
			},
			{
				path: '',
				name: 'TheLayout',
				component: TheLayout,
				props: true,
				meta: {
					requireAuth: true
				},
				children: [{
					path: ':year?/classGroup/:classId?',
					name: 'Dashboard',
					component: Dashboard,
					props: true,
					children: [{
						path: 'studentList',
						name: 'ClassGroup',
						component: ClassGroup
					}, {
						path: 'term/:term?',
						name: 'LessonPlanHeader',
						component: LessonPlanHeader,
						redirect: { name: 'LessonPlanList' },
						props: true,
						children: [{
							path: 'lessons',
							name: 'LessonPlanList',
							component: LessonPlanList,
							props: true
						}, {
							path: 'records/:lessonId?',
							name: 'LessonPlanRecord',
							component: LessonPlanRecord,
							props: true,
							meta: {
								mainRoute: 'LessonPlanList'
							}
						}, {
							path: 'lesson/:lessonId?',
							name: 'LessonPlan',
							component: LessonPlan,
							props: true,
							meta: {
								mainRoute: 'LessonPlanList'
							}
						}]
					}, {
						path: 'term/:term?',
						name: 'Master',
						component: Master,
						redirect: { name: 'MasterRecord' },
						props: true,
						children: [{
							path: 'master',
							name: 'MasterRecord',
							component: LessonPlanRecord,
							props: true
						}]
					}, {
						path: 'reports',
						name: 'Reports',
						component: Reports
					}]
				}, {
					path: ':year?/student/:studentId?',
					name: 'Learning',
					component: Learning,
					props: true,
					redirect: { name: 'Piep' },
					children: [{
						path: 'studentAnnualDetails',
						name: 'Aiep',
						component: StudentDetailsAnnualPlan,
						props: true
					}, {
						path: 'studentAnnualDetails',
						name: 'Aiep0',
						component: StudentDetailsAnnualPlan,
						props: true
					}, {
						path: 'studentAnnualDetails',
						name: 'Aiep1',
						component: StudentDetailsAnnualPlan,
						props: true
					}, {
						path: 'studentAnnualDetails',
						name: 'Aiep3',
						component: StudentDetailsAnnualPlan,
						props: true
					}, {
						path: 'studentProgrammeDetails',
						name: 'Piep',
						component: StudentDetailsProgrammePlan,
						props: true
					// }, {
					//	path: 'clientEngagement',
					//	name: 'ClientEngagement',
					//	component: ClientEngagement,
					//	props: true
					}]
				}, {
					// path: ':year?/student/:studentId?',
					path: ':year?/classGroup/:classId?/student/:studentId?/sce/:studentCoachingEngagementId?',					
					name: 'CoachingEngagement',
					component: CoachingEngagement,
					props: true,
					redirect: { name: 'CoachingEngagement' },
					children: [{
						path: 'coachingEngagement',
						name: 'CoachingEngagement',
						component: CoachingEngagement,
						props: true
					}]
				}, {
					path: ':year?/programme/:programmeId?',
					name: 'Programme',
					component: ProgrammeDashboard,
					props: (route) => ({ year: parseInt(route.params.year), programmeId: parseInt(route.params.programmeId) })
				}, {
					path: ':year?/classGroupEdit/:classId?',
					name: 'ClassGroupEdit',
					component: ClassGroupEdit,
					props: true,
					redirect: { name: 'ClassGroupEditStudents' },
					children: [{
						path: 'students',
						name: 'ClassGroupEditStudents',
						component: ClassGroupEditStudents,
						props: true
					}, {
						path: 'teachers',
						name: 'ClassGroupEditTeachers',
						component: ClassGroupEditTeachers,
						props: true
					}]
				}, {
					path: '/users',
					name: 'Users',
					component: UserList,
					props: true
				},
				{
					path: 'masterReport',
					name: 'MasterReport',
					component: MasterReports
				},
				{
					path: 'masterReport/details',
					name: 'LeadershipDashboard',
					component: MasterReportsDetails
				},
				{
					path: 'masterReport/detailsMultiTerm',
					name: 'LeadershipDashboardMultiTerm',
					component: MasterReportsDetailsMultiTerms
				}, {
					path: "schools",
					name: "Schools",
					component: Schools
				}, {
					path: "tenants",
					name: "Tenants",
					component: Tenants
				},
				{
					path: "curriculum",
					name: "Curriculum",
					component: Curriculum
				},{
					path: "CurriculumInterface",
					name: "CurriculumInterface",
					component: CurriculumInterfaceDetailsProgrammePlan
				}]
			},
			{ path: '**', redirect: '/' }]
	});

	navigationGuard(router);
}

export default router;
