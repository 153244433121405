<template>
  <div v-cloak>
    <div class="grid-x align-middle margin-bottom-2">
      <radio-filter
        class="cell shrink small"
        :option-list="optionList"
        :callback="toggleExternal"
      ></radio-filter>
    </div>
    <p v-if="external"><slot name="externalDisclaimer"></slot></p>
    <!-- slot disclaimer editElo vs terminate remark-->

    <p class="empty-message" v-if="remarks && remarks.length == 0">
      {{ $t("remarks.empty") }}
    </p>

    <remark-card
      v-for="remark in remarks"
      :key="remark.id"
      :remark="remark"
      :evidence-src="evidenceSrc"
      @deleteRemark="deleteRemark"
      @fileLoaded="createImage"
    >
    </remark-card>
    <form @submit.prevent="submit">
      <rich-text-editor
        v-model="formData.remark"
        :placeholder="$t('remarks.message.placeholder')"
        :height="100"
      >
      </rich-text-editor>

      <div class="action-selector margin-top-1">
        <div class="grid-x align-right">
          <button
            type="submit"
            class="cell button shrink primary hollow margin-bottom-0"
            :disabled="!formData.remark"
          >
            <slot name="submitLabel">{{ $t("remarks.add") }}</slot>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RadioFilter from "./RadioFilter";
import RemarkCard from "./RemarkCard";
import RichTextEditor from "./RichTextEditor";

export default {
  name: "Remarks",
  components: {
    RadioFilter,
    RemarkCard,
    RichTextEditor,
  },
  props: {
    remarks: {
      type: Array,
      default: () => [],
    },
    external: Boolean,
    formData: Object,
    evidenceSrc: String,
  },
  data() {
    return {
      optionList: [
        {
          label: this.$i18n.t("remarks.toggle.internal"),
          value: false,
        },
        {
          label: this.$i18n.t("remarks.toggle.external"),
          value: true,
        },
      ],
    };
  },
  methods: {
    toggleExternal(value) {
      this.$emit("toggleExternal", value);
    },
    submit() {
      this.$emit("submit");
    },
    createImage(file, fileName, remarkId) {
      this.$emit("createImage", file, fileName, remarkId);
    },
    deleteRemark(remarkId) {
      this.$emit("deleteRemark", remarkId);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

