import schoolApi from '@/api/school'
import programmeApi from '@/api/programme'
import * as types from '../mutation-types'
import constants from '@/constants'
import _ from 'lodash'

const state = {
	schoolIds: [],
	schools: {},
	programmes: {}
};

const getters = {
	schools: (state) => state.schools,
	programmesForSchoolId: (state) => (schoolId) => state.programmes[schoolId] ? state.programmes[schoolId] : []
};

const mutations = {
	[types.SET_SCHOOL_IDS](state, schoolIds) {
		state.schoolIds = [...state.schoolIds, ...schoolIds]
	},
	[types.SET_SCHOOLS](state, schools) {
		state.schools = { ...state.schools, ...schools }
	},
	[types.SET_SCHOOL](state, school) {
		state.schools = { ...state.schools, [school.id]: school }
	},
	[types.REMOVE_SCHOOL](state, school) {
		const copyOfSchools = { ...state.schools }
		delete copyOfSchools[school.id]
		state.schools = copyOfSchools
	},
	[types.SET_SCHOOL_PROGRAMMES](state, programmes) {
		state.programmes = { ...state.programmes, ...programmes }
	},
	[types.REMOVE_SCHOOL_PROGRAMMES](state, params) {
		const { id, schoolId } = params
		const copyOfSchoolProgrammes = { ...state.programmes }
		const programmes = copyOfSchoolProgrammes[schoolId]
		const programmeLocation = programmes.findIndex((programme) => programme.id === id)
		programmes.splice(programmeLocation, 1)

		state.programmes = copyOfSchoolProgrammes
	},
}

const actions = {
	async getSchools({ commit }) {
		const { data } = await schoolApi.getSchools()

		let schools = _.keyBy(data, 'id');
		commit('SET_SCHOOL_IDS', Object.keys(schools));

		const programmes = _.mapValues(schools, 'programmes');
		commit('SET_SCHOOL_PROGRAMMES', programmes);

		schools = _.transform(schools, (result, school, schoolId) => {
			const flatSchoolObj = _.omit(school, 'programmes');
			result[schoolId] = flatSchoolObj;
		}, {});

		commit('SET_SCHOOLS', schools)
	},
	async createSchool({ commit }, params) {
		const { data } = await schoolApi.createSchool(params)

		commit(types.SET_SCHOOL, data.school)
		return data.school
	},
	async editSchool({ commit }, params) {
		const { data } = await schoolApi.editSchool(params)
		const { programmes, ...schoolData } = data

		// console.log(data)
		// console.log(schoolData.school)
		// console.log(programmes)

		commit(types.SET_SCHOOL, schoolData.school)
		commit(types.SET_SCHOOL_PROGRAMMES, { [schoolData.school.id]: programmes })
		return data
	},
	async deleteSchool({ commit }, params) {
		const { status } = await schoolApi.deleteSchool(params)

		if (status === constants.RESPONSE_STATUS.OK) {
			commit(types.REMOVE_SCHOOL, { id: params.id })
			return true
		}
		return false
	},
	async deleteProgramme({ commit }, params) {
		const { status } = await programmeApi.deleteProgramme(params)

		if (status === constants.RESPONSE_STATUS.OK) {
			commit(types.REMOVE_SCHOOL_PROGRAMMES, params)
			return true
		}
		return false
	}

}

export default {
	state,
	getters,
	mutations,
	actions
};
