<template>
  <div class="user-modal" v-cloak>
    <h2 style="text-align: left">Edit ELO</h2>
    <form @submit.prevent="submit">
      <div class="class-group-modal-content grid-x content-wrapper">
        <div class="tlo-list-card" v-cloak>
          <div class="tlo-header card-divider grid-y">
            <div>
              <p
                class="tlo-text grid-x cell auto margin-bottom-0"
                style="margin-bottom: 5px"
              >
                <span class="tlo-code grid-x cell"
                  >{{ domainCode }}{{ " " }}&nbsp;<span
                    style="color: #666666"
                    >{{ domainName }}</span
                  >
                </span>
              </p>
              <p class="tlo-text grid-x cell auto margin-bottom-0">
                <span class="tlo-code grid-x cell"
                  >{{ subDomainCode }}{{ " " }}&nbsp;<span
                    style="color: #666666"
                    >{{ subdomainName }}</span
                  ></span
                >
              </p>
              <p class="tlo-text grid-x cell auto margin-bottom-0">
                <span class="tlo-code grid-x cell"
                  >{{ tloCode }}{{ " " }}&nbsp;<span style="color: #666666">{{
                    tloName
                  }}</span></span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <label>Name</label>
          <input
            type="text"
            v-bind:name="eloName"
            required
            v-model="formData.eloName"
          />
          <label>Code</label>
          <input
            type="text"
            v-bind:name="eloCode"
            required
            v-model="formData.eloCode"
          />
          <label>Order Number</label>
          <input
            type="text"
            v-bind:name="eloId"
            required
            v-model="formData.eloId"
          />
          <label>Link</label>
          <input type="text" v-bind:name="eloLink" v-model="formData.eloLink" />
          <label>Common Core Programmes</label>
          <table style="width: 250px">
            <thead>
              <tr>
                <td></td>
                <td><label>Code</label></td>
                <td><label>Programme</label></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.programmeTickboxArray" :key="index">
                <td>
                    <input
                      class="checkbox"
                      type="checkbox"
                      v-model="item.tickbox"
                      v-bind:value="item.code"
                      @click="getCheckedValue"
                    />
                </td>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
              </tr>
            </tbody>
          </table>
          <label>ELO Status</label>
          <table>
            <tr>
              <td>
                <input
                  class="checkbox"
                  type="checkbox"
                  v-bind:name="eloStatus"
                  v-model="formData.eloStatus"
                  @click="toggleEloStatus"
                  :checked="!formData.eloStatus"
                />
              </td>
            </tr>
          </table>
          <input
            type="submit"
            class="cell button shrink primary hollow margin-bottom-0"
            value="Update"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CurriculumInterfaceEditEloModal",
  props: {
    eloName: String,
    eloCode: String,
    domainName: String,
    domainCode: String,
    subdomainName: String,
    subDomainCode: String,
    tloName: String,
    tloCode: String,
    id: Number,
    eloLink: String,
    eloId: String,
    eloStatus: Boolean,
    programmeCodes: Array,
  },
  data() {
    return {
      formData: {
        id: "ELO-" + this.id,
        eloName: this.eloName,
        eloCode: this.eloCode,
        eloLink: this.eloLink,
        eloStatus: this.eloStatus,
        eloId: this.eloId,
        programmeCodes: this.programmeCodes,
        selectedProgrameeArray: this.programmeCodes,
        unCheckedProgrammeeArray: [],
        programmeTickboxArray: [],
      },
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
  },
  mounted () {
    var currentTickbox = false;
    for (let i = this.curriculum.programmes.length - 1; i >= 0; i--) {
      currentTickbox = false;
      if (this.formData.selectedProgrameeArray.includes(this.curriculum.programmes[i].code)) {
        currentTickbox = true;
      }
      this.formData.programmeTickboxArray.push( { 
        tickbox: currentTickbox, 
        originalTickbox: currentTickbox,
        code: this.curriculum.programmes[i].code, 
        name: this.curriculum.programmes[i].name
      });
    }
  },
  methods: {
    ...mapActions(["getCurriculum", "forceGetCurriculum"]),
    submit() {
      const currciulumInterface = this.formData.eloName;
      this.$store
        .dispatch("curriculumInterfaceEloUpdate", this.formData)
        .then((resp) => {
          if (resp) {
            this.$store.dispatch("curriculumTimestampReset");
            this.$modal.closeAll();
            this.forceGetCurriculum();
            this.$toastr.success(
              this.$t("currciulumInterfaceModal.success.updated", {
                currciulumInterface,
              })
            );
          }
        })
        .catch((err) => {
          this.$toastr.error(err.data && err.data.message);
        });
    },
    toggleEloStatus() {
      this.formData.eloStatus = !this.formData.eloStatus;
    },
    getCheckedValue(event) {
      if (event.target.checked) {
        if (!this.formData.selectedProgrameeArray.includes(event.target.value)) {
          this.formData.selectedProgrameeArray.push(event.target.value);
        }
        if (this.formData.unCheckedProgrammeeArray.includes(event.target.value)) {
          for (let i = this.formData.unCheckedProgrammeeArray.length - 1; i >= 0; i--) {
            if (this.formData.unCheckedProgrammeeArray[i] === event.target.value) {
              this.formData.unCheckedProgrammeeArray.splice(i, 1);
            }
          }
        }
      } else {
        // here to remove from checked list and to add to unchecked list (if not already included)
        if (this.formData.selectedProgrameeArray.includes(event.target.value)) {
          for (let i = this.formData.selectedProgrameeArray.length - 1; i >= 0; i--) {
            if (this.formData.selectedProgrameeArray[i] === event.target.value) {
              this.formData.selectedProgrameeArray.splice(i, 1);
            }
          }
        }
        if (!this.formData.unCheckedProgrammeeArray.includes(event.target.value)) {
          // add here only if the item was originally checked
          if (this.formData.programmeTickboxArray.find(item => item.code === event.target.value && item.originalTickbox)) {
            this.formData.unCheckedProgrammeeArray.push(event.target.value);
          }
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.tlo-list-card {
  border-radius: 0;
  border: 0;
  width: 100%;
}
.tlo-header {
  background-color: $lighter-gray;
  border-bottom: 1px solid $light-gray;
  position: relative;
}
.tlo-text {
  color: $darker-gray;
}
.tlo-code {
  color: $dark-gray;
  font-size: 10px;
}
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
.card {
  margin: auto;
  text-align: left;
  font-family: arial;
  border: none;
}
.card dmonainName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #73894e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.card subdmonainName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #dce1d3;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.card tloName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #efe;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.checkbox {
  margin-right: 97%;
  margin-top: 12px;
}
</style>
