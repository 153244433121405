<template>
  <div
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    @click="onClick"
    v-click-outside="clickedOutside"
  >
    <button
      class="button clear"
      :class="remarks.length > 0 ? 'color--medium-gray' : 'color--light-gray'"
      @click="() => openEditRemarkModal()"
    >
      <template v-if="remarks.length > 0">
        <font-awesome-icon :icon="['far', 'comment-dots']" />
      </template>
      <template v-else>
        <font-awesome-icon :icon="['far', 'comment']" />
      </template>
    </button>

    <div
      class="dropdown-pane has-alignment-center has-position-right is-open"
      v-if="showModal"
    >
      <div v-for="remarkObj in remarks" :key="remarkObj.id" class="grid-x">
        <span>
          <p class="margin-bottom-0 remark" style="text-align: left">
            {{ remarkObj.remark }}
          </p>
          <p
            class="margin-bottom-0 cell auto remark"
            style="font-size: 0.75rem; text-align: left"
          >
            {{ lastUpdated(remarkObj) }}
          </p>
        </span>
        <button
          class="button clear cell shrink margin-left-1"
          v-if="
            showDelete ||
            remarkObj.remarkBy.fullName === profile.details.fullName
          "
          @click="deleteRemark(remarkObj)"
        >
          <font-awesome-icon :icon="['far', 'trash-alt']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EditEloModalAnalysisCardRemarksEdit from "./EditEloModalAnalysisCardRemarksEdit";
import moment from "moment";

export default {
  name: "EditEloModalAnalysisCardRemarks",
  components: {
    FontAwesomeIcon,
  },
  props: {
    studentId: String,
    studentTaskItemId: Number,
    hasRemarks: Boolean,
  },
  data() {
    return {
      hover: false,
      clicked: false,
    };
  },
  computed: {
    ...mapGetters(["studentTaskItemRemarks", "profile"]),
    showModal() {
      return this.remarks.length > 0 && (this.hover || this.clicked);
    },
    remarks() {
      const result = this.studentTaskItemRemarks(this.studentTaskItemId);
      const response = [];
      if (result) {
        for (const data of result) {
          if (data.week === 0) {
            response.push(data);
          }
        }
        response.sort((a, b) => {
          return a.id - b.id;
        });
      }
      return response;
    },
    showDelete() {
      const seniorTeacher = this.profile.roles.includes("ROLE_SENIOR_TEACHER");
      const leadTeacher = this.profile.roles.includes("ROLE_LEAD_TEACHER");
      const superTeacher = this.profile.roles.includes("ROLE_SUPER_TEACHER");
      return seniorTeacher || leadTeacher || superTeacher;
    },
  },
  // watch: {
  // 	async showModal (newVal, oldVal) {
  // 		if (!oldVal && newVal) {
  // 			await this.getStudentTaskItemRemarks({studentTaskItemId: this.studentTaskItemId})
  // 		}
  // 	}
  // },
  methods: {
    ...mapActions([
      "getStudentTaskItemRemarks",
      "updateTaskItemListRemarks",
      "deleteStudentTaskItemRemarks",
    ]),
    lastUpdated({ lastUpdated, remarkBy }) {
      const result =
        moment().format("DD MM YYYY") ===
        moment(lastUpdated).format("DD MM YYYY")
          ? "Today"
          : moment(lastUpdated).format("DD/MM/YYYY");
      const time = moment(lastUpdated).format("H:mm");
      return `${result} at ${time} by ${remarkBy.fullName}`;
    },
    clickedOutside(event) {
      this.setClicked(false);
    },
    setHover(hover) {
      this.hover = hover;
    },
    setClicked(clicked) {
      this.clicked = clicked;
    },
    onClick() {
      if (this.remarks.length > 0) {
        this.setClicked(true);
      }
    },
    openEditRemarkModal() {
      this.$modal.show({
        component: EditEloModalAnalysisCardRemarksEdit,
        componentProps: {
          studentTaskItemId: this.studentTaskItemId,
          updateTaskItemRemarksFlag: this.updateTaskItemRemarksFlag,
        },
      });
    },
    updateTaskItemRemarksFlag(flag) {
      return this.updateTaskItemListRemarks({
        studentId: this.studentId,
        studentTaskItemId: this.studentTaskItemId,
        hasRemarks: flag,
      });
    },
    deleteRemark({ id }) {
      this.deleteStudentTaskItemRemarks({
        studentTaskItemId: `${this.studentTaskItemId}`,
        remarkId: id,
      }).then((resp) => {
        if (resp) {
          const result = this.updateTaskItemRemarksFlag(false);
          if (result) {
            return this.$toastr.success(
              this.$t("editEloDetails.analysis.remarkModal.deleteSuccess")
            );
          }
        }
        this.$toastr.error(this.$t("error.unable.tryAgain"));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.remark {
  word-break: break-word;
}
</style>