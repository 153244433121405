<template>
  <base-class-group-list-card
    class="class-group-edit-list-card"
    :image="image"
    :condition-types="conditionTypes"
    :text-content="textContent"
    :profile-name="profileName"
    :card-id="cardId"
  >
    <more-dropdown
      slot="right"
      :options="dropdownOptions"
      :identifier="'card-' + $vnode.key"
    ></more-dropdown>
  </base-class-group-list-card>
</template>

<script>
import BaseClassGroupListCard from "@/components/BaseClassGroupListCard";
import MoreDropdown from "@/components/MoreDropdown";

export default {
  name: "ClassGroupEditListCard",
  components: {
    BaseClassGroupListCard,
    MoreDropdown,
  },
  computed: {
    dropdownOptions() {
      const options = [
        {
          label: "classGroupEdit.listCard.more.dropdownOption.remove",
          method: this.removeCard,
        },
      ];
      if (this.updateFunction) {
        options.unshift({
          label: "classGroupEdit.listCard.more.dropdownOption.edit",
          method: this.updateCard,
        });
      }

      return options;
    },
  },
  props: {
    image: String,
    conditionTypes: Array,
    textContent: String,
    profileName: String,
    cardId: String,
    updateFunction: Function,
    removeFunction: Function,
  },
  methods: {
    updateCard() {
      this.updateFunction(this.cardId);
    },
    removeCard() {
      this.removeFunction(this.cardId, this.textContent);
    },
  },
};
</script>

<style lang="scss" scoped>
.class-group-edit-list-card {
  position: relative;
  overflow: inherit;
}
</style>
