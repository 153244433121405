import Vue from "vue";
import VueResource from "vue-resource";
import moment from "moment";

import store from "@/store";
import router from "@/router";

Vue.use(VueResource);
Vue.http.options.root = process.env.VUE_APP_SERVER_URL;

Vue.http.interceptors.push((request, next) => {
	if (!request.skipAuthorization && store.getters.isAuthenticated) {
		// SNOW-87 Disable use of refresh token from multi-tenant
		// let diff = moment(store.getters.profile.expire).diff(moment(), "seconds");
		// if (diff > 0 && diff <= store.getters.profile.expires_in * 7 / 8 ) { // can change to use minutes 105 / 120
		// 	store.dispatch("refreshToken");
		// }
		request.headers.set("Authorization", store.getters.profile.token_type + " " + store.getters.profile.access_token);
	}

	request.headers.set("SameSite", "None; Secure");// set for single site access "Lax", to allow third party access define "SameSite=None" and "Secure"

	if (request.setContentType === false) {
		request.headers.set("Content-Type", undefined);
	}

	next((response) => {
		if (response.status === 0) {
			Vue.$toastr.error("error.timeout", { translate: true });
		}

		let hasRequireAuth = (router.history.pending && router.history.pending.matched.some(m => m.meta.requireAuth)) ||
			(router.history.current && router.history.current.matched.some(m => m.meta.requireAuth));

		if (hasRequireAuth && response.status === 401) {
			Vue.$toastr.error("error.unauthorized", { translate: true });
			Vue.$modal.closeAll();
			store.dispatch("logout");
			return router.push({ name: "Login" });
		}

		if (hasRequireAuth && response.status === 403) {
			Vue.$toastr.error("error.forbidden", { translate: true });
		}

		// Maintenance message
		// if (hasRequireAuth) {
		// 	Vue.$toastr.error("error.maintenance", { translate: true })
		//	Vue.$modal.closeAll()
		//	store.dispatch("logout")
		//	return router.push({ name: "Login" })
		// }
	});
});;

// const refreshToken = (request) => {
// return Vue.http.post(process.env.SERVER_URL.replace('/api', '') + 'oauth/access_token', null, {
// skipAuthorization: true,
// params: {
// grant_type: 'refresh_token',
// refresh_token: store.getters.profile.refresh_token
// }
// }).then((resp) => {
// return store.dispatch('setProfile', resp.data)
// })
// }
