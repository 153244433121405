import Vue from 'vue'

export default {
	getCurriculum() {
		return Vue.http.get('curriculum')
	},
	getTaskItem(eloCode) {
		return Vue.http.get(`taskItem/${eloCode}?eloCode=${eloCode}`)
	},
	uploadDomain(body) {
		return Vue.http.post('customCurriculum/curriculum/uploadDomain', body)
	},
	uploadSubDomain(body) {
		return Vue.http.post('customCurriculum/curriculum/uploadSubDomain', body)
	},
	uploadTLO(body) {
		return Vue.http.post('customCurriculum/curriculum/uploadTLO', body)
	},
	uploadELO(body) {
		return Vue.http.post('customCurriculum/curriculum/uploadELO', body)
	},
	uploadTaskItem(body) {
		return Vue.http.post('customCurriculum/curriculum/uploadTaskItem', body)
	},
	downloadCsv(type) {
		return Vue.http.get(`customCurriculum/curriculum/${type}`, {
			responseType: 'arraybuffer'
		})
	},
	curriculumInterfaceEloAdd(tableName, eloName, eloCode, eloLink, eloStatus, tloId, selectedProgrameeArray, unCheckedProgrammeeArray) {
		return Vue.http.post(`customCurriculum/curriculum/`, {
			tableName, eloName, eloCode, eloLink, eloStatus, tloId, selectedProgrameeArray, unCheckedProgrammeeArray
		})
	},
	curriculumInterfaceTaskItemAdd(tableName, taskDescription, eloCode, itemcategory) {
		return Vue.http.post(`customCurriculum/curriculum/`, {
			tableName, taskDescription, eloCode, itemcategory
		})
	},
	curriculumInterfaceTaskItemUpdate(id, taskDescription, eloCode, itemcategory, orderNumber) {
		return Vue.http.put(`customCurriculum/curriculum/${id}`, {
			id, taskDescription, eloCode, itemcategory, orderNumber
		})
	},	
	async removeItemFromTaskItem(id) {
		return Vue.http.delete(`customCurriculum/curriculum/${id}`, {
		})
	},
	curriculumInterfaceEloUpdate(id, eloName, eloCode, eloLink, eloStatus, eloId, selectedProgrameeArray, unCheckedProgrammeeArray) {
		return Vue.http.put(`customCurriculum/curriculum/${id}`, {
			eloName, eloCode, eloLink, eloStatus, eloId, selectedProgrameeArray, unCheckedProgrammeeArray
		})
	},
	async curriculumInterfaceEloDelete(id) {
		return Vue.http.delete(`customCurriculum/curriculum/${id}`, {
		})
	},
	async curriculumInterfaceTloDelete(id) {
		return Vue.http.delete(`customCurriculum/curriculum/${id}`, {
		})
	},
	async curriculumInterfaceSubDomainDelete(id) {
		return Vue.http.delete(`customCurriculum/curriculum/${id}`, {
		})
	},
	async curriculumInterfaceDomainDelete(id) {
		return Vue.http.delete(`customCurriculum/curriculum/${id}`, {
		})
	},
	curriculumInterfaceTloAdd(tableName, subdomainId, tloName, tloCode) {
		return Vue.http.post(`customCurriculum/curriculum/`, {
			tableName, subdomainId, tloName, tloCode
		})
	},
	curriculumInterfaceTloUpdate(id, tloName, tloCode, tloId) {
		return Vue.http.put(`customCurriculum/curriculum/${id}`, {
			tloName, tloCode, tloId
		})
	},
	curriculumInterfaceSubDomainUpdate(id, subDomainName, orderNumber, subDomainId) {
		return Vue.http.put(`customCurriculum/curriculum/${id}`, {
			subDomainName, orderNumber, subDomainId
		})
	},
	curriculumInterfaceSubDomainAdd(tableName, subDomainName, subDomainId, domainId) {
		return Vue.http.post(`customCurriculum/curriculum/`, {
			tableName, subDomainName, subDomainId, domainId
		})
	},
	curriculumInterfaceDomainUpdate(id, domainName, domainId) {
		return Vue.http.put(`customCurriculum/curriculum/${id}`, {
			id, domainName, domainId
		})
	},
	curriculumInterfaceDomainAdd(tableName, domainName, domainId) {
		return Vue.http.post(`customCurriculum/curriculum/`, {
			tableName, domainName, domainId
		})
	},
	createStudentProfile(fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes) {
		return Vue.http.post('student', {
			fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes
		})
	},
}
