<template>
  <div class="tlo-list-card" v-cloak>
    <div class="tlo-header card-divider grid-y">
      <div>
        <span class="tlo-code grid-x cell"
          >{{ $t("tloListCard.tlo") }}: {{ tlo.tloCode }}
        </span>
        <p class="tlo-text grid-x cell auto margin-bottom-0">
          <small>{{ tlo.tloName }}</small>
        </p>
      </div>
      <MoreDropdown :options="dropdownOptions" :identifier="`tlo-${id}`">
      </MoreDropdown>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants";
import MoreDropdown from "./MoreDropdown";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CurriculumInterfaceTloEditModal from "@/views/CurriculumInterface/CurriculumInterfaceTloEditModal";
import CurriculumInterfaceEloAddModal from "@/views/CurriculumInterface/CurriculumInterfaceEloAddModal";
export default {
  name: "CurriculumInterfaceTloListCard",
  /* eslint-disable vue/no-unused-components */
  components: {
    MoreDropdown,
    CurriculumInterfaceTloEditModal,
    CurriculumInterfaceEloAddModal,
    FontAwesomeIcon,
    constants,
  },
  /* eslint-enable vue/no-unused-components */
  props: {
    tlo: {
      type: Object,
    },
    id: Number,
    tloName: String,
    domainName: String,
    subDomainName: String,
    subDomainCode: String,
    tloId: String,
    tloCode: String,
    domainCode: String,
  },
  data() {
    return {
      fromData: {
        id: "TLO-" + this.id,
      },
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
    dropdownOptions: function () {
      let dropdownOptions = {
        Add: {
          label: "tloListCard.add",
          method: this.showAddModal,
        },
        Edit: {
          label: "tloListCard.edit",
          method: this.showEditModal,
        },
        Delete: {
          label: "tloListCard.delete",
          method: this.curriculumInterfaceTloDeletes,
        },
      };

      let finalDropdownOptions = [];

      finalDropdownOptions.push(
        dropdownOptions["Add"],
        dropdownOptions["Edit"],
        dropdownOptions["Delete"]
      );

      return finalDropdownOptions;
    },
  },
  methods: {
    ...mapActions([
      "getCurriculum",
      "forceGetCurriculum",
      "curriculumInterfaceTloDelete",
    ]),
    showEditModal() {
      let componentProps = {
        domainName: this.domainName,
        domainCode: this.domainCode,
        subdomainName: this.subDomainName,
        subDomainCode: this.subDomainCode,
        id: "TLO-" + this.id,
        tloName: this.tloName,
        tloCode: this.tloCode,
        tloId: this.tloId,
      };
      this.$modal.show({
        component: CurriculumInterfaceTloEditModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    showAddModal() {
      let componentProps = {
        domainName: this.domainName,
        domainCode: this.domainCode,
        subdomainName: this.subDomainName,
        subDomainCode: this.subDomainCode,
        id: "ELO",
        eloName: "",
        eloCode: "",
        eloLink: "",
        eloStatus: "",
        tloId: this.id,
        tloName: this.tloName,
        tloCode: this.tloCode,
      };
      this.$modal.show({
        component: CurriculumInterfaceEloAddModal,
        componentProps: componentProps,
        class: "large",
        closeCallback: this.modalClose,
      });
    },
    curriculumInterfaceTloDeletes() {
      let deletedTloName = this.tloName;
      console.log("Test");
      let modalParams = {
        header: this.$t("elo.delete.modalTitle", { deletedTloName }),
        text: deletedTloName,
        includeConfirm: true,
        successCallback: this.doDeleteTlo,
      };

      this.$modal.show(modalParams);
    },
    async doDeleteTlo() {
      const curriculumInterface = this.tloName;
      try {
        const { status, data } = await this.$store.dispatch(
          "curriculumInterfaceEloDelete",
          this.fromData
        );
        if (status !== constants.RESPONSE_STATUS.OK && data) {
          return this.$toastr.error(data && data.message);
        }
        this.forceGetCurriculum();
        this.$toastr.success(
          this.$t("curriculumInterfaceModal.success.deleted", {
            curriculumInterface,
          })
        );
        this.$modal.closeAll();
      } catch (resp) {
        this.$toastr.error(resp.data && resp.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tlo-list-card {
  border-radius: 0;
  border: 0;
}
.tlo-header {
  background-color: $lighter-gray;
  border-bottom: 1px solid $light-gray;
  position: relative;
}
.tlo-text {
  color: $darker-gray;
}
.tlo-code {
  color: $dark-gray;
  font-size: 10px;
}
</style>

