<template>
    <div> <!-- v-if="assessmentProgress !== null || selfAssessmentProgress !== null"> -->
      <div class="progress-container">
        <div :class="[assessmentBarClass, 'progress-bar']" :style="assessmentBarStyle"></div>
        <span :style="assessmentTextStyle">{{ assessmentProgress || 0 }}</span>
      </div>
      <div class="explanation-container">
        <small class="explanation-text">Avg. Progress from first to latest assessment</small>
      </div>
      <div class="progress-container">
        <div :class="[selfAssessmentBarClass, 'progress-bar']" :style="selfAssessmentBarStyle"></div>
        <span :style="selfAssessmentTextStyle">{{ selfAssessmentProgress || 0 }}</span>
      </div>
      <div class="explanation-container">
        <small class="explanation-text">Avg. Progress from first to latest self assessment</small>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProgressIndicators",
    props: {
      assessmentProgress: Number,
      selfAssessmentProgress: Number,
    },
    computed: {
      assessmentBarClass() {
        return this.getColorClass(this.assessmentProgress);
      },
      selfAssessmentBarClass() {
        return this.getColorClass(this.selfAssessmentProgress);
      },
      assessmentBarStyle() {
        const width = `${Math.abs(this.assessmentProgress) * (50 / 9)}%`;
        return this.assessmentProgress >= 0
          ? `width: ${width}; left: 50%; right: auto;`
          : `width: ${width}; right: 50%; left: auto;`;
      },
      selfAssessmentBarStyle() {
        const width = `${Math.abs(this.selfAssessmentProgress) * (50 / 9)}%`;
        return this.selfAssessmentProgress >= 0
          ? `width: ${width}; left: 50%; right: auto;`
          : `width: ${width}; right: 50%; left: auto;`;
      },
      assessmentTextStyle() {
        return this.getTextStyle(this.assessmentProgress);
      },
      selfAssessmentTextStyle() {
        return this.getTextStyle(this.selfAssessmentProgress);
      },
    },
    methods: {
      getColorClass(value) {
        if (value > 0) return 'success';
        if (value < 0) return 'alert';
        return 'secondary';
      },
      getTextStyle(value) {
        let bgColor = 'grey';
        if (value > 0) bgColor = 'green';
        if (value < 0) bgColor = 'red';
        return {
          backgroundColor: bgColor,
          color: 'white',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          textAlign: 'center',
          lineHeight: '30px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        };
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .progress-container {
    position: relative;
    height: 20px;
    padding: 5px;  // Adds padding to all sides
    margin-top: 10px;  // Adds margin to the bottom
    margin-bottom: 6px;  // Adds margin to the bottom
  }
  
  .progress-bar {
    position: absolute;
    height: 20%;
    transition: width 0.3s ease;

    top: 40%;
  }
  
  .success {
    background-color: green;
  }
  
  .alert {
    background-color: red;
  }
  
  .secondary {
    background-color: grey;
  }
  
  .explanation-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .explanation-text {
    font-size: 10px;
    color: #c4c4c4;
  }
  </style>
  