import Vue from 'vue'

export default {
	getStudentPlanStatusesForClassGroup (classGroupId) {
		return Vue.http.get('studentPlanStatus/' + classGroupId)
	},
	getStudentProgrammePlanStatus (studentId, year) {
		return Vue.http.get('studentProgrammePlanStatus/' + studentId, { params: { year } })
	},
	setStudentProgrammePlanStatus (studentId, year, planStatus) {
		return Vue.http.put('studentProgrammePlanStatus/' + studentId, {
			year: year,
			planStatus: planStatus
		})
	},
	getStudentAnnualPlanStatus (studentId, planYear) {
		return Vue.http.get('studentAnnualPlanStatus/' + studentId, { params: { planYear } })
	},
	setStudentAnnualPlanStatus (studentId, planYear, planStatus) {
		return Vue.http.put('studentAnnualPlanStatus/' + studentId, {
			planYear: planYear,
			planStatus: planStatus
		})
	}
}
