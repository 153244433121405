<template>
  <div id="app">
    <router-view></router-view>
    <the-toastr></the-toastr>
    <the-modal></the-modal>
    <the-loader></the-loader>
    <refresh-token-alert />
  </div>
</template>

<script>
import TheToastr from "@/components/TheToastr";
import TheModal from "@/components/TheModal";
import TheLoader from "@/components/TheLoader";
import RefreshTokenAlert from "@/components/RefreshTokenAlert";

export default {
  name: "app",
  components: {
    TheToastr,
    TheModal,
    TheLoader,
    RefreshTokenAlert,
  },
};
</script>

<style>
ol,
dl,
RefreshTokenAlertul {
  list-style-position: inside;
}
</style>
