<template>
  <div class="grid-x datepicker-wrapper" v-cloak>
    <div class="cell medium-6 large-shrink start-date">
      {{ $t("dateRangePicker.startDate") }}:
      <input ref="startDate" type="text" />
    </div>
    <div class="cell medium-auto large-shrink end-date">
      {{ $t("dateRangePicker.endDate") }}: <input ref="endDate" type="text" />
    </div>
  </div>
</template>

<script>
import "foundation-datepicker";
import moment from "moment";

export default {
  name: "DateRangePicker",
  props: {
    startDate: {
      type: String,
      default: () => moment().format(),
    },
    endDate: {
      type: String,
      default: () => moment().add(2, "weeks").format(),
    },
  },
  data() {
    return {
      startDateP: null,
      endDateP: null,
      buttonsConfig: {
        format: "dd/mm/yyyy",
        leftArrow: "<",
        rightArrow: ">",
      },
      highlightColor: "highlight",
    };
  },
  mounted() {
    this.initStartDatePicker();
    this.initEndDatePicker();
  },
  destroyed() {
    $(".datepicker").remove();
  },
  methods: {
    initStartDatePicker() {
      let today = moment(this.startDate).toDate();

      let context = this;
      this.startDateP = $(this.$refs.startDate)
        .fdatepicker({
          ...this.buttonsConfig,
          initialDate: today,
          onRender: function (date) {
            return context.startDateOnRender(date, this);
          },
        })
        .on("changeDate", (e) => {
          this.startDateOnChange(e);
        })
        .data("datepicker");
    },
    startDateOnRender(date, el) {
      if (!this.endDateP) return;
      return date.valueOf() >= el.date.valueOf() &&
        date.valueOf() < this.endDateP.date.valueOf()
        ? this.highlightColor
        : "";
    },
    startDateOnChange(e) {
      if (e.date.valueOf() > this.endDateP.date.valueOf()) {
        let newDate = moment(e.date).add(1, "days").toDate();
        this.endDateP.update(newDate);
      }
      this.emitValues(e.date, this.endDateP.date);
      this.startDateP.hide();
      $(this.$refs.endDate).focus();
    },
    initEndDatePicker() {
      let endDay = moment(this.endDate).toDate();

      let context = this;
      this.endDateP = $(this.$refs.endDate)
        .fdatepicker({
          ...this.buttonsConfig,
          initialDate: endDay,
          minDate: this.startDateP.date,
          onRender: function (date) {
            return context.endDateOnRender(date, this);
          },
        })
        .on("changeDate", (e) => {
          this.emitValues(this.startDateP.date, e.date);
        })
        .data("datepicker");
    },
    endDateOnRender(date, el) {
      let className =
        date.valueOf() < this.startDateP.date.valueOf() ? "disabled" : ""; // Allow selection of same endDate as startDate
      className +=
        date.valueOf() >= this.startDateP.date.valueOf() &&
        date.valueOf() < el.date.valueOf()
          ? " " + this.highlightColor
          : "";
      return className;
    },
    emitValues(startDate, endDate) {
      this.$emit("update:startDate", moment(startDate).format());
      this.$emit("update:endDate", moment(endDate).format());
    },
  },
};
</script>

<style lang="scss">
.datepicker-wrapper {
  margin-top: $global-margin;

  @include breakpoint(large) {
    margin-top: 0;
  }
}
.datepicker {
  td.day {
    &.active {
      background: $brand-primary;
      color: $white;
    }

    &:hover {
      background: $primary;
      color: $white;
    }
  }
  .highlight.day {
    background: $bg-selected;
  }
}

.end-date {
  margin-left: 0;

  @include breakpoint(medium) {
    margin-left: $global-margin / 2;
  }
}
</style>
