import * as types from '../mutation-types';
import supportNeedOptionsApi from '@/api/supportNeedOptions';

// initial state
const state = {
	learningSupportNeedList: [],
	behaviourSupportNeedList: [],
	additionalSupportNeedList: []
};

// getters
const getters = {
	learningSupportNeedList(state) {
		return state.learningSupportNeedList;
	},
	behaviourSupportNeedList(state) {
		return state.behaviourSupportNeedList;
	},
	additionalSupportNeedList(state) {
		return state.additionalSupportNeedList;
	}
};

// actions
const actions = {
	async getSupportNeedOptions({ commit, dispatch }) {
		const { data } = await supportNeedOptionsApi.getSupportNeedOptions();
		commit(types.SET_LEARNING_SUPPORT_NEED_LIST, await dispatch('stringEditor', data.learningSupportNeedList));
		commit(types.SET_BEHAVIOUR_SUPPORT_NEED_LIST, await dispatch('stringEditor', data.behaviourSupportNeedList));
		commit(types.SET_ADDITIONAL_SUPPORT_NEED_LIST, await dispatch('stringEditor', data.additionalSupportNeedList));
	},
	stringEditor(vuex, stringList) {
		for (const data of stringList) {
			data.label = `${data.label[0] + data.label.slice(1).toLowerCase()} (${data.value})`;
		}
		return stringList;
	}
};

// mutations
const mutations = {
	[types.SET_LEARNING_SUPPORT_NEED_LIST](state, learningSupportNeedList) {
		state.learningSupportNeedList = learningSupportNeedList;
	},
	[types.SET_BEHAVIOUR_SUPPORT_NEED_LIST](state, behaviourSupportNeedList) {
		state.behaviourSupportNeedList = behaviourSupportNeedList;
	},
	[types.SET_ADDITIONAL_SUPPORT_NEED_LIST](state, additionalSupportNeedList) {
		state.additionalSupportNeedList = additionalSupportNeedList;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
