import * as types from '../mutation-types';
import studentAttainmentScoreApi from '@/api/studentAttainmentScore';

// initial state
const state = {
	attainmentScore: {}
};

const getters = {
	attainmentScore: (state) => (classId, studentId) => {
		let classAttainmentScore = state.attainmentScore[classId];
		return classAttainmentScore ? classAttainmentScore.find(it => it.studentId === studentId) : {};
	}
};

// actions
const actions = {
	getStudentAttainmentScore({ commit }, classId) {
		studentAttainmentScoreApi.getStudentAttainmentScore(classId).then((resp) => {
			commit(types.SET_CLASS_ATTAINMENT_SCORES, { classId, scores: resp.data.scores });
		});
	}
};

// mutations
const mutations = {
	[types.SET_CLASS_ATTAINMENT_SCORES](state, { classId, scores }) {
		state.attainmentScore = { ...state.attainmentScore, [classId]: scores };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
