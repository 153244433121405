<template>
  <div class="grid-x" v-cloak>
    <div class="report-item cell medium-10">
      <div class="report-header grid-x align-middle">
        <div class="cell auto h-pos-relative">
          <h6 class="color--white margin-bottom-0">
            {{ $t("classgroup.report.heading") }}
          </h6>
        </div>
      </div>
      <div class="report-body grid-x">
        <div class="cell auto">
          <select id="student" v-model="selectedClassId">
            <option key="null" :value="null">
              {{ $t("report.select.class") }}
            </option>
            <option
              v-for="classGroup in classGroupList"
              :key="classGroup.id"
              :value="classGroup.id"
            >
              {{ classGroup.name }}
            </option>
          </select>
        </div>
        <div class="actions cell margin-left-1 shrink">
          <button
            class="primary button margin-bottom-0"
            @click="getClassGroupCsv()"
            :disabled="!selectedClassId"
          >
            <font-awesome-icon
              class="action-item"
              :icon="['far', 'file-excel']"
              size="1x"
            ></font-awesome-icon>
            {{ $t("report.download.csv") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import fileService from "@/mixins/fileService";

export default {
  name: "ClassGroupReport",
  components: {
    FontAwesomeIcon,
  },
  mixins: [fileService],
  props: {
    classGroupList: Array,
  },
  data() {
    return {
      selectedClassId: null,
    };
  },
  methods: {
    getClassGroupCsv() {
      if (this.selectedClassId) {
        this.$store
          .dispatch("getClassGroupReport", { classId: this.selectedClassId })
          .then(
            (resp) => {
              let fileName =
                "CLASSGROUP-" +
                this.getClassGroupName(this.selectedClassId) +
                ".csv";
              this.downloadFile(fileName, resp);
            },
            () => {
              this.$toastr.error(this.$t("error.notFound"));
            }
          );
      } else {
        console.log("No class selected.");
      }
    },
    getClassGroupName(selectedClassId) {
      let classGroup = this.classGroupList.find(
        (it) => it.id === selectedClassId
      );
      return classGroup ? classGroup.name.replace(/[\s+|\u00a0]+/g, "_") : null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
