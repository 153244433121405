<template>
  <div
    @click="addToEloCodes($event, textId)"
    class="card-section edit"
    :class="[isAdded ? 'added' : '']"
    :style="piepActiveCheck ? '' : 'background-color: #efefef'"
    v-cloak
  >
    <FontAwesomeIcon
      v-if="isAdded"
      icon="check"
      class="check-icon color--success"
    ></FontAwesomeIcon>

    <elo-list-card-details
      :elo-code="textId"
      :elo-name="textName"
      :programme-codes="programmeCodes"
      :show-details="isDeveloped"
      :link="link"
    >
      <p><elo-list-card-strands :strands="strands"></elo-list-card-strands></p>
    </elo-list-card-details>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EloListCardDetails from "./EloListCardDetails";
import EloListCardStrands from "./EloListCardStrands";

export default {
  name: "EloSideTrayEloListCard",
  components: {
    FontAwesomeIcon,
    EloListCardDetails,
    EloListCardStrands,
  },
  props: {
    textId: String,
    textName: String,
    programmeCodes: Array,
    strands: Array,
    isDeveloped: Boolean,
    link: String,
    active: {
      required: false,
      type: [Boolean, null, String],
      default: false,
    },
  },
  data() {
    return {
      added: false,
    };
  },
  computed: {
    ...mapGetters({
      elosToAdd: "elosToAdd",
    }),
    isAdded: function () {
      return _.indexOf(this.elosToAdd, this.textId) >= 0;
    },
    piepActiveCheck: function () {
      if (this.$route.name === "Piep") {
        return this.active;
      } else {
        return true;
      }
    },
  },
  methods: {
    addToEloCodes(event, code) {
      if (!this.active && this.$route.name === "Piep") {
        return 0;
      }
      if (event.target.classList[1] !== "linkTab") {
        if (!this.isAdded) {
          this.$store.dispatch("addToEloCode", code);
        } else {
          this.$store.dispatch("removeFromEloCode", code);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-section {
  position: relative;
  border-bottom: 1px solid $gray;

  &.edit {
    cursor: pointer;
  }

  &.added {
    background-color: $bg-selected;
    border-left: 2px solid $success;
  }

  .check-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}
</style>

