import * as types from '../mutation-types';
import lessonPlanRemarksApi from '@/api/lessonPlanRemarks';

const state = {
	lessonPlanRemarks: {}
};

const getters = {
	lessonPlanRemarks: (state) => (lessonPlanId) => {
		return state.lessonPlanRemarks[lessonPlanId];
	}
};

const actions = {
	getLessonPlanRemarks({ commit }, { lessonPlanId }) {
		lessonPlanRemarksApi.getLessonPlanRemarks(lessonPlanId).then((resp) => {
			if (resp.data && resp.data.lessonPlanRemarks) {
				commit(types.SET_LESSON_PLAN_REMARKS, { lessonPlanId: lessonPlanId, lessonPlanRemarks: resp.data.lessonPlanRemarks });
			}
		});
	},
	postLessonPlanRemark({ commit }, { lessonPlanId, remark }) {
		lessonPlanRemarksApi.postLessonPlanRemark(lessonPlanId, remark).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_PLAN_REMARKS, { lessonPlanId: lessonPlanId, lessonPlanRemarks: resp.data });
			}
		});
	},
	deleteLessonPlanRemark({ commit }, remarkId) {
		return lessonPlanRemarksApi.deleteLessonPlanRemark(remarkId);
	},
	addEvidenceToLessonPlanRemark({ commit }, { details, lessonPlanId }) {
		return lessonPlanRemarksApi.postLessonPlanRemarkEvidence(details).then((resp) => {
			if (resp.data) {
				commit(types.SET_LESSON_PLAN_REMARKS, { lessonPlanId: lessonPlanId, lessonPlanRemarks: resp.data });
			}
		});
	},
	getLessonPlanRemarkEvidence({ commit }, { remarkId, fileUUID }) {
		return lessonPlanRemarksApi.getLessonPlanRemarkEvidence(remarkId, fileUUID);
	}
};

const mutations = {
	[types.SET_LESSON_PLAN_REMARKS](state, { lessonPlanId, lessonPlanRemarks }) {
		state.lessonPlanRemarks = { ...state.lessonPlanRemarks, [lessonPlanId]: lessonPlanRemarks };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
