<template>
  <div id="mainWrapper">
    <div id="ReportSection">
      <h5>Report Type</h5>
      <div class="row">
        <select class="selectCheck" v-model="reportTypeId">
          <option :key="0" :value="0">{{ $t("reports.select.type") }}</option>
          <option
            v-for="report in reportType"
            :key="report.id"
            :value="report.id"
          >
            {{ report.name }}
          </option>
        </select>
      </div>
      <div v-if="reportTypeId !== 0">
        <div class="row">
          <select class="selectCheck" v-model="year1">
            <option :key="0" :value="0">{{ $t("reports.select.year") }}</option>
            <option v-for="year in yearList" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
          <select
            v-if="reportTypeId <= 2 || reportTypeId === 5"
            class="selectCheck"
            v-model="term1"
            @change="termCheck('assesmentPoint1', $event)"
          >
            <option :key="0" :value="0">{{ $t("reports.select.term") }}</option>
            <option v-for="term in terms" :key="term.id" :value="term.id">
              {{ term.name }}
            </option>
          </select>
          <select
            v-if="reportTypeId <= 2 || reportTypeId === 5"
            class="selectCheck"
            v-model="assesmentPoint1"
            :disabled="!(term1 === 3 || term1 === 4)"
          >
            <option :key="0" :value="0">
              {{ $t("reports.select.assesment") }}
            </option>
            <option
              v-for="assessment in term1 === 3
                ? [2, 3]
                : term1 === 4
                ? [4, 5]
                : [1]"
              :key="assessment"
              :value="
                term1 === 3
                  ? assessment - 1
                  : term1 === 4
                  ? assessment - 3
                  : assessment
              "
            >
              {{
                term1 === 1 ? 0 : term1 === 2 ? 1 : term1 === 5 ? 6 : assessment
              }}
            </option>
          </select>
        </div>
        <div v-if="reportTypeId <= 2 || reportTypeId === 5" class="row">
          <select class="selectCheck" v-model="year2">
            <option :key="0" :value="0">{{ $t("reports.select.year") }}</option>
            <option v-for="year in yearList" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
          <select
            class="selectCheck"
            v-model="term2"
            @change="termCheck('assesmentPoint2', $event)"
          >
            <option :key="0" :value="0">{{ $t("reports.select.term") }}</option>
            <option v-for="term in terms" :key="term.id" :value="term.id">
              {{ term.name }}
            </option>
          </select>
          <select
            class="selectCheck"
            v-model="assesmentPoint2"
            :disabled="!(term2 === 3 || term2 === 4)"
          >
            <option :key="0" :value="0">
              {{ $t("reports.select.assesment") }}
            </option>
            <option
              v-for="assessment in term2 === 3
                ? [2, 3]
                : term2 === 4
                ? [4, 5]
                : [1]"
              :key="assessment"
              :value="
                term2 === 3
                  ? assessment - 1
                  : term2 === 4
                  ? assessment - 3
                  : assessment
              "
            >
              {{
                term2 === 1 ? 0 : term2 === 2 ? 1 : term2 === 5 ? 6 : assessment
              }}
            </option>
          </select>
        </div>
        <div class="row underline" />
      </div>
    </div>
    <div v-if="reportTypeId !== 0" id="SelectionSection">
      <div class="selectionHeader">
        <h5>Selection</h5>
        <div style="display: flex">
          <h5 class="clearSelection" @click="clearSelection()">
            Clear selection
          </h5>
          <h5 class="clearOrder" @click="clearOrder()">Clear order</h5>
        </div>
      </div>
      <div class="row">
        <MasterReportsMultiSelect
          name="school"
          :addCheckList="
            () => addCheckList({ key: 'id', table: 'school' }, 'School')
          "
          :checkListModel.sync="checkListSchool"
          :labelList="selectionList"
          :inputList="school"
          :addValue="(value) => addValue(value, 'school')"
          :labelOption="schoolNameList || $t('reports.select.school')"
        />
      </div>
      <div class="row">
        <MasterReportsMultiSelect
          name="programme"
          :addCheckList="
             () => addCheckList({ key: 'code', table: 'programme' }, 'Programme')
          "
          :checkListModel.sync="checkListProgramme"
          :labelList="programmeList"
          :inputList="programme"
          :addValue="(value) => addValue(value, 'programme')"
          :labelOption="programmeNameList || $t('reports.select.programme')"
        />
        <MasterReportsMultiSelect
          name="class"
          :addCheckList="
            () => addCheckList({ key: 'id', table: 'class_group' }, 'Class')
          "
          :checkListModel.sync="checkListClass"
          :labelList="classList"
          :inputList="classId"
          :addValue="(value) => addValue(value, 'classId')"
          :labelOption="classNameList || $t('reports.select.class')"
        />
        <MasterReportsMultiSelect
          name="Student"
          :disable="classId.length === 0"
          :addCheckList="
            () => addCheckList({ key: 'id', table: 'student' }, 'Student')
          "
          :checkListModel.sync="checkListStudent"
          :labelList="studentList"
          :inputList="student"
          :addValue="(value) => addValue(value, 'student')"
          :labelOption="studentNameList || $t('reports.select.student')"
        />
      </div>
      <div class="row">
        <MasterReportsMultiSelect
          name="domain"
          :addCheckList="
            () => addCheckList({ key: 'id', table: 'domain' }, 'Domain')
          "
          :checkListModel.sync="checkListDomain"
          :labelList="domainList"
          :inputList="domain"
          :addValue="(value) => addValue(value, 'domain')"
          :labelOption="domainNameList || $t('reports.select.domain')"
        />
        <MasterReportsMultiSelect
          name="tlo"
          :addCheckList="
            () =>
              addCheckList(
                { key: 'tlo_code', table: 'terminal_learning_objective' },
                'TLO'
              )
          "
          :checkListModel.sync="checkListTLO"
          :labelList="tloLists"
          :inputList="tlo"
          :addValue="(value) => addValue(value, 'tlo')"
          :labelOption="tloNameList || $t('reports.select.tlo')"
        />
        <MasterReportsMultiSelect
          name="elo"
          :addCheckList="
            () =>
              addCheckList(
                { key: 'elo_code', table: 'enabling_learning_objective' },
                'ELO'
              )
          "
          :checkListModel.sync="checkListElo"
          :labelList="eloLists"
          :inputList="elo"
          :addValue="(value) => addValue(value, 'elo')"
          :labelOption="eloNameList || $t('reports.select.elo')"
        />
      </div>
      <div class="row">
        <MasterReportsMultiSelect
          name="outcome"
          :addCheckList="
            () =>
              addCheckList(
                { key: 'programme_outcome', table: 'elo_programme_outcome' },
                'Programme Outcome'
              )
          "
          :checkListModel.sync="checkListOutcome"
          :labelList="programmeOutcomeList"
          :inputList="programmeOutcome"
          :addValue="(value) => addValue(value, 'programmeOutcome')"
          :labelOption="outputNameList || $t('reports.select.programmeOutcome')"
        />
      </div>
    </div>
    <div v-if="reportTypeId !== 0" id="LearningProfileSection">
      <div style="display: flex; align-items: center; margin: 0 0 8px 0">
        <input
          v-if="!checkListLearningProfile.status"
          style="cursor: pointer; user-select: none"
          @click="
            addCheckList(
              { key: 'support_needs', table: 'student' },
              'Student Learning Profile'
            )
          "
          type="checkbox"
          v-model="checkListLearningProfile.status"
        />
        <h5
          v-else
          style="
            margin: 0 1.09vw 0 0;
            width: 1.09vw;
            cursor: pointer;
            user-select: none;
          "
          @click="
            addCheckList(
              { key: 'support_needs', table: 'student' },
              'Support Need'
            )
          "
        >
          {{ checkListLearningProfile.number + 1 }}
        </h5>
        <h5 style="margin: 0">Student Learning Profile</h5>
      </div>
      <div class="row">
        <MasterReportsMultiSelect
          name="learning"
          :addCheckList="
            () =>
              addCheckList(
                { key: 'learning_support_need', table: 'student' },
                'Learning Support Need'
              )
          "
          :checkListModel.sync="checkListLearning"
          :labelList="learningSelection"
          :addValue="(value) => addValue(value, 'learning')"
          :inputList="learning"
          :labelOption="learningNameList || $t('reports.select.learning')"
        />
        <MasterReportsMultiSelect
          name="behaviour"
          :addCheckList="
            () =>
              addCheckList(
                { key: 'behaviour_support_need', table: 'student' },
                'Behaviour Support Need'
              )
          "
          :checkListModel.sync="checkListBehaviour"
          :labelList="behaviourSelection"
          :addValue="(value) => addValue(value, 'behaviour')"
          :inputList="behaviour"
          :labelOption="behaviourNameList || $t('reports.select.behaviour')"
        />
        <MasterReportsMultiSelect
          name="support"
          :addCheckList="
            () =>
              addCheckList(
                { key: 'additional_support_need', table: 'student' },
                'Additional Support Need'
              )
          "
          :checkListModel.sync="checkListSupport"
          :labelList="additionalSelection"
          :addValue="(value) => addValue(value, 'supportNeed')"
          :inputList="supportNeed"
          :labelOption="supportNameList || $t('reports.select.supportNeed')"
        />
      </div>
    </div>
    <div v-if="reportTypeId !== 0" id="FilterBy">
      <h5>Filter By</h5>
      <div class="row">
        <select class="selectCheck" v-model="coreSteps1">
          <option
            :key="0"
            @click="() => (showList['coreSteps1'] = '')"
            :value="0"
          >
            {{ $t("reports.select.lowerCoreSteps") }}
          </option>
          <option
            v-for="report in coreSteps"
            :key="report.id"
            @click="() => (showList['coreSteps1'] = `${report.name}%`)"
            :value="report.name"
          >
            {{ report.name + "%" }}
          </option>
        </select>
        <MasterReportsMultiSelect
          class="selectCheck"
          name="attainment"
          :showCheck="false"
          :labelList="attainmentList"
          :inputList="attainment"
          :addValue="(value) => addValue(value, 'attainment')"
          :labelOption="
            attainmentNameList || $t('reports.select.lowerAttainment')
          "
        />
      </div>
      <div class="row">
        <select class="selectCheck" v-model="coreSteps2">
          <option
            :key="0"
            @click="() => (showList['coreSteps2'] = '')"
            :value="0"
          >
            {{ $t("reports.select.higherCoreSteps") }}
          </option>
          <option
            v-for="report in coreSteps"
            :key="report.id"
            @click="() => (showList['coreSteps2'] = `${report.name}%`)"
            :value="report.name"
          >
            {{ report.name + "%" }}
          </option>
        </select>
        <select class="selectCheck" v-model="criteria">
          <option
            :key="0"
            @click="() => (showList['criteria'] = '')"
            :value="0"
          >
            {{ $t("reports.select.criteria") }}
          </option>
          <option
            v-for="report in criteriaList"
            :key="report.id"
            @click="() => (showList['criteria'] = `${report.name}`)"
            :value="report.id"
          >
            {{ report.name }}
          </option>
        </select>
      </div>
      <div class="row underline" />
    </div>
    <button
      :disabled="!submitButton"
      v-if="reportTypeId !== 0"
      class="primary button selectCheck"
      @click="submit()"
    >
      Create Report
    </button>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MasterReportsMultiSelect from "./MasterReportsMultiSelect";
import _ from "lodash";

export default {
  name: "MasterReport",
  components: {
    MasterReportsMultiSelect,
  },
  data: () => {
    return {
      reportTypeId: 0,
      year1: 0,
      term1: 0,
      assesmentPoint1: 0,
      year2: 0,
      term2: 0,
      assesmentPoint2: 0,
      school: [],
      programme: [],
      classId: [],
      student: [],
      domain: [],
      elo: [],
      tlo: [],
      programmeOutcome: [],
      learning: [],
      behaviour: [],
      supportNeed: [],
      coreSteps1: 0,
      attainment: [],
      coreSteps2: 0,
      criteria: 0,
      coreSteps: [],
      criteriaList: [
        { id: 1, name: "Missing Attainment" },
        { id: 2, name: "Opposite Selection" },
      ],
      programmeOutcomeList: [
        { id: "JO1", name: "JO1" },
        { id: "JO2", name: "JO2" },
        { id: "JO3", name: "JO3" },
        { id: "JO4", name: "JO4" },
        { id: "JO5", name: "JO5" },
        { id: "MO1", name: "MO1" },
        { id: "MO2", name: "MO2" },
        { id: "MO3", name: "MO3" },
        { id: "MO4", name: "MO4" },
        { id: "MO5", name: "MO5" },
        { id: "SO1", name: "SO1" },
        { id: "SO2", name: "SO2" },
        { id: "SO3", name: "SO3" },
        { id: "SO4", name: "SO4" },
        { id: "SO5", name: "SO5" },
      ],
      reportType: [
        { id: 1, name: "ELO Term-to-term comparison summary" },
        { id: 2, name: "ELO Term-to-term comparison student listing" },
        { id: 3, name: "ELO Full year report (All terms, summary)" },
        { id: 4, name: "ELO Full year report (All terms, student listing)" },
        { id: 5, name: "Student data term-to-term comparisons" },
        { id: 6, name: "Student data full year comparisons" },
      ],
      terms: [
        { id: 1, name: "Baseline" },
        { id: 2, name: "Term 1" },
        { id: 3, name: "Term 2" },
        { id: 4, name: "Term 3" },
        { id: 5, name: "Term 4" },
      ],
      learningSelection: [
        { id: "A", name: "Low (A)" },
        { id: "B", name: "Moderate (B)" },
        { id: "C", name: "High (C)" },
      ],
      behaviourSelection: [
        { id: 1, name: "Low (1)" },
        { id: 2, name: "Moderate (2)" },
        { id: 3, name: "High (3)" },
        { id: 4, name: "Exceptional (4)" },
      ],
      additionalSelection: [
        { id: "X", name: "Low (X)" },
        { id: "Y", name: "Moderate (Y)" },
        { id: "Z", name: "High (Z)" },
      ],
      programmeList: [],
      // { id: 'J', name: 'Junior' },
      // { id: 'M', name: 'Middle' },
      // { id: 'S', name: 'Senior' }
      // ],
      yearList: [],
      checkList: [],
      sendCheckList: [],
      selectionList: [],
      domainList: [],
      tloList: [],
      attainmentList: [
        { name: "EXPERIENCED", id: "EXPERIENCED" },
        { name: "DEVELOPING", id: "DEVELOPING" },
        { name: "COOPERATIVE", id: "COOPERATIVE" },
        { name: "ROUTINISED", id: "ROUTINISED" },
        { name: "ADAPTIVE", id: "ADAPTIVE" },
      ],
      showList: {
        school: [],
        programme: [],
        classId: [],
        student: [],
        domain: [],
        tlo: [],
        elo: [],
        programmeOutcome: [],
        learning: [],
        behaviour: [],
        supportNeed: [],
        coreSteps1: "",
        attainment: [],
        coreSteps2: "",
        criteria: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentYear",
      "curriculum",
      "leadershipReport",
      "taskItemCategoryList",
      "dataSelected",
      "selectedList",
      "dashboardList",
      "classes",
    ]),
    checkListSchool() {
      return {
        status:
          this.checkList.findIndex(({ table }) => table === "school") >= 0,
        number: this.checkList.findIndex((el) => el.table === "school"),
      };
    },
    checkListProgramme() {
      return {
        status:
          this.checkList.findIndex(({ table }) => table === "programme") >= 0,
        number: this.checkList.findIndex((el) => el.table === "programme"),
      };
    },
    checkListClass() {
      return {
        status:
          this.checkList.findIndex(({ table }) => table === "class_group") >= 0,
        number: this.checkList.findIndex((el) => el.table === "class_group"),
      };
    },
    checkListStudent() {
      return {
        status:
          this.checkList.findIndex(
            ({ table, key }) => table === "student" && key === "id"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.table === "student" && el.key === "id"
        ),
      };
    },
    checkListDomain() {
      return {
        status:
          this.checkList.findIndex(({ table }) => table === "domain") >= 0,
        number: this.checkList.findIndex((el) => el.table === "domain"),
      };
    },
    checkListElo() {
      return {
        status:
          this.checkList.findIndex(
            ({ table }) => table === "enabling_learning_objective"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.table === "enabling_learning_objective"
        ),
      };
    },
    checkListOutcome() {
      return {
        status:
          this.checkList.findIndex(
            ({ table }) => table === "elo_programme_outcome"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.table === "elo_programme_outcome"
        ),
      };
    },
    checkListTLO() {
      return {
        status:
          this.checkList.findIndex(
            ({ table }) => table === "terminal_learning_objective"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.table === "terminal_learning_objective"
        ),
      };
    },
    checkListLearningProfile() {
      return {
        status:
          this.checkList.findIndex(({ key }) => key === "support_needs") >= 0,
        number: this.checkList.findIndex((el) => el.key === "support_needs"),
      };
    },
    checkListLearning() {
      return {
        status:
          this.checkList.findIndex(
            ({ key }) => key === "learning_support_need"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.key === "learning_support_need"
        ),
      };
    },
    checkListBehaviour() {
      return {
        status:
          this.checkList.findIndex(
            ({ key }) => key === "behaviour_support_need"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.key === "behaviour_support_need"
        ),
      };
    },
    checkListSupport() {
      return {
        status:
          this.checkList.findIndex(
            ({ key }) => key === "additional_support_need"
          ) >= 0,
        number: this.checkList.findIndex(
          (el) => el.key === "additional_support_need"
        ),
      };
    },
    schoolNameList() {
      const { school, selectionList } = this;
      if (school.length > 0) {
        let returnList = [];
        for (const data of school) {
          const index = selectionList.findIndex((el) => el.id === data);
          returnList.push(selectionList[index].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    studentNameList() {
      const { student, studentList } = this;
      if (student.length > 0) {
        let returnList = [];
        for (const data of student) {
          const index = studentList.findIndex((el) => el.id === data);
          returnList.push(studentList[index].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    programmeNameList() {
      const { programmeList, programme } = this;
      if (programme.length > 0) {
        let returnList = [];
        for (const data of programme) {
          const index = programmeList.findIndex((el) => el.id === data);
          returnList.push(programmeList[index].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    classNameList() {
      const { classId, allClassList } = this;
      if (classId.length > 0) {
        let returnList = [];
        for (const data of classId) {
          const index = allClassList.findIndex((el) => el.id === data);
          if (index > -1) returnList.push(allClassList[index].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    domainNameList() {
      const { domain, domainList } = this;
      if (domain.length > 0) {
        let returnList = [];
        for (const data of domain) {
          returnList.push(domainList[data - 1].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    attainmentNameList() {
      const { attainmentList, attainment } = this;
      if (attainment.length > 0) {
        let returnList = [];
        for (const data of attainment) {
          const response = attainmentList.findIndex((el) => el.id === data);
          returnList.push(attainmentList[response].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    tloNameList() {
      const { tlo, tloLists } = this;
      if (tlo.length > 0) {
        const returnList = [];
        for (const data of tlo) {
          const value = tloLists.findIndex(({ id }) => id === data);
          returnList.push(tloLists[value].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    tloLists() {
      const returnList = [];
      const { tloList, domain } = this;
      if (domain.length > 0) {
        for (const domains of domain) {
          for (const { tlos } of tloList[domains]) {
            for (const { tloCode, tloName } of tlos) {
              if (returnList.findIndex((el) => el.id === tloCode) === -1) {
                returnList.push({
                  id: tloCode,
                  name: `${tloCode} - ${tloName}`,
                });
              }
            }
          }
        }
      } else {
        for (const domains in tloList) {
          for (const { tlos } of tloList[domains]) {
            for (const { tloCode, tloName } of tlos) {
              if (returnList.findIndex((el) => el.id === tloCode) === -1) {
                returnList.push({
                  id: tloCode,
                  name: `${tloCode} - ${tloName}`,
                });
              }
            }
          }
        }
      }

      return returnList;
    },
    eloNameList() {
      const { elo, allEloLists } = this;
      if (elo.length > 0) {
        let returnList = [];
        for (const data of elo) {
          const eloIndex = allEloLists.findIndex((el) => el.id === data);
          if (eloIndex > -1) returnList.push(allEloLists[eloIndex].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
    eloLists() {
      const returnList = [];
      const { tloList, domain } = this;
      if (domain.length > 0) {
        for (const domains of domain) {
          for (const { tlos } of tloList[domains]) {
            for (const { tloCode, elos } of tlos) {
              if (this.tlo.length > 0) {
                for (const data of this.tlo) {
                  if (data === tloCode) {
                    for (const eloList of elos) {
                      const { eloName, eloCode, eloId, id, programmeCodes } =
                        eloList;
                      returnList.push({
                        name: `${eloCode} - ${eloName}`,
                        eloCode,
                        eloId,
                        id,
                        programmeCodes,
                      });
                    }
                  }
                }
              } else {
                for (const eloList of elos) {
                  const { eloName, eloCode, eloId, id, programmeCodes } =
                    eloList;
                  returnList.push({
                    name: `${eloCode} - ${eloName}`,
                    eloCode,
                    eloId,
                    id,
                    programmeCodes,
                  });
                }
              }
            }
          }
        }
      } else {
        for (const domain in tloList) {
          for (const { tlos } of tloList[domain]) {
            for (const { tloCode, elos } of tlos) {
              if (this.tlo.length > 0) {
                for (const data of this.tlo) {
                  if (data === tloCode) {
                    for (const eloList of elos) {
                      const { eloName, eloCode, eloId, id, programmeCodes } =
                        eloList;
                      returnList.push({
                        name: `${eloCode} - ${eloName}`,
                        eloCode,
                        eloId,
                        id,
                        programmeCodes,
                      });
                    }
                  }
                }
              } else {
                for (const eloList of elos) {
                  const { eloName, eloCode, eloId, id, programmeCodes } =
                    eloList;
                  returnList.push({
                    name: `${eloCode} - ${eloName}`,
                    eloCode,
                    eloId,
                    id,
                    programmeCodes,
                  });
                }
              }
            }
          }
        }
      }
      return returnList;
    },
    allEloLists() {
      const returnList = [];
      const { tloList } = this;
      for (const domain in tloList) {
        for (const { tlos } of tloList[domain]) {
          for (const { elos } of tlos) {
            for (const eloList of elos) {
              const { eloName, eloCode, eloId, id, programmeCodes } = eloList;
              returnList.push({
                name: eloName,
                eloCode,
                eloId,
                id,
                programmeCodes,
              });
            }
          }
        }
      }
      return returnList;
    },
    classList() {
      const { selectionList, school, programme, year1, year2 } = this;
      const response = [];
      if (school.length !== 0 && programme.length !== 0) {
        for (const sch of school) {
          const schIndex = selectionList.findIndex((el) => el.id === sch);
          for (const prog of programme) {
            const programmeIndex = selectionList[schIndex].programmes.findIndex(
              (el) => el.code === prog
            );
            if (programmeIndex > -1) {
              for (const i of selectionList[schIndex].programmes[programmeIndex]
                .classes) {
                if (i.year === year1 || i.year === year2) response.push(i);
              }
            }
          }
        }
      } else if (school.length > 0) {
        for (const sch of school) {
          const schIndex = selectionList.findIndex((el) => el.id === sch);
          for (const programme of selectionList[schIndex].programmes) {
            for (const classes of programme.classes) {
              if (classes.year === year1 || classes.year === year2) {
                response.push(classes);
              }
            }
          }
        }
      } else if (programme.length !== 0) {
        for (const school of selectionList) {
          for (const prog of programme) {
            const programmeIndex = school.programmes.findIndex(
              (el) => el.code === prog
            );
            if (programmeIndex > -1) {
              for (const i of school.programmes[programmeIndex].classes) {
                if (i.year === year1 || i.year === year2) response.push(i);
              }
            }
          }
        }
      } else {
        return this.allClassList;
      }
      return response;
    },
    allClassList() {
      const { selectionList, year1, year2 } = this;
      const response = [];
      for (const school of selectionList) {
        for (const programmes of school.programmes) {
          for (const classes of programmes.classes) {
            if (classes.year === year1 || classes.year === year2) {
              response.push(classes);
            }
          }
        }
      }
      return response;
    },
    studentList() {
      const response = [];
      if (this.classId.length > 0) {
        for (const data of this.classId) {
          const index = this.classes.findIndex(({ id }) => data === id);
          for (const { id, fullName } of this.classes[index].students) {
            response.push({ id, name: fullName });
          }
        }
        return response;
      } else {
        for (const data of this.classes) {
          for (const { id, fullName } of data.students) {
            response.push({ id, name: fullName });
          }
        }
        return response;
      }
    },
    submitButton() {
      const {
        reportTypeId,
        year1,
        term1,
        year2,
        term2,
        assesmentPoint1,
        assesmentPoint2,
      } = this;

      if (reportTypeId < 3 || reportTypeId === 5) {
        return (
          year1 > 0 &&
          term1 > 0 &&
          year2 > 0 &&
          term2 > 0 &&
          assesmentPoint1 > 0 &&
          assesmentPoint2 > 0
        );
      } else if (reportTypeId > 2 || reportTypeId === 6) {
        return year1 > 0;
      }
      return false;
    },
    outputNameList() {
      const { programmeOutcome, programmeOutcomeList } = this;
      if (programmeOutcome.length > 0) {
        const returnList = [];
        for (const data of programmeOutcome) {
          const index = programmeOutcomeList.findIndex((el) => el.id === data);
          returnList.push(programmeOutcomeList[index].name);
        }

        return returnList.join(", ");
      } else {
        return null;
      }
    },
    learningNameList() {
      const { learningSelection, learning } = this;
      if (learning.length > 0) {
        const returnList = [];
        for (const data of learning) {
          const index = learningSelection.findIndex((el) => el.id === data);
          returnList.push(learningSelection[index].name);
        }

        return returnList.join(", ");
      } else {
        return null;
      }
    },
    behaviourNameList() {
      const { behaviourSelection, behaviour } = this;
      if (behaviour.length > 0) {
        const returnList = [];
        for (const data of behaviour) {
          const index = behaviourSelection.findIndex((el) => el.id === data);
          returnList.push(behaviourSelection[index].name);
        }

        return returnList.join(", ");
      } else {
        return null;
      }
    },
    supportNameList() {
      const { additionalSelection, supportNeed } = this;
      if (supportNeed.length > 0) {
        const returnList = [];
        for (const data of supportNeed) {
          const index = additionalSelection.findIndex((el) => el.id === data);
          returnList.push(additionalSelection[index].name);
        }
        return returnList.join(", ");
      } else {
        return null;
      }
    },
  },
  async created() {
    await this.getDashboardList();
    await this.getAssessmentOptions();
    await this.getCurriculum();
    if (Object.keys(this.dataSelected).length !== 0) {
      const {
        reportTypeId,
        year1,
        term1,
        assesmentPoint1,
        year2,
        term2,
        assesmentPoint2,
        checkList,
        sendCheckList,
        school,
        programme,
        classId,
        student,
        domain,
        elo,
        tlo,
        programmeOutcome,
        learning,
        behaviour,
        supportNeed,
        coreSteps1,
        attainment,
        coreSteps2,
        criteria,
      } = this.dataSelected;
      this.reportTypeId = reportTypeId;
      this.year1 = year1;
      this.term1 = term1;
      this.assesmentPoint1 = assesmentPoint1;
      this.year2 = year2;
      this.term2 = term2;
      this.assesmentPoint2 = assesmentPoint2;
      this.checkList = checkList;
      this.sendCheckList = sendCheckList;
      this.school = school;
      this.programme = programme;
      this.classId = classId;
      this.student = student;
      this.domain = domain;
      this.elo = elo;
      this.tlo = tlo;
      this.programmeOutcome = programmeOutcome;
      this.learning = learning;
      this.behaviour = behaviour;
      this.supportNeed = supportNeed;
      this.coreSteps1 = coreSteps1;
      this.attainment = attainment;
      this.coreSteps2 = coreSteps2;
      this.criteria = criteria;
    } else {
      this.year1 = this.currentYear;
    }
    if (Object.keys(this.selectedList).length !== 0) {
      this.showList = this.selectedList;
    }
    const {
      currentYear,
      coreSteps,
      curriculum: { domains, domainDetails },
      dashboardList,
    } = this;
    this.tloList = domainDetails;
    let id = 1;
    for (let percent = 5; percent <= 100; percent += 5) {
      coreSteps.push({ id, name: percent });
      id++;
    }
    for (const { domainId, domainName, id } of domains) {
      this.domainList.push({ domainId, name: domainName, id });
    }
    this.selectionList = dashboardList;
    for (let i = currentYear - 5; i <= this.currentYear; i++) {
      this.yearList.push(i);
    }

    this.yearList.reverse();
    for(let i = this.curriculum.programmes.length -1; i >= 0; i--){
      this.programmeList.push({
        id: this.curriculum.programmes[i].code,
        name: this.curriculum.programmes[i].name,
      });
    }
  },
  methods: {
    ...mapActions([
      "getCurriculum",
      "createReport",
      "getAssessmentOptions",
      "setSelection",
      "setReportType",
      "setDataSelection",
      "setSelectedList",
      "setAttainment",
      "setTitle",
      "getSchools",
      "getDashboardList",
    ]),
    addValue({ id, name }, object, sort = true) {
      const response = this[object].findIndex((el) => el === id);

      if (response === -1) {
        this[object].push(id);
        this.showList[object].push(name);
      } else {
        this[object].splice(response, 1);
        this.showList[object].splice(response, 1);
      }
      /*
			//Needs use of a Maps to sync these sorts if ever sort is needed.
			this[object].sort((a, b) => {
				if (a < b) return -1
				if (a > b) return 1
				return 0
			})
			this.showList[object].sort((a, b) => {
				if (a < b) return -1
				if (a > b) return 1
				return 0
			})
			*/
      console.log("object list :: " + this[object]);
      console.log("showList includes :: " + this.showList[object]);
    },
    addCheckList(data, string) {
      const response = this.checkList.findIndex(
        ({ table, key }) => table === data.table && key === data.key
      );
      if (response > -1) {
        this.checkList.splice(response, 1);
        this.sendCheckList.splice(response, 1);
      } else {
        this.checkList.push(data);
        this.sendCheckList.push(string);
      }
    },
    clearSelection() {
      this.school = [];
      this.programme = [];
      this.classId = [];
      this.student = [];
      this.domain = [];
      this.tlo = [];
      this.elo = [];
      this.programmeOutcome = [];
      this.learning = [];
      this.behaviour = [];
      this.supportNeed = [];
      this.resetShowList();
    },
    resetShowList() {
      this.showList = {
        school: [],
        programme: [],
        classId: [],
        student: [],
        domain: [],
        tlo: [],
        elo: [],
        programmeOutcome: [],
        learning: [],
        behaviour: [],
        supportNeed: [],
        coreSteps1: "",
        attainment: [],
        coreSteps2: "",
        criteria: "",
      };
    },
    clearOrder() {
      this.checkList = [];
      this.sendCheckList = [];
    },
    termCheck(stateName, e) {
      const id = Number(e.target.value);

      if (id === 3 || id === 4 || id === 0) {
        this[stateName] = 0;
      } else {
        this[stateName] = 1;
      }
    },
    async submit() {
      let apiBody;
      const selectedElo = [];
      const {
        reportTypeId,
        year1,
        term1,
        assesmentPoint1,
        year2,
        term2,
        assesmentPoint2,
        school,
        programme,
        classId,
        student,
        domain,
        tlo,
        elo,
        programmeOutcome,
        learning,
        behaviour,
        supportNeed,
        coreSteps1,
        attainment,
        coreSteps2,
        criteria,
        criteriaList,
        checkList,
        sendCheckList,
      } = this;
      if (reportTypeId === 0) return 0;

      elo.map((element) => {
        selectedElo.push(
          this.allEloLists.find((el) => el.id === element).eloCode
        );
        // selectedElo.push(this.allEloLists[element - 1].eloCode)
      });

      if ((reportTypeId < 5 && reportTypeId > 2) || reportTypeId === 6) {
        apiBody = {
          reportType: [
            {
              type: reportTypeId,
              comparisons: [
                {
                  assessmentCycle: 1,
                  term: 0,
                  year: year1,
                },
                {
                  assessmentCycle: 1,
                  term: 1,
                  year: year1,
                },
                {
                  assessmentCycle: 1,
                  term: 2,
                  year: year1,
                },
                {
                  assessmentCycle: 2,
                  term: 2,
                  year: year1,
                },
                {
                  assessmentCycle: 1,
                  term: 3,
                  year: year1,
                },
                {
                  assessmentCycle: 2,
                  term: 3,
                  year: year1,
                },
                {
                  assessmentCycle: 1,
                  term: 4,
                  year: year1,
                },
              ],
            },
          ],
          selection: [
            {
              key: "id",
              table: "school",
              value: school,
            },
            {
              key: "code",
              table: "programme",
              value: programme,
            },
            {
              key: "id",
              table: "class_group",
              value: classId,
            },
            {
              key: "id",
              table: "student",
              value: student,
            },
            {
              key: "id",
              table: "domain",
              value: domain,
            },
            {
              key: "tlo_code",
              table: "terminal_learning_objective",
              value: tlo,
            },
            {
              key: "elo_code",
              table: "enabling_learning_objective",
              value: selectedElo,
            },
            {
              key: "programme_outcome",
              table: "elo_programme_outcome",
              value: programmeOutcome,
            },
            {
              key: "learning_support_need",
              table: "student",
              value: learning,
            },
            {
              key: "behaviour_support_need",
              table: "student",
              value: behaviour,
            },
            {
              key: "additional_support_need",
              table: "student",
              value: supportNeed,
            },
          ],
          ordering: checkList,
          filter: [
            {
              key: "core steps",
              operator: "<=",
              table: "core steps",
              value: coreSteps1,
            },
            {
              key: "core steps",
              operator: ">=",
              table: "core steps",
              value: coreSteps2,
            },
            {
              key: "attainment",
              operator: "==",
              table: "attainment",
              value: attainment,
            },
            {
              key: criteria > 0 ? criteriaList[criteria - 1].name : null,
              operator: "==",
              value: criteria > 0 ? 1 : 0,
            },
          ],
        };
      } else if (reportTypeId < 3 || reportTypeId === 5) {
        apiBody = {
          reportType: [
            {
              type: reportTypeId,
              comparisons: [
                {
                  assessmentCycle: assesmentPoint1,
                  term: term1 - 1,
                  year: year1,
                },
                {
                  assessmentCycle: assesmentPoint2,
                  term: term2 - 1,
                  year: year2,
                },
              ],
            },
          ],
          selection: [
            {
              key: "id",
              table: "school",
              value: school,
            },
            {
              key: "code",
              table: "programme",
              value: programme,
            },
            {
              key: "id",
              table: "class_group",
              value: classId,
            },
            {
              key: "id",
              table: "student",
              value: student,
            },
            {
              key: "id",
              table: "domain",
              value: domain,
            },
            {
              key: "tlo_code",
              table: "terminal_learning_objective",
              value: tlo,
            },
            {
              key: "elo_code",
              table: "enabling_learning_objective",
              value: selectedElo,
            },
            {
              key: "programme_outcome",
              table: "elo_programme_outcome",
              value: programmeOutcome,
            },
            {
              key: "learning_support_need",
              table: "student",
              value: learning,
            },
            {
              key: "behaviour_support_need",
              table: "student",
              value: behaviour,
            },
            {
              key: "additional_support_need",
              table: "student",
              value: supportNeed,
            },
          ],
          ordering: checkList,
          filter: [
            {
              key: "core steps",
              operator: "<=",
              table: "core steps",
              value: coreSteps1,
            },
            {
              key: "core steps",
              operator: ">=",
              table: "core steps",
              value: coreSteps2,
            },
            {
              key: "attainment",
              operator: "==",
              table: "attainment",
              value: attainment,
            },
            {
              key: criteria > 0 ? criteriaList[criteria - 1].name : null,
              operator: "==",
              value: criteria > 0 ? 1 : 0,
            },
          ],
        };
      }

      const attains = [
        "Experienced",
        "Developing",
        "Cooperative",
        "Routinised",
        "Adaptive",
      ];

      if (attainment.length > 0) {
        const attain = _.cloneDeep(attains);
        attain.map((data) => {
          const returns = attainment.includes(data.toUpperCase());
          const index = attains.findIndex((el) => el === data);
          if (!returns) {
            attains.splice(index, 1);
          }
        });
      }

      this.setAttainment(attains);

      const response = await this.createReport(apiBody);
      if (response) {
        this.setReportType(reportTypeId);
        if (reportTypeId % 2 === 1 || reportTypeId > 4) {
          this.setSelection(sendCheckList);
        } else if (reportTypeId % 2 === 0 && reportTypeId < 5) {
          this.setSelection([...sendCheckList, "Student Name"]);
        }

        this.setDataSelection({
          reportTypeId,
          year1,
          term1,
          assesmentPoint1,
          year2,
          term2,
          assesmentPoint2,
          checkList,
          sendCheckList,
          school,
          programme,
          classId,
          student,
          domain,
          elo,
          tlo,
          programmeOutcome,
          learning,
          behaviour,
          supportNeed,
          coreSteps1,
          attainment,
          coreSteps2,
          criteria,
        });
        this.setSelectedList(this.showList);

        this.setTitle(this.reportType[this.reportTypeId - 1].name);

        if (reportTypeId < 3 || reportTypeId === 5) {
          this.$router.push({ name: "LeadershipDashboard" });
        } else if (
          (reportTypeId < 5 && reportTypeId > 2) ||
          reportTypeId === 6
        ) {
          this.$router.push({ name: "LeadershipDashboardMultiTerm" });
        }
      }
    },
  },
};
</script>
<style scoped>
select {
  width: 20vw;
  margin: 0 1vw 0 0;
}

input {
  margin: 0 1.09vw 0 0;
  width: 1.09vw;
  height: auto;
}

h5 {
  margin-left: 2.18vw;
}

#FilterBy {
  margin-top: 6vh;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 3vh 0;
}

.underline {
  width: 66.18vw;
  margin: 0 0 3vh 2.18vw;
  border-bottom: 0.3vh solid grey;
}

.selectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 68.36vw;
}

.clearSelection {
  cursor: pointer;
  user-select: none;
  color: goldenrod;
}

.clearOrder {
  cursor: pointer;
  user-select: none;
  color: goldenrod;
}

.selectCheck {
  margin: 0 1vw 0 2.18vw;
}
</style>
