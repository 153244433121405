import Vue from 'vue'

export default {
	getCurrentYear () {
		return Vue.http.get('currentYear')
	},
	getSideMenu (selectedYear) {
		return Vue.http.get('sideMenu', {
			params: {
				selectedYear: selectedYear
			}
		})
	}
}
