<template>
  <transition name="fade" @after-leave="closeModal">
    <div
      :id="params.id"
      class="reveal"
      :class="params.class"
      v-foundation:Reveal.destroy
      data-v-offset="30"
      data-multiple-opened="true"
      data-close-on-click="false"
      data-close-on-esc="false"
      v-show="show"
      v-cloak
    >
      <component
        v-if="params.component"
        :is="params.component"
        :close-self="animateLeave"
        v-bind="params.componentProps"
      >
      </component>

      <div v-else>
        <h4 class="modal-title">
          {{ params.header || $t("modal.defaultTitle") }}
        </h4>
        <p class="pre-formatted">
          {{ params.text || $t("modal.defaultText") }}
        </p>
      </div>

      <!-- Buttons -->
      <button
        type="button"
        class="close-button color--medium-gray"
        aria-label="Close Modal"
        @click="animateLeave"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div v-if="params.includeConfirm" class="grid-x align-right">
        <button
          type="submit"
          class="button primary margin-bottom-0"
          @click="confirm"
        >
          {{ params.okText || $t("modal.okText") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TheModalComponent",
  props: {
    params: {
      type: Object,
      required: true,
      props: {
        id: String,
        component: Object,
        componentProps: Object,
        header: String,
        text: String,
        includeConfirm: Boolean,
        okText: String,
        successCallback: Function,
        closeCallback: Function,
      },
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    $(this.$el).foundation("open");
  },
  destroyed() {
    this.params.closeCallback && this.params.closeCallback();
    $(this.$el).remove();
  },
  methods: {
    confirm() {
      this.params.successCallback && this.params.successCallback();
      this.animateLeave();
    },
    animateLeave() {
      this.show = false;
    },
    closeModal() {
      this.$modal.close(this.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.reveal {
  margin-bottom: $global-margin * 2;

  .pre-formatted {
    white-space: pre-wrap;
  }
}
</style>
