<template>
  <div class="grid-x margin-top-1 margin-bottom-1 align-bottom" v-cloak>
    <!-- <radio-filter :option-list="optionList" :callback="navigateToPlan" :selected-value="$route.name"></radio-filter> -->
    <radio-filter-aiep
      :option-list="optionList"
      :callback="navigateToPlan"
      :selected-value="optionList.value"
    ></radio-filter-aiep>
    <slot></slot>
    <!-- <button class="filter button gray margin-0">
			<font-awesome-icon icon="filter"></font-awesome-icon>
		</button> -->
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import RadioFilterAiep from "./RadioFilterAiep";
import moment from "moment";

export default {
  name: "StudentDetailsControlSection",
  components: {
    // FontAwesomeIcon,
    RadioFilterAiep,
  },
  data() {
    return {
      optionList: [
        {
          label: this.$i18n.t("studentDetailsControlSection.piep"),
          value: "Piep",
          year: this.$route.params.year,
          page: "Piep",
        },
      ],
    };
  },
  methods: {
    navigateToPlan({ val, year }) {
      this.$router.push({
        name: val,
        params: { studentId: this.$route.params.studentId, year },
      });
    },
  },
  created() {
    let yearGap = 2019 - this.$route.params.year;
    const currentMonth = moment().format("MM");
    const currentYear = moment().format("YYYY").toString();
    let finishGap = 0;
    if (currentYear > this.$route.params.year.toString()) {
      finishGap = 1;
    } else if (currentYear === this.$route.params.year.toString()) {
      if (currentMonth >= 9) {
        finishGap = 1;
      }
    }

    if (yearGap < -2) {
      yearGap = -2;
    }

    for (let i = yearGap; i <= finishGap; i++) {
      const year = Number(this.$route.params.year) + i;
      this.optionList.push({
        label: this.$i18n.t("studentDetailsControlSection.aiep", { year }),
        value: i === 0 ? "Aiep" : `Aiep${i + 2}`,
        year,
        page: i === 0 ? "Aiep" : `Aiep${i + 2}`,
      });
    }
    // set default
    this.optionList.value = "Aiep";
  },
};
</script>

<style lang="scss" scoped>
.filter {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>
