<template>
  <div v-cloak>
    <div class="grid-x align-right year-selection">
      <div class="cell shrink">
        <button class="button clear" @click.prevent="page--">
          <font-awesome-icon icon="chevron-left"></font-awesome-icon>
        </button>
      </div>
      <div class="cell shrink">
        <button
          class="button clear"
          @click.prevent="
            page = 0;
            goToYear(currentYear);
          "
        >
          <font-awesome-icon :icon="['far', 'calendar']"></font-awesome-icon>
        </button>
      </div>
      <div class="cell shrink">
        <button class="button clear" @click.prevent="page++">
          <font-awesome-icon icon="chevron-right"></font-awesome-icon>
        </button>
      </div>
    </div>

    <ul class="year-wrapper grid-x grid-padding-x align-right">
      <li v-for="year in years" :key="year" class="cell shrink">
        <button
          class="button clear"
          :class="selectedYearFilter === year ? 'selected' : ''"
          @click.prevent="goToYear(year)"
        >
          {{ year }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import constants from "@/constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "TheSidebarYear",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      page: 0,
      itemsToShow: 3,
    };
  },
  computed: {
    ...mapState({
      hierarchy: (state) => state.sideMenu.hierarchy,
      selectedYearFilter: (state) => state.sideMenu.selectedYearFilter,
    }),
    ...mapGetters({
      currentYear: "currentYear",
      programmes: "programmes",
      filteredClasses: "filteredClasses",
    }),
    years: function () {
      let indexYear = this.currentYear + this.page * this.itemsToShow;
      return [indexYear - 1, indexYear, indexYear + 1];
    },
  },
  mounted() {
    if (this.currentYear !== this.selectedYearFilter) {
      this.page = Math.round((this.selectedYearFilter - this.currentYear) / 3);
    }
  },
  methods: {
    goToYear(year) {
      this.setSelectedYearFilter(year);
      this.getSideMenu(year).then(() => {
        this.pushToDashboardByHierarchy(this.hierarchy[0]);
      });
    },
    ...mapActions(["setSelectedYearFilter", "getSideMenu"]),
    pushToDashboardByHierarchy(topHierarchy) {
      if (topHierarchy === constants.HIERARCHY.PROGRAMMES) {
        let programmeId = this.programmes
          ? this.programmes.length > 0 && this.programmes[0].id
          : false;
      
        this.$router.push({
          name: "Programme",
          params: {
            year: this.selectedYearFilter,
            programmeId: programmeId,
          },
        });
      } else {
        let classId =
          this.filteredClasses.length > 0 && this.filteredClasses[0].id;
        this.$router.push({
          name: "Dashboard",
          params: {
            year: this.selectedYearFilter,
            classId: classId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.year-wrapper {
  margin-top: $global-margin;
  margin-bottom: $global-margin / 2;
  color: inherit;
  font-size: 0.85em;

  .button {
    @extend .sans-serif-regular;
    opacity: 0.5;
  }

  .button.selected {
    @extend .sans-serif-bold;
    padding-bottom: $global-padding / 2.5;
    border-radius: 0;
    border-bottom: 1px solid $primary-color;
    color: inherit;
    opacity: 1;
  }
}
li {
  list-style-type: none;

  button {
    font-size: 1em;
  }
}
.button.clear {
  border: none;
  color: inherit;
}
.year-selection {
  button {
    margin-right: $global-margin;
    opacity: 0.5;
    font-size: $small-font-size / 1.1;
  }
}
</style>
