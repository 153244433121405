import Vue from 'vue'

export default {
	createLessonPlan (classGroupId, planTerm) {
		return Vue.http.post('lessonPlan', {
			classGroupId: classGroupId,
			planTerm: planTerm
		})
	},
	updateLessonPlan (lessonId, lessonPlan) {
		return Vue.http.put('lessonPlan/' + lessonId, lessonPlan)
	},
	getLessonPlan (lessonId) {
		return Vue.http.get('lessonPlan/' + lessonId)
	},
	getLessonPlans (classGroupId, planYear, planTerm) {
		return Vue.http.get('lessonPlan', {
			params: {
				classGroupId: classGroupId,
				planYear: planYear,
				planTerm: planTerm
			}
		})
	},
	deleteLessonPlan (lessonId) {
		return Vue.http.delete('lessonPlan/' + lessonId)
	},
	getLessonPlanElos (lessonId) {
		return Vue.http.get('lessonPlanElo/' + lessonId)
	},
	getStudentElosForTerm (classGroupId, planTerm) {
		return Vue.http.get('lessonPlanElo', {
			params: {
				classGroupId: classGroupId,
				planTerm: planTerm
			}
			// },
			// timeout: 119000
		})
	},
	addElosToLessonPlan (lessonId, eloCodes) {
		return Vue.http.put('lessonPlanElo/' + lessonId, {
			eloCodes: eloCodes
		})
	},
	removeEloFromLessonPlan (lessonId, eloCode) {
		return Vue.http.delete('lessonPlanElo/' + lessonId, {
			// body param is required for delete http calls
			body: {
				eloCode: eloCode
			}
		})
	}
}
