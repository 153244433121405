<template>
  <div v-if="hasElos" class="tlo-list-card" v-cloak>
    <div class="tlo-header card-divider grid-y">
      <div>
        <span class="tlo-code grid-x cell"
          >{{ $t("tloListCard.tlo") }}: {{ tlo.tloCode }}
        </span>
        <p class="tlo-text grid-x cell auto margin-bottom-0">
          <small>{{ tlo.tloName }}</small>
        </p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TloListCard",
  props: {
    tlo: {
      type: Object,
    },
    retainElos: {
      type: Function,
    },
  },
  computed: {
    hasElos: function () {
      return (
        this.retainElos(this.tlo.elos) &&
        this.retainElos(this.tlo.elos).length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tlo-list-card.card {
  border-radius: 0;
  border: 0;
}
.tlo-header {
  background-color: $lighter-gray;
  border-bottom: 1px solid $light-gray;
}
.tlo-text {
  color: $darker-gray;
}
.tlo-code {
  color: $dark-gray;
  font-size: 10px;
}
</style>

