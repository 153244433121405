<template>
  <div>
    <div class="cell small-12 grid-x text-right margin-bottom-1">
      <div class="cell auto no-print">
        <button class="button clear" @click="assignTeachers()">
          <font-awesome-icon icon="glasses" size="lg" />
          &nbsp;<b>{{
            classGroup.coachingEngagement ? $t("classGroupEdit.assign.teachers.coaching", { title: classGroup.name }) : $t("classGroupEdit.assign.teachers", { title: classGroup.name })
          }}</b>
        </button>
      </div>
    </div>
    <class-group-edit-list
      v-if="classGroup && classGroup.teachers"
      :list="classGroup.teachers"
      :remove-function="showDeleteConfirmation"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ClassGroupEditList from "./ClassGroupEditList";
import { mapActions } from "vuex";

export default {
  name: "ClassGroupEditTeachers",
  components: {
    FontAwesomeIcon,
    ClassGroupEditList,
  },
  props: {
    classGroup: {
      type: Object,
      required: true,
    },
  },
  methods: {
    assignTeachers() {
      $("#class-group-edit-side-tray").foundation("open");
    },
    refreshList() {
      this.$emit("refresh-list");
    },
    ...mapActions(["removeTeacherFromClassGroup"]),
    showDeleteConfirmation(teacherId, displayName) {
      const teacherDesignation = this.getTeacherDesignation(teacherId);

      let executeDelete = () => {
        this.removeTeacherFromClassGroupCallback(
          teacherDesignation,
          teacherId,
          displayName
        );
      };

      this.$modal.show({
        header: this.$t("classGroupEdit.listCard.teacher.remove.modalTitle", {
          teacherDesignation,
          className: this.classGroup.name,
        }),
        text: this.$t("classGroupEdit.listCard.teacher.remove.modalText", {
          teacherDesignation,
          teacherName: displayName,
          className: this.classGroup.name,
          year: this.classGroup.year,
        }),
        includeConfirm: true,
        successCallback: executeDelete,
      });
    },
    removeTeacherFromClassGroupCallback(
      teacherDesignation,
      teacherId,
      displayName
    ) {
      // execute removal
      this.removeTeacherFromClassGroup({
        classId: this.classGroup.id,
        teacherId: teacherId,
      }).then(() => {
        this.$toastr.success(
          this.$t("classGroupEdit.teacher.success.removed", {
            teacherDesignation,
            teacherName: displayName,
            className: this.classGroup.name,
          })
        );
        this.$modal.closeAll();
        this.refreshList();
      });
    },
    getTeacherDesignation(teacherId) {
      const { teachers } = this.classGroup;
      const teacher = teachers.find((teacher) => {
        return teacherId === teacher.id;
      });

      return teacher ? teacher.designation : undefined;
    },
  },
};
</script>
