<template>
	<div class="analysis-card grid-x">
      <div class="analysis-item small-11 medium-11 grid-x align-middle">
          <div v-if="readOnly" class="cell grid-x align-middle" v-cloak>
            <div class="cell small-12 medium-auto">
               <p
              :style="`${show ? '' : 'text-overflow: ellipsis; overflow:hidden; white-space:nowrap;'} ${focus ? 'background-color: #FFFF00;' : ''}`"
              class="read-only"
              :id="`text-collapsable-${index}`">{{eloCode}}
              <span class="count">{{order}}</span>{{taskDescription}}
            </p>
            </div>
          </div>
		  <div v-if="!readOnly">
			<textarea v-if="!readOnly" ref="textarea" rows="2" placeholder="Description" v-model="taskDescription" :key="id" class="cell editable"></textarea>        		
			<label>Item Category</label>
			<select v-if="!readOnly" v-model="itemcategory">	
			<option selected value=""  v-if="category==='HIGHER'">{{category}}</option>	
			<option selected value=""  v-if="category==='OTHER'">{{category}}</option>	
			<option selected value=""  v-if="category==='SUPPLEMENTARY'">{{category}}</option>
			<option selected value=""  v-if="category==='CORE'">{{category}}</option>
				<!--
				<option v-for="item in taskItemCategoryList" :key="item.label" :value="item.value" v-if="category!=item.label">{{ item.label }}</option>
				-->
				<option
					v-for="item in filteredTaskItemCategoryList"
					:key="item.label"
					:value="item.value"
				>
					{{ item.label }}
				</option>
			</select>
			<label>Order Number{{order}}</label>
			 <input v-if="order!=''"
            type="text"
            v-bind:name="orderNumber"
            v-model="orderNumber"
          />
			<label>Status Active</label>
			<input
                  class="checkbox"
                  type="checkbox"
                  name="taskItemStatus"
                  v-model="formData.taskItemStatus"
                  @click="toggleTaskItemStatus"
                  :checked="!formData.taskItemStatus"
                />
				
		  </div>
        <div v-if="!readOnly" class="cell grid-x edit-buttons text-center align-middle">
          <button class="button primary small hollow" @click="addOrUpdateItem">
            {{ $t('editEloDetails.analysis.done') }}
          </button>
          <button class="button secondary small hollow" @click="cancel">
            {{ $t('editEloDetails.analysis.cancel') }}
          </button>
        </div>
      </div>
      <div v-if="readOnly" class="small-1 prompt-assessment border-left text-center align-center grid-x align-top">
			<button class="cell auto small-12 medium-6 button clear color--medium-gray" @click="editItem">
				<font-awesome-icon  :icon="['far','edit']" size="1x"></font-awesome-icon>
			</button>
			<button class="cell auto small-12 medium-6 button clear color--medium-gray" @click="deleteItemConfirm">
				<font-awesome-icon :icon="['far','trash-alt']" size="1x"></font-awesome-icon>
			</button>
		</div>
	</div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'TaskItemAddModalCard',
  components: {
    FontAwesomeIcon,
  },
  props: {
    taskDescription: String,
    index: Number,
    focus: Boolean,
    readonly: Boolean,
    order: Number,
	eloCode: String,
	id: Number,
	category: String,
	
  },
  	data () {		
		return {
			formData: {
				taskItemStatus: true,
			},
			selected: 'ddd',
			itemcategory: '',
			orderNumber: this.order,
			edit: false,
			show: false,
			showButton: false,
      		subItemDescription: "",
			weekRemarks: [],
			selectedWeek: [],
			hasWeekRemarks: [],
			modalValue: {1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false}
		}
	},
  	computed: {
		...mapGetters(["taskItemCategoryList","taskItemList"]),
        taskItemCategoryListFormatted: function () {
			const reversedList = [ ...this.taskItemCategoryList ].reverse()
			return reversedList
		},
		readOnly: function () {
			return this.readonly && !this.edit
		},
		/*
		mount () {
			if (this.studentTaskItemId) {
				this.weekRemarks = this.studentTaskItemRemarks(this.studentTaskItemId)
				if (!this.weekRemarks) {
					this.weekRemarks = []
				}
				if (this.weekRemarks) {
					const response = this.weekRemarks.map(el => el.week)
					this.hasWeekRemarks = [...new Set(response)]
					return true
				}
			}
			return false
		},
		*/
		filteredTaskItemCategoryList() {
    		return this.taskItemCategoryList.filter(item => item.label !== this.category);
  		},
	},
  methods: {
	...mapActions(["curriculumInterfaceTaskItemAdd","getTaskItemList","curriculumInterfaceTaskItemUpdate","removeItemFromTaskItem"]),
	mount () {
		if (this.studentTaskItemId) {
			this.weekRemarks = this.studentTaskItemRemarks(this.studentTaskItemId)
			if (!this.weekRemarks) {
				this.weekRemarks = []
			}
			if (this.weekRemarks) {
				const response = this.weekRemarks.map(el => el.week)
				this.hasWeekRemarks = [...new Set(response)]
				return true
			}
		}
		return false
	},
	deleteItemConfirm () {
		this.$modal.show({
			header: this.$t('editEloDetails.analysis.delete.modalTitle'),
			text: this.$t('editEloDetails.analysis.delete.modalText', { item: this.taskDescription }),
			includeConfirm: true,
			successCallback: this.deleteItem
		});		
	},
	toggleTaskItemStatus() {
      this.formData.taskItemStatus = !this.formData.taskItemStatus;
    },
    textareaFocus () {
			this.$nextTick(() => {
				this.$refs.textarea.focus()
			})
		},
    editItem () {
			this.edit = true
			this.textareaFocus()
		},
	async deleteItem () {
		let id = this.id
		await this.removeItemFromTaskItem({
			id: "TASKITEM-" + id
		});
		this.getTaskItemList({
            	eloCode: this.eloCode
        })

		this.checkWidthButton()
	},
    addOrUpdateItem () {
		let description = this.taskDescription
		let eloCode = this.eloCode
		let itemcategory = this.itemcategory
		if (itemcategory === "") {
			if (this.category === "OTHER") {
				itemcategory = 'O'
			}
			if (this.category === "SUPPLEMENTARY") {
				itemcategory = 'S'
			}
			if (this.category === "CORE") {
				itemcategory = 'C'
			}
			if (this.category === "HIGHER") {
				itemcategory = 'H'
			}
		}
		let id = this.id
		if (!this.edit) {
			this.addTaskItem(description, eloCode, itemcategory)
		} else {
			let orderNumber = this.orderNumber;
			 this.updateTaskItemNew(id, description, eloCode, itemcategory, orderNumber)
			this.edit = false
		}
	},
	addTaskItem(description, eloCode, itemcategory) {
 		 this.$store
        .dispatch("curriculumInterfaceTaskItemAdd", {tableName: "TASKITEM", taskDescription: description,eloCode: eloCode, itemcategory: itemcategory})
        .then((resp) => {
          if (resp) {
			  this.getTaskItemList({
            	eloCode: this.eloCode
        		})
          }
        })
        .catch((err) => {
          this.$toastr.error(err.data && err.data.message);
        }); 
	},
	updateTaskItemNew(id, description, eloCode, itemcategory, orderNumber) {
 		 this.$store
        .dispatch("curriculumInterfaceTaskItemUpdate", {id: "TASKITEM-" + id, taskDescription: description,eloCode: eloCode, itemcategory: itemcategory, orderNumber: orderNumber})
        .then((resp) => {
          if (resp) {
			  this.getTaskItemList({
            	eloCode: this.eloCode
        		})
          }
        })
        .catch((err) => {
          this.$toastr.error(err.data && err.data.message);
        }); 
	},
	updateTaskItem (id, description, eloCode, itemcategory) {
		this.curriculumInterfaceTaskItemUpdate({
			id: "TASKITEM-" + id,
			taskDescription: description,
			eloCode: eloCode,
			itemcategory: itemcategory
		}).then(() => {
			this.$emit('delete-row')
			this.checkWidthButton()
		})
	},
    cancel () {
			if (!this.edit) {
				this.$emit('delete-row')
			} else {
				this.edit = false
			}
			// this.taskDescription = this.taskDescription

			this.checkWidthButton()
		},
    		checkWidthButton () {
			const element = document.getElementById(`text-collapsable-${this.index}`)
			if (element !== null) {
				this.showButton = element.offsetWidth < element.scrollWidth
			} else {
				this.showButton = false
			}
		}
  	},
  mounted() {
  	this.mount();
  },
}
</script>

<style lang="scss" scoped>
.remark {
	word-break: break-word;
}
.analysis-card {
	border-bottom: $card-border;

	.analysis-item {
		padding: $global-padding $global-padding $global-padding $global-padding / 2;
	}
}
.editable {
	margin: 0;
	resize: vertical;
}
.read-only {
	position: relative;
	padding-left: $global-padding * 1.5;
	margin-bottom: 0;
	white-space: pre-wrap;
	word-break: break-word;
	line-height: 1.4em;
}
.count {
	@extend .sans-serif-bold;
	position: absolute;
	top: 0;
	left: 0;
}
.border-left {
	border-left: $card-border;
}
.prompt-assessment {
	padding-top: $global-padding / 1.5;
	padding-bottom: $global-padding / 1.5;
}
.assessment {
	&.end-cycle {
		background: $bg-special;
	}
	&:hover {
		background: $bg-selected;
		cursor: pointer;
	}
}
.edit-buttons {
	button {
		margin-top: $global-margin / 2;
		margin-bottom: 0;
		padding: $global-padding / 3 $global-padding / 2;

		&:first-child {
			margin-right: $global-margin / 2;
		}
	}
}
.action-column {
	margin-left: $global-margin / 2;
}
.category-dropdown {
	margin-bottom: $global-margin / 3;

	.category-dropdown-control {
		.category-dropdown-label {
			@extend .small-selector-radio;
			@extend .tiny-form-field;
			cursor: pointer;

			&:hover {
				background: $bg-selected;
			}
		}
	}
	.category-dropdown-content {
		@extend .small-text
	}
}
.drag-handle {
	margin-left: $global-margin / 2;
	cursor: pointer;
}
</style>

