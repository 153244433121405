<template>
  <div class="grid-x" v-cloak>
    <div class="report-item cell medium-10">
      <div class="report-header grid-x align-middle">
        <div class="cell auto h-pos-relative">
          <h6 class="color--white margin-bottom-0">
            {{ $t("iar.report.heading") }}
          </h6>
        </div>
      </div>
      <div class="report-body grid-x">
        <div class="cell auto">
          <select
            id="student"
            v-model="selectedStudentId"
            class="select-student"
          >
            <option :key="0" :value="0">
              {{ $t("report.select.student") }}
            </option>
            <option
              v-for="student in studentList"
              :key="student.id"
              :value="student.id"
            >
              {{ student.fullName }}
            </option>
          </select>
        </div>
        <div class="actions cell margin-left-1 shrink">
          <button
            class="primary button margin-bottom-0"
            @click="getIarPdf()"
            :disabled="selectedStudentId === 0"
          >
            <font-awesome-icon
              class="action-item"
              :icon="['far', 'file-pdf']"
              size="1x"
            ></font-awesome-icon>
            {{ $t("report.download.pdf") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import fileService from "@/mixins/fileService";

export default {
  name: "IarReport",
  components: {
    FontAwesomeIcon,
  },
  mixins: [fileService],
  props: {
    studentList: Array,
  },
  data() {
    return {
      selectedStudentId: 0,
    };
  },
  methods: {
    getIarPdf() {
      let classId = this.$route.params.classId;
      if (classId && this.selectedStudentId) {
        this.$store
          .dispatch("getStudentIarReport", {
            classId: classId,
            studentId: this.selectedStudentId,
          })
          .then(
            (resp) => {
              let fileName =
                "IAR-" + this.getStudentName(this.selectedStudentId) + ".pdf";
              this.downloadFile(fileName, resp);
            },
            () => {
              this.$toastr.error(this.$t("error.notFound"));
            }
          );
      } else {
        console.log("No class OR Student selected.");
      }
    },
    getStudentName(selectedStudentId) {
      let student = this.studentList.find((it) => it.id === selectedStudentId);
      return student ? student.fullName.replace(/[\s+|\u00a0]+/g, "_") : null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
