<template>
  <div class="user-modal" v-cloak>
    <h2 style="text-align: left">Add Domain</h2>
    <form @submit.prevent="submit">
      <div class="class-group-modal-content grid-x content-wrapper">
        <div class="card">
          <label>Domain Name</label>
          <input
            type="text"
            required
            v-bind:name="domainName"
            v-model="formData.domainName"
          />
          <label>Domain Code</label>
          <input
            type="text"
            required
            v-bind:name="domainId"
            v-model="formData.domainId"
          />
          <input
            type="submit"
            class="cell button shrink primary hollow margin-bottom-0"
            value="Add"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants";
export default {
  name: "CurriculumInterfaceDomainEditModal",
  /* eslint-disable vue/no-unused-components */
  components: {
    constants,
  },
  /* eslint-enable vue/no-unused-components */
  props: {
    id: String,
    domainName: String,
    domainId: String,
  },
  data() {
    return {
      formData: {
        id: "DOMAIN",
        domainName: "",
        domainId: "",
      },
    };
  },
  computed: {
    ...mapGetters(["curriculum", "taskItems"]),
  },
  methods: {
    ...mapActions(["getCurriculum", "forceGetCurriculum"]),
    submit() {
      const currciulumInterface = this.formData.domainName;
      this.$store
        .dispatch("curriculumInterfaceDomainAdd", this.formData)
        .then((resp) => {
          if (resp) {
            this.$store.dispatch("curriculumTimestampReset");
            this.$modal.closeAll();
            this.forceGetCurriculum();
            this.$toastr.success(
              this.$t("currciulumInterfaceModal.success.created", {
                currciulumInterface,
              })
            );
          }
        })
        .catch((err) => {
          this.$toastr.error(err.data && err.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-selector {
  padding-top: $global-padding * 1.5;
}
.cell-padding-top {
  padding-top: $global-padding;
}
.card {
  margin: auto;
  text-align: left;
  font-family: arial;
  border: none;
}
.card dmonainName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #73894e;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.card subdmonainName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #dce1d3;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.card tloName {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #efe;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
</style>