<template>
  <div class="profile-info-wrapper" v-cloak>
    <div v-if="profile" class="grid-x align-middle overflow-hidden">
      <profile-image
        :image="profile.image"
        :image-alt="profile.displayName"
        :image-size="60"
        :image-title="profile.fullName"
      ></profile-image>
      <div class="profile-details cell auto">
        <span class="h4">{{ profile.fullName }}</span>
        <p class="margin-0 color--darker-gray">
          {{ profile.age }} {{ $t("details.yearOld") }},
          {{ $t("gender." + profile.gender) }},
          <span v-if="studentPlan && studentPlan.programme">
            {{ studentPlan.programme }} -
          </span>
          {{ classGroup && classGroup.name }}
        </p>
        <condition-type-badge
          class="condition-type-badge"
          :conditionTypes="profile.conditionTypes"
        ></condition-type-badge>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImage from "./ProfileImage";
import ConditionTypeBadge from "./ConditionTypeBadge";

export default {
  name: "StudentProfile",
  components: {
    ProfileImage,
    ConditionTypeBadge,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    classGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    studentPlan: function () {
      return this.$store.getters.studentPlan[this.profile.id];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-info-wrapper {
  margin-bottom: $global-margin;

  @include breakpoint(medium) {
    margin-bottom: $global-margin;
  }
}
.profile-details {
  margin-left: $global-margin * 1.5;
}
.condition-type-badge {
  margin-top: $global-margin / 2;
}
</style>
