import * as types from '../mutation-types';
import { defaultLang, i18n } from '@/utils/i18n';
import i18nApi from '@/api/i18n';

// initial state
const state = {
	lang: [defaultLang]
};

// actions
const actions = {
	loadLanguage({ state, commit }, lang) {
		if (i18n.locale !== lang && !state.lang.includes(lang)) {
			i18nApi.loadLanguageAsync(lang).then(msgs => {
				i18n.setLocaleMessage(lang, msgs);
				i18n.locale = lang;
				commit(types.ADD_LANGUAGE, lang);
			});
		}
	}
};

// mutations
const mutations = {
	[types.ADD_LANGUAGE](state, lang) {
		state.lang.push(lang);
	}
};

export default {
	state,
	actions,
	mutations
};
