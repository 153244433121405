<template>
  <div id="sidebarAccordionMenu" class="vertical menu accordion-menu" v-cloak>
    <the-sidebar-menu-nested :list="sideMenu" :hierarchy="hierarchy" :level="0">
    </the-sidebar-menu-nested>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheSidebarMenuNested from "./TheSidebarMenuNested";

export default {
  name: "TheSidebarMenu",
  components: {
    TheSidebarMenuNested,
  },
  computed: {
    ...mapGetters({
      sideMenu: "sideMenu",
      hierarchy: "hierarchy",
    }),
  },
};
</script>

<style lang="scss" scoped>
.accordion-menu {
  height: 100%;
  overflow-y: auto;
  padding-left: $global-padding / 2;
  padding-right: $global-padding / 2;
}
</style>
