<template>
  <div v-cloak>
    <lesson-plan-list-card
      v-for="lesson in lessonPlans"
      :key="lesson.id"
      :lesson="lesson"
    ></lesson-plan-list-card>

    <div
      class="empty-plan-list"
      v-if="!lessonPlans || lessonPlans.length === 0"
    >
      <p class="empty-message">
        {{ $t("lessonPlanList.noLessonPlan") }}<br />{{
          $t("lessonPlanList.addLessonPlan")
        }}
      </p>
    </div>
  </div>
</template>

<script>
import LessonPlanListCard from "./LessonPlanListCard";

export default {
  name: "LessonPlanList",
  components: {
    LessonPlanListCard,
  },
  props: ["classId", "year", "term"],
  computed: {
    lessonPlans: function () {
      return this.$store.getters.lessonPlans(this.classId);
    },
  },
  watch: {
    term: function (val) {
      this.getLessonPlans(val);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getLessonPlans(to.params.classId, to.params.year, to.params.term);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getLessonPlans(to.params.classId, to.params.year, to.params.term);
    next();
  },
  methods: {
    getLessonPlans(classId, year, term) {
      if (!classId || !year || !term) return;
      this.$store.dispatch("getLessonPlans", {
        classGroupId: classId,
        planYear: year,
        planTerm: term,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-plan-list {
  margin-top: $global-margin * 4;
}
</style>
