<template>
  <div v-cloak>
    <class-group-profile :class-group="classGroup"></class-group-profile>
    <div class="grid-x margin-bottom-1 align-middle">
      <span class="cell shrink">{{ $t("master.term") }}:&nbsp;&nbsp;</span>
      <div class="cell shrink">
        <radio-filter
          :option-list="optionTermList"
          :callback="navigateTerm"
          :selected-value="this.term"
        ></radio-filter>
      </div>
      <div class="cell auto grid-x align-right">
        <print-button></print-button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClassGroupProfile from "@/views/ClassGroup/ClassGroupProfile";
import RadioFilter from "./RadioFilter";
import PrintButton from "./PrintButton";
import termHandler from "@/mixins/termHandler";

export default {
  name: "Master",
  components: {
    ClassGroupProfile,
    RadioFilter,
    PrintButton,
  },
  mixins: [termHandler],
  props: ["term"],
  computed: {
    ...mapGetters({
      classGroup: "classGroup",
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>
