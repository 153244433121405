<template>
  <div class="container-wrapper" v-cloak>
	<input style="display: none;" type="file" id="uploadFile" name="uploadFile" accept=".csv" @change="getFile">
    <div class="grid-x align-middle align-justify">
      <h1 class="page-title">{{ $t("currciulum.title") }}</h1>
	  <div>
        <button class="button clear" v-if="downloadButton !== ''" @click="startDownload">
          <b>Download {{ $t(`currciulum.create.${downloadButton}`) }}</b>
        </button>
        <button class="button clear" v-if="createButton !== ''" @click="triggerInput">
          <font-awesome-icon icon="plus"></font-awesome-icon>
          &nbsp;<b>Create {{ $t(createButton) }}</b>
        </button>
	  </div>
    </div>

	<div class="grid-x align-middle align-justify select-wrapper">
		<select name="domain" v-model="selectedDomain" class="half left">
			<option value="default">Please Select Any Domain</option>
			<option v-for="domain in curriculum.domains" :key="`domain-select-${domain.domainId}`" :value="domain.domainId">
				{{ domain.domainName }}
			</option>
		</select>
		<select name="subDomain" v-model="selectedSubDomain" class="half right">
			<option value="default">Please Select Any Sub-Domain</option>
			<option v-for="subDomain in subDomains" :key="`subDomain-select-${subDomain.subDomainId}`" :value="subDomain.subDomainId">
				{{ subDomain.subDomainName }}
			</option>
		</select>
	</div>

	<div class="grid-x align-middle align-justify select-wrapper">
		<select name="tlo" v-model="selectedTLO" class="half left">
			<option value="default">Please Select Any TLO</option>
			<option v-for="tlo in tlos" :key="`TLO-select-${tlo.tloId}`" :value="tlo.tloId">
				{{ tlo.tloName }}
			</option>
		</select>
		<select name="elo" v-model="selectedELO" class="half right">
			<option value="default">Please Select Any ELO</option>
			<option v-for="elo in elos" :key="`ELO-select-${elo.eloId}`" :value="elo.eloId">
				{{ elo.eloName }}
			</option>
		</select>
	</div>

    <section>
      <div v-for="detail in showDetails" class="card" :key="`domain-${detail.id}`">
        <div class="card-section card-padding">
          <h5>{{ detail.name }}</h5>
          <p class="color--brand-primary">
            {{ $t("currciulum.code", { code: detail.id }) }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MoreDropdown from "@/components/MoreDropdown";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import fileService from '@/mixins/fileService'
import constants from '@/constants'
// import SchoolEditor from "../Schools/SchoolEditor.vue";

export default {
	name: "Curriculum",
	/* eslint-disable vue/no-unused-components */
	components: {
		MoreDropdown,
		FontAwesomeIcon,
	},
	/* eslint-enable vue/no-unused-components */
	data() {
		return {
			selectedDomain: 'default',
			selectedSubDomain: 'default',
			selectedTLO: 'default',
			selectedELO: 'default',
			selectedTaskItem: 'default'
		}
	},
	computed: {
		...mapGetters(["curriculum", "taskItems"]),
		domainIndex() {
			return this.selectedDomain === 'default' ? -1 : this.filterArrayKey(this.curriculum.domains, this.selectedDomain)
		},
		subDomains() {
			return this.domainIndex === -1 ? [] : this.curriculum.domainDetails[this.domainIndex]
		},
		subDomainIndex() {
			return this.selectedSubDomain === 'default' ? -1 : this.subDomains.findIndex(el => el.subDomainId === this.selectedSubDomain)
		},
		tlos() {
			return this.subDomainIndex === -1 ? [] : this.subDomains[this.subDomainIndex].tlos
		},
		tloIndex() {
			return this.selectedTLO === 'default' ? -1 : this.tlos.findIndex(el => el.tloId === this.selectedTLO)
		},
		elos() {
			return this.tloIndex === -1 ? [] : this.tlos[this.tloIndex].elos
		},
		eloIndex() {
			return this.selectedELO === 'default' ? -1 : this.elos.findIndex(el => el.eloId === this.selectedELO)
		},
		showDetails() {
			let response = []
			if (this.domainIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = this.curriculum.domains.map(el => ({ id: el.domainId, name: el.domainName }))
			} else if (this.subDomainIndex === -1 && Object.keys(this.curriculum).length > 0 && this.curriculum.domainDetails[this.domainIndex]) {
				response = this.curriculum.domainDetails[this.domainIndex].map(el => ({ id: el.subDomainId, name: el.subDomainName }))
			} else if (this.tloIndex === -1 && Object.keys(this.curriculum).length > 0 && this.subDomains) {
				response = this.subDomains[this.subDomainIndex].tlos.map(el => ({ id: el.tloId, name: el.tloName }))
			} else if (this.eloIndex === -1 && Object.keys(this.curriculum).length > 0 && this.tlos.length > 0) {
				response = this.tlos[this.tloIndex].elos.map(el => ({ id: el.eloId, name: el.eloName }))
			} else if (Object.keys(this.taskItems).length > 0) {
				response = this.taskItems.map(el => ({ id: el.id, name: el.description }))
			}

			return response
		},
		createButton() {
			let response = ""
			if (this.domainIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "currciulum.create.domain"
			} else if (this.subDomainIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "currciulum.create.subdomain"
			} else if (this.tloIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "currciulum.create.TLO"
			} else if (this.eloIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "currciulum.create.ELO"
			} else if (this.selectedTaskItem === 'default') {
				response = "currciulum.create.taskitem"
			}

			return response
		},
		downloadButton() {
			let response = ""
			if (this.domainIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "domain"
			} else if (this.subDomainIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "subdomain"
			} else if (this.tloIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "TLO"
			} else if (this.eloIndex === -1 && Object.keys(this.curriculum).length > 0) {
				response = "ELO"
			} else if (this.selectedTaskItem === 'default') {
				response = "taskitem"
			}

			return response
		}
	},
	mixins: [
		fileService
	],
	watch: {
		selectedDomain() {
			this.selectedSubDomain	= 'default'
		},
		selectedSubDomain() {
			this.selectedTLO		= 'default'
		},
		selectedTLO() {
			this.selectedELO		= 'default'
		},
		selectedELO() {
			const { domainIndex, subDomainIndex, tloIndex, eloIndex, selectedELO } = this
			this.selectedTaskItem	= 'default'
			if (selectedELO !== 'default') {
				const eloCode = this.elos[this.eloIndex].eloCode
				this.getTaskItem({ domainIndex, subDomainIndex, tloIndex, eloIndex, eloCode })
			}
		}
	},	
	methods: {
		...mapActions([
			"getCurriculum", "forceGetCurriculum",
			"uploadDomain", "uploadSubDomain", "uploadTLO", "uploadELO", "uploadTaskItem",
			"getTaskItem", "downloadCsv"
		]),
		async startDownload() {
			const response = await this.downloadCsv(this.downloadButton)
			this.downloadFile(`${this.downloadButton}.csv`, response)
		},
		getTargetSchoolFromElement(e) {
			const domainId = e.target.parentElement.id.split("-").at("-1");
			return this.domains[domainId];
		},
		triggerInput() {
			document.getElementById("uploadFile").click();
		},
		filterArrayKey(arraydetails, selectedDomain) {
			const filter = arraydetails.filter(function(e) {
				if (e.domainId === selectedDomain) {
					return e.id		
				}
			})
			return filter[0].id;			
		},
		async getFile(e) {
			const { createButton } = this
			const body = new FormData();
			body.append('file', e.target.files[0])

			let response
			let error
			switch (createButton) {
			case 'currciulum.create.domain':
				try {
					response = await this.uploadDomain(body)
				} catch (e) {
					error = e.body
					response = false
				}
				break
			case 'currciulum.create.subdomain':
				try {
					response = await this.uploadSubDomain(body)
				} catch (e) {
					error = e.body
					response = false
				}
				break
			case 'currciulum.create.TLO':
				try {
					response = await this.uploadTLO(body)
					this.$store.dispatch('curriculumTimestampReset');
				} catch (e) {
					error = e.body
					response = false
				}
				break
			case 'currciulum.create.ELO':
				try {
					response = await this.uploadELO(body)
					this.$store.dispatch('curriculumTimestampReset');
				} catch (e) {
					error = e.body
					response = false
				}
				break
			case 'currciulum.create.taskitem':
				try {
					response = await this.uploadTaskItem(body)
					const { domainIndex, subDomainIndex, tloIndex, eloIndex } = this
					const eloCode = this.elos[this.eloIndex].eloCode
					this.getTaskItem({ domainIndex, subDomainIndex, tloIndex, eloIndex, eloCode })
				} catch (e) {
					error = e.body
					response = false
				}
				break
			default :
				response = false
			}
			
			if (response.status === 'CREATED' || response.status === constants.RESPONSE_STATUS.OK) {
				this.$toastr.success(`Changes for ${this.$t(createButton)} has been saved`)
				console.log("get")
				this.forceGetCurriculum()
			} else if (error.error) {
				this.$toastr.error(this.$t(error.message))
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
}

.select-wrapper {
	margin: 0 0 0.45rem 0;

	.half {
		width: 49.5%;
	}
}
</style>