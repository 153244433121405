<template>
  <div v-if="editable" v-cloak>
    <div class="grid-x">
      <term-filter class="cell medium-2" v-model="planTerm"></term-filter>
    </div>

    <div class="control-section grid-x align-middle">
      <div class="cell small-12 medium-auto">
        <button class="button clear" @click="toAddItem()">
          <font-awesome-icon icon="plus"></font-awesome-icon>
          {{ $t("editEloDetails.analysis.addAnalysis") }}
        </button>
      </div>
      <div class="cell small-12 medium-shrink">
        <p
          class="update-status"
          v-if="
            taskItemList && taskItemList.updatedOn && taskItemList.updatedBy
          "
        >
          {{ $t("editEloDetails.lastUpdated.updatedOn") }}:
          {{ taskItemList.updatedOn | moment("calendar") }}
          {{ $t("editEloDetails.lastUpdated.by") }}
          {{ taskItemList.updatedBy.fullName }}
        </p>
      </div>
    </div>

    <div class="bgcolor--black color--white grid-x">
      <div class="small-5 padding-left-1 padding-right-1 grid-x align-middle">
        <h6 class="margin-bottom-0 margin-right-1">
          {{ $t("editEloDetails.analysis.table.header") }}
        </h6>

        <button class="button clear" @click="toggleSort">
          <font-awesome-icon
            v-if="reverseSort"
            icon="sort-up"
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="!reverseSort"
            icon="sort-down"
          ></font-awesome-icon>
        </button>

        <span class="cell auto"></span>

        <button class="button clear" @click="lunchCloneModal">
          <font-awesome-icon icon="clone"></font-awesome-icon>
        </button>
      </div>

      <div class="small-6 grid-x text-center align-middle">
        <span
          class="small-2 cell-item border-left"
          v-for="week in weekRange"
          :key="week"
        >
          <small>{{ week }}</small>
        </span>
      </div>

      <div
        class="small-1 grid-x align-center align-middle cell-item border-left"
      >
        <button class="button clear" @click="toggleWeekSet">
          <font-awesome-icon
            v-if="!weekSet"
            icon="angle-double-right"
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="weekSet"
            icon="angle-double-left"
          ></font-awesome-icon>
        </button>
      </div>
    </div>

    <div
      class="grid-x align-middle text-center"
      v-if="!this.addItem && hasEmptyTaskItemList()"
    >
      <p class="cell auto padding-top-2 padding-bottom-1">
        {{ $t("editEloDetails.analysis.emptyList") }}
      </p>
    </div>

    <edit-elo-modal-analysis-card
      v-if="addItem"
      :task-description-text="newItemValue"
      :task-item-id="0"
      :elo-code="eloCode"
      :student-id="studentId"
      :selected-year="selectedYear"
      :week-range="weekRange"
      @delete-row="cancelInput"
      :readonly="false"
      :planTerm="planTerm"
    >
    </edit-elo-modal-analysis-card>

    <draggable
      v-model="reverseItemList"
      :options="draggableOptions"
      @change="itemMoved"
    >
      <edit-elo-modal-analysis-card
        v-for="(taskItem, index) in reverseItemList"
        :key="taskItem.id"
        :task-description-text="taskItem.taskDescription"
        :student-task-item-id="taskItem.id"
        :category="taskItem.category"
        :prompt-hierarchies="taskItem.promptHierarchyList"
        :elo-code="eloCode"
        :student-id="studentId"
        :selected-year="selectedYear"
        :week-range="weekRange"
        :order="taskItem.order"
        :readonly="true"
        :focus="taskItem.focus"
        :reverse="reverseSort"
        :plan-term="planTerm"
        :has-remarks="taskItem.hasRemarks"
        :sub-item="taskItem.subItem"
        :index="index"
      >
      </edit-elo-modal-analysis-card>
    </draggable>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import draggable from "vuedraggable";
import TermFilter from "@/components/TermFilter";
import EditEloModalAnalysisCard from "./EditEloModalAnalysisCard";
import EditEloModalAnalysisClone from "./EditEloModalAnalysisClone";

export default {
  name: "EditEloModalAnalysis",
  components: {
    FontAwesomeIcon,
    draggable,
    TermFilter,
    EditEloModalAnalysisCard,
  },
  data() {
    return {
      planTerm: 0,
      reverseSort: false,
      addItem: false,
      newItemValue: "",
      weekSet: false, // false is 1..6 and true is 5..10
      draggableOptions: {
        handle: ".drag-handle",
        animation: 150,
      },
    };
  },
  props: {
    eloCode: String,
    editable: Boolean,
    studentId: String,
    selectedYear: Number,
    selectedTerm: Number,
  },
  computed: {
    ...mapGetters(["studentTaskItemListForStudentId"]),
    taskItemList: function () {
      return this.studentTaskItemListForStudentId(this.studentId) || {};
    },
    weekRange: function () {
      if (!this.weekSet) return 6;
      return _.range(5, 11);
    },
    reverseItemList: {
      get() {
        let order = 1;
        if (this.taskItemList.studentTaskItems) {
          this.taskItemList.studentTaskItems.forEach((it) => {
            it.order = order++;
          });
          return this.reverseSort
            ? this.taskItemList.studentTaskItems.slice().reverse()
            : this.taskItemList.studentTaskItems;
        }
        return [];
      },
      set(value) {
        let studentTaskItemList = this.studentTaskItemListForStudentId(
          this.studentId
        );
        studentTaskItemList.studentTaskItems = this.reverseSort
          ? value.slice().reverse()
          : value;
      },
    },
  },
  watch: {
    planTerm: function (value) {
      this.getStudentTaskItemList({
        studentId: this.studentId,
        eloCode: this.eloCode,
        planYear: this.selectedYear,
        planTerm: value,
      });
      this.getStudentAllTaskItemRemarks({
        studentId: this.studentId,
        eloCode: this.eloCode,
        planYear: this.selectedYear,
        planTerm: value,
      });
    },
  },
  created() {
    if (this.selectedTerm) this.planTerm = this.selectedTerm;

    this.getStudentTaskItemList({
      studentId: this.studentId,
      eloCode: this.eloCode,
      planYear: this.selectedYear,
      planTerm: this.planTerm,
    });
    this.getStudentAllTaskItemRemarks({
      studentId: this.studentId,
      eloCode: this.eloCode,
      planYear: this.selectedYear,
      planTerm: this.planTerm,
    });
  },
  methods: {
    ...mapActions([
      "getStudentTaskItemList",
      "updateOrderInTaskItemList",
      "cloneTaskItemListFromTerm",
      "getStudentAllTaskItemRemarks",
    ]),
    toggleSort: function () {
      this.reverseSort = !this.reverseSort;
    },
    toAddItem: function () {
      this.addItem = true;
    },
    cancelInput: function () {
      this.addItem = false;
    },
    itemMoved(obj) {
      let { oldIndex, newIndex } = obj.moved;
      let fromIndex, toIndex;
      if (this.reverseSort) {
        const length = this.taskItemList.studentTaskItems.length;
        fromIndex = length - oldIndex - 1;
        toIndex = length - newIndex - 1;
      } else {
        fromIndex = oldIndex;
        toIndex = newIndex;
      }

      this.updateOrderInTaskItemList({
        studentId: this.studentId,
        eloCode: this.eloCode,
        planYear: this.selectedYear,
        planTerm: this.planTerm,
        fromIndex,
        toIndex,
      });
    },
    hasEmptyTaskItemList: function () {
      return (
        this.taskItemList &&
        this.taskItemList.studentTaskItems &&
        this.taskItemList.studentTaskItems.length === 0
      );
    },
    toggleWeekSet: function () {
      this.weekSet = !this.weekSet;
    },
    lunchCloneModal() {
      this.$modal.show({
        component: EditEloModalAnalysisClone,
        componentProps: {
          studentId: this.studentId,
          eloCode: this.eloCode,
          planYear: this.selectedYear,
          targetTerm: this.planTerm,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-item {
  padding-top: $global-padding / 4;
  padding-bottom: $global-padding / 4;
}
.control-section {
  margin: $global-padding 0 $global-padding / 2;
}
.task-analysis {
  li {
    list-style: none;
    border-bottom: 1px solid $dark-gray;
  }
}
.update-status {
  margin-bottom: 0;
  font-size: $small-font-size;
  line-height: 1.4em;
}
.border-left {
  border-left: 1px solid $dark-gray;
}
</style>

