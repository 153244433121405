import Vue from 'vue';

export default {
	login(emailId, authToken) {
		return Vue.http.post('login', {
			email_id: emailId,
			auth_token: authToken
		});
	},
	refreshToken(token) {
		return Vue.http.post(process.env.VUE_APP_SERVER_URL.replace('/api', '') + 'oauth/access_token', null, {
			skipAuthorization: true,
			params: {
				grant_type: 'refresh_token',
				refresh_token: token
			}
		});
	}
};
