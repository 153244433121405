import Vue from 'vue'

export default {
	getStudentAiepReport (classId, studentId, noAttainment) {
		return Vue.http.get('reports/' + 'AIEP', {
			responseType: 'arraybuffer',
			params: {
				classId: classId,
				studentId: studentId,
				noAttainment: noAttainment
			}
		})
	},
	getStudentPiepReport (classId, studentId, draft) {
		return Vue.http.get('reports/' + 'PIEP', {
			responseType: 'arraybuffer',
			params: {
				classId: classId,
				studentId: studentId,
				draft: draft
			}
		})
	},
	getStudentIarReport (classId, studentId) {
		return Vue.http.get('reports/' + 'IAR', {
			responseType: 'arraybuffer',
			params: {
				classId: classId,
				studentId: studentId
			}
		})
	},
	getClassGroupReport (classId) {
		return Vue.http.get('reports/' + 'CLASSGROUP', {
			responseType: 'arraybuffer',
			params: {
				classId: classId
			}
		})
	}
}
