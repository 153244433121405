export default Object.freeze({
	RESPONSE_STATUS: {
		OK: 200,
		ERROR: 500
	},
	FOUNDATION: {
		MEDIA_QUERY: {
			SMALL: 'small',
			MEDIUM: 'medium',
			LARGE: 'large'
		}
	},
	PROFILE_STATUS: {
		START: 'START',
		DRAFT: 'DRAFT',
		OK: 'OK'
	},
	ELO_STATUS: {
		ACTIVE: 'ACTIVE',
		ATTAINED: 'ATTAINED',
		EXEMPTED: 'EXEMPTED'
	},
	HIERARCHY: {
		SCHOOLS: 'schools',
		PROGRAMMES: 'programmes',
		CLASSES: 'classes',
		STUDENTS: 'students'
	},
	GENDER: {
		M: 'M',
		F: 'F'
	},
	SALUTATION: {
		MR: 'Mr',
		MRS: 'Mrs',
		MISS: 'Miss',
		MS: 'Ms'
	},
	LEARNING_SUPPORT_NEED: {
		A: 'Low',
		B: 'Moderate',
		C: 'High'
	},
	ORGANIZATION_TYPE: {
		SCHOOL: "SCHOOL",
		EI: "EI"
	}
})
