var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.showTemplate === _vm.constants.FOUNDATION.MEDIA_QUERY.SMALL && _vm.smallAttributes
  )?_c('div',_vm._b({directives:[{name:"foundation",rawName:"v-foundation:Sticky.destroy",value:(_vm.stickyOptions),expression:"stickyOptions",arg:"Sticky",modifiers:{"destroy":true}}],key:"s"},'div',_vm.smallAttributes,false),[_vm._t("default")],2):(
    _vm.showTemplate === _vm.constants.FOUNDATION.MEDIA_QUERY.MEDIUM &&
    _vm.mediumAttributes
  )?_c('div',_vm._b({directives:[{name:"foundation",rawName:"v-foundation:Sticky.destroy",value:(_vm.stickyOptions),expression:"stickyOptions",arg:"Sticky",modifiers:{"destroy":true}}],key:"m"},'div',_vm.mediumAttributes,false),[_vm._t("default")],2):(
    _vm.showTemplate === _vm.constants.FOUNDATION.MEDIA_QUERY.LARGE && _vm.largeAttributes
  )?_c('div',_vm._b({directives:[{name:"foundation",rawName:"v-foundation:Sticky.destroy",value:(_vm.stickyOptions),expression:"stickyOptions",arg:"Sticky",modifiers:{"destroy":true}}],key:"l"},'div',_vm.largeAttributes,false),[_vm._t("default")],2):(_vm.showTemplate)?_c('div',_vm._b({directives:[{name:"foundation",rawName:"v-foundation:Sticky.destroy",value:(_vm.stickyOptions),expression:"stickyOptions",arg:"Sticky",modifiers:{"destroy":true}}],key:"default"},'div',_vm.baseAttributes,false),[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }