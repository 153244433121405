import reportsApi from '@/api/reports';

// initial state
const state = {
};

const getters = {
};

// actions
const actions = {
	getStudentAiepReport(_, { classId, studentId, noAttainment = false }) {
		return reportsApi.getStudentAiepReport(classId, studentId, noAttainment);
	},
	getStudentPiepReport(_, { classId, studentId, draft }) {
		return reportsApi.getStudentPiepReport(classId, studentId, draft);
	},
	getStudentIarReport(_, { classId, studentId }) {
		return reportsApi.getStudentIarReport(classId, studentId);
	},
	getClassGroupReport(_, { classId }) {
		return reportsApi.getClassGroupReport(classId);
	}
};

// mutations
const mutations = {
};

export default {
	state,
	getters,
	actions,
	mutations
};
