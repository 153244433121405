// coachingSession.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingSessionApi from '@/api/coachingSession'
import constants from '@/constants';

// initial state
const state = {
  // old coachingEngagement: null,
  coachingSession: {},
  coachingSessions: [],
};

// getters
const getters = {
  coachingSession: state => state.coachingSession,
  coachingSessions: state => state.coachingSessions,
};

// actions
const actions = {
  setCoachingSession({ commit }, coachingSession) {
    commit(types.SET_COACHING_SESSION, coachingSession);
  },
  setCoachingSessions({ commit }, coachingSessions) {
    commit(types.SET_COACHING_SESSIONS, coachingSessions);
  },
  setCoachingSessionOutcomeAssessments({ commit, dispatch }, { coachingSessionId, coachingOutcomeAssessments }) {
    console.log("STORE: Actions: setCoachingSessionOutcomeAssessments: ", coachingSessionId, coachingOutcomeAssessments)
    commit(types.SET_COACHING_SESSION_OUTCOME_ASSESSMENTS, { coachingSessionId, coachingOutcomeAssessments });
    dispatch('setCurrentCoachingEngagementCoachingSessionOutcomeAssessments', { coachingSessionId, coachingOutcomeAssessments });
  },
  async getCoachingSession({ commit }, id) {
    const response = await CoachingSessionApi.getCoachingSession(id)
    commit(types.SET_COACHING_SESSION, response.data)
  },
  async createCoachingSession({ commit, dispatch }, { studentCoachingEngagementId, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment }) {
    console.log("STORE: createCoachingSession - { studentCoachingEngagementId, name }:", { studentCoachingEngagementId, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment })
    const response = await CoachingSessionApi.createCoachingSession(studentCoachingEngagementId, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_SESSION, response.data.coachingSession);
      // here we need to get the studentCoachingEngagement again to have the latest info with coachingSession
      await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
    }
  },

  async updateCoachingSession({ state, commit, dispatch, rootState }, { id, studentCoachingEngagementId, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment }) {
    const response = await CoachingSessionApi.updateCoachingSession(id, startTime, endTime, online, venue, privateNotes, sharedNotes, outcomeSelfAssessmentTime, sessionAssessment)
    commit(types.UPDATE_COACHING_SESSION, response.data.coachingSession)
    // here we need to get the studentCoachingEngagement again to have the latest info with coachingSession
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },

  async deleteCoachingEngagement({ commit, dispatch }, id, studentCoachingEngagementId) {
    await CoachingSessionApi.deleteCoachingSession(id)
    commit(types.DELETE_COACHING_SESSION, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_SESSION](state, coachingSession) {
    state.coachingSession = coachingSession;
  },
  [types.SET_COACHING_SESSIONS] (state, coachingSessions) {
    state.coachingSessions = coachingSessions
  },
  [types.ADD_COACHING_SESSION] (state, coachingSession) {
    state.coachingSessions.unshift(coachingSession)
  },
  [types.UPDATE_COACHING_SESSION] (state, coachingSession) {
    Vue.set(state.coachingSession, coachingSession)
  },
  [types.SET_COACHING_SESSION_OUTCOME_ASSESSMENTS] (state, { coachingSessionId, coachingOutcomeAssessments }) {
    // (1) update the individual state.coachingSession if matching with id to have this property, or update the property if exists
    if (state.coachingSession && state.coachingSession.id && (state.coachingSession.id == coachingSessionId)) {
      Vue.set(state.coachingSession, 'outcomeAssessments', coachingOutcomeAssessments);
    }
    // (2) update the list of state.coachingSessions with matching session to have this property, or update the property if exists
    const sessionInList = state.coachingSessions.find(session => session.id == coachingSessionId);
    if (sessionInList) {
      console.log("STORE: mutation: SET_COACHING_SESSION_OUTCOME_ASSESSMENTS - state.coachingSessions.find:", coachingSessionId, coachingOutcomeAssessments)
      Vue.set(sessionInList, 'outcomeAssessments', coachingOutcomeAssessments);
    }
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
