<template>
  <div class="multiselect">
    <div class="selectBox" @click="toggleShowOptions">
      <select :disabled="disable">
        <option v-if="displaySelectedOptions == ''">{{ placeholder }}</option>
        <option v-else>{{ displaySelectedOptions }}</option>
      </select>
      <div class="overSelect"></div>
    </div>
    <template v-if="options && options.length > 0">
      <div
        v-if="showOptions"
        @mouseleave="toggleShowOptions"
        :class="`${
          isDropUp
            ? 'multi-select-checkboxes-dropup'
            : 'multi-select-checkboxes-dropdown'
        } margin-top-0`"
      >
        <label v-for="(option, index) in options" :key="index">
          <input
            :checked="isChecked(option)"
            class="margin-left-1"
            @click="addToValue(option)"
            type="checkbox"
            :disabled="
              valueModel.length === minimumSelected && isChecked(option)
            "
          />
          <p class="margin-0 option-label">{{ parseOptionLabel(option) }}</p>
        </label>
      </div>
    </template>
    <template v-else-if="showOptions">
      <div
        :class="`${
          isDropUp
            ? 'multi-select-checkboxes-dropup'
            : 'multi-select-checkboxes-dropdown'
        } margin-top-0`"
      >
        <p class="margin-left-1 margin-top-1">{{ $t("emptyOptions") }}</p>
      </div>
    </template>
  </div>
</template>
<script>
import gettersAndSetters from "@/libs/getAndSet";
import _ from "lodash";

export default {
  name: "MultiSelect",
  props: {
    value: { required: true, type: Array },
    options: {
      required: true,
      type: Array,
    },
    // Optional
    placeholder: {
      type: String,
      default: "",
    },
    disable: {
      type: Boolean,
      default: false,
    },
    parseValue: {
      type: Function,
      default: (value) => value,
    },
    parseDisplay: {
      type: Function,
      default: (value) => value.map(({ name }) => name).join(", "),
    },
    parseOptionLabel: {
      type: Function,
      default: (value) => value.name,
    },
    isDropUp: {
      type: Boolean,
      default: false,
    },
    minimumSelected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return { showOptions: false };
  },
  computed: {
    valueModel: gettersAndSetters.default("value"),
    displaySelectedOptions() {
      if (this.valueModel.length === 0) {
        return "";
      }

      return this.parseDisplay(this.valueModel);
    },
    valueObject() {
      const valueInObject = {};
      if (this.valueModel.length > 0) {
        for (let value of this.valueModel) {
          valueInObject[value.id] = value;
        }
      }
      return valueInObject;
    },
    selectedSchoolLenght() {
      const valueInObject = { ...this.valueObject };
      return _.size(valueInObject) > this.minimumSelected;
    },
  },
  methods: {
    toggleShowOptions() {
      if (!this.disable) {
        this.showOptions = !this.showOptions;
      }
    },
    addToValue(option) {
      const valueInObject = { ...this.valueObject };
      if (this.isChecked(option) && this.selectedSchoolLenght) {
        delete valueInObject[option.id];
      } else {
        valueInObject[option.id] = option;
      }

      this.valueModel = this.parseValue(Object.values(valueInObject));
    },
    isChecked(option) {
      return !!this.valueObject[option.id];
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  margin: 0 1.09vw 0 0;
  height: auto;
}
h5 {
  margin: 0 1.09vw 0 0;
  height: auto;
  cursor: pointer;
  user-select: none;
}
.multiselect {
  width: 100%;
  position: relative;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  /* font-weight: bold; */
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.multi-select-checkboxes-dropdown {
  display: block;
  width: 100%;
  position: absolute;
  background-color: $white;
  border: 1px #dadada solid;

  z-index: 9999;
  max-height: 15vh;
  overflow-y: auto;
}

.multi-select-checkboxes-dropup {
  display: block;
  width: 100%;
  position: absolute;
  background-color: $white;
  border: 1px #dadada solid;

  z-index: 9999;
  max-height: 15vh;
  overflow-y: auto;
  bottom: 39px;
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 3vh;
  margin: 0.5vh 0;
  font-size: $medium-font-size * 0.9;
  padding: $global-padding * 0.5 0;

  &:hover {
    background-color: $bg-selected;
  }
}

.option-label {
  white-space: pre-wrap;
}
</style>
