import studentPlanStatusApi from '@/api/studentPlanStatus';
import * as types from '../mutation-types';

// initial state
const state = {
	studentPlanStatus: {},
	studentProgrammePlanStatuses: {},
	studentAnnualPlanStatuses: {}
};

// getters
const getters = {
	studentPlanStatus: (state) => (classId, studentId) => {
		let studentPlanStatusForClass = state.studentPlanStatus[classId];
		return studentPlanStatusForClass ? studentPlanStatusForClass.find(it => it.studentId === studentId) : {};
	},
	studentProgrammePlanStatuses(state) {
		return state.studentProgrammePlanStatuses;
	},
	studentAnnualPlanStatuses(state) {
		return state.studentAnnualPlanStatuses;
	}
};

const actions = {
	getStudentPlanStatusesForClassGroup({ commit }, classGroupId) {
		return studentPlanStatusApi.getStudentPlanStatusesForClassGroup(classGroupId).then(resp => {
			if (resp.body) {
				return commit(types.SET_PLAN_STATUS_FOR_CLASS_GROUP, { classGroupId: classGroupId, studentPlanStatuses: resp.body.planStatuses });
			}
		});
	},
	getStudentProgrammePlanStatus({ commit }, { studentId, year }) {
		return studentPlanStatusApi.getStudentProgrammePlanStatus(studentId, year).then(resp => {
			if (resp.body) {
				return commit(types.SET_PROGRAMME_PLAN_STATUS, { studentId: studentId, programmePlanStatus: resp.body.programmePlanStatus });
			}
		});
	},
	setStudentProgrammePlanStatus({ commit }, { studentId, year, planStatus }) {
		return studentPlanStatusApi.setStudentProgrammePlanStatus(studentId, year, planStatus).then(resp => {
			if (resp.body) {
				return commit(types.SET_PROGRAMME_PLAN_STATUS, { studentId: studentId, programmePlanStatus: resp.body.programmePlanStatus });
			}
		});
	},
	getStudentAnnualPlanStatus({ commit }, { studentId, planYear }) {
		return studentPlanStatusApi.getStudentAnnualPlanStatus(studentId, planYear).then(resp => {
			if (resp.body) {
				return commit(types.SET_ANNUAL_PLAN_STATUS, { studentId: studentId, annualPlanStatus: resp.body.annualPlanStatus });
			}
		});
	},
	setStudentAnnualPlanStatus({ commit }, { studentId, planYear, planStatus }) {
		return studentPlanStatusApi.setStudentAnnualPlanStatus(studentId, planYear, planStatus).then(resp => {
			if (resp.body) {
				return commit(types.SET_ANNUAL_PLAN_STATUS, { studentId: studentId, annualPlanStatus: resp.body.annualPlanStatus });
			}
		});
	}

};

const mutations = {
	[types.SET_PLAN_STATUS_FOR_CLASS_GROUP](state, { classGroupId, studentPlanStatuses }) {
		state.studentPlanStatus = { ...state.studentPlanStatus, [classGroupId]: studentPlanStatuses };
	},
	[types.SET_PROGRAMME_PLAN_STATUS](state, { studentId, programmePlanStatus }) {
		state.studentProgrammePlanStatuses = { ...state.studentProgrammePlanStatuses, [studentId]: programmePlanStatus };
	},
	[types.SET_ANNUAL_PLAN_STATUS](state, { studentId, annualPlanStatus }) {
		state.studentAnnualPlanStatuses = { ...state.studentAnnualPlanStatuses, [studentId]: annualPlanStatus };
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
