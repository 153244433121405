export default {
	name: 'fileService',
	methods: {
		downloadFile(fileName, resp) {
			let headers = resp.headers.map;
			let blob = new Blob([resp.data], { type: headers['content-type'] });
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.click();
		},
		openPdfInTab(resp) {
			let file = new Blob([resp.data], { type: 'application/pdf' });
			let fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
	}
};
