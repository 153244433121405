<!-- CoachingEngagementLearningPointsList.vue -->
<template>
	<div class="learning-points-wrapper">
	  <div
		v-for="(learningPoint, index) in sortedLearningPoints"
		:key="index"
		class="learning-point-item"
	  >
		<coaching-engagement-learning-point-card
		  :learningPoint="learningPoint"
		></coaching-engagement-learning-point-card>
	  </div>
	</div>
  </template>
  
  <script>
  import CoachingEngagementLearningPointCard from "@/components/CoachingEngagementLearningPointCard";
  
  export default {
	name: "CoachingEngagementLearningPointsList",
	components: {
	  CoachingEngagementLearningPointCard,
	},
	props: ["learningPoints"],
	computed: {
		sortedLearningPoints() {
		if (!this.learningPoints) {
			return this.learningPoints
		}
		return this.learningPoints.slice().sort((a, b) => {
			const aDate = new Date(a.updatedDate).getTime();
      		const bDate = new Date(b.updatedDate).getTime();
      		return bDate - aDate;
		});
		},
	},
  };
  </script>
  
  <style lang="scss" scoped>
  .learning-points-wrapper {
	position: relative;
	border: 2px solid $light-gray;
	border-radius: 10px;
  }
  
  .learning-point-item {
	padding: 0.5em 1em;
	border-bottom: 1px solid $darker-gray;
  
	&:last-child {
	  border-bottom: none;
	}
  }
  </style>
  