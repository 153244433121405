<template>
  <div class="user-card" v-cloak>
    <a @click="showEditUserModal">
      <div class="card" :class="{ inactive: user.accountLocked }">
        <div class="card-section grid-y align-center">
          <div class="grid-x">
            <profile-image
              v-if="teacherDetails"
              class="margin-right-1"
              :image="teacherDetails && teacherDetails.image"
              :image-alt="teacherDetails.displayName"
              :image-size="50"
              :image-title="teacherDetails.displayName"
            >
            </profile-image>

            <div>
              <span v-if="teacherDetails" class="card-label">{{
                teacherDetails.displayName
              }}</span>
              <span class="card-label">({{ user.username }})</span>
              <div class="card-label">{{ $t(role) }}</div>
            </div>
          </div>
        </div>
      </div>
    </a>
    <more-dropdown
      :options="dropdownOptions"
      :identifier="'user-' + $vnode.key"
    ></more-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProfileImage from "@/components/ProfileImage";
import MoreDropdown from "@/components/MoreDropdown";
import UserModal from "@/views/UserModal/UserModal";
import constants from "@/constants";

export default {
  name: "UserCard",
  components: {
    ProfileImage,
    MoreDropdown,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["userRole", "userTeacherDetails", "getUserRoles"]),
    role() {
      return this.userRole(this.user.id);
    },
    teacherDetails() {
      return this.userTeacherDetails(this.user.id);
    },
  },
  data() {
    return {
      dropdownOptions: [
        {
          label: "userList.listCard.more.dropdownOption.edit",
          method: this.showEditUserModal,
        },
        {
          label: "userList.listCard.more.dropdownOption.delete",
          method: this.showDeleteConfirmation,
        },
      ],
    };
  },
  methods: {
    showEditUserModal() {
      const { username, accountLocked, id: userId } = this.user;
      const {
        salutation,
        fullName,
        schoolId,
        displayName,
        designation,
        id: teacherId,
      } = this.teacherDetails;
			const getUserRoles = this.getUserRoles
			let userAdminRoleCount = 0
			for (var prop in getUserRoles) {
				if (getUserRoles[prop] === "ROLE_ADMIN") {
						userAdminRoleCount++
				}
			}
			let isRoleDisable = false
			if (userAdminRoleCount === 1 && this.role === "ROLE_ADMIN") {
				isRoleDisable = true
			}
      let componentProps = {
        username,
        role: this.role,
        salutation,
        fullName,
        displayName,
        schoolId,
        designation,
        accountLocked,
        userId,
        teacherId,
				isRoleDisable
      };

      let modalParams = {
        component: UserModal,
        componentProps: componentProps,
        class: "small",
      };

      this.$modal.show(modalParams);
    },
    showDeleteConfirmation() {
      const { username } = this.user;

      let modalParams = {
        header: this.$t("userModal.delete.modalTitle", { username }),
        text: this.$t("userModal.delete.modalText", { username }),
        includeConfirm: true,
        successCallback: this.doDeleteUser,
      };

      this.$modal.show(modalParams);
    },
    async doDeleteUser() {
      const { id: userId, username } = this.user || {};
      const { id: teacherId } = this.teacherDetails || {};

      // temporary fix for an issue with multiple async causing null to i18n translate
      const successMsg = this.$t("userModal.success.deleted", { username });

      try {
        const { status: teacherStatus } = await this.deleteTeacherDetails({
          userId,
          teacherId,
        });
        if (teacherStatus !== constants.RESPONSE_STATUS.OK) {
          return this.$toastr.error(
            this.$t("userModal.delete.error", { username })
          );
        }
        const { status: userStatus } = await this.deleteUser(userId);
        if (userStatus !== constants.RESPONSE_STATUS.OK) {
          return this.$toastr.error(
            this.$t("userModal.delete.error", { username })
          );
        }

        this.$toastr.success(successMsg);
        this.$modal.closeAll();
      } catch (error) {
        this.$toastr.error(error.data && error.data.message);
      }
    },
    ...mapActions(["deleteTeacherDetails", "deleteUser"]),
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  margin-bottom: 0.3em;
  position: relative;

  a {
    color: $dark-gray;

    &:hover {
      color: smart-scale($dark-gray, 20%);
    }
  }
}
.card {
  &.inactive {
    background-color: $light-gray;
  }
}
.card-section {
  padding-top: $global-padding / 1.5;
  padding-bottom: $global-padding / 1.5;
}
.card-label {
  font-size: map-deep-get($header-styles, small, h5, font-size) * 1px;
  word-break: break-all;
}
</style>
