import * as types from '../mutation-types';
import classGroupApi from '@/api/classGroup';
import constants from '@/constants';

// initial state
const state = {
	classGroup: {}
};

// getters
const getters = {
	classGroup(state) {
		return state.classGroup;
	}
};

// actions
const actions = {
	getClassGroup({ commit, getters }, classId) {
		let year = getters.selectedYearFilter;
		return classGroupApi.getClassGroup(classId, year).then((resp) => {
			if (resp.data) {
				commit(types.SET_CLASS_GROUP, resp.data.classGroup);
			}
		});
	},
	clearClassGroup({ commit }) {
		commit(types.SET_CLASS_GROUP, {});
	},
	async createClassGroup({ dispatch }, { name, year, schoolId, programmeId }) {
		const { status, data } = await classGroupApi.createClassGroup(name, year, schoolId, programmeId);

		if (status === constants.RESPONSE_STATUS.OK && data) {
			// update programme dashboard, and update sidemenu
			await dispatch('getProgrammeDetails', { programmeId, year });
			await dispatch('getSideMenu', year);
		}

		return data;
	},
	updateClassGroup({ _ }, { classId, name, year, schoolId, programmeId }) {
		return classGroupApi.updateClassGroup(classId, name, year, schoolId, programmeId);
	},
	deleteClassGroup({ _ }, { classId }) {
		return classGroupApi.deleteClassGroup(classId);
	},
	async removeStudentFromClassGroup({ commit }, { classId, studentId }) {
		const { status, data } = await classGroupApi.removeStudentFromClassGroup(classId, studentId);

		if (status === constants.RESPONSE_STATUS.OK && data) {
		}
	},
	async addStudentsToClassGroup({ commit }, { classId, studentIds }) {
		const { status, data } = await classGroupApi.addStudentsToClassGroup(classId, studentIds);

		if (status === constants.RESPONSE_STATUS.OK && data) {
			commit(types.SET_CLASS_GROUP, data);
		}
		return { status, data };
	},
	async removeTeacherFromClassGroup({ commit }, { classId, teacherId }) {
		const { status, data } = await classGroupApi.removeTeacherFromClassGroup(classId, teacherId);

		if (status === constants.RESPONSE_STATUS.OK && data) {
		}
	},
	async addTeachersToClassGroup({ commit }, { classId, teacherIds }) {
		const { status, data } = await classGroupApi.addTeachersToClassGroup(classId, teacherIds);

		if (status === constants.RESPONSE_STATUS.OK && data) {
			commit(types.SET_CLASS_GROUP, data);
		}
	}
};

// mutations
const mutations = {
	[types.SET_CLASS_GROUP](state, classGroup) {
		state.classGroup = classGroup;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
