<!-- CoachingEngagementLearningPoints.vue -->
<template>
	<div>
	  <div class="learning-points-header" @click="toggleContent">
		<p>Learning Points</p>
		<span class="toggle-icon">{{ isOpen ? '▼' : '▶' }}</span>
		<button class="add-button" @click="addingLearningPoint=true">
			<font-awesome-icon icon="plus" size="1x"></font-awesome-icon>
			Add Learning Point
		</button>
	  </div>
	  <div v-if="addingLearningPoint" class="add-learning-point-container">
		<input 
			v-model="newDescription" 
			@keyup.enter="confirmAddLearningPoint"
			@keyup.esc="cancelAddLearningPoint" 
			placeholder="Type learning point description and press Enter"
		>
		<button class="button primary margin-top-1" @click="confirmAddLearningPoint">Save</button>
		<button class="button primary margin-top-1" @click="cancelAddLearningPoint">Cancel</button>
	  </div>
	  <coaching-engagement-learning-points-list
		:learningPoints="currentCoachingEngagement.coachingLearningPoints"
		v-show="isOpen"
	  ></coaching-engagement-learning-points-list>
	</div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import CoachingEngagementLearningPointsList from "@/components/CoachingEngagementLearningPointsList";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  
  export default {
	name: "CoachingEngagementLearningPoints",
	components: {
	  FontAwesomeIcon,
	  CoachingEngagementLearningPointsList,
	},
	props: ["learningPoints"],
	data() {
	  return {
		isOpen: false,
		addingLearningPoint: false,
		newDescription: '',
	  };
	},
	computed: {
		...mapGetters({
			currentCoachingEngagement: 'currentCoachingEngagement',
		})
	},
	methods: {
	  ...mapActions([
		"createCoachingLearningPoint",
		"updateCoachingLearningPoint"
	  ]),
	  toggleContent() {
		this.isOpen = !this.isOpen;
	  },
	  async confirmAddLearningPoint() {
		if (this.newDescription.trim() !== '') {
			console.log("createCoachingLearningPoint:")
			console.log("createCoachingLearningPoint: this.currentCoachingEngagement.id: ", this.currentCoachingEngagement.id)
			console.log("createCoachingLearningPoint: this.newDescription: ", this.newDescription)
				// check the vuex store call to add outcome
			await this.createCoachingLearningPoint({ 
					studentCoachingEngagementId: this.currentCoachingEngagement.id,
					description: this.newDescription 
				});
				this.newDescription = '';
			}
			this.addingLearningPoint = false;
		},
		cancelAddLearningPoint() {
			this.addingLearningPoint = false;
			this.newDescription = '';
	  },
	},
  };
</script>
  
<style lang="scss" scoped>
  .learning-points-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $title-color;
	color: $white;
	padding: 0.5em 1em;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	font-size: 1.25em;
	font-weight: bold;
	cursor: pointer;
	border: 2px solid $light-gray;
  
	p {
	  margin: 0;
	  padding: 0.5em;
	}

	.add-button {
      color: $white;
      font-size: 1rem;

      &:hover {
          background-color: $bg-selected;
      }
    }

  }
  
  .add-learning-point-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $white; // or any other color you want

    input {
      flex-grow: 1;
      //margin-right: 1em; // add some space between input and buttons
    }
    
    .button {
      width: auto; // reset the button width to auto for better spacing
      margin-left: 1em;
    }
  }

  .toggle-icon {
	font-size: 1.2em;
  }
</style>
  