import Vue from 'vue'

export default {
	setStudentProgrammeEloStatusActive (studentId, selectedYear, eloCode) {
		return Vue.http.put('studentProgrammeEloStatus/' + studentId, {
			year: selectedYear,
			eloCode: eloCode
		})
	}
}
