import { mapGetters } from 'vuex';

export default {
	name: 'studentAttainmentScore',
	computed: {
		...mapGetters({
			attainmentScore: 'attainmentScore'
		})
	},
	methods: {
		getStudentAttainmentScore(classId, studentId) {
			let studentAttainmentScore = this.attainmentScore(classId, studentId);
			return studentAttainmentScore ? studentAttainmentScore.score : -1;
		}
	}
};
