import Vue from 'vue'

export default {
	getClassGroup (classId, year) {
		return Vue.http.get('classGroup/' + classId, {
			params: {
				year: year
			}
		})
	},
	createClassGroup (name, year, schoolId, programmeId) {
		return Vue.http.post('classGroup', {
			name, year, schoolId, programmeId
		})
	},
	updateClassGroup (classId, name, year, schoolId, programmeId) {
		return Vue.http.put(`classGroup/${classId}`, {
			name, year, schoolId, programmeId
		})
	},
	deleteClassGroup (classId) {
		return Vue.http.delete(`classGroup/${classId}`)
	},
	addStudentsToClassGroup (classId, studentIds) {
		return Vue.http.put('classGroupStudent/' + classId, {
			studentIds
		})
	},
	removeStudentFromClassGroup (classId, studentId) {
		return Vue.http.delete('classGroupStudent/' + classId, {
			// body param is required for delete http calls
			body: {
				studentIds: [studentId]
			}
		})
	},
	addTeachersToClassGroup (classId, teacherIds) {
		return Vue.http.put('classGroupTeacher/' + classId, {
			teacherIds
		})
	},
	removeTeacherFromClassGroup (classId, teacherId) {
		return Vue.http.delete('classGroupTeacher/' + classId, {
			// body param is required for delete http calls
			body: {
				teacherIds: [teacherId]
			}
		})
	}
}
