import * as types from '../mutation-types';

// initial state
const state = {
	loaders: []
};

// getters
const getters = {
	loaders(state) {
		return state.loaders;
	}
};

// actions
const actions = {
	addLoader({ commit }, loaderId) {
		commit(types.ADD_LOADER, loaderId);
	},
	removeLoader({ commit, state }, loaderId) {
		const index = state.loaders.findIndex((loader) => loader === loaderId);

		if (index >= 0) {
			commit(types.REMOVE_LOADER, index);
		}
	}
};

// mutations
const mutations = {
	[types.ADD_LOADER](state, loaderId) {
		state.loaders.push(loaderId);
	},
	[types.REMOVE_LOADER](state, index) {
		state.loaders.splice(index, 1);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
