<template>
	<ul class="tabs no-print" v-cloak>
		<li v-for="nav in allowedFunctions" :key="nav.target" class="tabs-title" :class="isActive(nav.target) ? 'is-active' : ''">
			<router-link :to="{ name: nav.target, params: params }">{{ $t(nav.label) }}</router-link>
		</li>
        <li key="Tenants"  v-if="isHyper" class="tabs-title" :class="isActive('Tenants') ? 'is-active' : ''">
			<router-link to="tenants">Tenants</router-link>
		</li>
	</ul>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'
export default {
	name: 'TheNavigation',
	props: {
		params: Object
	},
	data () {
		return {
            isHyper: false,
		}
	},
    created() {
        this.isHyper = false; // this.isHyperCheck();
    },
	computed: {
		...mapGetters({
			allowedFunctions: 'allowedFunctions'
		})
	},
	methods: {
		isActive (target) {
			return this.$route.matched.some(m => m.name === target)
		},
        isHyperCheck() {
            const role = store.getters.profile.roles;
            const isHyper = role.includes('ROLE_HYPER');
            return isHyper
        }
	}
}
</script>

<style lang="scss" scoped>
.tabs-title a {
  padding-bottom: $global-padding / 2;
  padding-left: $global-padding / 2;
  padding-right: $global-padding / 2;
  font-size: $small-font-size;

  @include breakpoint(medium) {
    padding-bottom: $global-padding;
    padding-left: $global-padding;
    padding-right: $global-padding;
    font-size: $medium-font-size;
  }
}
.is-active a {
  margin-bottom: -2px;
  border-bottom: 2px solid $black;
  color: $black;
}
</style>

