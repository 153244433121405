<template>
  <div class="container-wrapper" v-cloak>
    <lesson-plan-elo-list></lesson-plan-elo-list>
    <lesson-plan-body :lessonPlan="lessonPlan"></lesson-plan-body>
    <lesson-plan-remarks></lesson-plan-remarks>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LessonPlanBody from "./LessonPlanBody";
import LessonPlanEloList from "./LessonPlanEloList.vue";
import LessonPlanRemarks from "./LessonPlanRemarks";

export default {
  name: "LessonPlan",
  components: {
    LessonPlanEloList,
    LessonPlanBody,
    LessonPlanRemarks,
  },
  props: ["classId", "term", "lessonId"],
  watch: {
    term: function () {
      this.getStudentElosForTerm();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getLessonPlan();
      vm.getStudentElosForTerm();
      vm.getLessonPlanRemarks();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.getLessonPlan();
    this.getStudentElosForTerm();
    this.getLessonPlanRemarks();
    next();
  },
  computed: {
    lessonPlan: function () {
      return this.$store.getters.lessonPlan;
    },
  },
  created() {
    this.getCurriculum();
  },
  beforeDestroy() {
    this.$store.dispatch("setLessonPlan", {
      lessonPlan: null,
    });
  },
  methods: {
    ...mapActions(["getCurriculum"]),
    getStudentElosForTerm() {
      this.$store.dispatch("getLessonPlanElos", {
        lessonId: this.lessonId,
      });
      this.$store.dispatch("getStudentElosForTerm", {
        classGroupId: this.classId,
        planTerm: this.term,
      });
    },
    getLessonPlan() {
      this.$store.dispatch("getLessonPlan", {
        lessonId: this.lessonId,
      });
    },
    getLessonPlanRemarks() {
      this.$store.dispatch("getLessonPlanRemarks", {
        lessonPlanId: this.lessonId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
