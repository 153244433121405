<!-- CoachingEngagementOutcomes.vue -->
<template>
	<div>
		<coaching-engagement-outcomes-list />

	</div>
</template>

<script>
import CoachingEngagementOutcomesList from "@/components/CoachingEngagementOutcomesList";
  
  export default {
	name: "CoachingEngagementOutcomes",
	components: {
	  CoachingEngagementOutcomesList
	},
}
</script>