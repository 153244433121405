<template>
  <div>
    <div id="signin_button" class="google-logo"></div>
  </div>
</template>

<script>
  export default {
	name: "SigninButton",
    components: {

	},
    methods: {
      handleCredentialResponse(response) {
		const responsePayload = JSON.parse(Buffer.from(response.credential.split('.')[1], 'base64').toString());
        this.$store.dispatch("login", { emailId: responsePayload.email, authToken: response.credential }).then(
          () => {
            let postLogin = this.$route.query.redirect
              ? this.$route.query.redirect
              : { name: "Dashboard" };
            this.$router.push(postLogin);
          },
          (error) => {
            let message = error.data.message;
            if (error.status === 401) {
              message = this.$t("error.invalidUsernameOrPassword");
            }
            if (message) {
              this.$toastr.error(message);
            }
          }
        );
      }
    },
    mounted: function () {
      let googleScript = document.createElement('script');
      googleScript.src = 'https://accounts.google.com/gsi/client';
      document.head.appendChild(googleScript);
      window.addEventListener('load', () => {
        window.google.accounts.id.initialize({
          client_id: "708123936534-8fc8qi449rjulp5t7ednr6viim4tfh3q.apps.googleusercontent.com", // Algebrasites
          callback: this.handleCredentialResponse,
		  ux_mode: "popup"
        })
        window.google.accounts.id.renderButton(
          document.getElementById("signin_button"), { 
			theme: "outline", 
			size: "large" 
		})
      })
    }
  }
</script>

<style lang="scss">

	.google-logo {
		height: 1.5em;
		padding-right: $global-padding / 2;
		display: flex;
		justify-content: center;
	}
</style>