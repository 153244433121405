import "./styles/global.scss";

import Vue from "vue";
import "./utils/foundation";
import "./utils/font-awesome";
import "./api";
import "./directives";
import "./plugins";
import "./filters";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./utils/i18n";
import "./registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
