import Vue from 'vue'

export default {
	getLessonPlanRemarks (lessonPlanId) {
		return Vue.http.get('lessonPlanRemarks/' + lessonPlanId)
	},
	postLessonPlanRemark (lessonPlanId, remark) {
		return Vue.http.post('lessonPlanRemarks', {
			lessonPlanId: lessonPlanId,
			remark: remark
		})
	},
	deleteLessonPlanRemark (remarkId) {
		return Vue.http.delete('lessonPlanRemarks/' + remarkId)
	},
	getLessonPlanRemarkEvidence (remarkId, fileUUID) {
		return Vue.http.get('lessonPlanRemarksEvidence/' + fileUUID, {
			params: {
				remarkId: remarkId
			}
		})
	},
	postLessonPlanRemarkEvidence (details) {
		return Vue.http.post('lessonPlanRemarksEvidence', details, {
			setContentType: false,
			processData: false,
			headers: { 'Content-Type': undefined }
		})
	}
}
