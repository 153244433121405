import { render, staticRenderFns } from "./TaskItemAddModalCard.vue?vue&type=template&id=b2f48e80&scoped=true&"
import script from "./TaskItemAddModalCard.vue?vue&type=script&lang=js&"
export * from "./TaskItemAddModalCard.vue?vue&type=script&lang=js&"
import style0 from "./TaskItemAddModalCard.vue?vue&type=style&index=0&id=b2f48e80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2f48e80",
  null
  
)

export default component.exports