<template>
  <div class="total-elo-filter selector-radio" v-if="selected" v-cloak>
    <button class="button clear" data-toggle="filter-dropdown">
      <total-elo-filter-details
        :label="selected.label"
        :match="selected.match"
        :elo-count="selected.eloCount"
      >
      </total-elo-filter-details>
    </button>

    <div
      id="filter-dropdown"
      class="dropdown-pane"
      ref="filterDropdown"
      v-foundation:Dropdown.destroy
      data-auto-focus="true"
      data-close-on-click="true"
    >
      <total-elo-filter-details
        v-for="filter in filtersWithCount"
        :key="filter.match"
        @click.native="selectFilter(filter.match)"
        :label="filter.label"
        :match="filter.match"
        :elo-count="filter.eloCount"
      >
      </total-elo-filter-details>
    </div>
  </div>
</template>

<script>
import TotalEloFilterDetails from "./TotalEloFilterDetails";
import constants from "@/constants";

export default {
  name: "TotalEloFilter",
  components: {
    TotalEloFilterDetails,
  },
  props: {
    studentDetails: Object,
  },
  data() {
    return {
      filters: [
        {
          label: this.$t("totalEloFilter.totalElos"),
          match: null,
          eloCount: 0,
        },
        {
          label: this.$t("totalEloFilter.attained"),
          match: constants.ELO_STATUS.ATTAINED,
          eloCount: 0,
        },
        {
          label: this.$t("totalEloFilter.exempted"),
          match: constants.ELO_STATUS.EXEMPTED,
          eloCount: 0,
        },
      ],
      selected: null,
      constants: constants,
    };
  },
  computed: {
    studentProgrammeElos: function () {
      return this.studentDetails && this.studentDetails.studentProgrammeElos;
    },
    filtersWithCount: function () {
      if (!this.studentProgrammeElos) return;

      let filterClone = [].concat(this.filters);
      filterClone.forEach((filter) => {
        let getCount = this.studentProgrammeElos.filter((elo) =>
          !filter.match ? true : elo.eloStatus === filter.match
        );
        filter.eloCount = getCount ? getCount.length : filter.eloCount;
      });
      return filterClone;
    },
  },
  mounted() {
    this.initSelected();
  },
  methods: {
    initSelected() {
      this.selected =
        this.filters.find(
          (filter) => filter.match === this.$route.query.filter
        ) || this.filters[0];
    },
    selectFilter(match) {
      this.selected = this.filters.find((filter) => {
        return filter.match === match;
      });
      $(this.$refs.filterDropdown).foundation("close");
      this.setQueryToRoute(match);
    },
    setQueryToRoute(match) {
      let query = null;
      if (match) {
        query = { filter: match };
      }
      this.$router.push({ query });
    },
  },
};
</script>

<style lang="scss" scoped>
.total-elo-filter {
  position: relative;
  margin-left: $global-margin / 2;
  padding: $global-padding / 3.7 $global-padding / 2.5;
}
.dropdown-pane {
  margin-top: $global-margin * 2.5;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
}
</style>
