/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log("Service worker has been registered.");
      setInterval(function () {
        // note that the code in this file does not update until refresh.
        registration.update();
      }, 1000);
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("Service Worker update was found")
      const date = new Date();
      function htmlToElement(html) {
        const template = document.createElement("template");
        html = html.trim();
        template.innerHTML = html;
        return template.content.firstChild;
      }

      function reload() {
        location.reload(true);
      }

      function countDown() {
        const minutes = 10;
        const timeLimit = new Date(date.getTime() + minutes * 60000);

        setInterval(() => {
          const currentDate = new Date();
          const timeDifference =
            (timeLimit.getTime() - currentDate.getTime()) / 60000;
          const minute = parseInt(timeDifference);
          const second = Math.floor(
            (Math.abs(timeDifference) * 60) % 60
          );

          const timeLeft = `${minute} Minutes ${second} Seconds`;

          const countDownNode = document.querySelector(
            "#maintanence-time-left"
          );
          if (countDownNode) {
            countDownNode.innerText = timeLeft;
          }
          if (timeDifference <= 0) {
            reload(true);
          }
        }, 1000);

        const app = document.querySelector("#app");

        const countHTML = `    
      <div
        id="countdown-maintanence"
        style="
          padding: 12px;
          position: fixed;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: right;
          justify-content: right;
          z-index: 9998;
          border: 1px solid white;
        "
        class="bgcolor--header-color margin-bottom-1 margin-left-1"
      >
        <div class="locked-display-count-down">
          <h6 class="margin-bottom-0 mini-padding-bottom color--white" id="maintanence-time-left">Countdown before refresh</h6>
        </div>
      </div>`;
        app.appendChild(htmlToElement(countHTML));
      }

      function closeWarning() {
        const warning = document.querySelector(
          "#reveal-overlay-maintenance"
        );
        warning.remove();
        countDown();
      }

      function refreshWarning() {
        const app = document.querySelector("#app");

        const warning = `  
      <div id="reveal-overlay-maintenance" class="reveal-overlay" style="display: block">
        <div
          id="reveal_maintenance"
          data-v-offset="30"
          data-multiple-opened="true"
          data-close-on-click="false"
          data-close-on-esc="false"
          class="reveal"
          role="dialog"
          aria-hidden="false"
          data-yeti-box="reveal_maintenance"
          data-resize="reveal_maintenance"
          data-reveal="rh4prf-reveal"
          style="display: block"
          tabindex="-1"
        >
          <div class="edit-elo-modal">
            <h4 class="modal-title">New Update</h4>
            <div class="edit-elo-modal-content">
              <h6>
                Site is currently being updated. Kindly save your changes within
                the next ten minutes
              </h6>
              <div class="padding-top-1 grid-x align-right">
                <button class="button margin-right-1" id="maintenance-refresh">Refresh</button>
                <button class="button" id="maintenance-close-button">Close</button>
              </div>
            </div>
          </div>
          <button
            type="button"
            aria-label="Close Modal"
            class="close-button color--medium-gray"
            id="maintenance-close-cross"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>`;

        app.appendChild(htmlToElement(warning));

        document
          .querySelector("#maintenance-close-cross")
          .addEventListener("click", () => {
            closeWarning();
          });
        document
          .querySelector("#maintenance-close-button")
          .addEventListener("click", () => {
            closeWarning();
          });
        document
          .querySelector("#maintenance-refresh")
          .addEventListener("click", () => {
            reload();
          });
      }

      const thereIsCoundown = document.querySelector(
        "#countdown-maintanence"
      );
      const thereIsOverlay = document.querySelector(
        "#reveal-overlay-maintenance"
      );
      if (!thereIsCoundown || !thereIsOverlay) {
        refreshWarning();
      }
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
