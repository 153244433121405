import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from '@/i18n/en.json';

Vue.use(VueI18n);
let silentTranslationWarn = process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'local';

export const defaultLang = 'en';

export const i18n = new VueI18n({
	locale: defaultLang,
	fallbackLocale: defaultLang,
	silentTranslationWarn,
	messages: {
		en: enLocale
	}
});
