<template>
  <div class="details" v-cloak>
    <div class="elo-code">
      <font-awesome-icon
        v-if="showDetails"
        class="icon-image comment"
        :icon="['far', 'star']"
        size="1x"
        :style="{ color: 'gold', fill: 'gold' }"
      >
      </font-awesome-icon>
      {{ eloCode }}

      <span
        v-for="(code, index) in programmeCodes"
        :key="index"
        class="elo-core"
      >
        {{ programmeName(code)
        }}<template v-if="index + 1 < programmeCodes.length">, </template>
      </span>
      <span
        v-for="(outcome, index) in programmeOutcome"
        :key="'outcome' + index"
        class="elo-outcome"
      >
        {{ outcome
        }}<template v-if="index + 1 < programmeOutcome.length">, </template>
      </span>
    </div>

	<p class="elo-desc">{{ eloName }}</p>
	<p v-if="link != null && link != 'undefined' && link != ''">
	  <a class="elo-code linkTab" v-if="link !== null" @click="openTab">More Details</a>
	</p>
	<slot></slot>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "EloListCardDetails",
  components: {
    FontAwesomeIcon,
  },
  props: {
    eloCode: String,
    eloName: String,
    programmeOutcome: Array,
    programmeCodes: Array,
    showDetails: Boolean,
    link: String,
  },
  computed: {
    ...mapGetters({
      curriculum: "curriculum"
    })
  },
  methods: {
    openTab() {
	  const openLink = this.link
	  if (openLink != null && openLink !== "") {
		window.open(openLink)
	  }
    },
    programmeName(code) {
      if (typeof this.$store.getters.curriculum.programmes !== 'undefined') {
        for (const curriculumProgramme of this.$store.getters.curriculum.programmes) {
          if (curriculumProgramme.code == code) {
            return curriculumProgramme.name
          }
        }
      }
      return ''
    }
  },
};
</script>

<style lang="scss" scoped>
.details {
  position: relative;

  // Remove margin bottom for elos in student record.
  .lesson-record-wrapper & p {
    margin-bottom: 0;
  }
}
.elo-code {
  margin-bottom: $global-margin / 3;
  color: $dark-gray;
  font-size: 11px;

  .elo-core {
    @extend .sans-serif-extrabold;
    padding-left: $global-padding / 4.2;
    color: $brand-quaternary;
  }

  .elo-outcome {
    @extend .sans-serif-extrabold;
    padding-left: $global-padding / 4.2;
    color: $brand-outcome;
  }

  @media print {
    margin-bottom: 0;
  }
}
.elo-desc {
  // margin-bottom: $global-margin / 3;
  margin-bottom: 0;

  .terminated & {
    color: $darker-gray;
  }
}
</style>

