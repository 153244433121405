<template>
  <div class="grid-x top-info-wrapper" v-cloak>
    <div class="cell small-12 margin-bottom-1">
      <h1 class="page-title">{{ $t("programme") }} {{ programmeName }}</h1>
    </div>
    <div v-if="hasEditPermission" class="cell small-12 grid-x text-right">
      <div class="cell auto no-print">
        <button class="button clear" @click="createClassGroup()">
          <font-awesome-icon icon="plus"></font-awesome-icon>
          &nbsp;<b>{{ $t("programmeProfile.addClass") }}{{ year }}</b>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ProgrammeProfile",
  components: {
    FontAwesomeIcon,
  },
  props: ["programme", "year"],
  computed: {
    ...mapGetters(["profile"]),
    programmeName: function () {
      return this.programme ? "- " + this.programme.name : "";
    },
    hasEditPermission() {
      const permittedRoles = ["ROLE_SUPER_TEACHER", "ROLE_LEAD_TEACHER"];
      return this.profile && this.profile.roles
        ? this.profile.roles.some((role) => permittedRoles.indexOf(role) > -1)
        : false;
    },
  },
  methods: {
    createClassGroup() {
      this.$emit("create-class-group");
    },
  },
};
</script>

<style lang="scss" scoped>
.top-info-wrapper {
  margin-bottom: $global-margin;

  @media print {
    margin-bottom: 0;
  }
}
</style>
