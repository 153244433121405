import { mapGetters } from 'vuex';

export default {
	name: 'studentPlanStatus',
	computed: {
		...mapGetters({
			studentPlanStatus: 'studentPlanStatus'
		})
	},
	methods: {
		getStudentPlanStatus(classId, studentId) {
			let studentPlanStatus = this.studentPlanStatus(classId, studentId);
			return studentPlanStatus;
		},
		getStudentPlanStatusesForClassGroup(classId) {
			if (!classId) return;
			this.$store.dispatch('getStudentPlanStatusesForClassGroup', classId);
		}
	}
};
