<template>
  <div v-if="hasStudentEloDetails()" class="grid-x parent">
    <elo-list-card-details
      @click.native="showEditModal($event)"
      class="card-section edit include-hover cell auto"
      :class="[
        studentEloDetails && studentEloDetails.isNew ? 'is-new-elo' : '',
      ]"
      :show-details="isDeveloped"
      :link="link"
      :elo-code="studentEloDetails.elo.eloCode"
      :elo-name="studentEloDetails.elo.eloName"
      :programme-codes="studentEloDetails.elo.programmeCodes"
      :programme-outcome="studentEloDetails.elo.programmeOutcome"
    >
      <p>
        <span class="year-subtext">{{ studentEloDetails.planYear }}&nbsp;</span>
        <span class="icon-bar">
          <font-awesome-icon
            v-if="studentEloDetails.hasTaskItems"
            class="icon-image"
            icon="list-ul"
            size="1x"
          >
          </font-awesome-icon
          >&nbsp;
          <font-awesome-icon
            v-if="studentEloDetails.hasRemarks"
            class="icon-image comment"
            :icon="['far', 'comment-dots']"
            size="1x"
          >
          </font-awesome-icon>
        </span>
      </p>
    </elo-list-card-details>

    <more-dropdown
      :options="dropdownOptions"
      :identifier="$vnode.key"
      class="aiep-more"
    ></more-dropdown>

    <student-annual-plan-term-grid :studentEloDetails="studentEloDetails" />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EditEloModal from "@/views/EditEloModal/EditEloModal";
import MoreDropdown from "./MoreDropdown";
import EloListCardDetails from "./EloListCardDetails";
import StudentAnnualPlanTermGrid from "./StudentAnnualPlanTermGrid";

export default {
  name: "StudentAnnualPlanEloListCard",
  components: {
    FontAwesomeIcon,
    MoreDropdown,
    EloListCardDetails,
    StudentAnnualPlanTermGrid,
  },
  props: {
    textId: String,
    textName: String,
    programmeCodes: Array,
    programmeOutcome: Array,
    strands: Array,
    isDeveloped: Boolean,
    link: String,
  },
  data() {
    return {
      added: false,
    };
  },
  computed: {
    ...mapGetters({
      studentAnnualPlan: "studentAnnualPlan",
    }),
    studentEloDetails: function () {
      let studentId = this.$route.params.studentId;
      let year = this.$route.params.year;

      if (
        this.studentAnnualPlan[studentId] &&
        this.studentAnnualPlan[studentId][year] &&
        this.studentAnnualPlan[studentId][year].studentElos
      ) {
        return _.find(
          this.studentAnnualPlan[studentId][year].studentElos,
          (studentElo) => {
            return studentElo.eloCode === this.textId;
          }
        );
      } else {
        return {};
      }
    },
    studentPlanStatus: function () {
      return this.$store.getters.studentAnnualPlanStatuses[
        this.$route.params.studentId
      ];
    },
    dropdownOptions: function () {
      let dropdownOptions = [
        {
          label: "eloListCard.edit",
          method: this.showEditModal,
        },
        {
          label: "eloListCard.delete",
          method: this.deleteEloClicked,
        },
      ];

      return dropdownOptions;
    },
  },
  methods: {
    hasStudentEloDetails() {
      return this.studentEloDetails ? this.studentEloDetails.elo : null;
    },
    showEditModal(event) {
      let componentProps = {
        eloCode: this.textId,
        eloName: this.textName,
		link: this.link,
        programmeCodes: this.programmeCodes,
        programmeOutcome: this.programmeOutcome,
        studentId: this.$route.params.studentId,
        selectedYear: parseInt(this.$route.params.year),
      };

      if (event.target.classList[1] !== "linkTab") {
        this.$modal.show({
          component: EditEloModal,
          componentProps: componentProps,
          class: "large",
          closeCallback: this.modalClose,
        });
      }
    },
    modalClose() {
      let params = {
        studentId: this.$route.params.studentId,
        planYear: this.$route.params.year,
      };
      this.$store.dispatch("getStudentAnnualPlan", params);
    },
    deleteEloClicked() {
      if (this.studentPlanStatus.planStatus === "APPROVED") {
        this.$modal.show({
          header: this.$t("addRemoveElo.whenApproved.modal.header"),
          text: this.$t("addRemoveElo.whenApproved.modal.text"),
        });
      } else {
        this.showDeleteConfirmation();
      }
    },
    showDeleteConfirmation() {
      let modalParams = {
        header: this.$t("eloListCard.delete.elo.annual.modalTitle"),
        text: this.$t("eloListCard.delete.elo.annual.modalText"),
        includeConfirm: true,
        successCallback: this.deleteEloFromAnnualPlan,
      };

      this.$modal.show(modalParams);
    },
    deleteEloFromAnnualPlan() {
      let studentId = this.$route.params.studentId;
      let planYear = this.$route.params.year;
      let eloCode = this.textId;

      this.$store
        .dispatch("deleteEloFromAnnualPlan", { studentId, planYear, eloCode })
        .then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.card-section {
  position: relative;
  border-bottom: 1px solid $gray;

  &.is-new-elo {
    border-left: 2px solid $success;
    //-webkit-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //-moz-box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
    //box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 190, .75);
  }

  &.terminated {
    background-color: $light-gray;
  }

  &.edit {
    cursor: pointer;
  }

  &.include-hover {
    &:hover {
      background-color: $bg-selected;
    }
  }

  &.added {
    background-color: $bg-selected;
    border-left: 2px solid $success;
  }

  .check-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}
.icon-image {
  font-size: $small-font-size / 1.1;
  color: $darker-gray;
}
.year-subtext {
  font-size: $small-font-size / 1.1;
  line-height: 1em;
  color: $darker-gray;
}
.parent {
  position: relative;

  .aiep-more {
    right: 51%;

    @include breakpoint(small down) {
      right: 0;
    }
  }
}
</style>
