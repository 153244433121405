<template>
  <div v-if="editable" v-cloak>
    <div class="grid-x align-middle margin-bottom-2">
      <radio-filter
        class="cell shrink small"
        :option-list="optionList"
        :callback="toggleExternal"
      ></radio-filter>
    </div>
    <p v-if="external">{{ $t("editEloDetails.tabs.shared.remarks") }}</p>
    <p class="empty-message" v-if="remarks && remarks.length == 0">
      {{ $t("remarks.empty") }}
    </p>
    <remark-card
      v-for="remark in remarks"
      :key="remark.id"
      :remark="remark"
      :external="external"
      :evidence-src="evidenceSrc"
      @deleteRemark="deleteRemark"
      @fileLoaded="createImage"
    >
    </remark-card>
    <form @submit.prevent="submit">
      <rich-text-editor
        v-model="formData.remark"
        :placeholder="$t('remarks.message.placeholder')"
        :height="100"
      >
      </rich-text-editor>

      <div class="action-selector margin-top-1">
        <div class="grid-x align-right">
          <button
            type="submit"
            class="cell button shrink primary hollow margin-bottom-0"
          >
            {{ $t("editEloDetails.add") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash";
import RadioFilter from "@/components/RadioFilter";
import RemarkCard from "@/components/RemarkCard";
import RichTextEditor from "@/components/RichTextEditor";

export default {
  name: "EditEloModalRemarks",
  components: {
    RadioFilter,
    RemarkCard,
    RichTextEditor,
  },
  props: {
    eloCode: String,
    editable: Boolean,
    studentId: String,
    selectedYear: Number,
  },
  data() {
    return {
      optionList: [
        {
          label: this.$i18n.t("editEloDetails.remarks.internal"),
          value: false,
        },
        {
          label: this.$i18n.t("editEloDetails.remarks.external"),
          value: true,
        },
      ],
      external: false,
      formData: {
        remark: "",
      },
      evidenceSrc: "getEloRemarkEvidence",
    };
  },
  computed: {
    remarks: function () {
      return this.$store.getters.studentEloRemarks(
        this.studentId,
        this.selectedYear,
        this.eloCode,
        this.external
      );
    },
  },
  created() {
    this.getStudentEloRemarks();
  },
  methods: {
    getStudentEloRemarks() {
      this.params = {
        studentId: this.studentId,
        eloCode: this.eloCode,
        planYear: this.selectedYear,
        external: this.external,
      };
      this.$store.dispatch("getStudentEloRemarks", this.params);
    },
    toggleExternal(value) {
      this.external = value;
      this.getStudentEloRemarks();
    },
    submit() {
      if (!_.isEmpty(this.formData.remark)) {
        let remarksForm = { ...this.formData };
        let studentId = this.studentId;
        let planYear = this.selectedYear;
        this.$store
          .dispatch("postStudentEloRemark", {
            studentId: studentId,
            eloCode: this.eloCode,
            planYear: planYear,
            remark: remarksForm.remark,
            external: this.external,
          })
          .then((this.formData.remark = ""));
      }
    },
    createImage(file, fileName, remarkId) {
      let formData = new FormData();
      formData.append("remarkId", remarkId);
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append("external", this.external);
      this.$store
        .dispatch("addEvidenceToRemark", {
          details: formData,
          studentId: this.studentId,
          eloCode: this.eloCode,
          planYear: this.selectedYear,
          external: this.external,
        })
        .catch((error) => {
          this.$toastr.error(error.data && error.data.message);
        });
    },
    deleteRemark(remarkId) {
      this.$store.dispatch("deleteStudentEloRemark", remarkId).then(
        () => {
          this.params = {
            studentId: this.studentId,
            eloCode: this.eloCode,
            planYear: this.selectedYear,
            external: this.external,
          };
          this.$store.dispatch("getStudentEloRemarks", this.params);
        },
        () => {
          this.$toastr.error("unable to delete");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

