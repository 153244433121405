<template>
  <div>
    <p
      class="text-center"
      v-if="
        programmePlanEloStatus &&
        programmePlanEloStatus.eloStatus !== constants.ELO_STATUS.ACTIVE
      "
    >
      <span class="elo-status bgcolor--light-gray">
        {{
          $t("terminateElo.remarks.status.set", {
            eloStatus: $t(
              "terminateElo.remarks.status." + programmePlanEloStatus.eloStatus
            ),
            date: $moment(programmePlanEloStatus.eloStatusOn).calendar(),
          })
        }}
      </span>
    </p>

    <remarks
      :remarks="remarks"
      :external="external"
      :formData="formData"
      :evidence-src="evidenceSrc"
      @toggleExternal="toggleExternal"
      @submit="submit"
      @createImage="createImage"
      @deleteRemark="deleteRemark"
    >
      <template slot="externalDisclaimer">{{
        $t("terminateElo.remarks.disclaimer.external")
      }}</template>
      <template slot="submitLabel">{{ $t("remarks.submit") }}</template>
    </remarks>
  </div>
</template>

<script>
import _ from "lodash";
import Remarks from "./Remarks";
import constants from "@/constants";

export default {
  name: "TerminateEloModalRemarks",
  components: {
    Remarks,
  },
  props: {
    eloCode: String,
    studentId: String,
    selectedYear: Number,
    toBeEloStatus: {
      type: String,
      required: true,
      validator: (value) => {
        let typesAllowed = [
          constants.ELO_STATUS.ATTAINED,
          constants.ELO_STATUS.EXEMPTED,
        ];
        return _.includes(typesAllowed, value);
      },
    },
  },
  data() {
    return {
      external: false,
      formData: {
        remark: "",
      },
      evidenceSrc: "getEloTerminationRemarkEvidence",
      constants: constants,
    };
  },
  computed: {
    remarks: function () {
      return this.$store.getters.studentEloTerminationRemarks(
        this.studentId,
        this.eloCode,
        this.external
      );
    },
    programmePlanEloStatus: function () {
      let programmePlan = this.$store.getters.studentPlan[this.studentId];
      let programmeElo =
        programmePlan && programmePlan.studentProgrammeElos
          ? programmePlan.studentProgrammeElos.find(
              (it) => it.eloCode === this.eloCode
            )
          : null;
      return programmeElo;
    },
  },
  mounted() {
    this.getStudentEloTerminationRemarks();
    this.getStudentProgrammePlanEloStatus();
  },
  methods: {
    getStudentEloTerminationRemarks() {
      this.params = {
        studentId: this.studentId,
        eloCode: this.eloCode,
        external: this.external,
      };
      this.$store.dispatch("getStudentEloTerminationRemarks", this.params);
    },
    getStudentProgrammePlanEloStatus() {
      this.params = {
        studentId: this.studentId,
        planYear: this.selectedYear,
      };
      this.$store.dispatch("getStudentPlan", this.params);
    },
    toggleExternal(value) {
      this.external = value;
      this.getStudentEloTerminationRemarks();
      this.getStudentProgrammePlanEloStatus();
    },
    submit() {
      if (!this.isEmptyRemark(this.formData.remark)) {
        let remarksForm = { ...this.formData };
        let studentId = this.studentId;
        this.$store
          .dispatch("postStudentEloTerminationRemark", {
            studentId: studentId,
            eloCode: this.eloCode,
            remark: remarksForm.remark,
            external: this.external,
            selectedYear: this.selectedYear,
            eloStatus: this.toBeEloStatus,
          })
          .then(() => {
            this.formData.remark = "";
            this.getStudentProgrammePlanEloStatus();
          });
      }
    },
    isEmptyRemark(remark) {
      let isEmpty = _.isEmpty(remark);
      if (!isEmpty) {
        let html = remark;
        let div = document.createElement("div");
        div.innerHTML = html;
        let text = div.textContent || div.innerText || "";

        return text.trim() === "";
      }
      return isEmpty;
    },
    createImage(file, fileName, remarkId) {
      let formData = new FormData();
      formData.append("remarkId", remarkId);
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append("external", this.external);
      this.$store
        .dispatch("addEvidenceToEloTerminationRemark", {
          details: formData,
          studentId: this.studentId,
          eloCode: this.eloCode,
          external: this.external,
        })
        .catch((error) => {
          this.$toastr.error(error.data && error.data.message);
        });
    },
    deleteRemark(remarkId) {
      this.$store.dispatch("deleteStudentEloTerminationRemark", remarkId).then(
        () => {
          this.params = {
            studentId: this.studentId,
            eloCode: this.eloCode,
            external: this.external,
          };
          this.$store.dispatch("getStudentEloTerminationRemarks", this.params);
        },
        () => {
          this.$toastr.error("unable to delete");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.elo-status {
  padding: $global-padding * 0.3 $global-padding * 0.8;
  border-radius: 3px;
}
</style>

