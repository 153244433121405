// coachingLearningPoint.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingLearningPointApi from '@/api/coachingLearningPoint'
import constants from '@/constants';

// initial state
const state = {
  // old coachingEngagement: null,
  coachingLearningPoint: {},
  coachingLearningPoints: [],
};

// getters
const getters = {
  coachingLearningPoint: state => state.coachingLearningPoint,
  coachingLearningPoints: state => state.coachingLearningPoints,
};

// actions
const actions = {
  setCoachingLearningPoint({ commit }, coachingLearningPoint) {
    commit(types.SET_COACHING_LEARNING_POINT, coachingLearningPoint);
  },
  // new actions
  setCoachingLearningPoints({ commit }, coachingLearningPoints) {
    commit(types.SET_COACHING_LEARNING_POINTS, coachingLearningPoints);
  },
  async getCoachingLearningPoint({ commit }, id) {
    const response = await CoachingLearningPointApi.getCoachingLearningPoint(id)
    commit(types.SET_COACHING_LEARNING_POINT, response.data)
  },
  async createCoachingLearningPoint({ commit, dispatch }, { studentCoachingEngagementId, description }) {
    console.log("STORE: createCoachingLearningPoint - { studentCoachingEngagementId, description }:", { studentCoachingEngagementId, description })
    const response = await CoachingLearningPointApi.createCoachingLearningPoint(studentCoachingEngagementId, description);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_LEARNING_POINT, response.data.coachingLearningPoint);
      // here we need to get the studentCoachingEngagement again to have the latest info with coachingLearningPoint
      await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
    }
  },

  async updateCoachingLearningPoint({ commit, dispatch }, { id, studentCoachingEngagementId, description }) {
    const response = await CoachingLearningPointApi.updateCoachingLearningPoint(id, description)
    commit(types.UPDATE_COACHING_LEARNING_POINT, response.data.coachingLearningPoint)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },

  async deleteCoachingLearningPoint({ commit, dispatch }, id, studentCoachingEngagementId) { // here make this use: {id, studentCoachingEngagementId}
    await CoachingLearningPointApi.deleteCoachingLearningPoint(id)
    commit(types.DELETE_COACHING_LEARNING_POINT, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_LEARNING_POINT](state, coachingLearningPoint) {
    state.coachingLearningPoint = coachingLearningPoint;
  },
  [types.SET_COACHING_LEARNING_POINTS] (state, coachingLearningPoints) {
    state.coachingLearningPoints = coachingLearningPoints
  },
  [types.ADD_COACHING_LEARNING_POINT] (state, coachingLearningPoint) {
    state.coachingLearningPoints.unshift(coachingLearningPoint)
  },
  [types.UPDATE_COACHING_LEARNING_POINT] (state, coachingLearningPoint) {
    Vue.set(state.coachingLearningPoint, coachingLearningPoint)
  },
  [types.DELETE_COACHING_LEARNING_POINT] (state, coachingLearningPointId) {
    state.coachingLearningPoints = state.coachingLearningPoints.filter(e => e.id !== coachingLearningPointId)
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
