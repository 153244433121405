<!-- CoachingEngagementSessionCard.vue -->
<template>
	<div class="session-card">
	  <!-- Display session information -->
	  <div class="session-info">
		<div v-if="!session.placeholderFutureSession">
		  <!-- Show the goal symbol for the session >>> refactor to switch from id to the index number -->
		  <div v-if="sessionGoal()" class="goal-number">
			{{ sessionGoalIndex() }}
      	  </div>
		  <p>
			<strong>Session:</strong>
			{{ formatDateOnly(session.startTime) }} {{ formatTime(session.startTime) }} - {{ formatTime(session.endTime) }}
			<span v-if="session.online" class="online-indicator">Online</span>
		  </p>
		  <p>
			<strong>Venue:</strong>
			{{ session.venue }}
		  </p>
		  <p v-if="session.outcomeSelfAssessmentTime > '2000-01-01'">
          	<strong>Self-Assessed:</strong>
          	{{ formatDate(session.outcomeSelfAssessmentTime) }}
          </p>
		</div>
		<div v-else>
        	<p>Future session not yet scheduled</p>
      	</div>
		<div class="outcome-assessments" v-if="!session.placeholderFutureSession">
			<!-- <p>session.id: {{ session.id }}</p>  -->
			<!-- <p>addAssessment: {{ addAssessment }}</p>  -->
			<div v-if="!addAssessment">
				Outcome Assessment:
				<div v-for="(assessment, index) in session.coachingOutcomeAssessments" :key="index" class="outcome-container">
					<div class="assessment-item">
						<!-- Outcome {{ index + 1 }}: -->
						<div class="assessment-bar">
							{{ index + 1 }}:
							<div class="bar-inner" :style="{ width: ((assessment.result - 1) * 11.11) + '%' }"></div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<button @click="addAssessmentActive = true">Add Assessments +</button>
			</div>
			<!-- Interface to Add Assessments -->
			<div class="add-assessment-container" v-if="addAssessmentActive">
				<div class="content">
					<strong>Add Assessments</strong>
					<div v-if="!sessionGoal()">
						<label>Select Goal to Assess:</label>
						<select v-model="selectedGoal">
							<option v-for="(goal, index) in this.currentCoachingEngagement.coachingGoals" :key="index" :value="goal">{{ goal.name }}</option>
						</select>
						<button @click="addAssessmentActive = false">Cancel</button>
					</div>
					<div v-if="currentGoal">
						<div v-for="(outcome, index) in currentGoal.coachingOutcomes" :key="index">
							<label>{{ outcome.outcomeName }}:</label>
							<input type="range" v-model="newAssessmentValues[index]" min="1" max="10" />
							<span>{{ newAssessmentValues[index] }}</span>
						</div>
						<button @click="addNewAssessments(currentGoal.coachingOutcomes, newAssessmentValues)">Save</button>
						<button @click="addAssessmentActive = false">Cancel</button>
					</div>
				</div>
			</div>

			<!-- <p>addSelfAssessment: {{ addSelfAssessment }}</p>
			<p>addSelfAssessment: {{ session.coachingOutcomeSelfAssessments }}</p> -->
			<div v-if="!addSelfAssessment">
				<!-- <p>session.coachingOutcomeSelfAssessments: {{ session.coachingOutcomeSelfAssessments }}</p> -->
				Self-Assessment:
				<div v-for="(selfAssessment, index) in session.coachingOutcomeSelfAssessments" :key="index" class="outcome-container">
					<div class="assessment-item">
						<!-- Self-Assessment {{ index + 1 }}: -->
						<div class="assessment-bar">
							{{ index + 1 }}:
							<div class="bar-inner" :style="{ width: ((selfAssessment.result - 1) * 11.11) + '%' }"></div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<button @click="addSelfAssessmentActive = true">Add Self Assessments +</button>
			</div>
			<!-- Interface to Add Self Assessments -->
			<div class="add-assessment-container" v-if="addSelfAssessmentActive">
				<div class="content">
					<strong>Add Self Assessments</strong>
					<div v-if="!sessionGoal()">
						<label>Select Goal to Assess:</label>
						<select v-model="selectedGoal">
							<option v-for="(goal, index) in this.currentCoachingEngagement.coachingGoals" :key="index" :value="goal">{{ goal.name }}</option>
						</select>
						<button @click="addSelfAssessmentActive = false">Cancel</button>
					</div>
					<div v-if="currentGoal">
						<div v-for="(outcome, index) in currentGoal.coachingOutcomes" :key="index">
							<label>{{ outcome.outcomeName }}:</label>
							<input type="range" v-model="newAssessmentValues[index]" min="1" max="10" />
							<span>{{ newAssessmentValues[index] }}</span>
						</div>
						<button @click="addNewSelfAssessments(currentGoal.coachingOutcomes, newAssessmentValues)">Save</button>
						<button @click="addSelfAssessmentActive = false">Cancel</button>
					</div>
				</div>
			</div>

			<div v-if="!addSessionAssessment" class="assessment-item">
				Session:
				<div class="assessment-bar">
					<div class="bar-inner" :style="{ width: ((session.sessionAssessment - 1) * 11.11) + '%' }"></div>
				</div>
			</div>
			<div v-else>
				<button @click="addSessionAssessmentActive = true">Add Session Assessment +</button>
			</div>
			<!-- Interface to Add Session Assessment -->
			<div class="add-assessment-container" v-if="addSessionAssessmentActive">
				<div class="content">
					<strong>Add Session Assessment</strong>
					<label>This session:</label>
					<input type="range" v-model="newSessionAssessment" min="1" max="10" />
					<span>{{ newSessionAssessment }}</span>
					<button @click="addNewSessionAssessment(newSessionAssessment)">Save</button>
					<button @click="addSessionAssessmentActive = false">Cancel</button>

				</div>
			</div>

		</div>
	  </div>
  
	  <!-- Display private and shared notes -->
	  <div v-if="editNotes && !session.placeholderFutureSession" class="edit-notes-container">
		<div v-if="hasCoachPermission" class="edit-notes-section">
			<strong>Coach’s Private Notes:</strong>
			<!-- removed from textare to allow enter be pressed when editing: @keyup.enter="saveEditNotes()" -->
			<textarea
				v-model="editedPrivateNotes"
				@keyup.esc="editNotes = false"
				@blur="saveEditNotes()"
			></textarea>
		</div>
		<div class="edit-notes-section">
			<strong>Shared Session notes:</strong>
			<textarea
				v-model="editedSharedNotes"
				@keyup.esc="editNotes = false"
				@blur="saveEditNotes()"
			></textarea>
		</div>
		<div class="button-section">
			<button class="button primary margin-top-1" @click="saveEditNotes()">Save</button>
			<button class="button primary margin-top-1" @click="editNotes = false">Cancel</button>
		</div>
	  </div>
	  <div class="notes-section" v-if="!editNotes && !session.placeholderFutureSession" @click.stop="setupEditNotes()">
		<div v-if="hasCoachPermission" class="notes-item">
		  <strong>Coach’s Private Notes:</strong>
		  <p>{{ session.privateNotes }}</p>
		</div>
		<div class="notes-item">
		  <strong>Shared Session notes:</strong>
		  <p>{{ session.sharedNotes }}</p>
		</div>
	  </div>

<!-- tests to check available data for the outcomeAssessments handling -->
<p> {{ this.currentCoachingEngagement.goals }}</p>



	  <!-- Remove remarks -->

<!-- Show/hide remarks button 
	  <button @click="toggleRemarks">
		{{ showRemarks ? 'Hide Remarks' : 'Show Remarks' }}
	  </button>
-->  
	  <!-- Display remarks if showRemarks is true -->
<!--
	  <div v-if="showRemarks" class="remarks-list">
		<coaching-engagement-remark-card
		  v-for="(remark, index) in filteredRemarks"
		  :key="index"
		  :remark="remark"
		></coaching-engagement-remark-card>
	  </div>

-->

	</div>
  </template>
  
  <script>
  //import CoachingEngagementRemarkCard from "@/components/CoachingEngagementRemarkCard.vue";
  import { mapGetters, mapActions } from 'vuex';

  export default {
	name: "CoachingEngagementSessionCard",
	// components: {
	//   CoachingEngagementRemarkCard,
	// },
	props: {
	  session: Object,
	  filteredRemarks: Array,
	},
	data() {
	  return {
		showRemarks: false,
		editSession: false,
		editNotes: false,
		isSaving: false,
		addAssessment: !this.session.coachingOutcomeAssessments || this.session.coachingOutcomeAssessments.length === 0,
		addSelfAssessment: !this.session.coachingOutcomeSelfAssessments || this.session.coachingOutcomeSelfAssessments.length === 0,
		addSessionAssessment: this.session.sessionAssessment === 0,
		addAssessmentActive: false,
		addSelfAssessmentActive: false,
		addSessionAssessmentActive: false,
		selectedGoal: null,
		newAssessmentValues: [],
		newSessionAssessment: 0,
		editAssessment: false,
		editSelfAssessment: false,
		localOutcomeAssessments: [],
		outcomeSelfAssessments: [],
		localSessionGoal: {}, // we will assign sessionGoal based on goal associated with sessionOutcomeAssessments
		newOutcomeAssessments: [],
		newOutcomeSelfAssessments: []
	  };
	},
	mounted() {
		this.checkOutcomeAssessments();
		},
	computed: {
		...mapGetters({
		// coachingEngagement: 'coachingEngagement',  // Assuming 'coachingEngagement' is a defined getter in your Vuex store
		currentCoachingEngagement: 'currentCoachingEngagement',
		coachingOutcomeAssessments: 'coachingOutcomeAssessments',
		coachingOutcomeSelfAssessments: 'coachingOutcomeSelfAssessments',
		profile: "profile",
		}),
		hasCoachPermission() {
			const permittedRoles = ["ROLE_SUPER_TEACHER", "ROLE_COACH"];
			return this.profile && this.profile.roles
			? this.profile.roles.some((role) => permittedRoles.indexOf(role) > -1)
			: false;
		},
		currentGoal() {
			// Use the session goal if available, otherwise use the selected goal
			return this.sessionGoal() || this.selectedGoal;
		},
		goalIndex() {
			// Check if coachingEngagement and coachingEngagement.goals are defined
			if (this.currentCoachingEngagement && this.currentCoachingEngagement.goals) {
				// Find the index of the goal with the same id as session.goalId
				const index = this.currentCoachingEngagement.goals.findIndex(goal => goal.id === this.session.goalId);

				// If the goal was found, return its index. Otherwise, return a default value (e.g., -1)
				return index !== -1 ? index + 1 : -1;  // +1 because arrays are 0-indexed
			}
			return -1;  // Default value if coachingEngagement or coachingEngagement.goals is undefined
		},
		sessionToWatch() {
			return this.session;
		}
	},
	/*
	computed: {
	  filteredRemarks() {
		if (!this.prevSessionEndTime) return [];
		return this.clientRemarks.filter(
		  (remark) =>
			remark.remarkDate > new Date(this.prevSessionEndTime) &&
			remark.remarkDate < new Date(this.session.startTime)
		);
	  },
	},
	*/

	watch: {
		currentGoal() {
			// Initialize newAssessmentValues based on the number of outcomes in the current goal
			if (this.currentgoal && this.currentGoal.coachingOutcomes) {
				this.newAssessmentValues = new Array(this.currentGoal.coachingOutcomes.length).fill(1);
			}
		},
		sessionToWatch: {
			immediate: true, // Run the handler immediately after the watcher is created
			handler(newSession, oldSession) {
				// Invoke checkOutcomeAssessments when session changes
				this.checkOutcomeAssessments();
			}
		}
	},
	methods: {
		...mapActions([
			"updateCoachingSession",
			"getCoachingOutcomeAssessmentsForSession",
			"createCoachingOutcomeAssessment",
			"setCoachingSessionOutcomeAssessments",
			"getCoachingOutcomeSelfAssessmentsForSession",
			"createCoachingOutcomeSelfAssessment"
		]),
	  sessionGoal() {
		// console.log("*** sessionGoal ", (this.session.coachingOutcomeAssessments && (this.session.coachingOutcomeAssessments.length != 0)), (this.session.coachingOutcomeSelfAssessments && (this.session.coachingOutcomeSelfAssessments.length != 0)))
		// if session has assessments, then the session goal is the one tied to those assessments. We take any first assessment
		// check state of assessment data for session and return the goal
		let outcomeId = -1
		if (this.session.coachingOutcomeAssessments && (this.session.coachingOutcomeAssessments.length != 0)) {
			// use first assessment to derive outcome
			outcomeId = this.session.coachingOutcomeAssessments[0].coachingOutcomeId
			// console.log("outcomeId assigned from assessments: ", outcomeId)
		}
		if (outcomeId == -1 && this.session.coachingOutcomeSelfAssessments && (this.session.coachingOutcomeSelfAssessments.length != 0)) {
			// use first selfAssessment to derive outcome
			outcomeId = this.session.coachingOutcomeSelfAssessments[0].coachingOutcomeId
			// console.log("outcomeId assigned from selfAssessments: ", outcomeId)
		}
		if (outcomeId != -1) {
			// loop through goals and outcomes until matching outcome
			for (const coachingGoal of this.currentCoachingEngagement.coachingGoals) {
				for (const coachingOutcome of coachingGoal.coachingOutcomes) {
					// console.log("***sessionGoal - coachingOutcome:", coachingOutcome)
					if (coachingOutcome.id == outcomeId) {
						return coachingGoal
					}
				}
			}
		}
		// else session does not have goal, return null
		return null
	  },
	  sessionGoalIndex() {
		let outcomeId = -1
		let index = 0
		if (this.session.coachingOutcomeAssessments && (this.session.coachingOutcomeAssessments.length != 0)) {
			outcomeId = this.session.coachingOutcomeAssessments[0].coachingOutcomeId
		}
		if (outcomeId == -1 && this.session.coachingOutcomeSelfAssessments && (this.session.coachingOutcomeSelfAssessments.length != 0)) {
			outcomeId = this.session.coachingOutcomeSelfAssessments[0].coachingOutcomeId
		}
		if (outcomeId != -1) {
			// loop through goals and outcomes until matching outcome
			for (const coachingGoal of this.currentCoachingEngagement.coachingGoals) {
				index++
				for (const coachingOutcome of coachingGoal.coachingOutcomes) {
					// console.log("***sessionGoal - coachingOutcome:", coachingOutcome)
					if (coachingOutcome.id == outcomeId) {
						return index
					}
				}
			}
		}
		// else session does not have goal, return null
		return -1
	  },
	  toggleRemarks() {
		this.showRemarks = !this.showRemarks;
	  },
	  formatDate(date) {
		const d = new Date(date);
		return `${d.toLocaleDateString()} ${d.toLocaleTimeString([], {
		  hour: "2-digit",
		  minute: "2-digit",
		})}`;
	  },
	  formatDateOnly(date) {
		const d = new Date(date);
		return d.toLocaleDateString();
		},
	  formatTime(date) {
		const d = new Date(date);
		return d.toLocaleTimeString([], {
		hour: "2-digit",
		minute: "2-digit",
		});
  	  },
	  setupEditNotes() {
		this.editNotes = true
		this.editedPrivateNotes = this.session.privateNotes
		this.editedSharedNotes = this.session.sharedNotes
	  },
	  async saveEditNotes() {
		if (this.isSaving) return; // allow save only once at a time
		this.isSaving = true;
		this.editNotes = false
		console.log("saving notes placeholder - data to save")
		console.log(this.session.id)
		console.log("this.currentCoachingEngagement.id")
		console.log(this.currentCoachingEngagement.id)
		console.log("this.currentCoachingEngagement.coachingEngagementId:")
		console.log(this.currentCoachingEngagement.coachingEngagementId)
		console.log(this.editedPrivateNotes)
		console.log(this.editedSharedNotes)

		await this.updateCoachingSession({ 
			id: this.session.id, 
			studentCoachingEngagementId: this.currentCoachingEngagement.id, // Modify to point to studentCoachingEngagementId
			startTime: this.session.startTime, 
			endTime: this.session.endTime, 
			online: this.session.online, 
			venue: this.session.venue, 
			privateNotes: this.editedPrivateNotes, 
			sharedNotes: this.editedSharedNotes, 
			outcomeSelfAssessmentTime: this.session.outcomeSelfAssessmentTime, 
			sessionAssessment: this.session.sessionAssessment
		});
		this.isSaving = false;
	  },
	  // methods to handle coachingOutcomeAssessments for session
	  async checkOutcomeAssessments() {
		// fetch outcome assessment data if it is not currently present
		if (!this.session.coachingOutcomeAssessments || (this.session.coachingOutcomeAssessments == [])) {
			// await this.getCoachingOutcomeAssessments()
		}
		if (!this.session.coachingOutcomeSelfAssessments || (this.session.coachingOutcomeSelfAssessments == [])) {
			// we have changed logic to retrieve these with session in studentCoachingEngagement
			// await this.getCoachingOutcomeSelfAssessments()
		}

		// check state of assessment data for session ans set the flags accordingly
		if (this.session.sessionAssessment == 0) {
			this.addSessionAssessment = true
		}
		if (!this.session.coachingOutcomeAssessments || (this.session.coachingOutcomeAssessments.length == 0)) {
			this.addAssessment = true
		}
		if (!this.session.coachingOutcomeSelfAssessments || (this.session.coachingOutcomeSelfAssessments.length == 0)) {
			this.addSelfAssessment = true
		}
	  },

	  // createCoachingOutcomeAssessments
	  async createCoachingOutcomeAssessments(newCoachingOutcomeAssessments) {
		for (const coachingOutcomeAssessment in newCoachingOutcomeAssessments) {
			await this.createCoachingOutcomeAssessment({ 
				coachingOutcomeId: coachingOutcomeAssessment.coachingOutcomeId, 
				coachingSessionId: coachingOutcomeAssessment.coachingSessionId,
				assessmentTime: coachingOutcomeAssessment.assessmentTime, 
				result: coachingOutcomeAssessment.result })
		}
	  },
	  async addNewAssessments(selectedCoachingOutcomes, newAssessmentResults) {
		console.log("placeholder to create new outcome assessments from results input by user")

		for (const newResult of newAssessmentResults ) {
			console.log("result: ", newResult)
		}
		for (let index = 0; index < newAssessmentResults.length; index++) {
			// console.log("coachingOutcomeNamet: ", selectedCoachingOutcomes[index].outcomeName)
			// console.log("coachingOutcomeId: ", selectedCoachingOutcomes[index].id)
			// console.log("studentCoachingEngagementId: ", this.currentCoachingEngagement.coachingEngagementId)
			// console.log("coachingSessionId: ", this.session.id)
			// console.log("result: ", newAssessmentResults[index])
			// console.log("assessmentTime: ", new Date())
			await this.createCoachingOutcomeAssessment({
				// studentCoachingEngagementId: this.currentCoachingEngagement.coachingEngagementId, // Modify to point to studentCoachingEngagement.id
				studentCoachingEngagementId: this.currentCoachingEngagement.id, // Modify to point to studentCoachingEngagement.id
				coachingOutcomeId: selectedCoachingOutcomes[index].id, 
				coachingSessionId: this.session.id, 
				assessmentTime: new Date(),
				result: newAssessmentResults[index]
			})
		}
		this.addAssessmentActive = false
		this.addAssessment = false // does not show add button, but data
		this.checkOutcomeAssessments()
	  },
	  async addNewSelfAssessments(selectedCoachingOutcomes, newAssessmentResults) {
		console.log("placeholder to create new outcome self assessments from results input by user")

		// for (const newResult of newAssessmentResults ) {
		// 	console.log("result: ", newResult)
		// }
		for (let index = 0; index < newAssessmentResults.length; index++) {
			// console.log("coachingOutcomeNamet: ", selectedCoachingOutcomes[index].outcomeName)
			// console.log("coachingOutcomeId: ", selectedCoachingOutcomes[index].id)
			// console.log("coachingSessionId: ", this.session.id)
			// console.log("result: ", newAssessmentResults[index])
			// console.log("assessmentTime: ", new Date())

			await this.createCoachingOutcomeSelfAssessment({
				studentCoachingEngagementId: this.currentCoachingEngagement.id, // Modify to point to studentCoachingEngagement.id
				coachingOutcomeId: selectedCoachingOutcomes[index].id, 
				coachingSessionId: this.session.id, 
				assessmentTime: new Date(),
				result: newAssessmentResults[index]
			})
		}
		// update selfAssessmentDate  for the session
		await this.updateCoachingSession({ 
			id: this.session.id, 
			studentCoachingEngagementId: this.currentCoachingEngagement.id, // Modify to point to studentCoachingEngagement.id
			startTime: this.session.startTime, 
			endTime: this.session.endTime, 
			online: this.session.online, 
			venue: this.session.venue, 
			privateNotes: this.session.rivateNotes, 
			sharedNotes: this.session.sharedNotes, 
			outcomeSelfAssessmentTime: new Date(), 
			sessionAssessment: this.session.sessionAssessment
		});
		this.addSelfAssessmentActive = false
		this.addSelfAssessment = false // does not show add button, but data
		this.checkOutcomeAssessments()
	  },
	  async addNewSessionAssessment(newSessionAssessment) {
		// console.log("placeholder to update new session assessment from input by user")
		// console.log("newSessionAssessment: ", newSessionAssessment)
		// console.log("this.outcomeSelfAssessmentTime: ", this.session.outcomeSelfAssessmentTime)
		// console.log("this.session.studentCoachingEngagementId: ", this.session.studentCoachingEngagementId)
		// console.log("this.currentCoachingEngagement.coachingEngagementId: ", this.currentCoachingEngagement.coachingEngagementId)

		await this.updateCoachingSession({ 
			id: this.session.id, 
			studentCoachingEngagementId: this.currentCoachingEngagement.id, // Modify to point to studentCoachingEngagement.id
			startTime: this.session.startTime, 
			endTime: this.session.endTime, 
			online: this.session.online, 
			venue: this.session.venue, 
			privateNotes: this.session.rivateNotes, 
			sharedNotes: this.session.sharedNotes, 
			outcomeSelfAssessmentTime: this.session.outcomeSelfAssessmentTime, 
			sessionAssessment: newSessionAssessment
		});

		this.addSessionAssessmentActive = false // closes the pop-up
		this.addSessionAssessment = false // does not show add button, but data
		this.newSessionAssessment = 0
		this.checkOutcomeAssessments() // refreshes assessment data and statuses
	  }
	},
  };
  </script>
  
  
  <style lang="scss" scoped>
  $progress-color: #4caf50;
  $light-gray: #f0f0f0;
  $darker-gray: #c0c0c0;
  $title-color: #3f51b5;
  $white: #ffffff;
  
  .session-card {
	position: relative;
	border: 2px solid $light-gray;
	border-radius: 10px;
	margin-bottom: 1em;
	background-color: $white;
  }
  
  .goal-number {
	position: absolute;
	top: -10px; 
	left: -10px;
	width: 30px;
	height: 30px;
	background: $brand-quaternary;
	color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
  }
  .session-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5em 1em;
	border-bottom: 1px solid $darker-gray;
  
	p {
	  margin: 0;
	}
  }
  
  .online-indicator {
	display: inline-block;
	background-color: $title-color;
	color: $white;
	border-radius: 5px;
	padding: 0.25em 0.5em;
	margin-top: 0.5em;
	font-size: 0.85em;
  }
  
  .outcome-assessments {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 1em;
	padding: 0.5em 1em;
  }
  
  .outcome-container {
    display: flex;
    flex-direction: column;
  }

  .assessment-item {
	font-size: 0.85em;
	margin-bottom: 0.25em;
  }
  
  .assessment-bar {
	background-color: $light-gray;
	height: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
  
	.bar-inner {
	  height: 100%;
	  background-color: $progress-color;
	  border-radius: 5px;
	}
  }
  
  .add-assessment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure it sits on top */


	.content {
	background-color: #fefefe;
	border: 1px solid #888;
	border-radius: 10px; /* Rounded corners */
	padding: 20px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	max-width: 500px;
	}
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}








  .notes-section {
	padding: 0.5em 1em;
	border-bottom: 1px solid $darker-gray;
  }
  
  .notes-item {
	margin-bottom: 1em;
  
	p {
	  margin: 0;
	}
  }
  
  .remarks-list {
	padding: 0.5em 1em;
  }
  
  button {
	background-color: $secondary;// $title-color;
	color: $white;
	border: none;
	border-radius: 5px;
	padding: 0.5em 1em;
	margin: 0.5em;
	font-size: 1em;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.2s;
  
	&:hover {
	  background-color: darken($title-color, 5%);
	}
  }

  .edit-notes-container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  width: 100%;
  // background-color: $white; // or any other color you want

	input {
		flex-grow: 1;
		//margin-right: 1em; // add some space between input and buttons
	}
	
	.button {
		width: auto; // reset the button width to auto for better spacing
		margin-left: 1em;
	}
  }

  .edit-notes-section {
    //margin-bottom: 1em;
	flex-direction: column;
  }

  textarea {
    width: 100%;
    min-height: 100px; /* or whatever height you want */
    resize: vertical; /* allows vertical resizing, disable if unwanted */
}

  .button-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    .button-section {
        align-items: stretch;
    }

    .button {
        width: 100%;
    }
  }

  .notes-item p {
    white-space: pre-line;
  }
  </style>