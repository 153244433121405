import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import _ from 'lodash';
import modules from './modules';

Vue.use(Vuex);

// persist state
const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	modules: ['configuration']
});

const vuexSession = new VuexPersistence({
	storage: window.sessionStorage,
	modules: ['authentication']
});

// initialize store
const store = new Vuex.Store({
	modules,
	plugins: [vuexLocal.plugin, vuexSession.plugin]
});

export default store;

const ignoreCopy = ['authentication', 'configuration', 'loader', 'toastr'];

// store a copy of the initial state
const initialStateCopy = _.cloneDeep(_.omitBy(store.state, (val, key) => ignoreCopy.indexOf(key) > -1));

export function resetState() {
	Object.keys(initialStateCopy).forEach(key => {
		store.state[key] = { ...initialStateCopy[key] };
	});
	store.replaceState(store.state);
}
