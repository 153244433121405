<template>
  <div class="condition-type-wrapper" v-cloak>
    <div
      v-for="conditionType in conditionTypes"
      class="condition-type"
      :key="conditionType"
    >
      <img
        class="condition-icon"
        :src="conditionTypesMap[conditionType].image"
        :style="{ height: size + 'px', width: size + 'px' }"
      />
      &nbsp;<small v-if="withTextLabel">{{
        $t(conditionTypesMap[conditionType].label)
      }}</small>
    </div>
  </div>
</template>

<script>
import IconEpilepsy from "@/assets/icon-epilepsy.png";

export default {
  name: "ConditionTypeBadge",
  props: {
    conditionTypes: Array,
    withTextLabel: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      conditionTypesMap: {
        EPILEPSY: {
          label: "conditionType.epilepsy",
          image: IconEpilepsy,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>