import Vue from 'vue'

export default {
	getStudentProfile (studentId, planYear) {
		return Vue.http.get('student/' + studentId, {
			params: {
				planYear: planYear
			}
		})
	},
	createStudentProfile (fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes) {
		return Vue.http.post('student', {
			fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes
		})
	},
	updateStudentProfile (studentId, fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes) {
		return Vue.http.put(`student/${studentId}`, {
			fullName, displayName, gender, yearOfBirth, learningSupportNeed, behaviourSupportNeed, additionalSupportNeed, conditionTypes
		})
	},
	deleteStudentProfile (studentId) {
		return Vue.http.delete(`student/${studentId}`)
	}
}
