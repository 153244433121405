import Vue from 'vue'

export default {
	fetchAIResponse (messages, context) {
		console.log("Posting to getAIResponse: { messages }", { messages })
		if (context) {
			console.log("Posting to getAIResponse: { context }", { context })
		}
		const apiPayload = { messages };

		if (context) {
			apiPayload.context = context;
		}

		return Vue.http.post('ai', apiPayload)
	}
}