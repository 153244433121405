// coachingGoal.js vuex store module
import Vue from 'vue'
import * as types from '../mutation-types';
import CoachingGoalApi from '@/api/coachingGoal'
import constants from '@/constants';

// initial state
const state = {
  // old coachingEngagement: null,
  coachingGoal: {},
  coachingGoals: [],
};

// getters
const getters = {
  coachingGoal: state => state.coachingGoal,
  coachingGoals: state => state.coachingGoals,
};

// actions
const actions = {
  setCoachingGoal({ commit }, coachingGoal) {
    commit(types.SET_COACHING_GOAL, coachingGoal);
  },

  // new actions
  setCoachingGoals({ commit }, coachingGoals) {
    commit(types.SET_COACHING_GOALS, coachingGoals);
  },
  async getCoachingGoal({ commit }, id) {
    const response = await CoachingGoalApi.getCoachingGoal(id)
    commit(types.SET_COACHING_GOAL, response.data)
  },

  //async createCoachingEngagement({ commit }, engagementData) {
  //  console.log("STORE: createCoachingENgagement - engagementData: ", engagementData)
  //  const response = await CoachingEngagementApi.createCoachingEngagement(engagementData)
  //  commit('newCoachingEngagement', response.data)
  //},

  async createCoachingGoal({ commit, dispatch, rootState }, { studentCoachingEngagementId, name }) {
    console.log("STORE: createCoachingGoal - { studentCoachingEngagementId, name }:", { studentCoachingEngagementId, name })
    const response = await CoachingGoalApi.createCoachingGoal(studentCoachingEngagementId, name);
  
    if (response.status === constants.RESPONSE_STATUS.OK && response.data) {
      commit(types.ADD_COACHING_GOAL, response.data.coachingGoal);
      // here we need to get the studentCoachingEngagement again to have the latest info with coachingGoal
      await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);

      // if no previous activeGoal, then set active goal to this new first goal
      if (!rootState.coachingActiveGoal.activeGoal.goal) {
        commit(types.SET_ACTIVE_GOAL, {index: 1, goal: response.data.coachingGoal})
      } 
    }
  },

  async updateCoachingGoal({ state, commit, dispatch, rootState }, { id, studentCoachingEngagementId, name }) {
    const response = await CoachingGoalApi.updateCoachingGoal(id, name)
    commit(types.UPDATE_COACHING_GOAL, response.data.coachingGoal)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
	// if we have changed the active goal contents then we set active goal here again. Index remains same
	console.log("*** updateCoachingGoal - state.activeGoal ***")
	console.log(state.activeGoal)
	console.log(rootState.coachingActiveGoal.activeGoal)
	// console.log(context.rootState.coachingActiveGoal.activeGoal)
	// if (state.activeGoal.goal.id === id) {
	if (rootState.coachingActiveGoal.activeGoal.goal.id === id) {
		// commit(types.SET_ACTIVE_GOAL, {index: state.activeGoal.index, goal: response.data.coachingGoal})
		commit(types.SET_ACTIVE_GOAL, {index: rootState.coachingActiveGoal.activeGoal.index, goal: response.data.coachingGoal})
	} 
  },

  async deleteCoachingEngagement({ commit, dispatch }, id, studentCoachingEngagementId) { // here make this use: {id, studentCoachingEngagementId}
    await CoachingGoalApi.deleteCoachingGoal(id)
    commit(types.DELETE_COACHING_GOAL, id)
    // here we need to get the classGroup again to have the latest infor with coachingEngagement
    await dispatch('getStudentCoachingEngagement', studentCoachingEngagementId);
  },
};

// mutations
const mutations = {
  [types.SET_COACHING_GOAL](state, coachingGoal) {
    state.coachingGoal = coachingGoal;
  },
  [types.SET_COACHING_GOALS] (state, coachingGoals) {
    state.coachingGoals = coachingGoals
  },
  [types.ADD_COACHING_GOAL] (state, coachingGoal) {
    state.coachingGoals.unshift(coachingGoal)
  },
  [types.UPDATE_COACHING_GOAL] (state, coachingGoal) {
    Vue.set(state.coachingGoal, coachingGoal)
    // const index = state.coachingEngagements.findIndex(e => e.id === engagement.id)
    // if (index !== -1) {
    //   Vue.set(state.coachingEngagements, index, engagement)
    // }
  },
  [types.DELETE_COACHING_GOAL] (state, coachingGoalId) {
    state.coachingGoals = state.coachingGoals.filter(e => e.id !== coachingGoalId)
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
